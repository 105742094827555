import { Component, ElementRef, OnInit, ViewChild, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploadInputSetting } from '../model/UploadInputSetting.model';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ElementService } from 'src/app/shared/services/element.service';
import { MatInputModule } from '@angular/material/input';
import { FormService } from '../../form/form.service';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss'],
  standalone: true,
  imports: [MatIconModule, NgFor, NgIf, NgClass, NgStyle, ReactiveFormsModule, TranslateModule, MatInputModule, MatFormFieldModule]
})
export class UploadInputComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() uploadSetting: UploadInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;

  @ViewChild('fileInput') fileInput: ElementRef;

  files: any[] = [];
  uploadedFiles: any[] = [];
  element: any;
  formGrpCtrl: any;
  formGrpCtrlData: any;

  currTotalFile: number = 0;
  maxUploadFileSize: number = 5000000; //5mb
  maxCompressFileSize: number = 500000; // 500kb
  progress: number[] = [];

  loading: boolean[] = [];
  dragging: boolean = false;
  isMaxSizeError: boolean = false;
  fail: boolean[] = [];
  success: boolean[] = [];

  constructor(private imageCompress: NgxImageCompressService,
    private elementService: ElementService,
    private formService: FormService) { }

  ngOnInit(): void {
    this.uploadSetting = {
      id: this.uploadSetting?.id? this.uploadSetting.id: '',
      name: this.uploadSetting?.name? this.uploadSetting.name: '',
      label: this.uploadSetting?.label? this.uploadSetting.label: '',
      required: this.uploadSetting?.required? this.uploadSetting.required: false,
      maxUploadFileSize: this.uploadSetting?.maxUploadFileSize? this.uploadSetting.maxUploadFileSize: this.maxUploadFileSize,
      maxFileNum: this.uploadSetting?.maxFileNum? this.uploadSetting.maxFileNum: 1,
      verticalLayout: this.uploadSetting?.verticalLayout? this.uploadSetting.verticalLayout: false,
      acceptFormat: this.uploadSetting?.acceptFormat? this.uploadSetting.acceptFormat: '*/',
      dataKey: this.uploadSetting?.dataKey? this.uploadSetting.dataKey: ''
    };

    this.formGrpCtrl = this.formGroup.get(this.uploadSetting.name);

    if(this.uploadSetting.dataKey) {
      this.formGrpCtrlData = this.formGroup.get(this.uploadSetting.dataKey);
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
  let key = this.uploadSetting.dataKey? this.uploadSetting.dataKey: this.uploadSetting.name;

    if(changes.formData?.currentValue && changes.formData?.currentValue[key]) {
      let data = changes.formData.currentValue[key];

      if(Array.isArray(data)) {
        this.currTotalFile = changes.formData.currentValue[key].length;
        this.files = data;
        this.formGroup.controls[key].setValue(this.files);
      } else {
        this.currTotalFile = 1;
        this.files.push(data);
        this.formGroup.controls[key].setValue(data);
      }
    }

    if(changes.formData?.currentValue && this.uploadSetting.dataKey && changes.formData?.currentValue[this.uploadSetting.dataKey]) {
      this.formGrpCtrl.clearValidators();
      this.formGrpCtrl.updateValueAndValidity();
      this.formGrpCtrlData.markAsTouched();
      this.formGrpCtrlData.setValidators(this.formService.getValidatorFnArray(this.uploadSetting));
    }
  }

  ngAfterViewInit(): void {
    // Get Element
    if(this.uploadSetting.id && this.elementService.getElementById(this.uploadSetting.id)) {
      this.element = this.elementService.getElementById(this.uploadSetting.id);
    }
  }

  onClickUploadFile() {
    if(!this.formGroup.get(this.uploadSetting.name).disabled && !this.element?.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileDragOver(event: any) {
    event.preventDefault();
    this.dragging = true;
  }

  onFileDragLeave(event: any) {
    event.preventDefault();
    this.dragging = false;
  }

  onFileDrop(event: any) {
    event.preventDefault();
    this.dragging = false;
    
    if(!this.formGroup.get(this.uploadSetting.name).disabled && !this.element?.disabled) {
      this.loadFile(event.dataTransfer.files);
    }
  }

  onFileChange(event: any) {
    if(!this.formGroup.get(this.uploadSetting.name).disabled && !this.element?.disabled) {
      this.loadFile(event.target.files);
    }

    if(this.uploadSetting.dataKey) {
      this.formGrpCtrl.markAsTouched();
      this.formGrpCtrl.setValidators(this.formService.getValidatorFnArray(this.uploadSetting));
      this.formGrpCtrlData.clearValidators();
      this.formGrpCtrlData.updateValueAndValidity();
    }

    //clear to allow upload same image
    event.target.value = null;
  }

  onRemove(index: number) {
    this.files.splice(index, 1);
    this.currTotalFile = this.files.length;
    this.isMaxSizeError = false;

    if(this.uploadSetting.maxFileNum <= 1) {
      this.formGroup.controls[this.uploadSetting.name].setValue('');

      if(this.uploadSetting.dataKey) {
        this.formGroup.controls[this.uploadSetting.dataKey].setValue('');
      }
    } else {
      let uploadedFiles = this.formGroup.controls[this.uploadSetting.name].value;
      if(uploadedFiles.length > 0) {
        uploadedFiles.splice(index, 1);
        this.formGroup.controls[this.uploadSetting.name].setValue(uploadedFiles);
      }
    }
  }

  private async loadFile(files: File[]) {
    const formData: FormData = new FormData();
    let uploadedFileLength = this.files.length;

    this.isMaxSizeError = false;

    if(files && files[0]) {
      //extra image selected won't upload
      for (let i: number = 0; i < files.length && (!this.uploadSetting.maxFileNum || this.currTotalFile < this.uploadSetting.maxFileNum); i++) {
        let imageSize = files[i].size;
        let index = uploadedFileLength + i;

        if (imageSize <= this.uploadSetting.maxUploadFileSize) {
          let reader = new FileReader();
          let finalFile: File = files[i];

          reader.onloadstart = () => {
            this.loading[index] = true;
            this.fail[index] = false;
            this.success[index] = false;
          };

          reader.onload = async (event: any) => {
            this.files.push({url: event.target.result, file: finalFile});

            // Compress image
            if(imageSize > this.maxCompressFileSize) {
              finalFile = await this.imageCompression(event.target.result, finalFile);
            }
          };

          reader.onprogress = (data) => {
            if (data.lengthComputable) {
              let progress = Math.round((data.loaded / data.total) * 100);

              if(progress < 90) {
                this.progress[index] = progress;
              } else {
                this.progress[index] = 99;
              }
            }
          };

          reader.onloadend = async (event: any) => {
            this.progress[index] = 100;

            if (event?.target?.result) {
              this.success[index] = true;
              this.loading[index] = false;
              this.fail[index] = false;
            } else {
              this.loading[index] = false;
              this.fail[index] = true;
              this.success[index] = false;
            }
            
            if(this.uploadSetting.maxFileNum <= 1) {
              this.formGroup.controls[this.uploadSetting.name].setValue(finalFile);
            } else {
              let uploadedFiles = this.formGroup.controls[this.uploadSetting.name]?.value? this.formGroup.controls[this.uploadSetting.name]?.value: [];
              this.formGroup.controls[this.uploadSetting.name].setValue(uploadedFiles.push(finalFile));
            }
          }

          reader.readAsDataURL(finalFile);
          this.currTotalFile++;
        } else {
          this.fail[index] = true;
          this.loading[index] = false;
          this.success[index] = false;
          this.isMaxSizeError = true;
        }
      }
    }
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  private imageCompression(url: string, file: File) {
    return new Promise<File>((resolve, reject) => {
      let imageDataUrl = url;
      const dimensionLimit = 500;
      const orientation = 1;
      let ratio = 100;
      let quality = 100;

      let self = this;
      let imageObj = new Image();
      imageObj.src = imageDataUrl;

      imageObj.onload = function () {
        let imageWidth = imageObj.width;
        let imageHeight = imageObj.height;

        if(imageWidth > imageHeight) { // Horizontal image
          ratio = ( dimensionLimit / imageWidth ) * 100;
        } else { // Vertical image
          ratio = ( dimensionLimit / imageHeight ) * 100;
        }

        if (ratio > 100) {
          ratio = 100;
        }

        self.imageCompress.compressFile(imageDataUrl, orientation, ratio, quality).then(
          (result: any) => {
            const compressedImageFile = self.dataURLtoFile(result, file.name);
            if(compressedImageFile.size > file.size) {
              resolve(file);
            }else {
              resolve(compressedImageFile);
            }
          }
        );
      };
    });
  }

  private dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getFileName(url: string) {
    return url.split("/").pop();
  }

  isImage(url: string) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
}
