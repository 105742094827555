import { InvoiceListingComponent } from "src/app/cxm-backend-web/invoice/invoice-listing/invoice-listing.component";
import { PageRoute } from "../enums/pageRoute";
import { FormCode } from "../enums/formCode";
import { InvoiceFormComponent } from "src/app/cxm-backend-web/invoice/invoice-form/invoice-form.component";
import { MasterFormComponent } from "../component/master-form/master-form.component";
import { InvoiceEntryComponent } from "src/app/cxm-backend-web/invoice/invoice-entry/invoice-entry.component";
import { InvoiceEmailEntryComponent } from "src/app/cxm-backend-web/invoice/invoice-emailentry/invoice-emailentry.component";

export const invoicePathComponent = [{
  filePath: PageRoute.INVOICE_LISTING,
  component: InvoiceListingComponent
},
{
  filePath: PageRoute.INVOICE_FORM,
  formCode: FormCode.invoiceform,
  component: InvoiceFormComponent
},
{
  filePath: PageRoute.INVOICE_ENTRY,
  formCode: FormCode.invoiceentry,
  component: InvoiceEntryComponent
},
{
  filePath: PageRoute.INVOICE_EMAILENTRY,
  formCode: FormCode.invoiceemailentry,
  component: InvoiceEmailEntryComponent
},
{
  filePath: PageRoute.INVOICE_MASTER_ENTRY,
  component: MasterFormComponent
},
];
