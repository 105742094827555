import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { TimeInputSetting } from '../model/TimeInputSetting.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { customInputError } from '../model/customInputError.model';
import { ComponentService } from 'src/app/shared/services/component.service';

@Component({
  selector: 'app-time-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule
  ],
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() timeSetting?: TimeInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;
  @Output() onTimeChange = new EventEmitter<string>();

  timeChange$: Subscription;
  required$: Subscription;

  errors: customInputError[];

  constructor(private translateService: TranslateService,
    private componentService: ComponentService
  ) {}

  ngOnInit(): void {
    this.setError();

    if(this.formGroup.get(this.timeSetting.name)) {
      this.timeChange$ = this.formGroup?.get(this.timeSetting.name).valueChanges.subscribe((value: any) => {
        this.onTimeChange.emit(value);
      });
    }

    this.required$ = this.componentService.required$.subscribe((val: any) => {
      if(val.name === this.timeSetting.name) {
        this.timeSetting.required = val.required;

        if(this.timeSetting.required) {
          this.formGroup.get(this.timeSetting.name)?.addValidators(Validators.required);
        } else {
          this.formGroup.get(this.timeSetting.name)?.removeValidators(Validators.required);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue && this.formGroup?.get(this.timeSetting.name)) {
      this.formGroup.controls[this.timeSetting.name].setValue(changes.formData.currentValue[this.timeSetting.name]);
    }

    if(changes.timeSetting?.currentValue) {
      this.timeSetting = changes.timeSetting?.currentValue;

      if(this.timeSetting.required) {
        this.formGroup.get(this.timeSetting.name)?.addValidators(Validators.required);
      } else {
        this.formGroup.get(this.timeSetting.name)?.removeValidators(Validators.required);
      }
    }
  }

  focusOut(event: any) {
    if (!event.target.value) {
      event.target.value = '';
    }
  }

  timeChange(event: any) {
    if(!this.formGroup?.get(this.timeSetting.name)) {
      this.onTimeChange.emit(event.target.value);
    }
  }

  setError() {
    let errors: customInputError[] = [];
    let name: string = this.translateService.instant(this.timeSetting?.name);

    // default errors
    errors.push({name: 'required', errMsg: name + ' ' + this.translateService.instant('msg.is_required')});

    errors = [...this.timeSetting.errors, ...errors];
    let errNames = errors.map(err => err.name);
    this.errors = errors.filter((err, index) => {return errNames.findIndex(name => name === err.name) === index;});
  }

  ngOnDestroy(): void {
    this.timeChange$?.unsubscribe();
    this.required$?.unsubscribe();
  }
}
