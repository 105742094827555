import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormContent } from '../model/FormContent.model';
import { UntypedFormGroup } from '@angular/forms';
import { InputTextComponent } from '../../input/input-text/input-text.component';
import { InputNumberComponent } from '../../input/input-number/input-number.component';
import { LookDropComponent } from '../../input/look-drop/look-drop.component';
import { ToggleInputComponent } from '../../input/toggle-input/toggle-input.component';
import { AutoGenerateInputComponent } from '../../auto-generate-input/auto-generate-input/auto-generate-input.component';
import { TextAreaComponent } from '../../input/text-area/text-area.component';
import { UploadInputComponent } from '../../input/upload-input/upload-input.component';
import { DatePickerComponent } from '../../input/date-picker/date-picker.component';
import { ButtonToggleComponent } from '../../input/button-toggle/button-toggle.component';
import { TimePickerComponent } from '../../input/time-picker/time-picker.component';
import { PeriodInputComponent } from '../../input/period-input/period-input.component';
import { DatePickerRangeComponent } from '../../input/date-picker-range/date-picker-range.component';

@Component({
  selector: 'app-form-inputs',
  standalone: true,
  imports: [
    CommonModule,
    InputTextComponent,
    InputNumberComponent,
    TextAreaComponent,
    LookDropComponent,
    ToggleInputComponent,
    AutoGenerateInputComponent,
    UploadInputComponent,
    DatePickerComponent,
    TimePickerComponent,
    ButtonToggleComponent,
    PeriodInputComponent,
    DatePickerRangeComponent
  ],
  templateUrl: './form-inputs.component.html',
  styleUrls: ['./form-inputs.component.scss']
})
export class FormInputsComponent implements OnInit {
  @Input() content: FormContent;
  @Input() formData?: any;
  @Input() formGrp: UntypedFormGroup;
  @Output() onGenerateInput = new EventEmitter();
  @Output() onToggleChange = new EventEmitter();
  @Output() onButtonToggle = new EventEmitter();
  @Output() onDateChange = new EventEmitter();
  @Output() onTimeChange = new EventEmitter();
  @Output() onPeriodChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onGenerate(event: any) {
    this.onGenerateInput.emit(event);
  }

  onToggleChg(event: any) {
    this.onToggleChange.emit(event);
  }

  onBtnToggle(event: any) {
    this.onButtonToggle.emit(event);
  }

  onDateChg(event: any) {
    this.onDateChange.emit(event);
  }

  onTimeChg(event: any) {
    this.onTimeChange.emit(event);
  }

  onPeriodChg(event: any) {
    this.onPeriodChange.emit(event);
  }
}
