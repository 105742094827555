import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { InputTextSetting } from '../../input/model/InputTextSetting.model';
import { InputTypes } from '../../input/enum/InputTypes';
import { InputTextComponent } from '../../input/input-text/input-text.component';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LookDropInputSetting } from '../../input/model/LookDropInputSetting.model';
import { LookDropComponent } from '../../input/look-drop/look-drop.component';
import { ToggleInputSetting } from '../../input/model/ToggleInputSetting.model';
import { ToggleInputComponent } from '../../input/toggle-input/toggle-input.component';
import { DropdownSetting } from '../../input/model/DropdownSetting.model';
import { DropdownComponent } from '../../input/dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import { PropertyGridSetting } from '../../../models/PropertyGridSetting.model';
import { TextAreaSetting } from '../../input/model/TextAreaSetting.model';
import { TextAreaComponent } from '../../input/text-area/text-area.component';
import { InputNumberSetting } from '../../input/model/InputNumberSetting.model';
import { InputNumberComponent } from '../../input/input-number/input-number.component';
import { DateInputSetting } from '../../input/model/DateInputSetting.model';
import { DatePickerComponent } from '../../input/date-picker/date-picker.component';

@Component({
  selector: 'app-property-grid-renderer',
  standalone: true,
  imports: [
    CommonModule,
    InputTextComponent,
    ReactiveFormsModule,
    FormsModule,
    LookDropComponent,
    ToggleInputComponent,
    DropdownComponent,
    TextAreaComponent,
    InputNumberComponent,
    DatePickerComponent
  ],
  templateUrl: './property-grid-renderer.component.html',
  styleUrls: ['./property-grid-renderer.component.scss']
})
export class PropertyGridRendererComponent implements OnInit, ICellRendererAngularComp, OnDestroy {
  @ViewChild('lookupContainer', { read: ViewContainerRef }) lookupContainer: ViewContainerRef;
  @Output() onLookup = new EventEmitter<any>();

  valueChange$: Subscription;

  formGroup: UntypedFormGroup;
  params!: ICellRendererParams & { setting: PropertyGridSetting, formGroup: UntypedFormGroup, rowData: any };
  isPopup: boolean = false;
  value: string;
  formData: any;

  inputTextSetting: InputTextSetting;
  lookDropInputSetting: LookDropInputSetting;
  toggleInputSetting: ToggleInputSetting;
  dropdownSetting: DropdownSetting;
  textAreaSetting: TextAreaSetting;
  inputNumberSetting: InputNumberSetting;
  dateInputSetting: DateInputSetting;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {}

  agInit(params: ICellRendererParams & { setting: PropertyGridSetting, formGroup: UntypedFormGroup, rowData: any }): void {
    this.params = params;
    this.formGroup = this.params?.formGroup? this.params.formGroup: new UntypedFormGroup({});

    if(this.params.data && (this.params?.setting?.inputTypeKey || this.params?.setting?.valueTypeKey)) {
      let inputType: number = this.params?.setting?.inputTypeKey && this.params.data[this.params.setting.inputTypeKey] ? this.params.data[this.params.setting.inputTypeKey]: 0;
      let valueType: string = this.params?.setting?.valueTypeKey && this.params.data[this.params.setting.valueTypeKey] ? this.params.data[this.params.setting.valueTypeKey]: '';
      let dataSource: string = this.params?.setting?.dropdownData && this.params.data[this.params.setting.dropdownData] ? this.params.data[this.params.setting.dropdownData]: '';
      let inputKey: string = this.params.data[this.params.setting.inputKey]? this.params.data[this.params.setting.inputKey]: '';
      let value = this.params.data[this.params.setting.inputVal]? this.params.data[this.params.setting.inputVal]: '';
      this.params.data.value = this.formGroup.get(inputKey)?.value? this.formGroup.get(inputKey)?.value: value;

      if(inputType === 0 && valueType === 'L') {
        // boolean
        this.toggleInputSetting = {
          name: inputKey,
        };

        if(value === '0' || value === 'false') {
          value = false;
        } else if(value === '1' || value === 'true') {
          value = true;
        }
        this.formGroup.addControl(inputKey, this.fb.control(false));
        this.formGroup.controls[inputKey].setValue(value? value: false);
      } else if(inputType === 1 || (inputType === 0 && valueType === 'I') || (inputType === 0 && valueType === 'N')) {
        // number spinner
        let range = dataSource? JSON.parse(dataSource): null;

        this.inputNumberSetting = {
          name: inputKey,
          min: range.SpinnerMin !== null || range.SpinnerMin !== ""? range.SpinnerMin: undefined,
          max: range.SpinnerMax !== null || range.SpinnerMax !== ""? range.SpinnerMax: undefined,
          step: range?.SpinnerStep? range.SpinnerStep: undefined,
          styling: { width: '100%' },
          showButton: true
        };

        this.formGroup.addControl(inputKey, this.fb.control(''));
        this.formGroup.controls[inputKey].setValue(value? value: '');

        let validators = [];
        if(range.SpinnerMin !== null || range.SpinnerMin !== "") {
          validators.push(Validators.min(range.SpinnerMin));
        }
        if(range.SpinnerMax !== null || range.SpinnerMax !== "") {
          validators.push(Validators.max(range.SpinnerMax));
        }

        if(validators && validators.length > 0) {
          this.formGroup.get(inputKey).setValidators(validators);
        }
      } else if(inputType === 2 || inputType === 3) {
        // dropdown
        this.lookDropInputSetting = {
          name: inputKey,
          placeholder: 'general.select_at_least_one',
          dropdownOption: dataSource? JSON.parse(dataSource).SelectionItemList: null,
          styling: {
            width: '100%'
          },
          autoComplete: true,
          valueKey: 'ItemValue',
          displayValueKey: 'ItemDesc',
          colDef: [{ field: 'ItemDesc' }]
        };

        this.formGroup.addControl(inputKey, this.fb.control(value? value: ''));
        this.formGroup.controls[inputKey].setValue(value? value: '');

        this.formData = [{
          [inputKey]: value? value: ''
        }];
      } else if (inputType === 6) {
        // password
        this.inputTextSetting = {
          name: inputKey,
          type: InputTypes.password,
          styling: { width: '100%' }
        };

        this.formGroup.addControl(inputKey, this.fb.control(value? value: ''));
        this.formGroup.controls[inputKey].setValue(value? value: '');
      } else if(inputType === 7 || (inputType === 0 && valueType === 'C')) {
        // textarea
        this.textAreaSetting = {
          name: inputKey,
          styling: { width: '100%' }
        };

        this.formGroup.addControl(inputKey, this.fb.control(value? value: ''));
        this.formGroup.controls[inputKey].setValue(value? value: '');
      } else if(inputType === 0 && valueType === 'D') {
        // Calender
        this.dateInputSetting = {
          name: inputKey,
          type: InputTypes.date,
          pattern: 'dd/MM/yy',
          styling: { width: '100%' }
        }

        this.formGroup.addControl(inputKey, this.fb.control(value? value: ''));
        this.formGroup.controls[inputKey].setValue(value? value: '');
      } else if(inputType === 0 && valueType === 'T') {
        // DateTime
        this.inputTextSetting = {
          name: inputKey,
          type: InputTypes.dateTimeLocal,
          dateFormat: 'dd/MM/yy  h:mm a',
          styling: { width: '100%' }
        }

        this.formGroup.addControl(inputKey, this.fb.control(value? value: ''));
      } else if(inputType === 0 && (valueType === 'F' || valueType === 'Y')) {
        // Float / Decimal
        this.inputNumberSetting = {
          name: inputKey,
          step: 0.1,
          showButton: true,
          styling: { width: '100%' },
        };

        this.formGroup.addControl(inputKey, this.fb.control(value? value: ''));
        this.formGroup.controls[inputKey].setValue(value? value: '');
      }
    }
  }

  refresh(params: ICellRendererParams & { setting: PropertyGridSetting, formGroup: UntypedFormGroup, rowData: any }): boolean {
    this.params = params;
    return false;
  }

  ngOnDestroy(): void {
    this.valueChange$?.unsubscribe();
  }
}
