import { Inject, Injectable, LOCALE_ID, inject } from '@angular/core';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { environment } from 'src/environments/environment';
import * as JSZip from 'jszip';
import { Store, createStore, withProps } from '@ngneat/elf';
import { ReportViewerParams } from '../../models/reportViewerParams.model';
import { decodeBase64, encodeBase64 } from 'src/app/core/services/utils.service';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { agGridDataType } from '../../enums/agGridDataType';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { FormCode } from '../../enums/formCode';
import { SysFormatPipe } from '../../pipes/sys-format.pipe';
import { SysFormatType } from '../../enums/sysFormatType';
import { PopupMessage } from '../../models/popupMessage.model';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';
import { GridApi } from 'ag-grid-community';
import { agGridTemplate } from '../../enums/agGridTemplate';
import { FlagMappingPipe } from '../../pipes/flag-mapping.pipe';

@Injectable({
  providedIn: 'root'
})

export class ReportViewerService {

  constructor(private customService: CustomService,
    private sysFormatPipe: SysFormatPipe,
    private flagMappingPipe: FlagMappingPipe,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string) { }

  async getReportViewer(params: ReportViewerParams) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.reportViewer.GetReportViewer,
      hostPath: params?.ApiUrl,
      headers: {
        xAccessCode: params.accessCode? params.accessCode: '',
        subscriptionId: params.SubscriptionId? params.SubscriptionId: environment.subscription
      },
      queryParams: {
        'parameters': params.JobId? encodeBase64(params.JobId.toString()): undefined
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest, true);
    return respInfo;
  }

  async getReportViewerV2(params: ReportViewerParams) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.reportViewer.GetReportViewerV2,
      hostPath: params?.ApiUrl,
      headers: {
        xAccessCode: params.accessCode? params.accessCode: '',
        subscriptionId: params.SubscriptionId? params.SubscriptionId: environment.subscription
      },
      queryParams: {
        'parameters': params.JobId? encodeBase64(params.JobId.toString()): undefined
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async getPreviousGeneratedReports(formCode: FormCode | string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.reportViewer.GetPreviousGeneratedReports,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest, unzip?: boolean) {
    return this.customService.createRequest(customRequest, true).then((dd: any) => {
      let body = dd && dd['body']? dd['body']: dd;

      if(unzip && dd && dd['body']) { 
        body = this.unzip(dd['body']); 
      }
      return body;
    });
  }

  async unzip(respData: ArrayBuffer) {
    return await JSZip.loadAsync(respData).then(function (zip) {
      var fileName = '';
      zip.forEach(function (relativePath, file) {
        fileName = relativePath;
      });
      return zip.file(fileName).async("string");
    }).then(function (data) {
      return JSON.parse(data);
    });
  }

  // format from encoded param to object
  formatEncodedParam(params: string): ReportViewerParams {
    let paramObj: ReportViewerParams = null;
    let decodedQueryParams = null;

    if(params) {
      try {
        decodedQueryParams = decodeURIComponent(decodeBase64(params));
        let parameters = decodedQueryParams.split("&");

        let object: any = {}
        parameters.forEach((param: any) => {
          let obj = param.split("=");
          object = {...object, ...{[obj[0]]: obj[1]}};
        });

        paramObj = object;
      } catch(e) {
        return paramObj;
      }
    }

    return paramObj;
  }

  // checkDataType(type: any, format: any) {
  //   if(type === agGridDataType.date) {
  //     return agGridDataType.date;
  //   } else if(type === agGridDataType.string) {
  //     return agGridDataType.string;
  //   } else {
  //     let decimalSplit = format? format.replaceAll("{", "").replaceAll("}", "").split(":"): '';
  //     let decimalType = decimalSplit?.[decimalSplit.length - 1];

  //     if(decimalType === 'p') {
  //       return agGridDataType.percentage;
  //     } else {
  //       return agGridDataType.decimal;
  //     }
  //   }
  // }
  
  calculateFormula(formula: string, value: any, gridApi?: GridApi) {
    let formulaArr = formula?.split(/\s*\{(\w+)\}\s*/g).filter(Boolean);
    let valueArr = value? Object.keys(value): null;

    formulaArr.forEach((formula: any, index: number) => {
      let val = valueArr?.find(val => val.includes(formula));
      if(val) {
        formulaArr[index] = value[val];
      } else if(gridApi) {
        let total = this.getTotalColumnValue(gridApi, formula);
        if(+total || total === 0) {
          formulaArr[index] = total.toString();
        }
      }
    });
    
    let calculation = formulaArr.join('').replace(" ", "");
    let result = 0;
    try {
      result = eval(calculation);
    } catch {}

    return result;
  }

  getTotalColumnValue(gridApi: GridApi, columnField: string) {
    let total = 0;
  
    // Iterate over each row node
    gridApi.forEachNode((node) => {
      // Add the value of the specified column to the total
      if (node.data && columnField in node.data) {
        total += node.data[columnField];
      } else {
        total = null;
      }
    });
  
    return total;
  }

  formatData(type: any, value: any, unit?: any, flag?: agGridTemplate) {
    // format template or flags
    switch(flag) {
      case agGridTemplate.secMins:
        value = value + "(" + (Math.round((value / 60) * 100) / 100).toFixed(2) + " mins)";
        break;
      case agGridTemplate.sourceFlag:
      case agGridTemplate.orderStatus:
      case agGridTemplate.memberType:
      case agGridTemplate.status:
      case agGridTemplate.salesMode:
      case agGridTemplate.salesException:
      case agGridTemplate.lineMode:
      case agGridTemplate.yesno: {
        let mappedVal: any = this.flagMappingPipe.transform(value, flag);
        value = mappedVal? mappedVal: 'N/A';
        break;
      };
      default:
        break;
    }
    
    // format data type
    if(type === agGridDataType.date) {
      let dateFormat = 'dd/MM/yyyy'; // Set the desired format here
      let datePipe = new DatePipe("EN-MY"); // assume only malaysia
      let date = datePipe.transform(value, dateFormat);
      return date;
    } else if(type === agGridDataType.percentage) {
      let percentPipe = new PercentPipe(this.locale);
      return percentPipe.transform(value, '0.2-2')? percentPipe.transform(value, '0.2-2'): 0;
    } else if(type === agGridDataType.decimal) {
      if(unit === 'amount') {
        value = this.sysFormatPipe.transform(value, SysFormatType.DECIMALBYAMT);
      } else if(unit === 'quantity') {
        value = this.sysFormatPipe.transform(value, SysFormatType.DECIMALBYQTY);
      }
      return value? value: 0;
    } else if(type === agGridDataType.string) {
      return value !== "" ? value: "";
    } else if(type === agGridDataType.integer) {
      return value? value: 0;
    }
    return value;
  }

  showQuitRptViewer() {
    let popupMsg: PopupMessage = {
      id: 'quit-rpt-viewer',
      titleIcon: 'assets/base/icons/exclamation-circle.svg',
      title: 'Quit Report Viewer',
      desc: 'Are you sure to quit viewing this report and start a new one?',
      actionBtnText: 'general.okay',
      closeBtnText: 'general.cancel'
    };

    this.dialog.open(PopupMessageComponent, { data: popupMsg });
  }
}
