<div class="filter-group-layout">
  <div class="header">
    <h1>{{ "general.filtercolumngrouping"| translate }}
      <span class="filter-group-title" *ngIf="!reportData">{{'report.' + formCode |
        translate}}</span>
      <span class="filter-group-title" *ngIf="reportData && reportData['report-title']">{{'report.' +
        reportData['report-title'] | translate}}</span>
    </h1>
    <img src="assets/base/icons/remove.svg" (click)="onClose()" />
  </div>
  <div class="filter-group-container" *ngIf="!isMobile">
    <div class="filter-container">
      <app-custom-filter #customFilter [filters]="filters" [reportData]="reportData" [filterItems]="filterItems"
        [isEditMode]="isEditMode" [formCode]="formCode" [defaultFilter]="defaultFilter" (onQuery)="onQuery($event)"
        (passFilter)="passFilter($event)" (passChangeState)="handleChange($event)"></app-custom-filter>
    </div>
    <div class="group-container">
      <app-group-selection [formCode]="formCode" [selectionGroups]="selectionGroups" [selectedGroups]="selectedGroups"
        (onSelectedChange)="selectedGroups = $event" [editMode]="isEditMode"></app-group-selection>
    </div>
  </div>

  <div class="filter-group-container" *ngIf="isMobile">
    <div class="filter-container">
      <mat-tab-group>
        <mat-tab label="Filter">
          <app-custom-filter #customFilter [filters]="filters" [reportData]="reportData" [filterItems]="filterItems"
            [isMobile]="isMobile" [isEditMode]="isEditMode" [formCode]="formCode" [defaultFilter]="defaultFilter"
            (onQuery)="onQuery($event)" (passFilter)="passFilter($event)"
            (passChangeState)="handleChange($event)"></app-custom-filter>
        </mat-tab>
        <mat-tab label="Grouping">
          <app-group-selection [formCode]="formCode" [selectionGroups]="selectionGroups"
            [selectedGroups]="selectedGroups" (onSelectedChange)="selectedGroups = $event" [editMode]="isEditMode"
            [isMobile]="isMobile"></app-group-selection>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="footer" *ngIf="isEditMode">
    <div class="count-option-container">
      <app-radio-button [radioBtnSetting]="radioBtnSetting" [formData]="{'countBy': countBy}" (onValueChanged)="onCountByChged($event)"></app-radio-button>
    </div>
    <app-action-tool-bar [actionToolbarSetting]="actionToolbarSetting"
      (onAction)="onToolbarAction($event)"></app-action-tool-bar>
  </div>
</div>
