<div class="terms-title">{{'general.term_title_1' | translate: {username: userDisplayName } }}</div>

<div #termsContent class="terms-content" (scroll)="onScroll()">
  <div class="terms-content-sub">
    <div class="paragraph">
      Before starting, please read our Terms and Conditions.
    </div>

    <div class="paragraph">ZEONIQ TERMS OF USE</div>

    <div class="paragraph">IMPORTANT - PLEASE READ CAREFULLY:</div>

    <div class="paragraph">
      This Zeoniq Terms of Use ("Agreement") constitutes a valid and binding agreement between EVOLOPER SDN BHD, a
      private limited company incorporated under the Companies Act 1965 of Malaysia together with its affiliates,
      successors and assigns (‘EVOLOPER’) with its registered address at 10-01, Tower 5, UOA Business Park, Jalan
      Pengaturcara U1/51, Seksyen U1, 40150 Shah Alam, Selangor Darul Ehsan and you ("you," or "your") for the use of
      EVOLOPER’s software applicable to our products Zeoniq POS or Zeoniq RMS, as the case may be, including any online
      or enclosed documentation, data distributed to your computer for processing and any future programming fixes,
      updates and upgrades provided to you (collectively, the "ZEONIQ Software"). You must enter into this Agreement in
      order to install and use ZEONIQ Software.

      BY INSTALLING AND USING THE ZEONIQ SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT
      AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT INSTALL OR USE THE ZEONIQ SOFTWARE.
    </div>

    <div class="paragraph">
      <div>1. License Grant</div>
      <div class="content">
        In consideration for the payment you have made for the ZEONIQ Software, receipt of which EVOLOPER now
        acknowledges, and subject to the terms of this Agreement, EVOLOPER hereby grants you a limited, non-exclusive,
        non-sub-licensable, non-assignable license to download, install and use a single copy of the ZEONIQ Software
        onto a network server or computer workstation for your sole use to install, interact with and utilize the ZEONIQ
        Software, including the content and features contained therein. This license may not be shared, or used
        concurrently on different servers or workstations.
      </div>
    </div>

    <div class="paragraph">
      <div>2. License Restrictions</div>
      <ol class="list-decimal">
        <li>
          Notwithstanding anything to the contrary, you may not: (i) remove any proprietary notices from the ZEONIQ
          Software or any copy thereof; (ii) cause, permit or authorize the modification, creation of derivative works,
          translation, reverse engineering, decompiling or disassembling or hacking of the ZEONIQ Software; (iii) sell,
          assign, rent, lease, act as a service bureau, or grant rights in the ZEONIQ Software, including, without
          limitation, through sublicense, to any other entity without the prior written consent of EVOLOPER; (iv) export
          or re-export the ZEONIQ Software in violation of Malaysia export laws or any other applicable law, regulation
          or ordinance in any jurisdictions; (v) use the ZEONIQ Software for any commercial purpose or the benefit of
          any third party or charge any person for the use of the ZEONIQ Software; or (vii) use the ZEONIQ Software to,
          or in any way that would violate any applicable law, regulation or ordinance; (viii) collect any information
          or communication about the users of the ZEONIQ Software by monitoring, interdicting or intercepting any
          process of the ZEONIQ Software; and (viii) use any type of bot, spider virus, clock, timer, counter, worm,
          software lock, drop dead device, packet-sniffer, Trojan-horse routing, trap door, time bomb or any other codes
          or instructions that are designed to be used to provide a means of surreptitious or unauthorized access or
          that are designed to distort, delete, damage or disassemble the ZEONIQ Software. Furthermore, you may not use
          the ZEONIQ Software to develop, generate, transmit or store information that: (a) infringes any third party's
          intellectual property or other proprietary right; (b) is defamatory, harmful, abusive, obscene or hateful; (c)
          in any way obstructs or otherwise interferes with the normal performance of another person's use of the ZEONIQ
          Software, (d) performs any unsolicited commercial communication not permitted by applicable law; (e) is
          harassment or a violation of privacy or threatens other people or groups of people; and (f) impersonates any
          other person, or steals or assumes any person's identity (whether a real identity or online nickname or
          alias);
        </li>
        <li>
          The ZEONIQ Software contains confidential and trade secret information owned or licensed by EVOLOPER, and you
          agree to take reasonable steps at all times to protect and maintain the confidentiality of such information;
          and The ZEONIQ Software may be incorporated into, and may incorporate, technology, software and services owned
          and controlled by third parties. Use of such third party software or services is subject to the terms and
          conditions of the applicable third party license agreements, and you agree to look solely to the applicable
          third party and not to EVOLOPER to enforce any of your rights. All modifications or enhancements to the ZEONIQ
          Software remain the sole property of EVOLOPER. EVOLOPER reserves the right to add additional features or
          functions to the ZEONIQ
        </li>
        <li>
          Software and provides such upgrades for your use. When installed on your computer, the ZEONIQ Software
          periodically communicates with ZEONIQ servers. You acknowledge and agree to always use the latest version of
          ZEONIQ Software made available to you.
        </li>
      </ol>
    </div>

    <div class="paragraph">
      <div>3. Maintenance Services</div>
      <div class="content">
        a) EVOLOPER shall provide, with reasonable skill and care, data hosting services including the provision and
        maintenance of such system and server necessary to support your use of ZEONIQ Software pursuant to this
        Agreement (‘Services’);

        b) EVOLOPER shall use its best endeavours to provide the Services at all times subject always to and you
        acknowledge that (i) our Services may be unavailable from time to time or may vary depending on your device and
        region or may be offered for a limited period or (ii) occasional disruptions and outages may occur unexpectedly
        or due to scheduled maintenance and affect EVOLOPER’s ability to provide the Services;

        c) In the event of any events set out in Clause 3(b) herein, EVOLOPER is not liable for any disruption or loss
        you may suffer directly or indirectly; and

        d) EVOLOPER has the absolute discretion to suspend the provision of the Services for any reasons whatsoever
        including but not limited to the reason that EVOLOPER stops providing the Services in relation to the ZEONIQ
        Software to end-users generally provided that EVOLOPER shall provide a 30-day written prior notice to you of its
        intention to suspend the Services and the provisions of this Clause 3 shall cease to apply but the other
        provisions in this Agreement will continue to be in full force and effect.
      </div>
    </div>

    <div class="paragraph">
      <div>4. Permission to Utilize</div>
      <div class="content">
        In order to receive the benefits provided by the ZEONIQ Software and under this Agreement, you hereby grant
        permission for the ZEONIQ Software to utilize the processor and bandwidth of your computer for the limited
        purpose
        of facilitating the communication between other ZEONIQ Software users. You understand that the ZEONIQ Software
        will protect the privacy and integrity of your computer resources and communication and ensure the unobtrusive
        utilization of your computer resources to the greatest extent possible.
      </div>
    </div>

    <div class="paragraph">
      <div>5. Privacy</div>
      <div class="content">
        (a) Customer, payment and support data provided by you during the sign-up for, purchase or administration of the
        ZEONIQ Software that is reasonably understood by EVOLOPER as confidential (‘Confidential Information’) will be,
        during such period this Agreement remains in full force and effective or such period you subscribe to use ZEONIQ
        Software as the case may be, (i) kept confidential by EVOLOPER; and (ii) not be disclosed by EVOLOPER to any
        person without your prior written consent PROVIDED ALWAYS that EVOLOPER may disclose such Confidential
        Information
        to (a) EVOLOPER’s officers, employees, professional advisers, insurers, agents and subcontractors who need to
        access such confidential Information for their work in respect of the ZEONIQ Software and/or this Agreement
        only;
        (b) third parties for purposes of fraud prevention or to process payment transactions; or (c) to the extent that
        the Confidential Information is required to be disclosed by any law or regulation, by any judicial or
        governmental
        order or request. Notwithstanding the aforesaid, this Clause imposes no obligations on EVOLOPER with respect to
        Confidential Information that is known to EVOLOPER before this Agreement or where such Confidential Information
        is
        or becomes publicly known through no act of EVOLOPER.

        (b) Confidential Information includes information EVOLOPER collects when you contact or engage EVOLOPER for the
        Services, including but not limited to when you run an automated troubleshooter.

        (c) The Confidential Information will be used by EVOLOPER to operate, improve and personalize end-user service
        that it provides to you; to contact you to provide information about your account, subscriptions, billing and
        updates regarding the ZEONIQ Software; to combat spam or other malware; to improve features and functionality of
        the ZEONIQ Software for your benefit; and if applicable, to complete payment transactions related to your use or
        subscription of the ZEONIQ Software.

        (d) Subject to the provisos contained in Clause 5 (a) herein, upon termination of this Agreement or your
        subscription to use the ZEONIQ Software, as the case may be, EVOLOPER shall remove or delete the Confidential
        Information from its records.

        (e) Any changes made by EVOLOPER from time to time regarding the Confidential Information, if necessary based on
        end-users feedback, will be notified to you by posting a notice of such changes on its website located at
        www.ZEONIQ.com.
      </div>
    </div>

    <div class="paragraph">
      <div>6. Proprietary Rights</div>
      <div class="content">
        The ZEONIQ Software contains proprietary and confidential information of EVOLOPER, including copyrights, trade
        secrets and trademarks contained therein, which are protected by international copyright laws. Title to and
        ownership of the ZEONIQ Software, including without limitation all intellectual property rights therein and
        thereto, are and shall remain the exclusive property of EVOLOPER and except for the limited license granted to
        you, EVOLOPER reserves all right, title and interest in and to the ZEONIQ Software. You shall not take any
        action
        to jeopardize, limit or interfere with EVOLOPER' ownership of and rights with respect to the ZEONIQ Software.
        You
        acknowledge that any unauthorized copying or unauthorized use of the ZEONIQ Software is a violation of this
        Agreement and copyright laws and is strictly prohibited.
      </div>
    </div>

    <div class="paragraph">
      <div>7. Terms and Termination</div>
      <div class="content">
        (a) This Agreement will be effective as of the date you accept this Agreement, thereby expressly agreeing to the
        terms and conditions set forth herein, and will remain effective until terminated by either party as set forth
        below.

        (b) You may terminate this Agreement at any time provided you cease all use of the ZEONIQ Software AND destroy
        or
        remove from all hard drives, networks, and other storage media all copies of the ZEONIQ Software in your
        possession. EVOLOPER may terminate this Agreement if you do not comply with the terms and conditions of this
        agreement by providing notice to you and/or preventing your access to the ZEONIQ Software.

        (c) Upon termination of this Agreement for any reason (i) all licenses and rights to use the ZEONIQ Software
        shall
        terminate and you must remove the ZEONIQ Software from your computer equipment and dispose of all originals and
        copies of the ZEONIQ Software in your possession, and Clauses 2, 5(d), 6, 7(c) through 13 shall survive such
        termination.
      </div>
    </div>

    <div class="paragraph">
      <div>8. Your Representations and Warranties</div>
      <div class="content">
        (a) You represent and warrant that (i) you possess the legal right and ability to enter into this Agreement and
        to
        comply with its terms, (ii) you will use the ZEONIQ Software for lawful purposes only and in accordance with
        this
        Agreement and all applicable laws, regulations and policies, (iii) you will not attempt to decompile, reverse
        engineer or hack the ZEONIQ Software to defeat or overcome any encryption and/or other technical protection
        methods implemented by EVOLOPER with respect to the ZEONIQ Software and/or data transmitted, processed or stored
        by EVOLOPER or other users of the ZEONIQ Software, (iv) you will not take any steps to interfere with or in any
        manner compromise any of EVOLOPER' security measures, any other individual's or entity's computer on the Network
        and/or otherwise sharing Services, (v) you will always provide and maintain true, accurate, current and complete
        information as requested by EVOLOPER, and (vi) you will only use the ZEONIQ Software on a computer on which such
        use is authorized by the computer's owner.

        (b) You agree that you will not use any automatic or manual device or process to interfere or attempt to
        interfere
        with the proper working of the ZEONIQ Software, except to remove the ZEONIQ Software from a computer of which
        you
        are an owner or authorized user in a manner permitted by this Agreement. You may not violate or attempt to
        violate
        the security of the ZEONIQ Software. EVOLOPER reserves the right to investigate occurrences which may involve
        such
        violations, and may involve, and cooperate with, law enforcement authorities in prosecuting users who have
        participated in such violations.

        (c) If EVOLOPER has reasonable grounds to suspect that your representations, warranties or promises are
        inaccurate
        or breached, EVOLOPER may terminate this license, deny any or all use of the ZEONIQ Software, and pursue any
        appropriate legal remedies.
      </div>
    </div>

    <div class="paragraph">
      <div>9. Indemnity</div>
      <div class="content">
        You agree to indemnify, hold harmless and defend EVOLOPER and its affiliates, subsidiaries, officers, directors,
        employees, agents and network service providers at your expense, against any and all third-party claims,
        actions,
        proceedings, and suits and all related liabilities, damages, settlements, penalties, fines, costs and expenses
        (including, without limitation, reasonable attorneys' fees and other dispute resolution expenses) incurred by
        EVOLOPER arising out of or relating to your (a) violation or breach of any term of this Agreement or any policy
        or
        guidelines referenced herein, or (b) use or misuse of the ZEONIQ Software.
      </div>
    </div>

    <div class="paragraph">
      <div>10. Disclaimer of Warranties</div>
      <ol class="list-decimal">
        <li>
          THE ZEONIQ SOFTWARE IS PROVIDED "AS IS" AND THERE ARE NO WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY
          EVOLOPER,
          EITHER EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO THE ZEONIQ SOFTWARE, INCLUDING WARRANTIES OF QUALITY,
          PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY
          WARRANTIES
          CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE USAGE. EVOLOPER FURTHER DOES NOT REPRESENT OR
          WARRANT THAT THE ZEONIQ SOFTWARE WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE,
          ACCURATE,
          COMPLETE, ERROR-FREE, OR WILL OPERATE WITHOUT PACKET LOSS, NOR DOES EVOLOPER WARRANT ANY CONNECTION TO OR
          TRANSMISSION FROM THE INTERNET.
        </li>
        <li>
          YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE ZEONIQ SOFTWARE REMAINS WITH
          YOU
          TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </li>
        <li>
          THE ZEONIQ SOFTWARE IS UTILIZED AND DISTRIBUTED BY THIRD PARTIES WHICH ARE UNRELATED TO EVOLOPER. YOU
          ACKNOWLEDGE THAT INSTALLATION OF THE ZEONIQ SOFTWARE WILL ALLOW THIRD PARTIES WHO ARE NOT AFFILIATED WITH
          EVOLOPER THE
          ABILITY TO COMMUNICATE WITH YOUR COMPUTER ("OUTSIDE PARTIES"). YOU AGREE THAT EVOLOPER WILL NOT BE LIABLE FOR
          ANY
          DAMAGE, CLAIM OR LOSS OF ANY KIND WHATSOEVER, INCLUDING BUT NOT LIMITED TO INDIRECT, INCIDENTAL, SPECIAL OR
          CONSEQUENTIAL DAMAGES AS STATED IN CLAUSE10(a) ABOVE, RESULTING FROM ANY ACTIONS OR OMISSIONS OF THE OUTSIDE
          PARTIES.
        </li>
        <li>
          As some jurisdictions do not allow some of the exclusions set forth in this Clause 10, some of these
          exclusions may not apply to you.
        </li>
      </ol>
    </div>

    <div class="paragraph">
      <div>11. Limitation of Liability</div>
      <ol class="list-decimal">
        <li>
          IN NO EVENT SHALL EVOLOPER, ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR
          NETWORK SERVICE PROVIDERS BE LIABLE WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE (WHETHER ACTIVE,
          PASSIVE
          OR IMPUTED), PRODUCT LIABILITY OR STRICT LIABILITY OR OTHER THEORY), FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR
          CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION ANY LOSS OF DATA, SERVICE INTERRUPTION, COMPUTER FAILURE
          OR
          PECUNIARY LOSS) ARISING OUT OF THE USE OR INABILITY TO USE THE ZEONIQ SOFTWARE, EVEN IF EVOLOPER HAS BEEN
          ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES.
        </li>
        <li>
          As some jurisdictions do not allow some of the exclusions set forth in this Clause 11, some of these
          exclusions may not apply to you.
        </li>
      </ol>
    </div>

    <div class="paragraph">
      <div>12. Electronic Signatures and Agreements</div>
      <div class="content">
        You acknowledge and agree that by clicking on the button labelled "SUBMIT", "DOWNLOAD", "I ACCEPT" or such
        similar
        links or methods as may be designated by EVOLOPER to download the ZEONIQ Software to accept the terms and
        conditions of this Agreement, you are submitting a legally binding electronic signature and are entering into a
        legally binding contract. You acknowledge that your electronic submissions constitute your agreement and intent
        to
        be bound by this Agreement. Pursuant to any applicable statutes, regulations, rules, ordinances or other laws,
        YOU
        HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS AND TO ELECTRONIC DELIVERY
        OF NOTICES, POLICIES AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE ZEONIQ SOFTWARE. Further,
        you
        hereby waive any rights or requirements under any statutes, regulations, rules, ordinances or other laws in any
        jurisdiction which require an original signature or delivery or retention of non-electronic records.
      </div>
    </div>

    <div class="paragraph">
      <div>13. General Provisions</div>
      <div class="content">
        EVOLOPER reserves all rights not expressly granted herein. EVOLOPER may modify this Agreement at any time by
        providing such revised Agreement to you or posting the revised Agreement on its website located at
        www.ZEONIQ.com.
        Your continued use of the ZEONIQ Software shall constitute your acceptance of such revised Agreement. You may
        not
        assign this Agreement or any rights hereunder. Nothing in this Agreement shall constitute a partnership or joint
        venture between you and EVOLOPER. Should any term or provision hereof be deemed invalid, void or unenforceable
        either in its entirety or in a particular application, the remainder of this Agreement shall nonetheless remain
        in
        full force and effect. The failure of EVOLOPER at any time or times to require performance of any provision
        hereof
        shall in no manner affect its right at a later time to enforce the same unless the same is waived in writing.
        This
        Agreement shall be governed by and construed in accordance with the laws of the Malaysia without regard to its
        conflict of law rules. Any legal proceeding arising out or relating to this Agreement will be subject to the
        exclusive jurisdiction of any court of the Malaysia and you irrevocably consent to the jurisdiction of such
        courts. The terms set forth in this Agreement and any related service agreements constitute the final, complete
        and exclusive agreement with respect to the ZEONIQ Software and may not be contradicted, explained or
        supplemented
        by evidence of any prior agreement, any contemporaneous oral agreement or any consistent additional terms.
        EVOLOPER may at its sole discretion assign this Agreement to a subsidiary or sister company, without giving
        prior
        notice. YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND UNDERSTAND THE RIGHTS, OBLIGATIONS,
        TERMS
        AND CONDITIONS SET FORTH HEREIN. BY CONTINUING TO INSTALL THE ZEONIQ SOFTWARE, YOU EXPRESSLY CONSENT TO BE BOUND
        BY ITS TERMS AND CONDITIONS AND GRANT TO EVOLOPER THE RIGHTS SET FORTH HEREIN.
      </div>
    </div>
  </div>
</div>
<div class="terms-footer">
  <button class="button btn-secondary" [disabled]="!isScrolledToEnd" (click)="notAgreeTerms()">
    {{'general.not_agree' | translate}}
  </button>
  <button class="button btn-primary" [disabled]="!isScrolledToEnd" (click)="agreeTerms()">
    {{'general.yes_agree' | translate}}
  </button>
</div>
