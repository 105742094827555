/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MerchantResponse { 
    merchantId?: number;
    merchantCode?: string;
    merchantDesc?: string;
    subscriptionId?: string;
    merCatgCode?: string;
    merchantTag?: string;
    contactPerson?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    addCity?: string;
    addPostal?: string;
    countryCode?: string;
    stateCode?: string;
    regionCode?: string;
    areaCode?: string;
    email?: string;
    statementEmails?: string;
    mobileNo?: string;
    phoneNo?: string;
    taxNo?: string;
    statusFlag?: number;
    discoverableFlag?: boolean;
    accessKey?: string;
    logoImageData?: string;
    bannerImageData?: string;
    aboutMerchant?: string;
    showInfoFlag?: boolean;
    showFlag?: boolean;
    showOutletFlag?: boolean;
    webURL?: string;
    latitude?: number;
    longitude?: number;
    walletFlag?: number;
    showMemberFlag?: boolean;
    showOrderFlag?: boolean;
    zeoniqOrderURL?: string;
    orderDineFlag?: boolean;
    orderPickupFlag?: boolean;
    orderDeliveryFlag?: boolean;
    rowVersion?: string;
}