import { Component, Input, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';
import { AuthService } from './../../../core/services/auth.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  standalone: true,
  imports: [NgFor, TranslateModule],
})
export class NavMenuComponent implements OnInit {
  @Input() selectedProducts: any[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}
}

