<button type="button" class="button-renderer" [ngClass]="params.type" [disabled]="disabled"
    (click)="action(params.type, $event)" *ngIf="params.value">
    <img src="assets/base/icons/copy-light.svg" *ngIf="params.type === buttonTypes.duplicate" />
    <img src="assets/base/icons/save.svg" *ngIf="params.type === buttonTypes.save" />
    <img src="assets/base/icons/remove.svg" *ngIf="params.type === buttonTypes.cancelEdit" />
    <div *ngIf="params.type === buttonTypes.saved">
        <img src="assets/base/icons/save.svg"/>
        <p>{{ 'general.saved' | translate }}</p>
    </div>
    <mat-icon *ngIf="params.type === buttonTypes.checkbox">done</mat-icon>
</button>
