<div class="formgroup-inline">
  <div class="position-container" [ngClass]="getContainerClass(positionItem)" *ngFor="let positionItem of positionedItems">
    <div *ngFor="let toolbar of positionItem.item" class="field" style="position: relative">
      <button name="{{ toolbar.name }}" id="{{ toolbar.id }}" class="action-btn" [type]="toolbar.btnType" #trigger
        [ngClass]="{ 'dropdown-toggle': toolbar.dropdownItm && toolbar.dropdownItm.length > 0, 'active': toolbar.buttonActive, 'input': toolbar.input }"
        overlayPanelClass="menu-dropdown"
        [attr.data-bs-toggle]="toolbar.dropdownItm && toolbar.dropdownItm.length > 0? 'dropdown': ''" #menuTrigger="matMenuTrigger" 
        [disabled]="toolbar.disabled" tabindex="0" [matMenuTriggerFor]="toolbar.dropdownItm && toolbar.dropdownItm.length > 0 && !actionToolbarSetting.popup? menu : null"
        (click)="toolbar.dropdownItm && toolbar.dropdownItm.length > 0? $event.preventDefault(): actionButton($event, toolbar);">
        <img [src]="toolbar.icon" [style]="{ 'width': toolbar.iconStyling?.width }" *ngIf="toolbar.icon" />
        <p *ngIf="toolbar.text && !toolbar.input" [style]="toolbar?.styling" [ngClass]="{'btn-dropdown': toolbar.dropdownItm && toolbar.dropdownItm.length > 0}" >
          <span (click)="toolbar.dropdownItm && toolbar.dropdownItm.length > 0? actionButton($event, toolbar, true): null">
            {{ toolbar.text | toolbarNewName | translate }}
          </span>
          <img class="unclickable-icon" src="assets/base/icons/chevron-down.svg" 
            [style]="{ 'width': toolbar.iconStyling?.width }" 
            *ngIf="toolbar.dropdownItm && toolbar.dropdownItm.length > 0" #menuTrigger="matMenuTrigger" 
            [matMenuTriggerFor]="toolbar.dropdownItm && toolbar.dropdownItm.length > 0 && actionToolbarSetting.popup? menu : null"/>
        </p>
        <div class="input-wrapper" *ngIf="toolbar.input" [ngClass]="{'close': inputText}">
          <label>{{ toolbar.text | translate }}</label>
          <input type="text" #input tabindex="0" id="{{ toolbar.name }}" autocomplete="off"
            (input)="onInput($event, toolbar);" (keyup.enter)="actionButton($event, toolbar);">
          <img src="assets/base/icons/remove.svg" *ngIf="inputText && input.value"
            (click)="onClear($event, toolbar)">
        </div>

        <!-- dropdown menu -->
        <mat-menu #menu="matMenu" class="actionToolbarMenu" xPosition="before">
          <button [type]="toolbar.btnType" *ngFor="let item of toolbar.dropdownItm"
            name="{{ item.name }}" [disabled]="item.disabled"
            (click)="actionButton($event, item, true);">
            <mat-icon *ngIf="item.icon" style="margin-right: 8px;">
              <img [src]="item.icon" [style]="{ 'width': item.iconStyling?.width ? item.iconStyling?.width : '20px' }" />
            </mat-icon>
            <label style="font-size: 14px;" *ngIf="item.text">{{ item.text | toolbarNewName | translate }}</label>
          </button>
        </mat-menu>
      </button>
  </div>
  </div>
</div>
