import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { Page } from 'src/app/shared/models/page.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UntypedFormGroup } from '@angular/forms';
import { BackendStoreAttr } from 'src/swagger/cxmbackend/openapi';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { StoreService } from '../../store.service';
import { FormService } from 'src/app/shared/component/form/form.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { Mode } from 'src/app/shared/enums/mode';
import { AttrCode } from 'src/swagger/cxmbackend/openapi/model/attrCode';

@Component({
  selector: 'app-store-attr-entry',
  standalone: true,
  imports: [CommonModule, FormComponent],
  templateUrl: './store-attr-entry.component.html',
  styleUrls: ['./store-attr-entry.component.scss']
})
export class StoreAttrEntryComponent {
  formSetting: FormSetting = {
    formCode: FormCode.storeattrentry,
    content: [
      {
        inputNumber: {
          name: 'storeId',
          label: 'Store ID',
          required: true,
          styling: {
            width: '30%',
          },
          disabled: true
        },
      },
      {
        lookDropInput: {
          name: 'attrCode',
          label: 'Attribute Code',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(AttrCode).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        uploadInput: {
          name: 'attrImage',
          label: 'Attribute Image',
          acceptFormat: 'image/*',
          dataKey: 'attrData',
        }
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  formDetail: any = null;
  @Input() params = null;
  @Input() page: Page;

  storeId: number = undefined;
  attrCode: string = '';
  storeAttrData: any;
  isEditMode: boolean;
  constructor(private authService: AuthService,
    private storeService: StoreService,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private formService: FormService,
    private loaderService: LoaderService,
  ) { }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {

      let params = this.pageService.getParameterDecode(this.page);
      this.storeId = this.params ? this.params['storeId'] : params['storeId'];
      this.attrCode = this.params ? this.params['attrCode'] : params['attrCode'];
    }
    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }

    if (this.storeId && this.attrCode) {
      await this.getStoreAttr(this.storeId, this.attrCode);
    }
    else {
      this.storeAttrData = {
        storeId: this.storeId
      }
    }
  }

  async getStoreAttr(storeId: number, attrCode: string) {
    let result = await this.storeService.getOdataStoreAttr(storeId, attrCode, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.storeAttrData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let formData = this.formService.getFormDataReqBody(formGroup);
    formData.append("storeId", this.storeId);
    if (this.isEditMode) {
      this.updateStoreAttr(formData);
    } else {
      this.addStoreAttr(formData);
    }
  }

  async addStoreAttr(formData: FormData) {
    let result = null;
    this.loaderService.startLoading();
    result = await this.storeService.addStoreAttr(formData, this.formDetail.formCode);
    this.loaderService.stopLoading();

    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);

      let msg: PopupMessage = {
        formCode: FormCode.storeattrlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.new_store_attr_saved',
        desc: "msg.added_new_store_attr",
        actionBtnText: 'general.okay'
      };
      this.pageService.refresh([FormCode.storeattrlisting]);

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });

      // this.pageService.refreshListing$.next(FormCode.storeattrlisting);
    } else {
      console.log(result);
      let msg: PopupMessage = {
        formCode: FormCode.storeattrlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.new_store_attr_unsuccessful',
        desc: result.error.errorCode == "DuplicatedField_409"? "msg.dupe_store_attr" : "msg.system_couldnt_save_store_attr",
        actionBtnText: 'general.back'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }

  }

  async updateStoreAttr(formData: FormData) {
    let result = null;
    this.loaderService.startLoading();
    result = await this.storeService.updateStoreAttr(formData, this.formDetail.formCode);
    this.loaderService.stopLoading();

    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);

      let msg: PopupMessage = {
        formCode: FormCode.storeattrlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.store_attr_updated',
        desc: "msg.updated_new_store_attr",
        actionBtnText: 'general.okay'
      };
      this.pageService.refresh([FormCode.storeattrlisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });

      // this.pageService.refreshListing$.next(FormCode.storeattrlisting);
    } else {
      console.log(result);
      let msg: PopupMessage = {
        formCode: FormCode.storeattrlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.new_store_attr_unsuccessful',
        desc: result.error.errorCode == "NotFound_404"? "msg.store_attr_not_found" : "msg.system_couldnt_save_store_attr",
        actionBtnText: 'general.back'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }

  }
}
