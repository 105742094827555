import { Injectable } from '@angular/core';
import { menuStore } from './menu.store';
import { addEntities, updateEntities, selectAllEntities, selectEntityByPredicate } from '@ngneat/elf-entities';

export interface Menu {
  id: number;
  formCode: string;
  caption: string;
  textId: string;
  icon: string;
  filePath: string;
  badge: string;
  queryString: string;
  isFavourite: boolean;
  menuItems: Menu[];
}

@Injectable({ providedIn: 'root' })
export class MenuRepository {
  items$ = menuStore.pipe(selectAllEntities());

  addItems(items: Menu[]): void {
    menuStore.update(addEntities(items));
  }

  private isEntity(menu: Menu, formCode: string): boolean {
    if (menu.formCode === formCode) {
      return true;
    }

    if (menu.menuItems && menu.menuItems.length > 0) {
      return menu.menuItems.some(item => this.isEntity(item, formCode));
    }

    return false;
  }

  toggleFav(formCode: string): void {
    menuStore.pipe(
      selectEntityByPredicate(entity => this.isEntity(entity, formCode))
    ).subscribe(
      selected => {
        menuStore.update(
          updateEntities(selected.id, { isFavourite: !selected.isFavourite })
        );
      },
      error => {
        console.error('Error occurred:', error);
      }
    );
  }

  updateFavoriteSuccess(id: number, isFavourite: boolean): void {
    menuStore.update(
      updateEntities(id, { isFavourite })
    );
  }
}
