/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SectionShowTimerFlag = 'None' | 'Horizontal_Slider';

export const SectionShowTimerFlag = {
    None: 'None' as SectionShowTimerFlag,
    ShowExpiryTime: 'ShowExpiryTime' as SectionShowTimerFlag
};
