// list of the formCode, refer from the api formCode return.

export enum FormCode {
  merchantentry = 'merchantentry',
  merchantlisting = 'merchantlisting',

  storeentry = 'storeentry',
  storelisting = 'storelisting',
  storeattr = 'storeattr',
  storeattrentry = 'storeattrentry',
  storeattrlisting = 'storeattrlisting',
  storeohentry = 'storeohentry',
  storeohlisting = 'storeohlisting',
  storesetting = 'storesetting',
  storecatgattrlisting = 'storecatgattrlisting',
  storecatgattrentry = 'storecatgattrentry',

  statementlisting = 'statementlisting',
  statemententry = 'statemententry',

  currencyentry = 'currencyentry',
  currencylisting = 'currencylisting',

  salesReport = 'salesReport',
  salesReportListing = 'salesReportListing',

  rptsalesdetails = 'rptsalesdetails',

  orderdetaillisting = 'orderdetaillisting',
  revertcutoff = 'revertcutoff',
  updateorderstatus = 'updateorderstatus',
  activityloglisting = 'activityloglisting',
  syspendingjoblisting = 'syspendingjoblisting',

  invoiceform = 'invoiceform',
  invoiceentry = 'invoiceentry',
  invoiceemailentry = 'invoiceemailentry',
  invoicelisting = 'invoicelisting',

  channelentry = 'channelentry',
  channellisting = 'channellisting',
  channeladdrlisting = 'channeladdrlisting',
  channeladdrentry = 'channeladdrentry',
  channelsectionlisting = 'channelsectionlisting',
  channelsectionentry = 'channelsectionentry',
  channelstorelisting = 'channelstorelisting',
  channelstoreentry = 'channelstoreentry',
  channelstoretaxlisting = 'channelstoretaxlisting',
  channelstoretaxentry = 'channelstoretaxentry',
  storechannelstoretaxlisting = 'storechannelstoretaxlisting',
  storechannelstoretaxentry = 'storechannelstoretaxentry',
  storechannelstorelisting = 'storechannelstorelisting',
  storechannelstoreentry = 'storechannelstoreentry',

  taxconditionhlisting = 'taxconditionhlisting',
  taxconditiondlisting = 'taxconditiondlisting',
  taxconditionflisting = 'taxconditionflisting',
  taxconditionhentry = 'taxconditionhentry',
  taxconditiondentry = 'taxconditiondentry',
  taxconditionfentry = 'taxconditionfentry',
  taxlisting = 'taxlisting',
  taxentry = 'taxentry',
  taxgrouplisting = 'taxgrouplisting',
  taxgroupentry = 'taxgroupentry',

  dealerentry = 'dealerentry',
  dealerlisting = 'dealerlisting',

  catgattroptionlisting = 'catgattroptionlisting',
}
