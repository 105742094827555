<div class="radio-button-container" [formGroup]="formGroup" *ngIf="radioBtnSetting?.item">
    <label class="inline-label" *ngIf="radioBtnSetting.label">{{ radioBtnSetting.label | translate }}</label>
    <div class="container">
        <label class="wrapper" *ngFor="let item of radioBtnSetting.item">
            {{ item.name | translate }}
            <input class="checkbox" type="radio" formControlName="{{ radioBtnSetting.name? radioBtnSetting.name: '' }}" 
                [value]="item.value" [checked]="item.checked">
                <span class="checkmark"></span>
        </label>
    </div>
</div>
