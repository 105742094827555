import { Page } from './../../models/page.model';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { PageService } from '../../services/page.service';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { SystemService } from '../../services/system.service';
import { Menu, MenuRepository } from './../../../state/menu.repository'
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgClass]
})
export class BreadcrumbComponent implements OnInit {
  @Input() currentPage: Page;
  @Input() pageNo: number = 0;
  @Input() pages: Page[];

  menuSets: any;
  menu: Menu[];
  private itemsSubscription: Subscription;

  constructor(private pageService: PageService, private systemService: SystemService, private menuRepository: MenuRepository, private http: HttpClient) { }

  ngOnInit(): void {
    this.itemsSubscription = this.menuRepository.items$.subscribe(items => {
      this.menu = items;
    });
  }

  ngOnDestroy(): void {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }

  isFavourite(formCode: string): boolean {
    const menuItem = this.findMenuItem(formCode);
    return menuItem ? menuItem.isFavourite : false;
  }

  private findMenuItem(formCode: string): Menu | undefined {
    let foundMenuItem: Menu | undefined;
    this.menu.forEach(menu => {
      menu.menuItems.forEach(list => {
        list.menuItems.forEach(item => {
          if (item.formCode === formCode) {
            foundMenuItem = item;
          }
        });
      });
    });
    return foundMenuItem;
  }

  navigate(page: Page) {
    document.getElementById('pageNo-' + page.seq)?.scrollIntoView();
  }

  async favouriteItem(path: string): Promise<void> {
    let menuItemToUpdate: Menu | null = null;
    this.menu.forEach(menu => {
      menu.menuItems.forEach(list => {
        list.menuItems.forEach(item => {
          if (item.filePath === path) {
            menuItemToUpdate = item;
          }
        });
      });
    });

    if (menuItemToUpdate) {
      const updatedIsFavourite = !menuItemToUpdate.isFavourite;
      this.systemService.updateFavourite(menuItemToUpdate.formCode);
      this.menuRepository.updateFavoriteSuccess(menuItemToUpdate.id, updatedIsFavourite);

      // Update local state
      menuItemToUpdate.isFavourite = updatedIsFavourite;

      this.updateLocal();
    }
  }

  updateLocal() {
    localStorage.setItem('selectedMenuSet', JSON.stringify(this.menu));
  }
}
