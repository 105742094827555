import { ReportComponent } from "src/app/project-demo/report/report.component";
import { PageRoute } from "../../enums/pageRoute";
import { PageComponent } from "../../models/pageComponent.model";

export const reportPathComponent: PageComponent[] = [
  {
    filePath: PageRoute.REPORT,
    component: ReportComponent
  },
]
