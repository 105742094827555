import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { LanguageComponent } from '../language/language.component';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, LanguageComponent, TranslateModule,
  ],
})
export class NotificationComponent implements OnInit {
  @Output() closeSidebar = new EventEmitter<void>();

  notificationData = {
    title: 'Notifications',
    data: [
      {
        date: '14/10/2023, 08:47',
        content: 'Sales Detail Report is ready to view now.',
        read: false,
        filePath: 'report/rptsalesdetails?jobId=2346'
      },
      {
        date: '24/03/2022, 08:47',
        content:
          'This is how a standard alert message will look like. It should hold a regular 3 lines. So this is how it would look.',
        read: false,
        link: true
      },
      {
        date: '22/09/2023, 08:47',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut',
        read: false,
        link: false
      },
      {
        date: '1/03/2022, 08:47',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut',
        read: false,
        link: false
      },
      {
        date: '1/03/2022, 08:47',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut',
        read: true,
        link: true
      },
      {
        date: '15/09/2023, 08:47',
        content: 'Testing notification.',
        read: false,
        link: false
      },
      {
        date: '3/03/2022, 08:47',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut',
        read: true,
        link: false
      },
    ],
  };

  constructor(
    public translateService: TranslateService,
    public translateCacheService: TranslateCacheService,
    private pageService: PageService
  ) { }

  groupedNotifications = {};
  sortedDates: string[] = [];

  ngOnInit(): void {
    this.notificationData.data.sort(this.sortByDateDesc);
    this.groupedNotifications = this.groupByDate(this.notificationData.data);

    // Sort keys based on actual dates for displaying purposes
    this.sortedDates = Object.keys(this.groupedNotifications).sort((a, b) => {
      return this.dateForSorting(b) - this.dateForSorting(a);
    });
  }

  dateForSorting(date: string): number {
    if (date === 'Today') {
      return new Date().getTime();
    }
    const dateParts = date.split('/');
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]).getTime();
  }

  sortByDateDesc(a, b) {
    return (
      new Date(b.date.split(',')[0]).getTime() -
      new Date(a.date.split(',')[0]).getTime()
    );
  }

  groupByDate(notifications) {
    return notifications.reduce((grouped, notification) => {
      const rawDate = notification.date.split(',')[0];
      const date = this.formatDate(rawDate);
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(notification);
      return grouped;
    }, {});
  }

  formatDate(rawDate: string): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight

    const dateParts = rawDate.split('/');
    const givenDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

    if (givenDate.getTime() === today.getTime()) {
      return 'Today';
    }
    return rawDate;
  }

  onCloseButtonClick() {
    this.closeSidebar.emit();
  }

  get unreadCount(): number {
    const notificationsArray: any[][] = Object.values(this.groupedNotifications);
    return notificationsArray
      .reduce((acc: any[], val: any[]) => acc.concat(val), [])
      .filter((notification: any) => !notification.read).length;
  }

  selectNotification(notification: any) {
    if (notification) {
      this.pageService.navigateByQueryParams(notification.filePath);
    }
  }
}
