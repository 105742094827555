import { FilterGroupComponent } from './../../filter-group/filter-group.component';
import { Component, Input, Output, EventEmitter, HostListener, OnInit, OnDestroy, SimpleChanges } from "@angular/core";
import { TranslateModule, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomFilterComponent } from "../custom-filter/custom-filter.component";
import { NgIf, NgFor } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PageService } from 'src/app/shared/services/page.service';
import { LowerCasePipe } from "@angular/common";
import { Subscription } from 'rxjs';
import { StrucConvertService } from "src/app/shared/services/struc-convert.service";
import { ReportService } from "src/app/shared/services/report.service";
import { ChangeDetectorRef } from "@angular/core";
import * as _ from 'lodash'; // Import lodash for deep copy
import { ActionToolBarComponent } from '../../action-tool-bar/action-tool-bar.component';
import { ActionToolBarSetting } from '../../action-tool-bar/model/ActionToolBarSetting.model';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-shared-filter',
  templateUrl: './shared-filter.component.html',
  styleUrls: ['./shared-filter.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, SharedModule, TranslateModule, ActionToolBarComponent]
})

export class SharedFilterComponent implements OnInit, OnDestroy {
  private langChangeSubscription: Subscription;

  filter: any;
  queryStringParam: any;
  isMobile: boolean;
  resetDefault: any;
  filterStructure: any;
  defaultQuery: any;
  firstGroup: boolean = false;
  displayGroups?: any;

  @Input() report?: any;
  @Input() customFilter?: any;
  @Input() filterItems?: any;
  @Input() filters?: any;
  @Input() filterReport?: any[];
  @Input() reportData?: any;
  @Input() formCode?: any;
  @Input() selectedGroups?: any;
  @Input() selectionGroups?: any;
  @Input() filterQueryString?: any;
  @Input() firstGroupQuery?: any;
  @Input() isDatePickerMode?: boolean = true;
  @Input() editMode?: boolean = true;
  @Input() queryHTML?: string;
  @Input() defaultFilter?: any;
  @Input() default?: any;
  @Input() reportViewerFilter?: any;
  @Input() filterGrpData?: any;
  @Input() rptJobFilter?: any
  @Input() countBy?: number;
  @Input() actionToolbarSetting: ActionToolBarSetting;

  @Output() onFilterValue = new EventEmitter();
  @Output() onFilterGroupData = new EventEmitter();
  @Output() queryStringPass = new EventEmitter();
  @Output() onActionToolbar = new EventEmitter();

  openRptFilterGrp$: Subscription;

  constructor(public dialog: MatDialog,
    private pageService: PageService,
    private strucConvertService: StrucConvertService,
    public translate: TranslateService,
    private reportService: ReportService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService
  ) {
    this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }

  private checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 991;
  }

  ngOnInit(): void {
    if (this.report) {
      this.openRptFilterGrp$ = this.reportService.openRptFilterGrp$.subscribe(() => this.onClickFilterGrp());
    } else {
      const structureMapping = {
        logic: 'ruleCondition',
        filters: 'rules',
      };

      this.resetDefault = this.customFilter;
      this.filter = this.strucConvertService.reverseNewStructure(this.strucConvertService.removeParent(this.customFilter), this.filterItems, true)
      this.queryStringParam = this.strucConvertService.toQueryStringParam(this.strucConvertService.convertArrayStructure(this.strucConvertService.separateMultiple(this.filter, this.filterItems), structureMapping));
      this.generateQueryString(this.filter)
    }

    this.langChangeSubscription = this.languageService.getLangChangeObservable().subscribe(event => {
      this.translate.setDefaultLang(event.lang);
      this.translate.use(event.lang);
      this.generateQueryString(this.filter)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.report) {
      if (!this.filterQueryString) {
        this.generateQueryString(this.defaultFilter);
      }

      if (changes.queryHTML && changes.queryHTML.currentValue) {
        this.filterQueryString = changes.queryHTML.currentValue;
      }
      if (this.editMode) {
        if (this.reportViewerFilter) {
          const structureMapping = {
            logic: 'ruleCondition',
            filters: 'rules',
          };

          this.filters = this.strucConvertService.addParent(
            this.strucConvertService.convertArrayStructure(
              this.strucConvertService.separateMultiple(
                this.reportViewerFilter,
                this.filterItems
              ),
              structureMapping
            )
          );
          this.filterStructure = this.filters;
          this.generateQueryString(this.reportViewerFilter);

        } else {
          if (changes.defaultFilter) {
            this.defaultFilter = changes.defaultFilter.currentValue;
            const structureMapping = {
              logic: 'ruleCondition',
              filters: 'rules',
            };
            this.filters = this.strucConvertService.addParent(
              this.strucConvertService.convertArrayStructure(
                this.strucConvertService.separateMultiple(changes.defaultFilter.currentValue, this.filterItems),
                structureMapping
              )
            );
            this.filterStructure = this.filters;
            this.generateQueryString(this.defaultFilter);
          } else if (changes.rptJobFilter) {
            this.rptJobFilter = changes.rptJobFilter.currentValue;
            const structureMapping = {
              logic: 'ruleCondition',
              filters: 'rules',
            };
            this.filters = this.strucConvertService.addParent(
              this.strucConvertService.convertArrayStructure(
                this.strucConvertService.separateMultiple(changes.rptJobFilter.currentValue, this.filterItems),
                structureMapping
              )
            );
            this.filterStructure = this.filters;
            this.generateQueryString(this.rptJobFilter);
          }
        }
      } else {
        this.reportViewerFilter = this.strucConvertService.reverseNewStructure(this.reportViewerFilter, this.filterItems, true)
        if (this.reportViewerFilter) {
          const structureMapping = {
            logic: 'ruleCondition',
            filters: 'rules',
          };
          this.filters = this.strucConvertService.addParent(
            this.strucConvertService.convertArrayStructure(
              this.strucConvertService.separateMultiple(
                this.reportViewerFilter, this.filterItems),
              structureMapping
            )
          );
          this.filterStructure = this.filters;
          this.generateQueryString(this.reportViewerFilter)
        }
      }
    }

    if (changes.selectedGroups?.currentValue) {
      let selectedGroups = _.cloneDeep(changes.selectedGroups.currentValue);
      this.displayGroups = selectedGroups?.filter(grp => grp.showTopN);
    }
  }

  translateOption(option: string): string {
    if (option) {
      return this.translate.instant(option);
    } else {
      return option;
    }
  }

  generateQueryString(filterStruc: any) {
    this.filter = filterStruc;
    let queryHTML: string = '';
    let queryString: string = '';
    let queryPlainText: string = '';
    let firstGrpQueryHTML: string = '';

    for (const key in filterStruc) {
      if (filterStruc.hasOwnProperty(key)) {
        const ruleGroup = filterStruc[key];
        const firstGroup = filterStruc[0];

        let firstCondition = firstGroup.ruleCondition;
        let groupSentences: string[] = [];
        let innerQueryString: string = '';
        let innerPlainText: string = '';

        if (ruleGroup.rules) {
          ruleGroup.rules.forEach((rule, j) => {
            let field = this.translateOption('general.' + rule.field);
            let op = this.translateOption('general.' + rule.operator);
            let value;

            if (rule.value === null) {
              value = '';
            } else {
              let transValue = rule.value;
              if (typeof transValue === 'string' && transValue.includes(', ')) {
                let values = transValue.split(', ');
                let translatedValues: string[] = [];

                values.forEach(value => {
                  translatedValues.push(this.translateOption(value));
                });
                value = translatedValues.join(', ');
              } else if (typeof transValue === 'number') {
                value = transValue;
              } else if (Array.isArray(rule.value)) {
                value = rule.value.join(', ');
              } else {
                value = this.translateOption(transValue)
              }
            }

            groupSentences.push(
              `${field} <span style="font-weight: bold;">${op.toUpperCase()}</span> ${value}`
            );

            innerQueryString += `${field} ${op.toUpperCase()} ${value}`;
            innerPlainText += `${field} ${op.toUpperCase()} ${value}`;

            if (j !== ruleGroup.rules.length - 1) {
              const groupStyle = `color: ${ruleGroup.ruleCondition === 'AND' ? '#FC5555' : '#0DCAF0'
                };`;

              groupSentences.push(
                `<span style="${groupStyle};">${ruleGroup.ruleCondition}</span>`
              );

              innerQueryString += ` ${ruleGroup.ruleCondition} `;
              innerPlainText += ` ${ruleGroup.ruleCondition} `;
            }
          });
        }

        const firstGroupStyle = `color: ${firstCondition === 'AND' ? '#FC5555' : '#0DCAF0'};`;

        let firstGrp;
        const groupSentence = groupSentences.join(' ');
        if (parseInt(key) === 0) {
          firstGrp = groupSentence;
        }

        queryHTML += `<span style="font-family: sofia-pro; background-color: #e9e9e9; padding: 4px 10px; border-radius: 8px; max-width: fit-content; font-size: 14px;">${groupSentence}</span>`;
        if (firstGrp) {
          firstGrpQueryHTML += `<span style="font-family: sofia-pro; background-color: #e9e9e9; padding: 4px 10px; border-radius: 8px; max-width: fit-content; font-size: 14px;">${firstGrp}</span>`;
        }

        if (parseInt(key) < Object.keys(filterStruc).length - 1) {
          queryHTML += `<span style="font-family: sofia-pro; margin: 5px; max-width: fit-content; font-size: 14px; ${firstGroupStyle}">${firstCondition}</span>`;
          queryString += `(${innerQueryString}) ${firstCondition} `;
          queryPlainText += `(${innerPlainText}) ${firstCondition} `;
        } else if (parseInt(key) > 0) {
          queryString += `(${innerQueryString})`;
          queryPlainText += `(${innerPlainText})`;
        } else {
          queryString = innerQueryString;
          queryPlainText = innerPlainText;
        }
      }
    }

    this.firstGroupQuery = firstGrpQueryHTML
    this.filterQueryString = queryHTML;
    if (this.report) {
      this.queryStringPass.emit(queryPlainText);
    }
  }

  openStatement(event: Event) {
    if (this.filter && this.filter.length - 1 > 0) {
      event.stopPropagation();
      this.firstGroup = !this.firstGroup;
    }
  }

  getData2Array(value: any): any[] {
    return Array.isArray(value) ? value : [value];
  }

  isIterable(value: any): boolean {
    return (
      Array.isArray(value) || (typeof value === 'object' && value !== null)
    );
  }

  restructure() {
    const structureMapping = {
      ruleCondition: 'logic',
      rules: 'filters',
    };

    this.filters = this.strucConvertService.convertArrayStructure(
      this.strucConvertService.combineMultiple(this.strucConvertService.removeParent(this.filterStructure), this.filterItems),
      structureMapping
    );
    this.generateQueryString(this.filters)
  }

  onClickFilter(event: Event) {
    const dialogRef = this.dialog.open(CustomFilterComponent, {
      height: '100%',
      maxWidth: '100%',
      width: '100%',
      panelClass: 'fullWidth',
      hasBackdrop: true,
      backdropClass: 'filter-dialog-container',
      data: {
        filters: _.cloneDeep(this.filter), // Deep copy the filters
        filterItems: _.cloneDeep(this.filterItems), // Deep copy the filterItems
        editMode: true,
        isStandalone: true,
        isMobile: this.isMobile,
        filterTitle: this.pageService.getCurrentPage()
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const structureMapping = {
          ruleCondition: 'logic',
          rules: 'filters',
        };

        // get filter query string and display
        this.filterQueryString = data.filterQueryString;
        this.filter = this.strucConvertService.convertArrayStructure(
          this.strucConvertService.combineMultiple(this.strucConvertService.removeParent(data.filter), this.filterItems),
          structureMapping
        );
        this.queryStringParam = this.strucConvertService.toQueryStringParam(this.strucConvertService.removeParent(data.filter));
        if (data.generate) {
          this.generateFilter();
        }
        this.generateQueryString(this.filter);
      }
    });
  }

  onClickFilterGrp(event?: Event) {
    event.stopPropagation();
    this.restructure();
    const dialogRef = this.dialog.open(FilterGroupComponent, {
      height: '100vh',
      maxWidth: '100vw',
      width: '100vw',
      panelClass: 'fullWidth',
      hasBackdrop: false,
      data: {
        filters: this.filters,
        reportData: this.reportData,
        formCode: this.formCode,
        selectedGroups: this.selectedGroups,
        selectionGroups: this.selectionGroups,
        filterItems: this.filterItems,
        editMode: this.editMode,
        defaultFilter: this.defaultFilter,
        isMobile: this.isMobile,
        countBy: this.countBy
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      // get filter query string and selected groups and display
      if (data) {
        this.filterQueryString = data.filterQueryString
        this.filter = data.filter;
        this.firstGroupQuery = data.firstGroupQuery;
        this.filterStructure = data.filterStructure;
        this.selectedGroups = data?.selectedGroups;
        this.countBy = data?.countBy;
      }
      this.onFilterGroupData.emit(data);
    });
  }

  onToolbarAction(event: any) {
    this.onActionToolbar.emit(event);
  }

  generateFilter() {
    this.onFilterValue.emit(decodeURIComponent(`$filter=${this.queryStringParam}`));
  }

  reset() {
    if (this.report) {
      this.reportViewerFilter = null;
      const structureMapping = {
        logic: 'ruleCondition',
        filters: 'rules',
      };
      this.generateQueryString(this.default);
      this.filters = this.strucConvertService.addParent(this.strucConvertService.convertArrayStructure(
        this.strucConvertService.separateMultiple(this.default, this.filterItems),
        structureMapping
      ));
      this.filterStructure = this.filters;
    } else {
      const structureMapping = {
        logic: 'ruleCondition',
        filters: 'rules',
      };
      this.filter = this.strucConvertService.reverseNewStructure(this.strucConvertService.removeParent(this.resetDefault), this.filterItems, true);
      this.queryStringParam = this.strucConvertService.toQueryStringParam(this.strucConvertService.convertArrayStructure(this.strucConvertService.separateMultiple(this.filter, this.filterItems), structureMapping));
      this.generateQueryString(this.filter);
    }
  }

  ngOnDestroy(): void {
    this.openRptFilterGrp$?.unsubscribe();
    this.langChangeSubscription?.unsubscribe();
  }
}
