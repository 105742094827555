import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { form } from 'src/app/core/models/form';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { ActionToolBarComponent } from '../../../shared/component/action-tool-bar/action-tool-bar.component';
import { CommonModule, NgIf } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MatIconModule } from '@angular/material/icon';
import { FormService } from 'src/app/shared/component/form/form.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    ActionToolBarComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    InputTextModule,
    RadioButtonModule,
    CheckboxModule,
    FormComponent,
    TranslateModule,
    CalendarModule,
    InputSwitchModule,
    MatIconModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatButtonModule,
  ],
})
export class CustomFormComponent implements OnInit {
  formDetail?: form;
  formGroup: UntypedFormGroup;
  requiredFormError: boolean = false;

  userFormGroup: FormGroup = this.formBuilder.group({
    description: new FormControl(''),
    category: new FormControl(''),
    weekdays: new FormControl(''),
    startTime: new FormControl(''),
    endTime: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    hideSchedule: new FormControl(false),
    hideDescription: new FormControl(false),
  });

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm,
  };

  categories = [
    { key: 'daily', name: 'daily' },
    { key: 'weekly', name: 'weekly' },
    { key: 'monthly', name: 'monthly' },
    { key: 'yearly', name: 'yearly' },
  ];
  weekday = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  selectedCategory: any;
  weekdays = [
    { key: '1', name: 'Mon' },
    { key: '2', name: 'Tue' },
    { key: '3', name: 'Wed' },
    { key: '4', name: 'Thu' },
    { key: '5', name: 'Fri' },
    { key: '6', name: 'Sat' },
    { key: '7', name: 'Sun' },
  ];

  selectedCategories: any;
  selectedWeekday: any;
  startDate: any;
  endDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
  ) {}

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});
    this.formDetail = this.formService.getFormDetail(
      PageRoute.FRAME_CUSTOM_FORM
    );
  }

  onSubmit() {
    this.formService.markAllAsTouched(this.userFormGroup);
  }

  onActionButton(value: ActionToolBarResp) {}

  getFormGrpCtrl(formGroupName: string) {
    return this.formService.getFormGroupCtrl(this.userFormGroup, formGroupName);
  }
}
