import { Component, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { environment } from 'src/environments/environment';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { Router } from '@angular/router';
import { FormService } from 'src/app/shared/component/form/form.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import {
  InterfaceType,
  TaxConditionHService as TaxConditionHAPIService,
  TaxConditionHResponse,
  ErrorCode,
} from 'src/swagger/base/openapi';
import { DropdownOption } from 'src/app/shared/component/input/model/DropdownOption.model';
import { BackendTaxConditionH, CurrencyService, StoreRegisterstoretwoBody } from 'src/swagger/cxmbackend/openapi';
import { TimeZone } from 'src/app/shared/enums/timeZone';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { TaxService } from '../../tax.service';
import { TaxType } from 'src/swagger/base/openapi/model/taxType';
import { TaxTypeFlag } from 'src/swagger/base/openapi/model/taxTypeFlag';
import { LookupTaxGroupComponent } from 'src/app/cxm-backend-web/lookup/lookup-taxgroup/lookup-taxgroup.component';

@Component({
  selector: 'app-tax-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, TranslateModule],
  templateUrl: './tax-entry.component.html',
  styleUrls: ['./tax-entry.component.scss'],
  providers:[CurrencyService]
})
export class TaxEntryComponent {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  taxCode: string = '';
  formGroup: FormGroup;
  taxData: any;
  masterData: any;

  pageSub$: Subscription;

  interfaceTypeOption: any = Object.keys(InterfaceType).map((key) => { return { name: key, value: InterfaceType[key] } });

  RegionTimeZone: {[key: string]: string} = {
    'Singapore Standard Time (UTC+08:00)': 'Singapore Standard Time',
    }

  formSetting: FormSetting = {
    formCode: FormCode.taxconditionhentry,
    content: [
      {
        inputText: {
          name: 'taxCode',
          label: 'Tax Code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 5,
          editable: false,
        },
      },
      {
        inputText: {
          name: 'taxDesc',
          label: 'Description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 254,
        },
      },
      {
        inputText: {
          name: 'shortDesc',
          label: 'Short Description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 50,
        },
      },
      {
        inputText: {
          name: 'taxSymbol',
          label: 'Tax Print Symbol',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 5,
        },
      },
      {
        lookDropInput: {
          name: 'typeFlag',
          label: 'Tax Type',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(TaxTypeFlag).map((key) => {
            return { name: TaxTypeFlag[key], value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
          editable: true,

        },
      },
      {
        toggleInput: {
          name: 'adjFlag',
          label: 'Adjustment Flag',
          required: false,
          styling: {
            width: '30%',
          },
        },
      },
      {
        lookDropInput: {
          componentName: LookupTaxGroupComponent,
          name: 'taxGroupCode',
          label: 'Tax Group Code',
          valueKey: 'taxGroupCode',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'displayNote',
          label: 'Display Note',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 254,
        },
      },
    ],
  };

  actionToolbarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private customService: CustomService,
    private TaxConditionHApiService: TaxConditionHAPIService,
    private authService: AuthService,
    private pageService: PageService,
    public dialog: MatDialog,
    private taxService: TaxService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private formService: FormService,


  ) {
    this.pageSub$ = this.pageService.pageSubject$.subscribe(async pages => {
      this.pages = pages;

      if (this.page) {
        let foundPage = this.pages.find(page => page.form.filePath === this.page.form.filePath);
        if (foundPage) {
          this.taxCode = foundPage.listingId;
          if (this.taxCode) {
            await this.getTax(this.taxCode);
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(FormCode.taxentry);


    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.taxCode = params['taxCode'];
      if (this.taxCode) {
        await this.getTax(this.taxCode.toString());
      }
      else {
        this.taxData =
        {
          typeFlag: 'T'
        };
      }
      if (!this.taxData.displayNote) {
        this.taxData.displayNote = 'N/A'
      }
    }

  }


  async getTax(taxCode: string) {
    let result = await this.taxService.getOdataTax(taxCode, null, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.taxData = result['value'][0];
    }
  }

  async addTax(reqBody: any) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.tax.tax.AddTax,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: this.formDetail.formCode
      }
    } as CustomRequest;

    let data = null;
    this.loaderService.startLoading();
    data = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    if (data instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.tax.create.success.title',
        desc: 'msg.tax.tax.create.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxlisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(data);
      let msg: PopupMessage = {
        formCode: FormCode.taxlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.tax.create.fail.title',
        desc: data.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  async updateTax(reqBody: any) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.tax.tax.UpdateTax,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: this.formDetail.formCode
      }
    } as CustomRequest;

    let data = null;
    this.loaderService.startLoading();
    data = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    if (data instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.tax.update.success.title',
        desc: 'msg.tax.tax.update.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxlisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(data);
      let msg: PopupMessage = {
        formCode: FormCode.taxlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.tax.update.fail.title',
        desc: data.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }
  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
  onGenerateInput(event: any) {
    // call generate api
    let generatedCode = 'code';
    event.formGrp.controls[event.setting.name].setValue(generatedCode);
  }
  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody = this.formService.getReqBody(formGroup);
    if (this.taxCode) {
      this.updateTax(reqBody);
    } else {
      this.addTax(reqBody);
    }
  }



  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy() {
    this.pageSub$.unsubscribe();
  }
}
