/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InvoiceResponse { 
    invoiceId?: number;
    invoiceNo?: string;
    merchantId?: number;
    storeId?: number;
    invoiceDate?: Date;
    startDate?: Date;
    endDate?: Date;
    merchantDesc?: string;
    storeDesc?: string;
    contactPerson?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    addCity?: string;
    addPostal?: string;
    countryCode?: string;
    stateCode?: string;
    email?: string;
    mobileNo?: string;
    phoneNo?: string;
    taxNo?: string;
    bankName?: string;
    bankAccountNo?: string;
    bankAccountName?: string;
    tranCount?: number;
    merchantDiscAmt?: number;
    platformDiscAmt?: number;
    collectionAmt?: number;
    merchantTaxAmt?: number;
    merchantAdjAmt?: number;
    revenueAmt?: number;
    commSalesAmt?: number;
    commPerc?: number;
    commAmt?: number;
    commTaxAmt?: number;
    payableAmt?: number;
    salesAdjAmt?: number;
    subscriptionAmt?: number;
    netPayableAmt?: number;
    outputURL?: string;
    versionNo?: number;
    invoiceStatusFlag?: number;
    invoiceLine?: number;
    statementId?: number;
    salesAdjId?: number;
    statementStartDate?: Date;
    statementEndDate?: Date;
    statementTranCount?: number;
    statementMerchantDiscAmt?: number;
    statementPlatformDiscAmt?: number;
    statementCollectionAmt?: number;
    statementMerchantTaxAmt?: number;
    statementMerchantAdjAmt?: number;
    statementRevenueAmt?: number;
    statementCommSalesAmt?: number;
    statementCommPerc?: number;
    statementCommAmt?: number;
    statementCommTaxAmt?: number;
    statementPayableAmt?: number;
    statusFlag?: string;
    lastRevDate?: Date;
}