<!-- web view header -->
<div class="main-container">
  <header class="page-header">
    <div class="header-content">
      <img src="assets/base/images/ZeoniqLogo-R.svg" class="logo hidden-sm">
      <h1>Select Subscription</h1>
      <p-dropdown [options]="status" [(ngModel)]="selectedStatus" optionLabel="label" styleClass="custom-dropdown-style"
        panelStyleClass="custom-dropdown-panel-style" class="hidden-sm" (onChange)="filterSubscriptions()">
        <ng-template pTemplate="selectedItem">
          <div><span style="opacity: 0.4;">Display: </span>{{selectedStatus.label}}</div>
        </ng-template>
      </p-dropdown>

      <div class="search-bar hidden-sm">
        <input type="text" pInputText [(ngModel)]="searchSub" placeholder="Search my subscriptions"
          class="search-sub-input" (input)="filterSubscriptions()">
      </div>

      <div class="sign-out-panel">
        <button mat-icon-button class="sign-out-container" (click)="logout()">
          <img src="assets/base/icons/sign-out.svg" class="sign-out-icon" />
        </button>
      </div>
    </div>
  </header>
</div>

<ng-lottie *ngIf="!subscriptionInfoList" containerClass="lottie-center-style"
  [options]="{ path: '/assets/base/animations/loading-animation.json' }"></ng-lottie>

<div class="sub-container" (scroll)="onScroll($event)" *ngIf="subscriptionInfoList">
  <!-- mobile view header -->
  <div class="hidden-lg hidden-md sub-header">
    <input type="text" pInputText [(ngModel)]="searchSub" placeholder="Search my subscriptions" class="search-sub-input"
      (input)="filterSubscriptions()">
    <p-dropdown [options]="status" [(ngModel)]="selectedStatus" (onChange)="filterSubscriptions()" optionLabel="label"
      styleClass="custom-dropdown-style" panelStyleClass="custom-dropdown-panel-style" class="mobile-filter">
      <ng-template pTemplate="selectedItem">
        <div><span style="opacity: 0.4;">Display: </span>{{selectedStatus.label}}</div>
      </ng-template>
    </p-dropdown>
  </div>

  <!-- <div class="grid responsive-panel" appScrollNearEnd (nearEnd)="onNearEndScroll()" (scroll)="divScroll($event)"> -->
  <div class="grid responsive-panel" [ngClass]="{'empty-fixed-height': subscriptionDisplayList.length == 0}">
    <div *ngFor="let subscription of subscriptionDisplayList" [ngClass]="{'subscription-card-panel': true}">
      <app-subscription-card [subscription]="subscription"
        (selectSubscription)="onSelectSubscription(subscription)"></app-subscription-card>
    </div>

    <!-- empty layout -->
    <div class="center-error-view" *ngIf="showWarningPage">
      <img src="assets/base/images/bx_error.png" />
      <h1 style="font-size: 36px; font-weight: 500; text-align: center;">
        Your account has no user subscriptions.
      </h1>
      <h1 style="font-size: 18px; margin-top: 24px; color: #9393A3">
        Please contact Zeoniq Admin or Support.
      </h1>
    </div>
  </div>
</div>
