<div class="autogenerate-input-layout" [formGroup]="formGroup" [style]="{ 'width': autoGenerateInputSetting.styling?.width }">
    <mat-form-field>
        <mat-label class="mat-label">{{ autoGenerateInputSetting.label | translate }}</mat-label>
        <input matInput placeholder="{{ autoGenerateInputSetting.placeholder | translate }}" required="{{ autoGenerateInputSetting.required? autoGenerateInputSetting.required: false }}"
        formControlName="{{ autoGenerateInputSetting.name }}"  id="{{ autoGenerateInputSetting.id? autoGenerateInputSetting.id: '' }}"
        (input)="generateErr = false;">
        <mat-icon-button *ngIf="formGroup.controls[autoGenerateInputSetting.name].value || generateErr" matSuffix mat-icon-button aria-label="Indicator" aria-disabled="true" tabindex="-1">
            <mat-icon *ngIf="formGroup.controls[autoGenerateInputSetting.name].status === 'INVALID' || generateErr" class="indicator-icon invalid-icon">error_outline</mat-icon>
            <mat-icon *ngIf="formGroup.controls[autoGenerateInputSetting.name].status === 'VALID'" class="indicator-icon valid-icon">check_circle_outline</mat-icon>
        </mat-icon-button>
        <mat-error *ngIf="generateErr">{{ 'msg.failed_to_call_api' | translate }}</mat-error>
        <mat-error *ngIf="formGrpCtrl?.errors?.required">{{autoGenerateInputSetting.label | translate}} {{'msg.is_required' | translate}}</mat-error>
    </mat-form-field>
    <button type="button" (click)="onGenerate()" *ngIf="!formData">{{ 'general.generate' | translate }}</button>
</div>
