import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { ColDef, IRowNode } from 'ag-grid-community';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowSelection } from 'src/app/shared/enums/rowSelection';
import { PageService } from 'src/app/shared/services/page.service';
import { Page } from 'src/app/shared/models/page.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { StoreService } from "src/app/cxm-backend-web/store/store.service";
import { Subscription } from 'rxjs';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { Mode } from 'src/app/shared/enums/mode';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';

@Component({
  selector: 'app-store-cat-attr',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './store-cat-attr.component.html',
  styleUrls: ['./store-cat-attr.component.scss'],
})
export class StoreCatAttrComponent {

  page: Page;
  rowData: any;
  storeId: any;
  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  refresh$: Subscription;
  popupMsg$: Subscription;
  formDetail: any = null;

  route: any = PageRoute.STORE_CAT_ATTR_ENTRY;
  selectedRowData: any;

  colDef: ColDef[] = [
    listingCheckbox,
    {
      ...listingRouterLink,
      field: 'storeId',
      headerName: 'Store Id',
      cellRendererParams: {
        inRouterLink: {
          route: this.route,
          params: ['storeId', 'attrOptionId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      }
    },
    {
      ...listingRouterLink,
      field: 'attrOptionId',
      headerName: 'storeCatgAttr.store_catg_Attr_id',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        inRouterLink: {
          route: this.route,
          params: ['storeId', 'attrOptionId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      }
    },
    {
      ...listingRouterLink,
      field: 'attrCode',
      headerName: 'storeCatgAttr.store_catg_attr_code',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        inRouterLink: {
          route: this.route,
          params: ['storeId', 'attrOptionId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      }
    },
    {
      ...listingRouterLink,
      field: 'attrOptionDesc',
      headerName: 'storeCatgAttr.store_catg_desc',
      filter: true,
      initialFlex: 1,
      cellRendererParams: {
        inRouterLink: {
          route: this.route,
          params: ['storeId', 'attrOptionId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      }
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'new', icon: 'assets/base/icons/plus.svg', text: 'toolbar.new', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 1  },
      { name: 'edit', icon: 'assets/base/icons/pencil.svg', text: 'toolbar.edit', checkPermission: [AttributeCode.EDIT], collapseTo: 'others', sortNo: 2  },
      { name: 'delete', icon: 'assets/base/icons/trash.svg', text: 'toolbar.delete', checkPermission: [AttributeCode.DELETE], collapseTo: 'others', sortNo: 3  },
    ],
    position: ActionToolbarPosition.RIGHT
  };

  listingSetting: ListingSetting = {
    rowSelection: RowSelection.single,
    rowModelType: RowModelType.clientSide
  };

  constructor(
    private pageService: PageService,
    private storeService: StoreService,
    private dialog: MatDialog,
    private formService: FormService
  ) { }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    let params = this.pageService.getParameterDecode(this.page);
    this.storeId = params['storeId'];
    this.getStoreCatAttr();


    // Listen to refresh event
    this.refresh$ = this.pageService.refreshListing$.subscribe(async (formCode: any) => {
      if (formCode.includes(this.page.form.formCode)) {
        await this.getStoreCatAttr();
      }
    });

    // Listen to popup message event
    this.popupMsg$ = this.pageService.actionPopupMsg$.subscribe(async (data: any) => {
      if (data.formCode === this.page.form.formCode && data.id === 'success') {
        await this.getStoreCatAttr();
      }
    });
  }

  async getStoreCatAttr() {
    this.rowData = (await this.storeService.getOdataStoreCatgAttrData(this.storeId, null, this.formDetail.formCode))['value'];
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if(value.name === 'new'){
      this.pageService.navigateByUrl(PageRoute.STORE_CAT_ATTR_ENTRY, {
        storeId: this.storeId,
      }, null, true);
    }else if(value.name === 'edit'){
      this.pageService.navigateByUrl(PageRoute.STORE_CAT_ATTR_ENTRY, {
        storeId: this.storeId,
        attrOptionId: this.selectedRowData && this.selectedRowData.data['attrOptionId'] ? this.selectedRowData && this.selectedRowData.data['attrOptionId'] : '',
      });
    }
    else if(value.name === 'delete' && this.selectedRowData && this.selectedRowData.data['attrOptionId']){
      let result = null;
      result = await this.storeService.deleteStoreCatgAttr(this.storeId, this.selectedRowData.data['attrOptionId'], this.formDetail.formCode);
      if (result instanceof HttpErrorResponse === false) {
        this.getStoreCatAttr();
        this.selectedRowData.setSelected(false); //remove selected node
      }else {
        let msg: PopupMessage = {
          formCode: FormCode.storecatgattrlisting,
          id: 'failed',
          titleIcon: 'assets/base/icons/exclamation-circle.svg',
          title: result.error.errorData[0].errorField ? result.error.errorData[0].errorField : 'msg.new_store_catg_attr_unsuccessful',
          desc: result.error.title ? result.error.title : 'msg.duplicate_store_catg_attr',
          actionBtnText: 'popupMsg.closed_msg'
        };

        this.dialog.open(PopupMessageComponent, {
          data: msg
        });
      }
    }
  }

  onRowSelect(nodes: IRowNode[]) {
    // single selection so select only first data
    this.selectedRowData = nodes[0]? nodes[0]: null;
  }

  onPageSizeChange(pageSize: number) { }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
    this.popupMsg$?.unsubscribe();
  }
}
