/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackendMasterDataChannel { 
    channelId?: number;
    channelTag?: string;
    channelDesc?: string;
    platformCode?: string;
    statusFlag?: number;
    homeChannelId?: number;
    dineFlag?: boolean;
    pickupFlag?: boolean;
    deliveryFlag?: boolean;
    channelExpiryDate?: Date;
    joinMode?: number;
    custExpiryFlag?: number;
    custExpiryValue?: number;
    custExpiryDate?: Date;
    addrSysFlag?: number;
    coverageKM?: number;
    webDocGroup?: string;
    webDocCode?: string;
    regKey?: string;
    latitude?: number;
    longitude?: number;
    countryCode?: string;
    imageLink?: string;
    backImageLink?: string;
    backColor?: string;
    foreColor?: string;
    filterMinVal?: number;
    filterMaxVal?: number;
    techChrgPerc?: number;
    paymentChrgPerc?: number;
    channelChrgPerc?: number;
    loyaltyChrgPerc?: number;
    timeZone?: string;
}