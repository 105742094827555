<div class="property-grid-layout">
    <div class="content-layout">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmitGrid()">
            <app-action-tool-bar class="toolbar-container" [actionToolbarSetting]="actionToolbarSetting" (onAction)="onActionToolbar($event)"></app-action-tool-bar>
            <div class="property-grid-container" [hidden]="!rowData">
                <app-tree class="menu-container" [treeData]="treeData" [setting]="menuSetting" [selectedNode]="selectedNode" *ngIf="treeData" (onSelectionChanged)="onSelectionChanged($event)"></app-tree>
                <div class="form-container">
                    <ag-grid-angular class="ag-theme-alpine property-grid" domLayout="autoHeight"
                    (gridReady)="onGridReady($event)" (cellFocused)="onCellFocused($event)" (columnResized)="onColumnResized()"
                    (filterChanged)="onFilterChanged($event)"
                    [defaultColDef]="defaultColDef" [rowData]="rowData? rowData: []" [columnDefs]="colDef" [tabToNextCell]="tabToNextCell"
                    [groupDisplayType]="'groupRows'" [suppressRowTransform]="true" [groupDefaultExpanded]="1" [groupRowRendererParams]="groupRowRendererParams"></ag-grid-angular>
                </div>
            </div>
            <div class="emptySetting" *ngIf="!rowData">
                <p>{{ 'systemsetting.select_parameters' | translate }}</p>
            </div>
        </form>
    </div>
</div>

