import { Pipe, PipeTransform } from '@angular/core';
import { FormCode } from '../enums/formCode';

@Pipe({
    name: 'toolbarNewName',
    standalone: true
})
export class ToolbarNewNamePipe implements PipeTransform {

  transform(value: any): string {
    let toolbarNewKey: string = '';
    if (value === FormCode.storelisting) {
      toolbarNewKey = 'toolbar.new_store';
    } else if (value === FormCode.merchantlisting) {
      toolbarNewKey = 'toolbar.new_merchant';
    } else if (value === FormCode.storeattrlisting) {
      toolbarNewKey = 'toolbar.new_store_attr';
    } else if (value === FormCode.currencylisting) {
      toolbarNewKey = 'toolbar.new_currency';
    } else if (value === FormCode.invoicelisting) {
      toolbarNewKey = 'toolbar.new.invoice';
    } else if (value === FormCode.channellisting) {
      toolbarNewKey = 'toolbar.new_channel';
    } else if (value === FormCode.taxconditionhlisting) {
      toolbarNewKey = 'toolbar.new_taxconditionh';
    } else if (value === FormCode.taxlisting) {
      toolbarNewKey = 'toolbar.new_tax';
    } else if (value === FormCode.taxgrouplisting) {
      toolbarNewKey = 'toolbar.new_taxgroup';
    } else if (value === FormCode.dealerlisting) {
      toolbarNewKey = 'toolbar.new_dealer';
    } else if (value === FormCode.storecatgattrlisting){
      toolbarNewKey = 'toolbar.new_store_cat_attr';
    }
    else {
      toolbarNewKey = value;
    }
    return toolbarNewKey;
  }

}
