export * from './country.service';
import { CountryService } from './country.service';
export * from './currency.service';
import { CurrencyService } from './currency.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './customerCatg1.service';
import { CustomerCatg1Service } from './customerCatg1.service';
export * from './customerCatg2.service';
import { CustomerCatg2Service } from './customerCatg2.service';
export * from './customerCatg3.service';
import { CustomerCatg3Service } from './customerCatg3.service';
export * from './demo.service';
import { DemoService } from './demo.service';
export * from './gender.service';
import { GenderService } from './gender.service';
export * from './job.service';
import { JobService } from './job.service';
export * from './locCategory.service';
import { LocCategoryService } from './locCategory.service';
export * from './locCategory2.service';
import { LocCategory2Service } from './locCategory2.service';
export * from './locCategory3.service';
import { LocCategory3Service } from './locCategory3.service';
export * from './locGroup.service';
import { LocGroupService } from './locGroup.service';
export * from './location.service';
import { LocationService } from './location.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './orderPlatform.service';
import { OrderPlatformService } from './orderPlatform.service';
export * from './posOrderType.service';
import { PosOrderTypeService } from './posOrderType.service';
export * from './posPromotionH.service';
import { PosPromotionHService } from './posPromotionH.service';
export * from './posTender.service';
import { PosTenderService } from './posTender.service';
export * from './posTerminal.service';
import { PosTerminalService } from './posTerminal.service';
export * from './posUser.service';
import { PosUserService } from './posUser.service';
export * from './prodBrand.service';
import { ProdBrandService } from './prodBrand.service';
export * from './prodBrandGroup.service';
import { ProdBrandGroupService } from './prodBrandGroup.service';
export * from './prodCategory.service';
import { ProdCategoryService } from './prodCategory.service';
export * from './prodCategory2.service';
import { ProdCategory2Service } from './prodCategory2.service';
export * from './prodCategory3.service';
import { ProdCategory3Service } from './prodCategory3.service';
export * from './prodClass.service';
import { ProdClassService } from './prodClass.service';
export * from './prodColor.service';
import { ProdColorService } from './prodColor.service';
export * from './prodDepartment.service';
import { ProdDepartmentService } from './prodDepartment.service';
export * from './prodItem.service';
import { ProdItemService } from './prodItem.service';
export * from './prodProductGroup.service';
import { ProdProductGroupService } from './prodProductGroup.service';
export * from './prodSeason.service';
import { ProdSeasonService } from './prodSeason.service';
export * from './prodSize.service';
import { ProdSizeService } from './prodSize.service';
export * from './prodSizeGroup.service';
import { ProdSizeGroupService } from './prodSizeGroup.service';
export * from './rMSSalesReport.service';
import { RMSSalesReportService } from './rMSSalesReport.service';
export * from './race.service';
import { RaceService } from './race.service';
export * from './reportViewer.service';
import { ReportViewerService } from './reportViewer.service';
export * from './sellPriceType.service';
import { SellPriceTypeService } from './sellPriceType.service';
export * from './stkReq.service';
import { StkReqService } from './stkReq.service';
export * from './subscription.service';
import { SubscriptionService } from './subscription.service';
export * from './subscriptionUser.service';
import { SubscriptionUserService } from './subscriptionUser.service';
export * from './sysFormSet.service';
import { SysFormSetService } from './sysFormSet.service';
export * from './system.service';
import { SystemService } from './system.service';
export const APIS = [CountryService, CurrencyService, CustomerService, CustomerCatg1Service, CustomerCatg2Service, CustomerCatg3Service, DemoService, GenderService, JobService, LocCategoryService, LocCategory2Service, LocCategory3Service, LocGroupService, LocationService, NotificationService, OrderPlatformService, PosOrderTypeService, PosPromotionHService, PosTenderService, PosTerminalService, PosUserService, ProdBrandService, ProdBrandGroupService, ProdCategoryService, ProdCategory2Service, ProdCategory3Service, ProdClassService, ProdColorService, ProdDepartmentService, ProdItemService, ProdProductGroupService, ProdSeasonService, ProdSizeService, ProdSizeGroupService, RMSSalesReportService, RaceService, ReportViewerService, SellPriceTypeService, StkReqService, SubscriptionService, SubscriptionUserService, SysFormSetService, SystemService];
