<div class="col-12" *ngIf="formDetail">
  <app-filter-custom [filters]="filters" [gotEmail]="gotEmail" [gotRemove]="gotRemove" [value]="defaultFilterValue" (onFilterValue)="onFilter($event)" (onDeleteValue)="onDelete()" (onEmailValue)="onEmail()"></app-filter-custom>
  <div class="scrollable-container">
    <div class="ag-grid-table-container" [ngClass]="formDetail.formCode" #outletList>
      <div class="action-msg-bar">
        <div class="message">
          <app-message [msgs]="" [msgType]=""></app-message>
        </div>
        <div class="action-tool-bar">
          <app-action-tool-bar [formDetail]="formDetail" [isRowSelected]="isRowSelected"
            (onAction)="onToolbarAction($event)"></app-action-tool-bar>
        </div>
      </div>
      <button *ngIf="rowData" (click)="onBtExport()"
        style="margin-bottom: 5px; font-weight: bold; display: flex; margin-left: auto;">
        Export to Excel
      </button>
      <app-listing *ngIf="rowData" [colDef]="colDef" [rowData]="rowData" [setting]="listingSetting" [formCode]="formCode.invoicelisting"
       [excelStyles]="excelStyles" (onGridApi)="onGridApiUpdate($event)" (onListingRowSelected)="onRowSelect($event)"></app-listing>

      <!-- <ag-grid-angular class="ag-theme-alpine ag-grid-table" rowModelType="serverSide" serverSideStoreType="partial"
        [defaultColDef]="defaultColDef" [sideBar]="{ toolPanels: ['columns', 'filters'] }"
        [frameworkComponents]="frameworkComponents" [statusBar]="statusBar" [pagination]="true"
        [suppressPaginationPanel]="true" [paginationPageSize]="10" (gridReady)="onGridReady($event)">
      </ag-grid-angular> -->
    </div>
  </div>
</div>
