export enum sourceFlag {
    pos = 0,
    web_order_onsite = 1,
    web_order_pickup = 3,
    web_order_delivery = 4,
    app_onsite = 5,
    app_take_way = 6,
    app_pickup = 7,
    app_delivery = 8,
    web_QR_dine_in = 9,
    web_QR_take_away = 10,
    app_QR_dine_in = 11,
    app_QR_take_away = 12,
    third_party = 90
}