import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNodeTransaction } from 'ag-grid-community';
import { Message, MessageService } from 'primeng/api';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { AgGridService } from 'src/app/shared/services/ag-grid.service';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupMessageComponent } from '../../popup-message/popup-message.component';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Subscription } from 'rxjs';
import { PopupService } from '../../services/popup.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';

@Component({
    selector: 'app-button-renderer',
    templateUrl: './button-renderer.component.html',
    styleUrls: ['./button-renderer.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, MatIconModule, TranslateModule]
})
export class ButtonRendererComponent implements ICellRendererAngularComp, OnDestroy {
  @ViewChild('agGridTable', {static: false}) agGridTable: any;

  cancelEditPopup$: Subscription;

  params!: ICellRendererParams & { page: FormCode, type: ButtonRendererTypeEnum, buttonText: string };
  buttonText!: string;
  buttonTypes: any = ButtonRendererTypeEnum;
  disabled: boolean = false;

  constructor(private messageService: MessageService,
    public dialog: MatDialog,
    private pageService: PageService,
    private translateService: TranslateService,
    private agGridService: AgGridService,
    private popupService: PopupService) { }

  agInit(params: ICellRendererParams & { page: FormCode, type: ButtonRendererTypeEnum, buttonText: string }): void {
    this.params = params;
    this.checkDisable();
  }

  action(buttonType: ButtonRendererTypeEnum, event: any) {
    switch(buttonType) {
      case this.buttonTypes.duplicate:
        this.duplicateRow();
        break;
      case this.buttonTypes.save:
        this.save(event);
        break;
      case this.buttonTypes.cancelEdit:
        this.cancelEdit();
        break;
      default:
        break;
    }
  }

  duplicateRow() {
    const newItems: any[] = [];
    const rowIndex: number = this.params?.node?.rowIndex != null? this.params.node.rowIndex: null;
    const data: any = this.params?.node?.data? this.params?.node?.data: null

    if(rowIndex != null && this.params.context.componentParent?.setting?.rowModelType === RowModelType.clientSide) {
      newItems.push(data);
      let newRows: RowNodeTransaction = this.params.api.applyTransaction({ add: newItems, addIndex: rowIndex + 1 });

      if(newRows.add[0]) {
        setTimeout(() => { this.params.api.deselectAll(); newRows.add[0].setSelected(true); }, 0);
        this.agGridService.unsetEditMode(this.params.api, this.params.node, this.params.page);
        this.agGridService.setEditMode(this.params.api, newRows.add[0], this.params.page);
      }
    }

    this.pageService.actionBtnListing$.next({action: this.buttonTypes.duplicate, param: this.params});
  }

  save(event: any) {
    let parentEl = event.target.closest('.ag-cell');
    let siblingEl = event.target.closest('.ag-cell').previousElementSibling;

    this.agGridService.setSavedMode(this.params.api, this.params.node, parentEl, siblingEl);

    this.messageService.clear();
    let message: Message = {
      severity: 'success',
      detail: this.translateService.instant("msg.success_save_info")
    };
    this.messageService.add(message);

    this.pageService.actionBtnListing$.next({action: this.buttonTypes.save, param: this.params, cellEl: parentEl});
  }

  cancelEdit() {
    if(this.agGridService.hasEditedCell(this.params.page, this.params)) {
      let msg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.cancel_listing_edit',
        desc: "msg.to_cancel_edit",
        closeBtnText: 'general.no',
        actionBtnText: 'general.yes'
      };

      // pass in data & response the button action
      this.cancelEditPopup$ = this.popupService.getDialogResp(msg).subscribe((result) => {
        if (result === PopupAction.actionBtn) {
          this.agGridService.undoEdit(this.params.page, this.params);
          this.agGridService.unsetEditMode(this.params.api, this.params.node, this.params.page);
          this.agGridService.unsetEditedMode(this.params.page, this.params);
          this.pageService.unsetPendingEdit(this.params.page);
          this.messageService.clear();
        }
      });
    } else {
      this.messageService.clear();
      this.agGridService.unsetEditMode(this.params.api, this.params.node, this.params.page);
    }

    this.pageService.editing$.next({page: this.params.page, isEditing: false});
    this.pageService.actionBtnListing$.next({action: this.buttonTypes.cancelEdit, param: this.params});
  }

  checkDisable() {
    if(!this.params.context.componentParent?.setting?.duplicateRow && this.params.type === this.buttonTypes.duplicate) {
      this.disabled = !this.params.context.componentParent?.setting?.duplicateRow;
    }
  }

  refresh(params: ICellRendererParams & {page: FormCode, type: ButtonRendererTypeEnum, buttonText: string}): boolean {
    this.params = params;
    this.checkDisable();
    return false;
  }

  ngOnDestroy(): void {
    this.cancelEditPopup$?.unsubscribe();
  }
}
