import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextAreaSetting } from '../model/TextAreaSetting.model';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormService } from '../../form/form.service';

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, TranslateModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit, OnChanges {
  @Input() textAreaSetting: TextAreaSetting;
  @Input() formGroup?: UntypedFormGroup;
  @Input() formData?: any;

  formGrpCtrl: any;

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    if(this.textAreaSetting) {
      this.formGrpCtrl = this.formGroup.controls[this.textAreaSetting.name];
    }

    // Set Dependency
    if(this.formGroup && this.textAreaSetting) {
      this.formService.setDependency(this.formGroup, this.textAreaSetting);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue && changes.formData.currentValue[this.textAreaSetting.name]) {
      this.formGroup.controls[this.textAreaSetting.name].setValue(changes.formData.currentValue[this.textAreaSetting.name]);
    }
  }
}
