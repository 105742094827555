import { DealerListingComponent } from "src/app/cxm-backend-web/dealer/dealer-listing/dealer-listing.component";
import { PageRoute } from "../enums/pageRoute";
import { FormCode } from "../enums/formCode";
import { MasterFormComponent } from "../component/master-form/master-form.component";
import { DealerEntryComponent } from "src/app/cxm-backend-web/dealer/dealer-entry/dealer-entry.component";
import { PageComponent } from "../models/pageComponent.model";

export const dealerPathComponent: PageComponent[] = [{
  filePath: PageRoute.DEALER_LISTING,
  component: DealerListingComponent
},
{
  filePath: PageRoute.DEALER_ENTRY,
  formCode: FormCode.dealerentry,
  component: DealerEntryComponent
},
{
  filePath: PageRoute.DEALER_MASTER_ENTRY,
  component: MasterFormComponent
},];
