<div class="tree-layout">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
            This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle (click)="onClickTreeNode($event, node);">
            <div class="mat-tree-node" [ngClass]="{'highlight': node === selectedNode }">
                <div class="node-container">
                    {{ getValue(node) }}
                </div>
            </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild && hasLevel; level">
        <!-- <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild; level"> -->
            <div class="mat-tree-node" (click)="onClickTreeNode($event, node)" [ngClass]="{'highlight': node === selectedNode}">
                <div class="node-container">
                    {{ getValue(node) }}
                    <button type="button" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node[setting.id]" *ngIf="node.childVisible">
                        <mat-icon>{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>
                    </button>
                </div>
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.tree-invisible]="!treeControl.isExpanded(node)" [ngClass]="'child-' + node.level" class="child" role="group">
                <ng-container matTreeNodeOutlet *ngIf="node.childVisible"></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
</div>
