import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GetRowIdFunc, GetRowIdParams, StatusPanelDef } from 'ag-grid-community';

import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { OrderDetailService } from '../../services/orderdetail.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterComponent } from '../../../shared/component/filter/filter.component';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-activitylog-listing',
  templateUrl: './activitylog-listing.component.html',
  styleUrls: ['./activitylog-listing.component.scss'],
  standalone: true,
  imports: [ListingComponent, FilterComponent]
})
export class ActivityLogListingComponent implements OnInit {
  @Input() page: Page;

  pages: Page[];
  screenContainer: any;
  rowData: any = null;
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  formDetail: any = null;
  listingSetting: ListingSetting;
  activityId: string;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    editable: false,
    sortable: true,
    filterParams: {
      browserDatePicker: true
    }
  };

  colDef: ColDef[] = [
    { field: 'logId', headerName: 'Log Id', filter: 'agTextColumnFilter' },
    { field: 'activityId', headerName: 'Activity Id', filter: 'agTextColumnFilter', },
    { field: 'activityType', headerName: 'Activity Type', filter: 'agTextColumnFilter' },
    { field: 'logData', headerName: 'Log Data', filter: 'agTextColumnFilter' },
  ];

  statusBar: { statusPanels: StatusPanelDef[]; } = {
    statusPanels: [
      {
        statusPanel: 'totalRecordRenderer',
        align: 'left'
      },
      {
        statusPanel: 'paginatorRenderer',
        align: 'right',
        statusPanelParams: {
          page: FormCode.activityloglisting
        }
      },

    ],
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
    ],
  };
  public filters: FilterExpression[] = [
    {
      field: 'orderNo',
      title: 'Order No',
      editor: 'string',
    },
    {
      field: 'locShortDesc',
      title: 'Store Name',
      editor: 'string',
    },
    {
      field: 'tranDate',
      title: 'Transaction Date',
      editor: 'date',
    },
    {
      field: 'paymentStatus',
      title: 'Payment Status',
      editor: 'string',
    },
    {
      field: 'refno1',
      title: 'Reference No',
      editor: 'string',
    }
  ];
  constructor(
    private pageService: PageService,
    private orderDetailService: OrderDetailService,
    private authService: AuthService,
    private formService: FormService
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe(pages => {
      this.pages = pages;
      this.screenContainer = document.getElementById("general-container");
    });
  }

  async ngOnInit() {
    //this.getActivityLog();
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide
    }
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.activityId = params['activityId'];
      if (this.activityId) {
        await this.getActivityLog(this.activityId);
      }
    }


  }

  async getActivityLog(activityId: string) {
    let queryOpts: ODataRawQueryOptions = {
      filter: 'activityId eq ' + this.activityId
    };
    let result = await this.orderDetailService.getOdataActivityLog(queryOpts, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.rowData = result['value'];

    }
  }

  onToolbarAction(value: ActionToolBarResp) {
    /* if (value.name === 'new') {
      this.pageService.openNewPage(this.pageRoute.MERCHANT_FORM);
    } */
  }
  async onFilter(odataFilterString: any) {

    let queryOpts: ODataRawQueryOptions = {
      filter: odataFilterString
    };
    let data = await this.orderDetailService.getOdataOrderDetail(queryOpts, this.formDetail.formCode);
    if (data instanceof HttpErrorResponse === false) {
      console.log(data['value']);

      this.rowData = data && data['value'] ? data['value'] : [];
    }
  }
  onPageSizeChange(pageSize: number) {

  }
}
