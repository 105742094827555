import { ChannelListingComponent } from 'src/app/cxm-backend-web/channel/channel-listing/channel-listing.component';
import { PageRoute } from '../enums/pageRoute';
import { FormCode } from '../enums/formCode';
import { ChannelFormComponent } from 'src/app/cxm-backend-web/channel/channel-form/channel-form.component';
import { MasterFormComponent } from '../component/master-form/master-form.component';
import { ChannelAddressListingComponent } from 'src/app/cxm-backend-web/channel/channel-address-listing/channel-address-listing.component';
import { ChannelStoreListingComponent } from 'src/app/cxm-backend-web/channelstore/channelstore-listing/channelstore-listing.component';
import { ChannelStoreEntryComponent } from 'src/app/cxm-backend-web/channelstore/channelstore-entry/channelstore-entry.component';
import { ChannelStoreTaxListingComponent } from 'src/app/cxm-backend-web/channel/channelstoretax/channelstoretax-listing/channelstoretax-listing.component';
import { ChannelStoreTaxEntryComponent } from 'src/app/cxm-backend-web/channel/channelstoretax/channelstoretax-entry/channelstoretax-entry.component';
import { ChannelAddressEntryComponent } from 'src/app/cxm-backend-web/channel/channel-address-entry/channel-address-entry.component';
import { ChannelSectionListingComponent } from 'src/app/cxm-backend-web/channel/channel-section/channel-section-listing/channel-section-listing.component';
import { ChannelSectionEntryComponent } from 'src/app/cxm-backend-web/channel/channel-section/channel-section-entry/channel-section-entry.component';

export const channelPathComponent = [
  {
    filePath: PageRoute.CHANNEL_LISTING,
    component: ChannelListingComponent,
  },
  {
    filePath: PageRoute.CHANNEL_FORM,
    formCode: FormCode.channelentry,
    component: ChannelFormComponent,
  },
  {
    filePath: PageRoute.CHANNEL_MASTER_ENTRY,
    component: MasterFormComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELADDRLISTING,
    formCode: FormCode.channeladdrlisting,
    component: ChannelAddressListingComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELADDR_ENTRY,
    formCode: FormCode.channeladdrentry,
    component: ChannelAddressEntryComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELSECTIONLISTING,
    formCode: FormCode.channelsectionlisting,
    component: ChannelSectionListingComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELSECTION_ENTRY,
    formCode: FormCode.channelsectionentry,
    component: ChannelSectionEntryComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELSTORELISTING,
    formCode: FormCode.channelstorelisting,
    component: ChannelStoreListingComponent,
  },
  {
    filePath: PageRoute.CHANNEL_MASTER_CHANNELSTORELISTING,
    component: MasterFormComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELSTORE_ENTRY,
    formCode: FormCode.channelstoreentry,
    component: ChannelStoreEntryComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELSTORETAX_LISTING,
    formCode: FormCode.channelstoretaxlisting,
    component: ChannelStoreTaxListingComponent,
  },
  {
    filePath: PageRoute.CHANNEL_CHANNELSTORETAX_ENTRY,
    formCode: FormCode.channelstoretaxentry,
    component: ChannelStoreTaxEntryComponent,
  },
];
