import { ChangeDetectorRef, Component, ComponentFactoryResolver, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Page } from '../../models/page.model';
import { form } from 'src/app/core/models/form';
import { PageService } from '../../services/page.service';
import { FormTypeCode } from '../../enums/formTypeCode';
import { Subscription } from 'rxjs';
import { FormSidenavComponent } from '../form/form-sidenav/form-sidenav.component';
import { NgIf, NgClass } from '@angular/common';
import { CurrencyService } from 'src/app/cxm-backend-web/currency/currency.service';

@Component({
    selector: 'app-master-form',
    templateUrl: './master-form.component.html',
    styleUrls: ['./master-form.component.scss'],
    standalone: true,
    imports: [NgIf, FormSidenavComponent, NgClass],
    providers:[CurrencyService]

})
export class MasterFormComponent implements OnInit {
  page: Page;
  @ViewChild('masterPageContainer', { read: ViewContainerRef }) masterPageContainer: ViewContainerRef;
  formTypeCode = FormTypeCode;
  activeForm: form = null;
  sideNavPinnedSub$: Subscription;
  sideNavPinned: boolean = false;
  noLeft: boolean = false;
  childComponentRef: any;

  constructor(
    private pageService: PageService,
    private CFR: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {
    this.sideNavPinned = this.pageService.sideNavPinned;

    this.sideNavPinnedSub$ = this.pageService.sideNavPinned$.subscribe(sideNavPinned => {
      this.sideNavPinned = sideNavPinned;
      this.screenSizeCheck();
    });
  }

  ngOnInit(): void {
    this.screenSizeCheck();

    this.page = this.pageService.pages[this.pageService.pages.length - 1];
    this.activeForm = this.page.form;

    this.pageService.pageSubject$.subscribe((pages) => {
      let page = pages.find((page) => page.seq === this.page.seq);

      if(page) {
        let seq = page.seq;
        this.page = pages[seq - 1];
      }
    });
  }

  ngAfterViewInit() {
    let componentData: any = this.pageService.getMasterChildComponent(this.page.form.formCode);
    this.createMasterComponent(componentData);
  }

  async openChildForm(childForm: form) {
    this.activeForm = childForm;
    console.log(childForm);
    let componentData: any = this.pageService.getMasterChildComponent(childForm.formCode);
    this.page = await this.pageService.updatePages(childForm, this.page.seq);
    this.createMasterComponent(componentData, true);
  }

  createMasterComponent(componentData: any, isReplace?: boolean) {
    const sampleFactory = this.CFR.resolveComponentFactory(componentData.component);
    this.childComponentRef = this.masterPageContainer.createComponent(sampleFactory);
    if (this.childComponentRef) {
      this.childComponentRef.instance.page = this.page;
    }

    if (isReplace) {
      this.pageService.removeComponent(this.masterPageContainer, 0);
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.sideNavPinnedSub$.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  screenSizeCheck() {
    if (window.innerWidth <= 1100 || (window.innerWidth <= 1400 && this.sideNavPinned)) {
      this.noLeft = true;
    } else {
      this.noLeft = false;
    }
  }
}
