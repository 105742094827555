import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LookupMasterService {

  constructor() { }

  setFilter(filterVal: string, filterBy: string[]) {
    let filter: string = '';
    if(filterVal) {
      filterBy.forEach((filterKey: string, index: number) => {
        filter += index > 0? " or ": '';
        filter += 'contains(' + filterKey + ',\'' + filterVal + '\')';
      });
    }
    return filter;
  }

  setOrderBy(orderVal: {key: string, order: 'asc' | 'desc'}[]) {
    let orderBy: string = '';
    orderVal?.forEach((orderVal: any, index: number) => {
      orderBy += index > 0? ", ": "";
      orderBy += orderVal.key + " " + orderVal.order;
    });
    return orderBy;
  }
}
