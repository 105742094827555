import { FormCode } from '../enums/formCode';
import { PageRoute } from '../enums/pageRoute';
import { TaxConditionHListingComponent } from 'src/app/cxm-backend-web/tax/taxconditionh/taxconditionh-listing/taxconditionh-listing.component';
import { TaxConditionDListingComponent } from 'src/app/cxm-backend-web/tax/taxconditiond/taxconditiond-listing/taxconditiond-listing.component';
import { MasterFormComponent } from '../component/master-form/master-form.component';
import { TaxConditionHEntryComponent } from 'src/app/cxm-backend-web/tax/taxconditionh/taxconditionh-entry/taxconditionh-entry.component';
import { TaxConditionDEntryComponent } from 'src/app/cxm-backend-web/tax/taxconditiond/taxconditiond-entry/taxconditiond-entry.component';
import { TaxConditionFListingComponent } from 'src/app/cxm-backend-web/tax/taxconditionf/taxconditionf-listing/taxconditionf-listing.component';
import { TaxConditionFEntryComponent } from 'src/app/cxm-backend-web/tax/taxconditionf/taxconditionf-entry/taxconditionf-entry.component';
import { TaxListingComponent } from 'src/app/cxm-backend-web/tax/tax/tax-listing/tax-listing.component';
import { TaxEntryComponent } from 'src/app/cxm-backend-web/tax/tax/tax-entry/tax-entry.component';
import { TaxGroupListingComponent } from 'src/app/cxm-backend-web/tax/taxgroup/taxgroup-listing/taxgroup-listing.component';
import { TaxGroupEntryComponent } from 'src/app/cxm-backend-web/tax/taxgroup/taxgroup-entry/taxgroup-entry.component';

export const taxPathComponent = [
  {
    filePath: PageRoute.TAXCONDITIONH_LISTING,
    formCode: FormCode.taxconditionhlisting,
    component: TaxConditionHListingComponent,
  },
  {
    filePath: PageRoute.TAXCONDITIOND_LISTING,
    formCode: FormCode.taxconditiondlisting,
    component: TaxConditionDListingComponent
  },
  {
    filePath: PageRoute.TAXCONDITIOND_MASTER_LISTING,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.TAXCONDITIONH_ENTRY,
    formCode: FormCode.taxconditionhentry,
    component: TaxConditionHEntryComponent
  },
  {
    filePath: PageRoute.TAXCONDITIONH_MASTER_ENTRY,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.TAXCONDITIOND_ENTRY,
    formCode: FormCode.taxconditiondentry,
    component: TaxConditionDEntryComponent
  },
  {
    filePath: PageRoute.TAXCONDITIOND_MASTER_ENTRY,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.TAXCONDITIONF_LISTING,
    formCode: FormCode.taxconditionflisting,
    component: TaxConditionFListingComponent
  },
  {
    filePath: PageRoute.TAXCONDITIONF_MASTER_LISTING,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.TAXCONDITIONF_ENTRY,
    formCode: FormCode.taxconditionfentry,
    component: TaxConditionFEntryComponent
  },
  {
    filePath: PageRoute.TAXCONDITIONF_MASTER_ENTRY,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.TAX_LISTING,
    formCode: FormCode.taxlisting,
    component: TaxListingComponent,
  },
  {
    filePath: PageRoute.TAX_ENTRY,
    formCode: FormCode.taxentry,
    component: TaxEntryComponent
  },
  {
    filePath: PageRoute.TAX_MASTER_ENTRY,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.TAXGROUP_LISTING,
    formCode: FormCode.taxgrouplisting,
    component: TaxGroupListingComponent,
  },
  {
    filePath: PageRoute.TAXGROUP_ENTRY,
    formCode: FormCode.taxgroupentry,
    component: TaxGroupEntryComponent
  },
  {
    filePath: PageRoute.TAXGROUP_MASTER_ENTRY,
    component: MasterFormComponent
  },
];
