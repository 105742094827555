export enum PageRoute {
  DASHBOARD = 'dashboard',

  MERCHANT = 'merchant',
  MERCHANT_LISTING = 'merchant/listing',
  MERCHANT_ENTRY = 'merchant/form',
  MERCHANT_MASTER_ENTRY = 'merchant/master/entry',

  STORE = 'store',
  STORE_LISTING = 'store/listing',
  STORE_ENTRY = 'store/entry',
  STORE_MASTER_ENTRY = 'store/master/entry',
  STORE_ATTRLISTING = 'store/attrlisting',
  STORE_OHLISTING = 'store/ohlisting',
  STORE_MASTER_ATTRLISTING = 'store/master/attrlisting',
  STORE_MASTER_OHLISTING = 'store/master/ohlisting',
  STORE_MASTER_SETTING = 'store/master/storesetting',
  STORE_SETTING = 'store/storesetting',
  STORE_MASTER_CAT_ATTR = 'store/master/storecatgattrlisting',
  STORE_CAT_ATTR = 'store/storecatgattrlisting',
  STORE_CAT_ATTR_ENTRY = 'store/storecatgattr/entry',

  STORE_CHANNELSTORETAX_LISTING = 'store/master/channelstoretaxlisting',
  STORE_CHANNELSTORETAX_ENTRY = 'store/channelstoretax/entry',

  CURRENCY = 'currency',
  CURRENCY_MASTER_ENTRY = 'currency/master/entry',
  CURRENCY_MASTER_LISTING = 'currency/master/listing',
  CURRENCY_ENTRY = 'currency/entry',
  CURRENCY_LISTING = 'currency/listing',

  STATEMENT_LISTING = 'statement/listing',
  STATEMENT_ENTRY = 'statement/entry',

  STORE_ATTR_ENTRY = 'store/storeattr/entry',
  STORE_OH_ENTRY = 'store/storeoh/entry',

  STORE_MASTER_CHANNELSTORE_LISTING = 'store/master/storechannelstorelisting',
  STORE_CHANNELSTORE_LISTING = 'store/storechannelstorelisting',

  STORE_CHANNELSTORE_ENTRY = 'store/storechannelstore/entry',



  // framework
  FRAME_CUSTOM_FORM = 'framework/customform',
  FRAME_DEMO = 'framework/demo',
  FRAME_GRID = 'framework/grid',
  FRAME_LAYOUT = 'framework/layout',
  FRAME_PROPERTY_GRID = 'framework/propertygrid',
  FRAME_SUBSCRIPTION = 'framework/subscription',
  FRAME_WIDGET = 'framework/widget',

  // report
  REPORT = 'report',
  REPORT_VIEWER = 'report-viewer',
  SETTING = 'setting',
  STOREATTR_ENTRY = 'store/storeattr/entry',

  CHANNEL_LISTING = 'channel/listing',
  CHANNEL_FORM = 'channel/entry',
  CHANNEL_MASTER_ENTRY = 'channel/master/entry',
  CHANNEL_CHANNELADDRLISTING = 'channel/master/channeladdrlisting',
  CHANNEL_CHANNELADDR_ENTRY = 'channel/channeladdr/entry',
  CHANNEL_MASTER_CHANNELSTORELISTING = 'channel/master/channelstorelisting',
  CHANNEL_CHANNELSTORELISTING = 'channel/channelstorelisting',
  CHANNEL_CHANNELSTORE_ENTRY = 'channel/channelstore/entry',
  CHANNEL_CHANNELSTORETAX_LISTING = 'channel/master/channelstoretaxlisting',
  CHANNEL_CHANNELSTORETAX_ENTRY = 'channel/channelstoretax/entry',
  CHANNEL_CHANNELSECTIONLISTING = 'channel/master/channelsectionlisting',
  CHANNEL_CHANNELSECTION_ENTRY = 'channel/channelsection/entry',

  ORDERDETAIL_LISTING = 'order-management/listing',
  REVERTCUTOFFFORM = 'order-management/revertcutoff',
  UPDATEORDERSTATUS_FORM = 'order-management/updateorderstatus',
  ACTIVITYLOG_LISTING = 'order-management/activityloglisting',
  SYSPENDINGJOB_LISTING = 'order-management/syspendingjoblisting',
  REVERTCUTOFF_MASTER_FORM = 'order-management/master/revertcutoff',

  ACTIVITYLOG_MASTER_LISTING = 'order-management/master/activityloglisting',
  SYSPENDINGJOB_MASTER_LISTING = 'order-management/master/syspendingjoblisting',


  INVOICE_LISTING = 'invoice/listing',
  INVOICE_FORM = 'invoice/form',
  INVOICE_ENTRY = 'invoice/entry',
  INVOICE_MASTER_ENTRY = 'invoice/master/entry',
  INVOICE_EMAILENTRY = 'invoice/emailentry',

  TAX_LISTING = "tax/taxlisting",
  TAX_ENTRY = 'tax/taxentry',
  TAX_MASTER_ENTRY =  'tax/master/taxentry',
  TAXGROUP_LISTING = "tax/taxgrouplisting",
  TAXGROUP_ENTRY = 'tax/taxgroupentry',
  TAXGROUP_MASTER_ENTRY =  'tax/master/taxgroupentry',
  TAXCONDITIONH_LISTING = "tax/taxconditionhlisting",
  TAXCONDITIOND_LISTING = 'tax/taxconditiondlisting',
  TAXCONDITIOND_MASTER_LISTING = 'tax/master/taxconditiondlisting',
  TAXCONDITIONH_ENTRY = 'tax/taxconditionhentry',
  TAXCONDITIONH_MASTER_ENTRY = 'tax/master/taxconditionhentry',
  TAXCONDITIOND_ENTRY = 'tax/taxconditiondentry',
  TAXCONDITIOND_MASTER_ENTRY = 'tax/taxconditiond/master/taxconditiondentry',
  TAXCONDITIONF_LISTING = 'tax/taxconditionflisting',
  TAXCONDITIONF_MASTER_LISTING = 'tax/taxconditiond/master/taxconditionflisting',
  TAXCONDITIONF_ENTRY = 'tax/taxconditionfentry',
  TAXCONDITIONF_MASTER_ENTRY = 'tax/master/taxconditionfentry',

  DEALER = 'dealer',
  DEALER_LISTING = 'dealer/listing',
  DEALER_ENTRY = 'dealer/entry',
  DEALER_MASTER_ENTRY = 'dealer/master/entry',
}
