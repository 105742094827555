import { Pipe, PipeTransform } from '@angular/core';
import { agGridTemplate } from '../enums/agGridTemplate';
import { sourceFlag } from '../enums/sourceFlag';
import { orderStatus } from '../enums/orderStatus';
import { TranslateService } from '@ngx-translate/core';
import { memberType } from '../enums/memberType';
import { statusFlag } from '../enums/statusFlag';
import { salesMode } from '../enums/salesMode';
import { excpFlag } from '../enums/excpFlag';
import { lineMode } from '../enums/lineMode';
import { voidFlag } from '../enums/voidFlag';

@Pipe({
  name: 'flagMapping'
})
export class FlagMappingPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}
    
  transform(value: any, flag: any): any {
    let mapVal: any = null;

    switch(flag) {
      case agGridTemplate.orderStatus: 
        mapVal = orderStatus;
        break;
      case agGridTemplate.sourceFlag:
        mapVal = sourceFlag;
      break;
      case agGridTemplate.memberType:
        mapVal = memberType;
        break;
      case agGridTemplate.status:
        mapVal = statusFlag;
        break;
      case agGridTemplate.salesMode:
        mapVal = salesMode;
        break;
      case agGridTemplate.salesException:
        mapVal = excpFlag;
        break;
      case agGridTemplate.lineMode:
        mapVal = lineMode;
        break;
      case agGridTemplate.yesno:
        mapVal = voidFlag;
        break;
      default:
        break;
    }

    let mappedVal = Object.keys(mapVal)?.find(val => mapVal[val] === value);

    if(mappedVal) {
      value = this.translateService.instant('general.' + mappedVal) !== mappedVal? this.translateService.instant('general.' + mappedVal): mappedVal;
    } else if(agGridTemplate.salesException && value === 0) {
      value = "N/A";
    }

    return value;
  }

}
