import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { ReportViewerService } from "src/app/shared/component/services/report-viewer.service";
import { ReportViewerParams } from "src/app/shared/models/reportViewerParams.model";
import { ErrorPageService } from "src/app/shared/services/error-page.service";

export const ReportViewerGuard: CanActivateFn = (route, state) => {
  const rptViewerService: ReportViewerService = inject(ReportViewerService);
  const errPgService: ErrorPageService = inject(ErrorPageService);

  const parameters: ReportViewerParams = route?.queryParams?.parameters? rptViewerService.formatEncodedParam(route?.queryParams?.parameters): null;

  if(parameters && parameters?.JobId && parameters?.SubscriptionId && parameters?.ApiUrl 
    && parameters?.RequireAccessCode && parameters?.FormCode) {
    return true;
  } else {
    errPgService.show(400, 'Bad Request', '/dashboard');
  }
  return false;
};
