import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private langChangeSubject = new Subject<LangChangeEvent>();

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChangeSubject.next(event);
    });
  }

  getLangChangeObservable() {
    return this.langChangeSubject.asObservable();
  }
}
