export enum salesMode {
    cash_sales = 'S',
    return_and_exchange = 'R',
    credit_sales = 'C',
    deposit_sales = 'D',
    refundable_deposit = 'E',
    deposit_redeem = 'F',
    deposit_refund = 'G',
    hire_purchase_sales = 'H',
    tax_relief_sales = 'X',
    non_sales = 'N'
}