import { PageRoute } from "../../enums/pageRoute";
import { DemoFormComponent } from "src/app/project-demo/framework/demo-form/demo-form.component";
import { PageComponent } from "../../models/pageComponent.model";
import { CustomFormComponent } from "src/app/project-demo/framework/custom-form/custom-form.component";
import { GridComponent } from "src/app/project-demo/framework/grid/grid.component";
import { LayoutComponent } from "src/app/project-demo/framework/layout/layout.component";
import { PropertyGridComponent } from "src/app/shared/component/property-grid/property-grid.component";
import { SettingComponent } from "src/app/project-demo/setting/setting/setting.component";
import { SubscriptionComponent } from "src/app/subscription/subscription.component";
import { WidgetComponent } from "../../component/widget/widget.component";
import { CurrencyListingComponent } from "src/app/project-demo/currency/currency-listing/currency-listing.component";
import { CurrencyEntryComponent } from "src/app/project-demo/currency/currency-entry/currency-entry.component";

export const frameworkPathComponent: PageComponent[] = [
  {
    filePath: PageRoute.FRAME_DEMO,
    component: DemoFormComponent
  },
  {
    filePath: PageRoute.FRAME_CUSTOM_FORM,
    component: CustomFormComponent
  },
  {
    filePath: PageRoute.FRAME_GRID,
    component: GridComponent
  },
  {
    filePath: PageRoute.FRAME_LAYOUT,
    component: LayoutComponent
  },
  {
    filePath: PageRoute.FRAME_PROPERTY_GRID,
    component: SettingComponent
  },
  {
    filePath: PageRoute.FRAME_SUBSCRIPTION,
    component: SubscriptionComponent
  },
  {
    filePath: PageRoute.FRAME_WIDGET,
    component: WidgetComponent
  },
  {
    filePath: PageRoute.CURRENCY_MASTER_LISTING,
    component: CurrencyListingComponent
  },
  {
    filePath: PageRoute.CURRENCY_MASTER_ENTRY,
    component: CurrencyEntryComponent
  }
];
