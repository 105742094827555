export const basedEnvironmentProd = {
  production: false,
  apiVersion: '1.0',
  subHostPath: 'https://zptlapiv2dev.azurewebsites.net',
  oauthRetryCountLimit: 5,
  oauthRetryIntervalSeconds: 30,
  identityUrl: 'https://zaccv2dev.azurewebsites.net', //framework identity link
  hostPath: 'https://zrmsapiv2dev.azurewebsites.net',
  apis: {
    channel: {
      GetAllChannel: '/api/channel/getallchannel'
    },
    currency: {
      AddCurrency: '/api/currency/addcurrency',
      UpdateCurrency: '/api/currency/updatecurrency',
      GetCurrency: '/api/currency/getcurrency',
      OdataCurrency: '/odata/currency',
      OdataCurrencyList: '/odata/currency/getcurrencylist',
      DeleteCurrency: '/api/currency/deletecurrency'
    },
    country: {
      OdataCountry: '/odata/country',
    },
    state: {
      OdataState: '/odata/ctryState',
    },
    region: {
      OdataRegion: '/odata/ctryRegion'
    },
    area: {
      OdataArea: '/odata/ctryArea'
    },
    generalMasterData: {
      GetGeneralMasterData: '/api/generalmasterdata/getgeneralmasterdata'
    },
    merchant: {
      RegisterMerchant: '/api/merchant/registermerchant',
      UpdateMerchant: '/api/merchant/updatemerchant',
      GetAllMerchant: '/api/merchant/getallmerchant',
      OdataMerchant: '/odata/merchant'
    },
    store: {
      GetAllStore: '/api/store/getallstore',
      RegisterStore: '/api/store/registerstore',
      UpdateStore: '/api/store/updatestore',
      OdataStore: '/odata/store'
    },
    storeAttr: {
      UpdateStoreAttr: '/api/storeattr/updatestoreattr',
      OdataStoreAttr: '/odata/storeattr',
      AddStoreAttr: '/api/storeattr/addstoreattr',
      DeleteStoreAttr: '/api/storeattr/deletestoreattr'
    },
    storeOH: {
      UpdateStoreOH: '/api/storeoh/updatestoreoh',
      OdataStoreOH: '/odata/storeoh',
      AddStoreOH: '/api/storeoh/addstoreoh',
      DeleteStoreOH: '/api/storeoh/deletestoreoh'
    },
    statement: {
      OdataStatement: '/odata/statement',
    },
    file: {
      upload: '/api/file/upload'
    },
    system: {
      GetNextCounter: '/api/system/getnextcounter',
      GetInitData: '/api/system/getinitdata',
      UpdateFavourite: '/api/system/updatefavourite',
      GetSysCultureList: '/odata/system/getsysculturelist',
      GetSysLanguageList: '/odata/system/getsyslanguagelist'
    },
    reportViewer: {
      GetReportViewer: '/api/reportviewer/getreportviewerdata',
      GetReportViewerV2: '/api/reportviewer/getreportviewerdatav2',
      GetReportResult: '/api/reportviewer/getreportresult',
      GetPreviousGeneratedReports: "/api/reportviewer/getpreviousgeneratedreports"
    },
    subscription: {
      Getsubscriptioninfolist: '/odata/Subscription/Getsubscriptioninfolist',
      GetRMSSubscriptionInfo: '/api/Subscription/GetRMSSubscriptionInfo',
      UpdateInfo: '/api/subscription/updateinfo'
    },
    subscriptionUser: {
      getmyprofile: '/api/subscriptionuser/getmyprofile',
      updatemyprofile: '/api/subscriptionuser/updatemyprofile',
      agreeterms: '/api/subscriptionuser/agreeterms',
      getsysuserlist: '/odata/subscriptionuser/getsysuserlist'
    },
    sysFormSet: {
      GetReportSettingList: '/api/sysFormSet/getreportsettinglist',
      GetReportSetting: '/api/sysFormSet/getreportsetting',
      GetUserDefaultReportSetting: '/api/sysFormSet/getuserdefaultreportsetting',
      AddReportSetting: '/api/sysFormSet/addreportsetting',
      UpdateReportSetting: '/api/sysFormSet/updatereportsetting',
      DeleteReportSetting: '/api/sysFormSet/deletereportsetting',
    },
    location: {
      getOutletList: '/odata/location/getoutletlist'
    },
    locCategory: {
      getOutletCategoryList: '/odata/loccategory/getoutletcategorylist'
    },
    locCategory2: {
      getOutletCategory2List: '/odata/loccategory2/getoutletcategory2list'
    },
    locCategory3: {
      getOutletCategory3List: '/odata/loccategory3/getoutletcategory3list'
    },
    locGroup: {
      getOutletGroupList: '/odata/locgroup/getoutletgrouplist'
    },
    orderPlatform: {
      getOrderPlatformList: '/odata/orderplatform/getorderplatformlist'
    },
    posOrderType: {
      getPosOrderTypeList: '/odata/posordertype/getposordertypelist'
    },
    posPromotionH: {
      getPosPromotionhList: '/odata/pospromotionh/getpospromotionhlist'
    },
    posTender: {
      getPosTenderList: '/odata/postender/getpostenderlist'
    },
    posTerminal: {
      getPosTerminalList: '/odata/posterminal/getposterminallist'
    },
    posUser: {
      getPosUserList: '/odata/posuser/getposuserlist'
    },
    prodBrand: {
      getProdBrandList: '/odata/prodbrand/getprodbrandlist'
    },
    prodBrandGroup: {
      getProdBrandGroupList: '/odata/prodbrandgroup/getprodbrandgrouplist'
    },
    prodCategory: {
      getProdCategoryList: '/odata/prodcategory/getprodcategorylist'
    },
    prodCategory2: {
      getProdCategory2List: '/odata/prodcategory2/getprodcategory2list'
    },
    prodCategory3: {
      getProdCategory3List: '/odata/prodcategory3/getprodcategory3list'
    },
    prodClass: {
      getProdClassList: '/odata/prodclass/getprodclasslist'
    },
    prodColor: {
      getProdColorList: '/odata/prodcolor/getprodcolorlist'
    },
    prodDepartment: {
      getProdDepartmentList: '/odata/proddepartment/getproddepartmentlist'
    },
    prodItem: {
      getProdItemList: '/odata/proditem/getproditemlist'
    },
    prodProductGroup: {
      getProdProductGroupList: '/odata/prodproductgroup/getprodproductgrouplist'
    },
    prodSeason: {
      getProdSeasonList: '/odata/prodseason/getprodseasonlist'
    },
    prodSize: {
      getProdSizeList: '/odata/prodsize/getprodsizelist'
    },
    prodSizeGroup: {
      getProdSizeGroupList: '/odata/prodsizegroup/getprodsizegrouplist'
    },
    customer: {
      getCustomerList: '/odata/customer/getcustomerlist'
    },
    customerCatg1: {
      getCustomerCatg1List: '/odata/customercatg1/getcustomercatg1list'
    },
    customerCatg2: {
      getCustomerCatg2List: '/odata/customercatg2/getcustomercatg2list'
    },
    customerCatg3: {
      getCustomerCatg3List: '/odata/customercatg3/getcustomercatg3list'
    },
    sellPriceType: {
      getSellPriceTypeList: '/odata/sellpricetype/getsellpricetypelist'
    },
    job: {
      getjobsbyqueue: '/api/job/getjobsbyqueue'
    }
  },
  firebaseConfig: {
    apiKey: '<your-key>',
    authDomain: '<your-project-authdomain>',
    databaseURL: '<your-database-URL>',
    projectId: '<your-project-id>',
    storageBucket: '<your-storage-bucket>',
    messagingSenderId: '<your-messaging-sender-id>'
  },
  subscription: '00001',
  subscriptionName: 'Odaring CXM',
  secretKey: '3ijXOCZxjIYtFgqbtbZGfcCblGKq1DSt1Xo9Y2Si8cg=',
  oauthConfig: {
    clientId: 'odaring.backend.web',
    scope: 'openid profile cxm.backend.api offline_access',
    allowedUrls: [
      'https://localhost:4200',
      'https://zptlapiv2dev.azurewebsites.net',
      'https://cxmbackendapi.azurewebsites.net',
      'https://odabackapi100.odaring.com'
    ]
  },
  showFramework: true,
  showDevInfo: true,
  disableSubscriptionPage: false,
  rptGrpAutoSelect: true
};
