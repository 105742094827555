/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SysPendingJobResponse { 
    jobId?: number;
    queueName?: string;
    subscriptionId?: string;
    jobType?: number;
    jobStatus?: number;
    priorityNo?: number;
    requestBody?: string;
    responseBody?: string;
    docNo?: string;
    docDate?: Date;
    refCode1?: string;
    refCode2?: string;
    startTime?: Date;
    rowVersion?: string;
}