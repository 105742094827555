import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ColDef, GetRowIdFunc, IRowNode } from 'ag-grid-community';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';

import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Page } from 'src/app/shared/models/page.model';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { TaxService } from '../../tax.service';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { Subscription } from 'rxjs';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Mode } from 'src/app/shared/enums/mode';

@Component({
  selector: 'app-taxconditiond-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './taxconditionf-listing.component.html',
  styleUrls: ['./taxconditionf-listing.component.scss']
})
export class TaxConditionFListingComponent {
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  @Input() page: Page;

  locDesc: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  listingSetting: ListingSetting;
  rowData: any = null;
  formDetail: any = null;
  taxCondCode: string;
  taxCondLine: string;
  // Column data
  colDef: ColDef[] = [];
  refresh$: Subscription;
  selectedRowData: any;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1, position: ActionToolbarPosition.LEFT },
      { name: 'edit', icon: 'assets/base/icons/pencil.svg', text: 'toolbar.edit', checkPermission: [AttributeCode.EDIT], collapseTo: 'others', sortNo: 3  },
      { name: 'delete', icon: 'assets/base/icons/trash.svg', text: 'toolbar.delete', checkPermission: [AttributeCode.DELETE], collapseTo: 'others', sortNo: 4 },
      { name: 'new', icon: 'assets/base/icons/plus.svg', text: 'toolbar.new_taxconditionf', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
    ],
    position: ActionToolbarPosition.RIGHT
  };

  constructor(
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private taxService: TaxService,
    private authService: AuthService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService
  ) {}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide
    }
     // Column data
    this.colDef = [
    listingCheckbox,
    {
      ...listingRouterLink,
      field: 'taxCondCode',
      headerName: 'Tax Condition Code',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        inRouterLink: {
          route: '/tax/taxconditionfentry',
          params: ['taxCondCode', 'taxCondLine', 'filterLine'],
          queryParams: { mode: Mode.edit },
          childPage: true
        }
      },
    },
    {
      field: 'taxCondLine',
      headerName: 'Tax Condition Line',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'filterLine',
      headerName: 'Filter Line',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'filterParentLine',
      headerName: 'Filter Parent Line',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'fieldType',
      headerName: 'Field Type',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'fieldOperator',
      headerName: 'Field Operator',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'FieldData',
      headerName: 'field Data',
      filter: 'agTextColumnFilter'
    }
  ];

  if (this.page) {
    let params = this.pageService.getParameterDecode(this.page);
    this.taxCondCode = params['taxCondCode'];
    this.taxCondLine = params['taxCondLine'];
    console.log(this.taxCondCode)
    if (this.taxCondCode && this.taxCondLine) {
      await this.getTaxConditionF();
    }
  }
  this.refresh$ = this.pageService.refreshListing$.subscribe(
    async (formCode: any) => {
      if (formCode.includes(this.page.form.formCode)) {
        await this.getTaxConditionF();
      }
    }
  );
  }

  async getTaxConditionF(queryOpts?: ODataRawQueryOptions) {
    if (!queryOpts){
      queryOpts = {
        filter: 'taxCondCode eq ' + "'" + this.taxCondCode + "'" + ' and taxCondLine eq ' + "'" + this.taxCondLine + "'"
      };
    }
    else {
      queryOpts = {
        filter: 'taxCondCode eq ' + "'" + this.taxCondCode + "'" + ' and taxCondLine eq ' + "'" + this.taxCondLine + "'" + 'and (' + queryOpts.filter + ')'
      };
    }
    let result = await this.taxService.getOdataTaxConditionF(null, queryOpts, this.formDetail.formCode);
    if (result instanceof HttpErrorResponse === false){
      this.rowData = result['value'];
    }
  }

  closePopup() {
    this.popupFormService.close(this.formCode.taxconditiondlisting);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByUrl(PageRoute.TAXCONDITIONF_ENTRY, {
        taxCondCode: this.taxCondCode,
        taxCondLine: this.taxCondLine,
      }, null, true);
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox' && col.field !== 'actionButton')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
     await this.getTaxConditionF(filterString);
    }

    if (value.name === 'delete'
      && this.selectedRowData
      && this.selectedRowData.data['taxCondCode']
      && this.selectedRowData.data['taxCondLine']
      && this.selectedRowData.data['filterLine']) {

      let result = await this.taxService.deleteTaxConditionF(this.selectedRowData.data['taxCondCode'], this.selectedRowData.data['taxCondLine'], this.selectedRowData.data['filterLine'], this.formDetail.formCode);
      if (result instanceof HttpErrorResponse === false){
        this.pageService.refresh([FormCode.taxconditionflisting]);
      }
    }
  }
  ngOnDestroy() {
    this.refresh$.unsubscribe();
  }
  onPageSizeChange(pageSize: number) {}

  onRowSelect(nodes: IRowNode[]) {
    this.selectedRowData = nodes[0] ? nodes[0] : null;
  }
}
