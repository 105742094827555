import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupSelectionComponent } from '../group/group-selection/group-selection.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomFilterComponent } from '../filter/custom-filter/custom-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ButtonType } from '../../enums/buttonType';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { ActionToolBarResp } from '../action-tool-bar/model/ActionToolBarResp.model';
import { Output, EventEmitter } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangeDetectorRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RadioButtonComponent } from '../input/radio-button/radio-button/radio-button.component';
import { RadioButtonSetting } from '../input/model/RadioButtonSetting.model';
@Component({
  selector: 'app-filter-group',
  standalone: true,
  imports: [
    CommonModule,
    CustomFilterComponent,
    GroupSelectionComponent,
    TranslateModule,
    ActionToolBarComponent,
    SharedModule,
    MatTabsModule,
    RadioButtonComponent
  ],
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnInit {
  filters: any;
  reportData: any;
  formCode: any;
  selectedGroups: any;
  selectionGroups: any;
  filterItems: any;
  filterQueryString: any;
  filter: any[];
  filterStructure: any[];
  firstGroupQuery: string;
  isValueChange: boolean = false;
  isDatePickerMode: boolean;
  isEditMode: boolean;
  filterSetting: any;
  dateFormulaBuilder: any;
  defaultFilter: any;
  countBy: number;

  //Mobile View
  isMobile: boolean = window.innerWidth <= 991;

  @Output() onFilterState = new EventEmitter();
  @ViewChild('customFilter') customFilter: CustomFilterComponent;

  radioBtnSetting: RadioButtonSetting = {
    name: 'countBy',
    label: 'report.count_by',
    item: [
      {name: 'general.item', value: 0},
      {name: 'general.set', value: 1}
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    popup: true,
    actionToolBarItems: [
      {
        id: 'btn-cancel',
        name: 'cancel',
        text: 'toolbar.cancel',
        btnType: ButtonType.button,
        sortNo: 1,
        position: ActionToolbarPosition.RIGHT,
        styling: { minWidth: this.isMobile ? '70px' : '140px' },
      },
      {
        id: 'btn-apply',
        name: 'apply',
        text: 'general.apply',
        btnType: ButtonType.button,
        sortNo: 2,
        position: ActionToolbarPosition.RIGHT,
        buttonActive: true,
        styling: { minWidth: this.isMobile ? '100px' : '200px' },
        dropdownItm: [
          {
            id: 'btn-apply-generate',
            name: 'apply_generate',
            text: 'toolbar.apply_generate',
            btnType: ButtonType.button,
            sortNo: 1,
          },
        ],
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<FilterGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    this.checkIfMobile();
  }

  ngOnInit(): void {
    this.filters = this.data?.filters ? this.data.filters : null;
    this.reportData = this.data?.reportData ? this.data.reportData : null;
    this.formCode = this.data?.formCode ? this.data.formCode : null;
    this.selectionGroups = this.data?.selectionGroups ? this.data.selectionGroups : null;
    this.selectedGroups = this.data?.selectedGroups ? this.data.selectedGroups : null;
    this.filterItems = this.data?.filterItems ? this.data.filterItems : null;
    this.isEditMode = this.data?.editMode;
    this.defaultFilter = this.data?.defaultFilter;
    this.isMobile = this.data?.isMobile;
    this.countBy = this.data?.countBy;
  }

  onToolbarAction(resp: ActionToolBarResp) {
    if (resp.name === 'cancel') {
      this.onClose();
    } else if (resp.name === 'apply') {
      const generateResult = this.onGenerate(); // Check if an error occurred during generation
      if (!generateResult) {
        return; // Exit early if an error occurred
      }

      this.dialogRef.close({
        filterQueryString: this.filterQueryString,
        firstGroupQuery: this.firstGroupQuery,
        filter: this.filter,
        filterStructure: this.filterStructure,
        selectedGroups: this.selectedGroups,
        filterSetting: this.filterSetting,
        dateFormulaBuilder: this.dateFormulaBuilder,
        countBy: this.countBy,
        generate: false,
      });
    } else if (resp.name === 'apply_generate') {
      const generateResult = this.onGenerate(); // Check if an error occurred during generation
      if (!generateResult) {
        return; // Exit early if an error occurred
      }

      this.dialogRef.close({
        filterQueryString: this.filterQueryString,
        firstGroupQuery: this.firstGroupQuery,
        filter: this.filter,
        filterStructure: this.filterStructure,
        selectedGroups: this.selectedGroups,
        filterSetting: this.filterSetting,
        dateFormulaBuilder: this.dateFormulaBuilder,
        countBy: this.countBy,
        generate: true,
      });
    }
  }

  onGenerate(): boolean {
    try {
      this.customFilter.restructure();
      return true; // Return true if restructuring succeeds
    } catch (error) {
      return false; // Return false if an error occurs during restructuring
    }
  }

  onQuery(event: {
    queryHTML: any;
    resultArray: any[];
    firstGroupQuery: string;
  }) {
    this.filterQueryString = event.queryHTML;
    this.cd.detectChanges()

    this.filter = event.resultArray;
    this.firstGroupQuery = event.firstGroupQuery;
  }

  onCountByChged(val: any) {
    this.countBy = val;
  }

  handleChange(event: boolean) {
    this.isValueChange = event;
  }

  passFilter(event: { filterStructure: any, filterSetting: any, dateFormulaBuilder: any}) {
    this.filterStructure = event.filterStructure;
    this.filterSetting = event.filterSetting;
    this.dateFormulaBuilder = event.dateFormulaBuilder;
  }

  onClose() {
    this.dialogRef.close();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }

  private checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 991;
  }
}
