<div class="filter-container">
  <p class="title">Filter</p>

  <form [formGroup]="filterForm">
    <kendo-filter [filters]="filters" [value]="value" editorFormat="dd/MM/yy" (valueChange)="onFilterChange($event)">
    </kendo-filter>
  </form>

  <div class="right-button">
    <button class="generate-filter-btn" (click)="generateFilter()"><img class="plus-circle" src="assets/base/icons/play.svg" /> Load</button>
    <button class="reset-filter-btn" (click)="reset()" *ngIf="gotReset"><img class="plus-circle" src="assets/base/icons/undo.svg" /> Reset</button>
    <button class="reset-filter-btn" (click)="remove()"*ngIf="gotRemove"><img class="plus-circle" src="assets/base/icons/remove.svg" /> Remove</button>
    <button class="reset-filter-btn" (click)="email()" *ngIf="gotEmail"><img class="plus-circle" src="assets/base/icons/export.svg" /> Email</button>
    <button class="reset-filter-btn" (click)="resend()" *ngIf="gotResend"><img class="plus-circle" src="assets/base/icons/arrow-right.svg" /> Resend</button>

  </div>
</div>
