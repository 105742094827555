import { FormCodeBase } from "src/app/shared/enums/base/FormCodeBase";

export enum FormCodeProject {
    rptsalesdetailsv2 = 'rptsalesdetailsv2',
    platformsales = 'platformsales',
    rptstocksummv2 = 'rptstocksummv2',
    rptinvbaldailyv2 = 'rptinvbaldailyv2',
    rptsalessummaryv2 = 'rptsalessummaryv2'
}

export const FormCode = {
    ...FormCodeBase,
    ...FormCodeProject
  }