<div class="upload-input-layout" [formGroup]="formGroup">
    <label *ngIf="uploadSetting.label">{{ uploadSetting.label | translate }}<span class="required-symbol" *ngIf="uploadSetting.required">*</span></label>
    <!-- upload container -->
    <div class="upload-container" [ngClass]="{'vertical': uploadSetting.verticalLayout, 'dragging': dragging}"  *ngIf="!uploadSetting.maxFileNum || (uploadSetting.maxFileNum && files.length < uploadSetting.maxFileNum)"
        (dragover)="onFileDragOver($event)" (dragleave)="onFileDragLeave($event)" (drop)="onFileDrop($event)">
        <p>{{ 'upload.drag_file_upload' | translate }}</p>
        <button type="button" (click)="onClickUploadFile()" [ngClass]="{'unclickable': formGroup.get(uploadSetting.name).disabled || element?.disabled}">{{ 'upload.file' | translate }}</button>
    </div>
    <div class="preview-container" [ngClass]="{'vertical': uploadSetting.verticalLayout}">
        <p class="error" *ngIf="isMaxSizeError">{{ 'upload.error_max_5mb' | translate }}</p>
        <div class="wrapper" *ngFor="let file of files; let i = index;">
            <!-- by column layout -->
            <div class="by-column" *ngIf="!uploadSetting.verticalLayout; else verticalLayout">
                <div class="preview-img-wrapper" [ngClass]="{'success': success[i], 'fail': fail[i], 'loading': loading[i]}">
                    <mat-icon class="btn-close" (click)="onRemove(i)" *ngIf="!formGroup.get(uploadSetting.name).disabled && !element?.disabled">close</mat-icon>
                    <div [ngStyle]="{ 'background-image': 'url(' + (file?.url? file.url: file) + ')' }" *ngIf="file; else successUpload;"></div>
                    <ng-template #successUpload>
                        <ng-container *ngIf="success[i]; else failUpload;">
                            <i class="pi pi-check-circle"></i>
                        </ng-container>
                    </ng-template>
                    <ng-template #failUpload>
                        <ng-container *ngIf="fail[i]; else loadingUpload;">
                            <i class="pi pi-times-circle"></i>
                        </ng-container>
                    </ng-template>
                    <ng-template #loadingUpload>
                        <ng-container *ngIf="loading[i]; else defaultFile;">
                            <div>
                                <i class="pi pi-spin pi-spinner"></i>
                                <span>{{ progress[i] }}%</span>
                            </div>
                        </ng-container>
                    </ng-template>
                    <ng-template #defaultFile><mat-icon (click)="onRemove(i)" class="file-img">description</mat-icon></ng-template>
                </div>
                <span>{{ file.file && file.file.name? file.file.name: getFileName(file) }}</span>
            </div>
            <ng-template #verticalLayout>
                <!-- by row layout -->
                <div class="by-row" [ngClass]="{'fail': fail[i], 'success': success[i]}">
                    <a [href]="file? file: ''" target="_blank">
                        <span>{{ file.file && file.file.name? file.file.name: getFileName(file) }}</span>
                    </a>
                    <p *ngIf="success[i]; else failUpload;">{{ 'upload.successful' | translate }}</p>
                    <ng-template #failUpload>
                        <ng-container *ngIf="fail[i]; else loadingUpload;">
                            <p>{{ 'upload.failed_try_again' | translate }}</p>
                        </ng-container>
                    </ng-template>
                    <ng-template #loadingUpload>
                        <ng-container *ngIf="loading[i];">
                            <div class="loading">
                                <i class="pi pi-spin pi-spinner"></i>
                                <span>{{ progress[i] }}%</span>
                            </div>
                        </ng-container>
                    </ng-template>
      
                    <div class="btn-close" > 
                        <mat-icon (click)="onRemove(i)" *ngIf="!formGroup.get(uploadSetting.name).disabled && !element?.disabled">close</mat-icon>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <input type='file' id="files" multiple #fileInput [accept]="uploadSetting.acceptFormat" (change)="onFileChange($event)" />
    <mat-form-field [style]="{'width': '100%'}">
        <input matInput type="text" formControlName="{{ uploadSetting.name? uploadSetting.name: '' }}" class="input-uploaded-file" />
        <mat-error *ngIf="formGrpCtrl?.errors?.required">{{uploadSetting.label | translate}}{{'msg.is_required' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field [style]="{'width': '100%'}">
        <input matInput type="text" formControlName="{{ uploadSetting.dataKey? uploadSetting.dataKey: '' }}" class="input-uploaded-file" />
        <mat-error *ngIf="formGrpCtrlData?.errors?.required">{{uploadSetting.label | translate}}{{'msg.is_required' | translate}}</mat-error>
    </mat-form-field>
</div>
