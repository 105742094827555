export * from './activityLog.service';
import { ActivityLogService } from './activityLog.service';
export * from './aliChatAppWebHook.service';
import { AliChatAppWebHookService } from './aliChatAppWebHook.service';
export * from './appNotification.service';
import { AppNotificationService } from './appNotification.service';
export * from './bank.service';
import { BankService } from './bank.service';
export * from './catgAttr.service';
import { CatgAttrService } from './catgAttr.service';
export * from './catgAttrOption.service';
import { CatgAttrOptionService } from './catgAttrOption.service';
export * from './channel.service';
import { ChannelService } from './channel.service';
export * from './channelAddress.service';
import { ChannelAddressService } from './channelAddress.service';
export * from './channelCatgAttr.service';
import { ChannelCatgAttrService } from './channelCatgAttr.service';
export * from './channelPlatformSet.service';
import { ChannelPlatformSetService } from './channelPlatformSet.service';
export * from './channelSection.service';
import { ChannelSectionService } from './channelSection.service';
export * from './channelSectionList.service';
import { ChannelSectionListService } from './channelSectionList.service';
export * from './channelStore.service';
import { ChannelStoreService } from './channelStore.service';
export * from './channelStoreTax.service';
import { ChannelStoreTaxService } from './channelStoreTax.service';
export * from './continent.service';
import { ContinentService } from './continent.service';
export * from './country.service';
import { CountryService } from './country.service';
export * from './ctryArea.service';
import { CtryAreaService } from './ctryArea.service';
export * from './ctryRegion.service';
import { CtryRegionService } from './ctryRegion.service';
export * from './ctryState.service';
import { CtryStateService } from './ctryState.service';
export * from './currency.service';
import { CurrencyService } from './currency.service';
export * from './email.service';
import { EmailService } from './email.service';
export * from './file.service';
import { FileService } from './file.service';
export * from './fileList.service';
import { FileListService } from './fileList.service';
export * from './generalMasterData.service';
import { GeneralMasterDataService } from './generalMasterData.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './merchant.service';
import { MerchantService } from './merchant.service';
export * from './message.service';
import { MessageService } from './message.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export * from './orderDetail.service';
import { OrderDetailService } from './orderDetail.service';
export * from './pDF.service';
import { PDFService } from './pDF.service';
export * from './payout.service';
import { PayoutService } from './payout.service';
export * from './statement.service';
import { StatementService } from './statement.service';
export * from './store.service';
import { StoreService } from './store.service';
export * from './storeAttr.service';
import { StoreAttrService } from './storeAttr.service';
export * from './storeCatgAttr.service';
import { StoreCatgAttrService } from './storeCatgAttr.service';
export * from './storeOH.service';
import { StoreOHService } from './storeOH.service';
export * from './sysPendingJob.service';
import { SysPendingJobService } from './sysPendingJob.service';
export * from './system.service';
import { SystemService } from './system.service';
export * from './taxConditionD.service';
import { TaxConditionDService } from './taxConditionD.service';
export * from './taxConditionF.service';
import { TaxConditionFService } from './taxConditionF.service';
export * from './taxConditionH.service';
import { TaxConditionHService } from './taxConditionH.service';
export * from './xplView.service';
import { XplViewService } from './xplView.service';
export const APIS = [ActivityLogService, AliChatAppWebHookService, AppNotificationService, BankService, CatgAttrService, CatgAttrOptionService, ChannelService, ChannelAddressService, ChannelCatgAttrService, ChannelPlatformSetService, ChannelSectionService, ChannelSectionListService, ChannelStoreService, ChannelStoreTaxService, ContinentService, CountryService, CtryAreaService, CtryRegionService, CtryStateService, CurrencyService, EmailService, FileService, FileListService, GeneralMasterDataService, InvoiceService, MerchantService, MessageService, MetadataService, OrderDetailService, PDFService, PayoutService, StatementService, StoreService, StoreAttrService, StoreCatgAttrService, StoreOHService, SysPendingJobService, SystemService, TaxConditionDService, TaxConditionFService, TaxConditionHService, XplViewService];
