import { ChannelService } from './../../../../swagger/cxmbackend/openapi/api/channel.service';
import { Component, Input, OnInit, Type } from '@angular/core';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
  StatusPanelDef,
} from 'ag-grid-community';

import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { ChannelStatusFlag, ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import {
  OdataProvider,
  OdataProviderOptions,
} from 'ag-grid-odata/build/OdataProvider';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { LocalChannelService } from '../localChannel.service';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { Mode } from 'src/app/shared/enums/mode';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-channel-listing',
  templateUrl: './channel-listing.component.html',
  styleUrls: ['./channel-listing.component.scss'],
  standalone: true,
  imports: [ListingComponent],
})
export class ChannelListingComponent implements OnInit {
  @Input() page: Page;

  pages: Page[];
  screenContainer: any;
  rowData: any = null;
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  listingSetting: ListingSetting;
  formDetail: any = null;

  pageRoute: any = PageRoute;
  channelStatuses: any = ChannelStatusFlag;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'channel.filter', input: true, clickable: false, sortNo: 1, position: ActionToolbarPosition.LEFT },
      { name: 'new', icon: 'assets/base/icons/plus.svg', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
      { name: 'edit', icon: 'assets/base/icons/pencil.svg', text: 'toolbar.edit', checkPermission: [AttributeCode.EDIT], collapseTo: 'others', sortNo: 3  },
    ],
    position: ActionToolbarPosition.RIGHT,
  };

  constructor(
    private pageService: PageService,
    private channelService: LocalChannelService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService

  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe((pages) => {
      this.pages = pages;
      this.screenContainer = document.getElementById('general-container');
    });
  }

  async ngOnInit() {
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.rowData =  (await
      this.channelService.getOdataChannel(null, null, this.formDetail.formCode)
    ).value;
    this.getRowId = (params: GetRowIdParams) => params.data['channelTag'];
    }


  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    editable: false,
  };

  colDef: ColDef[] = [
    {
      ...listingRouterLink,
      field: 'channelId',
      headerName: 'Channel ID',
      cellRendererParams: {
        inRouterLink: {
          route: '/channel/master/entry',
          params: ['channelId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      },
    },
    {
      ...listingRouterLink,
      field: 'channelTag',
      headerName: 'Channel Tag'
    },
    {
      field: 'channelDesc',
      headerName: 'Description',
      filter: true,
    },
    {
      field: 'coverageKM',
      headerName: 'Coverage KM',
      filter: true,
    },
    {
      field: 'platformCode',
      headerName: 'Platform Code',
      filter: true,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      filter: true,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      filter: true,
    },
    {
      field: 'backColor',
      headerName: 'Back Color',
      filter: true,
    },
    {
      field: 'imageLink',
      headerName: 'ImageLink',
      filter: true,
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
      filter: true,
    },
    {
      field: 'timeZone',
      headerName: 'Time Zone',
      filter: true,
    },
    {
      field: 'backImageLink',
      headerName: 'Back Image Link',
      filter: true,
    },
    {
      field: 'foreColor',
      headerName: 'Fore Color',
      filter: true,
    },
    {
      field: 'channelChrgPerc',
      headerName: 'Channel Charge Percentage',
      filter: true,
    },
    {
      field: 'loyaltyChrgPerc',
      headerName: 'Loyalty Charge Percentage',
      filter: true,
    },
    {
      field: 'paymentChrgPerc',
      headerName: 'Payment Charge Percentage',
      filter: true,
    },
    {
      field: 'techChrgPerc',
      headerName: 'Tech Charge Percentage',
      filter: true,
    },
    {
      ...listingDropdown,
      field: 'statusFlag',
      headerName: 'Status',
      cellEditorParams: {
        options: Object.keys(this.channelStatuses).map(
          (key) => this.channelStatuses[key]
        ),
      },
      initialFlex: 10,
    },
  ];

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.CHANNEL_MASTER_ENTRY, null, null, true);
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
      this.rowData = await this.getChannel(filterString);
    }
  }
  async getChannel(filter?: ODataRawQueryOptions) {
    let result = await this.channelService.getOdataChannel(null, filter, this.formDetail.formCode);
    if (!(result instanceof HttpErrorResponse)) {
      return result['value'];
    }
    return null;
  }

  onPageSizeChange(pageSize: number) {}
}
