import { LookupOutletComponent } from "src/app/project-demo/lookup/lookup-outlet/lookup-outlet.component";
import { LookupOutletGrpComponent } from "src/app/project-demo/lookup/lookup-outletGrp/lookup-outletGrp.component";
import { LookupOutletCatgComponent } from "src/app/project-demo/lookup/lookup-outletCatg/lookup-outletCatg.component";
import { LookupOutletCatg2Component } from "src/app/project-demo/lookup/lookup-outletCatg2/lookup-outletCatg2.component";
import { LookupOutletCatg3Component } from "src/app/project-demo/lookup/lookup-outletCatg3/lookup-outletCatg3.component";
import { LookupPosUserComponent } from "./lookup-posUser/lookup-posUser.component";
import { LookupPosTerminalComponent } from "./lookup-posTerminal/lookup-posTerminal.component";
import { LookupProdCategoryComponent } from "./lookup-prodCategory/lookup-prodCategory.component";
import { LookupProdCategory2Component } from "./lookup-prodCategory2/lookup-prodCategory2.component";
import { LookupProdCategory3Component } from "./lookup-prodCategory3/lookup-prodCategory3.component";
import { LookupProdBrandGroupComponent } from "./lookup-prodBrandGroup/lookup-prodBrandGroup.component";
import { LookupProdBrandComponent } from "./lookup-prodBrand/lookup-prodBrand.component";
import { LookupProdDepartmentComponent } from "./lookup-prodDepartment/lookup-prodDepartment.component";
import { LookupProdClassComponent } from "./lookup-prodClass/lookup-prodClass.component";
import { LookupProdSeasonComponent } from "./lookup-prodSeason/lookup-prodSeason.component";
import { LookupProdColorComponent } from "./lookup-prodColor/lookup-prodColor.component";
import { LookupProdSizeComponent } from "./lookup-prodSize/lookup-prodSize.component";
import { LookupProdSizeGroupComponent } from "./lookup-prodSizeGroup/lookup-prodSizeGroup.component";
import { LookupPosOrderTypeComponent } from "./lookup-posOrderType/lookup-posOrderType.component";
import { LookupPosPromotionHComponent } from "./lookup-posPromotionH/lookup-posPromotionH.component";
import { LookupOrderPlatformComponent } from "./lookup-orderPlatform/lookup-orderPlatform.component";
import { LookupProdItemComponent } from "./lookup-prodItem/lookup-prodItem.component";
import { LookupCustomerComponent } from "./lookup-customer/lookup-customer.component";
import { LookupCustomerCatg1Component } from "./lookup-customerCatg1/lookup-customerCatg1.component";
import { LookupCustomerCatg2Component } from "./lookup-customerCatg2/lookup-customerCatg2.component";
import { LookupCustomerCatg3Component } from "./lookup-customerCatg3/lookup-customerCatg3.component";
import { LookupSellPriceTypeComponent } from "./lookup-sellPriceType/lookup-sellPriceType.component";
import { LookupPosTenderComponent } from "./lookup-posTender/lookup-posTender.component";
import { LookupProdProductGroupComponent } from "./lookup-prodProductGroup/lookup-prodProductGroup.component";

export const componentMap: { [key: string]: any } = {

  'LocCode': LookupOutletComponent,
  'LocGrpCode': LookupOutletGrpComponent,
  'LocCatgCode': LookupOutletCatgComponent,
  'LocCatg2Code': LookupOutletCatg2Component,
  'LocCatg3Code': LookupOutletCatg3Component,
  'POSId': LookupPosTerminalComponent,
  'CashierId': LookupPosUserComponent,
  'AuthoriseId': LookupPosUserComponent,
  'BillAuthoriseId': LookupPosUserComponent,
  'LinkPOSId': LookupPosTerminalComponent,
  'CatgCode': LookupProdCategoryComponent,
  'Catg2Code': LookupProdCategory2Component,
  'Catg3Code': LookupProdCategory3Component,
  'BrandGrpCode': LookupProdBrandGroupComponent,
  'BrandCode': LookupProdBrandComponent,
  'DeptCode': LookupProdDepartmentComponent,
  'ClassCode': LookupProdClassComponent,
  'SeasonCode': LookupProdSeasonComponent,
  'ColorCode': LookupProdColorComponent,
  'SizeGrpCode': LookupProdSizeGroupComponent,
  'SizeCode': LookupProdSizeComponent,
  'OrderTypeCode': LookupPosOrderTypeComponent,
  'PromotionCode': LookupPosPromotionHComponent,
  'OrderPlatformCode': LookupOrderPlatformComponent,
  'MasterItemCode': LookupProdItemComponent,
  'ParentItemCode': LookupProdItemComponent,
  'ItemParentCode': LookupProdItemComponent,
  'ItemCode': LookupProdItemComponent,
  'CustomerCode': LookupCustomerComponent,
  'CustCatg1Code': LookupCustomerCatg1Component,
  'CustCatg2Code': LookupCustomerCatg2Component,
  'CustCatg3Code': LookupCustomerCatg3Component,
  'SalesPersonId': LookupPosUserComponent,
  'dSPTCode': LookupSellPriceTypeComponent,
  'TenderCode': LookupPosTenderComponent,
  'ProdGrpCode': LookupProdProductGroupComponent,
  'SalesCatgCode': LookupProdCategoryComponent,
  'SalesBrandCode': LookupProdBrandComponent,
  'SalesClassCode': LookupProdClassComponent
};
