import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { ColDef, IRowNode } from 'ag-grid-community';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { CountryService } from '../../services/country.service';

@Component({
  selector: 'app-lookup-country',
  standalone: true,
  templateUrl: './lookup-country.component.html',
  styleUrls: ['./lookup-country.component.scss'],
  imports: [LookDropInputComponent]
})
export class LookupCountryComponent implements OnInit {
  lookDropInputSetting: LookDropInputSetting;
  formGroup: UntypedFormGroup;
  formData: any;
  multiSelectData: {node?: IRowNode, value?: any}[];
  rowData: any;

  colDef: ColDef[] = [
    {
      field: 'countryCode',
      headerName: 'Currency Code',
      sortable: true,
    },
    {
      field: 'countryDesc',
      headerName: 'Description',
      sortable: true
    },
    {
      field: 'cultureCode',
      headerName: 'Culture Code',
      sortable: true
    },
    {
      field: 'continentCode',
      headerName: 'Continent Code',
      sortable: true
    },
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1 },
      {
        name: 'new',
        icon: 'assets/base/icons/plus.svg',
        text: 'toolbar.new_currency',
        checkPermission: [AttributeCode.NEW],
        sortNo: 2,
        clickable: true
      }
    ]
  };

  constructor(private countryService: CountryService,
    private lookupMasterService: LookupMasterService) {}

  ngOnInit(): void {
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'countryCode',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : 'countryCode',
      colDef: this.lookDropInputSetting.colDef ? this.lookDropInputSetting.colDef: this.colDef,
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey: 'countryDesc',
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : 'general.select_at_least_one',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling? this.lookDropInputSetting.styling: {},
      headerName: this.lookDropInputSetting.headerName? this.lookDropInputSetting.headerName: 'general.country',
      dependency: this.lookDropInputSetting.dependency? this.lookDropInputSetting.dependency: null,
    }
  }

  async getCountry(filter?: string, top?: number) {
    let result = await this.countryService.getCountry(filter, top);
    if (!(result instanceof HttpErrorResponse)) {
      return result['value'];
    }
    return null;
  }

  async onLookup(data: any) {
    // call api and set row
    this.rowData = await this.getCountry();
  }

  async onAutoComplete(autoCompleteVal: any) {
    // set filter
    let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field); // array of property name, eg. ['currCode', 'currDesc']
    let filter = this.lookupMasterService.setFilter(autoCompleteVal, filterBy);
    let top = 10;

    // call api and open autocomplete
    this.rowData = await this.getCountry(filter, top);
  }

  onSelected(selectedNodes: IRowNode[]) {
    if(this.lookDropInputSetting?.multiSelect) {
      // set multiselect display value
      this.multiSelectData = selectedNodes.map((node: IRowNode) => {return {node: node, value: node.data.currCode}});
    }
  }

  async onLookupToolbarAction(data: any) {
    if(data.name === 'filter') {
      let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(data.data, filterBy);
      this.rowData = await this.getCountry(filter);
    }
  }
}
