/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SysUserDataModel { 
    userId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    langCode?: string;
    ownerCode?: string;
    cultureCode?: string;
    isDeleted?: boolean;
    systemFlag?: boolean;
    notifyToken?: string;
    notifyPlatform?: number;
    refNo?: string;
    deviceInfo?: string;
    lastVisitDate?: Date;
    createdDate?: Date;
    createdUserId?: string;
    lastRevDate?: Date;
    lastUserId?: string;
    rowVersion?: string;
}