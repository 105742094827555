import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { Page } from 'src/app/shared/models/page.model';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { cronExpression } from 'src/app/shared/models/cronExpression.model';
import { CronPeriodType } from 'src/app/shared/enums/cronPeriodType';
import { FormSetting } from '../../form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from '../../input/enum/InputTypes';
import { ActionToolBarSetting } from '../../action-tool-bar/model/ActionToolBarSetting.model';
import { ButtonType } from 'src/app/shared/enums/buttonType';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { TranslateService } from '@ngx-translate/core';
import { ComponentService } from 'src/app/shared/services/component.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { SysFormSetService } from 'src/app/shared/services/sys-form-set.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PageService } from 'src/app/shared/services/page.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from '../../popup-message/popup-message.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastData } from 'src/app/core/models/ToastData';
import moment from 'moment';
import { ActionToolBarResp } from '../../action-tool-bar/model/ActionToolBarResp.model';
import { FormComponent } from '../../form/form.component';
import { CardListingComponent } from '../../card-listing/card-listing.component';
import { FormSetHWithSchedulerRequest, SysFormSetHResponse } from 'src/swagger/base-rms/openapi';
import { Weeks } from 'src/app/shared/enums/weeks';
import { CronService } from 'src/app/shared/services/cron.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { LookupSysUserComponent } from 'src/app/project-demo/lookup/lookup-sys-user/lookup-sys-user.component';
import { PopupForm } from 'src/app/shared/models/popupForm.model';
import { ToastType } from 'src/app/shared/enums/toastType';
import { PopupService } from '../../services/popup.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import { ActionToolbarService } from '../../services/action-toolbar.service';

@Component({
  selector: 'app-report-setting-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, CardListingComponent, MatDialogModule],
  templateUrl: './report-setting-entry.component.html',
  styleUrls: ['./report-setting-entry.component.scss']
})
export class ReportSettingEntryComponent implements AfterViewInit {
  @Input() params = null; // can pass param by popupForm model and get here
  @Input() popupFormData?: any;
  @Output() title = new EventEmitter<string>(); // set title to popupForm
  @Output() page: Page; // pass page to popupForm
  @Output() popupFormSetting = new EventEmitter<Partial<PopupForm>>(); // pass page to popupForm

  toggleChange$: Subscription;
  popupFormDelete$: Subscription;
  popupFormClose$: Subscription;
  popupMsgAction$: Subscription;
  noPermissionPopup$: Subscription;

  formCode: FormCode;
  formData: any;
  weekOption: any[] = [];
  periodTypeOption: any[] = [];
  formGrp: UntypedFormGroup;
  newDescFormGrp: UntypedFormGroup;
  cronPeriodOption: any[] = [];
  cronExp: string;
  cronExpObj: cronExpression;
  curCronPeriod: CronPeriodType;
  editMode: boolean = false;
  newDesc: boolean = false;
  userList: any[] = [];
  retrictUserOption = [{value: 2, name: 'Include'}, {value: 1, name: 'Exclude'}, {value: 0, name: 'None'}];
  currDate: string;
  currTime: string;

  formSetting: FormSetting = {
    formCode: FormCode.salesReport,
    content: [
      {
        inputText: {
          name: 'setDesc',
          label: 'report.description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        lookDropInput: {
          name: 'restrictUserFlag',
          label: 'report.restrict_user',
          autoComplete: false,
          dropdownOption: this.retrictUserOption,
          valueKey: 'value',
          displayValueKey: 'name',
          styling: {
            width: '100%'
          },
          required: true
        }
      },
      {
        lookDropInput: {
          name: 'userId',
          label: 'report.users',
          componentName: LookupSysUserComponent,
          multiSelect: true,
          autoComplete: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        buttonToggle: {
          name: 'settingDefaults',
          label: 'report.setting_defaults',
          option: [{key: 'personal', name: 'Personal'}, {key: 'global', name: 'Global'}, {key: 'inactive', name: 'Inactive'}],
          multiple: true
        }
      },
      {
        expansionPanel: {
          name: 'scheduleFlag',
          disabled: true,
          title: [
            {
              toggleInput: {
                name: 'scheduleFlag',
                label: 'report.schedule_report',
                inline: true,
                styling: {
                  width: '100%'
                }
              }
            }
          ],
          content: [
            {
              toggleInput: {
                name: 'scheduleType',
                label: 'report.repeat_schedule',
                inline: true,
                styling: {
                  padding: '0 0 16px 0',
                  color: '#3D3D59'
                }
              }
            },
            {
              buttonToggle: {
                name: 'periodType'
              }
            },
            {
              buttonToggle: {
                name: 'weekly',
                label: 'report.generate_report_every',
                circleButton: true,
                hidden: true,
                required: true
              }
            },
            {
              dateInput: {
                name: 'scheduledDateTime',
                label: 'report.generate_report_on',
                type: InputTypes.date,
                // minDate: new Date(),
                pattern: 'dd/MM/yy',
                styling: {
                  width: '100%',
                },
                hidden: true,
                errors: [
                  {name: 'required', errMsg: 'msg.date_required' }
                ],
                required: true
              }
            },
            {
              periodInput: {
                name: 'generatePeriod',
                label: 'report.generate_report_every',
                styling: {
                  width: '100%'
                },
                hidden: true
              }
            },
            {
              timeInput: {
                name: 'generateTime',
                label: 'report.exactly_at',
                styling: {
                  width: '100%'
                },
                errors: [
                  {name: 'required', errMsg: 'msg.time_required' },
                  {name: 'exceedDateTime', errMsg: 'msg.past_dateTime' }
                ],
                required: true
              }
            },
            {
              lookDropInput: {
                name: 'email',
                label: 'report.recipient_email',
                type: InputTypes.email,
                manualInput: true,
                multiSelect: true,
                dropdownOption: [],
                autoComplete: false,
                styling: {
                  width: '100%'
                },
                required: true
              }
            },
            {
              inputText: {
                name: 'accessCode',
                label: "general.password",
                type: InputTypes.password,
                styling: {
                  width: '100%'
                }
              }
            }
          ]
        }
      }
    ]
  }

  newDescFormSetting: FormSetting = {
    formCode: FormCode.salesReport,
    popup: true,
    content: [
      {
        inputText: {
          name: 'setDesc',
          label: 'report.description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      }
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { id: 'btn-cancel', name: 'cancel', text: 'toolbar.cancel', btnType: ButtonType.button, sortNo: 1, position: ActionToolbarPosition.RIGHT },
      { id: 'btn-save', name: 'save', text: 'toolbar.save', btnType: ButtonType.submit, iconStyling: {'width': '20px'}, sortNo: 2, buttonActive: true, position: ActionToolbarPosition.RIGHT },
      { id: 'btn-save-as', name: 'saveAs', text: 'toolbar.save_as', btnType: ButtonType.button, iconStyling: {'width': '20px'}, sortNo: 2, buttonActive: true, position: ActionToolbarPosition.RIGHT, hide: true }
    ]
  }

  newDescActionToolbarSetting: ActionToolBarSetting = {
    popup: true,
    actionToolBarItems: [
      { id: 'btn-desc-cancel', name: 'cancel', text: 'toolbar.cancel', btnType: ButtonType.button, sortNo: 1, position: ActionToolbarPosition.RIGHT },
      { id: 'btn-desc-save', name: 'save', text: 'toolbar.save', btnType: ButtonType.submit, iconStyling: {'width': '20px'}, sortNo: 2, buttonActive: true, position: ActionToolbarPosition.RIGHT },
    ]
  }

  constructor(private translateService: TranslateService,
    private componentService: ComponentService,
    private popupFormService: PopupFormService,
    private loaderService: LoaderService,
    private sysFormSetService: SysFormSetService,
    private dialog: MatDialog,
    private pageService: PageService,
    private toastService: ToastService,
    private cronService: CronService,
    private cd: ChangeDetectorRef,
    private reportService: ReportService,
    private datePipe: DatePipe,
    private popupService: PopupService,
    private actionToolbarService: ActionToolbarService
  ) {}

  async ngOnInit() {
    this.formCode = this.popupFormData?.formCode;
  
    this.toggleChange$ = this.componentService.toggleChange$.subscribe((event: any) => {
      let expansionPanel = this.formSetting.content.find((content: any) => content.expansionPanel);

      if(event.name === 'scheduleFlag') {
        this.componentService.toggleExpansionPanel$.next({name: event.name, expand: event.checked});

        if(event.checked) {
          this.curCronPeriod = this.cronPeriodOption[0].key;
          this.formGrp.controls['periodType']?.setValue(this.cronPeriodOption[0].key);

          this.componentService.setRequired('email', true);
          this.componentService.setRequired('generateTime', true);

          this.formGrp.get('generateTime').updateValueAndValidity({emitEvent: false});
          this.formGrp.get('email').updateValueAndValidity({emitEvent: false});
        } else {
          this.formGrp.get('scheduledDateTime').setErrors(null);
          this.formGrp.get('generateTime').setErrors(null);
          this.formGrp.get('email').setErrors(null);
          
          this.componentService.setRequired('email', false);
          this.componentService.setRequired('generateTime', false);
        }
      }

      if(event.name === 'scheduleType') {
        let runOnlyOnceDateInput = expansionPanel?.expansionPanel?.content?.find((content: any) => content.dateInput && content.dateInput.name === 'scheduledDateTime');
        let customPeriod = expansionPanel?.expansionPanel?.content?.find((content: any) => content.periodInput && content.periodInput.name === 'generatePeriod');
        let periodButtonToggle = expansionPanel?.expansionPanel?.content?.find((content: any) => content.buttonToggle && content.buttonToggle.name === 'periodType');

        runOnlyOnceDateInput.dateInput.hidden = this.curCronPeriod === CronPeriodType.custom? event.checked: true;
        customPeriod.periodInput.hidden = this.curCronPeriod === CronPeriodType.custom? !event.checked: true;

        let titleCasePipe = new TitleCasePipe();
        this.cronPeriodOption = Object.keys(CronPeriodType).map((type: any) => {return {key: type, name: titleCasePipe.transform(type)}});

        if(event.checked) {
          this.formGrp.controls['generatePeriod'].setValue(this.periodTypeOption[0].value);
        } else {
          this.formGrp.controls['periodType']?.setValue(this.cronPeriodOption[0].key);
        }

        periodButtonToggle.buttonToggle.hidden = !event.checked;
      }

      if(this.formGrp.get('scheduleFlag')?.value && !this.formGrp.get('scheduleType')?.value  && this.curCronPeriod === CronPeriodType.custom) {
        this.componentService.setRequired('scheduledDateTime', true);
        this.formGrp.get('scheduledDateTime').updateValueAndValidity({emitEvent: false});
        // this.validateScheduledDateTime();
      } else {
        this.formGrp.get('scheduledDateTime').setErrors(null);
        this.formGrp.get('scheduledDateTime').clearValidators();
        this.componentService.setRequired('scheduledDateTime', false);
      }
    });

    // open delete confirmation popup
    this.popupFormDelete$ = this.popupFormService.delete$.subscribe((event: any) => {
      if(event && this.params?.setId) {
        let msg: PopupMessage = {
          formCode: FormCode.salesReport,
          titleIcon: 'assets/base/icons/warning-orange.svg',
          title: 'msg.delete_confirmation',
          desc: this.translateService.instant("msg.confirm_delete", {form: this.formData?.setDesc}),
          actionBtnText: 'general.yes',
          closeBtnText: 'general.cancel',
        };

        this.dialog.open(PopupMessageComponent, {
          data: msg
        });
      }
    });

    // delete action, call api
    this.popupMsgAction$ = this.pageService.actionPopupMsg$.subscribe(async (popupMsg: any) => {
      if(popupMsg.formCode === FormCode.salesReport) {
        let result = await this.sysFormSetService.deleteReportSetting(this.formCode, this.params.setId);

        if(!(result instanceof HttpErrorResponse)) {
          this.popupFormService.closeById('reportSetting');
          this.reportService.getRptSetting$.next();

          let toast: ToastData = {
            name: 'deleteReportSetting',
            type: ToastType.Success,
            message: 'msg.success_deleted'
          };
          this.toastService.show(toast);

          this.reportService.resetRptSetting$.next(this.params.setId);
        } else if(result?.status === 403) {
          let popupMsg: PopupMessage = {
            titleIcon: 'assets/base/icons/exclamation-circle.svg',
            title: 'msg.permission_denied',
            desc: this.translateService.instant('msg.rpt_no_permission', {action: this.translateService.instant("toolbar.delete")}),
            actionBtnText: 'general.okay',
          };
    
          this.popupService.getDialogResp(popupMsg);
        }
      }
    });

    // close popup
    this.popupFormClose$ = this.popupFormService.triggerClose$.subscribe((val: any) => {
      if(this.formCode === val.formCode) {
        if(this.newDesc) {
          this.newDesc = false;
        } else {
          this.popupFormService.closeById('reportSetting');
        }
      }
    });

    let expansionPanel = this.formSetting.content.find((content: any) => content.expansionPanel);

    // Cron Period options
    let periodButtonToggle = expansionPanel?.expansionPanel?.content?.find((content: any) => content.buttonToggle && content.buttonToggle.name === 'periodType');
    let titleCasePipe = new TitleCasePipe();

    if(periodButtonToggle && CronPeriodType) {
      this.cronPeriodOption = Object.keys(CronPeriodType).map((type: any) => {return {key: type, name: titleCasePipe.transform(type)}});
      periodButtonToggle.buttonToggle.option = this.cronPeriodOption;
      periodButtonToggle.buttonToggle.hidden = true;
    }

    // Week options
    let weekButtonToggle = expansionPanel?.expansionPanel?.content?.find((content: any) => content.buttonToggle && content.buttonToggle.name === 'weekly');

    if(weekButtonToggle && Weeks) {
      Object.keys(Weeks).forEach((key: any) => {
        let val = key? key.charAt(0): ''
        this.weekOption.push({key: key, name: val.toUpperCase()});
      });

      weekButtonToggle.buttonToggle.option = this.weekOption;
    }

    // Period type option
    this.periodTypeOption = [{name: 'general.month', value: 'month'}, {name: 'general.year', value: 'year'}];

    // Get report setting
    if(this.params?.setId) {
      this.loaderService.startLoading();
      let result: SysFormSetHResponse = await this.sysFormSetService.getReportSetting(this.formCode, this.params.setId);

      if(!(result instanceof HttpErrorResponse)) {
        this.setData(result);
      }

      this.loaderService.stopLoading();
    }
  }

  setData(result: SysFormSetHResponse) {
    let setData: any = result?.setData? JSON.parse(result.setData): '';
    let scheduler = setData?.Scheduler? setData.Scheduler: '';

    let scheduleDefault = [];
    if(result.defaultFlag) {
      scheduleDefault.push('global');
    }

    if(result.userDefaultFlag) {
      scheduleDefault.push('personal');
    }

    if(result.inactiveFlag) {
      scheduleDefault.push('inactive');
    }

    // save as button handling
    let toolbarItm = this.actionToolbarSetting?.actionToolBarItems;

    if(toolbarItm && toolbarItm.length > 0) {
      let saveBtn = toolbarItm.find((itm: any) => itm.name === 'save');

      if(result?.updateRight) {
        saveBtn.icon = 'assets/base/icons/chevron-down.svg';
        saveBtn.dropdownItm = [
          { id: 'btn-save-as', name: 'saveAs', text: 'toolbar.save_as', btnType: ButtonType.button, sortNo: 1, position: ActionToolbarPosition.LEFT },
        ];

        this.actionToolbarSetting = {...this.actionToolbarSetting};
      } else {
        this.actionToolbarSetting = this.actionToolbarService.hide(this.actionToolbarSetting, ['save']);
        this.actionToolbarSetting = this.actionToolbarService.show(this.actionToolbarSetting, ['saveAs']);
      }
    }

    let cronExp = this.cronService.getCronType(scheduler.CronExpression);
    this.userList = result?.sysFormSetUs? result.sysFormSetUs.map(user => user.userId.toString()): [];

    let date: Date;
    if(scheduler?.ScheduledDateTime) {
      date = scheduler?.ScheduledDateTime;
    }

    this.formData = {
      setDesc: result?.setDesc,
      restrictUserFlag: result?.restrictUserFlag,
      email: scheduler?.Email? scheduler.Email: '',
      scheduleFlag: scheduler?.ScheduleFlag? true: false,
      cronExpression: scheduler?.CronExpression? scheduler.CronExpression: '',
      accessCode: scheduler?.AccessCode? scheduler.AccessCode: '',
      settingDefaults: scheduleDefault,
      scheduleType: scheduler?.ScheduleType? scheduler.ScheduleType: 0,
      scheduledDateTime: scheduler?.ScheduledDateTime? new Date(scheduler.ScheduledDateTime): '',
      expiryDay: scheduler?.ExpiryDay? scheduler.ExpiryDay: '',
      periodType: scheduler?.ScheduleType? (cronExp? cronExp.cronType: ''): CronPeriodType.custom,
      generatePeriod: cronExp? cronExp.periodType: '',
      generateTime: scheduler?.ScheduledDateTime? this.datePipe.transform(new Date(scheduler.ScheduledDateTime), 'HH:mm'): cronExp? cronExp.time: '00:00',
      day: cronExp?.day || cronExp?.day === 0? cronExp.day: '',
      month: cronExp?.month? cronExp.month: '',
      weekly: cronExp?.week? this.weekOption[cronExp.week - 1].key: '',
      sysFormSetUs: result?.sysFormSetUs? result.sysFormSetUs: [],
    };

    this.cd.detectChanges();
  }

  onGetFormGrp(event: any) {
    this.formGrp = event;

    // Set default value
    if(this.formGrp.get('restrictUserFlag')) {
      this.formGrp.controls['restrictUserFlag'].setValue(this.retrictUserOption[this.retrictUserOption.length - 1]?.value);
    }

    if(this.formGrp.get('scheduleType')) {
      this.formGrp.controls['scheduleType'].setValue(false);
    }
  }

  ngAfterViewInit(): void {
    if(!this.formGrp.get('scheduleFlag')?.value) {
      this.componentService.setRequired('email', false);
      this.componentService.setRequired('generateTime', false);
      this.componentService.setRequired('scheduledDateTime', false);
    }
  }

  onGetNewDescFormGrp(event: any) {
    this.newDescFormGrp = event;
  }

  onButtonToggle(event: any) {
    let weeks = this.weekOption? this.weekOption.map((week: any) => week.key): [];
    let periodTypes = this.cronPeriodOption? this.cronPeriodOption.map((type: any) => type.key): [];

    if(!periodTypes.includes(event) && !weeks.includes(event)) return;

    if(periodTypes.includes(event)) {
      let expansionPanel = this.formSetting.content.find((content: any) => content.expansionPanel);
      let weeklyButtonToggle = expansionPanel?.expansionPanel?.content?.find((content: any) => content.buttonToggle && content.buttonToggle.name === 'weekly');
      let timePicker = expansionPanel?.expansionPanel?.content?.find((content: any) => content.timeInput && content.timeInput.name === 'generateTime');
      let customPeriod = expansionPanel?.expansionPanel?.content?.find((content: any) => content.periodInput && content.periodInput.name === 'generatePeriod');
      let runOnlyOnceDateInput = expansionPanel?.expansionPanel?.content?.find((content: any) => content.dateInput && content.dateInput.name === 'scheduledDateTime');

      timePicker.timeInput.required = true;

      weeklyButtonToggle.buttonToggle.hidden = true;
      weeklyButtonToggle.buttonToggle.required = false;

      customPeriod.periodInput.hidden = true;
      customPeriod.periodInput.required = false;

      runOnlyOnceDateInput.dateInput.hidden = true;

      this.curCronPeriod = event;
      this.cronExpObj = null;
      this.cronExp = '';

      this.formGrp.controls['generateTime']?.setValue('00:00');

      if(event === CronPeriodType.weekly) {
        weeklyButtonToggle.buttonToggle.hidden = false;
        weeklyButtonToggle.buttonToggle.required = true;
        this.formGrp.controls['weekly']?.setValue(this.weekOption[0].key);
        this.componentService.setRequired('schduledDateTime', false);
        this.formGrp.controls['scheduledDateTime'].setErrors(null);
      } else if(event === CronPeriodType.custom) {
        let scheduleType = this.formGrp.get('scheduleType')?.value;
        customPeriod.periodInput.hidden = !scheduleType;
        customPeriod.periodInput.required = true;
        customPeriod.periodInput.typeOption = this.periodTypeOption;
        runOnlyOnceDateInput.dateInput.hidden = scheduleType;
        this.formGrp.controls['generatePeriod'].setValue(this.periodTypeOption[0].value);

        if(scheduleType) {
          this.componentService.setRequired('scheduledDateTime', false);
          this.formGrp.controls['scheduledDateTime'].setErrors(null);
        } else if(this.formGrp?.get('scheduleFlag')?.value) {
          this.componentService.setRequired('scheduledDateTime', true);
          this.formGrp.get('scheduledDateTime').updateValueAndValidity({emitEvent: false});
        }
      } else if(event === CronPeriodType.daily) {
        this.componentService.setRequired('scheduledDateTime', false);
        this.formGrp.controls['scheduledDateTime'].setErrors(null);
      }

      this.cd.detectChanges();
    }

    if(weeks.includes(event)) {
      this.cronExp = '';

      let weekIndex = this.weekOption.findIndex(week => week.key === event);

      if(weekIndex >= 0) {
        this.cronExpObj = {
          week: weekIndex + 1,
          hr: this.cronExpObj?.hr? this.cronExpObj.hr: 0,
          min: this.cronExpObj?.hr? this.cronExpObj.min: 0
        };
        this.cronExp = this.cronService.computeCron(this.curCronPeriod, this.cronExpObj);
      }
    }
  }

  onDateChange(event: any) {
    if(this.curCronPeriod === CronPeriodType.custom && this.formGrp.get('scheduleFlag')?.value) {
      // this.validateScheduledDateTime();
    }
  }

  onTimeChange(event: any) {
    let time = event.split(":");
    let hr = time && time[0]? time[0]: '';
    let min = time && time[1]? time[1]: '';
    this.cronExp = '';

    if(this.curCronPeriod === CronPeriodType.daily) {
      this.cronExpObj = null;
      this.cronExp = this.cronService.computeCron(this.curCronPeriod, {hr: hr, min: min});
    } else if(this.curCronPeriod === CronPeriodType.custom || this.curCronPeriod === CronPeriodType.weekly) {
      this.cronExpObj = {
        ...this.cronExpObj,
        hr: hr,
        min: min
      };
      this.cronExp = this.cronService.computeCron(this.curCronPeriod, this.cronExpObj);
    }

    if(this.curCronPeriod === CronPeriodType.custom && this.formGrp.get('scheduleFlag')?.value) {
      // this.validateScheduledDateTime();
    }
  }

  onPeriodChange(event: any) {
    let months = moment.months();
    let month = months.findIndex((month: string) => month === event.month);

    if(event && (event.month || event.day || event.day === 0) && this.curCronPeriod) {
      this.cronExpObj = {
        ...this.cronExpObj,
        month: month >= 0? month + 1: undefined,
        day: event?.day === 0? 'L': event?.day? event.day: undefined
      }

      this.cronExp = this.cronService.computeCron(this.curCronPeriod, this.cronExpObj);
    }
  }

  onNewDescToolbarAction(resp: ActionToolBarResp) {
    if(resp.name === 'cancel') {
      this.newDesc = false;
      this.title.emit(this.translateService.instant('report.report_setting'));
    }
  }

  onToolbarAction(resp: ActionToolBarResp) {
    this.editMode = false;

    if(resp.name === 'cancel') {
      this.popupFormService.closeById('reportSetting');
    } else if(resp.name === 'save' && this?.params?.setId) {
      this.editMode = true;
    } else if(resp.name === 'saveAs') {
      if(this.formGrp.valid) {
        this.newDesc = true;
        this.title.emit(this.translateService.instant('report.enter_new_desc'));
        this.popupFormSetting.emit({deleteBtn: false});
      } else {
        this.formGrp.markAllAsTouched();
      }
    }
  }

  getScheduledDateTime() {
    let date: Date;

    if(this.formGrp.get('scheduledDateTime')?.value && this.formGrp.get('generateTime')?.value) {
      const date: Date = this.formGrp.get('scheduledDateTime').value;
      const year: number = moment(date).year();
      const month: number = moment(date).month();
      const day: number = moment(date).date();
      const time: string = this.formGrp.get('generateTime').value?.split(":");
      const hr: number = time && time[0]? parseInt(time[0]): 0;
      const min: number = time && time[1]? parseInt(time[1]): 0;
      return new Date(year, month, day, hr, min);
    }

    return date;
  }

  validateScheduledDateTime() {
    const date: Date = this.getScheduledDateTime();
    const diff = moment(date).diff(moment(new Date()));

    if(diff < 0) {
      this.formGrp.get('generateTime').markAllAsTouched();
      this.formGrp.get('scheduledDateTime').markAllAsTouched();
      this.formGrp.get('generateTime').setErrors({'exceedDateTime': true});
      this.formGrp.get('scheduledDateTime').setErrors({'exceedDateTime': true});
    } else {
      this.formGrp.get('generateTime').setErrors({'exceedDateTime': false});
      this.formGrp.get('scheduledDateTime').setErrors({'exceedDateTime': false});
      this.formGrp.get('generateTime').updateValueAndValidity({emitEvent: false});
      this.formGrp.get('scheduledDateTime').updateValueAndValidity({emitEvent: false});
    }
  }

  setReq(formGroup: any): FormSetHWithSchedulerRequest {
    let addUserList: any[] = this.formGrp.get('userId')?.value? this.formGrp.get('userId')?.value: [];
    let delUserList: any[] = [];

    // filter add and delete user list
    if(this.userList && this.userList.length > 0) {
      addUserList?.map(addUser => !this.userList?.includes(addUser));

      this.userList?.forEach(user => {
        if(!addUserList.includes(user)) {
          delUserList.push(user);
        }
      });
    }

    let scheduledDateTime: any;

    if(!formGroup.get('scheduleType')?.value && formGroup.get('scheduleFlag')?.value) {
      const date: Date = this.getScheduledDateTime();
      scheduledDateTime = date?.toISOString();
    }

    let req: FormSetHWithSchedulerRequest = {
      schedulerSet: {
        scheduleFlag: formGroup.get('scheduleFlag')?.value,
        email: formGroup.get('email')?.value? formGroup.get('email').value: [],
        scheduleType: formGroup.get('scheduleType')?.value? 1: 0,
        cronExpression: formGroup.get('scheduleType')?.value? this.cronExp: '',
        scheduledDateTime: scheduledDateTime? scheduledDateTime: '',
        accessCode: formGroup.get('accessCode')?.value
      },
      schedulerRptRequest: this.popupFormData.schedulerRptRequest,
      setDesc: formGroup.get('setDesc').value,
      userDefaultFlag: formGroup.get('settingDefaults').value.includes('personal'),
      defaultFlag: formGroup.get('settingDefaults').value.includes('global'),
      inactiveFlag: formGroup.get('settingDefaults').value.includes('inactive'),
      restrictUserFlag: formGroup.get('restrictUserFlag').value,
      setData: this.popupFormData?.setData,
      userAddList: addUserList,
      userDelList: delUserList
    }

    return req;
  }

  onNewDescSubmitForm(formGroup: any) {
    this.editMode = false;
    this.formGrp.get('setDesc')?.setValue(formGroup.get('setDesc').value);
    this.onSubmitForm(this.formGrp);
  }

  async onSubmitForm(formGroup: any) {
    let result: SysFormSetHResponse = null;
    this.loaderService.startLoading();

    let toast: ToastData;
    let req: FormSetHWithSchedulerRequest = this.setReq(formGroup);

    if(this.editMode) {
      req = {
        ...req,
        setId: this.params.setId,
        ctrlId: '',
      };
      result = await this.sysFormSetService.updateReportSetting(this.formCode, req);

      toast = {
        name: 'updateReportSetting',
        type: ToastType.Success,
        message: 'msg.success_updated'
      };
    } else {
      result = await this.sysFormSetService.addReportSetting(this.formCode, req);

      toast = {
        name: 'addReportSetting',
        type: ToastType.Success,
        message: 'msg.success_added'
      };
    }

    if(!(result instanceof HttpErrorResponse)) {
      this.popupFormService.closeById('reportSetting', result);
      this.toastService.show(toast);
      this.reportService.getRptSetting$.next(result?.setId);
    } else if(result?.status === 403) {
      let popupMsg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.permission_denied',
        desc: this.translateService.instant('msg.rpt_no_permission', {action: this.translateService.instant("toolbar.save")}) + "<br><br>" + this.translateService.instant('msg.use_save_as'),
        actionBtnText: 'general.okay',
      };

      this.noPermissionPopup$ = this.popupService.getDialogResp(popupMsg).subscribe(async (result) => {
        if(result === PopupAction.actionBtn) {
          this.actionToolbarSetting = this.actionToolbarService.hide(this.actionToolbarSetting, ['save']);
          this.actionToolbarSetting = this.actionToolbarService.show(this.actionToolbarSetting, ['saveAs']);
        }
      });
    }

    this.loaderService.stopLoading();
  }

  ngOnDestroy(): void {
    this.toggleChange$?.unsubscribe();
    this.popupFormDelete$?.unsubscribe();
    this.popupFormClose$?.unsubscribe();
    this.popupMsgAction$?.unsubscribe();
    this.noPermissionPopup$?.unsubscribe();
  }
}
