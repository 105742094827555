import { APP_INITIALIZER, enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AgGridModule } from 'ag-grid-angular';
import {
  ConfigurationParameters,
  Configuration,
  ApiModule,
} from 'src/swagger/base/openapi';
import { ApiModule as ApiModule2 } from 'src/swagger/base-rms/openapi';
import { AngularFireModule } from '@angular/fire/compat';
import { SharedModule } from './app/shared/shared.module';
import {
  TranslateCacheModule,
  TranslateCacheService,
  TranslateCacheSettings,
} from 'ngx-translate-cache';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import {
  withInterceptorsFromDi,
  provideHttpClient,
  HttpBackend,
} from '@angular/common/http';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LayoutModule } from '@angular/cdk/layout';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import 'ag-grid-enterprise';
import { LicenseManager } from  'ag-grid-enterprise';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMAT } from './app/core/services/utils.service';
import { CoreModule } from './app/core/core.module';
import { AnimationLoader, provideLottieOptions } from 'ngx-lottie';

const configurationFactory = () => {
  const configParams: ConfigurationParameters = {
    basePath: environment.hostPath,
  };
  return new Configuration(configParams);
};

export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/base/i18n/',
    './assets/i18n/',
  ]);
}

export function TranslateCacheFactory(
  translateService: TranslateService,
  translateCacheSettings: TranslateCacheSettings
) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}

function appInitializer(translateService: TranslateService): () => Promise<any> {
  return () => {
    return new Promise((resolve) => {
      translateService.setDefaultLang('en-US');
      translateService.use('en-US').subscribe(() => {
        resolve(true);
      });
    });
  };
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      CoreModule.forRoot(),
      AppRoutingModule,
      LayoutModule,
      ScrollingModule,
      ReactiveFormsModule,
      FormsModule,
      DropdownModule,
      InputTextModule,
      PaginatorModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
      TranslateCacheModule.forRoot({
        cacheService: {
          provide: TranslateCacheService,
          useFactory: TranslateCacheFactory,
          deps: [TranslateService, TranslateCacheSettings],
        },
      }),
      SharedModule,
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AgGridModule,
      ApiModule.forRoot(configurationFactory),
      ApiModule2.forRoot(configurationFactory),
    ),
    MatDatepickerModule,
    DatePipe,
    MessageService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    AnimationLoader,
    {
      provide: APP_INITIALIZER,
      useFactory: () => appInitializer(inject(TranslateService)),
      multi: true,
    },
  ],
}).catch((err) => console.error(err));

LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061148}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Evoloper_Sdn_Bhd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{zeoniq}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{zeoniq}_need_to_be_licensed___{zeoniq}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{4_June_2025}____[v3]_[0102]_MTc0ODk5MTYwMDAwMA==b3a61a1aef8cfba34fdd79c923b25e3a");
