import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseWidgetComponent } from "./license-widget/license-widget.component";

@Component({
    selector: 'app-widget',
    standalone: true,
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.scss'],
    imports: [CommonModule, LicenseWidgetComponent]
})
export class WidgetComponent {

}
