import { Injectable } from '@angular/core';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatementService {

  constructor(
    private customService: CustomService,
    private loaderService: LoaderService
  ) { }

  async getOdataStatement(data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataStatementData(data, formCode);
  }

  async reqOdataStatementData(data?: ODataRawQueryOptions, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.statement.OdataStatement,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: data?.filter.replace('$filter=', '')
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}
