import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
} from 'ag-grid-community';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { Page } from 'src/app/shared/models/page.model';
import { Mode } from 'src/app/shared/enums/mode';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalChannelService } from 'src/app/cxm-backend-web/channel/localChannel.service';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-channelstoretax-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './channelstoretax-listing.component.html',
  styleUrls: ['./channelstoretax-listing.component.scss'],
})
export class ChannelStoreTaxListingComponent implements OnInit {
  @Input() page: Page;

  pages: Page[];
  screenContainer: any;
  rowData: any = null;
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  selectedRowData: any;
  selectedRow: any;
  refresh$: Subscription;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  formDetail: any = null;

  storeId: number | null;
  channelId: number | null;

  // Column data
  colDef: ColDef[] = [];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      ...toolbarListing,
      { name: 'new', icon: 'assets/base/icons/plus.svg', text: 'toolbar.new_channel_store_tax', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
    ],
    position: ActionToolbarPosition.RIGHT
  };

  constructor(
    private pageService: PageService,
    private channelApiService: LocalChannelService,
    private formService: FormService
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe((pages) => {
      this.pages = pages;
      this.screenContainer = document.getElementById('general-container');
    });
  }

  ngOnInit() {
    // Get route parameter
    let params = this.pageService.getParameterDecode(this.page);
    this.channelId = params['channelId'];
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    console.log(params);
    this.colDef = [
      listingCheckbox,
      {
        ...listingRouterLink,
        field: 'channelDesc',
        headerName: 'channelstoretax.channel_desc',
        cellRendererParams: {
          inRouterLink: {
            route: '/channel/channelstoretax/entry',
            params: ['channelId', 'storeId', 'taxLine'],
            queryParams: { mode: Mode.edit },
          },
        },
      },
      {
        field: 'locDesc',
        headerName: 'channelstoretax.store_name',
        filter: true,
      },
      {
        field: 'taxLine',
        headerName: 'channelstoretax.tax_line',
        filter: true,
      },
      {
        field: 'taxCondCode',
        headerName: 'channelstoretax.tax_cond_code',
        filter: true,
      },
      {
        field: 'taxCondDesc',
        headerName: 'channelstoretax.tax_cond_desc',
        filter: true,
      },
      {
        field: 'dineinFlag',
        headerName: 'channelstoretax.dinein_flag',
        filter: true,
      },
      {
        field: 'pickupFlag',
        headerName: 'channelstoretax.pickup_flag',
        filter: true,
      },
      {
        field: 'deliveryFlag',
        headerName: 'channelstoretax.delivery_flag',
        filter: true,
      },
      {
        field: 'takeawayFlag',
        headerName: 'channelstoretax.takeaway_flag',
        filter: true,
      },
      {
        field: 'transportModeList',
        headerName: 'channelstoretax.transport_mode_list',
        filter: true,
      },
      {
        field: 'effectiveDate',
        headerName: 'channelstoretax.effective_date',
        filter: true,
      },
      {
        field: 'obsoleteDate',
        headerName: 'channelstoretax.obsolete_date',
        filter: true,
      }
    ];

    this.getChannelStoreTaxData();

     // Listen to refresh event
     this.refresh$ = this.pageService.refreshListing$.subscribe(
      (formCode: any) => {
        if (formCode.includes(this.formDetail.formCode)) {
          this.getChannelStoreTaxData();
        }
      }
    );
  }

  async getChannelStoreTaxData() {
    this.rowData = this.channelApiService.reqOdataChannelStoreTaxData(
      this.channelId, this.storeId, null, this.formDetail.formCode
    );
    this.getRowId = (params: GetRowIdParams) => params.data['channelId'];
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByUrl(PageRoute.CHANNEL_CHANNELSTORETAX_ENTRY, {
        channelId: this.channelId,
      });
    }

    if (
      value.name === 'delete'
      && this.selectedRowData
      && this.selectedRowData.data['storeId']
      && this.selectedRowData.data['taxLine']
    ) {
      let result = null;

      result = await this.channelApiService.deleteChannelStoreTax(
        this.channelId,
        this.selectedRowData.data['storeId'],
        this.selectedRowData.data['taxLine'],
        this.formDetail.formCode
      );


      if (result instanceof HttpErrorResponse === false) {
        this.getChannelStoreTaxData();
        this.selectedRowData.setSelected(false);
      }
    }
  }

  onRowSelect(nodes: IRowNode[]) {
    this.selectedRowData = nodes[0] ? nodes[0] : null;
  }

  onPageSizeChange(pageSize: number) {}
}
