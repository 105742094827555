import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FilterExpression, FilterModule } from '@progress/kendo-angular-filter';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  toODataString,
} from '@progress/kendo-data-query';
import { FilterEditor } from '@progress/kendo-angular-filter/model/filter-expression';
import { CustomFilterExpression } from './model/customFilterExpression.mode';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { NgIf } from '@angular/common';
import { LookDropComponent } from 'src/app/shared/component/input/look-drop/look-drop.component';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';

@Component({
  selector: 'app-filter-custom',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FilterModule, LookDropComponent, DropDownsModule, DatePickerModule, NgIf],
})
export class CustomFilterComponent implements OnInit {
  @Input() filters: FilterExpression[];
  @Input() value: CompositeFilterDescriptor = {
    logic: null,
    filters: [],
  };
  @Input() gotEmail: boolean;
  @Input() gotReset: boolean;
  @Input() gotRemove: boolean;
  @Input() gotResend: boolean;

  @Output() onFilterValue = new EventEmitter();
  @Output() onDeleteValue = new EventEmitter();
  @Output() onEmailValue = new EventEmitter();
  @Output() onResendValue = new EventEmitter();
  @ViewChild('templateLookDrop', { static: true })
  public templateLookDrop: TemplateRef<any>;

  kendoFilters: FilterExpression[];

  filterEditor: FilterEditor;

  optionControl = new FormControl();
  conditionControl = new FormControl();

  filterForm: FormGroup = this.formBuilder.group({
    option: this.optionControl,
    condition: this.conditionControl,
  });

  options: any[] = [
    {
      name: 'Order Date',
      value: 'BizDate',
    },
    {
      name: 'Store Name',
      value: 'StoreName',
    },
    {
      name: 'Order No',
      value: 'OrderNo',
    },
    {
      name: 'Order Type',
      value: 'OrderMode',
    },
  ];

  conditions: any[] = [
    {
      name: 'equal',
      value: 'eq',
    },
    {
      name: 'not equal',
      value: 'neq',
    },
    {
      name: 'greater',
      value: 'gt',
    },
    {
      name: 'greater or equal',
      value: 'gte',
    },
    {
      name: 'less',
      value: 'lt',
    },
    {
      name: 'less or equal',
      value: 'lte',
    },
    {
      name: 'between',
      value: 'bt',
    },
  ];

  inputText = {
    name: 'dineSuspendEnd',
    label: '',
    type: InputTypes.date,
    pattern: 'dd/MM/yy',
    styling: {
      width: '60%',
    },
  };

  formGrp: UntypedFormGroup;
  filterItems: [];
  filterValue = {
    filter: null,
  };

  odataFilterString: string = '';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    console.log('this.filters: ', this.filters)
    // for (let index = 0; index < this.filters.length; index++) {
    //   this.kendoFilters.push({
    //     field: this.filters[index]?.field,
    //     title: this.filters[index]?.title,
    //     editor:
    //       this.filters[index]?.editor == 'lookdrop'
    //         ? 'string'
    //         : (this.filters[index]?.editor as FilterEditor),
    //     operators: this.filters[index]?.operators,
    //     editorTemplate: this.filters[index]?.editorTemplate,
    //     editorFormat: this.filters[index]?.editorFormat,
    //   });

    //   if (this.filters[index].editor == 'lookdrop') {
    //     this.filters[index].editorTemplate = this.templateLookDrop;
    //   }
    // }
    console.log(this.gotEmail)
    this.onFilterChange(this.value);
    // console.log('filters: ', this.filters);
  }

  generateFilter() {
    this.onFilterValue.emit(decodeURIComponent(this.odataFilterString));
  }

  reset() {}
  remove() {
    this.onDeleteValue.emit(this.odataFilterString);
  }
  email(){
    this.onEmailValue.emit(this.odataFilterString);
  }
  resend(){
    this.onResendValue.emit(this.odataFilterString);
  }
  onDatetimeValue(event: any) {}

  addRule() {}

  addGroup() {}

  onFilterChange(value: any): void {
    this.filterValue.filter = value;
    this.odataFilterString = toODataString(this.filterValue);
  }

  public editorValueChange(value, currentItem: FilterDescriptor, filterValue: CompositeFilterDescriptor): void {
    currentItem.value = value;
    console.log(filterValue);
}
}
