/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FormPermission { 
    formCode?: string;
    formCaption?: string;
    formTextId?: string;
    formTypeCode?: string;
    icon?: string;
    queryString?: string;
    filePath?: string;
    isSettingsRequired?: boolean;
    attributeCodes?: Array<string>;
    childFormCodes?: Array<string>;
}