<div class="confirm-msg-container">
  <div class="popup-msg">
      <div class="title">
          <img [src]="data.titleIcon" />
          <h1 mat-dialog-title>{{ data.title }}</h1>
      </div>
      <div mat-dialog-content>
        <div [innerHTML]="data.message"></div>
      </div>
  </div>
  <div mat-dialog-actions>
      <button mat-button class="btn-left btn-confirm" (click)="onConfirm()">Yes</button>
      <button mat-button class="btn-right btn-confirm" cdkFocusInitial (click)="onDismiss()">No</button>
  </div>
</div>
