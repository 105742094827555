import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { AuthService } from 'src/app/core/services/auth.service';
import { Page } from 'src/app/shared/models/page.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { InvoiceResponse, ODataRawQueryOptions, OrderDetailResponse } from 'src/swagger/cxmbackend/openapi';
import { PageService } from 'src/app/shared/services/page.service';
import { UtilsService, decodeBase64, formatDateString } from 'src/app/core/services/utils.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { CustomService } from 'src/app/core/services/custom.service';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { environment } from 'src/environments/environment';
import { InvoiceService as InvoiceServiceTwo } from 'src/swagger/cxmbackend/openapi';
import { CreateInvoiceRequest } from 'src/swagger/cxmbackend/openapi/model/createInvoiceRequest';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { ColDef, GridApi } from 'ag-grid-community';
import { NgClass, NgIf } from '@angular/common';
import { MessageComponent } from 'src/app/shared/component/message/message.component';
import { ActionToolBarComponent } from 'src/app/shared/component/action-tool-bar/action-tool-bar.component';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import * as moment from 'moment';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { InvoiceStatusFlag } from 'src/swagger/cxmbackend/openapi/model/invoiceStatusFlag';
import { InvoiceService as InvoiceAPIService } from 'src/swagger/cxmbackend/openapi';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { InvoiceEmailRequest } from 'src/swagger/cxmbackend/openapi/model/InvoiceEmailRequest';
import { OrderDetailService } from '../../services/orderdetail.service';
import { PaymentStatus } from 'src/swagger/base/openapi/model/paymentStatus';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'updateorderstatus-form',
  templateUrl: './updateorderstatus-form.component.html',
  styleUrls: ['./updateorderstatus-form.component.scss'],
  standalone: true,
  imports: [NgIf, FormComponent, NgClass, MessageComponent, ActionToolBarComponent, ListingComponent],
  providers: [InvoiceAPIService]
})
export class UpdateOrderStatusComponent implements OnInit {
  formSetting: FormSetting = {
    formCode: FormCode.invoiceemailentry,
    content: [
      {
        inputText: {
          name: 'orderNo',
          label: 'Order No',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          disabled: true
        }
      },
      {
        inputText: {
          name: 'refNo1',
          label: 'Reference No',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          disabled: true
        }
      },
      {
        inputText: {
          name: 'locShortDesc',
          label: 'Store Name',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          disabled: true
        }
      },
      {
        inputText: {
          name: 'checkOrderUrl',
          label: 'Check Order Url',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
        }
      },
      {
        lookDropInput: {
          name: 'status',
          label: 'Payment Status',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(PaymentStatus).map((key) => {
            return { name: PaymentStatus[key], value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
          editable: true
        },
      },
      {
        inputText: {
          name: 'remarks',
          label: 'Remarks',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'invoiceDate',
          label: 'Transaction Date',
          type: InputTypes.date,
          styling: {
            width: '60%'
          },
          editable: true
        }
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  page: Page;
  formDetail: any = null;
  params: [] = [];
  activityId: number = undefined;
  addressId: number = undefined;
  activityData: any;
  attrCode: any;
  private gridApi!: GridApi;
  rowData: any = null;
  formCode: any = FormCode;
  isRowSelected: boolean = false;
  invoiceStatuses: any = InvoiceStatusFlag;
  activityDetail: OrderDetailResponse;
  email: string;
  constructor(private authService: AuthService,
    private orderDetailService: OrderDetailService,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private customService: CustomService,
    private utilsService: UtilsService,
    private loaderService: LoaderService,
    private formService: FormService)
    {}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.activityId = params['activityId'];
    }

    let queryOpts: ODataRawQueryOptions = {
      filter: 'activityId eq ' + this.activityId
    };
    this.activityDetail = (await this.orderDetailService.getOdataOrderDetail(queryOpts, this.formDetail.formCode)).value[0];
    this.activityData =  this.activityDetail;
    this.activityData.invoiceDate = new Date(new Date(Date.now()).setHours(0,0,0,0))
  }

  onGridApiUpdate($event) {
    this.gridApi = $event;
  }

  onToolbarAction(resp: ActionToolBarResp){}

  onSubmitForm(formGroup: UntypedFormGroup){
    let reqBody: any = {
      status: formGroup.get('status').value,
      remarks: formGroup.get('remarks').value == null ? '' : formGroup.get('remarks').value,
      invoiceDate: formGroup.get('invoiceDate').value == null ?
      (moment(new Date())).format('DD-MMM-YYYY') :
      formatDateString(formGroup.get('invoiceDate').value),
      activityId: this.activityId
    }
    this.updateOrderStatus(reqBody);
  }
  async updateOrderStatus(reqBody: any){
    this.loaderService.startLoading();
    let result = await this.orderDetailService.updateOrderStatus(reqBody, this.formDetail.formCode);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.payment_status_updated',
        desc: "msg.payment_status_updated_desc",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
    else {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.payment_status_updated_failed',
        desc: "msg.payment_status_updated_failed_desc",
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

}
