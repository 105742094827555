/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TransformationNode { 
    readonly kind?: TransformationNode.KindEnum;
}
export namespace TransformationNode {
    export type KindEnum = 'Aggregate' | 'GroupBy' | 'Filter' | 'Compute' | 'Expand';
    export const KindEnum = {
        Aggregate: 'Aggregate' as KindEnum,
        GroupBy: 'GroupBy' as KindEnum,
        Filter: 'Filter' as KindEnum,
        Compute: 'Compute' as KindEnum,
        Expand: 'Expand' as KindEnum
    };
}