import { Injectable } from '@angular/core';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  constructor(private customService: CustomService) { }

  async getRegion(filter?: string, top?: number) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.region.OdataRegion,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
      queryParams: {
        $count: true,
        $filter: filter? filter: undefined,
        $top: top? top: undefined
      }
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}
