import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { AuthService } from 'src/app/core/services/auth.service';
import { Page } from 'src/app/shared/models/page.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { BackendChannel, BackendChannelSection } from 'src/swagger/cxmbackend/openapi';
import { PageService } from 'src/app/shared/services/page.service';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Mode } from 'src/app/shared/enums/mode';
import { LocalChannelService } from '../../localChannel.service';
import { SectionDataType } from 'src/swagger/cxmbackend/openapi/model/sectionDataType';
import { SectionUIMode } from 'src/swagger/cxmbackend/openapi/model/sectionUIMode';
import { SectionShowTimerFlag } from 'src/swagger/cxmbackend/openapi/model/sectionShowTimerFlag';

@Component({
  selector: 'app-channel-section-entry',
  templateUrl: './channel-section-entry.component.html',
  styleUrls: ['./channel-section-entry.component.scss'],
  standalone: true,
  imports: [FormComponent]
})
export class ChannelSectionEntryComponent implements OnInit {
  formSetting: FormSetting = {
    formCode: FormCode.channelsectionentry,
    content: [
      {
        inputText: {
          name: 'channelId',
          label: 'Channel ID',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          disabled: true
        },
      },
      {
        inputText: {
          name: 'sectionId',
          label: 'Section ID',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          disabled: true
        },
      },
      {
        inputNumber: {
          name: 'seqNo',
          label: 'Sequence Number',
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'sectionDesc',
          label: 'Description',
          type: InputTypes.text,
          maxLength: 100,
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'sectionSymbol',
          label: 'Section Symbol',
          type: InputTypes.text,
          maxLength: 5,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'dataType',
          label: 'Data Type',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(SectionDataType).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'uiMode',
          label: 'UI Mode',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(SectionUIMode).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'showTimerFlag',
          label: 'Show Timer?',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(SectionShowTimerFlag).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'startDate',
          label: 'Start Date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'endDate',
          label: 'End Date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputNumber: {
          name: 'infoSectionId',
          label: 'Info Section Id',
          required: false,
          styling: {
            width: '60%',
          },
        },
      }
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  @Input() page: Page;
  @Input() params = null;
  formDetail: any = null;

  channelId: number = undefined;
  sectionId: number = undefined;
  channelSectionData: any;
  attrCode: any;

  reqBody: BackendChannelSection = null;
  editMode: boolean = false;

  constructor(private authService: AuthService,
    private channelService: LocalChannelService,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private formService: FormService) { }

  async ngOnInit() {


    if (this.page.queryParams?.mode) {
      this.editMode = this.page.queryParams.mode === Mode.edit ? true : false;
    }

    // Get route parameter
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);

      this.channelId = this.params ? this.params['channelId'] : params['channelId'];
      this.sectionId = this.params ? this.params['sectionId'] : params['sectionId'];
      this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    }

    // Get pass in parameter
    if(this.params) {
      this.attrCode = this.params['AttrCode']? this.params['AttrCode']: null;
    }

    if(this.channelId && this.sectionId) {
      await this.getChannelSection(this.channelId, this.sectionId);
    }
    else if (this.channelId) {
      this.channelSectionData = {
        channelId: this.channelId,
        seqNo: 0
      }
    }

  }

  async getChannelSection(channelId: number, sectionId: number) {
    let result = await this.channelService.getOdataChannelSection(channelId, sectionId, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.channelSectionData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp){}

  async onSubmitForm(formGroup: UntypedFormGroup){
    let result  = null;
    this.reqBody = this.formService.getReqBody(formGroup);
    if(this.editMode) {
      result = await this.channelService.updateChannelSection(this.reqBody, this.formDetail.formCode);
     } else {

       result = await this.channelService.addChannelSection(this.reqBody, this.formDetail.formCode);
     }

    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      console.log(result)
      let msg: PopupMessage = {
        formCode: FormCode.channelsectionentry,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.new.channel.addr.saved',
        desc: "popupMsg.added.new.channel.addr",
        actionBtnText: 'btn.okay'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.refresh([FormCode.channelsectionlisting]);
        this.pageService.closePage(this.page);
      });
    } else {
      let msg: PopupMessage = {
        formCode: FormCode.channelsectionentry,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.new.store.attr.unsuccessful',
        desc: "popupMsg.system.couldnt.save.store.attr",
        actionBtnText: 'btn.back'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }

  }
}
