/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MerchantStatus = 'Active' | 'Suspended' | 'Demo' | 'Inactive';

export const MerchantStatus = {
    Active: 'Active' as MerchantStatus,
    Suspended: 'Suspended' as MerchantStatus,
    Demo: 'Demo' as MerchantStatus,
    Inactive: 'Inactive' as MerchantStatus
};