import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AutoGenerateInputSetting } from '../../input/model/AutoGenerateInputSetting.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroup, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgIf } from '@angular/common';
import { SystemService } from 'src/app/shared/services/system.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { GetNextCounterRequest } from 'src/swagger/base/openapi';

@Component({
  selector: 'app-auto-generate-input',
  templateUrl: './auto-generate-input.component.html',
  styleUrls: ['./auto-generate-input.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, TranslateModule, ReactiveFormsModule, NgIf, MatIconModule]
})
export class AutoGenerateInputComponent implements OnChanges {
  @Input() autoGenerateInputSetting: AutoGenerateInputSetting;
  @Input() formData: any;
  @Input() formGroup: FormGroup;
  @Output() onGenerateInput = new EventEmitter();

  code: string;
  generateErr: boolean = false;
  formGrpCtrl: any;

  constructor(private systemService: SystemService) { }

  ngOnInit(): void {
    this.formGrpCtrl = this.formGroup.controls[this.autoGenerateInputSetting.name];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue && changes.formData.currentValue[this.autoGenerateInputSetting.name]) {
      this.formGrpCtrl.setValue(changes.formData.currentValue[this.autoGenerateInputSetting.name]);
    }
  }

  async onGenerate() {
    this.generateErr = false;
    let counterData: GetNextCounterRequest = {
      docGrp: this.autoGenerateInputSetting.docgrp? this.autoGenerateInputSetting.docgrp: '',
      doccde: this.autoGenerateInputSetting.doccde? this.autoGenerateInputSetting.doccde: '',
      clientDate: new Date(),
      length: this.autoGenerateInputSetting.length? this.autoGenerateInputSetting.length: undefined,
      increaseLastNo: this.autoGenerateInputSetting.increaseLastNo? this.autoGenerateInputSetting.increaseLastNo: undefined,
      prefix: this.autoGenerateInputSetting.prefix? this.autoGenerateInputSetting.prefix: undefined,
      postfix: this.autoGenerateInputSetting.postfix? this.autoGenerateInputSetting.postfix: undefined,

    }

    let result = await this.systemService.getNextCounter(counterData);

    if (!(result instanceof HttpErrorResponse)) {
      this.formGrpCtrl.setValue(result);
    } else {
      this.formGrpCtrl.markAllAsTouched();
      this.generateErr = true;
    }
  }
}
