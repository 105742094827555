import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { AuthService } from 'src/app/core/services/auth.service';
import { InvoiceService } from '../invoice.service';
import { Page } from 'src/app/shared/models/page.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { InvoiceResponse, ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { PageService } from 'src/app/shared/services/page.service';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { CustomService } from 'src/app/core/services/custom.service';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { environment } from 'src/environments/environment';
import { InvoiceService as InvoiceAPIService } from 'src/swagger/cxmbackend/openapi';
import { CreateInvoiceRequest } from 'src/swagger/cxmbackend/openapi/model/createInvoiceRequest';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import jsPDF from 'jspdf';
import { InvoiceActionComponent } from '../invoice-action/invoice-action.component';
import { UpdateInvoiceStatusRequest } from 'src/swagger/cxmbackend/openapi/model/updateInvoiceStatusRequest';
import { InvoiceStatusFlag } from 'src/swagger/cxmbackend/openapi/model/invoiceStatusFlag';
import { ConfirmDialogComponent } from 'src/app/shared/component/confirm-dialog/confirm-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import moment from 'moment';
import { ColDef, ExcelStyle, GridApi, GridReadyEvent, IDateFilterParams } from 'ag-grid-community';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { OdataProvider } from 'ag-grid-odata/build/OdataProvider';
import { TranslateService } from '@ngx-translate/core';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { NgClass, NgIf } from '@angular/common';
import { MessageComponent } from 'src/app/shared/component/message/message.component';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { StatementService } from '../../services/statement.service';
import { FormService } from 'src/app/shared/component/form/form.service';


@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
  standalone: true,
  imports: [FormComponent, InvoiceActionComponent, NgIf, NgClass, MessageComponent, PdfViewerModule, ListingComponent],
  providers: [InvoiceAPIService]
})
export class InvoiceFormComponent implements OnInit {
  data: InvoiceResponse;
  formSetting: FormSetting = {
    formCode: FormCode.invoiceentry,
    content: [
      {
        inputText: {
          name: 'startDate',
          label: 'Start Date',
          type: InputTypes.date,
          required: true,
          styling: {
            width: '60%'
          },
          editable: false
        }
      },
      {
        inputText: {
          name: 'endDate',
          label: 'End Date',
          type: InputTypes.date,
          required: true,
          styling: {
            width: '60%'
          },
          editable: false
        }
      },
      {
        toggleInput: {
          name: 'generateDataFile',
          label: 'Generate Data File',
        }
      },
      {
        toggleInput: {
          name: 'sendToMerchant',
          label: 'Send To Merchant',
        }
      },
      {
        inputText: {
          name: 'storeId',
          label: 'Store Id',
          type: InputTypes.number,
          required: false,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'statementId',
          label: 'Statement Id',
          type: InputTypes.number,
          required: false,
          styling: {
            width: '60%'
          }
        }
      },
      {
        inputText: {
          name: 'invoiceId',
          label: 'Invoice Id',
          type: InputTypes.number,
          required: false,
          styling: {
            width: '60%'
          }
        }
      },
      {
        inputText: {
          name: 'email',
          label: 'Recipient Email',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
        }
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  page: Page;
  formDetail: any = null;
  params: [] = [];
  invoiceId: number;
  invoiceNo: string;
  pdfSrc: any;
  addressId: number = undefined;
  invoiceData: any;
  attrCode: any;
  merchantDesc: string;
  invoice: InvoiceResponse;
  private gridApi!: GridApi;
  listingSetting: ListingSetting;
  rowData: any = null;
  formCode: any = FormCode;
  excelStyles: ExcelStyle[] = [
    {
      id: 'numberType',
      numberFormat: {
        format: '0',
      },
    },
    {
      id: 'currencyFormat',
      numberFormat: {
        format: '#,##0.00',
      },
      alignment: {
        horizontal: "Right"
      }
    },
    {
      id: 'currencyTypeFormat',
      alignment: {
        horizontal: "Center"
      }
    },
    {
      id: 'percentageFormat',
      numberFormat: {
        format: '0.000',
      },
    },
    {
      id: 'booleanType',
      dataType: 'Boolean',
    },
    {
      id: 'stringType',
      dataType: 'String',
    },
    {
      id: 'dateType',
      dataType: 'DateTime',
    },
    {
      id: 'header',
      font: {
        bold: true,
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {

      id: 'stringCenterType',
      dataType: 'String',
      alignment: {
        horizontal: 'Center',
      },
    },

  ];

  colDef: ColDef[] = [
    {
      field: 'statementRow',
      headerName: 'No.',
      filter: true,
      sortable: true,
      cellClass: "stringCenterType"

    },
    {
      field: 'orderNo',
      headerName: 'Order No.',
      filter: true,
      sortable: true,
      cellClass: "stringCenterType"

    },
    {
      field: 'salesDate',
      headerName: 'Sales Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filterParams: filterParams,
      type: 'dateColumn',
      sortable: true,
      cellClass: "stringCenterType"
    },
    {
      field: 'collectionMode',
      headerName: 'Collection Mode',
      filter: true,
      sortable: true,
      cellClass: "stringCenterType"
    },
    {
      field: 'netAmtExc',
      headerName: 'Net Order Value(Exclude Tax)',
      filter: true
    },
    {
      field: 'platformDiscAmt',
      headerName: 'Platform Discount',
      filter: true,
    },
    {
      field: 'merchantTaxAmt',
      headerName: 'Invoice Tax Value',
      filter: true,
    },
    {
      field: 'chargeAmt',
      headerName: 'Merchant Charges',
      filter: true,
    },
    {
      field: 'selfDeliveryAmt',
      headerName: 'Merchant Delivery Charges',
      filter: true,
    },
    {
      field: 'merchantAdjAmtPlusAdjAmt',
      headerName: 'Miscellaneous / Adjustments (If Any)',
      filter: true,
    },
    {
      field: 'otherChargeAmt',
      headerName: 'Other Charges Amount',
      filter: true
    },
    {
      field: 'commSalesAmt',
      headerName: 'Commission Base',
      filter: true
    },
    {
      field: 'commPerc',
      headerName: 'Platform Charge %',
      filter: true,
    },
    {
      field: 'commAmt',
      headerName: 'Platform Charge Value',
      filter: true
    },
    {
      field: 'commTaxAmt',
      headerName: 'Platform Charge Tax Value',
      filter: true
    },
    {
      field: 'revenueAmt',
      headerName: 'Restaurant Revenue',
      filter: true,
    },
    {
      field: 'payableAmt',
      headerName: 'Net Payable',
      filter: true
    }
  ]

  constructor(private authService: AuthService,
    private invoiceService: InvoiceService,
    private invoiceAPIService: InvoiceAPIService,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private customService: CustomService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private statementService: StatementService,
    private formService: FormService)
    {

    }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide
    };
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.invoiceId = params['invoiceId'];
    }
    let queryOpts: ODataRawQueryOptions = {
      filter: 'invoiceId eq ' + this.invoiceId
    };
    this.invoice = (await this.invoiceService.getOdataInvoice(queryOpts, this.formDetail.formCode)).value[0];
    this.invoiceNo = this.invoice.invoiceNo;
    this.data = this.invoice;

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.invoice.GetInvoiceDetailHtml,
      hostPath: environment.hostPath,
      queryParams: {
        invoiceId: this.invoiceId
      },
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);
    this.loaderService.startLoading();

    let result = await this.invoiceService.getInvoiceHtml(Number(this.invoiceId), this.formDetail.formCode)
        //document.getElementById("invoicepage").innerHTML = data.response;

        this.pdfSrc = result.response;
        document.getElementById("invoicepage").innerHTML = '';
        this.data.outputURL = result.response;
        this.loaderService.stopLoading();

        // var html = htmlToPdfmake(data.response);
        // const documentDefinition = { content: html };
        // const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        // pdfDocGenerator.getDataUrl((dataUrl) => {
        //   const targetElement = document.querySelector('#invoicepage');
        //   const iframe = document.createElement('iframe');
        //   iframe.src = dataUrl;
        //   targetElement.appendChild(iframe);
        // });


        this.loaderService.stopLoading();


    let queryOptsStmt: ODataRawQueryOptions = {
      filter: '$filter=invoiceId eq ' + this.invoiceId + ' and status eq \'Sales\''
    };
    let data = await this.statementService.getOdataStatement(queryOptsStmt, this.formDetail.formCode);
    let totalPayableAmt = 0;
    if (data instanceof HttpErrorResponse === false) {
      if (data && data['value']){
        let total = {
          statementRow: 'Total',
          netAmtExc: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.netAmtExc;}, 0),
          platformDiscAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.platformDiscAmt;}, 0),
          merchantTaxAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.merchantTaxAmt;}, 0),
          chargeAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.chargeAmt;}, 0),
          selfDeliveryAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.selfDeliveryAmt;}, 0),
          otherChargeAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.otherChargeAmt;}, 0),
          merchantAdjAmtPlusAdjAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.merchantAdjAmtPlusAdjAmt;}, 0),
          commSalesAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.commSalesAmt;}, 0),
          commAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.commAmt;}, 0),
          commTaxAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.commTaxAmt;}, 0),
          revenueAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.revenueAmt;}, 0),
          payableAmt: data['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.payableAmt;}, 0),
        };
        data['value'].push(total);

        this.rowData = data['value'];
        totalPayableAmt = total.payableAmt;
      }

      data['value'].push({})
      data['value'].push({})

      queryOptsStmt = {
        filter: '$filter=invoiceId eq ' + this.invoiceId + ' and status eq \'Platform Refunded\''
      };
      let refundData = await this.statementService.getOdataStatement(queryOptsStmt, this.formDetail.formCode);
      if (refundData instanceof HttpErrorResponse === false && refundData && refundData['value']) {
        data['value'].push({
          statementRow: 'Refund Details'
        });
        totalPayableAmt += refundData['value'].reduce((accumulator, currentValue) => {return accumulator + currentValue.payableAmt;}, 0);
        let newAppendix = data && data['value'] ? data['value'].concat(refundData['value']) : refundData['value'];

        newAppendix.push({
          payableAmt: totalPayableAmt
        });
        this.rowData = newAppendix;
      }
    }

  }

  async getInvoice(data?: ODataRawQueryOptions) {
    let result = await this.invoiceService.getOdataInvoice(data);
    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.invoiceData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp){}

  onBtnDownload() {
    const elementToPrint = document.getElementById("invoicepage");
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    })
    const scale = pdf.internal.pageSize.getWidth() / elementToPrint.offsetWidth;

    pdf.html(elementToPrint,{
      callback: () =>{
        pdf.save("INV_"+this.invoice.storeDesc+"_"+this.invoice.startDate+"_"+this.invoice.endDate+".pdf")
      },
      html2canvas:{
        scale: scale
      }
    });
  }

  onBtnRegen()
  {
    this.loaderService.startLoading();
    let result  = null;
    let reqBody: CreateInvoiceRequest = {
      startDate: new Date(),
      endDate: new Date(),
      generateDataFile: true,
      sendToMerchant: false,
      invoiceId: this.invoiceId,
    }
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.invoice.CreateInvoice,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    result = this.invoiceAPIService.apiInvoiceCreateinvoicePost(reqBody, environment.apiVersion, environment.subscription, hMac, 'en').subscribe(
      (data) => {
        this.loaderService.stopLoading();
        if (result instanceof HttpErrorResponse === false) {
          this.popupFormService.close(this.page.form.formCode);

          let msg: PopupMessage = {
            formCode: FormCode.invoicelisting,
            id: 'success',
            titleIcon: 'assets/base/icons/check-circle.svg',
            title: 'msg.invoice.regenerated',
            desc: "msg.regenerate.invoice",
            actionBtnText: 'btn.okay'
          };

          this.dialog.open(PopupMessageComponent, {
            data: msg
          });
        this.pageService.closePage(this.page);
        } else {
          let msg: PopupMessage = {
            formCode: FormCode.invoicelisting,
            id: 'failed',
            titleIcon: 'assets/base/icons/exclamation-circle.svg',
            title: 'msg.regen.invoice.unsuccessful',
            desc: "msg.system.couldnt.regen.invoice",
            actionBtnText: 'btn.back'
          };

          this.dialog.open(PopupMessageComponent, {
            data: msg
          });
        }
      },
      (err) => {
        this.loaderService.stopLoading();
        console.error(err);
      }
    );

  }
  onBtnCancel(){
    let result  = null;
    let reqBody: UpdateInvoiceStatusRequest = {
      statusFlag: InvoiceStatusFlag.Cancelled,
      invoiceId: this.invoice.invoiceId,
    }
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.invoice.UpdateInvoiceStatus,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: null,
      httpHeaderType: undefined
    }
    let hMac = this.customService.assignHMac(cusReq);

      result = this.invoiceAPIService.apiInvoiceUpdateinvoicestatusPut(reqBody, environment.apiVersion, environment.subscription, hMac, 'en').subscribe(
        (data) => {

        },
        (err) => {
          console.error(err);
        }
      );
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);

      let msg: PopupMessage = {
        formCode: FormCode.invoicelisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.invoice.cancelled',
        desc: "msg.cancel.invoice",
        actionBtnText: 'btn.okay'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });

    } else {
      let msg: PopupMessage = {
        formCode: FormCode.invoicelisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.cancel.invoice.unsuccessful',
        desc: "msg.system.couldnt.cancel.invoice",
        actionBtnText: 'btn.back'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }
  onBtnEmail()
  {
    this.pageService.navigateByUrl(PageRoute.INVOICE_EMAILENTRY, { invoiceId: this.invoiceId }, null, true);
  }
  async onBtnStmtDownload(){

    var params = this.getParams();
    // this.gridApi.exportDataAsCsv(params);
    this.gridApi.exportDataAsExcel(params);
  }
  async onAction(odataActionString: any) {
    if(odataActionString === 'download')
    {
      this.onBtnDownload();
    }
    else if(odataActionString === 'regenerate')
    {
      this.onBtnRegen();
    }
    else if(odataActionString === 'cancel')
    {
      this.onBtnCancel();
    }
    else if(odataActionString === 'email')
    {
      this.onBtnEmail();
    }
    else if(odataActionString === 'downloadstmt')
    {
      await this.onBtnStmtDownload();
    }
  }

  getParams() {
    return {
      fileName: 'Statement ' + moment(new Date).format("DD-MMM-YYYY")
    };
  }


  getSignature(options: any) {
    let optionsSpaceFormat = options.replace(/\s/g, "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.statement.OdataStatement + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }

  onGridApiUpdate($event) {
    this.gridApi = $event;
  }
}
var filterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};
