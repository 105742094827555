import { Component } from '@angular/core';
import { DatePickerComponent } from '../../date-picker/date-picker.component';
import { DatePickerRangeComponent } from '../../date-picker-range/date-picker-range.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { Input, Output, EventEmitter } from '@angular/core';
import { FormContent } from '../../../form/model/FormContent.model';
import { InputTypes } from '../../enum/InputTypes';
import { UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormService } from '../../../form/form.service';
import { FormControl, FormGroup } from '@angular/forms';
import { InputNumberComponent } from '../../input-number/input-number.component';
import moment from 'moment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ShareValueService } from 'src/app/shared/services/share-value.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  standalone: true,
  imports: [
    DatePickerComponent,
    DatePickerRangeComponent,
    NgIf,
    NgFor,
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    InputNumberComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class CalendarComponent {
  dateFilter: FormContent = {
    dateInput: {
      name: 'Date',
      label: 'Enter a date',
      type: InputTypes.date,
      pattern: 'dd/MM/YYYY',
      styling: {
        width: '100%',
      },
      required: true,
      hideError: true
    },
  };

  dateRangeFilter: FormContent = {
    dateRangeInput: {
      name: 'Range',
      label: 'Enter a date range',
      styling: {
        width: '100%',
      },
      required: true,
      hideError: true
    },
  };

  @Input() isDatePickerMode: boolean;
  @Input() editValue: string;
  @Input() isBetween: boolean;
  @Input() dateSelection: any[] = [];
  @Input() dateValue: string;
  @Input() matching: boolean;
  @Input() isDateBoolean: boolean;
  @Input() editDateValue: string;
  @Input() formulaBuild: boolean;

  @Output() newDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() modifyDate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dateModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dateFormula: EventEmitter<{ date: string; optionNameWithCalc: string}> =
    new EventEmitter<{ date: string; optionNameWithCalc: string}>();

  formGrp: UntypedFormGroup;
  formContent = [this.dateFilter, this.dateRangeFilter];
  quantityForm: FormGroup;
  form: FormGroup;
  updatedValue: number = 1;
  quantityValue: number = this.updatedValue;
  selectedDate: Date;
  passOperator: string;
  passDigits: number | undefined;
  selectedDateOption: string;
  isDropdownOpen = false;
  isCalc: boolean = true;
  calc: string = ' + ';
  isModify: boolean = false;
  optionNameWithCalc: string;

  constructor(
    private formService: FormService,
    public translateService: TranslateService,
    private shareValueService: ShareValueService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.form = new FormGroup({
      quantity: new FormControl(1), // Set your default value here
    });

    this.quantityForm = this.fb.group({
      quantity: [this.quantityValue, Validators.min(1)],
    });
  }

  ngOnInit(): void {
    this.formGrp = this.formService.generateFormGroup(this.formContent);

    this.shareValueService.value$.subscribe((value) => {
      this.passOperator = value.operator;
      this.passDigits = value.digits;
    });

    if (!this.matching) {
      this.calc = this.passOperator;
      this.updatedValue = this.passDigits;
    }

    if (this.passOperator === ' + ') {
      this.isCalc = true;
    } else if (this.passOperator === ' - ') {
      this.isCalc = false;
    } else {
      this.calc = ' + ';
      this.isCalc = true;
    }

    if(this.editValue && this.editValue.includes('(')) {
      this.editValue =  this.editValue.slice(0, this.editValue.indexOf('(')).trim();
      const parts = this.editValue.match(/(.*?)(\s[\+\-]\s)?(\d+)?$/);
      const result = parts ? [parts[1].trim(), parts[2] ? parts[2].trim() : '', parts[3] ? parts[3].trim() : ''] : [this.editValue.trim(), '', ''];
      this.editValue = result[0];
      if(result[1] !== '' && result[2] !== ''){
        this.isModify = !this.isModify;
        this.calc = ` ${result[1]} `;
        this.passOperator = ` ${result[1]} `;
        this.updatedValue = parseInt(result[2]);
        if(this.calc === ' + ') {
          this.isCalc = true;
        } else {
          this.isCalc = false;
        }
      }
    }
  }

  handleModifyChange(isModify: boolean): void {
    this.modifyDate.emit(isModify);
  }

  handleDateChanged(updateDate: string): void {
    this.newDate.emit(updateDate);
  }

  reset() {
    this.editValue = 'Today';
    this.updatedValue = 1;
    this.isCalc = true;
    this.isModify = false;
    this.calc = ' + ';
    this.passOperator = ' + ';
  }

  dateModify() {
    this.isModify = !this.isModify;
    this.handleModifyChange(this.isModify);
    this.updatedValue = 1;
    this.passOperator = ' + ';
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onQuantityChange(event: any) {
    const inputValue = event.target.value;
    this.updatedValue = inputValue;
  }

  toggleView() {
    this.isDatePickerMode = !this.isDatePickerMode;
    this.reset();
    this.dateModeChange.emit(this.isDatePickerMode);
  }

  toggleCalc() {
    this.isCalc = !this.isCalc;

    if (this.isCalc) {
      this.calc = ' + ';
    } else {
      this.calc = ' - ';
    }
  }

  getDateValue(optionName: string): string {
    let format = 'DD/MM/YYYY';
    let calcNum: number;
    let offset = 0;

    if (this.isModify) {
      calcNum = this.updatedValue;
      this.optionNameWithCalc = optionName + this.calc + calcNum;
    } else {
      calcNum = 0;
      this.optionNameWithCalc = optionName;
    }

    if (this.isCalc) {
      offset = calcNum || 0; // Plus operation
    } else {
      offset = -(calcNum || 0); // Minus operation
    }

    switch (optionName) {
      case 'Today':
        const today = moment().add(offset, 'days').format(format);
        this.dateFormula.emit({ date: today, optionNameWithCalc: this.optionNameWithCalc});
        return today;

      case 'Yesterday':
        const yesterday = moment()
          .subtract(1, 'days')
          .add(offset, 'days')
          .format(format);
        this.dateFormula.emit({ date: yesterday, optionNameWithCalc: this.optionNameWithCalc});
        return yesterday;

      case 'This Week':
        const startWeek = moment()
          .startOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const endWeek = moment()
          .endOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        if (this.isModify) {
          const thisWeek = `${startWeek} - ${endWeek}`;
          this.dateFormula.emit({ date: thisWeek, optionNameWithCalc: this.optionNameWithCalc});
          return thisWeek;
        } else {
          const thisWeek = `${startWeek} - ${endWeek}`;
          this.dateFormula.emit({ date: thisWeek, optionNameWithCalc: this.optionNameWithCalc});
          return thisWeek;
        }

      case 'This Month':
        const startMonth = moment()
          .startOf('month')
          .add(offset, 'month')
          .format(format);
        const endMonth = moment()
          .endOf('month')
          .add(offset, 'month')
          .format(format);
        if (this.isModify) {
          const thisMonth = `${startMonth} - ${endMonth}`;
          this.dateFormula.emit({ date: thisMonth, optionNameWithCalc: this.optionNameWithCalc});
          return thisMonth;
        } else {
          const thisMonth = `${startMonth} - ${endMonth}`;
          this.dateFormula.emit({ date: thisMonth, optionNameWithCalc: this.optionNameWithCalc});
          return thisMonth;
        }

      case 'This Quarter':
        const startQuarter = moment()
          .startOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const endQuarter = moment()
          .endOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        if (this.isModify) {
          const thisQuart = `${startQuarter} - ${endQuarter}`;
          this.dateFormula.emit({ date: thisQuart, optionNameWithCalc: this.optionNameWithCalc });
          return thisQuart;
        } else {
          const thisQuart = `${startQuarter} - ${endQuarter}`;
          this.dateFormula.emit({ date: thisQuart, optionNameWithCalc: this.optionNameWithCalc });
          return thisQuart;
        }

      case 'This Year':
        const startYear = moment()
          .startOf('year')
          .add(offset, 'year')
          .format(format);
        const endYear = moment()
          .endOf('year')
          .add(offset, 'year')
          .format(format);
        if (this.isModify) {
          const thisYear = `${startYear} - ${endYear}`;
          this.dateFormula.emit({ date: thisYear, optionNameWithCalc: this.optionNameWithCalc });
          return thisYear;
        } else {
          const thisYear = `${startYear} - ${endYear}`;
          this.dateFormula.emit({ date: thisYear, optionNameWithCalc: this.optionNameWithCalc });
          return thisYear;
        }

      case 'Past Week':
        const startPastWeek = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const lastPastWeek = moment()
          .subtract(1, 'weeks')
          .endOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const pastWeek = `${startPastWeek} - ${lastPastWeek}`;
        this.dateFormula.emit({ date: pastWeek, optionNameWithCalc: this.optionNameWithCalc});
        return pastWeek;

      case 'Past Month':
        const startPastMonth = moment()
          .subtract(1, 'month')
          .startOf('month')
          .add(offset, 'month')
          .format(format);
        const lastPastMonth = moment()
          .subtract(1, 'month')
          .endOf('month')
          .add(offset, 'month')
          .format(format);
        const pastMonth = `${startPastMonth} - ${lastPastMonth}`;
        this.dateFormula.emit({ date: pastMonth, optionNameWithCalc: this.optionNameWithCalc});
        return pastMonth;

      case 'Past Quarter':
        const startPastQuarter = moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const lastPastQuarter = moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const pastQuart = `${startPastQuarter} - ${lastPastQuarter}`;
        this.dateFormula.emit({ date: pastQuart, optionNameWithCalc: this.optionNameWithCalc });
        return pastQuart;

      case 'Past Year':
        const startPastYear = moment()
          .subtract(1, 'year')
          .startOf('year')
          .add(offset, 'year')
          .format(format);
        const lastPastYear = moment()
          .subtract(1, 'year')
          .endOf('year')
          .add(offset, 'year')
          .format(format);
        const pastYear = `${startPastYear} - ${lastPastYear}`;
        this.dateFormula.emit({ date: pastYear, optionNameWithCalc: this.optionNameWithCalc });
        return pastYear;

    }
    return null;
  }

  onDateOptionChange(selectedOption: string) {
    this.editValue = selectedOption;
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
