/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SectionUIMode = 'ListView' | 'Horizontal_Slider' | 'Vertical_Slider' | 'HorizontalSliderTitle';

export const SectionUIMode = {
    ListView: 'ListView' as SectionUIMode,
    Horizontal_Slider: 'Horizontal_Slider' as SectionUIMode,
    Vertical_Slider: 'Vertical_Slider' as SectionUIMode,
    HorizontalSliderTitle: 'HorizontalSliderTitle' as SectionUIMode
};
