
<div class="text-area-layout" [formGroup]="formGroup" *ngIf="textAreaSetting">
    <mat-form-field [style]="{ 'width': textAreaSetting.styling?.width }">
        <mat-label class="mat-label" *ngIf="textAreaSetting.label">{{ textAreaSetting.label | translate }}</mat-label>
        <textarea matInput placeholder="{{ textAreaSetting.placeholder }}" formControlName="{{ textAreaSetting.name }}"
            required="{{ textAreaSetting.required? textAreaSetting.required: false }}" id="{{ textAreaSetting.name }}"
            [maxLength]="textAreaSetting.maxLength ? textAreaSetting.maxLength : 255"
            [minLength]="textAreaSetting.minLength ? textAreaSetting.minLength : 0"></textarea>

        <mat-icon-button *ngIf="formGroup.controls[textAreaSetting.name].value" matSuffix mat-icon-button aria-label="Indicator" aria-disabled="true" tabindex="-1">
            <mat-icon *ngIf="formGroup.controls[textAreaSetting.name].status === 'INVALID'" class="indicator-icon invalid-icon">error_outline</mat-icon>
            <mat-icon *ngIf="formGroup.controls[textAreaSetting.name].status === 'VALID'" class="indicator-icon valid-icon">check_circle_outline</mat-icon>
        </mat-icon-button>
        <mat-error *ngIf="formGrpCtrl?.errors?.required">{{textAreaSetting.label | translate}}{{'msg.is_required' | translate}}</mat-error>
      </mat-form-field>
</div>
