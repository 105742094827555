<div class="expansion-panel-layout"> 
    <mat-accordion>
        <mat-expansion-panel 
            [disabled]="expansionPanelSetting.disabled" [id]="expansionPanelSetting?.id? expansionPanelSetting.id: ''"
            [expanded]="expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="title" *ngFor="let content of expansionPanelSetting.title">
                        <app-form-inputs [content]="content" [formGrp]="formGrp" [formData]="formData"
                            (onGenerateInput)="onGenerate($event)" (onButtonToggle)="onBtnToggle($event)" (onTimeChange)="onTimeChg($event)"
                            (onPeriodChange)="onPeriodChg($event)" (onDateChange)="onDateChg($event)" (onToggleChange)="onToggleChg($event)"></app-form-inputs>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let content of expansionPanelSetting.content">
                <app-form-inputs [content]="content" [formGrp]="formGrp" [formData]="formData"
                (onGenerateInput)="onGenerate($event)" (onButtonToggle)="onBtnToggle($event)" (onTimeChange)="onTimeChg($event)"
                (onPeriodChange)="onPeriodChg($event)" (onDateChange)="onDateChg($event)"></app-form-inputs>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
