<invoice-action [invoiceData]="data"(onActionValue)="onAction($event)"></invoice-action>

<div id="invoicepage">Loading, please wait...</div>
<pdf-viewer [src]="pdfSrc"
[render-text]="true"
[original-size]="false"
style="width: 900px; height: 1000px"
></pdf-viewer>
<div class="col-12"hidden value="not shown">
    <div class="scrollable-container">
      <div class="ag-grid-table-container" [ngClass]="formDetail.formCode" #outletList>
        <div class="action-msg-bar">
          <div class="message">
            <app-message [msgs]="" [msgType]=""></app-message>
          </div>
        </div>      
        <app-listing *ngIf="rowData" [colDef]="colDef" [rowData]="rowData" [setting]="listingSetting" [formCode]="formCode.statementlisting"
        (onGridApi)="onGridApiUpdate($event)" [excelStyles]="excelStyles"></app-listing>
      </div>
    </div>
  </div>