import { Component } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api/menuitem';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { AccordionModule } from 'primeng/accordion';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { InputTextComponent } from "../../../shared/component/input/input-text/input-text.component";
import { FormComponentComponent } from "../../../shared/component/form/form-component/form-component.component";
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormContent } from 'src/app/shared/component/form/model/FormContent.model';
import { FormService } from 'src/app/shared/component/form/form.service';
import { AutoGenerateInputComponent } from 'src/app/shared/component/auto-generate-input/auto-generate-input/auto-generate-input.component';
import { ListingComponent } from "../../../shared/component/listing/listing.component";
import { ColDef } from 'ag-grid-community';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { ActionToolBarComponent } from "../../../shared/component/action-tool-bar/action-tool-bar.component";
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { TranslateModule } from '@ngx-translate/core';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { Mode } from 'src/app/shared/enums/mode';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [CommonModule, TabMenuModule, FormComponent, AccordionModule, NgIf, InputTextComponent, FormComponentComponent, FormsModule, ReactiveFormsModule, AutoGenerateInputComponent, ListingComponent, ActionToolBarComponent, TranslateModule]
})
export class LayoutComponent {
  activeItem: MenuItem | undefined;
  items: MenuItem[] = [
    { label: 'Layout 1 (Accordion with tab)', id: '1' },
    { label: 'Layout 2', id: '2' },
    { label: 'Layout 3', id: '3' },
    { label: 'Layout 4', id: '4' },
  ];

  selectedTab: number = 1;
  display: boolean = true;
  display2: boolean = true;
  formGrp: FormGroup = null;

  docNumber: FormContent = {
    autoGenerateInput: {
      name: 'adjDocNum',
      label: 'Adj. Document No.',
      required: true,
      docgrp: PageRoute.STORE,
      doccde: FormCode.storeentry,
      manual: true,
      length: '3',
      styling: {
        width: '100%'
      }
    }
  }

  docDate: FormContent = {
    inputText: {
      name: 'docDate',
      label: 'Document Date',
      type: InputTypes.dateTimeLocal,
      dateFormat: 'dd/MM/yy  h:mm a',
      styling: {
        width: '100%'
      },
    }
  }

  outletCode: FormContent = {
    inputText: {
      name: 'outletCode',
      label: 'Outlet Code',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  docTypeCode: FormContent = {
    inputText: {
      name: 'docTypeCode',
      label: 'Document Type Code',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  origin: FormContent = {
    inputText: {
      name: 'origin',
      label: 'Origin',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  destination: FormContent = {
    inputText: {
      name: 'destination',
      label: 'Destination',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  remark: FormContent = {
    inputText: {
      name: 'remark',
      label: 'Remark',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  inputA: FormContent = {
    inputText: {
      name: 'inputA',
      label: 'Input A',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  inputB: FormContent = {
    inputText: {
      name: 'inputB',
      label: 'Input B',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  inputC: FormContent = {
    inputText: {
      name: 'inputC',
      label: 'Input C',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  inputD: FormContent = {
    inputText: {
      name: 'inputD',
      label: 'Input D',
      type: InputTypes.text,
      required: true,
      styling: {
        width: '100%'
      },
    }
  }

  rowData: any = null;
  formCode: any = FormCode;
  colDef: ColDef[] = [
    listingCheckbox,
    {
      ...listingActionButton,
      cellRendererParams: {
        type: ButtonRendererTypeEnum.duplicate
      }
    },
    {
      ...listingRouterLink,
      field: 'locCode',
      headerName: 'Outlet Code',
      cellRendererParams: {
        inRouterLink: {
          route: '/store/master/entry',
          params: ['storeId'],
          queryParams: {mode: Mode.edit}
        }
      },
    },
    {
      field: 'locDesc',
      headerName: 'Description',
      filter: 'agTextColumnFilter'
    },
    {
      ...listingDropdown,
      field: 'currCode',
      headerName: 'Price Code',
      cellEditorParams: {
        options: ['MYR', 'SGD', 'Baht', 'Won', 'TW', 'MYR', 'SGD', 'Baht', 'Won', 'TW', 'MYR', 'SGD', 'Baht', 'Won', 'TW', 'MYR', 'SGD', 'Baht', 'Won', 'TW']
      }
    },
    {
      field: 'countryCode',
      headerName: 'Tax Condition Code',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['MY', 'SG']
      },
      initialFlex: 1
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarListing,
    position: ActionToolbarPosition.RIGHT,
  };

  formContent = [this.docNumber, this.docDate, this.outletCode, this.docTypeCode, this.origin, this.destination, this.remark, this.inputA, this.inputB, this.inputC, this.inputD];
  requiredFormError: boolean = false;

  constructor(
    private formService: FormService
  ) { }

  ngOnInit() {
    this.activeItem = this.items[0];
    this.formGrp = this.formService.generateFormGroup(this.formContent);
  }

  clickTab(tab: number) {
    this.selectedTab = tab;
    this.display2 = true;
  }

  async save() {
    this.formGrp.markAllAsTouched();
    this.requiredFormError = await this.formService.validateFormRequire(this.formGrp, this.formContent);
  }
}
