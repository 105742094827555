import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormCode } from '../enums/formCode';
import { PopupForm } from '../models/popupForm.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupFormService {
  open$: Subject<{popupForm: PopupForm, data?: any}> = new Subject();
  close$: Subject<{ formCode: FormCode | string, data?: any }> = new Subject();
  afterClosed$: Subject<{id?: string, formCode?: FormCode, data?: any}> = new Subject();
  triggerClose$: Subject<{id?: string, formCode?: FormCode, data?: any}> = new Subject();
  closeById$: Subject<{ id: string, data?: any }> = new Subject();
  closeAll$: Subject<any> = new Subject();
  delete$: Subject<any> = new Subject();
  submit$: Subject<{id?: string, formCode?: FormCode, data?: any}> = new Subject();

  constructor() {}

  open(popupForm: PopupForm, data?: any) {
    let popupData = {
      popupForm: popupForm,
      data: data? data: null
    }
    this.open$.next(popupData);
  }

  close(formCode: FormCode | string, data?: any) {
    this.close$.next({ formCode: formCode, data: data });
  }

  closeById(id: string, data?: any) {
    this.closeById$.next({ id: id, data: data});
  }

  closeAll() {
    this.closeAll$.next();
  }
}
