import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionToolBarItem } from '../action-tool-bar/model/ActionToolBarItem.model';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ActionToolbarService {
  setting: ActionToolBarSetting;
  names: string[];

  clearInput: Subject<string[]> = new Subject();

  constructor() { }

  show(setting: ActionToolBarSetting, names: string[]) {
    this.set(setting, names);
    names.forEach((name: string) => { this.setHide(name, false); });
    return this.setting;
  }

  hide(setting: ActionToolBarSetting, names: string[]) {
    this.set(setting, names);
    names.forEach((name: string) => { this.setHide(name, true); });
    return this.setting;
  }

  enable(setting: ActionToolBarSetting, names: string[]) {
    this.set(setting, names);
    names.forEach((name: string) => { this.setDisable(name, false); });
    return this.setting;
  }

  disable(setting: ActionToolBarSetting, names: string[]) {
    this.set(setting, names);
    names.forEach((name: string) => { this.setDisable(name, true); });
    return this.setting;
  }

  setText(setting: ActionToolBarSetting, name: string, text: string) {
    let names = [name];
    this.set(setting, names);
    this.text(name, text);
    return this.setting;
  }

  private set(setting: ActionToolBarSetting, names: string[]) {
    this.setting = _.cloneDeep(setting);
    this.names = names;
  }

  private findItem(name: string) {
    let foundItm: ActionToolBarItem = this.setting?.actionToolBarItems?.find((item: ActionToolBarItem) => item.name === name);
    return foundItm;
  }

  private updateSetting(item: ActionToolBarItem) {
    let index = this.setting?.actionToolBarItems?.findIndex((itm: ActionToolBarItem) => itm.name === item.name);

    if(index >= 0) {
      this.setting.actionToolBarItems.splice(index, 1);

      this.setting = {
        ...this.setting,
        actionToolBarItems: [
          ...this.setting.actionToolBarItems,
          item
        ]
      };
    }
  }

  private setHide(name: string, hide?: boolean) {
    let item: ActionToolBarItem = this.findItem(name);

    if(item && typeof hide === 'boolean') {
      item.hide = hide;
    }

    this.updateSetting(item);
  }

  private setDisable(name: string, disable?: boolean) {
    let item: ActionToolBarItem = this.findItem(name);

    if(item && typeof disable === 'boolean') {
      item.disabled = disable;
    }

   this.updateSetting(item);
  }

  private text(name: string, text: string) {
    let item: ActionToolBarItem = this.findItem(name);

    if(item) {
      item.text = text;
    }

   this.updateSetting(item);
  }
}
