<div class="popup-form-layout" #slider [ngClass]="{'show': data}">
    <div class="overlay"></div>
    <div  [className]="['slider ' + position]" [ngStyle]="{'width': getWidth(), 'height': popupForm?.styling?.height}">
        <div class="header">
            <span *ngIf="title">{{ title | translate }}
                <span *ngIf="popupForm.subTitle">{{ popupForm.subTitle | translate }}</span>
            </span>
            <div class="icon-wrapper">
                <img class="icon" src="assets/base/icons/trash-black.svg" (click)="delete()" *ngIf="popupForm?.deleteBtn">
                <mat-icon class="icon" style="margin-left:8px" (click)="popupForm?.disableClose? triggerClose(): close()">close</mat-icon>
            </div>
        </div>
        <div class="form" [ngStyle]="{'padding': popupForm?.styling?.padding? popupForm.styling.padding: '22px 16px'}">
            <ng-container #container></ng-container>
        </div>
    </div>
</div>