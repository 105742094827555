import { Component, Input, OnInit, Output, EventEmitter, HostListener, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { FormTypeCode } from '../../enums/formTypeCode';
import { FormSidenavDialogComponent } from '../form/form-sidenav-dialog/form-sidenav-dialog.component';
import { ActionToolBarItem } from './model/ActionToolBarItem.model';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ButtonType } from '../../enums/buttonType';
import { ActionToolBarResp } from './model/ActionToolBarResp.model';
import { AttributeCode } from '../../enums/attributeCode';
import { ToolbarNewNamePipe } from '../../pipes/toolbar-new-name.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { ActionToolbarService } from '../services/action-toolbar.service';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-action-tool-bar',
  templateUrl: './action-tool-bar.component.html',
  styleUrls: ['./action-tool-bar.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, NgIf, TitleCasePipe, TranslateModule, ToolbarNewNamePipe, SplitButtonModule, MenuModule, MatMenuModule, MatIconModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule]
})
export class ActionToolBarComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() actionToolbarSetting: ActionToolBarSetting;
  @Input() formDetail?: any;
  @Input() isRowSelected?: boolean = null;
  @Output() onAction = new EventEmitter<ActionToolBarResp>();
  @Output() onSubmit = new EventEmitter<{editMode: boolean}>();
  @Output() onActionKeydown = new EventEmitter<any>();

  @ViewChild('input') inputEl: ElementRef;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  actionToolbarItems: ActionToolBarItem[] = [];
  positionedItems: {position: string, item?: ActionToolBarItem[]}[];
  editing: boolean = false;
  isMobile: boolean = false;
  mobileOthersItem: ActionToolBarItem[] = [];
  onInputTimer: any;
  inputText: any = "";

  FormTypeCode = FormTypeCode;
  toolbarPositions = ActionToolbarPosition;

  positions: ActionToolbarPosition[] = [];

  editing$: Subscription;
  clearInput$: Subscription;
  items: MenuItem[] | undefined = [{
    label: 'Options',
    items: [
      {
        label: 'Update',
        icon: 'pi pi-refresh',
      },
      {
        label: 'Delete',
        icon: 'pi pi-times',
      }
    ]
  }];

  constructor(public dialog: MatDialog,
    private actionToolbarService: ActionToolbarService) { }

  async ngOnInit() {
    this.getScreenSize();
    this.setData();

    this.clearInput$ = this.actionToolbarService.clearInput.subscribe((names: string[]) => {
      names.forEach((name: string) => {
        let inputEl = document.getElementById(name) as HTMLInputElement;

        if(inputEl) {
          inputEl.value = "";
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isRowSelected?.currentValue != changes?.isRowSelected?.previousValue) {
      this.actionToolbarItems.forEach((toolbar: ActionToolBarItem, index: number) => {
        if (toolbar?.checkPermission && toolbar.checkPermission.some((attrCode: AttributeCode) => attrCode === AttributeCode.EDIT || attrCode === AttributeCode.DELETE) && this.formDetail?.formTypeCode === FormTypeCode.LISTING) {
          this.actionToolbarItems[index].disabled = !changes.isRowSelected.currentValue;
        }

        if (toolbar.dropdownItm && toolbar.dropdownItm.length > 0) {
          toolbar.dropdownItm.forEach((dropdown: ActionToolBarItem, dropdownIndex: number) => {
            if (dropdown.checkPermission.some((attrCode: AttributeCode) => attrCode === AttributeCode.EDIT || attrCode === AttributeCode.DELETE) && this.formDetail?.formTypeCode === FormTypeCode.LISTING) {
              this.actionToolbarItems[index].dropdownItm[dropdownIndex].disabled = !changes.isRowSelected.currentValue;
            }
          });
        }
      });
    }

    if(changes?.actionToolbarSetting?.currentValue) {
      this.actionToolbarSetting = changes.actionToolbarSetting.currentValue;
      this.setData();
    }
  }

  ngAfterViewInit(): void {
    if (this.inputEl) {
      this.inputEl.nativeElement.focus();
    }
  }

  setData() {
    this.actionToolbarSetting = {
      actionToolBarItems: this.actionToolbarSetting?.actionToolBarItems ? this.actionToolbarSetting?.actionToolBarItems : null,
      customActionToolBarItems: this.actionToolbarSetting?.customActionToolBarItems ? this.actionToolbarSetting?.customActionToolBarItems : null,
      position: this.actionToolbarSetting?.position ? this.actionToolbarSetting.position : ActionToolbarPosition.LEFT,
      popup: this.actionToolbarSetting?.popup ? this.actionToolbarSetting.popup : false
    };

    let actionToolbarItems: ActionToolBarItem[] = this.actionToolbarSetting?.actionToolBarItems ? this.actionToolbarSetting?.actionToolBarItems : null;
    let customActionToolbarItems: ActionToolBarItem[] = this.actionToolbarSetting?.customActionToolBarItems ? this.actionToolbarSetting?.customActionToolBarItems : null;
    let toolbarItems: ActionToolBarItem[] = [];

    // Merge default and custom toolbar
    if (actionToolbarItems && actionToolbarItems.length > 0 && customActionToolbarItems && customActionToolbarItems.length > 0) {
      toolbarItems = actionToolbarItems.concat(customActionToolbarItems);
    } else if (actionToolbarItems && actionToolbarItems.length > 0) {
      toolbarItems = actionToolbarItems;
    } else if (customActionToolbarItems && customActionToolbarItems.length > 0) {
      toolbarItems = customActionToolbarItems;
    }

    // Hide
    toolbarItems = toolbarItems.filter((item: ActionToolBarItem) => !item.hide);

    if (toolbarItems) {
      let mergedToolbarItems = [];

      // Merge duplicated toolbar items by attribute code
      toolbarItems?.forEach((itm: ActionToolBarItem) => {
        let duplicateIndex: number;

        if (mergedToolbarItems.length > 0) {
          duplicateIndex = mergedToolbarItems.findIndex((custom) => custom.name === itm.name);
        }

        if (duplicateIndex >= 0) {
          let dropdownItm = mergedToolbarItems[duplicateIndex].dropdownItm ? mergedToolbarItems[duplicateIndex].dropdownItm : null;

          mergedToolbarItems[duplicateIndex] = this.getSetting(mergedToolbarItems[duplicateIndex], itm);
          mergedToolbarItems[duplicateIndex].dropdownItm = this.getDropdownSetting(dropdownItm, itm.dropdownItm);
        } else {
          let toolbarItem: ActionToolBarItem = _.cloneDeep(itm);

          toolbarItem = this.getSetting(itm);

          if (itm?.dropdownItm && itm.dropdownItm.length > 0) {
            toolbarItem.dropdownItm = this.getDropdownSetting(itm.dropdownItm);
          }

          mergedToolbarItems.push(toolbarItem);
        }
      });

      this.actionToolbarItems = mergedToolbarItems;

      // Check Permission and hide
      this.actionToolbarItems.forEach((item: ActionToolBarItem, index: number) => {
        if (!this.checkPermission(item)) {
          this.actionToolbarItems.splice(index, 1);
        } else if (item.dropdownItm && item.dropdownItm.length > 0) {
          item.dropdownItm.forEach((dropdown: ActionToolBarItem, dropdownIndex: number) => {
            if (!this.checkPermission(dropdown)) {
              this.actionToolbarItems[index].dropdownItm.splice(dropdownIndex, 1);
            }
          });
        } 
      });

      // Collapse
      let collapseItem = this.actionToolbarItems.filter((item, index) => {
        if (item.collapseTo) {
          this.actionToolbarItems[index].mobileOnly = false;
          this.actionToolbarItems[index].deskOnly = true;
        }

        return item.collapseTo;
      });

      collapseItem.forEach((item) => {
        let index = this.actionToolbarItems.findIndex((itm) => itm.name === item.collapseTo);
        let collapseItem = _.cloneDeep(item);
        collapseItem.mobileOnly = true;
        collapseItem.deskOnly = false;

        if (index >= 0) {
          this.actionToolbarItems[index].dropdownItm.unshift(collapseItem);
        } else {
          let othersItem = { name: collapseItem.collapseTo, icon: 'assets/base/icons/ellipsis-v.svg', dropdownItm: [collapseItem] };
          this.actionToolbarItems.unshift(othersItem);
        }
      });

      // Sorting
      this.actionToolbarItems.sort((a, b) => b.sortNo && a.sortNo ? a.sortNo > b.sortNo ? 1 : -1 : 0);

      this.actionToolbarItems.forEach((item, index) => {
        if (item.dropdownItm && item.dropdownItm.length > 0) {
          this.actionToolbarItems[index].dropdownItm = item.dropdownItm.sort((a, b) => b.sortNo && a.sortNo ? a.sortNo > b.sortNo ? 1 : -1 : 0);
        }
      });

      // Positioning
      this.positions = Object.values(ActionToolbarPosition);
      this.positionedItems = this.positions.map((position: string) => {return {position: position, item: []}});
      this.actionToolbarItems.map((item: ActionToolBarItem) => {
        let index = this.positionedItems.findIndex((positionItem: any) => positionItem.position && positionItem.position === item.position);
        if(index >= 0) {
          this.positionedItems[index].item.push(item);
        }
      });

      let foundCenter: any = this.positionedItems.filter((item: any) => item.position === ActionToolbarPosition.CENTER && item.item.length > 0);
      if(foundCenter && foundCenter.length <= 0 ) {
        this.positionedItems = this.positionedItems.filter((item: any) => item.item.length > 0);
      }
    }
  }

  getSetting(toolbarItm?: ActionToolBarItem, customToolbarItm?: ActionToolBarItem) {
    let setting: ActionToolBarItem = {
      id: customToolbarItm?.id ? customToolbarItm.id : toolbarItm?.id ? toolbarItm.id : '',
      name: toolbarItm?.name,
      icon: customToolbarItm?.icon ? customToolbarItm.icon : toolbarItm?.icon ? toolbarItm.icon : '',
      iconStyling: customToolbarItm?.iconStyling ? customToolbarItm.iconStyling : toolbarItm?.iconStyling ? toolbarItm.iconStyling : {},
      text: customToolbarItm?.text ? customToolbarItm.text : toolbarItm?.text ? toolbarItm.text : '',
      input: customToolbarItm?.input ? customToolbarItm.input : toolbarItm?.input ? toolbarItm.input : false,
      disabled: customToolbarItm?.disabled ? customToolbarItm.disabled : toolbarItm?.disabled ? toolbarItm.disabled : false,
      clickable: customToolbarItm?.clickable ? customToolbarItm.clickable : toolbarItm?.clickable ? toolbarItm.clickable : true,
      hide: customToolbarItm?.hide ? customToolbarItm.hide : toolbarItm?.hide ? toolbarItm.hide : false,
      btnType: customToolbarItm?.btnType ? customToolbarItm.btnType : toolbarItm?.btnType ? toolbarItm.btnType : ButtonType.button,
      mobileOnly: customToolbarItm?.mobileOnly ? customToolbarItm.mobileOnly : toolbarItm?.mobileOnly ? toolbarItm.mobileOnly : false,
      deskOnly: customToolbarItm?.deskOnly ? customToolbarItm.deskOnly : toolbarItm?.deskOnly ? toolbarItm?.deskOnly : false,
      checkPermission: customToolbarItm?.checkPermission ? customToolbarItm.checkPermission : toolbarItm?.checkPermission ? toolbarItm?.checkPermission : [],
      sortNo: customToolbarItm?.sortNo ? customToolbarItm.sortNo : toolbarItm?.sortNo ? toolbarItm.sortNo : null,
      collapseTo: customToolbarItm?.collapseTo ? customToolbarItm.collapseTo : toolbarItm?.collapseTo ? toolbarItm.collapseTo : null,
      buttonActive: customToolbarItm?.buttonActive ? customToolbarItm.buttonActive : toolbarItm?.buttonActive ? toolbarItm.buttonActive : false,
      position: customToolbarItm?.position ? customToolbarItm.position : toolbarItm?.position ? toolbarItm.position : this.actionToolbarSetting.position,
      styling: customToolbarItm?.styling ? customToolbarItm.styling : toolbarItm?.styling ? toolbarItm.styling : null
    }

    if(!setting.text && this.formDetail && this.formDetail.formTypeCode === FormTypeCode.LISTING && setting.checkPermission.length && setting.checkPermission.every((attrCode: AttributeCode) => attrCode === AttributeCode.NEW))  {
      setting.text = this.formDetail.formCode;
    }

    if (setting.checkPermission.some((attrCode: AttributeCode) => attrCode === AttributeCode.EDIT || attrCode === AttributeCode.DELETE) && this.formDetail?.formTypeCode === FormTypeCode.LISTING) {
      setting.disabled = true;
    }

    return setting;
  }

  getDropdownSetting(dropdownItm?: ActionToolBarItem[], customToolbarItm?: ActionToolBarItem[]) {
    let dropdownSetting: ActionToolBarItem[] = [];
    let dropdown: ActionToolBarItem[] = [];

    if (customToolbarItm && customToolbarItm.length > 0 && dropdownItm && dropdownItm.length > 0) {
      dropdown = dropdownItm.concat(customToolbarItm)
    } else if (customToolbarItm && customToolbarItm.length > 0) {
      dropdown = customToolbarItm;
    } else if (dropdownItm && dropdownItm.length > 0) {
      dropdown = dropdownItm;
    }

    if (dropdown && dropdown.length > 0) {
      dropdown.forEach((itm: ActionToolBarItem) => {
        let index = dropdownSetting.findIndex((value) => value.name === itm.name);

        if (index >= 0) {
          dropdownSetting[index] = this.getSetting(dropdownSetting[index], itm);
        } else {
          dropdownSetting.push(this.getSetting(itm));
        }
      });
    }

    return dropdownSetting;
  }

  getContainerClass(positionItem: any) {
    let className: string = '';

    if(positionItem.position) {
      className += positionItem.position + ' ';
    }

    if(this.actionToolbarSetting.popup) {
      className += 'popup';
    }

    return className;
  }

  checkPermission(toolbarItm: ActionToolBarItem) {
    let permission: boolean = true;

    if (toolbarItm.checkPermission && toolbarItm.checkPermission.length > 0 && this.formDetail?.attributeCodes && this.formDetail.attributeCodes.length > 0) {
      permission = toolbarItm.checkPermission.every((permission) => this.formDetail.attributeCodes.includes(permission));
    }

    return permission;
  }

  actionButton(event: any, toolbarItm: ActionToolBarItem, dropdown?: boolean) {  
    if (toolbarItm.name && toolbarItm.clickable) {
      if (toolbarItm.input && event.type === 'keyup') {
        this.onAction.emit({ name: toolbarItm.name, data: this.inputEl.nativeElement.value });
      } else if (toolbarItm.input && event.type === 'input') {
        if (this.onInputTimer) {
          clearTimeout(this.onInputTimer);
        }

        this.onInputTimer = setTimeout(() => {
          this.onAction.emit({name: toolbarItm.name, data: this.inputEl.nativeElement.value});
        }, 800);
      } else if(!toolbarItm.input && dropdown && toolbarItm.btnType === ButtonType.submit) {
        this.onSubmit.emit();
        this.onAction.emit({name: toolbarItm.name});
      } else if(!toolbarItm.input) {
        this.onAction.emit({name: toolbarItm.name, toolbar: toolbarItm});
      }
    }
  }

  onInput(event: any, toolbarItm: ActionToolBarItem) {
    this.inputText = event.target.value;
    this.actionButton(event, toolbarItm);
  }

  onClear(event: any, toolbarItm: ActionToolBarItem) {
    this.inputText = "";
    this.inputEl.nativeElement.value = this.inputText;
    this.onAction.emit({name: toolbarItm.name});
  }
  
  showNavItmDialog() {
    this.dialog.open(FormSidenavDialogComponent, {
      panelClass: 'lookup-container'
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: any) {
    this.onActionKeydown.emit(event);
  }

  ngOnDestroy(): void {
    this.editing$?.unsubscribe();
    this.clearInput$?.unsubscribe();
  }
}
