import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dynamic-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dynamic-card.component.html',
  styleUrls: ['./dynamic-card.component.scss']
})
export class DynamicCardComponent implements AfterViewInit{
  @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;

  @Input() component: Type<any>;
  @Input() data: any;

  componentRef: ComponentRef<any>;
  
  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.componentRef = this.container.createComponent(this.component);
    this.componentRef.setInput('data', this.data);
    this.cd.detectChanges();
  }
}
