import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { ButtonToggleComponent } from '../input/button-toggle/button-toggle.component';
import { ButtonToggleSetting } from '../input/model/ButtonToggleSetting.model';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ButtonType } from '../../enums/buttonType';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { ExportService } from '../../services/export.service';
import { pageOrientation } from '../../enums/pageOrientation';
import { exportType } from '../../enums/exportType';

@Component({
  selector: 'app-export-selection',
  standalone: true,
  imports: [CommonModule, TranslateModule, ActionToolBarComponent, MatButtonToggleModule, ButtonToggleComponent, ActionToolBarComponent],
  templateUrl: './export-selection.component.html',
  styleUrls: ['./export-selection.component.scss']
})
export class ExportSelectionComponent implements OnInit {
  pageSettings: ButtonToggleSetting;
  docType: ButtonToggleSetting;
  actionToolbarSetting: ActionToolBarSetting;

  formGroup: UntypedFormGroup;

  constructor(private fb: FormBuilder,
    private exportService: ExportService) {}

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});

    this.pageSettings = {
      name: "pageSettings",
      label: "general.page_settings",
      option: [
        {key: pageOrientation.portriat, name: 'Portrait', icon: 'assets/base/icons/portrait.svg', iconStyling: {width: '24px'}},
        {key: pageOrientation.landscape, name: 'Landscape', icon: 'assets/base/icons/landscape.svg', iconStyling: {width: '24px'}}
      ],
    };

    this.formGroup.addControl(this.pageSettings.name, this.fb.control(this.pageSettings?.option[0].key));

    this.docType = {
      name: "docType",
      label: "general.document_type",
      option: [
        {key: exportType.excel, name: "Excel Sheet", icon: 'assets/base/icons/excel-sheet.svg', iconStyling: {width: '116px'}},
        {key: exportType.pdf, name: "PDF", icon: 'assets/base/icons/pdf.svg' , iconStyling: {width: '116px'}}
      ]
    };

    this.formGroup.addControl(this.docType.name, this.fb.control(this.docType?.option[0].key));

    this.actionToolbarSetting = {
      popup: true,
      position: ActionToolbarPosition.RIGHT,
      actionToolBarItems: [
        { id: 'btn-cancel', name: 'cancel', text: 'toolbar.cancel', btnType: ButtonType.button, sortNo: 1 },
        { id: 'btn-export', name: 'export', text: 'toolbar.export', btnType: ButtonType.button, sortNo: 2, buttonActive: true },
      ]
    }
  }

  onToolbarAction(event: any) {
    if(event.name === 'cancel') {
      this.exportService.closeSelection();
    } else if(event.name === 'export') {
      this.exportService.export$.next({
        type: this.formGroup.get('docType')?.value,
        orientation: this.formGroup.get('pageOrientation')?.value
      });
      this.exportService.closeSelection();
    }
  }
}
