import { form } from './../../../core/models/form';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSetting } from './model/FormSetting.model';
import { InputTypes } from '../input/enum/InputTypes';
import { Page } from '../../models/page.model';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from '../action-tool-bar/model/ActionToolBarResp.model';
import { ToggleInputComponent } from '../input/toggle-input/toggle-input.component';
import { DropdownComponent } from '../input/dropdown/dropdown.component';
import { InputTextComponent } from '../input/input-text/input-text.component';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { LookDropComponent } from '../input/look-drop/look-drop.component';
import { AutoGenerateInputComponent } from '../auto-generate-input/auto-generate-input/auto-generate-input.component';
import { TextAreaComponent } from '../input/text-area/text-area.component';
import { UploadInputComponent } from '../input/upload-input/upload-input.component';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { Message } from 'primeng/api/message';
import { FormService } from './form.service';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '../input/date-picker/date-picker.component';
import { InputNumberComponent } from '../input/input-number/input-number.component';
import { FormContent } from './model/FormContent.model';
import { Mode } from '../../enums/mode';
import { ButtonToggleComponent } from '../input/button-toggle/button-toggle.component';
import { ExpansionPanelComponent } from '../expansion-panel/expansion-panel.component';
import { FormInputsComponent } from './form-inputs/form-inputs.component';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: true,
    imports: [
      NgIf,
      NgClass,
      FormsModule,
      ReactiveFormsModule,
      ActionToolBarComponent,
      NgFor,
      InputTextComponent,
      DropdownComponent,
      LookDropComponent,
      ToggleInputComponent,
      AutoGenerateInputComponent,
      TextAreaComponent,
      UploadInputComponent,
      TranslateModule,
      DatePickerComponent,
      InputNumberComponent,
      ButtonToggleComponent,
      ExpansionPanelComponent,
      FormInputsComponent
    ]
})
export class FormComponent implements OnInit, AfterViewChecked {
  @Input() formSetting?: FormSetting;
  @Input() page: Page;
  @Input() formDetail?: form;
  @Input() formData?: any;
  @Input() actionToolbarSetting: ActionToolBarSetting;

  @Output() onGenerateInput = new EventEmitter();
  @Output() onToggleChange = new EventEmitter();
  @Output() onButtonToggle = new EventEmitter();
  @Output() onDateChange = new EventEmitter();
  @Output() onTimeChange = new EventEmitter();
  @Output() onPeriodChange = new EventEmitter();
  @Output() onToolbarAction = new EventEmitter();
  @Output() onSubmitForm = new EventEmitter();
  @Output() onGetFormGrp = new EventEmitter();

  formGrp: UntypedFormGroup;
  INPUTTYPES = InputTypes;
  requiredFormError: boolean = false;
  isEditMode: boolean = false;
  isShowDevInfo = environment.showDevInfo;
  messages: Message[];

  constructor(
    private cd: ChangeDetectorRef,
    private formService: FormService
    ) { }

  ngOnInit() {
    this.formGrp = new UntypedFormGroup({});

    // Check mode
    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }

    if (this.formSetting && this.formSetting.content) {
      // add grid class to content
      this.formSetting.content = this.formService.formatContentGrid(this.formSetting.content);

      // generate the form group based on the form setting content
      this.formGrp = this.formService.generateFormGroup(this.formSetting.content, this.isEditMode);
      this.onGetFormGrp.emit(this.formGrp);
    }
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  async onSubmit() {
    this.formService.markAllAsTouched(this.formGrp);
    this.requiredFormError = await this.formService.validateFormRequire(this.formGrp, this.formSetting.content);
    if (!this.requiredFormError) {
      this.onSubmitForm.emit(this.formGrp);
    }
  }

  onGenerate(event: any) {
    this.onGenerateInput.emit(event);
  }

  onToggleChg(event: any) {
    this.onToggleChange.emit(event);
  }

  onBtnToggle(event: any) {
    this.onButtonToggle.emit(event);
  }

  onDateChg(event: any) {
    this.onDateChange.emit(event);
  }

  onTimeChg(event: any) {
    this.onTimeChange.emit(event);
  }

  onPeriodChg(event: any) {
    this.onPeriodChange.emit(event);
  }

  onActionButton(value: ActionToolBarResp) {
    this.onToolbarAction.emit(value);
  }

  getFormContentClass(content: FormContent) {
    let className: string = '';

    if(content.gridClass) {
      className += content.gridClass + ' ';
    }

    if(!(!this.formData && (content.uploadInput || content.dateInput))) {
      className += 'field';
    }
    
    return className;
  }
}
