import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpMethod } from '../../core/enums/HttpMethod';
import { CustomRequest } from '../../core/models/local/CustomRequest';
import { CustomService } from '../../core/services/custom.service';
import OdataProvider from 'ag-grid-odata';
import { TranslateService } from '@ngx-translate/core';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { OAuthService } from 'angular-oauth2-oidc';
import { ODataRawQueryOptions } from "src/swagger/cxmbackend/openapi";
import { LoaderService } from "src/app/shared/services/loader.service";
import { CreateInvoiceRequest } from 'src/swagger/cxmbackend/openapi/model/createInvoiceRequest';
import { DeleteInvoiceRequest } from 'src/swagger/cxmbackend/openapi/model/deleteInvoiceRequest';
import { InvoiceEmailRequest } from 'src/swagger/cxmbackend/openapi/model/InvoiceEmailRequest';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private customService: CustomService,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private loaderService: LoaderService,
  ) { }

  getSignature(apiPath: string, options: any) {
    let optionsSpaceFormat = options.replace(/\s/g , "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: apiPath + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }
  async getOdataInvoice(data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataInvoiceData(data, formCode);
  }
  async getInvoiceHtml(invoiceId: number, formCode?: string) {
    return await this.reqInvoiceDataHTML(invoiceId, formCode);
  }
  getOdataInvoiceProvider(invoiceId?: number) {
    return this.reqOdataInvoiceProvider(invoiceId);
  }
  async deleteInvoice(invoiceId: number, formCode?: string) {
    return await this.reqDeleteInvoice(invoiceId, formCode);
  }
  async deleteMultiInvoice(invoiceIds: number[], formCode?: string) {
    return await this.reqDeleteMultiInvoice(invoiceIds, formCode);
  }
  async createInvoice(reqBody: CreateInvoiceRequest, formCode?: string) {
    return await this.reqCreateInvoice(reqBody, formCode);
  }
  async createInvoiceProvider(reqBody: CreateInvoiceRequest) {
    return await this.reqCreateInvoiceProvider(reqBody);
  }
  async sendInvoiceEmail(reqBody: InvoiceEmailRequest, formCode?: string) {
    return await this.reqSendInvoiceEmail(reqBody, formCode);
  }
  private async reqCreateInvoice(reqBody: CreateInvoiceRequest, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.invoice.CreateInvoice,
      body: reqBody,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
  private reqOdataInvoiceProvider(invoiceId?: number) {
    const apiPath = environment.apis.invoice.OdataInvoice;
    console.log("options");

    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${options}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              "Content-Language": this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = invoiceId? ['invoiceId eq ' + invoiceId]: undefined;
      }
    });
  }

  private async reqOdataInvoiceData(data?: ODataRawQueryOptions, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.invoice.OdataInvoice,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: data?.filter.replace('$filter=', '')

      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqInvoiceDataHTML(invoiceId?: number, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.invoice.GetInvoiceDetailHtml,
      hostPath: environment.hostPath,
      queryParams: {
        invoiceId: invoiceId
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqCreateInvoiceProvider(reqBody: CreateInvoiceRequest) {
    // let filter = reqBody.startDate? '?startDate=' + reqBody.startDate.toDateString(): '';
    // filter += reqBody.endDate? '&endDate=' + reqBody.endDate.toDateString(): '';
    // console.log(filter);
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.invoice.CreateInvoice,
      hostPath: environment.hostPath,
      queryParams: {
        //$filter: filter? filter: undefined
        // startDate: reqBody.startDate? reqBody.startDate.toISOString().split('T')[0]: undefined,
        // endDate: reqBody.endDate? reqBody.endDate.toISOString().split('T')[0]: undefined,
        generateDataFile: reqBody.generateDataFile? reqBody.generateDataFile: undefined,
        sendToMerchant: reqBody.sendToMerchant? reqBody.sendToMerchant: undefined,
        storeId: reqBody.storeId? reqBody.storeId: undefined,
        statementIds: reqBody.statementIds? reqBody.statementIds: undefined,
        email: reqBody.email? reqBody.email: null,
        invoiceId: reqBody.invoiceId? reqBody.invoiceId: null,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;

  }

  public async reqSendInvoiceEmail(reqBody: InvoiceEmailRequest, formCode?: string) {

    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.invoice.SendInvoiceEmail,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  private async reqDeleteInvoice(invoiceId: number, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.invoice.DeleteInvoice,
      hostPath: environment.hostPath,
      queryParams: {
        invoiceId: invoiceId
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  private async reqDeleteMultiInvoice(invoiceIds: number[], formCode?: string) {
    let reqBody: DeleteInvoiceRequest = {
      invoiceIds: invoiceIds
    }
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.invoice.DeleteMultiInvoice,
      hostPath: environment.hostPath,
      body: {
        invoiceIds: invoiceIds
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // registerChannel(reqBody: BackendRegisterChannelRequest): Observable<any> {
  //   let cusReq: CustomRequest = {
  //     httpMethod: HttpMethod.POST,
  //     requestpath: environment.apis.channel.RegisterChannel,
  //     hostPath: environment.hostPath,
  //     body: reqBody,
  //     headers: null,
  //     httpHeaderType: undefined
  //   }

  //   console.log('cusReq: ', cusReq)
  //   let hMac = this.customService.assignHMac(cusReq);
  //   console.log('hMac: ', hMac)

  //   this.channelService.apiChannelRegisterchannelPost(reqBody, '1', '00009', hMac, 'en').subscribe(
  //     (data) => {
  //       console.log('data: ', data);
  //       console.log('heyyy')
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );

  //   console.log('downnnn')

  //   return null;
  // }

}
