import { PageRoute } from '../enums/pageRoute';
import { FormCode } from '../enums/formCode';
import { MasterFormComponent } from '../component/master-form/master-form.component';
import { StoreListingComponent } from 'src/app/cxm-backend-web/store/store-listing/store-listing.component';
import { StoreAttrListingComponent } from 'src/app/cxm-backend-web/store/store-attr/store-attr-listing/store-attr-listing.component';
import { StoreEntryComponent } from 'src/app/cxm-backend-web/store/store-entry/store-entry.component';
import { StoreAttrEntryComponent } from 'src/app/cxm-backend-web/store/store-attr/store-attr-entry/store-attr-entry.component';
import { StoreOhComponent } from 'src/app/project-demo/store/store-oh/store-oh.component';
import { StoreOhListingComponent } from "src/app/cxm-backend-web/store/store-oh/store-oh-listing/store-oh-listing.component";
import { StoreOhEntryComponent } from "src/app/cxm-backend-web/store/store-oh/store-oh-entry/store-oh-entry.component";
import { ChannelStoreTaxListingComponent } from 'src/app/cxm-backend-web/store/channelstoretax/channelstoretax-listing/channelstoretax-listing.component';
import { ChannelStoreTaxEntryComponent } from 'src/app/cxm-backend-web/store/channelstoretax/channelstoretax-entry/channelstoretax-entry.component';
import { ChannelStoreListingComponent } from 'src/app/cxm-backend-web/channelstore/channelstore-listing/channelstore-listing.component';
import { ChannelStoreEntryComponent } from 'src/app/cxm-backend-web/channelstore/channelstore-entry/channelstore-entry.component';
import { StoreSettingComponent } from 'src/app/cxm-backend-web/store/store-setting/store-setting.component';
import { StoreCatAttrComponent } from 'src/app/cxm-backend-web/store/store-cat-attr/store-cat-attr.component';
import { StoreCatAttrEntryComponent } from 'src/app/cxm-backend-web/store/store-cat-attr/store-cat-attr-entry/store-cat-attr-entry.component';


export const storePathComponent = [{
  filePath: PageRoute.STORE_LISTING,
  component: StoreListingComponent
},
{
  filePath: PageRoute.STORE_ENTRY,
  formCode: FormCode.storeentry,//add the formCode if the component exist alone or master form
  component: StoreEntryComponent
},
{
  filePath: PageRoute.STORE_MASTER_ENTRY,
  component: MasterFormComponent
},
{
  filePath: PageRoute.STORE_MASTER_ATTRLISTING,
  component: MasterFormComponent
},
{
  filePath: PageRoute.STORE_MASTER_OHLISTING,
  component: MasterFormComponent
},
{
  filePath: PageRoute.STORE_ATTRLISTING,
  formCode: FormCode.storeattrlisting,
  component: StoreAttrListingComponent
},
{
  filePath: PageRoute.STORE_OHLISTING,
  formCode: FormCode.storeohlisting,
  component: StoreOhListingComponent
},
{
  filePath: PageRoute.STORE_ATTR_ENTRY,
  formCode: FormCode.storeattrentry,
  component: StoreAttrEntryComponent
},
{
  filePath: PageRoute.STORE_OH_ENTRY,
  formCode: FormCode.storeohentry,
  component: StoreOhEntryComponent
},
{
  filePath: PageRoute.STORE_CHANNELSTORETAX_LISTING,
  formCode: FormCode.storechannelstoretaxlisting,
  component: ChannelStoreTaxListingComponent
},
{
  filePath: PageRoute.STORE_CHANNELSTORETAX_ENTRY,
  formCode: FormCode.channelstoretaxentry,
  component: ChannelStoreTaxEntryComponent
},
{
  filePath: PageRoute.STORE_MASTER_CHANNELSTORE_LISTING,
  component: MasterFormComponent
},
{
  filePath: PageRoute.STORE_CHANNELSTORE_LISTING,
  formCode: FormCode.storechannelstorelisting,
  component: ChannelStoreListingComponent
},
{
  filePath: PageRoute.STORE_CHANNELSTORE_ENTRY,
  formCode: FormCode.storechannelstoreentry,
  component: ChannelStoreEntryComponent
},
{
  filePath: PageRoute.STORE_MASTER_SETTING,
  component: MasterFormComponent,
},
{
  filePath: PageRoute.STORE_SETTING,
  formCode: FormCode.storesetting,//add the formCode if the component exist alone or master form
  component: StoreSettingComponent
},
{
  filePath: PageRoute.STORE_MASTER_CAT_ATTR,
  component: MasterFormComponent,
},
{
  filePath: PageRoute.STORE_CAT_ATTR,
  formCode: FormCode.storecatgattrlisting,
  component: StoreCatAttrComponent
},
{
  filePath: PageRoute.STORE_CAT_ATTR_ENTRY,
  formCode: FormCode.storecatgattrentry,
  component: StoreCatAttrEntryComponent
},
];
