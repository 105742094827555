import { Component, OnInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from '../../services/toast.service';
import { toastAnimation } from './animation/ToastAnimation';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle, NgIf } from '@angular/common';
import { ToastType } from '../../enums/toastType';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [toastAnimation],
    standalone: true,
    imports: [NgStyle, NgIf, TranslateModule]
})
export class ToastComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter();

  toastState: string = "bottom-center";

  toastData: ToastData;
  toastSub$: Subscription;
  timeOutFunc: any;
  iconColor: string = "#FFFFFF";

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.toastSub$ = this.toastService.showToast.subscribe(toastData => {
      if (toastData) {
        if (this.toastState == 'show') {
          this.closeToast();
          this.showToast(toastData);
        }
        else {
          this.showToast(toastData);
        }
      }
      else {
        this.toastState = "bottom-center";
      }
    });
  }

  ngOnDestroy(): void {
    this.toastSub$?.unsubscribe();
  }

  setToastType() {
    if(this.toastData?.type) {
      let title = '';
      let backgroundColorCode = '';
      let fontClr = '#53536C';

      switch(this.toastData.type) {
        case ToastType.Success:
          title = 'msg.success';
          backgroundColorCode = '#DEF6E4';
          break;
        case ToastType.Error:
          title = 'msg.error';
          backgroundColorCode = '#FFE2E2';
          break;
        case ToastType.Warning:
          title = 'msg.warning';
          backgroundColorCode = '#FFF0D6';
          break;
        case ToastType.Info:
          title = 'msg.info';
          backgroundColorCode = '#D7EFFF';
          break;
        case ToastType.Basic_White:
          backgroundColorCode = '#FFFFFF';
          break;
        case ToastType.Basic_Dark:
          backgroundColorCode = '#1A1A36';
          fontClr = '#FFFFFF';
          break;
        default:
          backgroundColorCode = '#E9E9EC';
          break;
      }

      this.toastData.title = this.toastData?.title? this.toastData.title: title;
      this.toastData.backgroundColorCode = this.toastData?.backgroundColorCode? this.toastData.backgroundColorCode: backgroundColorCode;
      this.toastData.fontColor = this.toastData?.fontColor? this.toastData.fontColor: fontClr;
    }
  } 

  animationDone($event) {
    if ($event.fromState == 'show' && $event.toState == "bottom-center") {
      this.toastData = null;
      this.onClose.emit();
    }
  }

  closeToast() {
    this.toastState = "bottom-center";
    clearTimeout(this.timeOutFunc);
  }

  showToast(toastData: ToastData) {
    this.toastData = toastData;
    this.toastState = 'show';
    this.setToastType();
    let timeToClose = toastData.timeToClose ? toastData.timeToClose : 3000;
    this.iconColor = toastData.iconColor;

    this.timeOutFunc = setTimeout(() => {
      this.toastState = "bottom-center";
    }, timeToClose);
  }
}
