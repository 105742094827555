import { PopupService } from './../../../shared/component/services/popup.service';
import { Component, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { environment } from 'src/environments/environment';

import { InputTypes } from '../../../shared/component/input/enum/InputTypes';
import { FormSetting } from '../../../shared/component/form/model/FormSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormComponent } from '../../../shared/component/form/form.component';
import { StoreService } from '../store.service';
import { LookupCurrencyComponent } from '../../lookup/lookup-currency/lookup-currency.component';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { Router } from '@angular/router';
import { FormService } from 'src/app/shared/component/form/form.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import {
  StoreRegisterstoretwoBody,
  InterfaceType,
  StoreService as StoreAPIService,
  StoreResponse,
  ErrorCode,
} from 'src/swagger/base/openapi';
import { DropdownOption } from 'src/app/shared/component/input/model/DropdownOption.model';
import { CurrencyService, OutletStatus } from 'src/swagger/cxmbackend/openapi';
import { DropdownTimezoneComponent } from '../../dropdown/dropdown-timezone/dropdown-timezone/dropdown-timezone.component';
import { TimeZone } from 'src/app/shared/enums/timeZone';
import { TranslateModule } from '@ngx-translate/core';
import { LookupMerchantComponent } from '../../lookup/lookup-merchant/lookup-merchant.component';
import { DropdownBankComponent } from '../../dropdown/dropdown-bank/dropdown-bank.component';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { LookupCountryComponent } from '../../lookup/lookup-country/lookup-country.component';
import { LookupRegionComponent } from '../../lookup/lookup-region/lookup-region.component';
import { LookupStateComponent } from '../../lookup/lookup-state/lookup-state.component';
import { LookupAreaComponent } from '../../lookup/lookup-area/lookup-area.component';
import { LookupDealerComponent } from '../../lookup/lookup-dealer/lookup-dealer.component';

@Component({
  selector: 'app-store-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, TranslateModule],
  templateUrl: './store-entry.component.html',
  styleUrls: ['./store-entry.component.scss'],
  providers:[CurrencyService]
})
export class StoreEntryComponent {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  storeId: string = '';
  formGroup: FormGroup;
  outletData: any;
  masterData: any;

  pageSub$: Subscription;

  interfaceTypeOption: any = Object.keys(InterfaceType).map((key) => { return { name: key, value: InterfaceType[key] } });

  RegionTimeZone: {[key: string]: string} = {
    'Singapore Standard Time (UTC+08:00)': 'Singapore Standard Time',
    }

  formSetting: FormSetting = {
    formCode: FormCode.storeentry,
    content: [
      // {
      //   lookupInput: {
      //     componentName: MerchantLookupComponent
      //   }
      // },
      {
        lookDropInput: {
          componentName: LookupMerchantComponent,
          label: 'store.merchant_id',
          name: 'merchantId',
          valueKey: 'merchantId',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'storeId',
          label: 'store.store_id',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 25,
          editable: false,
          disabled: true
        },
      },
      {
        inputText: {
          name: 'locCode',
          label: 'store.loc_code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 25,
        },
      },
      {
        inputText: {
          name: 'storeTag',
          label: 'store.tag',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 50,
          // dependency: 'LocCode'
        },
      },
      {
        textArea: {
          name: 'locDesc',
          label: 'store.loc_desc',
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 254,
        },
      },
      {
        inputText: {
          name: 'locShortDesc',
          label: 'store.loc_short_desc',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 150,
        },
      },
      {
        lookDropInput: {
          name: 'statusFlag',
          label: 'Status',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(OutletStatus).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'promoText',
          label: 'store.promo_text',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 150,

        },
      },
      {
        inputText: {
          name: 'badgeText',
          label: 'store.badge_text',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 15,
        },
      },
      {
        inputText: {
          name: 'locTypeCode',
          label: 'store.loc_type_code',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          maxLength: 5,
        }
      },
      {
        lookDropInput: {
          componentName: LookupCurrencyComponent,
          label: 'store.currency',
          name: 'currCode',
          valueKey: 'currCode',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
          required: false
        },
      },
      {
        inputText: {
          name: 'address1',
          label: 'store.addr_1',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '100%'
          },
          maxLength: 100,
        }
      },
      {
        inputText: {
          name: 'address2',
          label: 'store.addr_2',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '100%'
          },
          maxLength: 100,
        }
      },
      {
        inputText: {
          name: 'address3',
          label: 'store.addr_3',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '100%'
          },
          maxLength: 100,
        }
      },
      {
        inputText: {
          name: 'addCity',
          label: 'store.city',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '100%'
          },
          maxLength: 50,
        }
      },
      {
        inputNumber: {
          name: 'addPostal',
          label: 'store.postal_code',
          required: false,
          maxLength: 15,
          styling: {
            width: '60%'
          },
        }
      },
      {
        lookDropInput: {
          name: 'countryCode',
          label: 'store.country_code',
          componentName: LookupCountryComponent,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'stateCode',
          label: 'store.state_code',
          componentName: LookupStateComponent,
          required: false,
          styling: {
            width: '60%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true,
            filterBy: 'countryCode' // optional if same value with name
          },
        },
      },
      {
        lookDropInput: {
          name: 'regionCode',
          label: 'store.region',
          componentName: LookupRegionComponent,
          required: false,
          styling: {
            width: '100%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true
          },
        },
        rowSeq: 16,
        columnSeq: 1
      },
      {
        lookDropInput: {
          name: 'areaCode',
          label: 'store.area',
          componentName: LookupAreaComponent,
          required: false,
          styling: {
            width: '100%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true
          },
        },
        rowSeq: 16,
        columnSeq: 2
      },
      {
        inputText: {
          name: 'email',
          label: 'store.email',
          type: InputTypes.email,
          required: false,
          maxLength: 50,
          styling: {
            width: '60%'
          },
        }
      },
      {
        textArea: {
          name: 'statementEmails',
          label: 'store.statement_emails',
          maxLength: 254,
          styling: {
            width: '60%'
          },
          required: false,
        }
      },
      {
        lookDropInput: {
          name: 'bankCode',
          label: 'store.bank_code',
          componentName: DropdownBankComponent,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'bankAccountNo',
          label: 'store.bank_acc_no',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 100,
        }
      },
      {
        inputText: {
          name: 'bankAccountName',
          label: 'store.bank_acc_name',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 100,

        }
      },
      {
        inputText: {
          name: 'mobileNo',
          label: 'store.mobile_no',
          type: InputTypes.tel,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 15,
        }
      },
      {
        inputText: {
          name: 'phoneNo',
          label: 'store.phone_no',
          type: InputTypes.tel,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 15,
        }
      },
      {
        inputNumber: {
          name: 'latitude',
          label: 'store.latitude',
          required: true,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputNumber: {
          name: 'longitude',
          label: 'store.longitude',
          required: true,
          styling: {
            width: '60%'
          },
        }
      },
      {
        lookDropInput: {
          name: 'timeZone',
          label: 'store.time_zone',
          componentName: DropdownTimezoneComponent,
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'messengerURL',
          label: 'store.messenger_url',
          type: InputTypes.url,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 100,
        }
      },
      {
        inputText: {
          name: 'dineSuspendEnd',
          label: 'store.dine_in_suspend_end_time',
          type: InputTypes.dateTimeLocal,
          dateFormat: 'dd/MM/yy  h:mm a',
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'pickupSuspendEnd',
          label: 'store.pick_up_suspend_end_time',
          type: InputTypes.dateTimeLocal,
          dateFormat: 'dd/MM/yy  h:mm a',
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'deliverySuspendEnd',
          label: 'store.delivery_suspend_end_time',
          type: InputTypes.dateTimeLocal,
          dateFormat: 'dd/MM/yy  h:mm a',
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputNumber: {
          name: 'avgUnitPrice',
          label: 'store.avg_unit_price',
          required: true,
          styling: {
            width: '60%'
          },
        }
      },
      {
        lookDropInput: {
          name: 'interfaceType',
          label: 'store.interface_type',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(InterfaceType).map((key) => {
            return { name: InterfaceType[key], value: InterfaceType[key] };
          }),
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'interfaceCode',
          label: 'store.interface_code',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          maxLength: 25
        }
      },
      {
        lookDropInput: {
          componentName: LookupDealerComponent,
          label: 'store.dealer_id',
          name: 'dealerId',
          valueKey: 'dealerId',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
        },
      },
    ],
    sideNavItm: [
      { name: 'Outlet Details ', action: '' },
      { name: 'Registered User ', action: '' },
      { name: 'Table Section ', action: '' },
      { name: 'Table ', action: '' },
      { name: 'Attribute ', action: '' },
      { name: 'Session Times ', action: '' },
      { name: 'Filter ', action: '' },
      { name: 'Kitchen Queue ', action: '' },
      { name: 'Operating Hours ', action: '' },
    ],
  };

  actionToolbarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private customService: CustomService,
    private storeApiService: StoreAPIService,
    private authService: AuthService,
    private pageService: PageService,
    public dialog: MatDialog,
    private storeService: StoreService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private formService: FormService

  ) {
    this.pageSub$ = this.pageService.pageSubject$.subscribe(async pages => {
      this.pages = pages;

      if (this.page) {
        let foundPage = this.pages.find(page => page.form.filePath === this.page.form.filePath);
        if (foundPage) {
          this.storeId = foundPage.listingId;
          if (this.storeId) {
            await this.getOutlet(this.storeId);
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);


    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.storeId = params['storeId'];
      if (this.storeId) {
        await this.getOutlet(this.storeId.toString());
        this.outletData.timeZone = this.outletData.timeZone? this.outletData.timeZone : 'Singapore Standard Time';
      }
      else {
        this.outletData =
        {
          timeZone: 'Singapore Standard Time',
          locDesc: "N/A",
          statusFlag: 'NewUnregistered',
          latitude: 0,
          longitude: 0,
          avgUnitPrice: 0
        };
      }
    }

  }


  async getOutlet(storeId: string) {
    let result = await this.storeService.getOdataStore(storeId, null, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.outletData = result['value'][0];
    }
  }

  async addStore(reqBody: any) {
    this.loaderService.startLoading();
    let result = await this.storeService.addStore(reqBody, this.formDetail.formCode)
    this.loaderService.stopLoading();
    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {

      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.storelisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.new.store.created',
        desc: "popupMsg.added.new.store",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    } else {
      let msg: PopupMessage = {
        formCode: FormCode.storelisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.new.store.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  async updateStore(reqBody: any) {
    this.loaderService.startLoading();
    let result = await this.storeService.updateStore(reqBody, this.formDetail.formCode)
    this.loaderService.stopLoading();
    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {

      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.storelisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.store.updated',
        desc: "popupMsg.updated.store",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    } else {
      let msg: PopupMessage = {
        formCode: FormCode.storelisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.update.store.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  onGenerateInput(event: any) {
    // call generate api
    let generatedCode = 'code';
    event.formGrp.controls[event.setting.name].setValue(generatedCode);
  }
  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody: StoreRegisterstoretwoBody = {
      merchantId: formGroup.get('merchantId').value,
      locCode: formGroup.get('locCode').value,
      storeTag: formGroup.get('storeTag').value,
      locDesc: formGroup.get('locDesc').value,
      currCode: formGroup.get('currCode').value,
      locShortDesc: formGroup.get('locShortDesc').value,
      promoText: formGroup.get('promoText').value,
      badgeText: formGroup.get('badgeText').value,
      address1: formGroup.get('address1').value,
      address2: formGroup.get('address2').value,
      address3: formGroup.get('address3').value,
      addCity: formGroup.get('addCity').value,
      addPostal: formGroup.get('addPostal').value,
      countryCode: formGroup.get('countryCode').value,
      stateCode: formGroup.get('stateCode').value ? formGroup.get('stateCode').value : null,
      regionCode: formGroup.get('regionCode').value? formGroup.get('regionCode').value : null,
      areaCode: formGroup.get('areaCode').value ? formGroup.get('areaCode').value : null,
      email: formGroup.get('email').value,
      statementEmails: formGroup.get('statementEmails').value,
      bankCode: formGroup.get('bankCode').value,
      bankAccountNo: formGroup.get('bankAccountNo').value? formGroup.get('bankAccountNo').value: '',
      bankAccountName: formGroup.get('bankAccountName').value? formGroup.get('bankAccountName').value: '',
      mobileNo: formGroup.get('mobileNo').value,
      phoneNo: formGroup.get('phoneNo').value,
      latitude: formGroup.get('latitude').value,
      longitude: formGroup.get('longitude').value,
      timeZone: formGroup.get('timeZone').value,
      messengerURL: formGroup.get('messengerURL').value? formGroup.get('messengerURL').value: '',
      dineSuspendEnd: formGroup.get('dineSuspendEnd').value,
      pickupSuspendEnd: formGroup.get('pickupSuspendEnd').value,
      deliverySuspendEnd: formGroup.get('deliverySuspendEnd').value,
      avgUnitPrice: formGroup.get('avgUnitPrice').value,
      interfaceType: formGroup.get('interfaceType').value? formGroup.get('interfaceType').value: null,
      interfaceCode: formGroup.get('interfaceCode').value? formGroup.get('interfaceCode').value: null,
      locTypeCode: formGroup.get('locTypeCode').value,
      statusFlag: formGroup.get('statusFlag').value,
      dealerId: formGroup.get('dealerId').value? formGroup.get('dealerId').value: null
    }

    if (this.storeId) {
      reqBody = { ...reqBody, storeId: +this.storeId };
      this.updateStore(reqBody);
    } else {
      this.addStore(reqBody);
    }
  }

  // Lookup Data
  getMerchant() {
    // Get Merchant
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.merchant.GetAllMerchant,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    this.storeApiService.apiStoreGetallmerchantGet(environment.apiVersion, environment.subscription, hMac, 'en')
      .subscribe((data) => {
        return data;
      }
      );
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy() {
    this.pageSub$.unsubscribe();
  }
}
