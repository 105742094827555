import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { RadioButtonSetting } from '../../model/RadioButtonSetting.model';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-radio-button',
  standalone: true,
  imports: [CommonModule, NgFor, ReactiveFormsModule, FormsModule, MatFormFieldModule, TranslateModule],
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() radioBtnSetting?: RadioButtonSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;
  @Output() onValueChanged = new EventEmitter<any>();

  valueChg$: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.addFormGrp();
    this.valueChg$ = this.formGroup.get(this.radioBtnSetting.name)?.valueChanges.subscribe((val: any) => this.onValueChanged.emit(val));
  }

  addFormGrp() {
    if(!this.formGroup?.get(this.radioBtnSetting.name)) {
      this.formGroup = new UntypedFormGroup({});
      this.formGroup.addControl(this.radioBtnSetting.name, this.fb.control(''));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.addFormGrp();

    if(changes.formData?.currentValue) {
      if(changes.formData.currentValue[this.radioBtnSetting.name] !== null) {
        this.formGroup.controls[this.radioBtnSetting.name].setValue(changes.formData.currentValue[this.radioBtnSetting.name]);
      }
    }
  }

  ngOnDestroy(): void {
    this.valueChg$?.unsubscribe();
  }
}
