import { PageRoute } from "../enums/pageRoute";
import { CurrencyListingComponent } from "src/app/project-demo/currency/currency-listing/currency-listing.component";
import { CurrencyEntryComponent } from "src/app/project-demo/currency/currency-entry/currency-entry.component";
import { PageComponent } from "../models/pageComponent.model";
import { MasterFormComponent } from "../component/master-form/master-form.component";
import { FormCode } from "../enums/formCode";

export const currencyPathComponent: PageComponent[] = [
  {
    filePath: PageRoute.CURRENCY_MASTER_LISTING,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.CURRENCY_MASTER_ENTRY,
    component: MasterFormComponent
  },
  {
    filePath: PageRoute.CURRENCY_ENTRY,
    formCode: FormCode.currencyentry,
    component: CurrencyEntryComponent
  },
  {
    filePath: PageRoute.CURRENCY_LISTING,
    formCode: FormCode.currencylisting,
    component: CurrencyListingComponent
  },
];
