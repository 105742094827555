/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface JobQueueModel { 
    jobId?: number;
    jobStatus?: number;
    /**
     * FE should use JobStatus and map to translation key
     */
    jobStatusDesc?: string;
    jobType?: number;
    jobTypeDesc?: string;
    docNo?: string;
    refCode1?: string;
    startTime?: Date;
    createdDate?: Date;
    lastRevDate?: Date;
}