/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EdmExpressionKind = 'None' | 'BinaryConstant' | 'BooleanConstant' | 'DateTimeOffsetConstant' | 'DecimalConstant' | 'FloatingConstant' | 'GuidConstant' | 'IntegerConstant' | 'StringConstant' | 'DurationConstant' | 'Null' | 'Record' | 'Collection' | 'Path' | 'If' | 'Cast' | 'IsType' | 'FunctionApplication' | 'LabeledExpressionReference' | 'Labeled' | 'PropertyPath' | 'NavigationPropertyPath' | 'DateConstant' | 'TimeOfDayConstant' | 'EnumMember' | 'AnnotationPath';

export const EdmExpressionKind = {
    None: 'None' as EdmExpressionKind,
    BinaryConstant: 'BinaryConstant' as EdmExpressionKind,
    BooleanConstant: 'BooleanConstant' as EdmExpressionKind,
    DateTimeOffsetConstant: 'DateTimeOffsetConstant' as EdmExpressionKind,
    DecimalConstant: 'DecimalConstant' as EdmExpressionKind,
    FloatingConstant: 'FloatingConstant' as EdmExpressionKind,
    GuidConstant: 'GuidConstant' as EdmExpressionKind,
    IntegerConstant: 'IntegerConstant' as EdmExpressionKind,
    StringConstant: 'StringConstant' as EdmExpressionKind,
    DurationConstant: 'DurationConstant' as EdmExpressionKind,
    Null: 'Null' as EdmExpressionKind,
    Record: 'Record' as EdmExpressionKind,
    Collection: 'Collection' as EdmExpressionKind,
    Path: 'Path' as EdmExpressionKind,
    If: 'If' as EdmExpressionKind,
    Cast: 'Cast' as EdmExpressionKind,
    IsType: 'IsType' as EdmExpressionKind,
    FunctionApplication: 'FunctionApplication' as EdmExpressionKind,
    LabeledExpressionReference: 'LabeledExpressionReference' as EdmExpressionKind,
    Labeled: 'Labeled' as EdmExpressionKind,
    PropertyPath: 'PropertyPath' as EdmExpressionKind,
    NavigationPropertyPath: 'NavigationPropertyPath' as EdmExpressionKind,
    DateConstant: 'DateConstant' as EdmExpressionKind,
    TimeOfDayConstant: 'TimeOfDayConstant' as EdmExpressionKind,
    EnumMember: 'EnumMember' as EdmExpressionKind,
    AnnotationPath: 'AnnotationPath' as EdmExpressionKind
};