import { Component, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { ColDef, IRowNode, IServerSideDatasource } from 'ag-grid-community';
import { FormCode } from 'src/app/shared/enums/formCode';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LookDropInputComponent } from "../../../shared/component/input/look-drop-input/look-drop-input.component";
import { DealerListingComponent } from "../../dealer/dealer-listing/dealer-listing.component";
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DealerService } from '../../dealer/dealer.service';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-lookup-dealer',
  standalone: true,
  templateUrl: './lookup-dealer.component.html',
  styleUrls: ['./lookup-dealer.component.scss'],
  imports: [CommonModule, DialogModule, TranslateModule, LookDropInputComponent, DealerListingComponent]
})
export class LookupDealerComponent {
  lookDropInputSetting: LookDropInputSetting; //pass from lookup master component when create component
  formGroup: UntypedFormGroup; //pass from lookup master component when create component
  formData: any;
  pageSub$: Subscription;
  page: Page;
  serverSideDataSource: IServerSideDatasource;
  autoCompleteData: any;
  rowData: any;
  selectedNodes: {node?: IRowNode, value?: any}[];
  multiSelectData: {node?: IRowNode, value?: any}[];
  listingGridApi: any;
  formDetail: any = null;

  hint: string;

  colDef: ColDef[] = [
    {
      field: 'dealerId',
      headerName: 'Dealer ID',
      sortable: true,
    },
    {
      field: 'dealerName',
      headerName: 'Name',
      sortable: true,
    },
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'dealer.filter', input: true, clickable: false, sortNo: 1 },
      {
        name: 'new',
        icon: 'assets/base/icons/plus.svg',
        text: 'toolbar.new_dealer',
        checkPermission: [AttributeCode.NEW],
        sortNo: 2,
        clickable: true
      }
    ]
  };

  constructor(
    private dealerService: DealerService,
    private lookupMasterService: LookupMasterService,
    private pageService: PageService,
    private formService: FormService
  ) {
  }

  ngOnInit(): void {
    // Custom settings
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'dealerId',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : 'dealerId',
      colDef: this.lookDropInputSetting.colDef ? this.lookDropInputSetting.colDef: this.colDef,
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey: 'dealerName',
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : 'general.select_at_least_one',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling? this.lookDropInputSetting.styling: {},
      headerName: this.lookDropInputSetting.headerName? this.lookDropInputSetting.headerName: 'general.dealer',
      dependency: this.lookDropInputSetting.dependency? this.lookDropInputSetting.dependency: null
    }
    this.formDetail = this.formService.getFormDetail(
      FormCode.dealerlisting
    );
  }

  async getDealer(dealerId?: string) {
    let result = await this.dealerService.getOdataDealer(dealerId, null, this.formDetail.formCode);
    if (!(result instanceof HttpErrorResponse)) {
      return result['value'];
    }
    return null;
  }

  async onLookup(data: any) {
    // call api and set row
    this.rowData = await this.getDealer();
  }

  async onAutoComplete(autoCompleteVal: any) {
    // set filter
    let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field); // array of property name, eg. ['currCode', 'currDesc']
    let filter = this.lookupMasterService.setFilter(autoCompleteVal, filterBy);
    let top = 10;

    // call api and open autocomplete
    this.rowData = await this.getDealer(filter);
  }

  onSelected(selectedNodes: IRowNode[]) {
    if(this.lookDropInputSetting?.multiSelect) {
      // set multiselect display value
      this.multiSelectData = selectedNodes.map((node: IRowNode) => {return {node: node, value: node.data.dealerCode}});
    }
  }

  async onLookupToolbarAction(data: any) {
    if(data.name === 'filter') {
      let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(data.data, filterBy);
      this.rowData = await this.getDealer(filter);
    }
    if (data.name === 'new') {
      this.pageService.navigateByPath(PageRoute.DEALER_MASTER_ENTRY);
    }
  }
}
