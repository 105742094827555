import { AttributeCode } from "src/app/shared/enums/attributeCode";
import { ActionToolBarItem } from "../model/ActionToolBarItem.model";
import { ActionToolbarPosition } from "src/app/shared/enums/actionToolbarPosition";

export const toolbarListing: ActionToolBarItem[] = [
    { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1, position: ActionToolbarPosition.LEFT },
    { name: 'new', icon: 'assets/base/icons/plus.svg', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
    { name: 'edit', icon: 'assets/base/icons/pencil.svg', text: 'toolbar.edit', checkPermission: [AttributeCode.EDIT], collapseTo: 'others', sortNo: 3, iconStyling: { width: '16px'} },
    { name: 'delete', icon: 'assets/base/icons/trash.svg', text: 'toolbar.delete', checkPermission: [AttributeCode.DELETE], collapseTo: 'others', sortNo: 4 },
    // { name: 'others', icon: 'assets/base/icons/ellipsis-v.svg', sortNo: 5, dropdownItm: [
    //     { name: 'markas', text: 'toolbar.mark_as', sortNo: 6 },
    //     { name: 'restore', text: 'toolbar.restore_last_change', sortNo: 7 }
    // ]}
];
