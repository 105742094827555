<div class="col-12 form-layout-padding">
  <h1><mark>Grid Layout (form setting)</mark></h1>
  <p>you can see more example at this link.
    <a href="https://primeflex.org/gridsystem" target="_blank">Primeflex link</a>
  </p>
  <app-form class="form-container" [formSetting]="formSettingMix" [actionToolbarSetting]="actionToolbarSetting"></app-form>

  <app-form class="form-container" [formSetting]="formSetting2" [actionToolbarSetting]="actionToolbarSetting"></app-form>

  <h1><mark>Grid Layout</mark></h1>
  <div class="formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="firstname6">Firstname</label>
      <input id="firstname6" type="text"
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
    </div>
    <div class="field col-12 md:col-6">
      <label for="lastname6">Lastname</label>
      <input id="lastname6" type="text"
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
    </div>
    <div class="field col-12">
      <label for="address">Address</label>
      <textarea id="address" type="text" rows="4"
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
    </div>
    <div class="field col-12 md:col-6">
      <label for="city">City</label>
      <input id="city" type="text"
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
    </div>
    <div class="field col-12 md:col-3">
      <label for="state">State</label>
      <select id="state"
        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
        style="appearance: auto">
        <option>Arizona</option>
        <option>California</option>
        <option>Florida</option>
        <option>Ohio</option>
        <option>Washington</option>
      </select>
    </div>
    <div class="field col-12 md:col-3">
      <label for="zip">Zip</label>
      <input id="zip" type="text"
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
    </div>
  </div>
</div>
