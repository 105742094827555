import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTypes } from '../../../shared/component/input/enum/InputTypes';
import { DropdownOption } from '../../../shared/component/input/model/DropdownOption.model';
import { FormSetting } from '../../../shared/component/form/model/FormSetting.model';
import { PageService } from 'src/app/shared/services/page.service';
import { Page } from 'src/app/shared/models/page.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { UntypedFormGroup } from '@angular/forms';
import { MerchantService, MerchantStatus, WalletFlag } from 'src/swagger/cxmbackend/openapi';
import { LookupCountryCodeComponent } from '../../lookup/component/lookup-country-code/lookup-country-code.component';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { LookupStateCodeComponent } from '../../lookup/component/lookup-state-code/lookup-state-code.component';
import { LookupRegionCodeComponent } from '../../lookup/component/lookup-region-code/lookup-region-code.component';
import { LookupAreaCodeComponent } from '../../lookup/component/lookup-area-code/lookup-area-code.component';
import { LocalMerchantService } from '../localMerchant.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormComponent } from '../../../shared/component/form/form.component';

import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';
import { LookupCountryComponent } from '../../lookup/lookup-country/lookup-country.component';
import { LookupStateComponent } from 'src/app/project-demo/lookup/lookup-state/lookup-state.component';
import { LookupRegionComponent } from '../../lookup/lookup-region/lookup-region.component';
import { LookupAreaComponent } from '../../lookup/lookup-area/lookup-area.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Mode } from 'src/app/shared/enums/mode';

@Component({
  selector: 'app-merchant-form',
  templateUrl: './merchant-entry.component.html',
  styleUrls: ['./merchant-entry.component.scss'],
  standalone: true,
  imports: [FormComponent],
  providers: [MerchantService]
})
export class MerchantEntryComponent implements OnInit, OnDestroy {
  page: Page;
  pages: Page[];
  formDetail: any = null;
  merchantId: string;
  merchantData: any;
  isEditMode: boolean;
  page$: Subscription;

  statusFlagOption: DropdownOption[] = [
    {
      name: 'Active',
      value: '0',
    },
    {
      name: 'Suspended',
      value: '1',
    },
    {
      name: 'Online',
      value: '2',
    },
    {
      name: 'Offline',
      value: '3',
    },
    {
      name: 'Inactive',
      value: '9',
    },
  ];

  walletFlagOption = [
    {
      name: WalletFlag.None,
      value: WalletFlag.None
    },
    {
      name: WalletFlag.Activated,
      value: WalletFlag.Activated
    }
  ];

  formSetting: FormSetting = {
    formCode: FormCode.merchantentry,
    content: [
      {
        inputText: {
          name: 'merchantId',
          label: 'Merchant ID',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          maxLength: 10,
          disabled: true
        },
      },
      {
        inputText: {
          name: 'merchantCode',
          label: 'Merchant code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 10
        },
      },
      {
        inputText: {
          name: 'merchantDesc',
          label: 'Merchant description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          }
        }
      },
      {
        inputText: {
          name: 'merchantTag',
          label: 'Merchant tag',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          required: false
        }
      },
      {
        inputText: {
          name: 'subscriptionId',
          label: 'Subscription Id',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
        }
      },
      {
        lookDropInput: {
          name: 'statusFlag',
          label: 'Status',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(MerchantStatus).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'merCatgCode',
          label: 'Merchant category code',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          required: false
        }
      },
      {
        inputText: {
          name: 'contactPerson',
          label: 'Contact person',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'address1',
          label: 'Address 1',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputText: {
          name: 'address2',
          label: 'Address 2',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputText: {
          name: 'address3',
          label: 'Address 3',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputText: {
          name: 'addCity',
          label: 'City',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputNumber: {
          name: 'addPostal',
          label: 'Postal Code',
          required: true,
          maxLength: 5
        }
      },

      {
        lookDropInput: {
          name: 'countryCode',
          label: 'general.merchant.country',
          componentName: LookupCountryComponent,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'stateCode',
          label: 'general.merchant.state',
          componentName: LookupStateComponent,
          required: false,
          styling: {
            width: '60%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true,
            filterBy: 'countryCode' // optional if same value with name
          },
        },
      },
      {
        lookDropInput: {
          name: 'regionCode',
          label: 'general.merchant.region',
          componentName: LookupRegionComponent,
          required: false,
          styling: {
            width: '100%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true
          },
        },
        rowSeq: 16,
        columnSeq: 1
      },
      {
        lookDropInput: {
          name: 'areaCode',
          label: 'general.merchant.area',
          componentName: LookupAreaComponent,
          required: false,
          styling: {
            width: '100%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true
          },
        },
        rowSeq: 16,
        columnSeq: 2
      },
      {
        inputText: {
          name: 'email',
          label: 'Email',
          type: InputTypes.email,
          required: false,
          maxLength: 50,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'statementEmails',
          label: 'Statement Emails',
          type: InputTypes.email,
          maxLength: 254,
          required: false,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'mobileNo',
          label: 'Mobile Number',
          type: InputTypes.tel,
          required: true,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'phoneNo',
          label: 'Phone Number',
          type: InputTypes.tel,
          required: true,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'taxNo',
          label: 'Tax Number',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'webURL',
          label: 'Web URL',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputNumber: {
          name: 'latitude',
          label: 'Latitude',
          required: true,
        }
      },
      {
        inputNumber: {
          name: 'longitude',
          label: 'Longitude',
          required: true,
        }
      },
      {
        inputText: {
          name: 'logoImageData',
          label: 'Logo Image Data',
          type: InputTypes.text,
          required: false
        }
      },
      {
        inputText: {
          name: 'bannerImageData',
          label: 'Banner Image Data',
          type: InputTypes.text,
          required: false
        }
      },
      {
        uploadInput: {
          name: 'logoImage',
          label: 'Logo Image',
          verticalLayout: true,
          acceptFormat: 'image/*',
          dataKey: 'logoImageData',
        }
      },
      {
        uploadInput: {
          name: 'bannerImage',
          label: 'Banner Image',
          acceptFormat: 'image/*',
          dataKey: 'bannerImageData',
        }
      },
      {
        textArea: {
          name: 'aboutMerchant',
          label: 'About Merchant',
          required: false,
          styling: {
            width: '100%',
          },
        },

      },
      {
        inputText: {
          name: 'zeoniqOrderURL',
          label: 'Zeoniq Order URL',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'accessKey',
          label: 'Access Key',
          type: InputTypes.text,
          required: true,
        }
      },
      {
        lookDropInput: {
          name: 'walletFlag',
          label: 'Show Wallet Flag',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(WalletFlag).map((key) => {
            return { name: WalletFlag[key], value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'discoverableFlag',
          label: 'Discoverable Flag',
        }
      },
      {
        toggleInput: {
          name: 'showInfoFlag',
          label: 'Show Info Flag',
        }
      },
      {
        toggleInput: {
          name: 'showFlag',
          label: 'Show Menu Flag',
        }
      },
      {
        toggleInput: {
          name: 'showOutletFlag',
          label: 'Show Outlet Flag',
        }
      },
      {
        toggleInput: {
          name: 'showMemberFlag',
          label: 'Show Member Flag',
        }
      },
      {
        toggleInput: {
          name: 'showOrderFlag',
          label: 'Show Order Flag',
        }
      },
      {
        toggleInput: {
          name: 'orderDineFlag',
          label: 'Order Dine-In Flag',
        }
      },
      {
        toggleInput: {
          name: 'orderPickupFlag',
          label: 'Order Pickup Flag',
        }
      },
      {
        toggleInput: {
          name: 'orderDeliveryFlag',
          label: 'Order Delivery Flag',
        }
      },
    ],
    sideNavItm: [
      { name: 'Merchant 1', action: '' },
      { name: 'Merchant 2', action: '' },
      { name: 'Merchant 3', action: '' },
      { name: 'Merchant 4', action: '' },
      { name: 'Merchant 5', action: '' },
      { name: 'Merchant 6', action: '' },
      { name: 'Merchant 7', action: '' },
      { name: 'Merchant 8', action: '' },
      { name: 'Merchant 9', action: '' },
    ]
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private authService: AuthService,
    private localMerchantService: LocalMerchantService,
    private pageService: PageService,
    private merchantService: MerchantService,
    private customService: CustomService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    public dialog: MatDialog,
    private formService: FormService,
  ) {
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.merchantId = params['merchantId'];
      if (this.merchantId) {
        await this.getMerchant(this.merchantId);
      }
    }
    // Check edit mode
    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }
    else {
      this.merchantData = {
        statusFlag: 'Active',
        latitude: 0,
        longitude: 0,
        accessKey: '99999-99999',
        walletFlag: 'None'
      }
    }
  }


  onToolbarAction(value: ActionToolBarResp) {
  }

  async getMerchant(merchantId: string) {
    // Get Merchant
    let result = await this.localMerchantService.getOdataMerchant(merchantId, null, this.formDetail.formCode);
    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.merchantData = result['value'][0];
    }
  }

  async addMerchant(reqBody: any) {
    this.loaderService.startLoading();
    let result = await this.localMerchantService.reqCreateMerchant(reqBody, this.formDetail.formCode);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.new.merchant.created',
        desc: "popupMsg.added.new.merchant",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.new.merchant.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
  }

  async updateMerchant(reqBody: any) {
    this.loaderService.startLoading();
    let result = await this.localMerchantService.reqUpdateMerchant(reqBody, this.formDetail.formCode);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.merchant.updated',
        desc: "popupMsg.updated.merchant",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.update.merchant.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let formData = this.formService.getFormDataReqBody(formGroup);
    if (!(formGroup.get('email').value)) formData.append("email", '-');
    if (this.isEditMode) {
      formData.append("merchantId", +this.merchantId);
      this.updateMerchant(formData);
    } else {
      this.addMerchant(formData);
    }

    this.pageService.refreshListing$.next([FormCode.merchantlisting]);
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy(): void {
    this.page$?.unsubscribe();
  }
}
