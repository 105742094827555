<div class="dropdown-cell-editor">
    <div class="add-new-container">
        <p>{{ 'general.add_new' | translate }}</p>
        <img src="assets/base/icons/plus.svg" />
    </div>
    <ul>
        <li *ngFor="let option of options" (click)="onSelect(option)">{{ option }}</li>
    </ul>
    <div class="see-more-container" (click)="onSeeMore(true)">
        <p>{{ 'general.more' | translate }}</p>
        <img src="assets/base/icons/arrow-right.svg" />
    </div>
</div>
