<div class="popup-msg-container">
    <div class="close-btn" *ngIf="!data.hideCloseBtn" (click)="close(false)"><img src="assets/base/icons/remove.svg"  /></div>
    <div class="popup-msg">
        <div class="title" *ngIf="data.title">
            <img [src]="data.titleIcon" />
            <h1 mat-dialog-title>{{ data.title | translate }}</h1>
        </div>
        <div class="desc" mat-dialog-content>
          <div [innerHTML]="data.desc | translate"></div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button class="btn-left" (click)="close(true)" *ngIf="data.closeBtnText">{{ data.closeBtnText | translate }}</button>
        <button mat-button class="btn-right" cdkFocusInitial (click)="action()" *ngIf="data.actionBtnText">{{ data.actionBtnText | translate }}</button>
    </div>
</div>
