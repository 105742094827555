<div class="export-selection-layout">
    <div class="page-setting-container" *ngIf="formGroup.get('docType')?.value === 'pdf'">
        <app-button-toggle [buttonToggleSetting]="pageSettings" [formGroup]="formGroup"></app-button-toggle>
    </div>
    <div class="doc-type-container">
        <app-button-toggle [buttonToggleSetting]="docType" [formGroup]="formGroup"></app-button-toggle>
    </div>
    <div class="button-container">
        <app-action-tool-bar [actionToolbarSetting]="actionToolbarSetting" (onAction)="onToolbarAction($event)"></app-action-tool-bar>
    </div>
</div>
