export type AttrCode = 'BANNERIMG1' | 'BIGBANNERIMG1' | 'OUTLETIMG' | 'PRODIMG1' | 'PRODIMG2' | 'PRODIMG3' | 'PRODIMG4';

export const AttrCode = {
    BANNERIMG1: 'BANNERIMG1' as AttrCode,
    BANNERIMG2: 'BANNERIMG2' as AttrCode,
    BANNERIMG3: 'BANNERIMG3' as AttrCode,
    BANNERIMG4: 'BANNERIMG4' as AttrCode,
    BANNERIMG5: 'BANNERIMG5' as AttrCode,
    BIGBANNERIMG1: 'BIGBANNERIMG1' as AttrCode,
    BIGBANNERIMG2: 'BIGBANNERIMG2' as AttrCode,
    BIGBANNERIMG3: 'BIGBANNERIMG3' as AttrCode,
    BIGBANNERIMG4: 'BIGBANNERIMG4' as AttrCode,
    BIGBANNERIMG5: 'BIGBANNERIMG5' as AttrCode,
    OUTLETIMG: 'OUTLETIMG' as AttrCode,
    PRODIMG1: 'PRODIMG1' as AttrCode,
    PRODIMG2: 'PRODIMG2' as AttrCode,
    PRODIMG3: 'PRODIMG3' as AttrCode,
    PRODIMG4: 'PRODIMG4' as AttrCode,
};
