import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GridApi, IRowNode } from 'ag-grid-community';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { CurrencyService } from '../currency.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Mode } from 'src/app/shared/enums/mode';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { Subscription } from 'rxjs';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastType } from 'src/app/shared/enums/toastType';
import { ToastService } from 'src/app/shared/services/toast.service';
import { BackendCurrency } from 'src/swagger/base/openapi';
import { AgGridService } from 'src/app/shared/services/ag-grid.service';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupService } from 'src/app/shared/component/services/popup.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Page } from 'src/app/shared/models/page.model';
import { agGridSideBarType } from 'src/app/shared/enums/agGridSideBarType';

@Component({
  selector: 'app-currency-listing',
  templateUrl: './currency-listing.component.html',
  styleUrls: ['./currency-listing.component.scss'],
  standalone: true,
  imports: [ListingComponent]
})
export class CurrencyListingComponent implements OnInit, OnDestroy {
  @Input() rowData: any; //open lookup pass the data
  @Input() actionToolbarSetting: ActionToolBarSetting;
  @Input() colDef: any;
  @Input() selectedNodes: {node?: IRowNode, value?: any}[];
  @Input() lookDropInputSetting?: LookDropInputSetting;
  @Output() onToolbarAction = new EventEmitter<any>();
  @Output() onRowSelected = new EventEmitter<IRowNode[]>();
  @Output() onRowClicked = new EventEmitter<IRowNode[]>();
  @Output() onApplySelectedRow = new EventEmitter<IRowNode[]>();
  
  refresh$: Subscription;
  actionBtnList$: Subscription;
  deletePopup$: Subscription;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  listingSetting: ListingSetting;
  gridApi: GridApi;
  page: Page;

  constructor(
    private pageService: PageService,
    private currencyService: CurrencyService,
    private toastService: ToastService,
    private agGridService: AgGridService,
    private popupService: PopupService,
    private translateService: TranslateService
  ) { }

  async ngOnInit() {
    this.formCode = FormCode.currencylisting;
    this.page = this.pageService.pages[this.pageService.pages.length - 1];
    this.pageRoute = this.page? this.page.form.filePath: '';

    // Custom action toolbar setting
    if(!this.actionToolbarSetting) {
      this.actionToolbarSetting = {
        actionToolBarItems: [
          ...toolbarListing,
          {
            name: 'new',
            icon: 'assets/base/icons/plus.svg',
            text: 'toolbar.new_currency',
            checkPermission: [AttributeCode.NEW],
            collapseTo: 'others',
            sortNo: 2,
            clickable: true
          }
        ],
        position: ActionToolbarPosition.RIGHT
      };
    }

    // Custom col def
    if(!this.colDef) {
      this.colDef = [
        listingCheckbox,
        {
          ...listingActionButton,
          cellRendererParams: {
            type: ButtonRendererTypeEnum.duplicate,
            setting: this.listingSetting
          },
        },
        {
          ...listingRouterLink,
          field: 'currCode',
          headerName: 'Currency Code',
          sortable: true,
          editable: false,
          cellRendererParams: {
            inRouterLink: {
              route: '/currency/entry',
              params: ['currCode'],
              queryParams: {mode: Mode.edit},
              childPage: true
            }
          }
        },
        {
          field: 'currDesc',
          headerName: 'Description',
          sortable: true,
          editable: true
        },
        {
          field: 'currPrefix',
          headerName: 'Currency Prefix',
          sortable: true,
          editable: true
        },
        {
          field: 'currSuffix',
          headerName: 'Currency Suffix',
          sortable: true,
          editable: true
        }
      ];
    };

    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
      duplicateRow: false,
      sideBar: [agGridSideBarType.columns, agGridSideBarType.filters]
    };

    await this.getCurrencyList();

    // Listen to refresh event
    this.refresh$ = this.pageService.refreshListing$.subscribe(async (formCode: any) => {
      if (formCode.includes(FormCode.currencylisting)) {
        await this.getCurrencyList();
      }
    });

    // Listing action button event
    this.actionBtnList$ = this.pageService.actionBtnListing$.subscribe((value: any) => {
      let data = value?.param?.data;
      let reqBody: BackendCurrency = {
        currCode: data.currCode,
        currDesc: data.currDesc,
        currPrefix: data.currPrefix,
        currSuffix: data.currSuffix,
        rateUDF: ''
      }

      if(value.action && value.action === ButtonRendererTypeEnum.save) {
        let result: any = this.currencyService.updateCurrency(reqBody, FormCode.currencyentry);

        if(!(result instanceof HttpErrorResponse)) {
          this.agGridService.setSuccessSaveMode(value);
        }
      }
    });
  }

  async getCurrencyList() {
    let result = await this.currencyService.getCurrencyList(this.formCode);

    if(!(result instanceof HttpErrorResponse)) {
      this.rowData = result?.value;
    }
  }

  ngOnChanges(changes: any){
    if(changes.selectedNodes?.currentValue) {
      this.selectedNodes = changes.selectedNodes.currentValue;
    }

    if(changes.rowData?.currentValue) {
      this.rowData = changes.rowData.currentValue;
    }
  }

  async toolbarAction(value: ActionToolBarResp) {
    this.onToolbarAction.emit(value);

    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.CURRENCY_ENTRY, null, null, true);
    } else if(value.name === 'filter') {
      let searchVal = value.data? value.data: null;
      this.gridApi.setGridOption("quickFilterText", searchVal);
    } else if(value.name === 'delete') {
      let selectedRows = this.gridApi.getSelectedRows();
      let deleteRows = [];
      deleteRows = _.cloneDeep(selectedRows)?.map(row => row.currCode);
      
      let popupMsg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.delete_confirmation',
        desc: this.translateService.instant('msg.confirm_delete', {form: deleteRows.join(', ')}) ,
        closeBtnText: 'general.cancel',
        actionBtnText: 'toolbar.delete',
      };

      // pass in data & response the button action
      this.deletePopup$ = this.popupService.getDialogResp(popupMsg).subscribe(async (result) => {
        if (result === PopupAction.actionBtn) {
          await Promise.all(selectedRows?.map(async (row: any) => {
            let result = await this.currencyService.deleteCurrency(this.formCode, row.currCode);
      
            if(!(result instanceof HttpErrorResponse)) {
              let toast: ToastData = {
                type: ToastType.Success,
                message: 'Successfully deleted ' + deleteRows.join(', ')
              };
              this.toastService.show(toast);
            }
          }));
      
          this.getCurrencyList();
        }
      });
    }
  }

  rowSelected(nodes: IRowNode[]) {
    this.onRowSelected.emit(nodes);
  }

  rowClicked(nodes: IRowNode[]) {
    this.onRowClicked.emit(nodes);
  }

  applySelectedRow(nodes: IRowNode[]) {
    this.onApplySelectedRow.emit(nodes);
  }

  onGridApi(event: any) {
    this.gridApi = event;
  }

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
    this.actionBtnList$?.unsubscribe();
    this.deletePopup$?.unsubscribe();
  }
}
