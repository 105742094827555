/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BackendMerchant } from '../model/backendMerchant';
import { CXMProblemModel } from '../model/cXMProblemModel';
import { MerchantResponse } from '../model/merchantResponse';
import { MerchantResponseODataQueryOptions } from '../model/merchantResponseODataQueryOptions';
import { MerchantStatus } from '../model/merchantStatus';
import { WalletFlag } from '../model/walletFlag';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MerchantService {

    protected basePath = 'https://cxmbackendapi.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMerchantGet(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MerchantResponse>>;
    public apiMerchantGet(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MerchantResponse>>>;
    public apiMerchantGet(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MerchantResponse>>>;
    public apiMerchantGet(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MerchantResponse>>('get',`${this.basePath}/api/merchant`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMerchantGetallmerchantGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MerchantResponse>>;
    public apiMerchantGetallmerchantGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MerchantResponse>>>;
    public apiMerchantGetallmerchantGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MerchantResponse>>>;
    public apiMerchantGetallmerchantGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MerchantResponse>>('get',`${this.basePath}/api/merchant/getallmerchant`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMerchantRegistermerchantPost(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<MerchantResponse>;
    public apiMerchantRegistermerchantPost(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MerchantResponse>>;
    public apiMerchantRegistermerchantPost(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MerchantResponse>>;
    public apiMerchantRegistermerchantPost(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MerchantResponse>('post',`${this.basePath}/api/merchant/registermerchant`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param merchantId 
     * @param merchantCode 
     * @param merchantDesc 
     * @param subscriptionId 
     * @param merCatgCode 
     * @param merchantTag 
     * @param contactPerson 
     * @param address1 
     * @param address2 
     * @param address3 
     * @param addCity 
     * @param addPostal 
     * @param countryCode 
     * @param stateCode 
     * @param regionCode 
     * @param areaCode 
     * @param email 
     * @param statementEmails 
     * @param mobileNo 
     * @param phoneNo 
     * @param taxNo 
     * @param discoverableFlag 
     * @param accessKey 
     * @param logoImageData 
     * @param logoImage 
     * @param bannerImageData 
     * @param bannerImage 
     * @param aboutMerchant 
     * @param showInfoFlag 
     * @param showFlag 
     * @param showOutletFlag 
     * @param webURL 
     * @param latitude 
     * @param longitude 
     * @param walletFlag 
     * @param showMemberFlag 
     * @param showOrderFlag 
     * @param zeoniqOrderURL 
     * @param orderDineFlag 
     * @param orderPickupFlag 
     * @param orderDeliveryFlag 
     * @param statusFlag 
     * @param createdDate 
     * @param createdUserId 
     * @param lastRevDate 
     * @param lastUserId 
     * @param rowVersion 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMerchantRegistermerchanttwoPostForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<MerchantResponse>;
    public apiMerchantRegistermerchanttwoPostForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MerchantResponse>>;
    public apiMerchantRegistermerchanttwoPostForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MerchantResponse>>;
    public apiMerchantRegistermerchanttwoPostForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




















































        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (merchantId !== undefined) {
            formParams = formParams.append('MerchantId', <any>merchantId) as any || formParams;
        }
        if (merchantCode !== undefined) {
            formParams = formParams.append('MerchantCode', <any>merchantCode) as any || formParams;
        }
        if (merchantDesc !== undefined) {
            formParams = formParams.append('MerchantDesc', <any>merchantDesc) as any || formParams;
        }
        if (subscriptionId !== undefined) {
            formParams = formParams.append('SubscriptionId', <any>subscriptionId) as any || formParams;
        }
        if (merCatgCode !== undefined) {
            formParams = formParams.append('MerCatgCode', <any>merCatgCode) as any || formParams;
        }
        if (merchantTag !== undefined) {
            formParams = formParams.append('MerchantTag', <any>merchantTag) as any || formParams;
        }
        if (contactPerson !== undefined) {
            formParams = formParams.append('ContactPerson', <any>contactPerson) as any || formParams;
        }
        if (address1 !== undefined) {
            formParams = formParams.append('Address1', <any>address1) as any || formParams;
        }
        if (address2 !== undefined) {
            formParams = formParams.append('Address2', <any>address2) as any || formParams;
        }
        if (address3 !== undefined) {
            formParams = formParams.append('Address3', <any>address3) as any || formParams;
        }
        if (addCity !== undefined) {
            formParams = formParams.append('AddCity', <any>addCity) as any || formParams;
        }
        if (addPostal !== undefined) {
            formParams = formParams.append('AddPostal', <any>addPostal) as any || formParams;
        }
        if (countryCode !== undefined) {
            formParams = formParams.append('CountryCode', <any>countryCode) as any || formParams;
        }
        if (stateCode !== undefined) {
            formParams = formParams.append('StateCode', <any>stateCode) as any || formParams;
        }
        if (regionCode !== undefined) {
            formParams = formParams.append('RegionCode', <any>regionCode) as any || formParams;
        }
        if (areaCode !== undefined) {
            formParams = formParams.append('AreaCode', <any>areaCode) as any || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('Email', <any>email) as any || formParams;
        }
        if (statementEmails !== undefined) {
            formParams = formParams.append('StatementEmails', <any>statementEmails) as any || formParams;
        }
        if (mobileNo !== undefined) {
            formParams = formParams.append('MobileNo', <any>mobileNo) as any || formParams;
        }
        if (phoneNo !== undefined) {
            formParams = formParams.append('PhoneNo', <any>phoneNo) as any || formParams;
        }
        if (taxNo !== undefined) {
            formParams = formParams.append('TaxNo', <any>taxNo) as any || formParams;
        }
        if (discoverableFlag !== undefined) {
            formParams = formParams.append('DiscoverableFlag', <any>discoverableFlag) as any || formParams;
        }
        if (accessKey !== undefined) {
            formParams = formParams.append('AccessKey', <any>accessKey) as any || formParams;
        }
        if (logoImageData !== undefined) {
            formParams = formParams.append('LogoImageData', <any>logoImageData) as any || formParams;
        }
        if (logoImage !== undefined) {
            formParams = formParams.append('LogoImage', <any>logoImage) as any || formParams;
        }
        if (bannerImageData !== undefined) {
            formParams = formParams.append('BannerImageData', <any>bannerImageData) as any || formParams;
        }
        if (bannerImage !== undefined) {
            formParams = formParams.append('BannerImage', <any>bannerImage) as any || formParams;
        }
        if (aboutMerchant !== undefined) {
            formParams = formParams.append('AboutMerchant', <any>aboutMerchant) as any || formParams;
        }
        if (showInfoFlag !== undefined) {
            formParams = formParams.append('ShowInfoFlag', <any>showInfoFlag) as any || formParams;
        }
        if (showFlag !== undefined) {
            formParams = formParams.append('ShowFlag', <any>showFlag) as any || formParams;
        }
        if (showOutletFlag !== undefined) {
            formParams = formParams.append('ShowOutletFlag', <any>showOutletFlag) as any || formParams;
        }
        if (webURL !== undefined) {
            formParams = formParams.append('WebURL', <any>webURL) as any || formParams;
        }
        if (latitude !== undefined) {
            formParams = formParams.append('Latitude', <any>latitude) as any || formParams;
        }
        if (longitude !== undefined) {
            formParams = formParams.append('Longitude', <any>longitude) as any || formParams;
        }
        if (walletFlag !== undefined) {
            formParams = formParams.append('WalletFlag', <any>walletFlag) as any || formParams;
        }
        if (showMemberFlag !== undefined) {
            formParams = formParams.append('ShowMemberFlag', <any>showMemberFlag) as any || formParams;
        }
        if (showOrderFlag !== undefined) {
            formParams = formParams.append('ShowOrderFlag', <any>showOrderFlag) as any || formParams;
        }
        if (zeoniqOrderURL !== undefined) {
            formParams = formParams.append('ZeoniqOrderURL', <any>zeoniqOrderURL) as any || formParams;
        }
        if (orderDineFlag !== undefined) {
            formParams = formParams.append('OrderDineFlag', <any>orderDineFlag) as any || formParams;
        }
        if (orderPickupFlag !== undefined) {
            formParams = formParams.append('OrderPickupFlag', <any>orderPickupFlag) as any || formParams;
        }
        if (orderDeliveryFlag !== undefined) {
            formParams = formParams.append('OrderDeliveryFlag', <any>orderDeliveryFlag) as any || formParams;
        }
        if (statusFlag !== undefined) {
            formParams = formParams.append('StatusFlag', <any>statusFlag) as any || formParams;
        }
        if (createdDate !== undefined) {
            formParams = formParams.append('CreatedDate', <any>createdDate) as any || formParams;
        }
        if (createdUserId !== undefined) {
            formParams = formParams.append('CreatedUserId', <any>createdUserId) as any || formParams;
        }
        if (lastRevDate !== undefined) {
            formParams = formParams.append('LastRevDate', <any>lastRevDate) as any || formParams;
        }
        if (lastUserId !== undefined) {
            formParams = formParams.append('LastUserId', <any>lastUserId) as any || formParams;
        }
        if (rowVersion !== undefined) {
            formParams = formParams.append('RowVersion', <any>rowVersion) as any || formParams;
        }

        return this.httpClient.request<MerchantResponse>('post',`${this.basePath}/api/merchant/registermerchanttwo`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMerchantUpdatemerchantPut(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<MerchantResponse>;
    public apiMerchantUpdatemerchantPut(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MerchantResponse>>;
    public apiMerchantUpdatemerchantPut(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MerchantResponse>>;
    public apiMerchantUpdatemerchantPut(body?: BackendMerchant, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MerchantResponse>('put',`${this.basePath}/api/merchant/updatemerchant`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param merchantId 
     * @param merchantCode 
     * @param merchantDesc 
     * @param subscriptionId 
     * @param merCatgCode 
     * @param merchantTag 
     * @param contactPerson 
     * @param address1 
     * @param address2 
     * @param address3 
     * @param addCity 
     * @param addPostal 
     * @param countryCode 
     * @param stateCode 
     * @param regionCode 
     * @param areaCode 
     * @param email 
     * @param statementEmails 
     * @param mobileNo 
     * @param phoneNo 
     * @param taxNo 
     * @param discoverableFlag 
     * @param accessKey 
     * @param logoImageData 
     * @param logoImage 
     * @param bannerImageData 
     * @param bannerImage 
     * @param aboutMerchant 
     * @param showInfoFlag 
     * @param showFlag 
     * @param showOutletFlag 
     * @param webURL 
     * @param latitude 
     * @param longitude 
     * @param walletFlag 
     * @param showMemberFlag 
     * @param showOrderFlag 
     * @param zeoniqOrderURL 
     * @param orderDineFlag 
     * @param orderPickupFlag 
     * @param orderDeliveryFlag 
     * @param statusFlag 
     * @param createdDate 
     * @param createdUserId 
     * @param lastRevDate 
     * @param lastUserId 
     * @param rowVersion 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMerchantUpdatemerchanttwoPutForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<MerchantResponse>;
    public apiMerchantUpdatemerchanttwoPutForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MerchantResponse>>;
    public apiMerchantUpdatemerchanttwoPutForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MerchantResponse>>;
    public apiMerchantUpdatemerchanttwoPutForm(merchantId?: number, merchantCode?: string, merchantDesc?: string, subscriptionId?: string, merCatgCode?: string, merchantTag?: string, contactPerson?: string, address1?: string, address2?: string, address3?: string, addCity?: string, addPostal?: string, countryCode?: string, stateCode?: string, regionCode?: string, areaCode?: string, email?: string, statementEmails?: string, mobileNo?: string, phoneNo?: string, taxNo?: string, discoverableFlag?: boolean, accessKey?: string, logoImageData?: string, logoImage?: Blob, bannerImageData?: string, bannerImage?: Blob, aboutMerchant?: string, showInfoFlag?: boolean, showFlag?: boolean, showOutletFlag?: boolean, webURL?: string, latitude?: number, longitude?: number, walletFlag?: WalletFlag, showMemberFlag?: boolean, showOrderFlag?: boolean, zeoniqOrderURL?: string, orderDineFlag?: boolean, orderPickupFlag?: boolean, orderDeliveryFlag?: boolean, statusFlag?: MerchantStatus, createdDate?: Date, createdUserId?: string, lastRevDate?: Date, lastUserId?: string, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




















































        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (merchantId !== undefined) {
            formParams = formParams.append('MerchantId', <any>merchantId) as any || formParams;
        }
        if (merchantCode !== undefined) {
            formParams = formParams.append('MerchantCode', <any>merchantCode) as any || formParams;
        }
        if (merchantDesc !== undefined) {
            formParams = formParams.append('MerchantDesc', <any>merchantDesc) as any || formParams;
        }
        if (subscriptionId !== undefined) {
            formParams = formParams.append('SubscriptionId', <any>subscriptionId) as any || formParams;
        }
        if (merCatgCode !== undefined) {
            formParams = formParams.append('MerCatgCode', <any>merCatgCode) as any || formParams;
        }
        if (merchantTag !== undefined) {
            formParams = formParams.append('MerchantTag', <any>merchantTag) as any || formParams;
        }
        if (contactPerson !== undefined) {
            formParams = formParams.append('ContactPerson', <any>contactPerson) as any || formParams;
        }
        if (address1 !== undefined) {
            formParams = formParams.append('Address1', <any>address1) as any || formParams;
        }
        if (address2 !== undefined) {
            formParams = formParams.append('Address2', <any>address2) as any || formParams;
        }
        if (address3 !== undefined) {
            formParams = formParams.append('Address3', <any>address3) as any || formParams;
        }
        if (addCity !== undefined) {
            formParams = formParams.append('AddCity', <any>addCity) as any || formParams;
        }
        if (addPostal !== undefined) {
            formParams = formParams.append('AddPostal', <any>addPostal) as any || formParams;
        }
        if (countryCode !== undefined) {
            formParams = formParams.append('CountryCode', <any>countryCode) as any || formParams;
        }
        if (stateCode !== undefined) {
            formParams = formParams.append('StateCode', <any>stateCode) as any || formParams;
        }
        if (regionCode !== undefined) {
            formParams = formParams.append('RegionCode', <any>regionCode) as any || formParams;
        }
        if (areaCode !== undefined) {
            formParams = formParams.append('AreaCode', <any>areaCode) as any || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('Email', <any>email) as any || formParams;
        }
        if (statementEmails !== undefined) {
            formParams = formParams.append('StatementEmails', <any>statementEmails) as any || formParams;
        }
        if (mobileNo !== undefined) {
            formParams = formParams.append('MobileNo', <any>mobileNo) as any || formParams;
        }
        if (phoneNo !== undefined) {
            formParams = formParams.append('PhoneNo', <any>phoneNo) as any || formParams;
        }
        if (taxNo !== undefined) {
            formParams = formParams.append('TaxNo', <any>taxNo) as any || formParams;
        }
        if (discoverableFlag !== undefined) {
            formParams = formParams.append('DiscoverableFlag', <any>discoverableFlag) as any || formParams;
        }
        if (accessKey !== undefined) {
            formParams = formParams.append('AccessKey', <any>accessKey) as any || formParams;
        }
        if (logoImageData !== undefined) {
            formParams = formParams.append('LogoImageData', <any>logoImageData) as any || formParams;
        }
        if (logoImage !== undefined) {
            formParams = formParams.append('LogoImage', <any>logoImage) as any || formParams;
        }
        if (bannerImageData !== undefined) {
            formParams = formParams.append('BannerImageData', <any>bannerImageData) as any || formParams;
        }
        if (bannerImage !== undefined) {
            formParams = formParams.append('BannerImage', <any>bannerImage) as any || formParams;
        }
        if (aboutMerchant !== undefined) {
            formParams = formParams.append('AboutMerchant', <any>aboutMerchant) as any || formParams;
        }
        if (showInfoFlag !== undefined) {
            formParams = formParams.append('ShowInfoFlag', <any>showInfoFlag) as any || formParams;
        }
        if (showFlag !== undefined) {
            formParams = formParams.append('ShowFlag', <any>showFlag) as any || formParams;
        }
        if (showOutletFlag !== undefined) {
            formParams = formParams.append('ShowOutletFlag', <any>showOutletFlag) as any || formParams;
        }
        if (webURL !== undefined) {
            formParams = formParams.append('WebURL', <any>webURL) as any || formParams;
        }
        if (latitude !== undefined) {
            formParams = formParams.append('Latitude', <any>latitude) as any || formParams;
        }
        if (longitude !== undefined) {
            formParams = formParams.append('Longitude', <any>longitude) as any || formParams;
        }
        if (walletFlag !== undefined) {
            formParams = formParams.append('WalletFlag', <any>walletFlag) as any || formParams;
        }
        if (showMemberFlag !== undefined) {
            formParams = formParams.append('ShowMemberFlag', <any>showMemberFlag) as any || formParams;
        }
        if (showOrderFlag !== undefined) {
            formParams = formParams.append('ShowOrderFlag', <any>showOrderFlag) as any || formParams;
        }
        if (zeoniqOrderURL !== undefined) {
            formParams = formParams.append('ZeoniqOrderURL', <any>zeoniqOrderURL) as any || formParams;
        }
        if (orderDineFlag !== undefined) {
            formParams = formParams.append('OrderDineFlag', <any>orderDineFlag) as any || formParams;
        }
        if (orderPickupFlag !== undefined) {
            formParams = formParams.append('OrderPickupFlag', <any>orderPickupFlag) as any || formParams;
        }
        if (orderDeliveryFlag !== undefined) {
            formParams = formParams.append('OrderDeliveryFlag', <any>orderDeliveryFlag) as any || formParams;
        }
        if (statusFlag !== undefined) {
            formParams = formParams.append('StatusFlag', <any>statusFlag) as any || formParams;
        }
        if (createdDate !== undefined) {
            formParams = formParams.append('CreatedDate', <any>createdDate) as any || formParams;
        }
        if (createdUserId !== undefined) {
            formParams = formParams.append('CreatedUserId', <any>createdUserId) as any || formParams;
        }
        if (lastRevDate !== undefined) {
            formParams = formParams.append('LastRevDate', <any>lastRevDate) as any || formParams;
        }
        if (lastUserId !== undefined) {
            formParams = formParams.append('LastUserId', <any>lastUserId) as any || formParams;
        }
        if (rowVersion !== undefined) {
            formParams = formParams.append('RowVersion', <any>rowVersion) as any || formParams;
        }

        return this.httpClient.request<MerchantResponse>('put',`${this.basePath}/api/merchant/updatemerchanttwo`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataMerchant(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MerchantResponse>>;
    public odataMerchant(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MerchantResponse>>>;
    public odataMerchant(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MerchantResponse>>>;
    public odataMerchant(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MerchantResponse>>('get',`${this.basePath}/odata/merchant`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataMerchantcount(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MerchantResponse>>;
    public odataMerchantcount(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MerchantResponse>>>;
    public odataMerchantcount(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MerchantResponse>>>;
    public odataMerchantcount(oDataQueryOptions?: MerchantResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MerchantResponse>>('get',`${this.basePath}/odata/merchant/$count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
