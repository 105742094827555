import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router';
import { form } from 'src/app/core/models/form'
import { FormTypeCode } from 'src/app/shared/enums/formTypeCode';
import { NgFor, NgClass } from '@angular/common';
import { PageService } from 'src/app/shared/services/page.service';

@Component({
    selector: 'app-form-sidenav',
    templateUrl: './form-sidenav.component.html',
    styleUrls: ['./form-sidenav.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass]
})
export class FormSidenavComponent implements OnInit {
  @Input() childFormDatas: form[] = [];
  @Input() page = null;
  @Input() params = null;
  @Output() openChildForm = new EventEmitter();
  sideNavIndex: number = 0;
  formTypeCode = FormTypeCode;

  constructor(
    private router: Router,
    private pageService: PageService,
  ) { }

  ngOnInit(): void {
    this.params = this.pageService.getParameter(this.page);
    this.sideNavIndex = this.childFormDatas.findIndex(child => this.router.url.includes(child.filePath));
  }

  onClickSideNavItem(event: Event, childForm: form, index: number) {
    if (childForm.formTypeCode !== FormTypeCode.ENTRY && !this.params) {
      event.preventDefault();
    } else {
      this.sideNavIndex = index;
      this.openChildForm.emit(childForm);
    }
  }

  routeNavigate(childForm: form) {
    let route = this.params ? (childForm?.filePath + '/' + this.params) : childForm?.filePath;
    if (childForm.formTypeCode !== FormTypeCode.ENTRY && !this.params) {
      return;
    } else {
      this.pageService.navigateByPath(route, null, null, true);
    }
  }
}
