/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type QueryNodeKind = 'None' | 'Constant' | 'Convert' | 'NonResourceRangeVariableReference' | 'BinaryOperator' | 'UnaryOperator' | 'SingleValuePropertyAccess' | 'CollectionPropertyAccess' | 'SingleValueFunctionCall' | 'Any' | 'CollectionNavigationNode' | 'SingleNavigationNode' | 'SingleValueOpenPropertyAccess' | 'SingleResourceCast' | 'All' | 'CollectionResourceCast' | 'ResourceRangeVariableReference' | 'SingleResourceFunctionCall' | 'CollectionFunctionCall' | 'CollectionResourceFunctionCall' | 'NamedFunctionParameter' | 'ParameterAlias' | 'EntitySet' | 'KeyLookup' | 'SearchTerm' | 'CollectionOpenPropertyAccess' | 'CollectionComplexNode' | 'SingleComplexNode' | 'Count' | 'SingleValueCast' | 'CollectionPropertyNode' | 'AggregatedCollectionPropertyNode' | 'In' | 'CollectionConstant';

export const QueryNodeKind = {
    None: 'None' as QueryNodeKind,
    Constant: 'Constant' as QueryNodeKind,
    Convert: 'Convert' as QueryNodeKind,
    NonResourceRangeVariableReference: 'NonResourceRangeVariableReference' as QueryNodeKind,
    BinaryOperator: 'BinaryOperator' as QueryNodeKind,
    UnaryOperator: 'UnaryOperator' as QueryNodeKind,
    SingleValuePropertyAccess: 'SingleValuePropertyAccess' as QueryNodeKind,
    CollectionPropertyAccess: 'CollectionPropertyAccess' as QueryNodeKind,
    SingleValueFunctionCall: 'SingleValueFunctionCall' as QueryNodeKind,
    Any: 'Any' as QueryNodeKind,
    CollectionNavigationNode: 'CollectionNavigationNode' as QueryNodeKind,
    SingleNavigationNode: 'SingleNavigationNode' as QueryNodeKind,
    SingleValueOpenPropertyAccess: 'SingleValueOpenPropertyAccess' as QueryNodeKind,
    SingleResourceCast: 'SingleResourceCast' as QueryNodeKind,
    All: 'All' as QueryNodeKind,
    CollectionResourceCast: 'CollectionResourceCast' as QueryNodeKind,
    ResourceRangeVariableReference: 'ResourceRangeVariableReference' as QueryNodeKind,
    SingleResourceFunctionCall: 'SingleResourceFunctionCall' as QueryNodeKind,
    CollectionFunctionCall: 'CollectionFunctionCall' as QueryNodeKind,
    CollectionResourceFunctionCall: 'CollectionResourceFunctionCall' as QueryNodeKind,
    NamedFunctionParameter: 'NamedFunctionParameter' as QueryNodeKind,
    ParameterAlias: 'ParameterAlias' as QueryNodeKind,
    EntitySet: 'EntitySet' as QueryNodeKind,
    KeyLookup: 'KeyLookup' as QueryNodeKind,
    SearchTerm: 'SearchTerm' as QueryNodeKind,
    CollectionOpenPropertyAccess: 'CollectionOpenPropertyAccess' as QueryNodeKind,
    CollectionComplexNode: 'CollectionComplexNode' as QueryNodeKind,
    SingleComplexNode: 'SingleComplexNode' as QueryNodeKind,
    Count: 'Count' as QueryNodeKind,
    SingleValueCast: 'SingleValueCast' as QueryNodeKind,
    CollectionPropertyNode: 'CollectionPropertyNode' as QueryNodeKind,
    AggregatedCollectionPropertyNode: 'AggregatedCollectionPropertyNode' as QueryNodeKind,
    In: 'In' as QueryNodeKind,
    CollectionConstant: 'CollectionConstant' as QueryNodeKind
};