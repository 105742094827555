<mat-toolbar class="toolbar">
  <button class="menu-icon" aria-label="Toggle sidenav" mat-button (click)="drawer.toggle(); toggleSideNav();"
    *ngIf="sideNavPinned && !isStandalonePage">
    <img class="toolbar-menu-icon" style="width: 28px;" [class.flipped]="menuOpen" src="assets/base/icons/rms-menu.svg" />
  </button>
  <div *ngIf="sideNavPinned" style="display: flex; cursor: pointer;" [ngStyle]="{'cursor': isStandalonePage? 'default': 'pointer'}">
    <img style="height: 32px; margin-left: 10px;" src="assets/base/images/ZeoniqLogo-R.svg" (click)="clickLogo()"/>
  </div>
  <span class="toolbar-spacer"></span>
  <span class="subscription" *ngIf="!isStandalonePage && subscriptionDetail">
    {{subscriptionDetail.subscriptionName}} | {{subscriptionDetail.subscriptionId}}
  </span>
  <button class="trigger-btn" (click)="openJobQueue()" *ngIf="!isStandalonePage">
    <img class="toolbar-icon" src="assets/base/icons/listMagnifyingGlass.svg" />
  </button>
  <!-- <button class="trigger-btn" *ngIf="!isStandalonePage">
    <img class="toolbar-icon" src="assets/base/icons/notebook.svg" />
  </button> -->
  <!-- <button class="trigger-btn" (click)="toggleDropdown('notification')" *ngIf="!isStandalonePage">
    <img class="toolbar-icon" [ngClass]="{'active': currentContent === 'notification'}"
      src="assets/base/icons/bell.svg" />
  </button> -->
  <button class="trigger-btn" (click)="toggleDropdown('user')" *ngIf="!isStandalonePage">
    <div class="toolbar-profile-icon" [ngClass]="{'active': currentContent === 'user'}">
      {{ userProfile?.firstName[0]?.toUpperCase() }}
    </div>
  </button>
</mat-toolbar>
