export enum FormCodeBase {
  merchantentry = 'merchantentry',
  merchantlisting = 'merchantlisting',

  storeentry = 'storeentry',
  storelisting = 'storelisting',
  storeattr = 'storeattr',
  storeattrentry = 'storeattrentry',
  storeattrlisting = "storeattrlisting",
  storeoh = "storeoh",

  statementlisting = 'statementlisting',

  currencyentry = 'currencyentry',
  currencylisting = 'currencylisting',
}
