export enum InputTypes {
    date = 'date',
    dateTimeLocal = 'datetime-local',
    email = 'email',
    month = 'month',
    year = 'year',
    number = 'number',
    password = 'password',
    search = 'search',
    text = 'text',
    tel = 'tel',
    time = 'time',
    url = 'url',
    week = 'week',
}
