import { CardContent } from '../shared/component/card/model/CardContent';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CardSetting } from 'src/app/shared/component/card/model/CardSetting';
import { SubscriptionCardComponent } from '../shared/component/card/subscription-card/subscription-card.component';
import { CommonModule, NgFor } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SubscriptionService } from '../core/services/subscription.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionStatus } from '../shared/enums/subscriptionStatus';
import { LottieComponent } from 'ngx-lottie';
import { AuthService } from '../core/services/auth.service';
import { SubscriptionListDetailOutputDTOCustomODataResult } from '../core/models/local/SubscriptionListDetailOutputDTOCustomODataResult';
import { SubscriptionUserService } from '../core/services/subscriptionUser.service';
import { Pagination } from '../shared/enums/pagination';
import { SubscriptionListDetailOutputDTO } from '../core/models/local/SubscriptionListDetailOutputDTO';
import { CustomService } from '../core/services/custom.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    MatButtonModule,
    MatMenuModule,
    NgFor,
    SubscriptionCardComponent,
    CommonModule,
    LottieComponent,
  ]
})
export class SubscriptionComponent implements OnInit {
  @Input() subscripData: CardSetting;
  @Input() subscripContent: CardContent;

  status: any[] = [
    { label: 'All', value: SubscriptionStatus.All },
    { label: 'Active', value: SubscriptionStatus.Active },
    { label: 'Suspended', value: SubscriptionStatus.Suspended },
    { label: 'Expired', value: SubscriptionStatus.Expired },
  ];

  selectedStatus: any = '';
  searchSub: string = '';
  subscriptionInfoList: SubscriptionListDetailOutputDTOCustomODataResult = null;
  subscriptionDisplayList: SubscriptionListDetailOutputDTO[] = [];
  pageLoading: boolean = false;
  loadingComplete: boolean = false;
  pagination: Pagination = {
    top: 20,
    skip: 0
  }
  showWarningPage: boolean = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private authService: AuthService,
    private subscriptionUserService: SubscriptionUserService,
    private customService: CustomService,
    private translate: TranslateService
  ) { this.translate.setDefaultLang('en-US'); }

  async ngOnInit() {
    this.subscriptionInfoList = await this.subscriptionService.getSubscriptionInfoList(this.pagination);
    if (!(this.subscriptionInfoList instanceof HttpErrorResponse)) {
      this.subscriptionDisplayList = this.subscriptionInfoList.value;

      // if api return only subscription, it will auto select it and redirect to dashboard page
      if (this.subscriptionDisplayList?.length == 1 && this.subscriptionDisplayList[0]?.status === SubscriptionStatus.Active) {
        let selectedSubscription = this.subscriptionDisplayList[0];
        selectedSubscription.hideSwitchSubscription = true;
        this.onSelectSubscription(selectedSubscription);
      } else if (this.subscriptionInfoList.value.length == 0) {
        this.showWarningPage = true;
      }
    }
  }

  filterSubscriptions() {
    let searchResult = this.subscriptionInfoList.value;

    if (this.selectedStatus.value !== SubscriptionStatus.All) {
      searchResult = searchResult.filter((subscription) => subscription.status.toLowerCase() === this.selectedStatus.value.toLowerCase());
    }

    if (this.searchSub) {
      const searchTerm = this.searchSub.toLowerCase();
      this.subscriptionDisplayList = searchResult.filter(searchList => {
        return searchList?.billingName?.toLowerCase().includes(searchTerm) ||
          searchList?.subscriptionId?.toLowerCase().includes(searchTerm) ||
          searchList?.subscriptionName?.toLowerCase().includes(searchTerm)
      });
    } else {
      this.subscriptionDisplayList = searchResult;
    }
  }

  async onSelectSubscription(SubscriptionResult: SubscriptionListDetailOutputDTO) {
    let subscriptionResponse = await this.subscriptionService.getRMSSubscriptionInfo(SubscriptionResult.subscriptionId);

    await this.subscriptionService.selectActiveSubscription(subscriptionResponse);
    this.customService.curHttpHeader.subscriptionId = subscriptionResponse.subscriptionId;
    this.subscriptionUserService.initSubscriptionUser();
  }

  logout() {
    this.authService.logout();
  }

  @HostListener('window:scroll', ['$event'])
  async onScroll(event: Event) {
    const container = event.target as HTMLElement;
    const scrollPosition = Math.round(container.scrollTop + container.offsetHeight);
    const scrollHeight = container.scrollHeight;

    let diffBottom = scrollHeight - scrollPosition;

    if (diffBottom < 50 && !this.pageLoading && !this.loadingComplete) {
      this.pageLoading = true;
      this.pagination.skip += this.pagination.top;
      let subscriptionList = await this.subscriptionService.getSubscriptionInfoList(this.pagination);

      if (!(subscriptionList instanceof HttpErrorResponse)) {
        this.subscriptionInfoList.value = [...this.subscriptionInfoList.value, ...subscriptionList.value];
        this.subscriptionDisplayList = this.subscriptionInfoList.value;
        this.pageLoading = false;
      }

      if (this.subscriptionInfoList?.value?.length === this.subscriptionInfoList.odataCount) {
        this.loadingComplete = true;
      }
    }
  }
}
