import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { environment } from 'src/environments/environment';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { Router } from '@angular/router';
import { FormService } from 'src/app/shared/component/form/form.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import {
  InterfaceType,
  TaxConditionDService as TaxConditionDAPIService,
  TaxConditionDResponse,
  ErrorCode,
} from 'src/swagger/base/openapi';
import { DropdownOption } from 'src/app/shared/component/input/model/DropdownOption.model';
import { BackendTaxConditionD, CurrencyService, ODataRawQueryOptions, StoreRegisterstoretwoBody } from 'src/swagger/cxmbackend/openapi';
import { TimeZone } from 'src/app/shared/enums/timeZone';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { TaxService } from '../../tax.service';
import { TaxType } from 'src/swagger/base/openapi/model/taxType';
import { LookupTaxComponent } from 'src/app/cxm-backend-web/lookup/lookup-tax/lookup-tax.component';
import { LookupTaxGroupComponent } from 'src/app/cxm-backend-web/lookup/lookup-taxgroup/lookup-taxgroup.component';
import { FilterFlag } from 'src/swagger/base/openapi/model/filterFlag';
import { Mode } from 'src/app/shared/enums/mode';
import { TaxMethod } from 'src/swagger/base/openapi/model/taxMethod';
import { CalcMethod } from 'src/swagger/cxmbackend/openapi/model/calcMethod';

@Component({
  selector: 'app-taxconditiond-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, TranslateModule],
  templateUrl: './taxconditiond-entry.component.html',
  styleUrls: ['./taxconditiond-entry.component.scss'],
})
export class TaxConditionDEntryComponent {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  taxCondCode: string = '';
  taxCondLine: string = '';
  editMode: boolean = false;

  formGroup: FormGroup;
  taxConditionDData: any;
  masterData: any;

  pageSub$: Subscription;

  interfaceTypeOption: any = Object.keys(InterfaceType).map((key) => { return { name: key, value: InterfaceType[key] } });


  formSetting: FormSetting = {
    formCode: FormCode.taxconditiondentry,
    content: [
      {
        inputText: {
          name: 'taxCondCode',
          label: 'Tax Condition Code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 5,
          disabled: true,
        },
      },
      {
        inputText: {
          name: 'taxCondLine',
          label: 'Tax Condition Line Number',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 50,
          disabled: true,
        },
      },
      {
        inputText: {
          name: 'taxLineDesc',
          label: 'Tax Line Description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 50,
        },
      },
      {
        inputText: {
          name: 'effectiveDate',
          label: 'Effective Date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'obsoleteDate',
          label: 'Obsolete Date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          componentName: LookupTaxComponent,
          name: 'taxCode',
          label: 'Tax Code',
          required: true,
          valueKey: 'taxCode',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'inclFlag',
          label: 'Inclusive?',
          required: true,
          styling: {
            width: '30%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'taxMethod',
          label: 'Tax Method',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(TaxMethod).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'taxRate',
          label: 'Tax Rate',
          styling: {
            width: '60%',
          },
          required: true,
        },
      },
      {
        inputText: {
          name: 'taxUDF',
          label: 'Tax User Defined Formula',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 15,
        },
      },
      {
        lookDropInput: {
          componentName: LookupTaxGroupComponent,
          name: 'addTaxGroupCode',
          label: 'Additional Tax Group Code',
          valueKey: 'taxGroupCode',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'adjFlag',
          label: 'Adjust with differences between tax calculation at item level and total level',
          required: true,
          styling: {
            width: '30%',
          },
        },
      },
      {
        toggleInput: {
          name: 'taxClaimFlag',
          label: 'Tax Claimable?',
          required: true,
          styling: {
            width: '30%',
          },
        },
      },
      {
        inputNumber: {
          name: 'minSalesAmt',
          label: 'Minimum Sales Amount',
          styling: {
            width: '60%',
          },
          required: true,
        },
      },
      {
        inputNumber: {
          name: 'maxSalesAmt',
          label: 'Maximum Sales Amount',
          styling: {
            width: '60%',
          },
          required: true,
        },
      },
      {
        inputNumber: {
          name: 'minDistanceKM',
          label: 'Minimum Distance (km)',
          styling: {
            width: '60%',
          },
          required: true,
        },
      },
      {
        inputNumber: {
          name: 'maxDistanceKM',
          label: 'Maximum Distance (km)',
          styling: {
            width: '60%',
          },
          required: true,
        },
      },
      {
        toggleInput: {
          name: 'inactiveFlag',
          label: 'Inactive Flag',
          required: true,
          styling: {
            width: '30%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'filterFlag',
          label: 'Filter Operator',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(FilterFlag).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'calcMethod',
          label: 'Calculation Method',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(CalcMethod).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
    ],
  };

  actionToolbarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private customService: CustomService,
    private TaxConditionDApiService: TaxConditionDAPIService,
    private authService: AuthService,
    private pageService: PageService,
    public dialog: MatDialog,
    private taxConditionHService: TaxService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private formService: FormService,
  ) {
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {
      if (this.page.queryParams?.mode) {
        this.editMode = this.page.queryParams.mode === Mode.edit ? true : false;
      }
      let params = this.pageService.getParameterDecode(this.page);
      this.taxCondCode = params['taxCondCode'];
      this.taxCondLine = params['taxCondLine'];
      if (this.taxCondCode && this.taxCondLine) {
        console.log(this.taxCondLine)
        let queryOpts: ODataRawQueryOptions = {
          filter: 'taxCondCode eq ' + '\'' + this.taxCondCode + '\''
          + ' and taxCondLine eq ' + '\'' + this.taxCondLine + '\''
        };
        await this.getTaxConditionD(queryOpts);
      }
      else {
        this.taxConditionDData =
        {
          taxCondCode: this.taxCondCode
        };
      }
    }
  }

  async getTaxConditionD(queryOpts: ODataRawQueryOptions) {

    let result = await this.taxConditionHService.getOdataTaxConditionD(null, queryOpts, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.taxConditionDData = result['value'][0];
    }
  }

  async addTaxConditionD(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.tax.taxConditionD.AddTaxConditionD,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: reqBody,
      httpHeaderType: undefined
    }

    this.loaderService.startLoading();
    let result = await this.reqCustomHttpCall(cusReq);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.taxconditiond.create.success.title',
        desc: 'msg.tax.taxconditiond.create.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxgrouplisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(result);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.taxconditiond.create.fail.title',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  async updateTaxConditionD(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.tax.taxConditionD.UpdateTaxConditionD,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: reqBody,
      httpHeaderType: undefined
    }

    this.loaderService.startLoading();
    let result = await this.reqCustomHttpCall(cusReq);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.taxconditiond.update.success.title',
        desc: 'msg.tax.taxconditiond.update.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxgrouplisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(result);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.taxconditiond.update.fail.title',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  onGenerateInput(event: any) {
    // call generate api
    let generatedCode = 'code';
    event.formGrp.controls[event.setting.name].setValue(generatedCode);
  }
  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody = this.formService.getReqBody(formGroup);

    if (this.taxCondLine) {
      this.updateTaxConditionD(reqBody);
    } else {
      this.addTaxConditionD(reqBody);
    }
  }
  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
