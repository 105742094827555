import { Injectable } from '@angular/core';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { environment } from 'src/environments/environment';
import { JobService as JobServiceApi } from 'src/swagger/base-rms/openapi';
import { SubscriptionUserService } from 'src/app/core/services/subscriptionUser.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(
    private customService: CustomService,
    private jobServiceApi: JobServiceApi,
    private subscriptionUserService: SubscriptionUserService
  ) { }

  async getJobsbyqueue(queueName: string = 'rmsCoreRpt', rptFormCode?: string) {
    let selectedSub = this.subscriptionUserService.selectedSub;
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.job.getjobsbyqueue,
      hostPath: selectedSub.apiUrl4,
      queryParams: {
        queueName: queueName,
        rptFormCode: rptFormCode
      },
    }
    let hMac = this.customService.assignHMac(cusReq);
    return this.jobServiceApi.apiJobGetjobsbyqueueGet(
      queueName,
      rptFormCode,
      this.customService.curHttpHeader.apiVersion,
      this.subscriptionUserService.selectedSub.subscriptionId,
      hMac,
      undefined,
      this.customService.curHttpHeader.cultureCode,
      this.customService.curHttpHeader.clientDate,
      this.customService.curHttpHeader.clientTimeZone,
      undefined,
    ).toPromise();
  }
}
