/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ChannelStoreTaxResponse { 
    channelId?: number;
    channelDesc?: string;
    storeId?: number;
    merchantCode?: string;
    locDesc?: string;
    taxLine?: number;
    effectiveDate?: Date;
    obsoleteDate?: Date;
    taxCondCode?: string;
    taxCondDesc?: string;
    dineinFlag?: boolean;
    pickupFlag?: boolean;
    deliveryFlag?: boolean;
    takeawayFlag?: boolean;
    orderTypeList?: string;
    transportModeList?: string;
    rowVersion?: string;
}