
import { ComponentFactoryResolver, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParamMap, Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PopupMessageComponent } from '../component/popup-message/popup-message.component';
import { FormCode } from '../enums/formCode';
import { PageRoute } from '../enums/pageRoute';
import { Page } from '../models/page.model';
import { PopupMessage } from '../models/popupMessage.model';
import { form } from 'src/app/core/models/form'
import { ContentComponent } from '../component/content/content.component';
import { UtilsService, convertStringToObject, decodeBase64, encodeBase64 } from 'src/app/core/services/utils.service';
import { combinePathComponents } from '../page-component';
import * as _ from 'lodash';
import { ButtonRendererTypeEnum } from '../enums/buttonRendererType';
import { PageComponent } from '../models/pageComponent.model';
import { reportPathComponent } from '../page-component/base/report';
import { FormTypeCode } from '../enums/formTypeCode';
import { FormService } from '../component/form/form.service';
import { ReportViewerService } from '../component/services/report-viewer.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupAction } from '../enums/popupAction';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  @ViewChild('agGridTable', { static: false }) agGridTable: any;
  isLogin: boolean = false;
  pages: Page[] = [];
  pendingEditPages: Array<string> = [];

  pageSubject$: Subject<Page[]> = new Subject();
  editing$: Subject<{ page: FormCode, isEditing: boolean }> = new Subject();
  refreshListing$: Subject<FormCode[] | string[]> = new Subject();
  actionPopupMsg$: Subject<any> = new Subject();
  actionBtnListing$: Subject<{ action: ButtonRendererTypeEnum, param: any, cellEl?: any }> = new Subject();
  quitEditMode$: Subject<any> = new Subject();
  routeParam$: Subject<Page> = new Subject();
  routeQueryParam$: Subject<Page> = new Subject();

  exitGridEdit$: Subscription;

  currentPage = null;
  currentRoute: string;
  currentPageNo: number;
  previousRoute: string;
  formCodeNo: number = 0;

  sideNavPinned: boolean = true;
  sideNavPinned$: Subject<boolean> = new Subject();
  private masterContainer: ViewContainerRef = null;

  filePathComponentList: PageComponent[] = [...combinePathComponents];
  isEditMode: boolean;
  pendingPageNavigate: ActivatedRoute = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private formService: FormService,
    private CFR: ComponentFactoryResolver,
    private utilsService: UtilsService,
    private reportViewerService: ReportViewerService,
    private translateService: TranslateService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.rootRoute(route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary')
    ).subscribe((route: ActivatedRoute) => {
      if (this.masterContainer) {
        this.currentRoute = route.snapshot['_routerState'].url;
        this.openNewPage(this.router.url, !this.router.getCurrentNavigation()?.extras?.state?.childPage, route.snapshot.paramMap, route.snapshot.queryParams);
      } else {
        this.pendingPageNavigate = route;
      }
    });

    this.sideNavPinned$.subscribe(sideNavPinned => {
      this.sideNavPinned = sideNavPinned;
    });
  }

  public rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  public async openNewPage(route: string, replace?: boolean, paramMap?: ParamMap, queryParams?: Params) {
    let routeLink: string = route;

    // report viewer handling
    if(route.includes(PageRoute.REPORT_VIEWER) && this.isLogin) {
      let param = this.reportViewerService.formatEncodedParam(queryParams?.parameters);
      route = param?.FormCode? PageRoute.REPORT + '/' + param.FormCode: route;
    }

    route = await this.processRoute(route);

    this.checkPendingEdit(true).then(() => {
      let duplicatePageIndex = -1;
      let selectedForm = this.getFormByRoute(route, paramMap);

      if (!selectedForm) {
        return null;
      }

      if (route === PageRoute.DASHBOARD || route.includes('framework') || replace) {
        this.pages = [];
        this.removeAllComponent(this.masterContainer);
      } else {
        duplicatePageIndex = this.pages.findIndex(data => data.form.filePath === selectedForm.filePath);
      }

      this.router.navigateByUrl(routeLink, { state: { formData: selectedForm } });

      if (duplicatePageIndex >= 0) {
        //update page
        this.pages[duplicatePageIndex] = {
          form: selectedForm,
          pageName: selectedForm?.formCaption,
          closeHeader: route === PageRoute.DASHBOARD || route === PageRoute.FRAME_CUSTOM_FORM ? false : true,
        };

        this.updateParam(paramMap, duplicatePageIndex);
        this.updateQueryParam(queryParams, duplicatePageIndex);
        this.scrollDuplicateRight();

        // popup when dirty
        this.pageSubject$.next(this.pages);
      } else {
        if ((this.pages && this.pages.length == 1 && this.pages[0].form.filePath === PageRoute.DASHBOARD)) {
          this.pages = [];
          this.removeAllComponent(this.masterContainer);
        }

        // remove all the pages when duplicate listing order type
        if (selectedForm.formTypeCode == "L") {
          let foundPage = this.pages.find(page => page.form.formTypeCode === "L");
          if (foundPage) {
            this.pages = [];
            this.removeAllComponent(this.masterContainer);
          }
        }

        this.pages.push({
          form: selectedForm,
          pageName: selectedForm?.formCaption,
          closeHeader: route === PageRoute.DASHBOARD || route === PageRoute.FRAME_CUSTOM_FORM ? false : true,
          paramsAsMap: paramMap,
          queryParams: queryParams
        });

        // reset pages seq
        for (let i = 0; i < this.pages.length; i++) {
          this.pages[i].seq = i + 1;
        }

        if (this.masterContainer) {
          this.matchToCreateLastComponent(this.masterContainer);
        }
        this.pageSubject$.next(this.pages);
      }
    });
  }

  navigateByUrl(route: string, params?: any, queryParams?: any, childPage?: any) {
    if (params) {
      route = this.formatNewParam(route, params);
    }

    if(queryParams) {
      route = route + this.formatNewQueryParam(queryParams);
    }

    this.router.navigateByUrl(route, { state: { childPage: childPage }});
  }

  navigateByPath(route: string, params?: any, queryParams?: any, childPage?: boolean) {
    if (params) {
      route = this.formatNewParam(route, params);
    }

    this.router.navigate([route], { queryParams: queryParams, state: { childPage: childPage } });
  }

  // sample like: report/rptsalesdetails?jobId=2346
  navigateByQueryParams(route: string) {
    let splitRoute = route.split('?');
    let params = convertStringToObject(splitRoute[1]);
    this.router.navigate([splitRoute[0]], { queryParams: params });
  }

  formatNewParam(route: string, params?: any) {
    let encodedParams = this.utilsService.getEncodedParams(params);
    route = route + '/' + encodedParams.replace(/=/g, "");
    return route;
  }

  formatNewQueryParam(queryParams: {}) {
    let formatQueryParams: string = '';

    let keys: string[] = Object.keys(queryParams);
    keys.forEach((key: string, index: number) => {
      let symbol: string = index? '&': '?';
      formatQueryParams += symbol + key + '=' + queryParams[key];
    });

    return formatQueryParams;
  }

  closePage(page?: Page) {
    this.checkPendingEdit(true).then(() => {
      let selectedPageIndex = this.pages.findIndex(data => data.form.filePath === page.form.filePath);

      if (selectedPageIndex !== -1) {
        let totalPageIndex = this.pages.length - 1;
        this.pages = this.pages.slice(0, selectedPageIndex);
        for (let index = totalPageIndex; index >= selectedPageIndex; index--) {
          this.removeComponent(this.masterContainer, index);
        }
      }

      // if page empty list, auto navigate to dashboard
      if (this.pages.length === 0) {
        this.router.navigateByUrl(PageRoute.DASHBOARD);
      } else {
        let lastPage = this.pages[this.pages.length - 1];
        let params = this.getParameter(lastPage);
        let pageRouteUrl = params ? (lastPage.form.filePath + '/' + params) : lastPage.form.filePath;
        this.navigateByPath(pageRouteUrl, null, null, true);
      }

      this.pageSubject$.next(this.pages);
    });
  }

  closePageAfter(currentPageUrl: string) {
    let pageIndex = this.pages.findIndex((page: Page) => currentPageUrl === page.form.filePath);

    if (pageIndex !== -1) {
      this.pages.splice(pageIndex + 1, this.pages.length - (pageIndex + 1));
    }

    this.pageSubject$.next(this.pages);
  }

  getCurrentPage(): string {
    return this.currentRoute;
  }

  getPages() {
    return this.pages;
  }

  scrollMostRight(screenContainer: any) {
    if (screenContainer) {
      setTimeout(function () {
        screenContainer.scrollLeft = screenContainer.scrollWidth;
      }, 100);
    }
  }

  scrollDuplicateRight() {
    let screenContainer = document.getElementById("general-container");
    this.scrollMostRight(screenContainer);
  }

  setPendingEdit(route: string) {
    let index = this.pendingEditPages.findIndex((pendingRoute: PageRoute) => pendingRoute === route);
    if (index < 0) this.pendingEditPages.push(route);
  }

  unsetPendingEdit(route: string) {
    let index = this.pendingEditPages.findIndex((pendingRoute: PageRoute) => pendingRoute === route);
    if (index >= 0) this.pendingEditPages.splice(index, 1);
  }

  checkPendingEdit(showPopup: boolean) {
    return new Promise((resolve, reject) => {
      let isPendingEdit = false;

      if (this.pendingEditPages && this.pendingEditPages.length > 0) {
        isPendingEdit = this.pendingEditPages.includes(this.pages[this.currentPageNo]?.form.filePath);
      }

      if (isPendingEdit && showPopup) {
        let msg: PopupMessage = {
          titleIcon: 'assets/base/icons/exclamation-circle.svg',
          title: this.translateService.instant("msg.exit_grid_edit"),
          desc: this.translateService.instant('msg.leave_editing'),
          closeBtnText: 'general.no',
          actionBtnText: 'general.yes'
        };

        const dialogRef = this.dialog.open(PopupMessageComponent, {
          data: msg
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && result === PopupAction.actionBtn && this.pendingEditPages && this.pendingEditPages.length > 0) {
            this.pendingEditPages.splice(this.currentPageNo, 1);
            resolve(true);
          }
        });
      } else if (isPendingEdit && !showPopup) {
        this.pendingEditPages.splice(this.currentPageNo, 1);
        resolve(isPendingEdit);
      } else {
        resolve(isPendingEdit);
      }
    });
  }

  refresh(formCode: FormCode[] | string[]) {
    this.refreshListing$.next(formCode);
  }

  getFormByRoute(route: string, paramMap: ParamMap): form {
    let localRoute = route;
    let forms = this.formService.newForm;
    let paramMapLength: number = 0;
    if (paramMap && (typeof paramMap === 'object' && Object.keys(paramMap['params']).length > 0)) {
      paramMapLength = Object.keys(paramMap['params']).length;
    }

    if (paramMapLength > 0 || typeof paramMap === 'string') {
      let splitRoute = localRoute.split('/');
      if (splitRoute[0] !== 'report') {
        for (let index = 0; index < paramMapLength; index++) {
          splitRoute.pop();
        }
      }
      localRoute = splitRoute.join('/');
    }
    localRoute = localRoute.split('?')[0];
    let matchedForm = forms.find(menu => menu.filePath == localRoute);
    return matchedForm;
  }

  getFormByFormCode(formCode: string): form {
    let forms = this.formService.newForm;
    return forms.find(form => form.formCode === formCode);
  }

  async processRoute(route: string): Promise<string> {
    let formatedRoute = route;
    if (formatedRoute.charAt(0) == '/') {
      formatedRoute = formatedRoute.substring(1);
    }

    if (formatedRoute.charAt(formatedRoute.length - 1) == '/') {
      formatedRoute = formatedRoute.slice(0, -1);
    }
    return formatedRoute;
  }

  removeParamRoute(route: string) {
    let formatedUrl = '';
    let routeSplit = route.split('/');
    if (routeSplit && routeSplit.length > 0) {
      routeSplit.pop();
      formatedUrl = routeSplit.join('/');
    }

    return formatedUrl;
  }

  async matchToCreateLastComponent(mainContainer: ViewContainerRef) {
    if (this.pages && this.pages.length > 0) {
      let lastPage = this.pages[this.pages.length - 1];
      let foundComponent = null;

      // if form type code is report, resue the report component
      if (lastPage.form.formTypeCode === FormTypeCode.REPORT) {
        foundComponent = reportPathComponent[0];
      } else {
        foundComponent = this.filePathComponentList.find(data => {
          return data.filePath === lastPage.form.filePath;
        });
      }

      if (foundComponent) {
        this.createComponent(mainContainer, foundComponent.component);
      }
    }
  }

  createComponent(container: ViewContainerRef, component: any) {
    let componentFactory = this.CFR.resolveComponentFactory(ContentComponent);

    let childComponentRef = container.createComponent(componentFactory);
    childComponentRef.instance.component = component;
  }

  removeComponent(container: ViewContainerRef, index: number) {
    container.remove(index);
  }

  removeAllComponent(container: ViewContainerRef) {
    if (this.masterContainer) {
      container.clear();
    }
  }

  getComponent(filePath: string) {
    return this.filePathComponentList.find(data => data.filePath === filePath);
  }

  getMasterChildComponent(formCode: string) {
    return this.filePathComponentList.find(data => data.formCode === formCode);
  }

  getComponentByName(name: string){
    return this.filePathComponentList.find(data => data.component.name === name);
  }

  getListingId(page: Page) {
    return page.listingId ? encodeBase64(page.listingId) : (page.paramsAsMap ? page.paramsAsMap.get('listingId') : undefined);
  }

  getParamId(page: Page, id: string): any {
    return page.paramsAsMap.get(id);
  }

  getParameter(page: Page, id?: string) {
    id = id ? id : 'params';
    return page.paramsAsMap.get(id) ? page.paramsAsMap.get(id) : '';
  }

  getParameterDecode(page: Page, id?: string): any {
    let paramObj = '';
    id = id ? id : 'params';
    if (page.paramsAsMap.get(id)) {
      // get the decode param form url
      let decodedParams = decodeBase64(decodeURIComponent(page.paramsAsMap.get(id)))
      // convert the param value to decoded object value
      paramObj = this.utilsService.getDecodedParamObj(decodedParams);
    }
    console.log(page)
    return paramObj;
  }

  async updatePages(childForm: form, seq: number) {
    let childFilePath = await this.processRoute(childForm.filePath);
    let form = this.formService.newForm.find(form => form.filePath === childFilePath);
    let index = seq - 1;
    let oriUpdatePage = _.clone(this.pages[index]);
    this.pages[index] = {
      pageName: form?.formCaption,
      closeHeader: oriUpdatePage.closeHeader,
      form: form,
      paramsAsMap: oriUpdatePage.paramsAsMap,
      listingId: oriUpdatePage.listingId,
      seq: oriUpdatePage.seq
    }
    this.pageSubject$.next(this.pages);    

    return this.pages[index];
  }

  updateParam(newParam: any, seq?: number) {
    let index: number = seq || seq === 0? seq: this.pages.length - 1;
    if(newParam) {
      this.pages[index].paramsAsMap = newParam;
      this.routeParam$.next(this.pages[index]);
    }
  }

  updateQueryParam(newQueryParam: any, seq?: number) {
    let index: number = seq || seq === 0? seq: this.pages.length - 1;
    if(newQueryParam) {
      this.pages[index].queryParams = newQueryParam;
      this.routeQueryParam$.next(this.pages[index]);
    }
  }

  getNewFormCode() {
    return this.formCodeNo + 1;
  }

  async setMasterContainer(masterContainer: any) {
    this.masterContainer = masterContainer;

    // handle the case when navigate route come first, but masterContainer is empty issue
    if (this.pendingPageNavigate) {
      let route = this.pendingPageNavigate;
      this.currentRoute = route.snapshot['_routerState'].url;
      this.openNewPage(this.router.url, false, route.snapshot.paramMap, route.snapshot.queryParams);
      this.pendingPageNavigate = null;
    }
  }

  isReportViewerPage() {
    return window.location.href.includes(PageRoute.REPORT_VIEWER)? true: false;
  }
}
