import { MerchantListingComponent } from "src/app/cxm-backend-web/merchant/merchant-listing/merchant-listing.component";
import { PageRoute } from "../enums/pageRoute";
import { FormCode } from "../enums/formCode";
import { MasterFormComponent } from "../component/master-form/master-form.component";
import { MerchantEntryComponent } from "src/app/cxm-backend-web/merchant/merchant-entry/merchant-entry.component";
import { PageComponent } from "../models/pageComponent.model";

export const merchantPathComponent: PageComponent[] = [{
  filePath: PageRoute.MERCHANT_LISTING,
  component: MerchantListingComponent
},
{
  filePath: PageRoute.MERCHANT_ENTRY,
  formCode: FormCode.merchantentry,
  component: MerchantEntryComponent
},
{
  filePath: PageRoute.MERCHANT_MASTER_ENTRY,
  component: MasterFormComponent
},];
