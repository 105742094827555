/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrderDetailResponse { 
    activityId?: number;
    orderNo?: string;
    locShortDesc?: string;
    tranDate?: Date;
    sourceFlag?: string;
    statusFlag?: string;
    lastActivityType?: string;
    cutOffFlag?: boolean;
    checkOrderUrl?: string;
    netAmt?: number;
    orderDesc?: string;
    mobileNo?: string;
    refNo1?: string;
    refNo2?: string;
    payRefNo?: string;
    paymentStatus?: string;
    errorMsg?: string;
    tenderAmt?: number;
    orderTranId?: number;
    salesTranId?: number;
    payTranid?: number;
    linkId?: number;
    tableNo?: string;
    activeFlag?: boolean;
    completeFlag?: boolean;
    cancelFlag?: boolean;
}