<!-- carousel -->
<div class="carousel" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)">
  <ng-container *ngFor="let slide of slides; let i = index">
    <img [hidden]="i !== currentSlide" [src]="slide.src" class="slide" [@slideAnimation]="animationType" />
  </ng-container>

  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>

  <div class="carousel_index">
    <button class="carousel_index_item" [ngClass]="{'carousel_index_item--active': i === currentSlide }"
      *ngFor="let slide of slides; let i = index" (click)="setIndex(i)">
    </button>
  </div>
</div>
