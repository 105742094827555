import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) { }

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + this.oauthService.getAccessToken()
      }
    });
  }

  private initImplicitFlow(): void {
    const encodedUri = encodeURIComponent(this.router.url);
    this.oauthService.initImplicitFlow(encodedUri);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: HttpResponse<any>) => {
        if (response && response.status === 401) {
          console.log('token-interceptor: ', response)
          return from(this.oauthService.refreshToken()).pipe(
            switchMap(() => next.handle(this.getAuthRequest(req))),
            catchError(error => {
              // show the popup, navigate user to subscription page
              this.initImplicitFlow();
              return throwError(error);
            }),
          );
        }
        return throwError(response);
      })
    );
  }
}
