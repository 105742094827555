import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { ColDef, IRowNode } from 'ag-grid-community';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FormCode } from 'src/app/shared/enums/formCode';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { LocGroupService } from '../../services/loc-group.service';
import { ChangeDetectorRef } from '@angular/core';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'app-lookup-outletGrp',
  standalone: true,
  imports: [CommonModule, LookDropInputComponent],
  templateUrl: './lookup-outletGrp.component.html',
})
export class LookupOutletGrpComponent implements OnInit {
  @Input() formData: any;
  @Input() defaultLookup: any;
  userData: any;
  lookDropInputSetting: LookDropInputSetting; //pass from lookup master component when create component
  formGroup: UntypedFormGroup;
  rowData: any = []
  multiSelectData: { node?: IRowNode, value?: any, displayValue?: any }[];

  colDef: ColDef[] = [
    {
      field: 'locGrpCode',
      headerName: 'general.locgrpcode',
      sortable: true,
    },
    {
      field: 'locGrpDesc',
      headerName: 'general.description',
      sortable: true,
    },
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1 }
    ]
  };

  constructor(private LocGroupService: LocGroupService,
    private lookupMasterService: LookupMasterService, private cd: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.formData?.currentValue?.isMultiple) {
        this.lookDropInputSetting = {
          ...this.lookDropInputSetting,
          multiSelect: changes.formData.currentValue.isMultiple ? changes.formData.currentValue.isMultiple : false
        }
      }
    }

  async ngOnInit(){
    // Custom settings
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'locGrpCode',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.multiSelect ? 'locGrpDesc' : 'locGrpCode',
      displayValueKey: this.lookDropInputSetting.multiSelect ? 'locGrpDesc' : 'locGrpCode',
      colDef: this.lookDropInputSetting.colDef ? this.lookDropInputSetting.colDef : this.colDef,
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey : 'locGrpDesc',
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : 'general.please_select_one',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling ? this.lookDropInputSetting.styling : {},
      headerName: this.lookDropInputSetting.headerName ? this.lookDropInputSetting.headerName : '',
      dependency: this.lookDropInputSetting.dependency ? this.lookDropInputSetting.dependency : null,
      disabled: this.lookDropInputSetting.disabled ? this.lookDropInputSetting.disabled : false,
      defaultLookdrop: this.lookDropInputSetting.defaultLookdrop ? this.lookDropInputSetting.defaultLookdrop : true
    }

    if (this.defaultLookup && !this.lookDropInputSetting.disabled) {
      let resultValues: any = await this.getResult();

      resultValues.forEach(item => {
        if (item.locGrpCode === this.defaultLookup) {
          this.passData.emit({ value: this.defaultLookup, description: item.locGrpDesc });
        }
      });
    }
  }

  async onLookup(data: any) {
    // call api and set row
    this.rowData = await this.getResult();
  }

  async getResult(filter?: string, top?: number) {
    let orderBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field); // array of property name, eg. ['currCode', 'currDesc']
    let order = this.lookupMasterService.setOrderBy(orderBy.map((order: string) => {return {key: order, order: 'asc'}}));
    let result: any = await this.LocGroupService.getLocGrpList(FormCode.rptsalesdetails, filter, top, order);
    if (!(result instanceof HttpErrorResponse)) {

      return result['value'];
    }
    return null;
  }

  async onAutoComplete(autoCompleteVal: any) {
    // set filter
    let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field); // array of property name, eg. ['currCode', 'currDesc']
    let filter = this.lookupMasterService.setFilter(autoCompleteVal, filterBy);
    let top = 10;

    // call api and open autocomplete
    this.rowData = await this.getResult(filter, top);
  }

  @Output() passData: EventEmitter<{ data?: any, value: any, description: any }> = new EventEmitter<{ data?: any, value: any, description: any }>();
  onSelected(selectedNodes: IRowNode[]) {
    const rowNodes = selectedNodes.filter((node) => node instanceof RowNode);
    if(this.lookDropInputSetting?.multiSelect) {
      this.multiSelectData = rowNodes.map((node: IRowNode) => {return {node: node, value: node.data['locGrpDesc']}});
      this.passData.emit({ data: this.multiSelectData.map(item => item.node.data), value: this.multiSelectData.map(item => item.value), description: this.multiSelectData.map(item => item.node.data['locGrpDesc']) });
    } else {
      this.passData.emit({ data: selectedNodes[0].data, value: selectedNodes[0].data['locGrpCode'], description: selectedNodes[0].data['locGrpDesc'] });
    }
  }

  async onLookupToolbarAction(data: any) {
    if (data.name === 'filter') {
      let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(data.data, filterBy);
      this.rowData = await this.getResult(filter);
    }
  }
}
