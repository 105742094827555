import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ButtonToggleSetting } from '../model/ButtonToggleSetting.model';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-button-toggle',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    TranslateModule,
    MatFormFieldModule
  ],
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() buttonToggleSetting: ButtonToggleSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() items: any[];
  @Input() formData: any;
  @Output() selectionChange = new EventEmitter();

  buttonToggleChg$: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.items = this.items? this.items: this.buttonToggleSetting.option? this.buttonToggleSetting.option: [];

    if(this.formGroup?.get(this.buttonToggleSetting.name)) {
      this.buttonToggleChg$ = this.formGroup.get(this.buttonToggleSetting.name).valueChanges.subscribe((value: any) => {
        this.selectionChange.emit(value);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue && this.formGroup?.get(this.buttonToggleSetting.name)) {
      this.formGroup.controls[this.buttonToggleSetting.name].setValue(changes.formData.currentValue[this.buttonToggleSetting.name]);
    }
  }

  onSelectionChange(value: any) {
    if(!this.formGroup?.get(this.buttonToggleSetting.name)) {
      this.selectionChange.emit(value);
    }
  }

  ngOnDestroy(): void {
    this.buttonToggleChg$?.unsubscribe();
  }
}
