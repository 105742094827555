import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorPageSetting } from '../input/model/ErrorPageSetting.model';
import { ErrorPageService } from '../../services/error-page.service';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  @Input() errPgDt: ErrorPageSetting

  constructor(private errorPageService: ErrorPageService,
    private pageService: PageService
  ) {}

  onBack() {
    this.errorPageService.hide();

    if(this.errPgDt?.redirectLink) {
      this.pageService.navigateByUrl(this.errPgDt.redirectLink);
    }
  }
}
