/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EdmTypeKind = 'None' | 'Primitive' | 'Entity' | 'Complex' | 'Collection' | 'EntityReference' | 'Enum' | 'TypeDefinition' | 'Untyped' | 'Path';

export const EdmTypeKind = {
    None: 'None' as EdmTypeKind,
    Primitive: 'Primitive' as EdmTypeKind,
    Entity: 'Entity' as EdmTypeKind,
    Complex: 'Complex' as EdmTypeKind,
    Collection: 'Collection' as EdmTypeKind,
    EntityReference: 'EntityReference' as EdmTypeKind,
    Enum: 'Enum' as EdmTypeKind,
    TypeDefinition: 'TypeDefinition' as EdmTypeKind,
    Untyped: 'Untyped' as EdmTypeKind,
    Path: 'Path' as EdmTypeKind
};