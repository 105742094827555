<app-loader></app-loader>
<app-header [hidden]="hideHeader" [ngClass]="{'drawerClosed': menuOpen}" [currentContent]="currentContent"
  [userProfile]="userProfile" (toggleDropdownEvent)="toggleDropdown($event)" (toggleSideNavEvent)="toggleSideNav()"
  #header [sideNavPinned]="sideNavPinned" [drawer]="drawer" (closeMenu)="closeMenu()" (closeSide)="closeSideNav()"
  [isStandalonePage]="isReportViewerPage && !isLogin" [(menuOpen)]="menuOpen"
  (menuOpenChange)="handleMenuOpenChange($event)">
</app-header>

<mat-sidenav-container class="sidenav-container" #sideNav [hidden]="!selectedSubscription && !isReportViewerPage">

  <mat-sidenav #drawer disableClose class="sidenav-left" fixedInViewport *ngIf="sideNavPinned"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="'side'" [opened]="sideNavPinned"
    (openedChange)="navbarOpened = $event; autoPageScroll()" [hidden]="!isLogin || hideHeader">

    <!-- <div class="search">
      <input class="searchbar" [(ngModel)]="searchText" (keyup)="onSearch()" [ngClass]="{'searchbar-active': isActive}"
        type="text" [placeholder]="'Search Document' | translate" #autoFocusInput (click)="showPopup()">
      <img src="assets/base/icons/search.svg" class="search-icon" [ngClass]="{'search-icon-active': isActive}"
        (click)="showPopup()">
      <img src="assets/base/icons/remove.svg" class="search-icon-close" [ngClass]="{'search-icon-active': !isActive}"
        (click)="resetSearch(); toggleContent()">
    </div> -->

    <mat-list style="padding: 16px;">
      <div class="menu-panel">
        <mat-list-item class="menu-list" (click)="openDefaultMenu('favourite', menuSets); $event.stopPropagation()">
          <div class="side-nav-container">
            <div class="icon-text-container">
              <img class="side-nav-icon" src="assets/base/icons/star-solid.svg" />
              <button class="menu-parent">
                {{'general.favorites' | translate}}
              </button>
            </div>
          </div>
        </mat-list-item>
      </div>
      <div class="menu-panel">
        <mat-list-item class="menu-list" (click)="openDefaultMenu('recent'); $event.stopPropagation()">
          <div class="side-nav-container">
            <div class="icon-text-container">
              <img class="side-nav-icon"src="assets/base/icons/recent.svg" />
              <button class="menu-parent">
                {{'general.recent' | translate}}
              </button>
            </div>
          </div>
        </mat-list-item>
      </div>

      <div style="width: 100%; border-bottom: 1px solid #BEBEC7;">
      </div>

      <div class="panel">
        <div class="menu-panel">
          <mat-list-item class="menu-list" (click)="openDefaultMenu('home', menuSets); $event.stopPropagation()">
            <div class="side-nav-container">
              <div class="icon-text-container">
                <img class="side-nav-icon" src="assets/base/icons/home-solid.svg" />
                <button class="menu-parent menu-home">
                  {{'general.home' | translate}}
                </button>
              </div>
            </div>
          </mat-list-item>
        </div>

        <div class="menu-panel" *ngFor="let menuSet of menuSets; let i = index">
          <mat-list-item class="menu-list" (click)="selectMenu(menuSet); $event.stopPropagation()">
            <div class="side-nav-container">
              <div class="icon-text-container">
                <img class="side-nav-icon" src="assets/base/icons/{{menuSet.icon}}.svg" />
                <button class="menu-parent">
                  {{ 'general.' + menuSet.caption.toLowerCase() | translate}}
                </button>
              </div>
            </div>
          </mat-list-item>
        </div>
      </div>
    </mat-list>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-header" [ngClass]="{ 'manualSideNavMargin': sideNavPinned && isLogin }">
    <div class="screen-container" id="general-container" #screenContainer (scrollend)="autoPageScroll()"
      [ngClass]="{'blur-content': navbarOpened && !isReportViewerPage, 'screen-less-height': hideHeader}">
      <!-- main viwer -->
      <ng-container #mainContainer></ng-container>
      <app-popup-form [fullScreen]="true"></app-popup-form>

      <!-- standalone report viewer -->
      <ng-container *ngIf="isReportViewerPage && !isLogin">
        <app-report style="width: 100%;"></app-report>
      </ng-container>

      <ng-container *ngIf="errPgDt?.show">
        <app-error-page [errPgDt]="errPgDt.errorPage" style="width: 100%"></app-error-page>
      </ng-container>
    </div>

  </mat-sidenav-content>

</mat-sidenav-container>

<mat-sidenav class="dropdown-menu mat-menu-user" #rightSidenav mode="over" position="end"
  (opened)="sidebarOpened = true" (closed)="handleSidebarClosed()" [opened]="sidebarOpened"
  (openedChange)="navbarOpened = $event">
  <ng-container *ngIf="currentContent === 'user'">
    <app-user-info [userProfile]="userProfile" [languages]="languages" [regional]="regional"
      (closeSidebar)="closeSidebar()" (onUpdateProfile)="updateProfile($event)"></app-user-info>
  </ng-container>
  <ng-container *ngIf="currentContent === 'notification'">
    <app-notification (closeSidebar)="closeSidebar()"></app-notification>
  </ng-container>
  <button class="close-button" (click)="closeRightSidenav()">
    <img src="assets/base/icons/remove.svg">
  </button>
</mat-sidenav>

<div #dropdownMenu *ngIf="isChildrenMenuOpen" class="dropdown-third-menu custom-dropdown">
  <app-nav-menu></app-nav-menu>
</div>

<!-- <div #searchpage *ngIf="showPopupContent" class="search-content">
  <div class="header-bar">
    <button (click)="resetSearch(); closePopup()" class="product-close-button"><img class="close-product"
        src="assets/base/icons/arrow-left.svg"></button>
    {{ "Search" | translate }}
  </div>
  <app-search (togglePopupContent)="handleTogglePopupContent($event)"
    (toggleActiveStatus)="handleToggleActiveStatus($event)">
  </app-search>
</div> -->

<div class="menu-navigation" *ngIf="isOpenMenu" [ngClass]="{'menuFullScreen': fullScreen}">
  <div class="menu-header-bar">
    <button (click)="closeMenu()" class="menuCloseBut"><img class="close-product"
        src="assets/base/icons/arrow-left.svg"></button>
    <div class="back-menu">
      {{"back to"}} <span class="menu-text">{{"Menu"}}</span>
    </div>
  </div>
  <app-menu-navigation [menuSet]="selectedMenuSet" [openFavourite]="openFavourite" [openRecent]="openRecent"
    (isOpen)="openMenu($event)" (isRecent)="passRecentMenu($event)" (closeSide)="closeSideNav()"
    (closeMenu)="closeMenu()"></app-menu-navigation>
</div>

<!-- subscription full page -->
<ng-container *ngIf="!selectedSubscription && router.url.includes('/subscription')">
  <router-outlet></router-outlet>
</ng-container>

<app-toast></app-toast>

<p-dialog *ngIf="showAgreed" [(visible)]="showAgreed" [styleClass]="'maximize-dialog'"
  [contentStyle]="{padding: '16px'}" [showHeader]="false" [closeOnEscape]="false">
  <app-terms-agreed [userDisplayName]="userProfile?.firstName + ' ' + userProfile?.lastName"
    (closeDialogEvent)="showAgreed = false"></app-terms-agreed>
</p-dialog>
