/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PaymentStatus = 'Unpaid' | 'PaymentSuccesful' | 'PaymentFailure' | 'PreAuthorizedPending' | 'PreAuthorizedFailed' | 'PreAuthorizedCancelled' | 'PaymentCancelled' | 'ProcesingRefund' | 'PendingRefund' | 'Refunded' | 'RefundFailed' | 'UnknownError';

export const PaymentStatus = {
    Unpaid: 'Unpaid' as PaymentStatus,
    PaymentSuccesful: 'PaymentSuccesful' as PaymentStatus,
    PaymentFailure: 'PaymentFailure' as PaymentStatus,
    PreAuthorizedPending: 'PreAuthorizedPending' as PaymentStatus,
    PreAuthorizedFailed: 'PreAuthorizedFailed' as PaymentStatus,
    PreAuthorizedCancelled: 'PreAuthorizedCancelled' as PaymentStatus,
    PaymentCancelled: 'PaymentCancelled' as PaymentStatus,
    ProcesingRefund: 'ProcesingRefund' as PaymentStatus,
    PendingRefund: 'PendingRefund' as PaymentStatus,
    Refunded: 'Refunded' as PaymentStatus,
    RefundFailed: 'RefundFailed' as PaymentStatus,
    UnknownError: 'UnknownError' as PaymentStatus
};