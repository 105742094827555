/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackendChannelAddress { 
    addressId?: number;
    channelId?: number;
    searchLabel: string;
    addUnit: string;
    addCity: string;
    addPostal: string;
    addState: string;
    addCountry: string;
    latitude?: number;
    longitude?: number;
    rowVersion?: string;
}