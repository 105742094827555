<div class="property-grid-renderer">
    <!-- input text -->
    <ng-template [ngIf]="inputTextSetting">
        <app-input-text [inputTextSetting]="inputTextSetting" [formGroup]="formGroup"></app-input-text>
    </ng-template>
    <!-- input number -->
    <ng-template [ngIf]="inputNumberSetting">
        <app-input-number [inputNumberSetting]="inputNumberSetting" [formGroup]="formGroup"></app-input-number>
    </ng-template>    
    <!-- lookup and dropdown -->
    <ng-template [ngIf]="lookDropInputSetting">
        <app-lookdrop [lookDropInputSetting]="lookDropInputSetting" [formGroup]="formGroup" [formData]="formData"></app-lookdrop>
    </ng-template>
    <!-- toggle input -->
    <ng-template [ngIf]="toggleInputSetting">
        <app-toggle-input [toggleInputSetting]="toggleInputSetting" [formGroup]="formGroup"></app-toggle-input>
    </ng-template>
    <!-- text area -->
    <ng-template [ngIf]="textAreaSetting">
        <app-text-area [textAreaSetting]="textAreaSetting" [formGroup]="formGroup" [formData]="formData"></app-text-area>
    </ng-template>
     <!-- date -->
     <ng-template [ngIf]="dateInputSetting">
        <app-date-picker [inputDateSetting]="dateInputSetting" [formGroup]="formGroup"></app-date-picker>
    </ng-template>
</div>