<div class="notification-wrapper">
  <div class="notification-container">
    <div class="notification-head">
      <button class="close-user" (click)="onCloseButtonClick();">
        <img class=" close-user-button" src="assets/base/icons/arrow-left.svg"></button>
      <p class="notification-title">{{notificationData.title}} <span *ngIf="unreadCount > 0"
          class="unread-count">{{unreadCount}} {{ "unread" | translate }}</span>
      </p>
      <button class="notification-button">
        <img class="notification-ellipsis" src="assets/base/icons/ellipsis-v.svg">
      </button>
    </div>
    <div *ngFor="let date of sortedDates">
      <div class="read-note">
        <h2 class="notification-date">{{ date }}</h2>
        <button class="read">mark as read</button>
      </div>
      <div *ngFor="let notification of groupedNotifications[date]"
        [ngClass]="{'unread': !notification.read,'link': notification.link}">
        <p class="notification-content" (click)="selectNotification(notification)">
          <img class="exclamation" src="assets/base/icons/exclamation-circle.svg">
          {{ notification.content }}
        </p>
      </div>
    </div>
  </div>

  <div class="clear-container">
    <button mat-flat-button class="clear">Clear All</button>
  </div>
</div>
