/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SchedulerSet { 
    jobId?: number;
    scheduleFlag?: boolean;
    email?: Array<string>;
    /**
     * 0 - Run Once <br />  1 - Recurring <br />  Required if ScheduleFlag is true
     */
    scheduleType?: number;
    cronExpression?: string;
    scheduledDateTime?: Date;
    accessCode?: string;
    /**
     * Number of days before the report expires <br />  valid range: 1 - 7  Required if ScheduleFlag is true
     */
    expiryDay?: number;
}