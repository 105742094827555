/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TransformationNodeKind = 'Aggregate' | 'GroupBy' | 'Filter' | 'Compute' | 'Expand';

export const TransformationNodeKind = {
    Aggregate: 'Aggregate' as TransformationNodeKind,
    GroupBy: 'GroupBy' as TransformationNodeKind,
    Filter: 'Filter' as TransformationNodeKind,
    Compute: 'Compute' as TransformationNodeKind,
    Expand: 'Expand' as TransformationNodeKind
};