import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportViewerComponent } from "../../shared/component/report-viewer/report-viewer.component";
import { Page } from 'src/app/shared/models/page.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from 'src/app/shared/services/page.service';
import salesDetailsReportFilter from "src/app/shared/component/input/data-list/salesDetailsReportFilter.json";
import salesSummaryFilterAndGrouping from "src/app/shared/component/input/data-list/salesSummaryFilterAndGrouping.json";
import { HttpErrorResponse } from '@angular/common/http';
import { skip } from 'rxjs/operators';
import { ReportViewerParams } from 'src/app/shared/models/reportViewerParams.model';
import { ReportViewerService } from 'src/app/shared/component/services/report-viewer.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormService } from 'src/app/shared/component/form/form.service';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormContent } from 'src/app/shared/component/form/model/FormContent.model';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextComponent } from 'src/app/shared/component/input/input-text/input-text.component';
import { ErrorPageService } from 'src/app/shared/services/error-page.service';
import { StrucConvertService } from 'src/app/shared/services/struc-convert.service';
import { FormCode } from '../enums/formCode';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-report',
  standalone: true,
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  imports: [CommonModule, ReportViewerComponent,  ReactiveFormsModule, TranslateModule, InputTextComponent]
})
export class ReportComponent implements OnDestroy {
  route$: Subscription;

  page: Page;
  formGrp: FormGroup;
  formCode: any;
  isReportMode: boolean = false;
  requiredAccessCode: boolean = false;
  rptViewerParam: ReportViewerParams;
  rptViewerData: any;
  filterGrpData: any;
  selectionGroups: any;
  selectedGroups: any;
  filterItems: any;
  defaultFilter: any;
  default: any;

  accessCodeInput: FormContent = {
    inputText: {
      name: 'accessCode',
      label: "Access Code",
      required: true,
      type: InputTypes.password,
      styling: {
        width: '100%'
      },
    }
  };

  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    private reportViewerService: ReportViewerService,
    private loaderService: LoaderService,
    private formService: FormService,
    private errorPageService: ErrorPageService,
    private strucConvertService: StrucConvertService
  ) { }

  async ngOnInit() {
    this.isReportMode = !this.pageService.isReportViewerPage();

    // report
    this.reportHandling();

    // report viewer (logged in)
    this.reportViewerHandling(this.page?.queryParams);

    this.route$ = this.route.queryParams.pipe(skip(1)).subscribe(async (param: any) => {
      this.isReportMode = !this.pageService.isReportViewerPage();

      // report viewer quit to report mode
      this.reportHandling();

      // report viewer (no login)
      this.reportViewerHandling(param);
    });
  }

  reportHandling() {
    this.requiredAccessCode = false;
    this.rptViewerData = null;
    if(this.isReportMode && this.page) {
      this.formCode = this.page.form.formCode;
      this.setFilterGrpItms();

      // set default group and filter
      this.selectedGroups = this.filterGrpData?.group ? this.filterGrpData.group : null;
      this.defaultFilter = this.strucConvertService.reverseNewStructure(this.filterGrpData?.rules, this.filterItems);
      this.default = this.filterGrpData?.rules ? this.filterGrpData.rules : null;
    }
  }

  async reportViewerHandling(param: any) {
    if(!this.isReportMode && param){
      const parameters = param?.parameters;

      this.rptViewerParam = this.reportViewerService.formatEncodedParam(parameters);
      this.formCode = this.rptViewerParam?.FormCode;

      // check to show access code page
      this.requiredAccessCode = this.rptViewerParam?.RequireAccessCode && this.rptViewerParam.RequireAccessCode.toLowerCase() === 'true' ? true : false;

      if(this.requiredAccessCode) {
        this.formGrp = this.formService.generateFormGroup([this.accessCodeInput]);
      } else {
        await this.getReportViewer();
      }
    }
  }

  async getReportViewer () {
    if (this.rptViewerParam && this.rptViewerParam.JobId && this.rptViewerParam.SubscriptionId
      && this.rptViewerParam.ApiUrl && (!this.requiredAccessCode || (this.requiredAccessCode && this.rptViewerParam.accessCode))) {
      this.loaderService.startLoading('msg.loading_report', '', 1);
      let result = await this.reportViewerService.getReportViewerV2(this.rptViewerParam);

      if (!(result instanceof HttpErrorResponse)) {
        this.setFilterGrpItms();
        result = await new Response(result).json();
        this.rptViewerData = result;
        this.requiredAccessCode = false;
      } else {
        this.errorPageService.show(result?.status, result?.statusText);
        this.loaderService.stopLoading();
      }
    } else {
      this.errorPageService.show(400, 'Bad Request');
      this.loaderService.stopLoading();
    }
  }

  setFilterGrpItms() {
    // set filter and group selection items
    this.filterGrpData = this.getReportSetting(this.formCode);
    this.selectionGroups = this.filterGrpData?.groupItems ? _.cloneDeep(this.filterGrpData.groupItems) : null;
    this.filterItems = this.filterGrpData?.filterItems ? this.filterGrpData.filterItems : null;
  }

  async onSubmitAccessCode() {
    this.formService.markAllAsTouched(this.formGrp);
    this.rptViewerParam.accessCode = this.formGrp.get('accessCode')?.value;
    await this.getReportViewer();
  }

  // temporary use the switch case to get the hard coded report json file, may change to call api to get the json later
  getReportSetting(formCode: string) {
    switch (formCode) {
      case FormCode.rptsalesdetailsv2:
        return salesDetailsReportFilter;
      case FormCode.rptsalessummaryv2:
        return salesSummaryFilterAndGrouping;
      default:
        return null;
    }
  }

  ngOnDestroy(): void {
    this.route$?.unsubscribe();
  }
}
