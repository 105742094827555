import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-report-setting-card',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: './report-setting-card.component.html',
  styleUrls: ['./report-setting-card.component.scss']
})
export class ReportSettingCardComponent {
  @Input() data: any;
}
