import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeriodInputSetting } from '../model/PeriodInputSetting.model';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberComponent } from '../input-number/input-number.component';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { MatCalendar } from '@angular/material/datepicker';
import { OrdinalPipe } from 'src/app/shared/pipes/ordinal.pipe';
import { PeriodType } from 'src/app/shared/enums/periodType';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-period-input',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InputNumberComponent,
    DatePickerComponent,
    OrdinalPipe
  ],
  templateUrl: './period-input.component.html',
  styleUrls: ['./period-input.component.scss']
})
export class PeriodInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() periodSetting: PeriodInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;
  @Output() periodChange = new EventEmitter<any>();

  @ViewChildren(MatCalendar, {read: ElementRef}) calendars: QueryList<ElementRef>;
  @ViewChild('monthInput', {static: false}) monthInputEl: ElementRef;

  dayChange$: Subscription;
  monthChange$: Subscription;
  typeChange$: Subscription;

  periodExpression: {month?: string, day?: number} = {};
  periodFormGroup: UntypedFormGroup;
  hideMonthInput: boolean = true;
  currtype: PeriodType;
  months: string[];

  month: any;
  day: any;

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    if(!this.formGroup.get(PeriodType.day)) {
      this.formGroup?.addControl(PeriodType.day, this.fb.control(''));
    }

    if(!this.formGroup.get(PeriodType.month)) {
      this.formGroup?.addControl(PeriodType.month, this.fb.control(''));
    }

    if(this.formGroup?.get(this.periodSetting.name)) {
      this.typeChange$ = this.formGroup?.get(this.periodSetting.name).valueChanges.subscribe((value: any) => {
        this.typeChange(value);
      });
    }

    if(this.formGroup?.get(PeriodType.month)) {
      this.monthChange$ = this.formGroup?.get(PeriodType.month).valueChanges.subscribe((value: any) => {
        this.monthChange(value);
      });
    }

    if(this.formGroup?.get(PeriodType.day)) {
      this.dayChange$ = this.formGroup?.get(PeriodType.day).valueChanges.subscribe((value: any) => {
        this.dayChange(value);
      });
    }

    this.periodSetting.typeOption = this.periodSetting?.typeOption? this.periodSetting.typeOption: [];
    this.months =  moment.months();
    this.periodSetting.monthOption = this.periodSetting.monthOption? this.periodSetting.monthOption: this.months.map((month: string) => {return {name: 'general.'+ month.toLowerCase(), value: month}});
    this.periodSetting.dayOption = this.periodSetting.dayOption? this.periodSetting.dayOption: [];
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.periodSetting?.currentValue) {
      this.periodSetting = changes.periodSetting.currentValue;
    }

    if(changes?.formData?.currentValue) {
      this.formData = changes.formData.currentValue;
      if(this.formGroup?.get(this.periodSetting.name)) {
        this.formGroup.controls[this.periodSetting.name].setValue(changes.formData.currentValue[this.periodSetting.name]);
      }

      if(this.formGroup?.get(PeriodType.day) && (changes.formData.currentValue[PeriodType.day] || changes.formData.currentValue[PeriodType.day] === 0)) {
        this.formGroup.controls[PeriodType.day].setValue(changes.formData.currentValue[PeriodType.day]);
      }

      if(this.formGroup?.get(PeriodType.month) && changes.formData.currentValue[PeriodType.month]) {
        this.formGroup.controls[PeriodType.month].setValue(changes.formData.currentValue[PeriodType.month]);
      }

    }
  }

  getDays(month?: string) {
    let day31: string[] = [this.months[0], this.months[2], this.months[4], this.months[6], this.months[7], this.months[9], this.months[11]];
    let day: number;

    if(month === this.months[1]) {
      day = 29;
    } else if(day31.includes(month) || !month) {
      day = 31;
    } else {
      day = 30;
    }

    let days = Array.from({length: day}, (_, i) => i + 1);
    days.push(0); // last day

    return days;
  }

  reset() {
    this.periodExpression = {};

    if(!this.hideMonthInput) {
      this.formGroup?.controls[PeriodType.month]?.setValue(this.months[0]);
      this.periodExpression = {
        month: this.months[0]
      }
    }

    this.periodSetting.dayOption = this.getDays(this.months[0]);
    this.formGroup?.controls[PeriodType.day]?.setValue(this.periodSetting.dayOption[0]);
    this.periodExpression = {
      ...this.periodExpression,
      day: this.periodSetting.dayOption[0]
    };
  }

  typeSelectionChange(event: any) {
    // if(!this.formGroup?.get(this.periodSetting.name)) {
      this.typeChange(event.value);
      this.reset();
    // }
  }
  
  typeChange(type: PeriodType) {
    if(type === PeriodType.month) {
      this.hideMonthInput = true;
      this.periodSetting.dayOption = this.getDays();
      this.periodExpression = {...this.periodExpression, day: this.formGroup.get(PeriodType.day)?.value};
      this.periodChange.emit(this.periodExpression);
    } else if(type === PeriodType.year) {
      this.hideMonthInput = false;
      this.periodSetting.dayOption = [];
    } else {
      this.hideMonthInput = false;
    }
  }

  monthSelectionChange(event: any) {
    this.monthChange(event.value);
  }

  monthChange(month: string) {
    this.periodSetting.dayOption = this.getDays(month);
    this.periodExpression = {...this.periodExpression, month: month};
    this.periodChange.emit(this.periodExpression);
  }

  daySelectionChange(event: any) {
    if(!this.formGroup?.get(PeriodType.day)) {
      this.dayChange(event.value);
    }
  }

  dayChange(day: any) {
    this.periodExpression = {...this.periodExpression, day: day};
    this.periodChange.emit(this.periodExpression);
  }

  ngOnDestroy(): void {
    this.dayChange$?.unsubscribe();
    this.monthChange$?.unsubscribe();
    this.typeChange$?.unsubscribe();
  }
}
