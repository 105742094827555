import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalChannelService } from '../../channel/localChannel.service';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { AuthService } from 'src/app/core/services/auth.service';
import { PageService } from 'src/app/shared/services/page.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UntypedFormGroup } from '@angular/forms';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { LookupStoreComponent } from '../../lookup/lookup-store/lookup-store.component';
import {
  BackendChannelStore,
  DeliveryMode,
} from 'src/swagger/cxmbackend/openapi';
import { FormService } from 'src/app/shared/component/form/form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { Mode } from 'src/app/shared/enums/mode';
import { formatDateString } from 'src/app/core/services/utils.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { LookupChannelComponent } from '../../lookup/lookup-channel/lookup-channel.component';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'app-channelstore-entry',
  standalone: true,
  templateUrl: './channelstore-entry.component.html',
  styleUrls: ['./channelstore-entry.component.scss'],
  imports: [FormComponent],
  providers: [LocalChannelService],
})
export class ChannelStoreEntryComponent implements OnInit, OnDestroy {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  channelId: number;
  storeId: number;
  channelStoreData: any;
  editMode: boolean = false;

  page$: Subscription;

  isStoreNewPage: boolean = false;
  isChannelNewPage: boolean = false;

  formSetting: FormSetting;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm,
  };

  reqBody: BackendChannelStore = null;

  constructor(
    private authService: AuthService,
    private pageService: PageService,
    private formService: FormService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private storeService: StoreService,
    private channelService: LocalChannelService,
  ) {}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);


    if (this.page) {
      if (this.page.queryParams?.mode) {
        this.editMode = this.page.queryParams.mode === Mode.edit ? true : false;
      }

      let params = this.pageService.getParameterDecode(this.page, 'params');

      this.channelId = this.params
        ? this.params['channelId']
        : params['channelId'];
      this.storeId = this.params ? this.params['storeId'] : params['storeId'];

      this.isStoreNewPage =  this.page.queryParams.mode != Mode.edit ? this.storeId? true : false : false;
      this.isChannelNewPage =  this.page.queryParams.mode != Mode.edit ? this.channelId? true : false : false;

      this.formSetting = {
        formCode: FormCode.channelentry,
        content: [
          {
            lookDropInput: {
              componentName: LookupStoreComponent,
              label: 'channelstore.store_id',
              name: 'storeId',
              valueKey: 'storeId',
              placeholder: 'general.select_at_least_one',
              api: '',
              filter: '',
              styling: {
                width: '60%',
              },
              editable: false,
            },
          },
          {
            lookDropInput: {
              componentName: LookupChannelComponent,
              label: 'channelstore.channel_id',
              name: 'channelId',
              valueKey: 'channelId',
              placeholder: 'general.select_at_least_one',
              api: '',
              filter: '',
              styling: {
                width: '60%',
              },
              editable: false,
            },
          },
          {
            toggleInput: {
              name: 'deliveryFlag',
              label: 'channelstore.delivery_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            lookDropInput: {
              name: 'deliveryMode',
              label: 'channelstore.delivery_mode',
              valueKey: 'value',
              hintValueKey: 'name',
              dropdownOption: Object.keys(DeliveryMode).map((key) => {
                return { value: key };
              }),
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'deliverySuspendEnd',
              label: 'channelstore.delivery_suspend_end_time',
              type: InputTypes.dateTimeLocal,
              required: false,
              styling: {
                width: '60%',
              },
            },
          },
          {
            toggleInput: {
              name: 'dineFlag',
              label: 'channelstore.dine_in_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            toggleInput: {
              name: 'qrDineFlag',
              label: 'channelstore.qr_dine_in_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'dineSuspendEnd',
              label: 'channelstore.dine_in_suspend_end_time',
              type: InputTypes.dateTimeLocal,
              required: false,
              styling: {
                width: '60%',
              },
            },
          },
          {
            toggleInput: {
              name: 'pickupFlag',
              label: 'channelstore.pickup_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            toggleInput: {
              name: 'qrTakeawayFlag',
              label: 'channelstore.qr_takeaway_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'pickupSuspendEnd',
              label: 'channelstore.pick_up_suspend_end_time',
              type: InputTypes.dateTimeLocal,
              required: false,
              styling: {
                width: '60%',
              },
            },
          },
          {
            toggleInput: {
              name: 'queueFlag',
              label: 'channelstore.queue_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            toggleInput: {
              name: 'reservationFlag',
              label: 'channelstore.reservation_flag',
              required: true,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'coverageKM',
              label: 'channelstore.coverage_km',
              type: InputTypes.number,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'badgeText',
              label: 'channelstore.badge_text',
              type: InputTypes.text,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'promoText',
              label: 'channelstore.promo_text',
              type: InputTypes.text,
              styling: {
                width: '60%',
              },
            },
          },
          {
            inputText: {
              name: 'previewJSON',
              label: 'channelstore.preview_json',
              type: InputTypes.text,
              styling: {
                width: '60%',
              },
            },
          },
        ],
      };

      if (this.editMode && this.channelId && this.storeId) {
        await this.getChannelStore(this.channelId, this.storeId);
      }
      else if (this.isStoreNewPage) {
        let store =  await this.storeService.getOdataStore(this.storeId + '', null, this.formDetail.formCode);
        this.channelStoreData = {
          storeId: this.storeId,
          locShortDesc: store['value'][0].locShortDesc
        };
      }
      else if (this.isChannelNewPage) {
        let channel =  await this.channelService.getOdataChannel(this.channelId + '', null, this.formDetail.formCode);
        this.channelStoreData = {
          channelId: this.channelId,
          channelTag: channel['value'][0].channelTag
        };
        let channelIdField = this.formSetting.content.splice(1,1);
        this.formSetting.content.splice(0,0,channelIdField[0]);
      }
    }
  }

  onToolbarAction(value: ActionToolBarResp) {}

  async getChannelStore(channelId: number, storeId: number) {
    // Get ChannelStore
    let result = await this.channelService.reqOdataChannelStoreData(
      channelId,
      storeId,
      this.formDetail.formCode
    );

    if (
      result instanceof HttpErrorResponse === false &&
      result['value'].length > 0
    ) {
      this.channelStoreData = result['value'][0];
    }
  }

  async onSubmitForm(formGroup: UntypedFormGroup) {
    let result = null;

    this.reqBody = this.formService.getReqBody(formGroup);
    if (this.editMode) {
      this.reqBody = {
        ...this.reqBody,
        storeId: +this.storeId,
        channelId: +this.channelId,
        dineSuspendEnd:
          formGroup.get('dineSuspendEnd').value == null
            ? null
            : formatDateString(formGroup.get('dineSuspendEnd').value),
        deliverySuspendEnd:
          formGroup.get('deliverySuspendEnd').value == null
            ? null
            : formatDateString(formGroup.get('deliverySuspendEnd').value),
        pickupSuspendEnd:
          formGroup.get('pickupSuspendEnd').value == null
            ? null
            : formatDateString(formGroup.get('pickupSuspendEnd').value),
        badgeText:
          formGroup.get('badgeText').value == null
            ? ''
            : formGroup.get('badgeText').value,
        promoText:
          formGroup.get('promoText').value == null
            ? ''
            : formGroup.get('promoText').value,
        rowVersion: this.channelStoreData?.rowVersion
          ? this.channelStoreData.rowVersion
          : '',
      };

      result = await this.channelService.updateChannelStore(this.reqBody, this.formDetail.formCode);
    } else {
      this.reqBody = {
        ...this.reqBody,
        // channelId: this.channelId? +this.channelId : formGroup.get('channelId').value,
        dineSuspendEnd:
          formGroup.get('dineSuspendEnd').value == null
            ? null
            : formatDateString(formGroup.get('dineSuspendEnd').value),
        deliverySuspendEnd:
          formGroup.get('deliverySuspendEnd').value == null
            ? null
            : formatDateString(formGroup.get('deliverySuspendEnd').value),
        pickupSuspendEnd:
          formGroup.get('pickupSuspendEnd').value == null
            ? null
            : formatDateString(formGroup.get('pickupSuspendEnd').value),
        badgeText:
          formGroup.get('badgeText').value == null
            ? ''
            : formGroup.get('badgeText').value,
        promoText:
          formGroup.get('promoText').value == null
            ? ''
            : formGroup.get('promoText').value,
      };

      result = await this.channelService.addChannelStore(this.reqBody, this.formDetail.formCode);
    }

    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {
      this.popupFormService.close(this.page.form.formCode);

      let msg: PopupMessage = {
        formCode: FormCode.storeohlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.channelstore_saved',
        desc: this.editMode
          ? 'msg.updated_channelstore'
          : 'msg.added_new_channelstore',
        actionBtnText: 'general.okay',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });

      this.pageService.refresh([FormCode.channelstorelisting]);
    } else {
      let msg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.operation_unsuccessful',
        desc: 'msg.system_couldnt_save_channelstore',
        actionBtnText: 'general.back',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
    }
  }

  openChildForm(event) {
    console.log('event: ', event);
  }

  ngOnDestroy(): void {
    this.page$?.unsubscribe();
  }
}
