import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { RMSSubscriptionInfoResponse } from '../core/models/local/RMSSubscriptionInfoResponse';

export interface UserSubscription {
  selectedSubscription: RMSSubscriptionInfoResponse;
}

export interface SubscriptionProps {}

const store = createStore(
  { name: 'subscription' },
  withProps<UserSubscription>({ selectedSubscription: null })
);

let globalSubscription: RMSSubscriptionInfoResponse = null;

store.subscribe((state) => {
  globalSubscription = state.selectedSubscription;
});

@Injectable({ providedIn: 'root' })
export class SubscriptionRepository {
  subscription$ = store.pipe(select((state) => state));
  selectedSubscription$ = store.pipe(select((state) => state.selectedSubscription));

  selectSubscription(subscription: RMSSubscriptionInfoResponse) {
    store.update((state) => ({
      ...state,
      selectedSubscription: subscription,
    }));
  }

  resetSubscription() {
    store.reset();
  }

  getSubscription() {
    return globalSubscription;
  }
}

export const persist = persistState(store, {
  key: 'subscription',
  storage: localStorageStrategy,
});

// persist.initialized$.subscribe(console.log);
