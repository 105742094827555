import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { deleteAllEntities, selectAllEntities, selectEntity, UIEntitiesRef, upsertEntities, withEntities, withUIEntities } from '@ngneat/elf-entities';
import { persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { PageRoute } from '../shared/enums/pageRoute';

interface ListUI {
  id: number;
  open: boolean;
}

interface List {
  id: number;
  name: string;
}

const store = createStore(
  { name: 'lists' },
  withEntities<List>(),
  withUIEntities<ListUI>()
);

@Injectable({ providedIn: 'root' })
export class ListsRepository {
  allList$ = store.pipe(selectAllEntities());

  getListByPage(page: any) {
    store.pipe(selectEntity(page));
  }

  addLists(page: PageRoute, list: any) {
    let data: any = {
      id: page, 
      list: list
    };
    
    store.update(
      upsertEntities(data),
      upsertEntities(data, { ref: UIEntitiesRef })
    );
  }

  removeLists() {
    store.update(deleteAllEntities());
  }

  persistState(key: string) {
    const persist = persistState(store, {
      key: key,
      storage: sessionStorageStrategy,
    });
  }

  get store() {
    return store;
  }
}

