<div class="border">
  <div class="row main-row">
    <div class="first-column">
      <img src="assets/base/icons/monitor.svg">
    </div>
    <div class="row second-column">
      <div class="row row-full-width first-row">
        License Title
      </div>
      <div class="row row-full-width second-row">
        1/10
      </div>
    </div>
  </div>
</div>
