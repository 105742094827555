/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StoreAddstoreattrtwoBody { 
    storeId?: number;
    attrCode: string;
    attrData: string;
    attrImage?: Array<Blob>;
    createdDate?: Date;
    createdUserId?: string;
    lastRevDate?: Date;
    lastUserId?: string;
    rowVersion?: string;
}