import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTypes } from '../../../shared/component/input/enum/InputTypes';
import { DropdownOption } from '../../../shared/component/input/model/DropdownOption.model';
import { FormSetting } from '../../../shared/component/form/model/FormSetting.model';
import { PageService } from 'src/app/shared/services/page.service';
import { Page } from 'src/app/shared/models/page.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { UntypedFormGroup } from '@angular/forms';
import {
  AddrSysFlag,
  ChannelAddchanneltwoBody,
  ChannelCustExpiryFlag,
  ChannelService,
  JoinMode,
  WalletFlag,
} from 'src/swagger/cxmbackend/openapi';
import { LookupCountryCodeComponent } from '../../lookup/component/lookup-country-code/lookup-country-code.component';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { decodeBase64, formatDateString } from 'src/app/core/services/utils.service';
import { LookupStateCodeComponent } from '../../lookup/component/lookup-state-code/lookup-state-code.component';
import { LookupRegionCodeComponent } from '../../lookup/component/lookup-region-code/lookup-region-code.component';
import { LookupAreaCodeComponent } from '../../lookup/component/lookup-area-code/lookup-area-code.component';
import { LocalChannelService } from '../localChannel.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { LookupMerchantIdComponent } from 'src/app/cxm-backend-web/lookup/component/lookup-merchant-id/lookup-merchant-id.component';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { DropdownTimezoneComponent } from '../../dropdown/dropdown-timezone/dropdown-timezone/dropdown-timezone.component';
import { LookupMerchantComponent } from '../../lookup/lookup-merchant/lookup-merchant.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Mode } from 'src/app/shared/enums/mode';

@Component({
  selector: 'app-channel-form',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.scss'],
  standalone: true,
  imports: [FormComponent],
  providers: [ChannelService],
})
export class ChannelFormComponent implements OnInit, OnDestroy {
  page: Page;
  pages: Page[];
  formDetail: any = null;
  channelId: string;
  channelData: any;
  page$: Subscription;
  editMode: boolean = false;
  timeZoneOption: DropdownOption[] = [
    {
      name: '+5:00, India',
      value: 'IN',
    },
    {
      name: '+6:00, Russia',
      value: 'RU',
    },
    {
      name: '+7:00, Indonesia',
      value: 'ID',
    },
    {
      name: '+8:00, Malaysia',
      value: 'MY',
    },
  ];

  boolOption: DropdownOption[] = [
    {
      name: 'Inactive',
      value: '0',
    },
    {
      name: 'Active',
      value: '1',
    },
  ];

  platformCodeOption: DropdownOption[] = [
    {
      name: 'Odaring',
      value: 'ODA',
    },
  ];

  joinModeOption: DropdownOption[] = [
    {
      name: 'No restriction',
      value: '0',
    },
    {
      name: 'By approval',
      value: '1',
    },
  ];

  custExpiryOption: DropdownOption[] = [
    {
      name: 'No expiry',
      value: '0',
    },
    {
      name: 'By day',
      value: '1',
    },
    {
      name: 'By month',
      value: '2',
    },
    {
      name: 'By month end',
      value: '3',
    },
    {
      name: 'By year',
      value: '4',
    },
    {
      name: 'Fixed date',
      value: '9',
    },
  ];

  addrSysOption: DropdownOption[] = [
    {
      name: 'Predefined',
      value: '0',
    },
    {
      name: 'Google map',
      value: '1',
    },
    {
      name: 'SG map',
      value: '2',
    },
  ];

  formSetting: FormSetting = {
    formCode: FormCode.channelentry,
    content: [
      {
        inputText: {
          name: 'channelTag',
          label: 'Channel Tag',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%',
          },
          maxLength: 50,
        },
      },
      {
        inputText: {
          name: 'channelDesc',
          label: 'Channel Description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%',
          },
          maxLength: 254,
        },
      },
      {
        inputText: {
          name: 'platformCode',
          label: 'Platform Code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%',
          },
          maxLength: 254,
        },
      },
      {
        inputNumber: {
          name: 'homeChannelId',
          label: 'Home Channel Id',
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'dineFlag',
          label: 'Dine Flag',
        },
      },
      {
        toggleInput: {
          name: 'pickupFlag',
          label: 'Pickup Flag',
        },
      },
      {
        toggleInput: {
          name: 'deliveryFlag',
          label: 'Delivery Flag',
        },
      },
      {
        inputText: {
          name: 'channelExpiryDate',
          label: 'Channel Expiry Date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'joinMode',
          label: 'Join Mode',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(JoinMode).map((key) => {
            return { value: key, name: JoinMode[key] };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'custExpiryFlag',
          label: 'Customer Expiry Flag',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(ChannelCustExpiryFlag).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'custExpiryValue',
          label: 'Customer Expiry Value',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'custExpiryDate',
          label: 'Customer Expiry Date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'addrSysFlag',
          label: 'Address system flag',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(AddrSysFlag).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'coverageKM',
          label: 'Coverage in KM',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'webDocGroup',
          label: 'Doc group for running no.',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          maxLength: 25,
        },
      },
      {
        inputText: {
          name: 'webDocCode',
          label: 'Doc code for running no.',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          maxLength: 25,
        },
      },
      {
        inputText: {
          name: 'regKey',
          label: 'Registration key',
          type: InputTypes.text,
          placeholder: '99999-99999',
          required: true,
          styling: {
            width: '60%',
          },
          maxLength: 15,
        },
      },
      {
        inputText: {
          name: 'menuSetId',
          label: 'Menu set id',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '100%',
          },
        },
      },
      {
        inputText: {
          name: 'countryCode',
          label: 'Country Code',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '100%',
          },
          maxLength: 5,
        },
      },
      {
        inputNumber: {
          name: 'latitude',
          label: 'Latitude of position',
          required: false,
          styling: {
            width: '100%',
          },
        },
      },
      {
        inputNumber: {
          name: 'longitude',
          label: 'Longitude of position',
          required: false,
          maxLength: 5,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'timeZone',
          label: 'store.time_zone',
          componentName: DropdownTimezoneComponent,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'imageLink',
          label: 'Image link (square logo)',
          type: InputTypes.text,
          styling: {
            width: '60%',
          },
          required: false,
        },
      },
      {
        inputText: {
          name: 'backImageLink',
          label: 'Back image link (rectangle)',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        uploadInput: {
          name: 'image',
          label: 'Logo Image',
          verticalLayout: true,
          acceptFormat: 'image/*',
          dataKey: 'imageLink',
        }
      },
      {
        uploadInput: {
          name: 'backImage',
          label: 'Banner Image',
          acceptFormat: 'image/*',
          dataKey: 'backImageLink',
        }
      },
      {
        inputText: {
          name: 'backColor',
          label: 'Background color',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          maxLength: 10,
        },
      },
      {
        inputText: {
          name: 'foreColor',
          label: 'Foreground color',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          maxLength: 10,
        },
      },
      {
        inputNumber: {
          name: 'filterMinVal',
          label: 'Filter minimum value',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'filterMaxVal',
          label: 'Filter maximum value',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'techChrgPerc',
          label: 'Tech charges percentage',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'paymentChrgPerc',
          label: 'Payment charges percentage',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'channelChrgPerc',
          label: 'Channel charges percentage',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'loyaltyChrgPerc',
          label: 'Loyalty charges percentage',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
    ],
    sideNavItm: [
      { name: 'Channel Details ', action: '' },
      { name: 'Registered User ', action: '' },
      { name: 'Table Section ', action: '' },
      { name: 'Table ', action: '' },
      { name: 'Attribute ', action: '' },
      { name: 'Session Times ', action: '' },
      { name: 'Filter ', action: '' },
      { name: 'Kitchen Queue ', action: '' },
      { name: 'Operating Hours ', action: '' },
    ],
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm,
  };

  constructor(
    private authService: AuthService,
    private localChannelService: LocalChannelService,
    private pageService: PageService,
    private channelService: ChannelService,
    private customService: CustomService,
    private formService: FormService,
    private dialog: MatDialog
  ) {
    this.page$ = this.pageService.pageSubject$.subscribe(async (pages) => {
      this.pages = pages;

      if (this.page) {
        let foundPage = this.pages.find(
          (page) => page.form.filePath === this.page.form.filePath
        );
        if (foundPage) {
          this.channelId = foundPage.listingId;
          if (this.channelId) {
            this.getChannel(this.channelId);
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.channelId = params['channelId'];
      if (this.channelId) {
        await this.getChannel(this.channelId.toString());
      }
      else
      {
        this.channelData = {
          platformCode: 'ODA'
        }
      }
      if (this.page.queryParams?.mode) {
        this.editMode = this.page.queryParams.mode === Mode.edit ? true : false;
      }
    }
  }

  onToolbarAction(value: ActionToolBarResp) {}

  async getChannel(channelId: string) {
    // Get Channel
    let result = await this.localChannelService.getOdataChannel(channelId, null, this.formDetail.formCode);
    if (
      result instanceof HttpErrorResponse === false &&
      result['value'].length > 0
    ) {
      this.channelData = result['value'][0];
    }
  }

  async addChannel(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channel.AddChannel,
      hostPath: environment.hostPath,
      headers: null,
      body: reqBody,
      httpHeaderType: undefined,
    };

    let hMac = this.customService.assignHMac(cusReq);

    let result = await this.localChannelService.addChannel(reqBody, this.formDetail.formCode)

    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {

      let msg: PopupMessage = {
        formCode: FormCode.channellisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.channel_saved',
        desc: 'msg.added_new_channel',
        actionBtnText: 'general.okay',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
      this.pageService.closePage(this.page);
      this.pageService.refresh([FormCode.channellisting]);
    } else {
      let msg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.operation_unsuccessful',
        desc: 'msg.system_couldnt_save_channel',
        actionBtnText: 'general.back',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
    }
  }

  async updateChannel(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channel.UpdateChannel,
      hostPath: environment.hostPath,
      headers: null,
      body: reqBody,
      httpHeaderType: undefined,
    };

    let hMac = this.customService.assignHMac(cusReq);

    let result = await this.localChannelService.updateChannel(reqBody, this.formDetail.formCode)

    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {

      let msg: PopupMessage = {
        formCode: FormCode.channellisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.channel_saved',
        desc: 'msg.updated_channel',
        actionBtnText: 'general.okay',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
      this.pageService.closePage(this.page);
      this.pageService.refresh([FormCode.channellisting]);
    } else {
      let msg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.operation_unsuccessful',
        desc: 'msg.system_couldnt_save_channel',
        actionBtnText: 'general.back',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
    }
  }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let formData = this.formService.getFormDataReqBody(formGroup);
    formData.delete('custExpiryDate')
    console.log(formGroup.get('custExpiryDate').value)
    if (formGroup.get('custExpiryDate').value) formData.append("custExpiryDate",  formatDateString(formGroup.get('custExpiryDate').value))
    formData.delete('channelExpiryDate')
    if (formGroup.get('channelExpiryDate').value) formData.append("channelExpiryDate",  formatDateString(formGroup.get('channelExpiryDate').value))
      if (this.channelId) {
      formData.append("channelId", +this.channelId);
      this.updateChannel(formData);
    } else {
      this.addChannel(formData);
    }
  }

  openChildForm(event) {
    console.log('event: ', event);
  }

  ngOnDestroy(): void {
    this.page$?.unsubscribe();
  }
}
