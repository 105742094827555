/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IEdmEntityContainerElement } from './iEdmEntityContainerElement';

export interface IEdmEntityContainer { 
    readonly elements?: Array<IEdmEntityContainerElement>;
    readonly schemaElementKind?: IEdmEntityContainer.SchemaElementKindEnum;
    readonly namespace?: string;
    readonly name?: string;
}
export namespace IEdmEntityContainer {
    export type SchemaElementKindEnum = 'None' | 'TypeDefinition' | 'Term' | 'Action' | 'EntityContainer' | 'Function';
    export const SchemaElementKindEnum = {
        None: 'None' as SchemaElementKindEnum,
        TypeDefinition: 'TypeDefinition' as SchemaElementKindEnum,
        Term: 'Term' as SchemaElementKindEnum,
        Action: 'Action' as SchemaElementKindEnum,
        EntityContainer: 'EntityContainer' as SchemaElementKindEnum,
        Function: 'Function' as SchemaElementKindEnum
    };
}