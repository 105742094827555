import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: environment.identityUrl,

  // Login Url of the Identity Provider
  loginUrl: `${environment.identityUrl}/connect/authorize`,

  // Login Url of the Identity Provider
  logoutUrl: `${environment.identityUrl}/connect/endsession`,


  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.oauthConfig.clientId,
  responseType: 'code',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. Also provide user sepecific
  scope: environment.oauthConfig.scope,

  // useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
  // // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  // silentRefreshShowIFrame: false,
  // silentRefreshTimeout: 0, // For faster testing
  // timeoutFactor: 0.8,
  // sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
}
