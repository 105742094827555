import { Injectable } from '@angular/core';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { CustomHeader } from 'src/app/core/models/local/CustomHeader';
import { FormCode } from 'src/app/shared/enums/formCode';

@Injectable({
  providedIn: 'root'
})
export class PosPromotionHService {
  curHttpHeader: CustomHeader;

  constructor(
    private customService: CustomService,
  ) {

  }

  async getPosPromotionhList(formCode: FormCode, filter?: string, top?: number, orderBy?: string) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.posPromotionH.getPosPromotionhList,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      } as CustomHeader,
      httpHeaderType: undefined,
      queryParams: {
        $count: true,
        $filter: filter? filter: 'StatusFlag ne 9',
        $top: top? top: undefined,
        $orderby: orderBy? orderBy: undefined
      }
    };

    let respInfo: any = null;
    respInfo = await this.reqCustomHttpCall(cusReq);

    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}

