import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef, reflectComponentType } from '@angular/core';
import { PopupForm } from '../../models/popupForm.model';
import { Subscription } from 'rxjs';
import { PopupFormService } from '../../services/popup-form.service';
import { Page } from '../../models/page.model';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PageService } from '../../services/page.service';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { FormCode } from '../../enums/formCode';
import { AnyAaaaRecord } from 'dns';

@Component({
    selector: 'app-popup-form',
    templateUrl: './popup-form.component.html',
    styleUrls: ['./popup-form.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, MatIconModule, NgStyle, TranslateModule]
})
export class PopupFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() page: Page;
  @Input() fullScreen: boolean = false;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('slider', { static: true }) sliderEl: ElementRef;

  open$: Subscription;
  close$: Subscription;
  closeById$: Subscription;
  closeAll$: Subscription;
  routeQueryParam$: Subscription;

  componentRef: ComponentRef<any>
  data: any;
  popupData: any;
  popupForm: PopupForm;
  title: string;
  params: any;
  position: string;

  slider: boolean;
  isMobile: boolean;
  constructor(private cd: ChangeDetectorRef,
    private popupFormService: PopupFormService,
    private translateService: TranslateService,
    private pageService: PageService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.open$ = this.popupFormService.open$.subscribe((popupData: any) => {
      this.createComponent(popupData);
      let position = popupData?.popupForm?.position? popupData?.popupForm?.position: ActionToolbarPosition.RIGHT;
      this.getPosition(position);
    });

    this.close$ = this.popupFormService.close$.subscribe((data: {formCode: FormCode, data?: any}) => {
      if(data.formCode && this.page && data.formCode === this.page.form.formCode) {
        this.close(data);
      }
    });

    this.closeById$ = this.popupFormService.closeById$.subscribe((data: {id: string, data?: any}) => {
      if(this.popupForm?.id && this.popupForm.id === data.id) {
        this.close(data);
      }
    });

    this.closeAll$ = this.popupFormService.closeAll$.subscribe(() => {
      this.close();
    });

    this.showPopupByParam();

    this.routeQueryParam$ = this.pageService.routeQueryParam$.subscribe((page: Page) => {
      if(this.page?.form?.formCode === page?.form?.formCode) {
        this.page = page;
        this.showPopupByParam();
      }
    });
  }

  createComponent(popupData: any) {
    let fullScreen = popupData && popupData.popupForm.fullScreen? popupData.popupForm.fullScreen: false;
    this.params = null;

    if(popupData && this.fullScreen === fullScreen && (fullScreen || (!fullScreen && this.page && this.page.form.formCode === popupData.popupForm.formCode))) {
      this.popupForm = popupData.popupForm;
      this.title = popupData.popupForm?.title? popupData.popupForm.title: '';

      // Bind popup form property with value
      if(popupData.popupForm && popupData.data) {
        // Bind title value
        let splitedTitle = popupData.popupForm?.title? popupData.popupForm.title.split(" "): [];

        splitedTitle.forEach((title: string, index: number) => {
          let key = title.charAt(0) === ":"? title.replace(/:/, ""): null;

          if(popupData.data[key]) {
            splitedTitle[index] = popupData.data[key];
          }

          if(!key && popupData) {
            splitedTitle[index] = this.translateService.instant(title);
          }
        });

        this.title = splitedTitle.join(" ");

        // Bind params value
        let params = this.popupForm?.params? _.cloneDeep(this.popupForm.params): [];

        if(params && params.length > 0){
          let formattedParams = [];
          params.forEach((param: string, index: number) => {
            if(popupData.data[param]) {
              formattedParams.push({[param]: popupData.data[param]});
            } else {
              formattedParams.push({[param]: param});
            }
          });

          this.params = Object.assign({}, ...formattedParams);
        }
      }

      // Create dynamic component
      this.data = popupData;
      this.container.clear();

      let component: Type<any>;

      if(typeof popupData.popupForm.componentName === 'string') {
        let foundComponent = this.pageService.getComponentByName(popupData.popupForm.componentName);
        component = foundComponent?.component? foundComponent.component: '';
      } else {
        component = this.data.popupForm.componentName;
      }

      this.componentRef = this.container.createComponent(component);

      let componentData: any = reflectComponentType(component);
      let inputs: any = [];
      componentData.inputs.forEach((data: any) => inputs.push(data.propName));

      if(this.params && inputs.includes('params')) {
        this.componentRef.setInput('params', this.params);
      }

      if(popupData?.data && inputs.includes('popupFormData')) {
        this.componentRef.setInput('popupFormData', popupData?.data);
      }

      if(popupData?.popupForm && inputs.includes('popupFormSetting')) {
        this.componentRef.setInput('popupFormSetting', popupData?.popupForm);
      }

      if(this.componentRef?.instance?.popupFormSetting) {
        this.componentRef.instance.popupFormSetting.subscribe((setting: Partial<PopupForm>) => {
          this.popupForm = {
            ...this.popupForm,
            ...setting
          };
        });
      }

      if(this.page && inputs.includes('page')) {
        this.componentRef.setInput('page', this.page);
      }

      if(this.componentRef?.instance.formSetting) {
        this.componentRef.instance.formSetting.popup = true;
      }

      if(this.componentRef?.instance.actionToolbarSetting) {
        this.componentRef.instance.actionToolbarSetting.popup = true;
      }

      if(this.componentRef?.instance?.title) {
        this.componentRef.instance.title.subscribe((title: any) => {
          this.title = title? title: '';
        });
      }

      if(this.componentRef?.instance?.page) {
        this.componentRef.instance.page = this.page;
      }

      this.cd.detectChanges();
    }
  }

  showPopupByParam() {
    if(this.page?.queryParams && this.page?.queryParams['p'] && this.page?.form?.formCode) {
      let popupData = {
        popupForm: {
          formCode: this.page.queryParams['f']? decodeBase64(decodeURIComponent(this.page.queryParams['f'])): '',
          componentName: this.page.queryParams['p']? decodeBase64(decodeURIComponent(this.page.queryParams['p'])): '',
        }
      }
      this.createComponent(popupData);
      this.getPosition(ActionToolbarPosition.RIGHT);
    }
  }

  close(data?: {id?: string, formCode?: FormCode, data?: any}) {
    this.data = null;
    
    if(this.page && this.popupForm?.navChg) {
      this.pageService.navigateByPath("/" + this.page.form.filePath, this.page.paramsAsMap.get('params'), null, true);
    }

    this.popupFormService.afterClosed$.next(data);
  }

  triggerClose() {
    this.popupFormService.triggerClose$.next({id: this.popupForm?.id, formCode: this.popupForm?.formCode as FormCode});
  }

  delete() {
    this.popupFormService.delete$.next(true);
  }

  getWidth() {
    let width = 'auto';
    this.getScreenSize();

    if(this.isMobile) {
      width = '100%';
    } else {
      width = this.popupForm?.styling?.width? this.popupForm.styling.width: '40%';
    }

    return width;
  }

  getPosition(position: ActionToolbarPosition) {
    switch(position) {
      case ActionToolbarPosition.LEFT: 
        this.position = 'from-left';
        break;
      case ActionToolbarPosition.RIGHT:
        this.position = 'from-right';
        break;
      case ActionToolbarPosition.CENTER:
        this.position = 'from-center';
        break;
      default:
        this.position = 'from-right';
    }
  }

  @HostListener('document:keydown.escape', ['$event']) 
  onKeydownHandler(event: KeyboardEvent) {
    if(this.popupForm?.disableClose) {
      this.triggerClose();
    } else {
      this.close();
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }

  ngOnDestroy(): void {
    this.open$?.unsubscribe();
    this.close$?.unsubscribe();
    this.closeById$?.unsubscribe();
    this.closeAll$?.unsubscribe();
    this.routeQueryParam$?.unsubscribe();
    this.componentRef?.destroy();
  }
}
