import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators, ValidatorFn } from '@angular/forms';
import { Component, Input, OnChanges, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef, ViewContainerRef, ComponentRef } from '@angular/core';
import { LookDropInputSetting } from '../model/LookDropInputSetting.model';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgClass, KeyValuePipe, NgFor, NgStyle } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CellPosition, ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, IRowNode, TabToNextCellParams, SizeColumnsToFitGridStrategy } from 'ag-grid-community';
import { AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import * as _ from 'lodash';
import { DialogModule } from 'primeng/dialog';
import { ListingComponent } from '../../listing/listing.component';
import { ActionToolBarSetting } from '../../action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from '../../action-tool-bar/model/ActionToolBarResp.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Router } from '@angular/router';
import { ListingSetting } from '../../listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { RowSelection } from 'src/app/shared/enums/rowSelection';
import { merge, Subscription } from 'rxjs';
import { FormService } from '../../form/form.service';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { OverlayModule } from '@angular/cdk/overlay';
import { ElementService } from 'src/app/shared/services/element.service';
import { InputTypes } from '../enum/InputTypes';
import { UppercaseTranslatePipe } from 'src/app/shared/pipes/uppercase-translate.pipe';
import { ComponentService } from 'src/app/shared/services/component.service';

@Component({
  selector: 'app-look-drop-input',
  templateUrl: './look-drop-input.component.html',
  styleUrls: ['./look-drop-input.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgFor,
    NgClass,
    NgStyle,
    KeyValuePipe,
    TranslateModule,
    OverlayModule,
    TranslateModule,
    DialogModule,
    ListingComponent,
    OverlayPanelModule,
    MatSelectModule,
    AgGridModule,
    UppercaseTranslatePipe
  ]
})
export class LookDropInputComponent implements OnInit, OnChanges, OnDestroy, ICellRendererAngularComp, AfterViewInit {
  @ViewChild('input') inputEl: ElementRef;
  @ViewChild('lookupInput') lookupInputEl: ElementRef;
  @ViewChild('lookup', { read: ViewContainerRef }) lookupComponent: ViewContainerRef;
  @ViewChild('trigger') trigger: ElementRef;

  @Input() lookDropInputSetting: LookDropInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData?: any;
  @Input() multiSelectData?: { node?: IRowNode, value?: any, displayValue?: any }[];
  @Input() hint?: string;
  @Input() isDisabled: boolean;

  inputValue: string = '';
  inputFocus: boolean = false;
  label: string = 'Input Label';
  inputClicked: boolean = false;

  openLookup: boolean;
  openAutoComplete: boolean;

  // Input for lookup dialog
  @Input() rowData: any;
  @Input() lookupActionToolbarSetting: ActionToolBarSetting;
  @Input() lookupFormCode: FormCode;

  @Output() onLookup = new EventEmitter();
  @Output() onAutoComplete = new EventEmitter();
  @Output() onSelected = new EventEmitter();
  @Output() onLookupToolbarAction = new EventEmitter<any>();
  @Output() onLookupRowSelected = new EventEmitter<{ event: any, nodes: IRowNode[] }>();
  @Output() onLookupApplySelectedRow = new EventEmitter<IRowNode[]>();

  dependency$: Subscription;
  hiddenFormField$: Subscription;
  required$: Subscription;
  lookDropStatus$: Subscription;

  private gridApi!: GridApi;
  params!: ICellRendererParams;
  gridOptions: GridOptions;
  disable: boolean = false;
  isMobile: boolean = false;
  readonly: boolean = false;
  selectedData: any;
  selectedNodes: { node?: IRowNode, value?: any }[] = [];
  dialogRef: any;
  defaultSetting: LookDropInputSetting;
  onLookInputTimer: any;
  onDropInputTimer: any;
  inputText: string;
  displayName: string;
  displayInput: string
  defaultColDef: ColDef;
  initInputElHeight: number;
  componentRef: ComponentRef<any>;
  listingSetting: ListingSetting;
  element: HTMLInputElement;
  rowSelection: any = RowSelection;
  displaySelectedNodes: IRowNode[] = [];
  expression: any;
  visibleData: { node?: IRowNode, value?: any, displayValue?: any }[] = [];
  hiddenCount: number = 0;

  constructor(public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private formService: FormService,
    private elementService: ElementService,
    private componenetService: ComponentService) { }

  @Input() defaultLookup: any;

  ngOnInit() {
    this.lookDropInputSetting = {
      id: this.lookDropInputSetting.id ? this.lookDropInputSetting.id : '',
      name: this.lookDropInputSetting.name,
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : '',
      displayValueKey: this.lookDropInputSetting.displayValueKey ? this.lookDropInputSetting.displayValueKey : '',
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : '',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling ? this.lookDropInputSetting.styling : {},
      dropdownOption: this.lookDropInputSetting.dropdownOption ? this.lookDropInputSetting.dropdownOption : null,
      headerName: this.lookDropInputSetting.headerName ? this.translateService.instant(this.lookDropInputSetting.headerName) : '',
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey : '',
      colDef: this.lookDropInputSetting.colDef ? this.lookDropInputSetting.colDef : [],
      lookupComponentName: this.lookDropInputSetting.lookupComponentName ? this.lookDropInputSetting.lookupComponentName : null,
      dependency: this.lookDropInputSetting.dependency ? this.lookDropInputSetting.dependency : null,
      disabled: this.lookDropInputSetting.disabled ? this.lookDropInputSetting.disabled : false,
      autoCompleteDelay: this.lookDropInputSetting.autoCompleteDelay ? this.lookDropInputSetting.autoCompleteDelay : 0,
      manualInput: this.lookDropInputSetting.manualInput ? this.lookDropInputSetting.manualInput : false,
      type: this.lookDropInputSetting.type ? this.lookDropInputSetting.type : null,
      defaultLookdrop: this.lookDropInputSetting.defaultLookdrop ? this.lookDropInputSetting.defaultLookdrop : false,
      isDropdown: this.lookDropInputSetting.isDropdown ? this.lookDropInputSetting.isDropdown : false
    }

    if ((!this.lookDropInputSetting.autoComplete && this.lookDropInputSetting.dropdownOption) && this.formGroup.get(this.lookDropInputSetting.name) && !this.lookDropInputSetting.manualInput) {
      this.readonly = true;
    }

    if ((!this.lookDropInputSetting.autoCompleteDelay && !this.lookDropInputSetting.dropdownOption)) {
      this.lookDropInputSetting.autoCompleteDelay = 500;
    }

    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
      height: '68vh'
    }

    // Set colDef
    if (this.lookDropInputSetting.colDef.length === 0 && (this.lookDropInputSetting.valueKey || this.lookDropInputSetting.displayValueKey)) {
      this.lookDropInputSetting.colDef = [{ field: this.lookDropInputSetting.displayValueKey ? this.lookDropInputSetting.displayValueKey : this.lookDropInputSetting.valueKey }];
    }

    // Add form control
    this.formGroup.addControl(this.lookDropInputSetting.name, this.fb.control(''));

    this.addCheckbox();

    // Set col def
    if (this.lookDropInputSetting?.colDef) {
      this.lookDropInputSetting.colDef.forEach((colDef: ColDef) => {
        if (colDef.headerName) {
          colDef.headerName = this.translateService.instant(colDef.headerName);
        }
      });
    }

    // On router change
    this.router.events.subscribe((val) => {
      this.openLookup = false;
    });

    // Set Dependency
    if (this.formGroup && this.lookDropInputSetting) {
      this.dependency$ = this.formService.setDependency(this.formGroup, this.lookDropInputSetting);
    }

    this.displayName = 'displayInput-' + this.lookDropInputSetting.name;
    let validators: ValidatorFn[] = [];

    // Add validator for display input
    if (this.lookDropInputSetting.type === InputTypes.email) {
      validators.push(Validators.email);
    }

    this.formGroup.addControl(this.displayName, this.fb.control('', { validators: validators, updateOn: 'change' }));

    // disable input field
    if (this.formGroup.get(this.displayName) && this.lookDropInputSetting.disabled) {
      this.formGroup.controls[this.displayName].disable();
      this.formGroup.controls[this.lookDropInputSetting.name].disable();
    }

    this.hiddenFormField$ = this.formGroup.get(this.lookDropInputSetting.name)?.valueChanges.subscribe(() => {
      if (this.formGroup.get(this.lookDropInputSetting.name).errors) {
        this.formGroup.get(this.displayName).setErrors(this.formGroup.get(this.lookDropInputSetting.name).errors);
      } else {
        this.formGroup.get(this.displayName).setErrors(null);
      }
    });

    // Set value to display input
    let value = this.formGroup?.get(this.lookDropInputSetting.name)?.value;
    if (this.lookDropInputSetting.dropdownOption && this.formGroup?.get(this.lookDropInputSetting.name)) {
      let foundData = this.lookDropInputSetting.dropdownOption.find((option: any) => option[this.lookDropInputSetting.valueKey] === value);
      if (foundData) {
        let displayVal = foundData[this.lookDropInputSetting?.displayValueKey ? this.lookDropInputSetting.displayValueKey : this.lookDropInputSetting.valueKey];
        setTimeout(() => {
          this.formGroup.controls[this.displayName]?.setValue(displayVal);
        }, 0);
      }
    }

    this.required$ = this.componenetService.required$.subscribe((val: any) => {
      if (val.name === this.lookDropInputSetting.name) {
        this.lookDropInputSetting.required = val.required;

        if (this.lookDropInputSetting.required) {
          this.formGroup.get(this.lookDropInputSetting.name)?.addValidators(Validators.required);
        } else {
          this.formGroup.get(this.lookDropInputSetting.name)?.removeValidators(Validators.required);
        }
      }
    });

    this.lookDropStatus$ = this.formGroup.get(this.lookDropInputSetting.name)?.statusChanges.subscribe(val => {
      if (val === 'INVALID' && this.formGroup.get(this.lookDropInputSetting.name).errors) {
        this.formGroup.get(this.displayName).setErrors(this.formGroup.get(this.lookDropInputSetting.name).errors);
      } else {
        this.formGroup.get(this.displayName).setErrors(null);
      }
    });
  }

  onGridColumnChange(event: any) {
    event.api?.autoSizeAllColumns();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  ngAfterViewInit(): void {
    // Scroll event of content page
    let inputEl: HTMLElement = this.lookupInputEl.nativeElement;
    const scroller = inputEl.closest(".content-page");

    scroller?.addEventListener("scroll", (event: any) => {
      let inputHeight = inputEl.offsetHeight;

      if (!this.lookDropInputSetting.defaultLookdrop) {
        if (inputEl && this.rowData && this.openAutoComplete) {
          let overlayEl = document.querySelector('.cdk-overlay-pane') as HTMLElement;

          if ((window.innerHeight - inputEl.offsetHeight - inputEl.getBoundingClientRect().top) < inputEl.getBoundingClientRect().top) {
            overlayEl.style.top = 'unset';
            overlayEl.style.bottom = window.innerHeight - inputEl.getBoundingClientRect().top + 15 + 'px';
          } else {
            overlayEl.style.bottom = 'unset';
            overlayEl.style.top = inputEl.getBoundingClientRect().top + inputHeight + 15 + 'px';
          }
        }
      }
    });


    // On dependency change
    if (this.formGroup && this.lookDropInputSetting?.dependency && this.formGroup.get(this.lookDropInputSetting.dependency.name)) {
      let dependency: any = this.formGroup.controls[this.lookDropInputSetting.dependency.name];
      let dependant: any = this.formGroup.controls[this.lookDropInputSetting.name];

      dependant.disable();

      this.dependency$ = dependency.valueChanges.subscribe((data: any) => {
        if ((data && !this.lookDropInputSetting.dependency.allowParentInvalid && dependency.valid) || (data && this.lookDropInputSetting.dependency.allowParentInvalid)) {
          dependant.enable();
        } else {
          dependant.reset();
          this.hint = "";
          dependant.disable();
        }
      });
    }

    // Get Element
    if (this.lookDropInputSetting.id && this.elementService.getElementById(this.lookDropInputSetting.id)) {
      this.element = this.elementService.getElementById(this.lookDropInputSetting.id);
    }
  }

  ngOnChanges(changes: any) {
    if (changes.formGroup?.currentValue) {
      this.formGroup = changes.formGroup.currentValue;
    }

    if (changes.rowData?.currentValue) {
      this.rowData = changes.rowData?.currentValue;

      // Filter rowData based on dependecy value
      if (this.lookDropInputSetting?.dependency?.filterByParentVal) {
        let dependency: any = this.formGroup.controls[this.lookDropInputSetting.dependency.name];

        if (dependency && dependency.value) {
          this.rowData = this.rowData.filter((data: any) => {
            if (data && !this.lookDropInputSetting.dependency.filterBy && data[this.lookDropInputSetting.dependency.name] === dependency.value) {
              return data;
            } else if (data && this.lookDropInputSetting.dependency.filterBy && data[this.lookDropInputSetting.dependency.filterBy] === dependency.value) {
              return data;
            }
          });
        }
      }

      if (this.componentRef) {
        this.componentRef.instance.rowData = this.rowData;
      }

      if (!this.hint && this.formData) {
        let index = this.rowData.findIndex(data => data[this.lookDropInputSetting.valueKey] === this.formData[this.lookDropInputSetting.valueKey]);
        this.hint = index >= 0 ? this.rowData[index][this.lookDropInputSetting.hintValueKey] : '';
      }
    }

    if (changes.formData?.currentValue) {
      let data: any = changes.formData.currentValue[this.lookDropInputSetting.name] !== null ? changes.formData.currentValue[this.lookDropInputSetting.name] : '';
      this.inputText = data;
      if (this.lookDropInputSetting.dropdownOption && this.lookDropInputSetting.dropdownOption.length > 0) {
        let foundData = this.lookDropInputSetting.dropdownOption.find((option: any) => option[this.lookDropInputSetting.valueKey] === data);
        if (foundData) {
          let displayVal = foundData[this.lookDropInputSetting?.displayValueKey ? this.lookDropInputSetting.displayValueKey : this.lookDropInputSetting.valueKey];
          this.formGroup.controls[this.lookDropInputSetting.name].setValue(foundData[this.lookDropInputSetting.valueKey]);

          setTimeout(() => {
            this.formGroup.controls[this.displayName]?.setValue(displayVal);
          }, 0);
        }
      } else {
        setTimeout(() => {
          this.formGroup.controls[this.lookDropInputSetting.name]?.setValue(data);
          if (!this.lookDropInputSetting.multiSelect) {
            this.formGroup.controls[this.displayName]?.setValue(data);
          }
        }, 0);
      }

      let separatedData: any[] = [];
      if (data) {
        if (typeof data === 'string') {
          separatedData = data.split(',').map(item => item.trim()); // Ensure to trim whitespace
        } else if (Array.isArray(data)) {
          separatedData = data;
        }
      }

      this.selectedNodes = separatedData.map((data: any) => { return { value: data } });
      if (this.lookDropInputSetting.multiSelect) {
        this.multiSelectData = this.selectedNodes;
      }

      if (this.lookDropInputSetting.hintValueKey) {
        this.hint = changes.formData.currentValue[this.lookDropInputSetting.hintValueKey];
      }
    }

    if (changes.multiSelectData?.currentValue) {
      this.multiSelectData = changes.multiSelectData?.currentValue;
      this.selectedNodes = this.multiSelectData;

      if (this.lookDropInputSetting.valueKey && this.formGroup?.controls[this.lookDropInputSetting.name]) {
        let selectedData = this.multiSelectData.map((multiData: any) => multiData?.node?.data ? multiData?.node?.data[this.lookDropInputSetting.valueKey] : multiData?.value);
        this.formGroup.controls[this.lookDropInputSetting.name].setValue(selectedData);
      }

      if (this.lookDropInputSetting.lookupComponentName && this.componentRef) {
        this.componentRef.instance.selectedNodes = this.selectedNodes;
      }

      this.positionAutoComplete();
    }

    if (changes.openLookup?.currentValue === false && this.inputEl) {
      this.inputEl.nativeElement.focus();
    }

    if (changes.lookDropInputSetting?.currentValue) {
      this.lookDropInputSetting = changes.lookDropInputSetting?.currentValue;
      if (this.lookDropInputSetting.required) {
        this.formGroup.get(this.lookDropInputSetting.name)?.addValidators(Validators.required);
      } else {
        this.formGroup.get(this.lookDropInputSetting.name)?.removeValidators(Validators.required);
      }
    }

    if (changes.hint?.currentValue) {
      this.hint = changes.hint.currentValue;
    }

    if (changes.formData?.currentValue) {
      this.formData = changes.formData.currentValue;
      const colDef = this.lookDropInputSetting.colDef;

      if (changes.formData.currentValue.isMultiple === true) {
        this.addCheckbox();
      } else {
        const checkboxColumnIndex = this.lookDropInputSetting?.colDef?.findIndex(col => col.field === 'checkbox');
        if (checkboxColumnIndex !== -1) {
          this.lookDropInputSetting?.colDef?.splice(checkboxColumnIndex, 1);
        }
      }

      if (changes.formData?.currentValue && changes.formData?.previousValue) {
        if (changes.formData?.currentValue.isMultiple !== changes.formData?.previousValue?.isMultiple) {
          const field = colDef[1].field;
          const lowercasedFirstLetter = field.charAt(0).toUpperCase() + field.slice(1);
          this.lookDropInputSetting.multiSelect = changes.formData?.currentValue.isMultiple;
          if(lowercasedFirstLetter) {
            this.formGroup.get(`displayInput-${lowercasedFirstLetter}`)?.setValue('');
          }
        }
      }
    }

    if (changes['multiSelectData']) {
      this.updateVisibleData();
    }
  }

  addCheckbox() {
    if (this.lookDropInputSetting.multiSelect && this.lookDropInputSetting?.colDef) {
      const checkboxColumnIndex = this.lookDropInputSetting.colDef.findIndex(col => col.field === 'checkbox');
      if (checkboxColumnIndex === -1) {
        this.lookDropInputSetting.colDef.unshift({
          field: 'checkbox',
          filter: false,
          checkboxSelection: true,
          suppressSizeToFit: true,
          editable: false,
          maxWidth: 42,
          lockPosition: 'left',
          cellStyle: undefined,
          cellRenderer: undefined,
          headerCheckboxSelection: true
        });
      }
    }
  }

  getLabel() {
    if (this.lookDropInputSetting.label
      && this.translateService.instant(this.lookDropInputSetting.label.toLowerCase()) !== this.lookDropInputSetting.label.toLowerCase()) {
      return this.translateService.instant(this.lookDropInputSetting.label.toLowerCase());
    }
    return this.lookDropInputSetting.label;
  }

  async onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.isMobile = window.innerWidth <= 991 ? true : false;

    let autoCompleteEl: HTMLElement = document.getElementById('agGrid');
    let inputEl: HTMLElement = this.lookupInputEl.nativeElement;

    if (!this.isMobile && this.openAutoComplete && autoCompleteEl) {
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 0);
    }

    if ((window.innerHeight - inputEl.offsetHeight - inputEl.getBoundingClientRect().top) >= inputEl.getBoundingClientRect().top) {
      autoCompleteEl.style.maxHeight = window.innerHeight - inputEl.getBoundingClientRect().bottom - 30 + 'px';
    } else {
      autoCompleteEl.style.maxHeight = inputEl.getBoundingClientRect().top - 70 + 'px';
    }

    this.positionAutoComplete();
    // this.focusCell(0);
  }

  onFirstDataRendered(event: FirstDataRenderedEvent) {
    // Check if input value is found from data
    // let foundLookupData = this.rowData && this.rowData[0]? this.rowData[0]: null;
    // if(foundLookupData && this.inputText && foundLookupData[this.lookDropInputSetting.valueKey]
    //     && foundLookupData[this.lookDropInputSetting.valueKey]?.toLowerCase() === this.inputText.toLowerCase()
    //     && !(this.lookDropInputSetting.multiSelect && this.selectedData?.length > 0)) {
    //   setTimeout(() => {
    //     let renderedNodes = this.gridApi.getRenderedNodes();
    //     renderedNodes[0].setSelected(true);
    //     this.onSelectionChanged(null, false);
    //   }, 0);
    // } else
    if (this.gridApi) {
      let selectedNodes = this.selectedNodes.map((node: { node?: IRowNode, value?: any }) => {
        if (node.node) {
          return JSON.stringify(node.node.data);
        } else {
          return node.value;
        }
      });

      this.gridApi.forEachNode((node: IRowNode) => {
        if (selectedNodes.includes(JSON.stringify(node.data)) || (this.lookDropInputSetting.valueKey && selectedNodes.includes(node.data[this.lookDropInputSetting.valueKey]))) {
          node.setSelected(true);
        }
      });
    }
  }

  onClickLookup(event: any) {
    event.stopPropagation();
    this.rowData = null;
    this.openAutoComplete = false;
    this.openLookup = false;
    if (!this.openLookup) {
      this.displaySelectedNodes = null;
    }

    if (this.onLookInputTimer) {
      clearTimeout(this.onLookInputTimer);
    }

    if (this.onDropInputTimer) {
      clearTimeout(this.onDropInputTimer);
    }

    if (!this.formGroup.get(this.lookDropInputSetting.name).disabled && !this.element?.disabled) {
      this.openLookup = true;
    }

    this.onLookup.emit(this.selectedNodes);
  }

  onClickInput(event: any) {
    if (!this.formGroup.get(this.lookDropInputSetting.name).disabled && !this.element?.disabled) {
      if (this.lookDropInputSetting.dropdownOption) {
        this.onClickDropdown();
      } else {
        this.onLoadAutoCompleteData(event);
      }
    }

    if (this.lookDropInputSetting.multiSelect && this.lookDropInputSetting.manualInput) {
      this.formGroup.controls[this.displayName].markAsTouched();
    }
  }

  onClickDropdown() {
    if (!this.openAutoComplete && !this.formGroup.get(this.lookDropInputSetting.name).disabled && !this.element?.disabled) {
      let inputText: string = this.inputEl.nativeElement.value;
      let foundData: any;

      if (this.lookDropInputSetting.isDropdown) {
        foundData = this.lookDropInputSetting.dropdownOption;
      } else if (inputText && this.lookDropInputSetting.autoComplete) {
        foundData = this.lookDropInputSetting.dropdownOption.filter((data: any) => {
          let keys: string[] = Object.keys(data);
          let found = keys.filter((key) => (data[key].toLowerCase().replace(/\s/g, "")).includes(inputText.toLowerCase().replace(/\s/g, "")));
          return found && found.length > 0 ? true : false;
        });
      }

      this.rowData = foundData && foundData.length > 0 ? foundData : this.lookDropInputSetting.dropdownOption;
      this.openAutoComplete = true;
    }
  }

  onClickClear() {
    this.formGroup.controls[this.lookDropInputSetting.name].setValue('');
    this.formGroup.controls[this.displayName].setValue('');
    this.selectedNodes = [];
  }

  onShowLookup() {
    // Dynamic component params for lookup
    if (this.lookDropInputSetting.lookupComponentName && this.lookupComponent) {
      this.lookupComponent.clear();
      this.componentRef = this.lookupComponent.createComponent(this.lookDropInputSetting.lookupComponentName);
      this.componentRef.instance.actionToolbarSetting = this.lookupActionToolbarSetting;
      this.componentRef.instance.lookDropInputSetting = this.lookDropInputSetting;
      this.componentRef.instance.selectedNodes = this.selectedNodes;
      this.componentRef.instance.rowData = this.rowData;
      this.componentRef.instance.onApplySelectedRow.subscribe((data: any) => { if (data) this.onListingApplySelectedRow(data) });
      this.componentRef.instance.onRowSelected.subscribe((data: any) => { if (data) this.onListingRowSelected(data) });
      this.componentRef.instance.onToolbarAction.subscribe((data: any) => { if (data) this.onToolbarActioned(data) });
      this.cd.detectChanges();
    }
  }

  onLookupVisibleChange(event: any) {
    if (!event) {
      this.rowData = null;
    }
  }

  onLoadAutoCompleteData(event: any) {
    if (this.lookDropInputSetting.autoComplete) {
      this.rowData = null;
      this.openAutoComplete = false;
      this.openLookup = false;

      if (this.onLookInputTimer) {
        clearTimeout(this.onLookInputTimer);
      }

      if (this.onDropInputTimer) {
        clearTimeout(this.onDropInputTimer);
      }

      let delay = this.lookDropInputSetting.autoCompleteDelay ? this.lookDropInputSetting.autoCompleteDelay : 0;

      // filter dropdown
      if (this.lookDropInputSetting.dropdownOption && this.lookDropInputSetting.dropdownOption.length > 0) {
        let foundData: any;

        if (event.target.value) {
          foundData = this.lookDropInputSetting.dropdownOption.filter((data: any) => {
            let keys: string[] = Object.keys(data);
            let found = keys.filter((key) => (data[key].toLowerCase().replace(/\s/g, "")).includes(event.target.value.toLowerCase().replace(/\s/g, "")));
            return found && found.length > 0 ? true : false;
          });
        }

        this.rowData = foundData ? foundData : this.lookDropInputSetting.dropdownOption;

        this.onDropInputTimer = setTimeout(() => {
          this.openAutoComplete = true;
          this.cd.detectChanges();
        }, delay);
      } else {
        this.onLookInputTimer = setTimeout(() => {
          this.onAutoComplete.emit(event.target.value);
          this.openAutoComplete = true;
          this.cd.detectChanges();
        }, delay);
      }

      this.initInputElHeight = this.lookupInputEl.nativeElement.offsetHeight;
      this.inputText = event.target.value;
    }
  }

  onInput(event: any) {
    if (event.target.value && !this.inputEl.nativeElement.classList.contains('autoComplete')) {
      this.inputEl.nativeElement.classList.add('autoComplete');
    }

    if (!this.lookDropInputSetting.multiSelect) {
      this.selectedData = [];
      this.hint = "";
      this.selectedNodes = [];
    }

    if ((this.lookDropInputSetting.dropdownOption && this.lookDropInputSetting.dropdownOption.length >= 0) || !this.lookDropInputSetting.dropdownOption) {
      this.onLoadAutoCompleteData(event);
    }
  }

  onKeydown(event: any) {
    if (event.key !== "ArrowDown" && event.key !== "ArrowUp" && event.key !== 'Tab') return;

    let lookupInputEl: HTMLElement = this.lookupInputEl.nativeElement;

    if (((event.key === "ArrowDown" && (window.innerHeight - lookupInputEl.offsetHeight - lookupInputEl.getBoundingClientRect().top) >= lookupInputEl.getBoundingClientRect().top)
      || (event.key === "ArrowUp" && (window.innerHeight - lookupInputEl.offsetHeight - lookupInputEl.getBoundingClientRect().top) < lookupInputEl.getBoundingClientRect().top)) && this.rowData && this.gridApi) {
      event.preventDefault();
      this.focusCell(0);
    } else if (event.key === "Tab" && this.lookDropInputSetting.multiSelect && this.lookDropInputSetting.manualInput) {
      this.onAddManualInputVal(event);
    }
  }

  onCellKeydown(event: any) {
    let key = event.event.key;

    if (key !== "ArrowDown" && key !== "ArrowUp") return;

    let rowCount = event.api.getModel().getRowCount();

    if ((key === "ArrowDown" && (event.rowIndex + 1) >= rowCount) || (key === "ArrowUp" && event.rowIndex === 0)) {
      // this.openAutoComplete = false;
      this.inputEl.nativeElement.focus();
    }
  }

  onFocus(event: any) {
    this.inputClicked = true;

    // if(event.target.value && this.inputEl) {
    //   this.onLoadAutoCompleteData(event);
    // }
  }

  onSelectionChanged(event: any, close: boolean = true) {
    let selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

    if (event?.source !== 'api' && this.lookDropInputSetting.multiSelect) {
      // merge back previous selected nodes
      let renderedNodes: any[] = !this.openLookup ? this.gridApi.getRenderedNodes().map((node) => JSON.stringify(node.data)) : [];
      let noNodes: any[] = this.selectedNodes.filter((data) => !data.node);
      let prevNodes: IRowNode[] = this.selectedNodes.filter((data) => data.node).map((data) => data.node);
      let prevSelectedNodes = prevNodes.filter((node) => !renderedNodes.includes(JSON.stringify(node.data)));

      let selectedRows = [...noNodes, ...prevSelectedNodes, ...selectedNodes];
      this.selectedNodes = selectedRows.map((node: IRowNode) => { return { node: node } });
      this.onSelected.emit(selectedRows);
    }

    if (this.lookDropInputSetting.multiSelect && this.inputEl.nativeElement.classList.contains('autoComplete')) {
      this.inputEl.nativeElement.classList.remove('autoComplete');
    }

    if (event?.source !== 'api' && !this.lookDropInputSetting.multiSelect) {
      if (close) {
        this.openAutoComplete = false;
        this.openLookup = false;
        this.inputEl.nativeElement.focus();
      }

      if (this.inputEl.nativeElement.classList.contains('autoComplete')) {
        this.inputEl.nativeElement.classList.remove('autoComplete');
      }

      this.selectedNodes = [];

      if (selectedNodes && selectedNodes.length > 0) {
        this.selectedNodes.push({ node: selectedNodes[0] });

        if (this.lookDropInputSetting.valueKey || this.lookDropInputSetting.displayValueKey) {
          let key = this.lookDropInputSetting?.displayValueKey ? this.lookDropInputSetting.displayValueKey : this.lookDropInputSetting.valueKey;
          this.formGroup.controls[this.displayName].setValue(selectedNodes[0].data[key]);
          this.formGroup.controls[this.lookDropInputSetting.name].setValue(selectedNodes[0].data[this.lookDropInputSetting.valueKey]);
          this.inputText = selectedNodes[0].data[this.lookDropInputSetting.valueKey];
        }

        if (this.lookDropInputSetting.hintValueKey) {
          this.hint = selectedNodes[0].data[this.lookDropInputSetting.hintValueKey];
        }

        this.onSelected.emit(selectedNodes);
      }
    }

    // if(selectedNodes && selectedNodes.length > 0) {
    //   this.focusCell(selectedNodes[0].rowIndex);
    // } else {
    //   this.focusCell(0);
    // }
  }

  onRowClicked(event: any) {
    if (!this.lookDropInputSetting.multiSelect && event.node.isSelected()) {
      this.onSelectionChanged(null, true);
      this.openAutoComplete = false;
    }
  }

  onRowDataUpdated(event: GridApi) {
    if (this.displaySelectedNodes) {
      let displaySelectedNodes = this.displaySelectedNodes.map(node => JSON.stringify(node.data));

      event.forEachNode((node: IRowNode) => {
        if (displaySelectedNodes.includes(JSON.stringify(node.data))) {
          node.setSelected(true);
        }
      });
    }
  }

  onInputFocusOut(event: any) {
    this.inputClicked = false;

    if (!event.relatedTarget || (!event.relatedTarget.classList.contains('ag-input-field-input') &&
      (!event.relatedTarget.classList.contains('ag-cell') || event.relatedTarget.innerHTML.includes("app-property-grid-renderer")) &&
      !event.relatedTarget.classList.contains('multi-select-btn') && !event.relatedTarget.classList.contains('show-more-btn') &&
      !event.relatedTarget.classList.contains('add-icon'))) {
      if (this.lookDropInputSetting.multiSelect) {
        this.inputEl.nativeElement.value = "";
      }

      this.openAutoComplete = false;
      this.openLookup = false;
      this.inputText = "";
      clearTimeout(this.onLookInputTimer);
      clearTimeout(this.onDropInputTimer);
    }

    if ((event.relatedTarget && event.relatedTarget.classList.contains('ag-header-cell'))) {
      this.inputEl.nativeElement.focus();
    }

    if (!this.lookDropInputSetting.multiSelect && event.target.classList.contains('autoComplete') && !event.relatedTarget?.classList.contains('ag-cell-value')) {
      this.formGroup.controls[this.lookDropInputSetting.name].setValue('');
      this.formGroup.controls[this.displayName].setValue('');
      this.inputText = "";
    }
  }

  onAutoCompleteFocusOut(event: any) {
    if (!event.relatedTarget ||
      event.relatedTarget && (!event.relatedTarget.classList.contains('ag-cell') || event.relatedTarget.innerHTML.includes("app-property-grid-renderer"))) {
      this.inputEl.nativeElement.focus();
    }
  }

  onAutoCompleteBeforeShow(event: any) {
    let inputEl: HTMLElement = this.lookupInputEl.nativeElement;
    let overlayEl = event.overlay;

    overlayEl.style.left = inputEl.getBoundingClientRect().left + 'px';
    overlayEl.style.maxWidth = inputEl.offsetWidth + 'px';
    overlayEl.style.maxHeight = window.innerHeight - inputEl.offsetHeight - 30 + 'px';

    if ((window.innerHeight - inputEl.offsetHeight - inputEl.getBoundingClientRect().top) >= inputEl.getBoundingClientRect().top) {
      overlayEl.style.top = inputEl.getBoundingClientRect().top + inputEl.offsetHeight + 15 + 'px';
    } else {
      overlayEl.style.top = 'unset';
      overlayEl.style.bottom = window.innerHeight - inputEl.getBoundingClientRect().top + 15 + 'px';
    }
  }

  onAutoCompleteClose(event?: any) {
    if (event) {
      event.stopPropagation();
    }

    this.openAutoComplete = false;
  }

  onRemoveMultiSelectNodes(event: any, selectedNode?: IRowNode, index?: number) {
    event.stopPropagation();

    // With node (select from lookdrop)
    if (selectedNode) {
      let node = this.multiSelectData.findIndex((data) => data.node === selectedNode);

      if (node >= 0) {
        this.multiSelectData.splice(node, 1);
      }

      if (this.gridApi && this.openAutoComplete) {
        this.gridApi.forEachNode((node: IRowNode) => {
          if (JSON.stringify(selectedNode.data) === JSON.stringify(node.data)) {
            node.setSelected(false);
          }
        });
      }

      this.selectedNodes = this.multiSelectData;
    }

    // Without node (manual input)
    if ((index >= 0 && this.lookDropInputSetting.manualInput) || !selectedNode) {
      this.multiSelectData.splice(index, 1);
      let selectedVal = this.formGroup.controls[this.lookDropInputSetting.name].value;
      selectedVal.splice(index, 1);
      this.formGroup.controls[this.lookDropInputSetting.name].setValue(selectedVal);
    }
  }

  onToolbarActioned(value: ActionToolBarResp) {
    this.onLookupToolbarAction.emit(value);
  }

  onListingRowSelected(nodes: IRowNode[]) {
    if (nodes && !this.lookDropInputSetting.multiSelect) {
      if (nodes[0] && this.lookDropInputSetting.valueKey) {
        this.selectedNodes = [];
        this.selectedNodes.push({ node: nodes[0] });
        this.formGroup.controls[this.lookDropInputSetting.name].setValue(nodes[0].data[this.lookDropInputSetting.valueKey]);
        this.formGroup.controls[this.displayName].setValue(nodes[0].data[this.lookDropInputSetting.valueKey]);
        this.inputText = nodes[0].data[this.lookDropInputSetting.valueKey];
        this.rowData = null;
        this.openLookup = false;
      }

      if (nodes[0] && this.lookDropInputSetting.hintValueKey) {
        this.hint = nodes[0].data[this.lookDropInputSetting.hintValueKey];
      }

      this.onSelected.emit(nodes);
    }

    // set and merge back the display selected nodes
    let rowData = this.rowData?.map(data => JSON.stringify(data));
    let mergeBack = [];
    this.displaySelectedNodes?.forEach(node => {
      if (!rowData?.includes(JSON.stringify(node.data))) {
        mergeBack.push(node);
      }
    });
    this.displaySelectedNodes = [...mergeBack, ...nodes];
  }

  onListingApplySelectedRow(nodes: IRowNode[]) {
    this.onSelected.emit(nodes);
    this.openLookup = false;
  }

  onAddManualInputVal(event: any) {
    let inputEl: HTMLInputElement = this.inputEl.nativeElement;

    if (!this.formGroup.get(this.displayName)?.errors && inputEl?.value) {
      this.multiSelectData = this.multiSelectData ? this.multiSelectData : [];
      this.multiSelectData.push({ value: inputEl.value });
      this.formGroup.controls[this.lookDropInputSetting.name].setValue(this.multiSelectData.map(data => data.value));
      inputEl.value = "";
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }

  positionAutoComplete() {
    if (!this.lookDropInputSetting.defaultLookdrop) {
      let overlayEl = document.querySelector('.cdk-overlay-pane') as HTMLElement;

      if (this.openAutoComplete && overlayEl) {
        setTimeout(() => {
          let inputEl: HTMLElement = this.lookupInputEl.nativeElement;
          overlayEl.style.width = inputEl.offsetWidth + 'px';

          if ((window.innerHeight - inputEl.offsetHeight - inputEl.getBoundingClientRect().top) >= inputEl.getBoundingClientRect().top) {
            overlayEl.style.top = inputEl.getBoundingClientRect().top + inputEl.offsetHeight + 15 + 'px';
          } else {
            overlayEl.style.top = 'unset';
            overlayEl.style.bottom = window.innerHeight - inputEl.getBoundingClientRect().top + 15 + 'px';
          }

          this.gridApi.sizeColumnsToFit();
        }, 0);
      }
    }
  }

  tabToNextCell(params: TabToNextCellParams): CellPosition | null {
    const previousCell = params.previousCellPosition;
    const lastRowIndex = previousCell.rowIndex;
    let nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;
    const renderedRowCount = params.api!.getModel().getRowCount();

    if (nextRowIndex < 0) {
      nextRowIndex = -1;
    }

    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }

    const result = {
      rowIndex: nextRowIndex,
      column: previousCell.column,
      rowPinned: previousCell.rowPinned,
    };

    return result;
  }

  focusCell(row: number) {
    // scrolls to the first row
    this.gridApi.ensureIndexVisible(row);

    // scrolls to the first column
    const firstCol = this.gridApi.getAllDisplayedColumns() ? this.gridApi.getAllDisplayedColumns()[0] : '';
    this.gridApi.ensureColumnVisible(firstCol);

    // sets focus into the first grid cell
    this.gridApi.setFocusedCell(row, firstCol);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return false;
  }

  isFormControlValuePresent(controlName: string): boolean {
    const control = this.formGroup.get(controlName);
    return control && control.value !== '';
  }

  updateVisibleData() {
    const maxVisible = 2; // Adjust as needed
    if (this.multiSelectData && this.multiSelectData.length > maxVisible) {
      this.visibleData = this.multiSelectData.slice(0, maxVisible);
      this.hiddenCount = this.multiSelectData.length - maxVisible;
    } else {
      this.visibleData = this.multiSelectData || [];
      this.hiddenCount = 0;
    }
  }

  removeMultiSelect(event: Event, node?: IRowNode, index?: number) {
    if (index !== undefined && this.multiSelectData) {
      this.multiSelectData.splice(index, 1);
      this.updateVisibleData();
    }
  }

  ngOnDestroy() {
    this.dependency$?.unsubscribe();
    this.hiddenFormField$?.unsubscribe();
    this.required$?.unsubscribe();
    this.lookDropStatus$?.unsubscribe();
  }
}
