import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ExcelStyle, GetRowIdFunc, GetRowIdParams, GridApi, IDateFilterParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';

import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { InvoiceFormComponent } from '../invoice-form/invoice-form.component';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupForm } from 'src/app/shared/models/popupForm.model';
import { Page } from 'src/app/shared/models/page.model';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { InvoiceService } from '../invoice.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { NgClass, NgIf } from '@angular/common';
import { FilterComponent } from '../../../shared/component/filter/filter.component';
import { ActionToolBarComponent } from 'src/app/shared/component/action-tool-bar/action-tool-bar.component';
import { MessageComponent } from 'src/app/shared/component/message/message.component';
import { CustomService } from 'src/app/core/services/custom.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ButtonRendererComponent } from 'src/app/shared/component/ag-grid/button-renderer/button-renderer.component';
import { TotalRecordRendererComponent } from 'src/app/shared/component/ag-grid/total-record-renderer/total-record-renderer.component';
import { PaginatorRendererComponent } from 'src/app/shared/component/ag-grid/paginator-renderer/paginator-renderer.component';
import { RouterLinkRendererComponent } from 'src/app/shared/component/ag-grid/router-link-renderer/router-link-renderer.component';
import { DropdownRendererComponent } from 'src/app/shared/component/ag-grid/dropdown-renderer/dropdown-renderer.component';
import { DropdownCellEditorComponent } from 'src/app/shared/component/ag-grid/dropdown-cell-editor/dropdown-cell-editor.component';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { InvoiceService as InvoiceServiceTwo } from 'src/swagger/cxmbackend/openapi';
import { InvoiceEntryComponent } from '../invoice-entry/invoice-entry.component';
import { InvoiceStatusFlag } from 'src/swagger/cxmbackend/openapi/model/invoiceStatusFlag';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Mode } from 'src/app/shared/enums/mode';
import { InvoiceEmailEntryComponent } from '../invoice-emailentry/invoice-emailentry.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { CustomFilterComponent } from '../../filter/filter.component';
import { agGridSideBarType } from 'src/app/shared/enums/agGridSideBarType';

@Component({
  selector: 'app-invoice-listing',
  templateUrl: './invoice-listing.component.html',
  styleUrls: ['./invoice-listing.component.scss'],
  standalone: true,
  imports: [NgIf, FilterComponent, NgClass, MessageComponent, ActionToolBarComponent, ListingComponent, CustomFilterComponent]
})
export class InvoiceListingComponent implements OnInit, OnDestroy {
  page: Page;
  serverSideDataSource: any;
  invoiceId: number;
  selectedRowData: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  refresh$: Subscription;
  popupMsg$: Subscription;
  actionBtn$: Subscription;
  rowData: any = null;
  options: any = null;
  frameworkComponents: any;
  formDetail: any = null;
  isRowSelected: boolean = false;
  isMobile: boolean = false;
  invoiceStatuses: any = InvoiceStatusFlag;
  listingSetting: ListingSetting;
  odataFilterString: any = null;
  gotEmail: boolean = true;
  gotRemove: boolean = true;

  excelStyles: ExcelStyle[] = [
    {
      id: 'numberType',
      numberFormat: {
        format: '0',
      },
    },
    {
      id: 'currencyFormat',
      numberFormat: {
        format: '#,##0.00',
      },
      alignment: {
        horizontal: "Right"
      }
    },
    {
      id: 'currencyTypeFormat',
      alignment: {
        horizontal: "Center"
      }
    },
    {
      id: 'percentageFormat',
      numberFormat: {
        format: '0.000',
      },
    },
    {
      id: 'booleanType',
      dataType: 'Boolean',
    },
    {
      id: 'stringType',
      dataType: 'String',
    },
    {
      id: 'dateType',
      dataType: 'DateTime',
    },
    {
      id: 'header',
      font: {
        bold: true,
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {

      id: 'stringCenterType',
      dataType: 'String',
      alignment: {
        horizontal: 'Center',
      },
    },

  ];

  // Column data
  colDef: ColDef[] = [
    {
      field: 'checkbox',
      headerCheckboxSelection: true
    },
    {
      ...listingRouterLink,
      field: 'invoiceRow',
      headerName: 'NO',
      cellClass: "stringCenterType",
      cellRendererParams: {
        inRouterLink: {
          route: '/invoice/form',
          params: ['invoiceId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        }
      },
      filter: true,
      sortable: true,
    },
    {
      ...listingActionButton,
      cellRendererParams: {
        type: ButtonRendererTypeEnum.duplicate
      },
      hide: true
    },
    {
      ...listingRouterLink,
      field: 'invoiceNo',
      headerName: 'Invoice number',
      sortable: true,
      hide: true
    },
    {
      ...listingDropdown,
      field: 'statusFlag',
      headerName: 'Status',
      cellEditorParams: {
        options: Object.keys(this.invoiceStatuses).map(key => this.invoiceStatuses[key])
      },
      hide: true
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice date',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filter: true,
      hide: true
    },
    {
      ...listingRouterLink,
      field: 'startDate',
      headerName: 'DATE',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filter: true,
      cellClass: "stringCenterType",
    },
    {
      field: 'endDate',
      headerName: 'End date',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filter: true,
      hide: true
    },
    {
      field: 'merchantDesc',
      headerName: 'Merchant Name',
      filter: true,
      hide: true
    },
    {
      field: 'storeDesc',
      headerName: 'Store Desc',
      filter: true,
      hide: true
    },
    {
      field: 'tranCount',
      headerName: 'Transaction count',
      filter: true,
      hide: true
    },
    {
      field: 'merchantDiscAmt',
      headerName: 'Merchant funded discount amount',
      filter: true,
      hide: true
    },
    {
      field: 'platformDiscAmt',
      headerName: 'Platform funded discount amount',
      filter: true,
      hide: true
    },
    {
      field: 'collectionAmt',
      headerName: 'Collection amount',
      filter: true,
      hide: true
    },
    {
      field: 'merchantTaxAmt',
      headerName: 'Merchant tax amount',
      filter: true,
      hide: true
    },
    {
      field: 'merchantAdjAmt',
      headerName: 'Merchant adjustment amount',
      filter: true,
      hide: true
    },
    {
      field: 'revenueAmt',
      headerName: 'Revenue amount',
      filter: true,
      hide: true
    },
    {
      field: 'commSalesAmt',
      headerName: 'Commission sales amount',
      filter: true,
      hide: true
    },
    {
      field: 'commPerc',
      headerName: 'Commission percentage',
      filter: true,
      hide: true
    },
    {
      field: 'CommAmt',
      headerName: 'Commission amount',
      filter: true,
      hide: true
    },
    {
      field: 'CommTaxAmt',
      headerName: 'Commission tax amount',
      filter: true,
      hide: true
    },
    {
      field: 'bankAccountName',
      headerName: 'NAME OF BENEFICIARY',
      filter: true,
      cellClass: "stringType"
    },
    {
      field: 'payableAmt',
      headerName: 'AMOUNT (MYR)',
      filter: true,
      cellClass: "currencyFormat"
    },
    {
      field: 'salesAdjAmt',
      headerName: 'Sales adjustment amount',
      filter: true,
      hide: true
    },
    {
      field: 'subscriptionAmt',
      headerName: 'Subscription amount',
      filter: true,
      hide: true
    },
    {
      field: 'netPayableAmt',
      headerName: 'Net payable amount',
      filter: true,
      hide:true
    },
    {
      field: 'outputURL',
      headerName: 'Output URL',
      filter: true,
      hide: true
    },
    {
      field: 'versionNo',
      headerName: 'Version no.',
      filter: true,
      hide: true
    },
    {
      field: 'platformCode',
      headerName: 'Payment Platform',
      filter: true,
      hide: true
    },
    {
      field: 'currency',
      headerName: 'Curreny Type',
      filter: true,
      hide: true,
    },
    {
      field: 'payRefNoString',
      headerName: 'Payment References',
      filter: true,
      hide: true
    },
    {
      field: 'bankCode',
      headerName: 'BANK CODE',
      filter: true,
      cellClass: "stringCenterType"
    },
    {
      field: 'bankAccountNo',
      headerName: 'ACCOUNT NUMBER',
      filter: true,
      cellClass: "stringCenterType"
    },

    {
      field: 'email',
      headerName: 'BENEFICIARY EMAIL',
      filter: true,
      cellClass: "stringType"
    },
    {
      field: 'mobileNo',
      headerName: 'Mobile No',
      filter: true,
      hide: true
    },
    {
      field: 'lastRevDate',
      headerName: 'Last revised date',
      filter: true,
      hide: true
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      ...toolbarListing,
      { name: 'new', icon: 'assets/base/icons/plus.svg', text: 'toolbar.new.invoice', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
    ]
  }
  private gridApi!: GridApi;
  public filters: FilterExpression[] = [
    {
      field: 'invoiceDate',
      title: 'Invoice Date',
      editor: 'date',
    },
    {
      field: 'startDate',
      title: 'Start Date',
      editor: 'date',
    },
    {
      field: 'endDate',
      title: 'End Date',
      editor: 'date',
    },
    {
      field: 'statusFlag',
      title: 'Status',
      editor: 'string',
    },
    {
      field: 'bankCode',
      title: 'Bank Code',
      editor: 'string',
    },
    {
      field: 'platformCode',
      title: 'Payment Platform',
      editor: 'string',
    },
    {
      field: 'storeDesc',
      title: 'Store Name',
      editor: 'string',
    },
    {
      field: 'merchantDesc',
      title: 'Merchant Name',
      editor: 'string',
    },
  ];

  customFilter: any = {
    filters: [
      {
        // condition: "AND",
        // rules: [
        //   {
        //     id: "SalesDate",
        //     field: "salesDate",
        //     type: "string",
        //     input: "formuladatepicker",
        //     operator: "equal",
        //     value: {
        //       formula: "[pastquarter]",
        //       type: "range",
        //       from: "2023-09-30T16:00:00Z",
        //       fromDate: "2023-10-01",
        //       to: "2023-12-31T15:59:59.999Z",
        //       toDate: "2023-12-31",
        //       offset: "+08:00",
        //       showText: "1/10/2023~31/12/2023"
        //     }
        //   },
        //   {
        //     id: "BizDate",
        //     field: "bizDate",
        //     type: "string",
        //     input: "formuladatepicker",
        //     operator: "equal",
        //     value: {
        //       formula: "[pastquarter]",
        //       type: "range",
        //       from: "2023-09-30T16:00:00Z",
        //       fromDate: "2023-10-01",
        //       to: "2023-12-31T15:59:59.999Z",
        //       toDate: "2023-12-31",
        //       offset: "+08:00",
        //       showText: "1/10/2023~31/12/2023"
        //     }
        //   },
        //   {
        //     id: "Status",
        //     field: "status",
        //     type: "string",
        //     input: "select",
        //     operator: "equal",
        //     value: "A"
        //   },
        //   {
        //     id: "StoreDesc",
        //     field: "storeDesc",
        //     type: "string",
        //     input: "text",
        //     operator: "equal",
        //     value: "SJ"
        //   },
        // ]
      },
      {
        // condition: "OR",
        // rules: [
        //   {
        //     id: "BillRefNo",
        //     field: "BillRefNo",
        //     type: "string",
        //     input: "text",
        //     operator: "contains",
        //     value: "123abc"
        //   },
        //   {
        //     id: "Week",
        //     field: "Week",
        //     input: "text",
        //     type: "integer",
        //     operator: "not_equal",
        //     value: "2"
        //   }
        // ]
      }
    ]
  };
  filterItems: any = [
    {
      fieldName: "general.invoicedate",
      id: "InvoiceDate",
      input: "formuladatepicker",
      type: "string",
      operator: [
        "equal",
        "not_equal",
        "greater",
        "greater_or_equal",
        "lesser",
        "less_or_equal",
        "between"
      ]
    },
    {
      fieldName: "general.storename",
      id: "StoreDesc",
      input: "text",
      type: "string",
      operator: ["equal", "not_equal", "begins_with", "contains", "not_contains", "ends_with"]
    }
  ]


  defaultFilterValue: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [{ field: "startDate", operator: "gte", value: new Date(new Date(Date.now()).setHours(0,0,0,0))},
    { field: "endDate", operator: "lte", value: new Date(new Date(Date.now()).setHours(0,0,0,0))}]
  }
  constructor(
    private pageService: PageService,
    private authService: AuthService,
    private invoiceService: InvoiceService,
    private popupFormService: PopupFormService,
    private formService: FormService
  ) { }
  ngOnInit(): void {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      totalRecordRenderer: TotalRecordRendererComponent,
      paginatorRenderer: PaginatorRendererComponent,
      routerLinkRenderer: RouterLinkRendererComponent,
      dropdownRenderer: DropdownRendererComponent,
      dropdownCellEditor: DropdownCellEditorComponent,
    };

    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
      duplicateRow: false,
      sideBar: [agGridSideBarType.columns, agGridSideBarType.filters]
    }
  }

  getInvoice() {
    this.serverSideDataSource = this.invoiceService.getOdataInvoiceProvider();
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(this.pageRoute.INVOICE_FORM);
    }
  }

  onRowSelect(event: any) {
    this.selectedRowData = event[0]?.data? event.map(a => a.data.invoiceId): null;
  }

  onPageSizeChange(pageSize: number) {}

  async onFilter(odataFilterString: any) {

    let queryOpts: ODataRawQueryOptions = {
      filter: odataFilterString
    };
    let data = await this.invoiceService.getOdataInvoice(queryOpts, this.formDetail.formCode);
    if (data instanceof HttpErrorResponse === false) {
      console.log(data['value']);

      this.rowData = data && data['value'] ? data['value'] : [];
      this.odataFilterString = odataFilterString;
    }
  }

  async onDelete() {
    if (this.selectedRowData){
      console.log(this.selectedRowData);
      let result = null;
      result = await this.invoiceService.deleteMultiInvoice(this.selectedRowData, this.formDetail.formCode);

      if (result instanceof HttpErrorResponse === false) {
        this.selectedRowData = null;
        await this.onFilter(this.odataFilterString);
      }
    }
  }

  async onEmail() {
    if (this.selectedRowData){
      console.log(this.selectedRowData);
      let popupForm: PopupForm = {
        formCode: FormCode.invoicelisting,
        title: 'general.invoice_email',
        componentName: InvoiceEmailEntryComponent,
        childPage: true
      };

      this.popupFormService.open(popupForm, this.selectedRowData);
    }
  }

  onBtExport() {
    var params = this.getParams();
    // this.gridApi.exportDataAsCsv(params);
    this.gridApi.exportDataAsExcel(params);
  }
  getParams() {
    return {
      fileName: 'Payout ' + moment(new Date).format("DD-MMM-YYYY")
    };
  }
  onGridApiUpdate($event) {
    this.gridApi = $event;
  }

  ngOnDestroy(): void {
    // Destroy event listener when component close
    this.refresh$?.unsubscribe();
    this.popupMsg$?.unsubscribe();
    this.actionBtn$?.unsubscribe();
  }
}
var filterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};
