import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams} from 'ag-grid-community';
// import { LookupComponent } from '../../input/lookup/lookup.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-dropdown-cell-editor',
    templateUrl: './dropdown-cell-editor.component.html',
    styleUrls: ['./dropdown-cell-editor.component.scss'],
    standalone: true,
    imports: [NgFor, TranslateModule]
})
export class DropdownCellEditorComponent implements ICellEditorAngularComp {
  private params!: ICellEditorParams & {options: []};
  public options!: [];
  public value: string;
  @Input() formGroup: UntypedFormGroup;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellEditorParams & {options: []}): void {
    this.params = params;
    this.options = this.params.options;
    this.value = params.value;
  }

  getValue() {
    return this.value;
  }

  onSelect(value: string) {
    this.value = value;
    this.params.api.stopEditing();
  }

  onSeeMore(openLookup: boolean) {
    if (openLookup) {
      // const dialogRef = this.dialog.open(LookupComponent, {
      //   panelClass: 'lookup-container-center'
      // });

      // dialogRef.afterClosed().subscribe(result => {
      //   if (result) {
      //     this.formGroup.controls['priceCode'].setValue(result.countryDesc);
      //   }
      // });
    }
  }

  refresh(params: ICellEditorParams & {options: []}): boolean {
    this.params = params;
    return false;
  }
}

