/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackendMasterDataStoreOH { 
    storeId?: number;
    seqNo?: number;
    weekDay?: string;
    weekDayDesc?: string;
    startDate?: Date;
    endDate?: Date;
    startTime1?: string;
    endTime1?: string;
    closedFlag?: boolean;
    closedMsg?: string;
}