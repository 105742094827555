/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorResponseModel } from '../model/errorResponseModel';
import { RaceDataModel } from '../model/raceDataModel';
import { RaceDeleteRequest } from '../model/raceDeleteRequest';
import { RaceDeleteResponse } from '../model/raceDeleteResponse';
import { RaceExpandModelCustomODataResult } from '../model/raceExpandModelCustomODataResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RaceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionId 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param formCode 
     * @param cultureCode Specify ISO 639-1 standard language codes. Default is &#x27;en-US&#x27;
     * @param clientDate yyyy-MM-dd
     * @param clientTimeZone Time Zone Name. Eg: Singapore Standard Time
     * @param autoFlag 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRaceAddracePost(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'body', reportProgress?: boolean): Observable<RaceDataModel>;
    public apiRaceAddracePost(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceDataModel>>;
    public apiRaceAddracePost(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceDataModel>>;
    public apiRaceAddracePost(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionId !== undefined && subscriptionId !== null) {
            headers = headers.set('subscriptionId', String(subscriptionId));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('x-Signature', String(xSignature));
        }
        if (formCode !== undefined && formCode !== null) {
            headers = headers.set('formCode', String(formCode));
        }
        if (cultureCode !== undefined && cultureCode !== null) {
            headers = headers.set('cultureCode', String(cultureCode));
        }
        if (clientDate !== undefined && clientDate !== null) {
            headers = headers.set('clientDate', String(clientDate));
        }
        if (clientTimeZone !== undefined && clientTimeZone !== null) {
            headers = headers.set('clientTimeZone', String(clientTimeZone));
        }
        if (autoFlag !== undefined && autoFlag !== null) {
            headers = headers.set('autoFlag', String(autoFlag));
        }

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RaceDataModel>('post',`${this.basePath}/api/race/addrace`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionId 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param formCode 
     * @param cultureCode Specify ISO 639-1 standard language codes. Default is &#x27;en-US&#x27;
     * @param clientDate yyyy-MM-dd
     * @param clientTimeZone Time Zone Name. Eg: Singapore Standard Time
     * @param autoFlag 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRaceDeleteracelistDelete(body?: Array<RaceDeleteRequest>, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'body', reportProgress?: boolean): Observable<RaceDeleteResponse>;
    public apiRaceDeleteracelistDelete(body?: Array<RaceDeleteRequest>, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceDeleteResponse>>;
    public apiRaceDeleteracelistDelete(body?: Array<RaceDeleteRequest>, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceDeleteResponse>>;
    public apiRaceDeleteracelistDelete(body?: Array<RaceDeleteRequest>, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionId !== undefined && subscriptionId !== null) {
            headers = headers.set('subscriptionId', String(subscriptionId));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('x-Signature', String(xSignature));
        }
        if (formCode !== undefined && formCode !== null) {
            headers = headers.set('formCode', String(formCode));
        }
        if (cultureCode !== undefined && cultureCode !== null) {
            headers = headers.set('cultureCode', String(cultureCode));
        }
        if (clientDate !== undefined && clientDate !== null) {
            headers = headers.set('clientDate', String(clientDate));
        }
        if (clientTimeZone !== undefined && clientTimeZone !== null) {
            headers = headers.set('clientTimeZone', String(clientTimeZone));
        }
        if (autoFlag !== undefined && autoFlag !== null) {
            headers = headers.set('autoFlag', String(autoFlag));
        }

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RaceDeleteResponse>('delete',`${this.basePath}/api/race/deleteracelist`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param raceCode 
     * @param apiVersion 
     * @param subscriptionId 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param formCode 
     * @param cultureCode Specify ISO 639-1 standard language codes. Default is &#x27;en-US&#x27;
     * @param clientDate yyyy-MM-dd
     * @param clientTimeZone Time Zone Name. Eg: Singapore Standard Time
     * @param autoFlag 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRaceGetraceGet(raceCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'body', reportProgress?: boolean): Observable<RaceDataModel>;
    public apiRaceGetraceGet(raceCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceDataModel>>;
    public apiRaceGetraceGet(raceCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceDataModel>>;
    public apiRaceGetraceGet(raceCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (raceCode !== undefined && raceCode !== null) {
            queryParameters = queryParameters.set('raceCode', <any>raceCode);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionId !== undefined && subscriptionId !== null) {
            headers = headers.set('subscriptionId', String(subscriptionId));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('x-Signature', String(xSignature));
        }
        if (formCode !== undefined && formCode !== null) {
            headers = headers.set('formCode', String(formCode));
        }
        if (cultureCode !== undefined && cultureCode !== null) {
            headers = headers.set('cultureCode', String(cultureCode));
        }
        if (clientDate !== undefined && clientDate !== null) {
            headers = headers.set('clientDate', String(clientDate));
        }
        if (clientTimeZone !== undefined && clientTimeZone !== null) {
            headers = headers.set('clientTimeZone', String(clientTimeZone));
        }
        if (autoFlag !== undefined && autoFlag !== null) {
            headers = headers.set('autoFlag', String(autoFlag));
        }

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RaceDataModel>('get',`${this.basePath}/api/race/getrace`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionId 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param formCode 
     * @param cultureCode Specify ISO 639-1 standard language codes. Default is &#x27;en-US&#x27;
     * @param clientDate yyyy-MM-dd
     * @param clientTimeZone Time Zone Name. Eg: Singapore Standard Time
     * @param autoFlag 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiRaceUpdateracePut(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'body', reportProgress?: boolean): Observable<RaceDataModel>;
    public apiRaceUpdateracePut(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceDataModel>>;
    public apiRaceUpdateracePut(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceDataModel>>;
    public apiRaceUpdateracePut(body?: RaceDataModel, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionId !== undefined && subscriptionId !== null) {
            headers = headers.set('subscriptionId', String(subscriptionId));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('x-Signature', String(xSignature));
        }
        if (formCode !== undefined && formCode !== null) {
            headers = headers.set('formCode', String(formCode));
        }
        if (cultureCode !== undefined && cultureCode !== null) {
            headers = headers.set('cultureCode', String(cultureCode));
        }
        if (clientDate !== undefined && clientDate !== null) {
            headers = headers.set('clientDate', String(clientDate));
        }
        if (clientTimeZone !== undefined && clientTimeZone !== null) {
            headers = headers.set('clientTimeZone', String(clientTimeZone));
        }
        if (autoFlag !== undefined && autoFlag !== null) {
            headers = headers.set('autoFlag', String(autoFlag));
        }

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RaceDataModel>('put',`${this.basePath}/api/race/updaterace`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param apiVersion 
     * @param subscriptionId 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param formCode 
     * @param cultureCode Specify ISO 639-1 standard language codes. Default is &#x27;en-US&#x27;
     * @param clientDate yyyy-MM-dd
     * @param clientTimeZone Time Zone Name. Eg: Singapore Standard Time
     * @param autoFlag 
     * @param count True &#x3D; return server side total count.  False &#x3D; return request record total count.
     * @param top Get first (x) numbers of records.
     * @param skip Skip (x) numbers of records.
     * @param filter Filter condition. Can join with \&quot;and\&quot; or \&quot;or\&quot; for multiple filter conditions. Format: {fieldname}{operator}{filtervalue} Eg: LocCode eq &#x27;001&#x27;
     * @param select Return selected fields/expand object(if supported by the API). Format: {fieldname} join with comma. Eg: LocCode,ItemCode
     * @param orderby Default sort by ascending order if no specific asc/desc. Format: {fieldname}{a space}{asc/desc} Eg: RowVersion desc
     * @param expand Return expand object. Join multiple objects with comma. Responses &gt; Example Value &gt; Object name within the \&quot;value\&quot; object are the supported expand object(s).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataRaceGetracelistGet(apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, count?: boolean, top?: number, skip?: number, filter?: string, select?: string, orderby?: string, expand?: string, observe?: 'body', reportProgress?: boolean): Observable<RaceExpandModelCustomODataResult>;
    public odataRaceGetracelistGet(apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, count?: boolean, top?: number, skip?: number, filter?: string, select?: string, orderby?: string, expand?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RaceExpandModelCustomODataResult>>;
    public odataRaceGetracelistGet(apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, count?: boolean, top?: number, skip?: number, filter?: string, select?: string, orderby?: string, expand?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RaceExpandModelCustomODataResult>>;
    public odataRaceGetracelistGet(apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, count?: boolean, top?: number, skip?: number, filter?: string, select?: string, orderby?: string, expand?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('$count', <any>count);
        }
        if (top !== undefined && top !== null) {
            queryParameters = queryParameters.set('$top', <any>top);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('$skip', <any>skip);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('$filter', <any>filter);
        }
        if (select !== undefined && select !== null) {
            queryParameters = queryParameters.set('$select', <any>select);
        }
        if (orderby !== undefined && orderby !== null) {
            queryParameters = queryParameters.set('$orderby', <any>orderby);
        }
        if (expand !== undefined && expand !== null) {
            queryParameters = queryParameters.set('$expand', <any>expand);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionId !== undefined && subscriptionId !== null) {
            headers = headers.set('subscriptionId', String(subscriptionId));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('x-Signature', String(xSignature));
        }
        if (formCode !== undefined && formCode !== null) {
            headers = headers.set('formCode', String(formCode));
        }
        if (cultureCode !== undefined && cultureCode !== null) {
            headers = headers.set('cultureCode', String(cultureCode));
        }
        if (clientDate !== undefined && clientDate !== null) {
            headers = headers.set('clientDate', String(clientDate));
        }
        if (clientTimeZone !== undefined && clientTimeZone !== null) {
            headers = headers.set('clientTimeZone', String(clientTimeZone));
        }
        if (autoFlag !== undefined && autoFlag !== null) {
            headers = headers.set('autoFlag', String(autoFlag));
        }

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RaceExpandModelCustomODataResult>('get',`${this.basePath}/odata/race/getracelist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
