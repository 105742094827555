import { CatgAttrOptionResponse } from './../../../../swagger/cxmbackend/openapi/model/catgAttrOptionResponse';
import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LookDropInputComponent } from "../../../shared/component/input/look-drop-input/look-drop-input.component";
import { MerchantListingComponent } from "../../merchant/merchant-listing/merchant-listing.component";
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { StoreService } from "src/app/cxm-backend-web/store/store.service";
import { FormService } from 'src/app/shared/component/form/form.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';

@Component({
  selector: 'app-lookup-catg-attr-options',
  standalone: true,
  templateUrl: './lookup-catg-attr-options.component.html',
  styleUrls: ['./lookup-catg-attr-options.component.scss'],
  imports: [CommonModule, DialogModule, TranslateModule, LookDropInputComponent, MerchantListingComponent]
})
export class LookupCatgAttrOptionsComponent {
  lookDropInputSetting: LookDropInputSetting;
  formGroup: UntypedFormGroup;
  formData: any;
  rowData: any;
  formDetail: any = null;

  colDef: ColDef[] = [
    {
      field: 'attrOptionId',
      headerName: 'Attribute ID',
      sortable: true,
    },
    {
      field: 'attrCode',
      headerName: 'Attribute Code',
      sortable: true,
    },
    {
      field: 'attrOptionDesc',
      headerName: 'Attribute Description',
      sortable: true
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
    { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1 }
  ]};

  constructor(
    private storeService: StoreService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService,
  ) {}

  ngOnInit(): void {
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'attrOptionId',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : 'attrOptionId',
      colDef: this.lookDropInputSetting.colDef ? this.lookDropInputSetting.colDef: this.colDef,
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey: 'attrOptionDesc',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : true,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling? this.lookDropInputSetting.styling: {},
      dependency: this.lookDropInputSetting.dependency? this.lookDropInputSetting.dependency: null
    }
    this.formDetail = this.formService.getFormDetail(FormCode.catgattroptionlisting);
  }

  async getCatgAttrOption(){
    let result = await this.storeService.getCatgAttrOption(null, this.formDetail.formCode);
    if (!(result instanceof HttpErrorResponse)) {
      return result['value'];
    }
    return null;
  }

  async onLookup(data: any) {
    this.rowData = await this.getCatgAttrOption();
  }

  async onAutoComplete(autoCompleteVal: any) {
    this.rowData = await this.getCatgAttrOption();
  }

  async onLookupToolbarAction(data: any) {
    if(data.name === 'filter') {
      let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(data.data, filterBy);
      this.rowData = await this.getCatgAttr(filter);
    }
  }

  async getCatgAttr(filter?: string) {
    let data: any = {filter: filter?? ""};
    let result = await this.storeService.getCatgAttrOption(data, this.formDetail.formCode);
    if (!(result instanceof HttpErrorResponse)) {
      return result['value'];
    }
    return null;
  }
}
