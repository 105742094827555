import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import FULLTIMEZONE from 'src/app/shared/json/full-time-zone.json';
import { TimeZone } from 'src/app/shared/enums/timeZone';
import { ColDef, IRowNode } from 'ag-grid-community';

@Component({
  selector: 'app-dropdown-timezone',
  standalone: true,
  imports: [CommonModule, LookDropInputComponent],
  templateUrl: './dropdown-timezone.component.html',
  styleUrls: ['./dropdown-timezone.component.scss']
})
export class DropdownTimezoneComponent implements OnInit {
  lookDropInputSetting: LookDropInputSetting;
  formGroup: UntypedFormGroup;
  formData: any;
  colDef: ColDef[];
  autoCompleteData: any;
  timezoneData: any;
  multiSelectData: any;
  selectedNodes: {node?: IRowNode, value?: any}[] = [];

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    // Set column
    this.colDef = [{ field: 'name' }];

    // Custom Setting
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'timezone',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : 'value',
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey : 'name',
      colDef: this.lookDropInputSetting.colDef? this.lookDropInputSetting.colDef: this.colDef,
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : 'Timezone',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling? this.lookDropInputSetting.styling: {},
      dependency: this.lookDropInputSetting.dependency? this.lookDropInputSetting.dependency: null
    }

    // Get data
    this.lookDropInputSetting.dropdownOption = this.utilsService.formatDropdownJSON(FULLTIMEZONE.TIMEZONE, `${TimeZone.CountryRegion}|${TimeZone.UTC}`, TimeZone.Timezone);
  }

  onSelected(selectedNodes: IRowNode[]) {
    if(this.lookDropInputSetting?.multiSelect) {
      // set display value
      this.multiSelectData = selectedNodes.map((node: IRowNode) => {return {node: node, value: node.data.name}});
    }
  }
}
