import { Injectable } from '@angular/core';
import { CustomRequest } from '../models/local/CustomRequest';
import { HttpMethod } from '../enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from './custom.service';
import { SubscriptionService } from './subscription.service';
import { UserRepository } from 'src/app/state/user.repository';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { UserProfile } from 'src/swagger/base-rms/openapi/model/userProfile';
import { SubscriptionUserService as SubscriptionUserAPIService } from 'src/swagger/base-rms/openapi';
import { CustomHeader } from '../models/local/CustomHeader';
import { RMSSubscriptionInfoResponse } from '../models/local/RMSSubscriptionInfoResponse';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageService } from 'src/app/shared/services/page.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionUserService {
  subscrip$: Observable<RMSSubscriptionInfoResponse>;
  user$: Observable<UserProfile>;

  selectedSub: RMSSubscriptionInfoResponse;
  user: UserProfile = null;
  curHttpHeader: CustomHeader;

  constructor(
    private customService: CustomService,
    private subscriptionService: SubscriptionService,
    private userRepository: UserRepository,
    private subscriptionUserAPIService: SubscriptionUserAPIService,
    private pageService: PageService
  ) {
    this.subscrip$ = this.subscriptionService.getSelectedSubscriptionRepo();
    this.user$ = this.userRepository.user$;

    combineLatest([this.subscrip$, this.user$]).pipe(
      map(([subscrip, user]) => {
        this.user = user;
        this.selectedSub = subscrip;
      })
    ).subscribe();
  }

  async initSubscriptionUser() {
    if (this.selectedSub && !this.user && this.pageService.isLogin) {
      this.getMyProfile();
    }
  }

  getMyProfile() {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.subscriptionUser.getmyprofile,
      hostPath: this.selectedSub.apiUrl4,
      headers: {
        subscriptionId: this.selectedSub.subscriptionId
      },
    };

    this.subscriptionUserAPIService.apiSubscriptionuserGetmyprofileGet(
      this.customService.curHttpHeader.apiVersion,
      this.selectedSub.subscriptionId,
      this.customService.assignHMac(cusReq),
      undefined,
      'en',
      this.customService.curHttpHeader.clientDate,
      this.customService.curHttpHeader.clientTimeZone,
      undefined,
    ).subscribe(userProfile => {
      this.userRepository.updateUser(userProfile);
    });
  }

  async updateMyProfile(userProfile: UserProfile) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.subscriptionUser.updatemyprofile,
      hostPath: this.selectedSub.apiUrl4,
      body: userProfile
    };

    // this.subscriptionUserAPIService.apiSubscriptionuserUpdatemyprofilePost(
    //   userProfile,
    // );

    let respInfo: any = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  async getSysUserList(formCode: FormCode, filter?: string, top?: number, orderBy?: string) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.subscriptionUser.getsysuserlist,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      } as CustomHeader,
      httpHeaderType: undefined,
      queryParams: {
        $count: true,
        $filter: filter? filter: undefined,
        $top: top? top: undefined,
        $orderby: orderBy? orderBy: undefined
      }
    };

    let respInfo: any = null;
    respInfo = await this.reqCustomHttpCall(cusReq);

    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  postAgreeterms() {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.subscriptionUser.agreeterms,
      hostPath: this.selectedSub.apiUrl4,
      headers: {
        subscriptionId: this.selectedSub.subscriptionId
      },
    };

    return this.subscriptionUserAPIService.apiSubscriptionuserAgreetermsPost(
      this.customService.curHttpHeader.apiVersion,
      this.selectedSub.subscriptionId,
      this.customService.assignHMac(cusReq),
      undefined,
      'en',
      this.customService.curHttpHeader.clientDate,
      this.customService.curHttpHeader.clientTimeZone,
      undefined,
    );
  }
}
