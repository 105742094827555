import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { AuthService } from 'src/app/core/services/auth.service';
import { InvoiceService } from '../invoice.service';
import { Page } from 'src/app/shared/models/page.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { PageService } from 'src/app/shared/services/page.service';
import { UtilsService, decodeBase64, formatDateString } from 'src/app/core/services/utils.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { CustomService } from 'src/app/core/services/custom.service';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { environment } from 'src/environments/environment';
import { InvoiceService as InvoiceServiceTwo } from 'src/swagger/cxmbackend/openapi';
import { CreateInvoiceRequest } from 'src/swagger/cxmbackend/openapi/model/createInvoiceRequest';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { InvoiceActionComponent } from '../invoice-action/invoice-action.component';
import { ColDef, ExcelStyle, GridApi } from 'ag-grid-community';
import { NgClass, NgIf } from '@angular/common';
import { MessageComponent } from 'src/app/shared/component/message/message.component';
import { ActionToolBarComponent } from 'src/app/shared/component/action-tool-bar/action-tool-bar.component';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import * as moment from 'moment';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { InvoiceStatusFlag } from 'src/swagger/cxmbackend/openapi/model/invoiceStatusFlag';
import { InvoiceService as InvoiceAPIService } from 'src/swagger/cxmbackend/openapi';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { ConfirmDialogComponent } from 'src/app/shared/component/confirm-dialog/confirm-dialog.component';
import { LookupStoreComponent } from '../../lookup/lookup-store/lookup-store.component';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-invoice-entry',
  templateUrl: './invoice-entry.component.html',
  styleUrls: ['./invoice-entry.component.scss'],
  standalone: true,
  imports: [NgIf, FormComponent, NgClass, MessageComponent, ActionToolBarComponent, ListingComponent],
  providers: [InvoiceAPIService]
})
export class InvoiceEntryComponent implements OnInit {
  formSetting: FormSetting = {
    formCode: FormCode.invoiceentry,
    content: [
      {
        inputText: {
          name: 'startDate',
          label: 'Start Date',
          type: InputTypes.date,
          required: true,
          styling: {
            width: '60%'
          },
          editable: false
        }
      },
      {
        inputText: {
          name: 'endDate',
          label: 'End Date',
          type: InputTypes.date,
          required: true,
          styling: {
            width: '60%'
          },
          editable: false
        }
      },
      {
        toggleInput: {
          name: 'regenerateInvoice',
          label: 'Regenerate Invoice',
        }
      },
      // {
      // toggleInput: {
      //   name: 'generateDataFile',
      //   label: 'Generate Data File',
      // }
      // },
      // {
      //   toggleInput: {
      //     name: 'sendToMerchant',
      //     label: 'Send To Merchant',
      //   }
      // },
      {
        lookDropInput: {
          componentName: LookupStoreComponent,
          label: 'Store ID',
          name: 'storeId',
          valueKey: 'storeId',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
          editable: false,
        },
      },
      {
        inputNumber: {
          name: 'statementId',
          label: 'Statement Id',
          required: false,
          styling: {
            width: '60%'
          }
        }
      },
      // {
      //   inputText: {
      //     name: 'invoiceNo',
      //     label: 'Invoice Number(For Regenerate Purposes)',
      //     type: InputTypes.number,
      //     required: false,
      //     styling: {
      //       width: '60%'
      //     }
      //   }
      // },
      // {
      //   inputText: {
      //     name: 'email',
      //     label: 'Recipient Email',
      //     type: InputTypes.text,
      //     required: false,
      //     styling: {
      //       width: '60%'
      //     },
      //   }
      // }
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  page: Page;
  formDetail: any = null;
  params: [] = [];
  invoiceId: number = undefined;
  addressId: number = undefined;
  invoiceData: any;
  attrCode: any;
  private gridApi!: GridApi;
  rowData: any = null;
  formCode: any = FormCode;
  isRowSelected: boolean = false;
  invoiceStatuses: any = InvoiceStatusFlag;
  listingSetting: ListingSetting;


  excelStyles: ExcelStyle[] = [
    {
      id: 'numberType',
      numberFormat: {
        format: '0',
      },
    },
    {
      id: 'currencyFormat',
      numberFormat: {
        format: '#,##0.00',
      },
      alignment: {
        horizontal: "Right"
      }
    },
    {
      id: 'currencyTypeFormat',
      alignment: {
        horizontal: "Center"
      }
    },
    {
      id: 'percentageFormat',
      numberFormat: {
        format: '0.000',
      },
    },
    {
      id: 'booleanType',
      dataType: 'Boolean',
    },
    {
      id: 'stringType',
      dataType: 'String',
    },
    {
      id: 'dateType',
      dataType: 'DateTime',
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: 'header',
      font: {
        bold: true,
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {

      id: 'stringCenterType',
      dataType: 'String',
      alignment: {
        horizontal: 'Center',
      },
    },

  ];


 // Column data
 colDef: ColDef[] = [
  {
    ...listingCheckbox,
    headerName: 'NO',
    cellClass: "stringCenterType"
  },
  {
    ...listingActionButton,
    cellRendererParams: {
      type: ButtonRendererTypeEnum.duplicate
    },
    hide: true
  },
  {
    ...listingRouterLink,
    field: 'invoiceNo',
    headerName: 'Invoice number',
    cellRendererParams: {
      inRouterLink: '/invoice/form',
      params: ['invoiceId']
    },
    sortable: true,
    hide: true
  },
  {
    ...listingDropdown,
    field: 'statusFlag',
    headerName: 'Status',
    cellEditorParams: {
      options: Object.keys(this.invoiceStatuses).map(key => this.invoiceStatuses[key])
    },
    hide: true
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice date',
    valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
    filter: true,
    hide: true
  },
  {
    field: 'startDate',
    headerName: 'DATE',
    valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
    filter: true,
    cellClass: "stringCenterType"
  },
  {
    field: 'endDate',
    headerName: 'End date',
    valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
    filter: true,
    hide: true
  },
  {
    field: 'merchantDesc',
    headerName: 'Merchant Name',
    filter: true,
    hide: true
  },
  {
    field: 'storeDesc',
    headerName: 'Store Desc',
    filter: true,
    hide: true
  },
  {
    field: 'tranCount',
    headerName: 'Transaction count',
    filter: true,
    hide: true
  },
  {
    field: 'merchantDiscAmt',
    headerName: 'Merchant funded discount amount',
    filter: true,
    hide: true
  },
  {
    field: 'platformDiscAmt',
    headerName: 'Platform funded discount amount',
    filter: true,
    hide: true
  },
  {
    field: 'collectionAmt',
    headerName: 'Collection amount',
    filter: true,
    hide: true
  },
  {
    field: 'merchantTaxAmt',
    headerName: 'Merchant tax amount',
    filter: true,
    hide: true
  },
  {
    field: 'merchantAdjAmt',
    headerName: 'Merchant adjustment amount',
    filter: true,
    hide: true
  },
  {
    field: 'revenueAmt',
    headerName: 'Revenue amount',
    filter: true,
    hide: true
  },
  {
    field: 'commSalesAmt',
    headerName: 'Commission sales amount',
    filter: true,
    hide: true
  },
  {
    field: 'commPerc',
    headerName: 'Commission percentage',
    filter: true,
    hide: true
  },
  {
    field: 'CommAmt',
    headerName: 'Commission amount',
    filter: true,
    hide: true
  },
  {
    field: 'CommTaxAmt',
    headerName: 'Commission tax amount',
    filter: true,
    hide: true
  },
  {
    field: 'bankAccountName',
    headerName: 'NAME OF BENEFICIARY',
    filter: true,
    cellClass: "stringType"
  },
  {
    field: 'payableAmt',
    headerName: 'AMOUNT (MYR)',
    filter: true,
    cellClass: "currencyFormat"
  },
  {
    field: 'salesAdjAmt',
    headerName: 'Sales adjustment amount',
    filter: true,
    hide: true
  },
  {
    field: 'subscriptionAmt',
    headerName: 'Subscription amount',
    filter: true,
    hide: true
  },
  {
    field: 'netPayableAmt',
    headerName: 'Net payable amount',
    filter: true,
    hide:true
  },
  {
    field: 'outputURL',
    headerName: 'Output URL',
    filter: true,
    hide: true
  },
  {
    field: 'versionNo',
    headerName: 'Version no.',
    filter: true,
    hide: true
  },
  {
    field: 'paymentPlatformCode',
    headerName: 'Payment Platform',
    filter: true,
    hide: true
  },
  {
    field: 'currency',
    headerName: 'Curreny Type',
    filter: true,
    hide: true,
  },
  {
    field: 'payRefNoString',
    headerName: 'Payment References',
    filter: true,
    hide: true
  },
  {
    field: 'bankCode',
    headerName: 'BANK CODE',
    filter: true,
    cellClass: "stringCenterType"
  },
  {
    field: 'bankAccountNo',
    headerName: 'ACCOUNT NUMBER',
    filter: true,
    cellClass: "stringCenterType"
  },

  {
    field: 'email',
    headerName: 'BENEFICIARY EMAIL',
    filter: true,
    cellClass: "stringType"
  },
  {
    field: 'mobileNo',
    headerName: 'Mobile No',
    filter: true,
    hide: true
  },
  {
    field: 'lastRevDate',
    headerName: 'Last revised date',
    filter: true,
    hide: true
  }
];

  constructor(private authService: AuthService,
    private invoiceService: InvoiceService,
    private invoiceServiceTwo: InvoiceServiceTwo,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private customService: CustomService,
    private utilsService: UtilsService,
    private loaderService: LoaderService,
    private formService: FormService) { }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide
    };
    // Get route parameter
    if(this.page) {
      let listingId = this.pageService.getListingId(this.page);
      this.invoiceId = +decodeBase64(decodeURIComponent(listingId));
    }

    //TBC
    // this.formSetting  = {
    //   formCode: FormCode.invoiceentry,
    //   content: [
    //     {
    //       inputText: {
    //         name: 'startDate',
    //         label: 'Start Date',
    //         type: InputTypes.date,
    //         required: true,
    //         styling: {
    //           width: '60%'
    //         },
    //         editable: false
    //       }
    //     },
    //     {
    //       inputText: {
    //         name: 'endDate',
    //         label: 'End Date',
    //         type: InputTypes.date,
    //         required: true,
    //         styling: {
    //           width: '60%'
    //         },
    //         editable: false
    //       }
    //     },
    //     {
    //       inputText: {
    //         name: 'storeId',
    //         label: 'Store Id',
    //         type: InputTypes.number,
    //         required: false,
    //         styling: {
    //           width: '60%'
    //         },
    //       }
    //     },
    //     {
    //       inputText: {
    //         name: 'statementId',
    //         label: 'Statement Id',
    //         type: InputTypes.number,
    //         required: false,
    //         styling: {
    //           width: '60%'
    //         }
    //       }
    //     },
    //     {
    //       inputText: {
    //         name: 'invoiceId',
    //         label: 'Invoice Id',
    //         type: InputTypes.number,
    //         required: false,
    //         styling: {
    //           width: '60%'
    //         }
    //       }
    //     },
    //     {
    //       inputText: {
    //         name: 'email',
    //         label: 'Recipient Email',
    //         type: InputTypes.text,
    //         required: false,
    //         styling: {
    //           width: '60%'
    //         },
    //       }
    //     },
    //   ]
    // }
  }

  onGridApiUpdate($event) {
    this.gridApi = $event;

  }
  async getInvoice(data?: ODataRawQueryOptions) {
    let result = await this.invoiceService.getOdataInvoice(data);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.invoiceData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp){}

  async onSubmitForm(formGroup: UntypedFormGroup){
    let result  = null;
    let reqBody: CreateInvoiceRequest = {
      startDate: formatDateString(formGroup.get('startDate').value),
      endDate: formatDateString(formGroup.get('endDate').value),
      storeId: formGroup.get('storeId').value,
      statementIds: formGroup.get('statementId').value? [formGroup.get('statementId').value] : null,
      regen: formGroup.get('regenerateInvoice').value,
    }

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.invoice.CreateInvoice,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);
    this.loaderService.startLoading();
    result = await this.invoiceService.createInvoice(reqBody, this.formDetail.formCode);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false || result.error.detail == 'Invoice Conflict') {
      if (result.error.detail == 'Invoice Conflict'){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
              title: "Generate Invoice",
              message: result.error.title + 'Would you like to remove all the conflicted invoices?',
            }

            });
            dialogRef.afterClosed().subscribe(async dialogResult => {
              if (dialogResult === true)
              {
                let endDate = new Date(reqBody.endDate);
                let getEndDate = new Date(endDate.setDate(endDate.getDate() + 1)).toISOString();
                let queryOpts: ODataRawQueryOptions = {
                  filter: 'startDate lt ' + getEndDate + ' and enddate gt ' + reqBody.startDate
                };
                let deleteInvoices = await this.invoiceService.getOdataInvoice(queryOpts, this.formDetail.formCode);
                if (deleteInvoices instanceof HttpErrorResponse === false && deleteInvoices['value'].length > 0) {
                  let invoiceIds = deleteInvoices['value'].map(a => a.invoiceId);
                  let deletedInvoices = await this.invoiceService.deleteMultiInvoice(invoiceIds, this.formDetail.formCode);
                  if (deletedInvoices instanceof HttpErrorResponse === false && deletedInvoices > 0) {
                    let msg: PopupMessage = {
                      formCode: FormCode.invoicelisting,
                      id: 'failed',
                      titleIcon: 'assets/base/icons/check-circle.svg',
                      title: 'msg.delete.conflicted.invoice.successful',
                      desc: 'msg.success.delete.conflicted.invoice',
                      actionBtnText: 'btn.back'
                    };

                    this.dialog.open(PopupMessageComponent, {
                      data: msg
                    });
                  }
                  else{
                    let msg: PopupMessage = {
                      formCode: FormCode.invoicelisting,
                      id: 'failed',
                      titleIcon: 'assets/base/icons/exclamation-circle.svg',
                      title: 'msg.delete.conflicted.invoice.unsuccessful',
                      desc: 'msg.delete.invoice.unsuccessful',
                      actionBtnText: 'btn.back'
                    };

                    this.dialog.open(PopupMessageComponent, {
                      data: msg
                    });
                  }
                }
              }

            });

      }
      else{
        this.popupFormService.close(this.page.form.formCode);
        let msg: PopupMessage = {
          formCode: FormCode.invoicelisting,
          id: 'failed',
          titleIcon: 'assets/base/icons/exclamation-circle.svg',
          title: 'msg.new.invoice.unsuccessful',
          desc: result.error.title,
          actionBtnText: 'btn.back'
        };

        this.dialog.open(PopupMessageComponent, {
          data: msg
        });
      }
    }
    else {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.merchantlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.new.invoice.saved',
        desc: "msg.added.new.invoice",
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
    //result = await this.invoiceService.createInvoiceProvider(reqBody);
  }

  onBtExport() {
    var params = this.getParams();
    // this.gridApi.exportDataAsCsv(params);
    this.gridApi.exportDataAsExcel(params);
  }
  getParams() {
    return {
      fileName: 'Payout ' + moment(new Date).format("DD-MMM-YYYY")
    };
  }
}
