<!-- Input Text -->
<ng-template [ngIf]="content?.inputText">
  <app-input-text [inputTextSetting]="content.inputText" [formGroup]="formGrp" [formData]="formData"></app-input-text>
</ng-template>

<!-- Input Number -->
<ng-template [ngIf]="content?.inputNumber">
  <app-input-number [inputNumberSetting]="content.inputNumber" [formGroup]="formGrp" [formData]="formData"></app-input-number>
</ng-template>

<!-- Text Area -->
<ng-template [ngIf]="content?.textArea">
  <app-text-area [textAreaSetting]="content.textArea" [formGroup]="formGrp" [formData]="formData"></app-text-area>
</ng-template>

<!-- Lookup / Dropdown Input -->
<ng-template [ngIf]="content?.lookDropInput">
  <app-lookdrop [lookDropInputSetting]="content.lookDropInput" [formGroup]="formGrp" [formData]="formData"></app-lookdrop>
</ng-template>

<!-- Toggle Input -->
<ng-template [ngIf]="content?.toggleInput">
  <app-toggle-input [toggleInputSetting]="content.toggleInput" [formGroup]="formGrp" [formData]="formData"
  (onToggleChange)="onToggleChg($event)"></app-toggle-input>
</ng-template>

<!-- Auto Generate Input -->
<ng-template [ngIf]="content?.autoGenerateInput">
  <app-auto-generate-input [autoGenerateInputSetting]="content.autoGenerateInput" [formGroup]="formGrp" [formData]="formData"
    (onGenerateInput)="onGenerate($event)"></app-auto-generate-input>
</ng-template>

<!-- Upload Input -->
<ng-template [ngIf]="content?.uploadInput">
  <app-upload-input [uploadSetting]="content.uploadInput" [formGroup]="formGrp" [formData]="formData"></app-upload-input>
</ng-template>

<!-- Date Input -->
<ng-template [ngIf]="content?.dateInput">
  <app-date-picker [inputDateSetting]="content.dateInput" [formGroup]="formGrp" [formData]="formData"
    (updateDate)="onDateChg($event)"></app-date-picker>
</ng-template>

<!-- Date Range Input -->
<ng-template [ngIf]="content?.dateRangeInput">
  <app-date-picker-range [inputDateSetting]="content.dateRangeInput" [formGroup]="formGrp" [formData]="formData"
    (updateDate)="onDateChg($event)"></app-date-picker-range>
</ng-template>

<!-- Time Input -->
<ng-template [ngIf]="content?.timeInput">
  <app-time-picker [timeSetting]="content.timeInput" [formGroup]="formGrp" [formData]="formData"
    (onTimeChange)="onTimeChg($event)"></app-time-picker>
</ng-template>

<!-- Button Toggle -->
<ng-template [ngIf]="content?.buttonToggle">
  <app-button-toggle [buttonToggleSetting]="content.buttonToggle" [formGroup]="formGrp"  [formData]="formData"
    (selectionChange)="onBtnToggle($event)"></app-button-toggle>
</ng-template>

<!-- Period Input -->
<ng-template [ngIf]="content?.periodInput">
  <app-period-input [periodSetting]="content.periodInput" [formGroup]="formGrp" [formData]="formData"
    (periodChange)="onPeriodChg($event)"></app-period-input>
</ng-template>
