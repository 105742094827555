import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { InputTypes } from '../input/enum/InputTypes';
import { FormContent } from './model/FormContent.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { InputPattern } from '../../enums/inputPattern';
import { environment } from 'src/environments/environment';
import { form } from 'src/app/core/models/form';
import demoSetData from './../../../project-demo/demo-setting.json';
import frameworkMenu from './../../../project-demo/framework-setting.json';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  settingData = {
    menus: [
      // {
      //   id: 2,
      //   textId: '',
      //   caption: 'Favorites',
      //   formCode: 'favorites',
      //   sortOrder: 0,
      //   icon: 'star-solid.svg',
      //   filePath: 'favorites',
      //   badge: null,
      //   queryString: null,
      //   isFavourite: '',
      //   menuItems: [],
      // },
      // FOR CXM BACKEND
      {
        id: 3,
        textId: '',
        caption: 'MasterForms',
        formCode: null,
        sortOrder: 0,
        icon: 'dashboard.svg',
        filePath: null,
        badge: null,
        queryString: null,
        isFavourite: '',
        menuItems: [
          {
            id: 201,
            textId: '',
            caption: "Product",
            formCode: 'product',
            sortOrder: 1,
            icon: null,
            filePath: null,
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [
              {
                id: 301,
                textId: '',
                caption: 'Product Item',
                formCode: 'productItem',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: true,
                menuItems: [],
              },
              {
                id: 302,
                textId: '',
                caption: 'Product Category',
                formCode: 'productCategory',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 303,
                textId: '',
                caption: 'Product Brand Group',
                formCode: 'productBrandGroup',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 304,
                textId: '',
                caption: 'Product Brand',
                formCode: 'productBrand',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 305,
                textId: '',
                caption: 'Product Class',
                formCode: 'productClass',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 306,
                textId: '',
                caption: 'Product Season',
                formCode: 'productSeason',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 307,
                textId: '',
                caption: 'Product Department',
                formCode: 'productDepartment',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 308,
                textId: '',
                caption: 'Product Color',
                formCode: 'productColor',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 309,
                textId: '',
                caption: 'Product Size',
                formCode: 'productSize',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 310,
                textId: '',
                caption: 'Product Attribute',
                formCode: 'productAttribute',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 311,
                textId: '',
                caption: 'Product UOM',
                formCode: 'productUOM',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 312,
                textId: '',
                caption: 'Product Code Configuration',
                formCode: 'productCode Configuration',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 313,
                textId: '',
                caption: 'Product Modifier',
                formCode: 'productModifier',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 314,
                textId: '',
                caption: 'Product Group',
                formCode: 'productGroup',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 315,
                textId: '',
                caption: 'Product UDG',
                formCode: 'productUDG',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 316,
                textId: '',
                caption: 'Product UD1',
                formCode: 'productUD1',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: true,
                menuItems: [],
              },
              {
                id: 317,
                textId: '',
                caption: 'Product UD2',
                formCode: 'productUD2',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 318,
                textId: '',
                caption: 'Product UD3',
                formCode: 'productUD3',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 319,
                textId: '',
                caption: 'Product UD4',
                formCode: 'productUD4',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 320,
                textId: '',
                caption: 'Product UD5',
                formCode: 'productUD5',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 321,
                textId: '',
                caption: 'Product UD6',
                formCode: 'productUD6',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 322,
                textId: '',
                caption: 'Product UD7',
                formCode: 'productUD7',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 323,
                textId: '',
                caption: 'Product UD8',
                formCode: 'productUD8',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 324,
                textId: '',
                caption: 'Product UD9',
                formCode: 'productUD9',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
              {
                id: 325,
                textId: '',
                caption: 'Product UD10',
                formCode: 'productUD10',
                sortOrder: 1,
                icon: null,
                filePath: null,
                badge: null,
                queryString: null,
                isFavourite: '',
                menuItems: [],
              },
            ],
          },
          {
            id: 201,
            textId: '',
            caption: 'Store',
            formCode: 'storelisting',
            sortOrder: 2,
            icon: null,
            filePath: 'store/listing',
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [],
          },
          {
            id: 605,
            textId: '',
            caption: 'Merchant',
            formCode: 'merchantlisting',
            sortOrder: 3,
            icon: null,
            filePath: 'merchant/listing',
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [],
          },
          {
            id: 605,
            textId: '',
            caption: 'Channel',
            formCode: 'channellisting',
            sortOrder: 3,
            icon: null,
            filePath: 'channel/listing',
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [],
          },
          {
            id: 605,
            textId: '',
            caption: 'Tax',
            formCode: 'taxlisting',
            formCaption: 'Tax',
            sortOrder: 3,
            icon: null,
            filePath: 'tax/taxlisting',
            badge: null,
            queryString: null,
            type: 'LINK',
            menuItems: [],
          },
          {
            id: 605,
            caption: null,
            formCode: 'dealerlisting',
            formCaption: 'Dealer',
            sortOrder: 3,
            icon: null,
            filePath: 'dealer/listing',
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [],
          },
          {
            id: 605,
            caption: null,
            formCode: 'dealerlisting',
            formCaption: 'Dealer',
            sortOrder: 3,
            icon: null,
            filePath: 'dealer/listing',
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [],
          },
        ],
      },
      {
        id: 4,
        textId: '',
        caption: 'StatementListing',
        formCode: 'stmtlisting',
        sortOrder: 0,
        icon: 'file-bar-chart-solid.svg',
        filePath: 'statement/listing',
        badge: null,
        queryString: null,
        isFavourite: '',
        menuItems: [],
      },
      {
        id: 5,
        textId: '',
        caption: 'CurrencyListing',
        formCode: 'currencylisting',
        sortOrder: 0,
        icon: 'file-bar-chart-solid.svg',
        filePath: 'currency/listing',
        badge: null,
        queryString: null,
        isFavourite: '',
        menuItems: [],
      },
      {
        id: 6,
        textId: '',
        caption: 'Reporting',
        formCode: 'reportlisting',
        sortOrder: 0,
        icon: 'file-bar-chart-solid.svg',
        filePath: null,
        badge: null,
        queryString: null,
        isFavourite: '',
        menuItems: [
          {
            id: 60,
            textId: 'general.sales',
            caption: 'Sales',
            formCode: 'sales',
            sortOrder: 0,
            icon: 'sales.svg',
            filePath: null,
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [
              {
                id: 601,
                textId: 'general.rptsalesdetailsv2',
                isFavourite: true,
                formCode: 'rptsalesdetailsv2',
                caption: 'Sales Details Report',
                sortOrder: null,
                icon: null,
                filePath: 'report/rptsalesdetailsv2',
                badge: 'preview',
                queryString: null,
                menuItems: [],
              },
              {
                id: 602,
                textId: 'general.rptsalessummaryv2',
                isFavourite: false,
                formCode: 'rptsalessummaryv2',
                caption: 'Sales Summary Report',
                sortOrder: null,
                icon: null,
                filePath: 'report/rptsalessummaryv2',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 603,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Daily Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 604,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Product Sales Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 605,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Tax & Charges Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 606,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Sales Discount Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 607,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Sales Payment Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 608,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Bill Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 609,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Table Turnover Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 610,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Hourly Sales Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 611,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Sales Person Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 612,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'POS Audit Log',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 613,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Session Sales Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 614,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Attendance Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 615,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'Sales Exception Listing (Cancelled Sales Listing)',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 616,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalessummary',
                caption: 'POS Application Log Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 617,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalessummary',
                caption: 'Commission Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 618,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalessummary',
                caption: 'Voucher Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 619,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalessummary',
                caption: 'Cash Management Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 620,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalessummary',
                caption: 'Tax & Charges Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 621,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalessummary',
                caption: 'Sales Discount Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 622,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalessummary',
                caption: 'Sales Payment Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
            ]
          },
          {
            id: 61,
            textId: '',
            formCode: 'inventory',
            caption: 'Inventory',
            sortOrder: 0,
            icon: 'inventory.svg',
            filePath: null,
            badge: null,
            queryString: null,
            isFavourite: '',
            menuItems: [
              {
                id: 601,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: 'preview',
                queryString: null,
                menuItems: [],
              },
              {
                id: 602,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Closing Stock Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 603,
                textId: '',
                isFavourite: true,
                formCode: 'rptsalesdetailsv2',
                caption: 'Item Transaction Details Listing (prev. Stock Movement)',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: 'preview',
                queryString: null,
                menuItems: [],
              },
              {
                id: 604,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Purchase Order Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 605,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Purchase Order Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 606,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Goods Receive Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 607,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Good Receive Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 608,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Good Receive Discrepancy Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 609,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Good Receive Discrepancy Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 610,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Adjustment Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 611,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Adjustment Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 612,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Request Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 613,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Request Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 614,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Purchase Return Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 615,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Purchase Return Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 616,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Transfer Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 617,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Transfer Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 618,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Transfer Discrepancy Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 619,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Transfer Discrepancy Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 620,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Take Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 621,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Stock Take Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 622,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Sales Order Summary Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 623,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Supplier Item Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
              {
                id: 624,
                textId: '',
                isFavourite: false,
                formCode: 'rptsalesdetailsv2',
                caption: 'Sales Order Details Listing',
                sortOrder: null,
                icon: null,
                filePath: '',
                badge: null,
                queryString: null,
                menuItems: [],
              },
            ]
          }
        ],
      },
      {
        id: 7,
        caption: 'InvoiceListing',
        formCode: 'invoicelisting',
        formCaption: 'Invoice Listing',
        sortOrder: 0,
        icon: 'file-bar-chart-solid.svg',
        filePath: 'invoice/listing',
        badge: null,
        queryString: null,
        type: 'HEADER',
        menuItems: [],
      },
      {
        id: 8,
        caption: 'InvoiceEntry',
        formCode: 'invoiceentry',
        formCaption: 'Invoice Generator',
        sortOrder: 0,
        icon: 'file-bar-chart-solid.svg',
        filePath: 'invoice/entry',
        badge: null,
        queryString: null,
        type: 'HEADER',
        menuItems: [],
      },
      {
        id: 9,
        caption: 'OrderDetailListing',
        formCode: 'orderdetaillisting',
        formCaption: 'Order Detail Listing',
        sortOrder: 0,
        icon: 'file-bar-chart-solid.svg',
        filePath: 'order-management/listing',
        badge: null,
        queryString: null,
        type: 'HEADER',
        menuItems: [],
      },
      {
        id: 10,
        caption: 'Tax Forms',
        formCode: null,
        formCaption: 'Tax Forms',
        sortOrder: 0,
        icon: 'file-excel-sheet-solid.svg',
        filePath: null,
        badge: null,
        queryString: null,
        type: 'HEADER',
        menuItems: [
          {
            id: 605,
            caption: null,
            formCode: 'taxlisting',
            formCaption: 'Tax',
            sortOrder: 3,
            icon: null,
            filePath: 'tax/taxlisting',
            badge: null,
            queryString: null,
            type: 'LINK',
            menuItems: [],
          },
          {
            id: 606,
            caption: null,
            formCode: 'taxgrouplisting',
            formCaption: 'Tax Group',
            sortOrder: 3,
            icon: null,
            filePath: 'tax/taxgrouplisting',
            badge: null,
            queryString: null,
            type: 'LINK',
            menuItems: [],
          },
          {
            id: 607,
            caption: null,
            formCode: 'taxconditionhlisting',
            formCaption: 'Tax Condition',
            sortOrder: 3,
            icon: null,
            filePath: 'tax/taxconditionhlisting',
            badge: null,
            queryString: null,
            type: 'LINK',
            menuItems: [],
          },
        ],}
    ],
    forms: [
      {
        formCode: 'dashboard',
        formCaption: 'Dashboard',
        formTypeCode: null,
        filePath: 'dashboard',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
      //MERCHANT
      {
        formCode: 'merchantmaster',
        formCaption: 'Merchant Master',
        formTypeCode: 'M',
        filePath: 'merchant/master',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: ['merchantentry'],
      },
      {
        formCode: 'merchantentry',
        formCaption: 'Merchant Entry',
        formTypeCode: 'E',
        filePath: 'merchant/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['NEW', 'EDIT', 'VIEW'],
        childFormCodes: [],
      },
      {
        formCode: 'merchantlisting',
        formCaption: 'Merchant Listing',
        formTypeCode: 'L',
        filePath: 'merchant/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      //Store.*
      {
        formCode: 'storemaster',
        formCaption: 'Store Master',
        formTypeCode: 'M',
        filePath: 'store/master',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [
          'storeentry',
          'storeattrlisting',
          'storecatgattrlisting',
          'storeohlisting',
          'storechannelstorelisting',
          'storesetting',
          'storechannelstoretaxlisting'
        ],
      },
      {
        formCode: 'storeentry',
        formCaption: 'Store Entry',
        formTypeCode: 'E',
        filePath: 'store/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'storeattrlisting',
        formCaption: 'Attribute',
        formTypeCode: 'L',
        filePath: 'store/attrlisting',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'storeohlisting',
        formCaption: 'Operating Hour',
        formTypeCode: 'L',
        formTextId: '',
        icon: null,
        filePath: 'store/ohlisting',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'storeattrentry',
        formCaption: 'Store Attribute',
        formTypeCode: 'E',
        filePath: 'store/storeattr/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'storecatgattrlisting',
        formCaption: 'Store Category Attribute',
        formTypeCode: 'L',
        filePath: 'store/storecatgattrlisting',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'storecatgattrentry',
        formCaption: 'Store Category Attribute',
        formTypeCode: 'E',
        formTextId: '',
        icon: null,
        filePath: 'store/storecatgattr/entry',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'storeohentry',
        formCaption: 'Store Opening Hour',
        formTypeCode: 'E',
        formTextId: '',
        icon: null,
        filePath: 'store/storeoh/entry',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'storelisting',
        formCaption: 'Store Listing',
        formTypeCode: 'L',
        filePath: 'store/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: null,
      },
      {
        formCode: 'storechannelstorelisting',
        formCaption: 'Channel Listing',
        formTypeCode: 'L',
        filePath: 'store/storechannelstorelisting',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'storechannelentry',
        formCaption: 'Store Channel',
        formTypeCode: 'E',
        filePath: 'store/storechannelstore/entry',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'storesetting',
        formCaption: 'Setting',
        formTypeCode: 'L',
        filePath: 'store/storesetting',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'storechannelstoretaxlisting',
        formCaption: 'Channel Tax',
        formTypeCode: 'L',
        filePath: 'store/channelstoretaxlisting',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'storechannelstoretaxentry',
        formCaption: 'Store Channel Tax',
        formTypeCode: 'E',
        filePath: 'store/channelstoretax/entry',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      //Tax
      {
        formCode: 'taxmaster',
        formCaption: 'Tax Master',
        formTypeCode: 'M',
        filePath: 'tax/master',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: ['taxhentry', 'taxdlisting'],
      },
      {
        formCode: 'taxhlisting',
        formCaption: 'Tax Header Listing',
        formTypeCode: 'L',
        filePath: 'taxh/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: null,
      },
      {
        formCode: 'taxhentry',
        formCaption: 'Tax Header Entry',
        formTypeCode: 'L',
        filePath: 'taxh/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: ['taxdlisting'],
      },
      {
        formCode: 'taxDlisting',
        formCaption: 'Tax Detail Listing',
        formTypeCode: 'L',
        filePath: 'taxd/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'taxdentry',
        formCaption: 'Tax Detail Entry',
        formTypeCode: 'L',
        filePath: 'taxd/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: ['taxflisting'],
      },
      {
        formCode: 'taxflisting',
        formCaption: 'Tax Filter Listing',
        formTypeCode: 'L',
        filePath: 'taxd/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
      },
      {
        formCode: 'taxfentry',
        formCaption: 'Tax Filter Entry',
        formTypeCode: 'L',
        filePath: 'taxf/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      //Channel
      {
        formCode: 'channellisting',
        formCaption: 'Channel Listing',
        formTypeCode: 'L',
        filePath: 'channel/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelmaster',
        formCaption: 'Channel Master',
        formTypeCode: 'M',
        filePath: 'channel/master',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [
          'channelentry',
          'channeladdrlisting',
          'channelsectionlisting',
          'channelstorelisting',
        ],
      },
      {
        formCode: 'channelentry',
        formCaption: 'Channel Entry',
        formTypeCode: 'E',
        formTextId: '',
        icon: null,
        queryString: null,
        filePath: 'channel/entry',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channeladdrlisting',
        formCaption: 'Channel Address Listing',
        formTypeCode: 'L',
        formTextId: '',
        icon: null,
        queryString: null,
        filePath: 'channel/channeladdrlisting',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channeladdrentry',
        formCaption: 'Channel Address Entry',
        formTextId: '',
        icon: null,
        queryString: null,
        formTypeCode: 'E',
        filePath: 'channel/channeladdr/entry',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelsectionlisting',
        formCaption: 'Channel Section Listing',
        formTypeCode: 'L',
        formTextId: '',
        icon: null,
        queryString: null,
        filePath: 'channel/channelsectionlisting',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelsectionentry',
        formCaption: 'Channel Section Entry',
        formTextId: '',
        icon: null,
        queryString: null,
        formTypeCode: 'E',
        filePath: 'channel/channelsection/entry',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelplatformsetlisting',
        formCaption: 'Channel PlatformSet Listing',
        formTypeCode: 'L',
        filePath: 'channelplatformset/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelplatformsetentry',
        formCaption: 'Channel PlatformSet Entry',
        formTypeCode: 'E',
        filePath: 'channelplatformset/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelstorelisting',
        formCaption: 'Channel Store Listing',
        formTypeCode: 'L',
        formTextId: '',
        icon: null,
        queryString: null,
        filePath: 'channel/channelstorelisting',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelstoremaster',
        formCaption: 'Channel Store Master',
        formTypeCode: 'M',
        filePath: 'channelstore/master',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [
          'channelstoreentry',
          'channelstoretaxlisting',
          'channelstoreplatformsetlisting',
        ],
      },
      {
        formCode: 'channelstoreentry',
        formCaption: 'Channel Store Entry',
        formTypeCode: 'E',
        formTextId: '',
        icon: null,
        queryString: null,
        filePath: 'channel/channelstore/entry',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelstoreplatformsetlisting',
        formCaption: 'Channel Store PlatformSet Listing',
        formTypeCode: 'L',
        filePath: 'channelstoreplatformset/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelstoreplatformsetentry',
        formCaption: 'Channel Store PlatformSet Entry',
        formTypeCode: 'E',
        filePath: 'channelstoreplatformsetentry/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelstoretaxlisting',
        formCaption: 'Channel Store Tax Listing',
        formTypeCode: 'L',
        filePath: 'channelstoretax/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'channelstoretaxentry',
        formCaption: 'Channel Store Tax Entry',
        formTypeCode: 'E',
        filePath: 'channelstoretaxentry/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
       //Dealer
       {
        formCode: 'dealermaster',
        formCaption: 'Dealer Master',
        formTypeCode: 'M',
        filePath: 'dealer/master',
        attributeCodes: [],
        childFormCodes: ['dealerentry'],
      },
      {
        formCode: 'dealerlisting',
        formCaption: 'Dealer Listing',
        formTypeCode: 'L',
        filePath: 'dealer/listing',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: null,
      },
      {
        formCode: 'dealerentry',
        formCaption: 'Dealer Entry',
        formTypeCode: 'E',
        filePath: 'dealer/entry',
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      //CatgAttrOption
      {
        formCode: 'catgattroptionlisting',
        formCaption: 'Category Attribute Option',
        formTypeCode: 'L',
        filePath: '',
        attributeCodes: [],
        childFormCodes: [],
      },
      //AppNotification
      {
        formCode: 'appnotification entry',
        formCaption: 'Application Notification Entry',
        formTypeCode: 'E',
        filePath: 'appnotification/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'statementlisting',
        formCaption: 'Statement Listing',
        formTypeCode: 'L',
        filePath: 'statement/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: null,
      },
      {
        formCode: 'statemententry',
        formCaption: 'Statement Form',
        formTypeCode: 'E',
        filePath: 'statement/entry',
        attributeCodes: [],
        childFormCodes: null,
      },
      {
        formCode: 'customform',
        formCaption: 'Custom Form',
        formTypeCode: null,
        filePath: 'framework/customform',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
      {
        formCode: 'currencyentry',
        formCaption: 'Currency Entry',
        formTypeCode: 'E',
        filePath: 'currency/entry',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
      {
        formCode: 'currencylisting',
        formCaption: 'Currency Listing',
        formTypeCode: 'L',
        filePath: 'currency/listing',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
      {
        formCode: 'invoicelisting',
        formCaption: 'Invoice Listing',
        formTypeCode: 'L',
        filePath: 'invoice/listing',
        attributeCodes: [],
        childFormCodes: [],
      },
      {
        formCode: 'invoiceentry',
        formCaption: 'Invoice Generator',
        formTypeCode: 'E',
        filePath: 'invoice/entry',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'invoiceemailentry',
        formCaption: 'Invoice Email Generator',
        formTypeCode: 'E',
        filePath: 'invoice/emailentry',
        queryString: null,
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'invoiceform',
        formCaption: 'Invoice Form',
        formTypeCode: 'E',
        filePath: 'invoice/form',
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
      {
        formCode: 'revertcutoff',
        formCaption: 'Order Detail',
        formTypeCode: null,
        filePath: 'order-management/revertcutoff',
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'orderdetaillisting',
        formCaption: 'Order Detail Listing',
        formTypeCode: 'L',
        filePath: 'order-management/listing',
        attributeCodes: ['EDIT'],
        childFormCodes: [],
      },
      {
        formCode: 'updateorderstatus',
        formCaption: 'Update Payment Status',
        formTypeCode: null,
        filePath: 'order-management/updateorderstatus',
        attributeCodes: ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'activityloglisting',
        formCaption: 'Activity Log Listing',
        formTypeCode: 'L',
        filePath: 'order-management/activityloglisting',
        attributeCodes: ['EDIT'],
        childFormCodes: [],
      },
      {
        formCode: 'syspendingjoblisting',
        formCaption: 'System Pending Job Listing',
        formTypeCode: 'L',
        filePath: 'order-management/syspendingjoblisting',
        attributeCodes: ['EDIT'],
        childFormCodes: [],
      },
      {
        formCode: 'orderdetailmaster',
        formCaption: 'Order Detail Master',
        formTypeCode: 'M',
        filePath: 'order-management/master',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [
          'revertcutoff',
          'activityloglisting',
          'syspendingjoblisting'
        ],
      },
      //Tax
      {
        formCode: 'taxconditionhlisting',
        formCaption: 'Tax Condition Listing',
        formTypeCode: 'L',
        filePath: 'tax/taxconditionhlisting',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'taxconditionmaster',
        formCaption: 'Tax Condition Master',
        formTypeCode: 'M',
        filePath: 'tax/master',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [
          'taxconditionhentry',
          'taxconditiondlisting'
        ],
      },
      {
        formCode: 'taxconditiondlisting',
        formCaption: 'Tax Condition D Listing',
        formTypeCode: 'L',
        filePath: 'tax/taxconditiondlisting',
        attributeCodes: ['EDIT', 'DELETE', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'taxconditionhentry',
        formCaption: 'Tax Condition H Entry',
        formTypeCode: 'E',
        filePath: 'tax/taxconditionhentry',
        attributeCodes:  ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'taxconditiondentry',
        formCaption: 'Tax Condition D Entry',
        formTypeCode: 'E',
        filePath: 'tax/taxconditiondentry',
        attributeCodes:  ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [
        ],
      },
      {
        formCode: 'taxconditiondmaster',
        formCaption: 'Tax Condition D Master',
        formTypeCode: 'E',
        filePath: 'tax/taxconditiond/master',
        attributeCodes:  ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [
          'taxconditiondentry',
          'taxconditionflisting'
        ],
      },
      {
        formCode: 'taxconditionflisting',
        formCaption: 'Tax Condition F Listing',
        formTypeCode: 'E',
        filePath: 'tax/taxconditionflisting',
        attributeCodes:  ['EDIT', 'DELETE', 'NEW'],
        childFormCodes: [
        ],
      },
      {
        formCode: 'taxconditionfentry',
        formCaption: 'Tax Condition F Entry',
        formTypeCode: 'E',
        filePath: 'tax/taxconditionfentry',
        attributeCodes:  ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'taxlisting',
        formCaption: 'Tax Listing',
        formTypeCode: 'L',
        filePath: 'tax/taxlisting',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'taxentry',
        formCaption: 'Tax Entry',
        formTypeCode: 'E',
        filePath: 'tax/taxentry',
        attributeCodes:  ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'taxgrouplisting',
        formCaption: 'Tax Group Listing',
        formTypeCode: 'L',
        filePath: 'tax/taxgrouplisting',
        attributeCodes: ['EDIT', 'VIEW', 'NEW', 'DELETE'],
        childFormCodes: [],
      },
      {
        formCode: 'taxgroupentry',
        formCaption: 'Tax Group Entry',
        formTypeCode: 'E',
        filePath: 'tax/taxgroupentry',
        attributeCodes:  ['EDIT', 'VIEW', 'NEW'],
        childFormCodes: [],
      },
      {
        formCode: 'rptsalesdetailsv2',
        formCaption: 'Sales Details Report',
        formTypeCode: 'RPT',
        filePath: 'report/rptsalesdetailsv2',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
      {
        formCode: 'rptsalessummaryv2',
        formCaption: 'Sales Summary Report',
        formTypeCode: 'RPT',
        filePath: 'report/rptsalessummaryv2',
        formTextId: '',
        icon: null,
        queryString: null,
        attributeCodes: [],
        childFormCodes: [],
      },
    ],
    sysOptions: [
      {
        groupCode: 'DECIMAL',
        options: [
          {
            setCode: 'DECBYAMT',
            setValue: '3',
            valueType: 'N',
          },
          {
            setCode: 'DECBYQTY',
            setValue: '3',
            valueType: 'N',
          }
        ],
      }
    ],
    widgets: [
      {
        profileCode: 'MOCK_DATA',
        widgets: [
          {
            id: 1,
            formCode: 'MOCK_WIDGET_1',
            formCaption: 'WIDGET LABEL 1',
            columnSize: 2,
            rowSize: 13,
          },
          {
            id: 2,
            formCode: 'MOCK_WIDGET_2',
            formCaption: 'WIDGET LABEL 2',
            columnSize: 9,
            rowSize: 180,
          },
          {
            id: 3,
            formCode: 'MOCK_WIDGET_3',
            formCaption: 'WIDGET LABEL 3',
            columnSize: 0,
            rowSize: 50,
          },
        ],
      },
    ],
    userProfile: {
      UserId: 'e393abc2-434f-4c50-b2ba-8404c133042f',
      Email: 'admin@zeoniq.com',
      FirstName: 'Zeoniq',
      LastName: 'Administrator/Support',
      RefLangCode: 'EN',
      CultureCode: 'EN-MY',
      IsDeleted: false,
      OwnerCode: null,
    },
    countries: ['bn', 'id', 'my', 'sg'],
    rowVersion: 'AAAAAAAACYQ=',
  };
  newForm: form[] = [];

  constructor(
    private fb: FormBuilder
  ) {
    if (environment.showFramework) {
      this.settingData = {
        ...this.settingData,
        menus: [...this.settingData.menus, ...frameworkMenu.menus],
        forms: [...this.settingData.forms, ...frameworkMenu.forms],
      };
    }
    this.newForm = this.settingData.forms;
    this.newForm.forEach((form) => {
      if (form.childFormCodes && form.childFormCodes.length > 0) {
        form.childFormCodes.forEach(async (childFormCodes) => {
          let childFormDt = this.getFormDetail(childFormCodes);
          if (childFormDt) {
            let childFilePathString = await this.formatMasterFilePath(
              childFormDt
            );

            this.newForm.push({
              ...form,
              formCode: childFormDt.formCode,
              formTypeCode: childFormDt.formTypeCode,
              formCaption: childFormDt.formCaption,
              filePath: form.filePath + '/' + childFilePathString,
              childFormData: this.getMasterChildrenFormData(form),
            });
          }
        });
      }
    });
  }

  generateFormGroup(data: any, editMode?: boolean, formGroup?: UntypedFormGroup): UntypedFormGroup {
    let formGrp = formGroup ? formGroup : new UntypedFormGroup({});

    data.forEach(content => {
      let setting: any;

      if (content.inputText) {
        formGrp.addControl(content.inputText.name, this.fb.control(''));
        formGrp.get(content.inputText.name).setValidators(this.getValidatorFnArray(content.inputText));
        setting = content.inputText;
      }

      if (content.lookDropInput) {
        formGrp.addControl(content.lookDropInput.name, this.fb.control(''));
        formGrp.get(content.lookDropInput.name).setValidators(content.lookDropInput.required ? [Validators.required] : []);
        setting = content.lookDropInput;
      }

      if (content.toggleInput) {
        formGrp.addControl(content.toggleInput.name, this.fb.control(false));
        setting = content.toggleInput;
      }

      if (content.autoGenerateInput) {
        formGrp.addControl(content.autoGenerateInput.name, this.fb.control(''));
        formGrp.get(content.autoGenerateInput.name).setValidators(this.getValidatorFnArray(content.autoGenerateInput));
        setting = content.autoGenerateInput;
      }

      if (content.textArea) {
        formGrp.addControl(content.textArea.name, this.fb.control(''));
        formGrp.get(content.textArea.name).setValidators(this.getValidatorFnArray(content.textArea));
        setting = content.textArea;
      }

      if (content.uploadInput) {
        // validators set in component
        formGrp.addControl(content.uploadInput.name, this.fb.control(''));
        formGrp.get(content.uploadInput.name).setValidators(this.getValidatorFnArray(content.uploadInput));

        if (content.uploadInput.dataKey) {
          formGrp.addControl(content.uploadInput.dataKey, this.fb.control(''));
        }
        setting = content.uploadInput;
      }

      if (content.dateInput) {
        formGrp.addControl(content.dateInput.name, this.fb.control(''));
        setting = content.dateInput;
      }

      if (content.dateRangeInput) {
        formGrp.addControl(content.dateRangeInput.name, this.fb.control(''));
        formGrp.get(content.dateRangeInput.name).setValidators(this.getValidatorFnArray(content.dateRangeInput));
        setting = content.dateRangeInput;
      }

      if (content.timeInput) {
        formGrp.addControl(content.timeInput.name, this.fb.control(''));
        setting = content.timeInput;
      }

      if (content.buttonToggle) {
        formGrp.addControl(content.buttonToggle.name, this.fb.control(''));
        setting = content.buttonToggle;
      }

      if (content.slideToggle) {
        formGrp.addControl(content.slideToggle.name, this.fb.control(''));
        setting = content.slideToggle
      }

      if (content.inputNumber) {
        formGrp.addControl(content.inputNumber.name, this.fb.control(''));
        formGrp.get(content.inputNumber.name).setValidators(this.getValidatorFnArray(content.inputNumber));
        setting = content.inputNumber;
      }

      if (content.periodInput) {
        formGrp.addControl(content.periodInput.name, this.fb.control(''));
        formGrp.get(content.periodInput.name).setValidators(this.getValidatorFnArray(content.periodInput));
        setting = content.periodInput;
      }

      if (content.expansionPanel) {
        let titleComponents = content.expansionPanel?.title ? content.expansionPanel.title : [];
        let contentComponenets = content.expansionPanel?.content ? content.expansionPanel.content : [];
        let inputs = [...titleComponents, ...contentComponenets];
        this.generateFormGroup(inputs, editMode, formGrp);
      }

      this.disableFormCtrl(formGrp, setting, editMode);
    });

    return formGrp;
  }

  getValidatorFnArray(obj): ValidatorFn[] {
    const validators = [];

    if (obj.required || obj.required === '') {
      validators.push(Validators.required);
    }
    if (obj.minLength) {
      validators.push(Validators.minLength(parseInt(obj.minLength, 10)));
    }
    if (obj.maxLength) {
      validators.push(Validators.maxLength(parseInt(obj.maxLength, 10)));
    }
    if (obj.pattern && !(InputTypes.date || InputTypes.dateTimeLocal || InputTypes.time)) {
      validators.push(Validators.pattern(obj.pattern));
    }
    if (obj.type === InputTypes.email) {
      validators.push(Validators.pattern(InputPattern.EmailPattern));
    }
    if (obj.type === InputTypes.number && obj.min !== null) {
      validators.push(Validators.min(obj.min));
    }
    if (obj.type === InputTypes.number && obj.max !== null) {
      validators.push(Validators.min(obj.max));
    }
    if (obj.type === InputTypes.text && obj.textFormat) {
      validators.push(Validators.pattern(obj.textFormat));
    }

    return validators;
  }

  disableFormCtrl(formGroup: UntypedFormGroup, setting: any, editMode: boolean) {
    if (setting?.name && formGroup.get(setting.name) && (setting.disabled || (editMode && setting.editable === false))) {
      formGroup.get(setting.name).disable();
    }
  }

  formatContentGrid(contentValue: FormContent[]): FormContent[] {
    // sort the row sequence
    let contentSeqs = contentValue.filter(content => content.rowSeq);
    contentSeqs = _.orderBy(contentSeqs, ['rowSeq', 'columnSeq'], ['asc', 'desc']);

    let uniqContentSeqs: FormContent[] = _.uniqBy(contentSeqs, 'rowSeq');

    let contentWithoutSeqs = contentValue.filter(content => !content.rowSeq);

    // the data contain with and without the rowSeq value
    if (contentWithoutSeqs && contentWithoutSeqs.length > 0) {
      for (let index = 0; index < uniqContentSeqs.length; index++) {
        contentWithoutSeqs.splice(uniqContentSeqs[index].rowSeq - 1, 0, uniqContentSeqs[index]);
      }

      for (let index = 0; index < uniqContentSeqs.length; index++) {
        let allContentSeqs = contentSeqs.filter(content => content.rowSeq == uniqContentSeqs[index].rowSeq);

        let foundIndex = contentWithoutSeqs.findIndex(content => content.rowSeq === uniqContentSeqs[index]?.rowSeq);
        if (foundIndex) {
          contentWithoutSeqs.splice(foundIndex, 1);
          for (let index3 = 0; index3 < allContentSeqs.length; index3++) {
            let selectedContent = allContentSeqs[index3];
            if (selectedContent) {
              selectedContent.gridClass = this.generateGridClass(allContentSeqs.length);
              contentWithoutSeqs.splice(foundIndex, 0, selectedContent);
            }
          }
        }
      }
    } else {
      contentWithoutSeqs = _.orderBy(contentSeqs, ['rowSeq', 'columnSeq'], ['asc', 'asc']);

      for (let index = 0; index < contentWithoutSeqs.length; index++) {
        let selectedContent = contentWithoutSeqs[index];
        let sameSeqTotalCount = contentWithoutSeqs.filter(content => content.rowSeq === selectedContent.rowSeq);
        contentWithoutSeqs[index].gridClass = this.generateGridClass(sameSeqTotalCount.length);
      }
    }

    contentWithoutSeqs.map(obj => {
      if (!obj.rowSeq) {
        obj.gridClass = 'col-12';
      }
      if (obj.columnEmpty) {
        obj.gridClass += ' hidden-sm';
      }
    });

    return contentWithoutSeqs;
  }

  markAllAsTouched(formGrp: UntypedFormGroup) {
    formGrp.markAllAsTouched();
  }

  validateFormRequire(formGrp: UntypedFormGroup, formContent: FormContent[]) {
    if (formGrp.invalid && this.validateRequiredField(formGrp, formContent)) {
      return true;
    } else {
      return false;
    }
  }

  async validateRequiredField(formGrp: UntypedFormGroup, formContent: FormContent[]) {
    let isRequiredError = false;
    formContent.forEach(content => {
      let formGrpName = '';
      if (content.inputText) {
        formGrpName = content.inputText.name;
      } else if (content.lookDropInput) {
        formGrpName = content.lookDropInput.name;
      } else if (content.toggleInput) {
        formGrpName = content.toggleInput.name;
      } else if (content.inputNumber) {
        formGrpName = content.inputNumber.name;
      } else if (content.uploadInput) {
        formGrpName = content.uploadInput.name;
      }

      if (formGrp.get(formGrpName) && formGrp.get(formGrpName).errors && formGrp.get(formGrpName).errors['required']) {
        isRequiredError = true;
      }
    });

    return isRequiredError;
  }

  generateGridClass(totalLength: number): string {
    let gridValue = 12 / totalLength;
    let gridClass = "col-12" + " " + "md:col-" + gridValue;
    return gridClass;
  }

  getReqBody(formGroup: UntypedFormGroup) {
    let reqBody = null;
    let keys = Object.keys(formGroup.controls);

    for (let index = 0; index < keys.length; index++) {
      let tempObj = {};
      let tempKey = keys[index];
      tempObj[tempKey] = formGroup.controls[tempKey].value;
      reqBody = { ...reqBody, ...tempObj };
    }
    return reqBody;
  }

  getFormDataReqBody(formGroup: UntypedFormGroup) {
    let formData: any = new FormData();
    let keys = Object.keys(formGroup.controls);

    for (let index = 0; index < keys.length; index++) {
      let tempKey = keys[index];

      if (formGroup.controls[tempKey]?.value || formGroup.controls[tempKey]?.value === false) {
        formData.append(tempKey, formGroup.controls[tempKey].value);
      }
    }

    return formData;
  }

  getFormGroupCtrl(formGrp: FormGroup<any>, fromGrpName: string) {
    return formGrp.controls[fromGrpName];
  }

  setDependency(formGrp: FormGroup<any>, setting: any) {
    let dependency$: Subscription;

    if (formGrp && setting?.dependency && formGrp.get(setting.dependency.name)) {
      let dependency: any = formGrp.controls[setting.dependency.name];
      let dependant: any = formGrp.controls[setting.name];

      dependant.disable();

      dependency$ = dependency.valueChanges.subscribe((data: any) => {
        if ((data && !setting.dependency.allowInvalid && dependency.valid) || (data && setting.dependency.allowInvalid)) {
          dependant.enable();
        } else {
          dependant.reset();
          dependant.disable();
        }
      });
    }

    return dependency$;
  }

  getFormDetail(formCode: string): form {
    return this.newForm.find((data) => data.formCode === formCode);
  }

  getSysOptions() {
    return this.settingData.sysOptions;
  }

  async formatMasterFilePath(childFormDt: form): Promise<string> {
    let childFilePathString = '';

    if (childFormDt) {
      let childFilePathSplit = childFormDt.filePath.split('/');
      if (childFilePathSplit && childFilePathSplit.length > 0) {
        childFilePathSplit.shift();
        childFilePathString = childFilePathSplit.join('/');
      }
    }

    return childFilePathString;
  }

  getMasterChildrenFormData(form: form): form[] {
    let childFormDetail = [];
    form.childFormCodes.forEach(async (code) => {
      let childForm = this.getFormByFormCode(code);

      if (childForm) {
        childFormDetail.push({
          ...childForm,
          filePath:
            '/' +
            form.filePath +
            '/' +
            (await this.formatMasterFilePath(childForm)),
        });
      }
    });
    return childFormDetail;
  }

  getFormByFormCode(formCode: string): form {
    let menus = this.settingData.forms;
    return menus.find((menu) => menu.formCode === formCode);
  }
}
