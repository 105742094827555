import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-form-sidenav-dialog',
    templateUrl: './form-sidenav-dialog.component.html',
    styleUrls: ['./form-sidenav-dialog.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class FormSidenavDialogComponent implements OnInit {

  // @Input() sidenavItm = [];

  sideNavItm = [
    {
      name: 'Outlet Details ', action: '', subNavItm: [
        { name: 'Details ', action: '' },
        { name: 'Manager Info ', action: '' },
        { name: 'Additional Info ', action: '' }
      ]
    },
    { name: 'Registered User ', action: '' },
    { name: 'Table Section ', action: '' },
    { name: 'Table ', action: '' },
    { name: 'Attribute ', action: '' },
    { name: 'Session Times ', action: '' },
    { name: 'Filter ', action: '' },
    { name: 'Kitchen Queue ', action: '' },
    { name: 'Operating Hours ', action: '' },
  ]

  sideNavItems: any = [];
  isSubNavMode: boolean = false;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.sideNavItems = this.sideNavItm;
  }

  onClickSideNavItem(sideItm: any) {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  viewSubNavItm(sideItm: any) {
    this.isSubNavMode = true;
    this.sideNavItems = [];
    this.sideNavItems = [...[sideItm], ...sideItm.subNavItm];
  }

  viewBackNavItm() {
    this.isSubNavMode = false;
    this.sideNavItems = this.sideNavItm;
  }
}
