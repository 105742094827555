import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { ColDef, IRowNode } from 'ag-grid-community';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { SubscriptionUserService } from 'src/app/core/services/subscriptionUser.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormCode } from 'src/app/shared/enums/formCode';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { SysFormUserResponse } from 'src/swagger/base-rms/openapi';

@Component({
  selector: 'app-lookup-sys-user',
  standalone: true,
  imports: [CommonModule, LookDropInputComponent],
  templateUrl: './lookup-sys-user.component.html',
  styleUrls: ['./lookup-sys-user.component.scss']
})
export class LookupSysUserComponent implements OnInit, OnChanges {
  @Input() formData: any;
  userData: any;
  lookDropInputSetting: LookDropInputSetting; //pass from lookup master component when create component
  formGroup: UntypedFormGroup;
  rowData: any = []
  multiSelectData: {node?: IRowNode, value?: any, displayValue?: any}[];

  colDef: ColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1 }
    ]
  };

  constructor(private subscriptionUser: SubscriptionUserService,
    private lookupMasterService: LookupMasterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue) {
      // set multiselect display value
      this.multiSelectData = changes.formData?.currentValue?.sysFormSetUs?.map((user: SysFormUserResponse) => {return {value: user.userId, displayValue: user.firstName + " " + user.lastName}});
      this.userData = {userId: changes.formData?.currentValue?.sysFormSetUs?.map((user: SysFormUserResponse) => user.userId)};
    }
  }

  ngOnInit(): void {
    // Custom settings
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'firstName',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : 'userId',
      displayValueKey: this.lookDropInputSetting.displayValueKey ? this.lookDropInputSetting.displayValueKey: ':firstName :lastName',
      colDef: this.lookDropInputSetting.colDef ? this.lookDropInputSetting.colDef: this.colDef,
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey: 'lastName',
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : 'general.select_at_least_one',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling? this.lookDropInputSetting.styling: {},
      headerName: this.lookDropInputSetting.headerName? this.lookDropInputSetting.headerName: 'report.users',
      dependency: this.lookDropInputSetting.dependency? this.lookDropInputSetting.dependency: null,
      disabled: this.lookDropInputSetting.disabled? this.lookDropInputSetting.disabled: false,
      defaultLookdrop: this.lookDropInputSetting.defaultLookdrop ? this.lookDropInputSetting.defaultLookdrop : false
    }
  }

  async onLookup(data: any) {
    // call api and set row
    this.rowData = await this.getSysUser();
  }

  async getSysUser(filter?: string, top?: number) {
    let orderBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field); // array of property name, eg. ['currCode', 'currDesc']
    let order = this.lookupMasterService.setOrderBy(orderBy.map((order: string) => {return {key: order, order: 'asc'}}));
    let result: any = await this.subscriptionUser.getSysUserList(FormCode.rptsalesdetails, filter, top, order);
    if (!(result instanceof HttpErrorResponse)) {
      return result['value'];
    }
    return null;
  }

  async onAutoComplete(autoCompleteVal: any) {
    // set filter
    let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field); // array of property name, eg. ['currCode', 'currDesc']
    let filter = this.lookupMasterService.setFilter(autoCompleteVal, filterBy);
    let top = 10;

    // call api and open autocomplete
    this.rowData = await this.getSysUser(filter, top);
  }

  onSelected(selectedData: any) {
    if(this.lookDropInputSetting?.multiSelect) {
      // set multiselect display value
      this.multiSelectData = selectedData.map((node: any) => {
        return {
          node: node,
          value: selectedData?.value? selectedData.value: node?.data?.userId,
          displayValue: selectedData?.displayValue? selectedData?.displayValue: node?.data?.firstName + " " + node?.data?.lastName
        }
      });
    }
  }

  async onLookupToolbarAction(data: any) {
    if(data.name === 'filter') {
      let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(data.data, filterBy);
      this.rowData = await this.getSysUser(filter, null);
    }
  }
}
