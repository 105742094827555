
import { storePathComponent } from './store';
import { merchantPathComponent } from './merchant';
import { dashboardPathComponent } from './dashboard';
import { statementPathComponent } from './statement';
import { currencyPathComponent } from './currency';
import { frameworkPathComponent } from './base/framework';
import { reportPathComponent } from './base/report';
import { orderManagementPathComponent } from './order-management';
import { invoicePathComponent } from './invoice';
import { channelPathComponent } from './channel';
import { taxPathComponent } from './tax';
import { dealerPathComponent } from './dealer';

export const combinePathComponents = [
  ...storePathComponent,
  ...merchantPathComponent,
  ...dashboardPathComponent,
  ...statementPathComponent,
  ...currencyPathComponent,
  ...frameworkPathComponent,
  ...reportPathComponent,
  ...frameworkPathComponent,
  ...orderManagementPathComponent,
  ...invoicePathComponent,
  ...channelPathComponent,
  ...taxPathComponent,
  ...dealerPathComponent
];
