export enum filterDateKey {
  "[pastmonth]"= "Past Month",
  "[pastquarter]"= "Past Quarter",
  "[pastweek]"= "Past Week",
  "[pastyear]"= "Past Year",
  "[thismonth]"= "This Month",
  "[thisquarter]"= "This Quarter",
  "[thisweek]"= "This Week",
  "[thisyear]"= "This Year",
  "[today]"= "Today",
  "[yesterday]"= "Yesterday"
}
