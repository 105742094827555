<div class="wrapper">
  <form class="user-dropdown-container" [formGroup]="userFormGroup" (ngSubmit)="onSubmit()">
    <div class="user-profile-wrapper">
      <button class="close-user" type="button" (click)="onCloseButtonClick();">
        <img class=" close-user-button" src="assets/base/icons/arrow-left.svg"></button>
      <div class="dropdown-toggle" [class.dropdown-open]="showDropdown" (click)="toggleDropdown()">
        <div class="profile-container">
          <img class="user-icon"
            [src]="activeContent === CONTENT_STATES.USER_FORM ? 'assets/base/icons/user.svg' :
               activeContent === CONTENT_STATES.PASSWORD ? 'assets/base/icons/lock.svg' :
               activeContent === CONTENT_STATES.SWITCH_SUBSCRIPTION ? 'assets/base/icons/switch.svg' : 'assets/base/icons/user.svg'"
            alt="Profile Icon" />
            <span class="active-content"> {{ activeContent === CONTENT_STATES.USER_FORM ? ('general.myprofile' | translate) :
              activeContent === CONTENT_STATES.PASSWORD ? 'general.password' :
              activeContent === CONTENT_STATES.SWITCH_SUBSCRIPTION ? 'general.subscription' : '' }}
            </span>
        </div>

        <img class="chevron-icon" style="width: 15px;"
          [src]="showDropdown ? 'assets/base/icons/chevron-up.svg' : 'assets/base/icons/chevron-down.svg'">
      </div>
    </div>

    <div class="dropdown-menu" [class.show]="showDropdown">
      <button *ngIf="activeContent !== CONTENT_STATES.USER_FORM" class="dropdown-item" type="button"
        (click)="handleMyProfileAction()">
        <img class="user-icon" src="assets/base/icons/user.svg" />
        {{ 'general.myprofile' | translate }}
      </button>

      <!-- <button *ngIf="activeContent !== CONTENT_STATES.PASSWORD" class="dropdown-item" (click)="handlePasswordAction()">
        <img class="user-icon" src="assets/base/icons/lock.svg" />
        {{ 'Password' | translate }}
      </button> -->

      <button class="dropdown-item" *ngIf="!hideSwitchSubscription" type="button"
        (click)="handleSwitchSubscriptionAction()">
        <img class="user-icon" src="assets/base/icons/switch.svg" />
        {{ 'general.switchsubscription' | translate }}
      </button>
    </div>

    <div class="profileContainer"
      *ngIf="activeContent === CONTENT_STATES.USER_FORM || activeContent === CONTENT_STATES.PASSWORD">
      <div class="profile-content">
        <span class="user-letter">{{ userProfile?.firstName[0].toUpperCase() }}</span>
        <div class="edit">
          <button class="edit-button" type="button" (click)="onClickEdit()"><img class="edit-pencil" src="assets/base/icons/pencil.svg" /></button>
        </div>
      </div>
    </div>

    <div class="usernameContainer"
      *ngIf="userProfile && activeContent === CONTENT_STATES.USER_FORM || activeContent === CONTENT_STATES.PASSWORD">
      <div class="username">
        {{userProfile.firstName}}
      </div>
    </div>

    <div class="user" *ngIf="activeContent === CONTENT_STATES.USER_FORM">
      <div class="user-form">
        <app-input-text [inputTextSetting]="inputEmail.inputText" [formGroup]="userFormGroup"></app-input-text>
        <app-input-text [inputTextSetting]="inputFname.inputText" [formGroup]="userFormGroup"></app-input-text>
        <app-input-text [inputTextSetting]="inputLname.inputText" [formGroup]="userFormGroup"></app-input-text>
        <app-lookdrop [lookDropInputSetting]="dropdownLang.lookDropInput" [formGroup]="userFormGroup"></app-lookdrop>
        <app-lookdrop [lookDropInputSetting]="dropdownRegion.lookDropInput" [formGroup]="userFormGroup"></app-lookdrop>
        <!-- <app-language></app-language> -->
      </div>
    </div>

    <!-- <div class="password" *ngIf="activeContent === CONTENT_STATES.PASSWORD">
      <form class="password-form">
        <app-input-text [inputTextSetting]="inputCurrentPassword.inputText" [formGroup]="userFormGroup"></app-input-text>
        <app-input-text [inputTextSetting]="inputNewPassword.inputText" [formGroup]="userFormGroup"></app-input-text>
        <app-input-text [inputTextSetting]="inputConfirmNewPassword.inputText" [formGroup]="userFormGroup"></app-input-text>
      </form>
    </div> -->

  <div class="action-container">
    <div *ngIf="showCancelSave" class="cancel-save" [hidden]="!editMode">
      <button class="cancel" type="button" (click)="onClickCancelEdit();">{{ "Cancel" | translate }}</button>
      <button class="save" type="submit" [disabled]="userFormGroup.invalid">{{ "Save" | translate }}</button>
    </div>

    <div class="sign-out-container" [hidden]="editMode">
      <button class="sign-out" type="button" (click)="signOut()">{{ "Sign Out" | translate }}</button>
    </div>
  </div>
  </form>
</div>
