import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionUserService } from '../services/subscriptionUser.service';
import { AuthService } from '../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-terms-agreed',
  templateUrl: './terms-agreed.component.html',
  styleUrls: ['./terms-agreed.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class TermsAgreedComponent {
  constructor(private subscriptionUserService: SubscriptionUserService, private authService: AuthService) { }
  @ViewChild('termsContent') termsContent!: ElementRef;
  @Input() userDisplayName: string = '';
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  isScrolledToEnd: boolean = false;

  notAgreeTerms() {
    this.authService.logout();
  }

  agreeTerms() {
    this.subscriptionUserService.postAgreeterms().subscribe(response => {
      if (!(response instanceof HttpErrorResponse)) {
        this.closeDialogEvent.emit(true);
      }
    });
  }

  onScroll() {
    const element = this.termsContent.nativeElement;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom) {
      this.isScrolledToEnd = atBottom;
    }
  }
}
