/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddrSysFlag } from '../model/addrSysFlag';
import { BackendChannel } from '../model/backendChannel';
import { BackendChannelAddress } from '../model/backendChannelAddress';
import { BackendChannelCatgAttr } from '../model/backendChannelCatgAttr';
import { BackendChannelPlatformSet } from '../model/backendChannelPlatformSet';
import { BackendChannelSection } from '../model/backendChannelSection';
import { BackendChannelSectionList } from '../model/backendChannelSectionList';
import { BackendChannelStore } from '../model/backendChannelStore';
import { BackendChannelStoreTax } from '../model/backendChannelStoreTax';
import { CXMProblemModel } from '../model/cXMProblemModel';
import { ChannelAddressResponse } from '../model/channelAddressResponse';
import { ChannelCatgAttrResponse } from '../model/channelCatgAttrResponse';
import { ChannelCustExpiryFlag } from '../model/channelCustExpiryFlag';
import { ChannelPlatformSetResponse } from '../model/channelPlatformSetResponse';
import { ChannelResponse } from '../model/channelResponse';
import { ChannelResponseODataQueryOptions } from '../model/channelResponseODataQueryOptions';
import { ChannelSectionListResponse } from '../model/channelSectionListResponse';
import { ChannelSectionResponse } from '../model/channelSectionResponse';
import { ChannelStoreResponse } from '../model/channelStoreResponse';
import { ChannelStoreTaxResponse } from '../model/channelStoreTaxResponse';
import { JoinMode } from '../model/joinMode';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChannelService {

    protected basePath = 'https://cxmbackendapi.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelPost(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public apiChannelAddchannelPost(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public apiChannelAddchannelPost(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public apiChannelAddchannelPost(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelResponse>('post',`${this.basePath}/api/channel/addchannel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchanneladdressPost(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelAddressResponse>;
    public apiChannelAddchanneladdressPost(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelAddressResponse>>;
    public apiChannelAddchanneladdressPost(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelAddressResponse>>;
    public apiChannelAddchanneladdressPost(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelAddressResponse>('post',`${this.basePath}/api/channel/addchanneladdress`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelcatgattrPost(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelCatgAttrResponse>;
    public apiChannelAddchannelcatgattrPost(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelCatgAttrResponse>>;
    public apiChannelAddchannelcatgattrPost(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelCatgAttrResponse>>;
    public apiChannelAddchannelcatgattrPost(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelCatgAttrResponse>('post',`${this.basePath}/api/channel/addchannelcatgattr`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelplatformsetPost(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelPlatformSetResponse>;
    public apiChannelAddchannelplatformsetPost(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelPlatformSetResponse>>;
    public apiChannelAddchannelplatformsetPost(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelPlatformSetResponse>>;
    public apiChannelAddchannelplatformsetPost(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelPlatformSetResponse>('post',`${this.basePath}/api/channel/addchannelplatformset`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelsectionPost(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelSectionResponse>;
    public apiChannelAddchannelsectionPost(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelSectionResponse>>;
    public apiChannelAddchannelsectionPost(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelSectionResponse>>;
    public apiChannelAddchannelsectionPost(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelSectionResponse>('post',`${this.basePath}/api/channel/addchannelsection`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelsectionlistPost(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelSectionListResponse>;
    public apiChannelAddchannelsectionlistPost(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelSectionListResponse>>;
    public apiChannelAddchannelsectionlistPost(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelSectionListResponse>>;
    public apiChannelAddchannelsectionlistPost(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelSectionListResponse>('post',`${this.basePath}/api/channel/addchannelsectionlist`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelStoreResponse>;
    public apiChannelAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelStoreResponse>>;
    public apiChannelAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelStoreResponse>>;
    public apiChannelAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelStoreResponse>('post',`${this.basePath}/api/channel/addchannelstore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchannelstoretaxPost(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelStoreTaxResponse>;
    public apiChannelAddchannelstoretaxPost(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelStoreTaxResponse>>;
    public apiChannelAddchannelstoretaxPost(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelStoreTaxResponse>>;
    public apiChannelAddchannelstoretaxPost(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelStoreTaxResponse>('post',`${this.basePath}/api/channel/addchannelstoretax`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param channelId 
     * @param channelTag 
     * @param channelDesc 
     * @param platformCode 
     * @param statusFlag 
     * @param homeChannelId 
     * @param dineFlag 
     * @param pickupFlag 
     * @param deliveryFlag 
     * @param channelExpiryDate 
     * @param joinMode 
     * @param custExpiryFlag 
     * @param custExpiryValue 
     * @param custExpiryDate 
     * @param addrSysFlag 
     * @param coverageKM 
     * @param webDocGroup 
     * @param webDocCode 
     * @param regKey 
     * @param merchantId 
     * @param menuSetId 
     * @param latitude 
     * @param longitude 
     * @param countryCode 
     * @param imageLink 
     * @param image 
     * @param backImageLink 
     * @param backImage 
     * @param backColor 
     * @param foreColor 
     * @param filterMinVal 
     * @param filterMaxVal 
     * @param techChrgPerc 
     * @param paymentChrgPerc 
     * @param channelChrgPerc 
     * @param loyaltyChrgPerc 
     * @param timeZone 
     * @param createdUserId 
     * @param createdDate 
     * @param lastUserId 
     * @param lastRevDate 
     * @param rowVersion 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelAddchanneltwoPostForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public apiChannelAddchanneltwoPostForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public apiChannelAddchanneltwoPostForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public apiChannelAddchanneltwoPostForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {















































        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (channelId !== undefined) {
            formParams = formParams.append('ChannelId', <any>channelId) as any || formParams;
        }
        if (channelTag !== undefined) {
            formParams = formParams.append('ChannelTag', <any>channelTag) as any || formParams;
        }
        if (channelDesc !== undefined) {
            formParams = formParams.append('ChannelDesc', <any>channelDesc) as any || formParams;
        }
        if (platformCode !== undefined) {
            formParams = formParams.append('PlatformCode', <any>platformCode) as any || formParams;
        }
        if (statusFlag !== undefined) {
            formParams = formParams.append('StatusFlag', <any>statusFlag) as any || formParams;
        }
        if (homeChannelId !== undefined) {
            formParams = formParams.append('HomeChannelId', <any>homeChannelId) as any || formParams;
        }
        if (dineFlag !== undefined) {
            formParams = formParams.append('DineFlag', <any>dineFlag) as any || formParams;
        }
        if (pickupFlag !== undefined) {
            formParams = formParams.append('PickupFlag', <any>pickupFlag) as any || formParams;
        }
        if (deliveryFlag !== undefined) {
            formParams = formParams.append('DeliveryFlag', <any>deliveryFlag) as any || formParams;
        }
        if (channelExpiryDate !== undefined) {
            formParams = formParams.append('ChannelExpiryDate', <any>channelExpiryDate) as any || formParams;
        }
        if (joinMode !== undefined) {
            formParams = formParams.append('JoinMode', <any>joinMode) as any || formParams;
        }
        if (custExpiryFlag !== undefined) {
            formParams = formParams.append('CustExpiryFlag', <any>custExpiryFlag) as any || formParams;
        }
        if (custExpiryValue !== undefined) {
            formParams = formParams.append('CustExpiryValue', <any>custExpiryValue) as any || formParams;
        }
        if (custExpiryDate !== undefined) {
            formParams = formParams.append('CustExpiryDate', <any>custExpiryDate) as any || formParams;
        }
        if (addrSysFlag !== undefined) {
            formParams = formParams.append('AddrSysFlag', <any>addrSysFlag) as any || formParams;
        }
        if (coverageKM !== undefined) {
            formParams = formParams.append('CoverageKM', <any>coverageKM) as any || formParams;
        }
        if (webDocGroup !== undefined) {
            formParams = formParams.append('WebDocGroup', <any>webDocGroup) as any || formParams;
        }
        if (webDocCode !== undefined) {
            formParams = formParams.append('WebDocCode', <any>webDocCode) as any || formParams;
        }
        if (regKey !== undefined) {
            formParams = formParams.append('RegKey', <any>regKey) as any || formParams;
        }
        if (merchantId !== undefined) {
            formParams = formParams.append('MerchantId', <any>merchantId) as any || formParams;
        }
        if (menuSetId !== undefined) {
            formParams = formParams.append('MenuSetId', <any>menuSetId) as any || formParams;
        }
        if (latitude !== undefined) {
            formParams = formParams.append('Latitude', <any>latitude) as any || formParams;
        }
        if (longitude !== undefined) {
            formParams = formParams.append('Longitude', <any>longitude) as any || formParams;
        }
        if (countryCode !== undefined) {
            formParams = formParams.append('CountryCode', <any>countryCode) as any || formParams;
        }
        if (imageLink !== undefined) {
            formParams = formParams.append('ImageLink', <any>imageLink) as any || formParams;
        }
        if (image !== undefined) {
            formParams = formParams.append('Image', <any>image) as any || formParams;
        }
        if (backImageLink !== undefined) {
            formParams = formParams.append('BackImageLink', <any>backImageLink) as any || formParams;
        }
        if (backImage !== undefined) {
            formParams = formParams.append('BackImage', <any>backImage) as any || formParams;
        }
        if (backColor !== undefined) {
            formParams = formParams.append('BackColor', <any>backColor) as any || formParams;
        }
        if (foreColor !== undefined) {
            formParams = formParams.append('ForeColor', <any>foreColor) as any || formParams;
        }
        if (filterMinVal !== undefined) {
            formParams = formParams.append('FilterMinVal', <any>filterMinVal) as any || formParams;
        }
        if (filterMaxVal !== undefined) {
            formParams = formParams.append('FilterMaxVal', <any>filterMaxVal) as any || formParams;
        }
        if (techChrgPerc !== undefined) {
            formParams = formParams.append('TechChrgPerc', <any>techChrgPerc) as any || formParams;
        }
        if (paymentChrgPerc !== undefined) {
            formParams = formParams.append('PaymentChrgPerc', <any>paymentChrgPerc) as any || formParams;
        }
        if (channelChrgPerc !== undefined) {
            formParams = formParams.append('ChannelChrgPerc', <any>channelChrgPerc) as any || formParams;
        }
        if (loyaltyChrgPerc !== undefined) {
            formParams = formParams.append('LoyaltyChrgPerc', <any>loyaltyChrgPerc) as any || formParams;
        }
        if (timeZone !== undefined) {
            formParams = formParams.append('TimeZone', <any>timeZone) as any || formParams;
        }
        if (createdUserId !== undefined) {
            formParams = formParams.append('CreatedUserId', <any>createdUserId) as any || formParams;
        }
        if (createdDate !== undefined) {
            formParams = formParams.append('CreatedDate', <any>createdDate) as any || formParams;
        }
        if (lastUserId !== undefined) {
            formParams = formParams.append('LastUserId', <any>lastUserId) as any || formParams;
        }
        if (lastRevDate !== undefined) {
            formParams = formParams.append('LastRevDate', <any>lastRevDate) as any || formParams;
        }
        if (rowVersion !== undefined) {
            formParams = formParams.append('RowVersion', <any>rowVersion) as any || formParams;
        }

        return this.httpClient.request<ChannelResponse>('post',`${this.basePath}/api/channel/addchanneltwo`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelGet(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelResponse>>;
    public apiChannelGet(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelResponse>>>;
    public apiChannelGet(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelResponse>>>;
    public apiChannelGet(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelResponse>>('get',`${this.basePath}/api/channel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelGetallchannelGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelResponse>>;
    public apiChannelGetallchannelGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelResponse>>>;
    public apiChannelGetallchannelGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelResponse>>>;
    public apiChannelGetallchannelGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelResponse>>('get',`${this.basePath}/api/channel/getallchannel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelGetallchannelstoretaxGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelStoreTaxResponse>>;
    public apiChannelGetallchannelstoretaxGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelStoreTaxResponse>>>;
    public apiChannelGetallchannelstoretaxGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelStoreTaxResponse>>>;
    public apiChannelGetallchannelstoretaxGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelStoreTaxResponse>>('get',`${this.basePath}/api/channel/getallchannelstoretax`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param channelId 
     * @param storeId 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelGetchannelstoretaxGet(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelStoreTaxResponse>>;
    public apiChannelGetchannelstoretaxGet(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelStoreTaxResponse>>>;
    public apiChannelGetchannelstoretaxGet(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelStoreTaxResponse>>>;
    public apiChannelGetchannelstoretaxGet(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (storeId !== undefined && storeId !== null) {
            queryParameters = queryParameters.set('storeId', <any>storeId);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelStoreTaxResponse>>('get',`${this.basePath}/api/channel/getchannelstoretax`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param channelId 
     * @param storeId 
     * @param taxLine 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelRemovechannelstoretaxDelete(channelId?: number, storeId?: number, taxLine?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiChannelRemovechannelstoretaxDelete(channelId?: number, storeId?: number, taxLine?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiChannelRemovechannelstoretaxDelete(channelId?: number, storeId?: number, taxLine?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiChannelRemovechannelstoretaxDelete(channelId?: number, storeId?: number, taxLine?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (storeId !== undefined && storeId !== null) {
            queryParameters = queryParameters.set('storeId', <any>storeId);
        }
        if (taxLine !== undefined && taxLine !== null) {
            queryParameters = queryParameters.set('taxLine', <any>taxLine);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('delete',`${this.basePath}/api/channel/removechannelstoretax`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelPut(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public apiChannelUpdatechannelPut(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public apiChannelUpdatechannelPut(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public apiChannelUpdatechannelPut(body?: BackendChannel, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelResponse>('put',`${this.basePath}/api/channel/updatechannel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechanneladdressPut(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelAddressResponse>;
    public apiChannelUpdatechanneladdressPut(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelAddressResponse>>;
    public apiChannelUpdatechanneladdressPut(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelAddressResponse>>;
    public apiChannelUpdatechanneladdressPut(body?: BackendChannelAddress, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelAddressResponse>('put',`${this.basePath}/api/channel/updatechanneladdress`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelcatgattrPut(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelCatgAttrResponse>;
    public apiChannelUpdatechannelcatgattrPut(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelCatgAttrResponse>>;
    public apiChannelUpdatechannelcatgattrPut(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelCatgAttrResponse>>;
    public apiChannelUpdatechannelcatgattrPut(body?: BackendChannelCatgAttr, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelCatgAttrResponse>('put',`${this.basePath}/api/channel/updatechannelcatgattr`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelplatformsetPut(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelPlatformSetResponse>;
    public apiChannelUpdatechannelplatformsetPut(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelPlatformSetResponse>>;
    public apiChannelUpdatechannelplatformsetPut(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelPlatformSetResponse>>;
    public apiChannelUpdatechannelplatformsetPut(body?: BackendChannelPlatformSet, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelPlatformSetResponse>('put',`${this.basePath}/api/channel/updatechannelplatformset`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelsectionPut(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelSectionResponse>;
    public apiChannelUpdatechannelsectionPut(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelSectionResponse>>;
    public apiChannelUpdatechannelsectionPut(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelSectionResponse>>;
    public apiChannelUpdatechannelsectionPut(body?: BackendChannelSection, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelSectionResponse>('put',`${this.basePath}/api/channel/updatechannelsection`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelsectionlistPut(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelSectionListResponse>;
    public apiChannelUpdatechannelsectionlistPut(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelSectionListResponse>>;
    public apiChannelUpdatechannelsectionlistPut(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelSectionListResponse>>;
    public apiChannelUpdatechannelsectionlistPut(body?: BackendChannelSectionList, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelSectionListResponse>('put',`${this.basePath}/api/channel/updatechannelsectionlist`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelStoreResponse>;
    public apiChannelUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelStoreResponse>>;
    public apiChannelUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelStoreResponse>>;
    public apiChannelUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelStoreResponse>('put',`${this.basePath}/api/channel/updatechannelstore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechannelstoretaxPut(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelStoreTaxResponse>;
    public apiChannelUpdatechannelstoretaxPut(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelStoreTaxResponse>>;
    public apiChannelUpdatechannelstoretaxPut(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelStoreTaxResponse>>;
    public apiChannelUpdatechannelstoretaxPut(body?: BackendChannelStoreTax, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelStoreTaxResponse>('put',`${this.basePath}/api/channel/updatechannelstoretax`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param channelId 
     * @param channelTag 
     * @param channelDesc 
     * @param platformCode 
     * @param statusFlag 
     * @param homeChannelId 
     * @param dineFlag 
     * @param pickupFlag 
     * @param deliveryFlag 
     * @param channelExpiryDate 
     * @param joinMode 
     * @param custExpiryFlag 
     * @param custExpiryValue 
     * @param custExpiryDate 
     * @param addrSysFlag 
     * @param coverageKM 
     * @param webDocGroup 
     * @param webDocCode 
     * @param regKey 
     * @param merchantId 
     * @param menuSetId 
     * @param latitude 
     * @param longitude 
     * @param countryCode 
     * @param imageLink 
     * @param image 
     * @param backImageLink 
     * @param backImage 
     * @param backColor 
     * @param foreColor 
     * @param filterMinVal 
     * @param filterMaxVal 
     * @param techChrgPerc 
     * @param paymentChrgPerc 
     * @param channelChrgPerc 
     * @param loyaltyChrgPerc 
     * @param timeZone 
     * @param createdUserId 
     * @param createdDate 
     * @param lastUserId 
     * @param lastRevDate 
     * @param rowVersion 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelUpdatechanneltwoPutForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public apiChannelUpdatechanneltwoPutForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public apiChannelUpdatechanneltwoPutForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public apiChannelUpdatechanneltwoPutForm(channelId?: number, channelTag?: string, channelDesc?: string, platformCode?: string, statusFlag?: number, homeChannelId?: number, dineFlag?: boolean, pickupFlag?: boolean, deliveryFlag?: boolean, channelExpiryDate?: Date, joinMode?: JoinMode, custExpiryFlag?: ChannelCustExpiryFlag, custExpiryValue?: number, custExpiryDate?: Date, addrSysFlag?: AddrSysFlag, coverageKM?: number, webDocGroup?: string, webDocCode?: string, regKey?: string, merchantId?: number, menuSetId?: number, latitude?: number, longitude?: number, countryCode?: string, imageLink?: string, image?: Blob, backImageLink?: string, backImage?: Blob, backColor?: string, foreColor?: string, filterMinVal?: number, filterMaxVal?: number, techChrgPerc?: number, paymentChrgPerc?: number, channelChrgPerc?: number, loyaltyChrgPerc?: number, timeZone?: string, createdUserId?: string, createdDate?: Date, lastUserId?: string, lastRevDate?: Date, rowVersion?: string, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {















































        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (channelId !== undefined) {
            formParams = formParams.append('ChannelId', <any>channelId) as any || formParams;
        }
        if (channelTag !== undefined) {
            formParams = formParams.append('ChannelTag', <any>channelTag) as any || formParams;
        }
        if (channelDesc !== undefined) {
            formParams = formParams.append('ChannelDesc', <any>channelDesc) as any || formParams;
        }
        if (platformCode !== undefined) {
            formParams = formParams.append('PlatformCode', <any>platformCode) as any || formParams;
        }
        if (statusFlag !== undefined) {
            formParams = formParams.append('StatusFlag', <any>statusFlag) as any || formParams;
        }
        if (homeChannelId !== undefined) {
            formParams = formParams.append('HomeChannelId', <any>homeChannelId) as any || formParams;
        }
        if (dineFlag !== undefined) {
            formParams = formParams.append('DineFlag', <any>dineFlag) as any || formParams;
        }
        if (pickupFlag !== undefined) {
            formParams = formParams.append('PickupFlag', <any>pickupFlag) as any || formParams;
        }
        if (deliveryFlag !== undefined) {
            formParams = formParams.append('DeliveryFlag', <any>deliveryFlag) as any || formParams;
        }
        if (channelExpiryDate !== undefined) {
            formParams = formParams.append('ChannelExpiryDate', <any>channelExpiryDate) as any || formParams;
        }
        if (joinMode !== undefined) {
            formParams = formParams.append('JoinMode', <any>joinMode) as any || formParams;
        }
        if (custExpiryFlag !== undefined) {
            formParams = formParams.append('CustExpiryFlag', <any>custExpiryFlag) as any || formParams;
        }
        if (custExpiryValue !== undefined) {
            formParams = formParams.append('CustExpiryValue', <any>custExpiryValue) as any || formParams;
        }
        if (custExpiryDate !== undefined) {
            formParams = formParams.append('CustExpiryDate', <any>custExpiryDate) as any || formParams;
        }
        if (addrSysFlag !== undefined) {
            formParams = formParams.append('AddrSysFlag', <any>addrSysFlag) as any || formParams;
        }
        if (coverageKM !== undefined) {
            formParams = formParams.append('CoverageKM', <any>coverageKM) as any || formParams;
        }
        if (webDocGroup !== undefined) {
            formParams = formParams.append('WebDocGroup', <any>webDocGroup) as any || formParams;
        }
        if (webDocCode !== undefined) {
            formParams = formParams.append('WebDocCode', <any>webDocCode) as any || formParams;
        }
        if (regKey !== undefined) {
            formParams = formParams.append('RegKey', <any>regKey) as any || formParams;
        }
        if (merchantId !== undefined) {
            formParams = formParams.append('MerchantId', <any>merchantId) as any || formParams;
        }
        if (menuSetId !== undefined) {
            formParams = formParams.append('MenuSetId', <any>menuSetId) as any || formParams;
        }
        if (latitude !== undefined) {
            formParams = formParams.append('Latitude', <any>latitude) as any || formParams;
        }
        if (longitude !== undefined) {
            formParams = formParams.append('Longitude', <any>longitude) as any || formParams;
        }
        if (countryCode !== undefined) {
            formParams = formParams.append('CountryCode', <any>countryCode) as any || formParams;
        }
        if (imageLink !== undefined) {
            formParams = formParams.append('ImageLink', <any>imageLink) as any || formParams;
        }
        if (image !== undefined) {
            formParams = formParams.append('Image', <any>image) as any || formParams;
        }
        if (backImageLink !== undefined) {
            formParams = formParams.append('BackImageLink', <any>backImageLink) as any || formParams;
        }
        if (backImage !== undefined) {
            formParams = formParams.append('BackImage', <any>backImage) as any || formParams;
        }
        if (backColor !== undefined) {
            formParams = formParams.append('BackColor', <any>backColor) as any || formParams;
        }
        if (foreColor !== undefined) {
            formParams = formParams.append('ForeColor', <any>foreColor) as any || formParams;
        }
        if (filterMinVal !== undefined) {
            formParams = formParams.append('FilterMinVal', <any>filterMinVal) as any || formParams;
        }
        if (filterMaxVal !== undefined) {
            formParams = formParams.append('FilterMaxVal', <any>filterMaxVal) as any || formParams;
        }
        if (techChrgPerc !== undefined) {
            formParams = formParams.append('TechChrgPerc', <any>techChrgPerc) as any || formParams;
        }
        if (paymentChrgPerc !== undefined) {
            formParams = formParams.append('PaymentChrgPerc', <any>paymentChrgPerc) as any || formParams;
        }
        if (channelChrgPerc !== undefined) {
            formParams = formParams.append('ChannelChrgPerc', <any>channelChrgPerc) as any || formParams;
        }
        if (loyaltyChrgPerc !== undefined) {
            formParams = formParams.append('LoyaltyChrgPerc', <any>loyaltyChrgPerc) as any || formParams;
        }
        if (timeZone !== undefined) {
            formParams = formParams.append('TimeZone', <any>timeZone) as any || formParams;
        }
        if (createdUserId !== undefined) {
            formParams = formParams.append('CreatedUserId', <any>createdUserId) as any || formParams;
        }
        if (createdDate !== undefined) {
            formParams = formParams.append('CreatedDate', <any>createdDate) as any || formParams;
        }
        if (lastUserId !== undefined) {
            formParams = formParams.append('LastUserId', <any>lastUserId) as any || formParams;
        }
        if (lastRevDate !== undefined) {
            formParams = formParams.append('LastRevDate', <any>lastRevDate) as any || formParams;
        }
        if (rowVersion !== undefined) {
            formParams = formParams.append('RowVersion', <any>rowVersion) as any || formParams;
        }

        return this.httpClient.request<ChannelResponse>('put',`${this.basePath}/api/channel/updatechanneltwo`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataChannel(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelResponse>>;
    public odataChannel(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelResponse>>>;
    public odataChannel(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelResponse>>>;
    public odataChannel(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelResponse>>('get',`${this.basePath}/odata/channel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataChannelcount(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelResponse>>;
    public odataChannelcount(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelResponse>>>;
    public odataChannelcount(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelResponse>>>;
    public odataChannelcount(oDataQueryOptions?: ChannelResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelResponse>>('get',`${this.basePath}/odata/channel/$count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
