<div class="loader-container" *ngIf="showLoadingPage">
  <div class="overlay" [ngStyle]="{'background-color': loadingType == 1 ? 'white' : 'rgb(0 0 0 / 50%)'}">
    <div class="loader-title">
      <div class="title" *ngIf="title">{{ title | translate }}</div>
      <img src="assets/base/icons/times.svg" (click)="close()" />
    </div>
    <div class="loader-image">
      <div class="wrapper">
        <ng-lottie [options]="options"></ng-lottie>
        <div class="msg" *ngIf="loadingMsg">{{ loadingMsg | translate }}</div>
      </div>
    </div>
  </div>
</div>
