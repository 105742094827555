import { Component, ChangeDetectorRef } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IRowModel, IStatusPanelParams } from 'ag-grid-community';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-total-record-renderer',
    templateUrl: './total-record-renderer.component.html',
    styleUrls: ['./total-record-renderer.component.scss'],
    standalone: true,
    imports: [NgIf, DecimalPipe]
})

export class TotalRecordRendererComponent implements IStatusPanelAngularComp{
  private params!: IStatusPanelParams;
  totalRows: number;

  constructor(private cd: ChangeDetectorRef) {}

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    this.totalRows = this.getTotalRowCount();
    this.params.api.addEventListener('gridReady', this.onGridReady.bind(this));
    this.params.api.addEventListener('paginationChanged', this.onPaginationChange.bind(this));
  }

  getTotalRowCount() {
    let totalRows = 0;
    this.params?.api?.forEachNodeAfterFilter(() => totalRows++);
    const totalRowContent: number = this.params?.api?.paginationGetRowCount();

    if(totalRows === totalRowContent) {
      return totalRows;
    } else {
      return totalRows - totalRowContent
    }
  }

  onGridReady() {
    this.totalRows = this.getTotalRowCount();
  }

  onPaginationChange() {
    this.totalRows = this.getTotalRowCount();
  }

}
