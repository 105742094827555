import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
} from 'ag-grid-community';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { LocalChannelService } from '../../channel/localChannel.service';
import { Page } from 'src/app/shared/models/page.model';
import { Mode } from 'src/app/shared/enums/mode';
import { HttpErrorResponse } from '@angular/common/http';
import { FormService } from 'src/app/shared/component/form/form.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';

@Component({
  selector: 'app-channelstore-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './channelstore-listing.component.html',
  styleUrls: ['./channelstore-listing.component.scss'],
})
export class ChannelStoreListingComponent implements OnInit {
  @Input() page: Page;

  listingSetting: ListingSetting;

  pages: Page[];
  screenContainer: any;
  rowData: any = null;
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  selectedRowData: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  storeId: number | null;
  channelId: number | null;
  formDetail: any = null;
  // Column data
  colDef: ColDef[] = [];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarListing,
    position: ActionToolbarPosition.RIGHT,
  };

  constructor(
    private pageService: PageService,
    private channelApiService: LocalChannelService,
    private formService: FormService,
    private lookupMasterService: LookupMasterService
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe((pages) => {
      this.pages = pages;
      this.screenContainer = document.getElementById('general-container');
    });
  }

  ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    // Get route parameter
    let params = this.pageService.getParameterDecode(this.page);
    this.channelId = params['channelId'];
    this.storeId = params['storeId'];

    let route = '/channel/channelstore/entry';

    if (!this.channelId)
    {
      route = '/store/storechannelstore/entry'
    }

    this.colDef = [
      listingCheckbox,
      {
        ...listingRouterLink,
        field: 'channelTag',
        headerName: 'channelstore.channel_tag',
        cellRendererParams: {
          inRouterLink: {
            route: route,
            params: ['channelId', 'storeId'],
            queryParams: { mode: Mode.edit },
            childPage: true
          },
        },
      },
      {
        ...listingRouterLink,
        field: 'locShortDesc',
        headerName: 'channelstore.store_name',
        cellRendererParams: {
          inRouterLink: {
            route: route,
            params: ['channelId', 'storeId'],
            queryParams: { mode: Mode.edit },
            childPage: true
          },
        },
      },
      {
        field: 'merchantDesc',
        headerName: 'channelstore.merchant_desc',
        filter: true,
      },
      {
        field: 'deliveryFlag',
        headerName: 'channelstore.delivery_flag',
        filter: true,
      },
      {
        field: 'deliveryMode',
        headerName: 'channelstore.delivery_mode',
        filter: true,
      },
      {
        field: 'deliverySuspendEnd',
        headerName: 'channelstore.delivery_suspend_end_time',
        filter: true,
      },
      {
        field: 'dineFlag',
        headerName: 'channelstore.dine_in_flag',
        filter: true,
      },
      {
        field: 'dineSuspendEnd',
        headerName: 'channelstore.dine_in_suspend_end_time',
        filter: true,
      },
      {
        field: 'pickupFlag',
        headerName: 'channelstore.pickup_flag',
        filter: true,
      },
      {
        field: 'pickupSuspendEnd',
        headerName: 'channelstore.pick_up_suspend_end_time',
        filter: true,
      },
      {
        field: 'qrDineFlag',
        headerName: 'channelstore.qr_dine_in_flag',
        filter: true,
      },
      {
        field: 'qrTakeawayFlag',
        headerName: 'channelstore.qr_takeaway_flag',
        filter: true,
      },
      {
        field: 'queueFlag',
        headerName: 'channelstore.queue_flag',
        filter: true,
      },
      {
        field: 'reservationFlag',
        headerName: 'channelstore.reservation_flag',
        filter: true,
      },
      {
        field: 'coverageKM',
        headerName: 'channelstore.coverage_km',
        filter: true,
      },
      {
        field: 'previewJSON',
        headerName: 'channelstore.preview_json',
        filter: true,
      },
      {
        field: 'badgeText',
        headerName: 'channelstore.badge_text',
        filter: true,
      },
      {
        field: 'promoText',
        headerName: 'channelstore.promo_text',
        filter: true,
      },
    ];
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    this.getChannelStoreData();
  }

  async getChannelStoreData(filter?: ODataRawQueryOptions) {
    this.rowData = (await this.channelApiService.reqOdataChannelStoreData(
      this.channelId, this.storeId, this.formDetail.formCode, filter
    ))['value'];
    this.getRowId = (params: GetRowIdParams) => params.data['channelId'];
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      if (this.storeId) {
        this.pageService.navigateByUrl(PageRoute.STORE_CHANNELSTORE_ENTRY, {
          storeId: this.storeId,
        }, null, true);
      }

      else {
        this.pageService.navigateByUrl(PageRoute.CHANNEL_CHANNELSTORE_ENTRY, {
          channelId: this.channelId,
        }, null, true);
      }
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
     await this.getChannelStoreData(filterString);
    }

    if (
      value.name === 'delete' &&
      this.selectedRowData &&
      this.selectedRowData['storeId'] &&
      this.selectedRowData['channelId']
    ) {
      let result = null;

      result = await this.channelApiService.deleteChannelStore(
        this.selectedRowData['channelId'],
        this.selectedRowData['storeId'],
        this.formDetail.formCode
      );

      if (result instanceof HttpErrorResponse === false) {
        this.getChannelStoreData();
      }
    }
  }

  onRowSelect(nodes: IRowNode[]) {
    this.selectedRowData = nodes[0]?.data ? nodes[0].data : null;
  }

  onPageSizeChange(pageSize: number) {}
}
