<div class="filter">
  <div class="filterContainer" (click)="report ? onClickFilterGrp($event) : onClickFilter($event);">
    <button class="filterCollapse">
      <div class="filterRegister" *ngIf="!filterQueryString; else reportView">
        <span><img style="margin: 5px;" src="assets/base/icons/alert-triangle.svg"></span>
        <span>{{"msg.no_filter_statement" | translate}}</span>
      </div>
      <ng-template class="filterRegister" #reportView>
        <div class="filterStatement" *ngIf="firstGroup; else fullStatement">
          <span class="filterStatementContent " [innerHTML]="firstGroupQuery | safeHtml"></span>
          <div class="groupsToken" *ngIf="filter && filter.length - 1 > 0">+ {{ filter.length - 1 }}
            <span *ngIf="filter.length - 1 > 1">{{'general.groups' | translate | lowercase}}</span>
            <span *ngIf="filter.length - 1 === 1">{{'general.group' | translate | lowercase}}</span>
          </div>
        </div>
        <ng-template #fullStatement>
          <span class="filterStatementContent" [innerHTML]="filterQueryString | safeHtml"></span>
        </ng-template>
      </ng-template>
      <div class="filter-icon-group" *ngIf="filter && filter.length - 1 > 0" (click)="openStatement($event);">
        <img class="filter-icon" *ngIf="firstGroup" src="assets/base/icons/chevron-down.svg">
        <img class="filter-icon" *ngIf="!firstGroup" src="assets/base/icons/chevron-up.svg">
      </div>
    </button>
  </div>

  <div class="tool" *ngIf="!report">
    <div class="toolbarBut">
      <button class="toolbarButtons" (click)="generateFilter()">
        <img style="width: 14px; margin: 8px" src="assets/base/icons/play.svg">{{"general.generate" |
        translate}}</button>
      <button class="toolbarButtons" (click)="reset()">
        <img style="width: 20px; margin: 8px" src="assets/base/icons/table.svg">{{"general.reset" |
        translate}}</button>
    </div>
  </div>

    <!-- grouping and action toolbar-->
    <div class="group-container">
      <div class="group-wrapper" *ngFor="let group of displayGroups; let i = index">
        <img src="assets/base/icons/grey-chevron-right.svg" *ngIf="i" />
        <div class="pill">
          <!-- <img class="icon-drag" src="assets/base/icons/drag.svg"> -->
          <p>{{ group.groupName | translate }}</p>
          <!-- <img class="icon-close" src="assets/base/icons/remove.svg"> -->
        </div>
      </div>
      <app-action-tool-bar [actionToolbarSetting]="actionToolbarSetting"
      (onAction)="onToolbarAction($event)" id="toolbar"></app-action-tool-bar>
    </div>
</div>
