/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BackendChannelStore } from '../model/backendChannelStore';
import { CXMProblemModel } from '../model/cXMProblemModel';
import { ChannelStoreResponse } from '../model/channelStoreResponse';
import { ChannelStoreResponseODataQueryOptions } from '../model/channelStoreResponseODataQueryOptions';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChannelStoreService {

    protected basePath = 'https://cxmbackendapi.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelstoreAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelStoreResponse>;
    public apiChannelstoreAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelStoreResponse>>;
    public apiChannelstoreAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelStoreResponse>>;
    public apiChannelstoreAddchannelstorePost(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelStoreResponse>('post',`${this.basePath}/api/channelstore/addchannelstore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param channelId 
     * @param storeId 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelstoreDeletechannelstoreDelete(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiChannelstoreDeletechannelstoreDelete(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiChannelstoreDeletechannelstoreDelete(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiChannelstoreDeletechannelstoreDelete(channelId?: number, storeId?: number, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }
        if (storeId !== undefined && storeId !== null) {
            queryParameters = queryParameters.set('storeId', <any>storeId);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('delete',`${this.basePath}/api/channelstore/deletechannelstore`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelstoreGet(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelStoreResponse>>;
    public apiChannelstoreGet(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelStoreResponse>>>;
    public apiChannelstoreGet(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelStoreResponse>>>;
    public apiChannelstoreGet(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelStoreResponse>>('get',`${this.basePath}/api/channelstore`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiChannelstoreUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelStoreResponse>;
    public apiChannelstoreUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelStoreResponse>>;
    public apiChannelstoreUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelStoreResponse>>;
    public apiChannelstoreUpdatechannelstorePut(body?: BackendChannelStore, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/json-patch+json',
            'text/json',
            'application/_*+json',
            'text/xml',
            'application/_*+xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ChannelStoreResponse>('put',`${this.basePath}/api/channelstore/updatechannelstore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataChannelStore(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelStoreResponse>>;
    public odataChannelStore(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelStoreResponse>>>;
    public odataChannelStore(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelStoreResponse>>>;
    public odataChannelStore(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelStoreResponse>>('get',`${this.basePath}/odata/channelstore`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param oDataQueryOptions 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public odataChannelStorecount(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelStoreResponse>>;
    public odataChannelStorecount(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelStoreResponse>>>;
    public odataChannelStorecount(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelStoreResponse>>>;
    public odataChannelStorecount(oDataQueryOptions?: ChannelStoreResponseODataQueryOptions, apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oDataQueryOptions !== undefined && oDataQueryOptions !== null) {
            queryParameters = queryParameters.set('oDataQueryOptions', <any>oDataQueryOptions);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChannelStoreResponse>>('get',`${this.basePath}/odata/channelstore/$count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
