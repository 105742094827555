import { Injectable } from '@angular/core';
import { MenuItemId } from '../enums/menuItemId';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { ReportViewerService } from 'src/swagger/base-rms/openapi';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { CustomHeader } from 'src/app/core/models/local/CustomHeader';
import JSZip from 'jszip';
import { FormCode } from '../enums/formCode';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  selectedSub = this.subscriptionService.getSelectedSubscription();
  rptsalesdetails = {
    dropdownItems: [MenuItemId.BizDate, MenuItemId.StatusFlag, MenuItemId.SalesMode, MenuItemId.VoidFlag, MenuItemId.LocCode],
    selectedGrouping: null
  };

  getRptSetting$: Subject<number> = new Subject();
  updateRptSetting$: Subject<number> = new Subject();
  resetRptSetting$: Subject<any> = new Subject();
  openRptFilterGrp$: Subject<any> = new Subject();
  resetFilter$: Subject<any> = new Subject();

  constructor(
    private customService: CustomService,
    private reportViewerService: ReportViewerService,
    private subscriptionService: SubscriptionService
  ) { }

  customReq(reqBody: any, method: HttpMethod, reqPath: string, hostPath: string, header: CustomHeader, queryParams: null) {
    let customRequest: CustomRequest = {
      httpMethod: method,
      requestpath: reqPath,
      hostPath: hostPath,
      headers: header,
      body: reqBody,
      httpHeaderType: undefined,
      queryParams: queryParams
    }
    return customRequest;
  }

  async getReportResult(formCode: string, jobId: number, password: boolean = false) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.reportViewer.GetReportResult,
      hostPath: this.selectedSub.apiUrl4,
      headers: {
        formCode: formCode
      },
      queryParams: {
        jobId: jobId,
        password: password
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest, true);
    return respInfo;
  }

  async getUnzipFiles(listOfZipFiles: any[], password?: string) {
    let combinedContent: any[] = [];
    // Unzip each zip file
    for (let index = 0; index < listOfZipFiles.length; index++) {
      let url = listOfZipFiles[index];
      try {
        let zipBlob = await this.fetchZipFile(url);
        let filesContent = await this.unzipFile(zipBlob, password);
        combinedContent = combinedContent.concat(filesContent);
      } catch (error) {
        console.error(`Error processing zip file from ${url}:`, error);
      }
    }

    return combinedContent;
  }

  async fetchZipFile(url: string): Promise<Blob> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      }
      return await response.blob();
    } catch (error) {
      console.error('Error fetching zip file:', error);
      throw error;
    }
  }

  async unzipFile(blob: Blob, password?: string): Promise<any> {
    try {
      return await JSZip.loadAsync(blob)
        .then(function (zip) {
          var fileName = '';
          zip.forEach(function (relativePath, file) {
            fileName = relativePath;
          });

          return zip.file(fileName).async("text");

        }).then(function (text) {
          var data = JSON.parse(text);
          return data;
        });
    } catch (error) {
      console.error('Error unzipping file:', error);
      throw error;
    }
  }

  public async generateReport(api: string, formCode: string, reqBody: any) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: api,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest, isOriBody?: boolean) {
    return this.customService.createRequest(customRequest, false, isOriBody).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}
