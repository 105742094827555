import { Injectable } from "@angular/core";

import { HttpMethod } from "src/app/core/enums/HttpMethod";
import { CustomRequest } from "src/app/core/models/local/CustomRequest";
import { CustomService } from "src/app/core/services/custom.service";
import { LoaderService } from "src/app/shared/services/loader.service";
import { ODataRawQueryOptions } from "src/swagger/cxmbackend/openapi";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class TaxService {
  constructor(
    private customService: CustomService,
    private loaderService: LoaderService,
  ) { }

  // #region Tax
  async getOdataTax(taxCode?: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataTax(taxCode, data, formCode);
  }
  async deleteTax(taxCode: string, formCode?: string) {
    return await this.reqDeleteTax(taxCode, formCode);
  }
  // #endregion

 // #region TaxGroup
 async getOdataTaxGroup(taxGroupCode?: string, data?: ODataRawQueryOptions, formCode?: string) {
  return await this.reqOdataTaxGroup(taxGroupCode, data, formCode);
}
async deleteTaxGroup(taxGroupCode: string, formCode?: string) {
  return await this.reqDeleteTaxGroup(taxGroupCode, formCode);
}
// #endregion


  // #region TaxConditionH
  async getOdataTaxConditionH(taxCondCode?: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataTaxConditionH(taxCondCode, data, formCode);
  }
  async deleteTaxConditionH(taxCondCode: string, formCode?: string) {
    return await this.reqDeleteTaxConditionH(taxCondCode, formCode);
  }
  // #endregion

  // #region TaxConditionD
  async getOdataTaxConditionD(taxCondCode?: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataTaxConditionD(data, formCode);
  }
  async deleteTaxConditionD(taxCondCode: string, taxCondLine: string, formCode?: string) {
    return await this.reqDeleteTaxConditionD(taxCondCode, taxCondLine, formCode);
  }
  // #endregion

  // #region TaxConditionF
  async getOdataTaxConditionF(taxCondCode?: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataTaxConditionF(data, formCode);
  }
  async deleteTaxConditionF(taxCondCode: string, taxCondLine: string, filterLine: string, formCode?: string) {
    return await this.reqDeleteTaxConditionF(taxCondCode, taxCondLine, filterLine, formCode);
  }
  // #endregion

  // #region Tax
  private async reqOdataTax(taxCode?: string, data?: ODataRawQueryOptions, formCode?: string) {

    let filter = '';
    filter += taxCode ? 'taxCode eq \'' + taxCode + '\'': '';
    filter += taxCode && data ? ' and ' : '';
    filter += data ? data.filter.replace('$filter=', '') : '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.tax.tax.OdataTax,
      hostPath: environment.hostPath,
      queryParams: filter ===''?{
        $count: true
      }:{
        $count: true,
        $filter: filter
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqDeleteTax(taxCode: string, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.tax.tax.DeleteTax,
      hostPath: environment.hostPath,
      queryParams: {
        taxCode: taxCode,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

   // #region Tax
   private async reqOdataTaxGroup(taxCode?: string, data?: ODataRawQueryOptions, formCode?: string) {

    let filter = '';
    filter += taxCode ? 'taxGroupCode eq \'' + taxCode + '\'': '';
    filter += taxCode && data ? ' and ' : '';
    filter += data ? data.filter.replace('$filter=', '') : '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.tax.taxGroup.OdataTaxGroup,
      hostPath: environment.hostPath,
      queryParams: filter ===''?{
        $count: true
      }:{
        $count: true,
        $filter: filter
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqDeleteTaxGroup(taxGroupCode: string, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.tax.taxGroup.DeleteTaxGroup,
      hostPath: environment.hostPath,
      queryParams: {
        taxGroupCode: taxGroupCode,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

  // #region TaxConditionH
  private async reqOdataTaxConditionH(taxCondCode?: string, data?: ODataRawQueryOptions, formCode?: string) {

    let filter = '';
    filter += taxCondCode ? 'taxCondCode eq \'' + taxCondCode + '\'': '';
    filter += taxCondCode && data ? ' and ' : '';
    filter += data ? data.filter.replace('$filter=', '') : '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.tax.taxConditiohH.OdataTaxConditionH,
      hostPath: environment.hostPath,
      queryParams: filter ===''?{
        $count: true
      }:{
        $count: true,
        $filter: filter
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqDeleteTaxConditionH(taxCondCode: string, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.tax.taxConditiohH.DeleteTaxConditionH,
      hostPath: environment.hostPath,
      queryParams: {
        taxCondCode: taxCondCode,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

  // #region TaxConditionD
  private async reqOdataTaxConditionD(data?: ODataRawQueryOptions, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.tax.taxConditionD.OdataTaxConditionD,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: data?.filter.replace('$filter=', '')
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqDeleteTaxConditionD(taxCondCode: string, taxCondLine: string, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.tax.taxConditionD.DeleteTaxConditionD,
      hostPath: environment.hostPath,
      queryParams: {
        taxCondCode: taxCondCode,
        taxCondLine: taxCondLine,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

  // #region TaxConditionF
  private async reqOdataTaxConditionF(data?: ODataRawQueryOptions, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.tax.taxConditionF.OdataTaxConditionF,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: data?.filter.replace('$filter=', '')
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqDeleteTaxConditionF(taxCondCode: string, taxCondLine: string, filterLine: string, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.tax.taxConditionF.DeleteTaxConditionF,
      hostPath: environment.hostPath,
      queryParams: {
        taxCondCode: taxCondCode,
        taxCondLine: taxCondLine,
        filterLine: filterLine,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

  getSignature(apiPath: string, options: any) {
    let optionsSpaceFormat = options.replace(/\s/g, "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: apiPath + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}

