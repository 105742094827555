import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-license-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './license-widget.component.html',
  styleUrls: ['./license-widget.component.scss']
})
export class LicenseWidgetComponent {

}
