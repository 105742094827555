import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FilterComponent } from "../../filter/filter.component";
import { SharedFilterComponent } from '../../filter/shared-filter/shared-filter.component';
import { ActionToolBarSetting } from '../../action-tool-bar/model/ActionToolBarSetting.model';

@Component({
  selector: 'app-filter-group-statement',
  standalone: true,
  templateUrl: './filter-group-statement.component.html',
  styleUrls: ['./filter-group-statement.component.scss'],
  imports: [CommonModule, SharedFilterComponent, TranslateModule, FilterComponent]
})

export class FilterGroupStatementComponent {

  @Input() filters?: any;
  @Input() filter: any[];
  @Input() reportData: any;
  @Input() formCode?: any;
  @Input() selectedGroups?: any;
  @Input() selectionGroups?: any;
  @Input() filterItems?: any;
  @Input() filterQueryString: string;
  @Input() firstGroupQuery: string;
  @Input() isDatePickerMode: boolean = true;
  @Input() editMode: boolean = true;
  @Input() queryHTML: string;
  @Input() defaultFilter: any;
  @Input() default: any;
  @Input() reportViewerFilter: any;
  @Input() filterGrpData: any;
  @Input() rptJobFilter: any
  @Input() countBy: number;
  @Input() report: boolean;
  @Input() actionToolbarSetting: ActionToolBarSetting;

  @Output() onFilterGroupData = new EventEmitter();
  @Output() onActionToolbar = new EventEmitter();
  @Output() queryStringPass = new EventEmitter();

  @ViewChild('sharedFilter') sharedFilter: SharedFilterComponent;

  reset() {
    this.sharedFilter.reset();
  }

  filterGroupData(data: any) {
    this.onFilterGroupData.emit(data);
  }

  onQueryStringPass(text: any) {
    this.queryStringPass.emit(text);
  }

  onToolbarAction(event: any) {
    this.onActionToolbar.emit(event);
  }
}
