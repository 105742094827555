/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ActivityResponse { 
    activityId?: number;
    activityGUID?: string;
    customerId?: number;
    storeId?: number;
    orderTranId?: number;
    payTranid?: number;
    salesTranId?: number;
    linkId?: number;
    rsvLinkId?: number;
    orderNo?: string;
    billNo?: string;
    custName?: string;
    mobileNo?: string;
    email?: string;
    tranDate?: Date;
    tableNo?: string;
    guestCount?: number;
    currCode?: string;
    netAmt?: number;
    netAmtExc?: number;
    totalItemQty?: number;
    remarks?: string;
    orderDesc?: string;
    checkOrderUrl?: string;
    statusFlag?: number;
    address?: string;
    addDesc?: string;
    addUnit?: string;
    addCity?: string;
    addPostal?: string;
    addState?: string;
    addCountry?: string;
    latitude?: number;
    longitude?: number;
    distanceKM?: number;
    sourceFlag?: number;
    reqTime?: Date;
    estimatedTime?: Date;
    delPlatformCode?: string;
    delPlatformRefId?: string;
    trackingURL?: string;
    deliveryCost?: number;
    deliveryInfo?: string;
    lastActivityType?: number;
    chatId?: string;
    activeFlag?: boolean;
    completeFlag?: boolean;
    ratedFlag?: boolean;
    cancelFlag?: boolean;
    refundedFlag?: boolean;
    deliveredFlag?: boolean;
    preAuthFlag?: boolean;
}