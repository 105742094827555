import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToggleInputComponent } from '../../input/toggle-input/toggle-input.component';
import { ToggleInputSetting } from '../../input/model/ToggleInputSetting.model';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { SearchbarComponent } from '../../input/searchbar/searchbar.component';
import { ResizableDividerComponent } from '../../resizable-divider/resizable-divider.component';
import { PageService } from 'src/app/shared/services/page.service';
import { ReportViewerService } from '../../services/report-viewer.service';
import { UppercaseTranslatePipe } from 'src/app/shared/pipes/uppercase-translate.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-group-selection',
  standalone: true,
  imports: [CommonModule, TranslateModule, ToggleInputComponent, CdkDropList, CdkDrag, CdkDragHandle, ReactiveFormsModule, SearchbarComponent, NgClass, ResizableDividerComponent, UppercaseTranslatePipe],
  templateUrl: './group-selection.component.html',
  styleUrls: ['./group-selection.component.scss']
})
export class GroupSelectionComponent implements OnInit {
  @Input() formCode: any;
  @Input() selectionGroups: any;
  @Input() selectedGroups: any;
  @Input() editMode: boolean;
  @Input() isMobile: boolean;
  @Output() onSelectedChange = new EventEmitter<any>();

  @ViewChild('topDiv', { static: true }) topDiv!: ElementRef;
  @ViewChild('bottomDiv', { static: true }) bottomDiv!: ElementRef;

  formGroup: UntypedFormGroup;
  toggleInputSetting: ToggleInputSetting;
  toggleInputSettings: ToggleInputSetting[] = [];
  selectionList: any[];
  filterTimer: any;
  displaySelection: any;
  topHeight: number;
  bottomHeight: number;

  constructor(private fb: FormBuilder,
    private translateService: TranslateService,
    private pageService: PageService) {}

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});

    this.toggleInputSetting = {
      inline: true,
      label: 'group.show_group'
    }

    this.selectedGroups = _.cloneDeep(this.selectedGroups);
    this.selectionList = _.cloneDeep(this.selectionGroups);
    this.selectionGroups = _.cloneDeep(this.filterSelection(this.selectionGroups, this.selectedGroups));
    this.displaySelection = this.selectionGroups;
    this.selectedChange();
  }

  filterSelection(selectionList: any[], selectedList: any[]) {
    let selectedGrpId = selectedList?.map((itm: any) => itm.groupId);

    selectionList?.forEach((data: any, index: number) => {
      if(selectedGrpId?.includes(data.groupId)) {
        this.selectionGroups.splice(index, 1);
      }
    });

    return selectionList;
  }

  selectedChange() {
    // add toggle button
    this.toggleInputSettings = [];

    this.selectedGroups?.forEach((data: any, index: number) => {
      this.toggleInputSettings.push({
        name: 'toggleInput-' + data.groupId,
        inline: true,
        label: 'report.show_group',
        disabled: true
      });

      this.formGroup.addControl(this.toggleInputSettings[index].name, this.fb.control(false));
      this.formGroup.get(this.toggleInputSettings[index].name).enable();
      this.formGroup.get(this.toggleInputSettings[index].name).setValue(data?.showTopN);

      if((index + 1) === this.selectedGroups?.length) {
        this.formGroup.get(this.toggleInputSettings[index].name).setValue(true);
        this.formGroup.get(this.toggleInputSettings[index].name).disable();
      }

      if(!this.editMode) {
        this.formGroup.get(this.toggleInputSettings[index].name).disable();
      }

      if(index > 0 && (index + 1) !== this.selectedGroups?.length && this.selectedGroups[index - 1]?.showTopN === this.selectedGroups[index + 1]?.showTopN) {
        this.selectedGroups[index].showTopN = this.selectedGroups[index - 1]?.showTopN;
        this.formGroup.get(this.toggleInputSettings[index].name).setValue(this.selectedGroups[index - 1]?.showTopN);
      }
    });

    this.onSelectedChange.emit(this.selectedGroups);
  }

  onSelected(itm: any) {
    let index = this.selectionGroups?.findIndex((data: any) => data.groupId === itm.groupId);
    if(index >= 0) {
      this.selectionGroups.splice(index, 1);
      this.selectedGroups.push(itm);
      this.displaySelection = this.selectionGroups;
    }
    this.selectedChange();

    // scroll selected group to bottom
    setTimeout(() => {
      if(this.topDiv) {
        this.topDiv.nativeElement.scrollTop = this.topDiv.nativeElement.scrollHeight;
      }
    }, 0);
  }

  onDeselected(event: any, itm: any) {
    event.preventDefault();
    let index = this.selectedGroups?.findIndex((data: any) => data.groupId === itm.groupId);
    if(index >= 0) {
      this.selectedGroups.splice(index, 1);
      itm.showTopN = false;
      this.selectionGroups.unshift(itm);
      this.displaySelection = this.selectionGroups;
    }

    this.selectedChange();
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.selectedChange();
  }

  onToggleChange(event: any) {
    if(environment.rptGrpAutoSelect) {
      let isBelow: boolean = false;

      this.selectedGroups.map((selected: any) => {
        let name = 'toggleInput-' + selected.groupId;

        if(name === event.name) {
          selected.showTopN = event.checked;
          isBelow = true;
        } else if(!isBelow && event.checked && !this.formGroup.get(name)?.disabled) {
          selected.showTopN = event.checked;
          this.formGroup.get(name)?.setValue(event.checked);
        } else if(isBelow && !event.checked && !this.formGroup.get(name)?.disabled) {
          selected.showTopN = event.checked;
          this.formGroup.get(name)?.setValue(event.checked);
        }
      });
    }

  }

  onClickToggle(event: any) {
    if(event?.target?.className?.includes('mdc-switch')) {
      event.stopPropagation();
    }
  }

  onSearch(event?: any) {
    if(this.filterTimer) {
      clearTimeout(this.filterTimer);
    }

    this.filterTimer = setTimeout(() => {
      this.displaySelection = this.selectionGroups.filter((data: any) => data.groupName && this.translateService.instant(data.groupName).toLowerCase().includes(event?.searchInput?.toLowerCase()));
    }, 800);
  }

  onResize(event: { clientY: number }) {
    const clientY = event.clientY;

    const newTopHeight = clientY;
    const newBottomHeight = window.innerHeight - clientY;

    if (newTopHeight > 50 && newBottomHeight > 50) {
      this.topHeight = newTopHeight;
      this.bottomHeight = newBottomHeight;
    }
  }

  onQuitEditMode() {
    this.pageService.quitEditMode$.next();
  }
}
