import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpMethod } from '../../core/enums/HttpMethod';
import { CustomRequest } from '../../core/models/local/CustomRequest';
import { CustomService } from '../../core/services/custom.service';
import OdataProvider from 'ag-grid-odata';
import { TranslateService } from '@ngx-translate/core';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomHeader } from 'src/app/core/models/local/CustomHeader';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { FormCode } from 'src/app/shared/enums/formCode';

@Injectable({
  providedIn: 'root'
})
export class LocalMerchantService {

  constructor(
    private customService: CustomService,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private loaderService: LoaderService,
    private utilsService: UtilsService
  ) { }

  async getOdataMerchant(merchantId: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataMerchantData(merchantId, data, formCode);
  }

  getOdataMerchantProvider() {
    return this.reqOdataMerchantProvider();
  }

  private async reqOdataMerchantData(merchantId?: string, data?: ODataRawQueryOptions, formCode?: string) {
    let filter = undefined;
    filter = merchantId? 'merchantId eq ' + merchantId: '';
    filter += data ? data.filter.replace('$filter=', '') : '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.merchant.OdataMerchant,
      hostPath: environment.hostPath,
      queryParams: filter ===''?{
        $count: true
      }:{
        $count: true,
        $filter: filter
      },
      headers:{
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private reqOdataMerchantProvider() {
    const apiPath = environment.apis.merchant.OdataMerchant;
    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${this.utilsService.fixedEncodeURIComponent(options)}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              "Content-Language": this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
      }
    });
  }


  getSignature(apiPath: string, options: any) {
    let optionsSpaceFormat = options.replace(/\s/g , "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: apiPath + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  public async reqUpdateMerchant(formData: FormData, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.merchant.UpdateMerchant,
      hostPath: environment.hostPath,
      body: formData,
      headers: {
        contentType: undefined,
        apiVersion: '2.0',
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  public async reqCreateMerchant(formData: FormData, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.merchant.RegisterMerchant,
      hostPath: environment.hostPath,
      body: formData,
      headers: {
        contentType: undefined,
        apiVersion: '2.0',
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }
}
