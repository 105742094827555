import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ColDef, GetRowIdFunc, IRowNode } from 'ag-grid-community';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';

import { HttpErrorResponse } from '@angular/common/http';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Page } from 'src/app/shared/models/page.model';
import { TaxService } from '../../tax.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-taxgroup-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './taxgroup-listing.component.html',
  styleUrls: ['./taxgroup-listing.component.scss']
})
export class TaxGroupListingComponent {
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;

  locDesc: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  listingSetting: ListingSetting;
  rowData: any = null;
  formDetail: any = null;
  pages: Page[];
  screenContainer: any;
  // Column data
  colDef: ColDef[] = [];
  refresh$: Subscription;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarListing
  };
  selectedRowData: any;
  page: Page;

  constructor(
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private taxService: TaxService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService,
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe(pages => {
      this.pages = pages;
      this.screenContainer = document.getElementById("general-container");
    });}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide
    }
     // Column data
    this.colDef = [
    listingCheckbox,
    {
      ...listingRouterLink,
      field: 'taxGroupCode',
      headerName: 'Tax Group Code',
      filter: 'agTextColumnFilter',
      cellRendererParams: {
        inRouterLink: {
          route: '/tax/taxgroupentry',
          params: ['taxGroupCode'],
        }
      },
    },
    {
      field: 'taxGroupDesc',
      headerName: 'Description',
      filter: 'agTextColumnFilter'
    },
  ];

    await this.getTaxGroup();

    this.refresh$ = this.pageService.refreshListing$.subscribe(
      (formCode: any) => {
        if (formCode.includes(FormCode.taxgrouplisting)) {
          this.getTaxGroup();
        }
      }
    );
  }

  async getTaxGroup(queryOpts?: ODataRawQueryOptions ) {
    let result = await this.taxService.getOdataTaxGroup(null, queryOpts, this.formDetail.formCode);
    if (result instanceof HttpErrorResponse === false){
      this.rowData = result['value'];
    }
  }

  closePopup() {
    this.popupFormService.close(this.formCode.taxlisting);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.TAXGROUP_ENTRY);
    }
    if (value.name === 'delete'
    && this.selectedRowData
    && this.selectedRowData.data['taxGroupCode']) {
      let result = await this.taxService.deleteTaxGroup(this.selectedRowData.data['taxGroupCode'], this.formDetail.formCode);
        if (result instanceof HttpErrorResponse === false){
          this.pageService.refresh([FormCode.taxgrouplisting]);
        }
    }
    if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
      await this.getTaxGroup(filterString);
    }
  }

  onPageSizeChange(pageSize: number) {}

  ngOnDestroy(): void {
    this.refresh$?.unsubscribe();
  }

  onRowSelect(nodes: IRowNode[]) {
    this.selectedRowData = nodes[0] ? nodes[0] : null;
  }
}
