// menu.store.ts
import { createStore } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { Menu } from './menu.repository';

const initialState: Menu[] = []; // Initial state can be empty or preloaded data

export const menuStore = createStore(
  { name: 'menu' },
  withEntities<Menu>({ initialValue: initialState, idKey: 'id' })
);
