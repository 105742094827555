import { Injectable } from '@angular/core';
import { PopupMessage } from '../../models/popupMessage.model';
import { Observable } from 'rxjs';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    public dialog: MatDialog
  ) { }

  getDialogResp(popupMsg: PopupMessage): Observable<string> {
    let dialogRef = this.dialog.open(PopupMessageComponent, { data: popupMsg });
    return dialogRef.afterClosed();
  }
}
