<div class="listing-layout" *ngIf="formCode">
  <div class="ag-grid-table-container" style="padding: 0" [ngClass]="formCode" #agGridTable>
    <div class="action-msg-bar">
      <div class="message">
        <app-message [msgs]="" [msgType]=""></app-message>
      </div>
      <div class="tool-bar-container" *ngIf="actionToolbarSetting">
        <app-action-tool-bar [formDetail]="formDetail" [actionToolbarSetting]="actionToolbarSetting"
          [isRowSelected]="isRowSelected" (onAction)="onActionToolbar($event)"
          (onActionKeydown)="onActionKeydown($event)"></app-action-tool-bar>
      </div>
    </div>

    <ag-grid-angular class="ag-theme-alpine ag-grid-table" [scrollbarWidth]="isMobile ? 4 : 12" [statusBar]="statusBar" [getRowId]="getRowId"
      [gridOptions]="gridOptions" [components]="frameworkComponents" [context]="context"
      [rowSelection]="setting.rowSelection" [rowMultiSelectWithClick]="rowMultiSelectWithClick" [editType]="editType"
      [undoRedoCellEditing]="true" [rowModelType]="setting.rowModelType"
      [cacheBlockSize]="setting.rowModelType === rowModelType.serverSide? setting.pageSize[0]: undefined"
      [suppressServerSideInfiniteScroll]="false"
      [suppressRowClickSelection]="!lookDropInputSetting && suppressRowClickSelection"
      [groupDisplayType]="groupDisplayType" [pagination]="agGridPagination" [paginationAutoPageSize]="false"
      [suppressPaginationPanel]="true" [groupDefaultExpanded]="1" [suppressClickEdit]="true"
      [stopEditingWhenCellsLoseFocus]="true" [enableCellChangeFlash]="true"
      [style]="{'height': setting.height? setting.height: 'calc(100vh - 137px)'}" [getRowHeight]="getRowHeight"
      [domLayout]="domLayout" [excelStyles]="excelStyles" [defaultExcelExportParams]="defaultExcelExportParams"
      [autoSizeStrategy]="autoSizeStrategy" [rowClassRules]="setting.rowClassRules" [sideBar]="sideBar" (gridReady)="onGridReady($event)"
      (dragStarted)="onDragStarted()" (dragStopped)="onDragStop()" (cellValueChanged)="onCellValueChanged($event)"
      (cellClicked)="onCellClicked($event)" (cellEditingStopped)="onCellEditingStopped($event)"
      (rowDataUpdated)="onRowDataUpdate($event)" (rowSelected)="onRowSelected($event)" (rowClicked)="onRowClicked()"
      (rowDoubleClicked)="onRowDoubleClick()" (columnRowGroupChanged)="columnRowGroupChanged()"
      (firstDataRendered)="onFirstDataRendered()"  (gridColumnsChanged)="gridColumnChange($event)">
    </ag-grid-angular>
    <button type="button" tabindex="0" class="apply-btn" [ngClass]="{'errorApply': !selectedRowNodes}"
      (click)="onClickApply()" *ngIf="lookDropInputSetting?.multiSelect" #applyBtn>{{ 'general.apply' | translate
      }}</button>
  </div>
</div>
