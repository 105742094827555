import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColDef, GetRowIdFunc } from 'ag-grid-community';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { DealerService } from '../dealer.service';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Mode } from 'src/app/shared/enums/mode';
import { HttpErrorResponse } from '@angular/common/http';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { DealerStatus } from 'src/swagger/cxmbackend/openapi/model/dealerStatus';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Page } from 'src/app/shared/models/page.model';

@Component({
  selector: 'app-dealer-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './dealer-listing.component.html',
  styleUrls: ['./dealer-listing.component.scss'],
})
export class DealerListingComponent {
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;

  locDesc: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  // Column data
  colDef: ColDef[] = [];
  listingSetting: ListingSetting;
  rowData: any;
  formDetail: any = null;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarListing,
  };
  dealerStatus: any = DealerStatus;
  page: Page;

  constructor(
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dealerService: DealerService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService,
  ) {}

  ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    // Column data
    this.colDef = [
      {
        ...listingRouterLink,
        field: 'dealerId',
        headerName: 'Dealer ID',
        cellRendererParams: {
          inRouterLink: {
            route: '/dealer/master/entry',
            params: ['dealerId'],
            queryParams: { mode: Mode.edit },
            childPage: true
          },
        },
        sortable: true,
      },
      {
        field: 'dealerName',
        headerName: 'Name',
        filter: true,
      },
      {
        ...listingDropdown,
        field: 'statusFlag',
        headerName: 'Status',
        cellEditorParams: {
          options: Object.keys(this.dealerStatus).map(
            (key) => this.dealerStatus[key]
          ),
        },
        initialFlex: 1,
      },
      {
        field: 'address1',
        headerName: 'Address 1',
        filter: true,
      },
       {
        field: 'address2',
        headerName: 'Address 2',
        filter: true,
      },
      {
        field: 'address3',
        headerName: 'Address 3',
        filter: true,
      },
      {
        field: 'addCity',
        headerName: 'City',
        filter: true,
      },
      {
        field: 'addPostal',
        headerName: 'Postal',
        filter: true,
      },
      {
        field: 'countryCode',
        headerName: 'Country',
        filter: true,
      },
      {
        field: 'stateCode',
        headerName: 'State',
        filter: true,
      },
      {
        field: 'regionCode',
        headerName: 'Region',
        filter: true,
      },
      {
        field: 'areaCode',
        headerName: 'Area',
        filter: true,
      },
      {
        field: 'email',
        headerName: 'Email',
        filter: true,
      },
      {
        field: 'mobileNo',
        headerName: 'Mobile No',
        filter: true,
      },
      {
        field: 'phoneNo',
        headerName: 'Phone No',
        filter: true,
      },
      {
        field: 'statementEmails',
        headerName: 'Statement Emails',
        filter: true,
      },
      {
        field: 'bankCode',
        headerName: 'Bank Code',
        filter: true,
      },
      {
        field: 'bankAccountNo',
        headerName: 'Bank Account No',
        filter: true,
      },
      {
        field: 'bankAccountName',
        headerName: 'Bank Account Name',
        filter: true,
      },

    ];
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    this.getDealerData();
  }

  async getDealerData() {
    let result = await this.dealerService.getOdataDealer(null, null, this.formDetail.formCode);
    this.rowData = result.value && result.value.length > 0? result.value: [];
    // this.rowData = this.dealerService.getOdataDealerProvider();
  }

  closePopup() {
    this.popupFormService.close(this.formCode.dealerlisting);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.DEALER_MASTER_ENTRY, null, null, true);
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
      let result = await this.dealerService.getOdataDealer(null, filterString, this.formDetail.formCode);
      if (!(result instanceof HttpErrorResponse)) {
        this.rowData = result['value'];
      }
    }
  }

  onPageSizeChange(pageSize: number) {}
}
