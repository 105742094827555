import { Component, OnInit, Output, Input, ViewChild, EventEmitter, SimpleChanges, OnChanges, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ColDef, ExcelStyle, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, RowGroupingDisplayType, IRowNode, StatusPanelDef, ExcelExportParams, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy, SizeColumnsToContentStrategy, GridOptions, SideBarDef } from 'ag-grid-community';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ButtonRendererComponent } from 'src/app/shared/component/ag-grid/button-renderer/button-renderer.component';
import { DropdownCellEditorComponent } from 'src/app/shared/component/ag-grid/dropdown-cell-editor/dropdown-cell-editor.component';
import { DropdownRendererComponent } from 'src/app/shared/component/ag-grid/dropdown-renderer/dropdown-renderer.component';
import { PaginatorRendererComponent } from 'src/app/shared/component/ag-grid/paginator-renderer/paginator-renderer.component';
import { RouterLinkRendererComponent } from 'src/app/shared/component/ag-grid/router-link-renderer/router-link-renderer.component';
import { TotalRecordRendererComponent } from 'src/app/shared/component/ag-grid/total-record-renderer/total-record-renderer.component';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { Page } from 'src/app/shared/models/page.model';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { AgGridService } from 'src/app/shared/services/ag-grid.service';
import { PageService } from 'src/app/shared/services/page.service';
import { ListsRepository } from 'src/app/state/list.repository';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from '../action-tool-bar/model/ActionToolBarResp.model';
import { LookDropInputSetting } from '../input/model/LookDropInputSetting.model';
import { AgGridModule } from 'ag-grid-angular';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { MessageComponent } from '../message/message.component';
import { NgIf, NgClass } from '@angular/common';
import { ButtonRendererTypeEnum } from '../../enums/buttonRendererType';
import { ListingSetting } from './model/ListingSetting.model';
import { RowModelType } from '../../enums/rowModelType';
import { RowSelection } from '../../enums/rowSelection';
import OdataProvider from 'ag-grid-odata';
import { FormService } from '../form/form.service';
@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, MessageComponent, ActionToolBarComponent, AgGridModule, TranslateModule]
})
export class ListingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() setting?: ListingSetting;
  @Input() colDef: ColDef[];
  @Input() rowData?: any;
  @Input() formCode?: any;
  @Input() pageRoute: any;
  @Input() actionToolbarSetting?: ActionToolBarSetting;
  @Input() getRowId?: GetRowIdFunc;
  @Input() customColDef: ColDef;
  @Input() lookDropInputSetting: LookDropInputSetting;
  @Input() selectedNodes: { node?: IRowNode, value?: any }[] = [];
  @Input() suppressRowClickSelection: boolean;
  @Input() defaultExcelExportParams: ExcelExportParams;
  @Input() defaultColDef: ColDef;
  @Input() excelStyles: ExcelStyle[];
  @Output() onToolbarAction = new EventEmitter<ActionToolBarResp>();
  @Output() onGridApi = new EventEmitter<GridApi<any>>();
  @Output() onPageSizeChange = new EventEmitter<number>();
  @Output() onListingRowSelected = new EventEmitter<IRowNode[]>();
  @Output() onListingRowClicked = new EventEmitter<IRowNode[]>();
  @Output() onRowDoubleClicked = new EventEmitter<any>();
  @Output() onApplySelectedRow = new EventEmitter<IRowNode[]>();
  @Output() onRowDataUpdated = new EventEmitter<GridApi>();
  @Output() onGridColumnChange = new EventEmitter<GridApi>();
  @ViewChild('agGridTable', { static: false }) agGridTable: any;
  @ViewChild('applyBtn', { static: false }) applyBtnEl: ElementRef;

  rightAligned: {
    headerClass: 'ag-right-aligned-header',
    cellClass: 'ag-right-aligned-cell'
  }

  private gridApi!: GridApi;

  rowModelType = RowModelType;

  pages: Page[];
  editType: 'fullRow';
  context: any;
  frameworkComponents: any;
  formDetail: any = null;
  editingRowId: number | null = null;
  selectedRowNodes: IRowNode[];
  currPageSize: number;

  isRowSelected: boolean = false;
  isMobile: boolean = false;
  rowMultiSelectWithClick: boolean = false;
  agGridPagination: boolean = false;

  list$: Subscription;
  actionBtn$: Subscription;

  statusBar: { statusPanels: StatusPanelDef[]; };
  isEditingMode: boolean = false;

  public groupDisplayType: RowGroupingDisplayType = 'groupRows';

  domLayout: any;
  gridHeight: string;
  getRowHeight: any;
  rowHeight: any = 0;
  headerHeight: any;
  gridOptions: GridOptions;
  sideBar: SideBarDef;
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
      defaultMinWidth: 100,
    };

  constructor(
    private repo: ListsRepository,
    private pageService: PageService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private agGridService: AgGridService,
    private dialog: MatDialog,
    private formService: FormService
  ) {
    this.context = { componentParent: this };
  }

  ngOnInit(): void {
    // Col Def
    this.defaultColDef = {
      resizable: true,
      enableRowGroup: true,
      editable: false,
      sortable: true,
      filter: true,
      useValueFormatterForExport: this.defaultColDef?.useValueFormatterForExport? this.defaultColDef.useValueFormatterForExport: true,
      headerClass: this.defaultColDef?.headerClass? this.defaultColDef.headerClass: '',
      cellClass: this.defaultColDef?.cellClass? this.defaultColDef.cellClass: '',
      cellClassRules: this.defaultColDef?.cellClassRules? this.defaultColDef.cellClassRules: null
    };

    // Setting
    this.setting = {
      rowModelType: this.setting?.rowModelType ? this.setting.rowModelType : RowModelType.serverSide,
      pagination: this.setting?.pagination ? this.setting.pagination : true,
      pageSize: this.setting?.pageSize ? this.setting.pageSize : [100],
      rowSelection: this.setting?.rowSelection ? this.setting.rowSelection : RowSelection.multiple,
      refresh: this.setting?.refresh ? this.setting.refresh : true,
      height: this.setting?.height ? this.setting.height : '',
      rowClassRules: this.setting?.rowClassRules ? this.setting.rowClassRules : null,
      duplicateRow: typeof this.setting?.duplicateRow == "boolean" ? this.setting.duplicateRow: false,
      sideBar: this.setting.sideBar ? this.setting.sideBar: []
    }

    this.setting.rowSelection = !this.lookDropInputSetting ? this.setting.rowSelection : !this.lookDropInputSetting.multiSelect ? RowSelection.single : RowSelection.multiple;
    this.rowMultiSelectWithClick = this.setting.rowSelection === RowSelection.single ? false : true;
    this.agGridPagination = this.setting.pagination && this.setting.rowModelType !== RowModelType.infinite;

    if (this.setting.height === 'auto') {
      this.domLayout = 'autoHeight';
    } else {
      this.domLayout = 'normal';
    }

    if(this.setting?.sideBar && this.setting?.sideBar.length > 0) {
      this.sideBar = {
        toolPanels: this.setting.sideBar
      }
    }

    // Component
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      totalRecordRenderer: TotalRecordRendererComponent,
      paginatorRenderer: PaginatorRendererComponent,
      routerLinkRenderer: RouterLinkRendererComponent,
      dropdownRenderer: DropdownRendererComponent,
      dropdownCellEditor: DropdownCellEditorComponent,
    };

    // Excel Styles
    let defaultExcelStyles: ExcelStyle[] = [
      {
        id: 'numberType',
        numberFormat: {
          format: '0',
        },
      },
      {
        id: 'currencyFormat',
        numberFormat: {
          format: '#,##0.00',
        },
        alignment: {
          horizontal: "Right"
        }
      },
      {
        id: 'percentageFormat',
        numberFormat: {
          format: '0.000',
        },
      },
      {
        id: 'booleanType',
        dataType: 'Boolean',
      },
      {
        id: 'stringType',
        dataType: 'String',
      },
      {
        id: 'dateType',
        dataType: 'DateTime',
      },
      {
        id: 'hyperlinks',
        font: {
          underline: 'Single',
          color: '#358ccb'
        }
      }
    ];

    if (this.excelStyles) {
      this.excelStyles = [...defaultExcelStyles, ...this.excelStyles];
    }

    this.defaultColDef = { ...this.defaultColDef, ...this.customColDef };
    if (this.defaultColDef.flex && this.defaultColDef.initialFlex) {
      delete this.defaultColDef.initialFlex;
    }

    if (this.formCode) {
      this.formDetail = this.formService.getFormDetail(this.formCode);
    } else {
      this.formCode = "listing-" + this.pageService.getNewFormCode();
    }

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'totalRecordRenderer',
          align: 'left'
        }
      ],
    };

    if (this.setting.pagination) {
      this.statusBar.statusPanels.push({
        statusPanel: 'paginatorRenderer',
        align: 'right',
        statusPanelParams: {
          page: this.formCode,
          setting: this.setting
        }
      });
    }

    // Col Def setting
    if (this.colDef) {
      this.colDef.forEach((colDef: ColDef) => {
        if (colDef.headerName) {
          colDef.headerName = this.translateService.instant(colDef.headerName);
        }

        if (colDef.field === 'checkbox') {
          colDef.cellRendererParams = { page: this.formCode };
          colDef.valueGetter = 'node.id';
          colDef.checkboxSelection = colDef.checkboxSelection !== undefined ? colDef.checkboxSelection : function (params) {
            if (params.node.id === undefined) {
              return false;
            } else {
              return true;
            }
          };
          colDef.suppressSizeToFit = true;
          colDef.filter = false;
          colDef.editable = false;
          colDef.maxWidth = 42;
          colDef.lockPosition = 'left';
          colDef.cellStyle = undefined;
          colDef.valueGetter = 'node.id';
          colDef.cellRenderer = colDef.cellRenderer ? colDef.cellRenderer : function (params) {
            if (params.value === undefined) {
              return '<img src="assets/base/images/loading-animation.gif" class="listing-loading-btn"/>';
            } else {
              return undefined;
            }
          };
        }

        if (colDef.field === 'actionButton') {
          colDef.cellRendererParams = {
            ...colDef.cellRendererParams,
            page: this.formCode,
            route: this.pageRoute
          }
          colDef.valueGetter = 'node.id';
          colDef.headerName = colDef.headerName ? colDef.headerName : '';
          colDef.filter = false;
          colDef.editable = false;
          colDef.suppressSizeToFit = true;
          colDef.width = 42;
          colDef.maxWidth = 42;
          colDef.lockPosition = 'left';
          colDef.cellRenderer = 'buttonRenderer';
        }

        if (colDef.cellRenderer === 'routerLinkRenderer' && colDef.cellRendererParams?.popupForm) {
          colDef.cellRendererParams = {
            ...colDef.cellRendererParams,
            popupForm: {
              ...colDef.cellRendererParams.popupForm,
              formCode: this.formCode
            }
          },
            colDef.filter = 'agTextColumnFilter';
        }

        if (colDef.cellRenderer === 'dropdownRenderer') {
          colDef.cellRendererParams = {
            ...colDef.cellRendererParams,
            page: this.formCode,
          }
          colDef.filter = true;
          colDef.editable = true;
          colDef.cellEditor = 'dropdownCellEditor';
          colDef.cellEditorPopup = true;
          colDef.cellEditorPopupPosition = 'under';
        }

        this.gridOptions = {
          columnDefs: this.colDef,
          animateRows: true,
          autoGroupColumnDef: {
            headerName: colDef.headerName,
            field: colDef.field,
          }
        }
      });
    }

    if (this.setting.rowModelType === RowModelType.serverSide || this.setting.rowModelType === RowModelType.infinite) {
      let id = 0;
      this.getRowId = (params: GetRowIdParams) => (id++).toString();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gridApi) {
      if (changes.rowData?.currentValue) {
        this.rowData = changes.rowData.currentValue;
        if(this.setting.rowModelType === RowModelType.clientSide && this.rowData instanceof Array) {
          let rowData = this.rowData && this.rowData.length > 0? this.rowData: [];
          this.gridApi.setGridOption("rowData", rowData);
        } else if (this.setting.rowModelType === RowModelType.serverSide && this.rowData instanceof OdataProvider) {
          setTimeout(() => {
            this.gridApi.refreshServerSide();
          }, 100);
        } else if (this.setting.rowModelType === RowModelType.infinite) {
          this.gridApi.purgeInfiniteCache();
        } else {
          console.error("Invalid rowData type or row model type not found");
        }
      }
    }
  }

  onRowDataUpdate(event: any) {
    if (this.gridApi) {
      let selectedNodes = this.selectedNodes?.map((node: { node?: IRowNode, value?: any }) => {
        if (node.node) {
          return JSON.stringify(node.node.data);
        } else {
          return node.value;
        }
      });

      this.gridApi.forEachNode((node: any) => {
        if (selectedNodes?.includes(JSON.stringify(node.data)) || (this.lookDropInputSetting?.valueKey && node.data && selectedNodes.includes(node.data[this.lookDropInputSetting.valueKey]))) {
          node.setSelected(true);
        }
      });

      let gridSelectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
      if (gridSelectedNodes && gridSelectedNodes.length > 0) {
        this.focusCell(gridSelectedNodes[0].rowIndex);
      } else if (this.lookDropInputSetting) {
        this.focusCell(0);
      }

      this.onRowDataUpdated.emit(this.gridApi);
    }
  }

  onFirstDataRendered() {
    if (this.gridApi) {
      if (this.gridApi.getDisplayedRowCount() > 0) {
        this.rowData = [];
      }
    }
  }

  edit() {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let column = null;
    let dropdown = null;
    this.isEditingMode = true;

    if (selectedNodes.length > 1) {
      selectedNodes.forEach((node, index) => {
        if (index != 0) {
          node.setSelected(false);
        }
      });
    }

    if (selectedNodes.length > 0) {
      this.editingRowId = selectedNodes[0].rowIndex;
      this.agGridService.setEditMode(this.gridApi, selectedNodes[0], this.formCode);
      this.rowMultiSelectWithClick = false;
      column = Object.keys(selectedNodes[0].data).find((data: any) => this.gridApi.getColumn(data)?.getColDef().editable);
      dropdown = Object.keys(selectedNodes[0].data).filter((data: any) => this.gridApi.getColumn(data)?.getColDef().cellRenderer === 'dropdownRenderer');
    }

    if (column) {
      this.messageService.clear();

      let message: Message = {
        severity: 'warn',
        summary: this.translateService.instant("msg.warning")?.toUpperCase() + ":",
        detail: this.translateService.instant("msg.direct_edit_error")
      };

      this.messageService.add(message);
    }
  }

  restore() {
    let msg: PopupMessage = {
      titleIcon: 'assets/base/icons/exclamation-circle.svg',
      title: 'msg.restore_previous_action',
      desc: "msg.restore_are_you_sure",
      closeBtnText: 'general.no',
      actionBtnText: 'general.yes'
    };

    const dialogRef = this.dialog.open(PopupMessageComponent, {
      data: msg
    });
  }

  async setData() {
    if (this.setting.rowModelType === RowModelType.serverSide && this.rowData instanceof OdataProvider) {
      this.gridApi.setGridOption("serverSideDatasource", this.rowData);
    } else if (this.setting.rowModelType === RowModelType.clientSide) {
      let rowData = this.rowData && this.rowData.length > 0 ? this.rowData : [];
      this.gridApi.setGridOption("rowData", rowData);
    } else if (this.setting.rowModelType === RowModelType.infinite) {
      this.gridApi.setGridOption("datasource", this.rowData);
    } else {
      console.error("Invalid rowData type or row model type not found");
    }

    if (this.setting?.pageSize) {
      this.currPageSize = this.setting.pageSize[0];
      this.gridApi.setGridOption("paginationPageSize", this.setting.pageSize[0]);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    this.gridApi.setGridOption("defaultColDef", this.defaultColDef);
    this.gridApi.setGridOption("columnDefs", this.colDef);
    this.setData();
    this.onGridApi.emit(this.gridApi);

    // Get Grid Height
    if ((this.setting.rowModelType !== RowModelType.infinite && this.setting.height === 'fixed') ||
      (this.setting.rowModelType === RowModelType.infinite && this.setting.height === 'auto' || this.setting.height === 'fixed')) {
      let count = 0;
      let header = this.agGridTable.nativeElement.querySelector('.ag-header');
      let rowTotalHeight = 0;
      this.getRowHeight = (params) => {
        rowTotalHeight += +params.node.rowHeight;

        // server side and client side
        if (count == this.currPageSize && header && this.setting.rowModelType !== RowModelType.infinite) {
          this.setting.height = rowTotalHeight + header.offsetHeight + 'px';
        }

        // infinite
        if (this.setting.rowModelType === RowModelType.infinite && !count) {
          this.setting.height = params.node.rowHeight * this.currPageSize + header.offsetHeight + 'px';
        }
        count++;
      }
    }

    // Persist state
    // this.repo.persistState(this.pageRoute);
    // this.list$ = this.repo.allList$.subscribe((value: any) => {
    //   let index = value.findIndex((data: any) => data && data.id === this.pageRoute);
    //   if (index >= 0) {
    //     this.gridApi.setColumnDefs(value[index].list);
    //   } else {
    //     this.gridApi.setColumnDefs(this.colDef);
    //   }
    // });

    // Listen to Action Button event
    this.actionBtn$ = this.pageService.actionBtnListing$.subscribe((data: any) => {
      if (data.param.page.formCode === this.formCode && data.action === ButtonRendererTypeEnum.cancelEdit) {
        this.rowMultiSelectWithClick = this.setting.rowSelection === 'single' ? false : true;
      }
    });
  }

  onDragStarted() {
    this.gridApi.stopEditing();
  }

  onDragStop() {
    let columnDefs = this.gridApi.getColumnDefs();
    this.repo.addLists(this.pageRoute, columnDefs);
  }

  onActionToolbar(value: ActionToolBarResp) {
    if (value.name === 'edit') {
      this.edit();
    } else if (value.name === 'restore') {
      this.restore();
    }

    this.onToolbarAction.emit(value);
  }

  onCellEditingStopped(event: any) {
    this.agGridService.setEditedMode(this.formCode, event);
  }

  onCellValueChanged(event: any) {
    if (this.agGridService.isEditing(this.formCode)) {
      this.pageService.setPendingEdit(this.pageRoute);
    }
  }

  onCellClicked(event: any) {
    if (event.colDef?.editable && this.agGridTable.nativeElement.classList.contains('editing')) {
      this.gridApi.startEditingCell({ rowIndex: event.rowIndex, colKey: event.column.colId });
    }
  }

  onRowSelected(event: any) {
    this.selectedRowNodes = event?.api?.getSelectedNodes();
    this.isRowSelected = this.selectedRowNodes.length > 0 ? true : false;

    if (event && event.source !== 'api') {
      this.selectedRowNodes = event?.api?.getSelectedNodes();
      this.onListingRowSelected.emit(this.selectedRowNodes);
    }
  }

  onRowClicked() {
    this.onListingRowClicked.emit(this.selectedRowNodes);
  }

  pageSizeChange(pageSize: number) {
    this.currPageSize = pageSize;
    this.gridApi.setGridOption("paginationPageSize", pageSize);
    this.onPageSizeChange.emit(pageSize);
  }

  columnRowGroupChanged() {
    this.gridApi.autoSizeAllColumns();
  }

  onRowDoubleClick() {
    const selectedData = this.gridApi.getSelectedRows();
    this.onRowDoubleClicked.emit(selectedData);
  }

  onClickApply() {
    // merge back previous selected nodes
    let renderedNodes: any[] = this.gridApi.getRenderedNodes().map((node) => JSON.stringify(node.data));
    let prevNodes: IRowNode[] = this.selectedNodes.filter((data) => data.node).map((data) => data.node);
    let prevSelectedNodes = prevNodes.filter((node) => !renderedNodes.includes(JSON.stringify(node.data)));

    this.selectedRowNodes = [...prevSelectedNodes, ...this.gridApi.getSelectedNodes()];
    if(this.selectedRowNodes.length > 0) {
      this.onApplySelectedRow.emit(this.selectedRowNodes);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event: any) {
    if (event.key !== 'Tab') return;

    if (event.key === 'Tab'
      && (event.target.classList.contains('ag-cell') || event.target.classList.contains('ag-header-cell'))
      && this.lookDropInputSetting?.multiSelect) {
      this.applyBtnEl.nativeElement.focus();
      this.gridApi.clearFocusedCell();
    }
  }

  onActionKeydown(event: any) {
    if (event.key !== 'Tab') return;

    if (event.key === 'Tab' && event.target.name === this.actionToolbarSetting.actionToolBarItems[this.actionToolbarSetting.actionToolBarItems.length - 1].name) {
      event.preventDefault();
      this.focusCell(0);
    }
  }

  focusCell(row: number) {
    // scrolls to the first row
    this.gridApi.ensureIndexVisible(row);

    // scrolls to the first column
    const firstCol = this.gridApi.getAllDisplayedColumns() ? this.gridApi.getAllDisplayedColumns()[0] : '';
    this.gridApi.ensureColumnVisible(firstCol);

    // sets focus into the first grid cell
    this.gridApi.setFocusedCell(row, firstCol);
  }

  gridColumnChange(event: any) {
    this.onGridColumnChange.emit(event)
  }

  ngOnDestroy(): void {
    this.list$?.unsubscribe();
    this.actionBtn$?.unsubscribe();
  }
}
