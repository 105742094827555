import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ExcelExportParams, ExcelRow, GridApi } from 'ag-grid-community';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReportViewerService } from '../component/services/report-viewer.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { exportPdfSetting } from '../models/exportPdfSetting.model';
import { MatDialog } from '@angular/material/dialog';
import { ExportSelectionComponent } from '../component/export-selection/export-selection.component';
import { PopupForm } from '../models/popupForm.model';
import { ActionToolbarPosition } from '../enums/actionToolbarPosition';
import { PopupFormService } from './popup-form.service';
import { Subject } from 'rxjs';
import { pageOrientation } from '../enums/pageOrientation';
import { exportType } from '../enums/exportType';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  'pingfangSC': {
    normal: 'https://db.onlinewebfonts.com/t/2e8b8a5bb209daf0c8dd2f3a94c2b4e8.ttf',
    bold: 'https://db.onlinewebfonts.com/t/2e8b8a5bb209daf0c8dd2f3a94c2b4e8.ttf'
  }
};

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  gridApi: GridApi;
  setting: exportPdfSetting;

  export$: Subject<{type: exportType, orientation: pageOrientation }> = new Subject();

  constructor(private reportViewerService: ReportViewerService,
    private translateService: TranslateService,
    @Inject(LOCALE_ID) private locale: string,
    public dialog: MatDialog,
    private popupFormService: PopupFormService) {}

  openSelection() {
    let popupForm: PopupForm = {
      id: 'exportSelection',
      componentName: ExportSelectionComponent,
      fullScreen: true,
      position: ActionToolbarPosition.CENTER,
      deleteBtn: false,
      title: "general.export_settings",
      styling: {
        width: '376px'
      }
    }

    this.popupFormService.open(popupForm);
  }

  closeSelection() {
    this.popupFormService.closeById('exportSelection');
  }

  exportExcel(gridApi: GridApi, setting?: ExcelExportParams) {
    this.gridApi = gridApi;
    this.gridApi?.exportDataAsExcel(setting);
  }

  excelPrependRow(reportData: any) {
    const datePipe = new DatePipe('en-US');

    let content: ExcelRow[] = [
      {
        cells: [{
          data: {
            value: reportData && reportData['report-title'] && reportData['report-generated-time']? this.translateService.instant('report.title', {reportName: this.translateService.instant(reportData['report-title']), date: datePipe.transform(reportData['report-generated-time'], 'mediumDate') }): '',
            type: "String"
          },
          styleId: 'header'
        }]
      },
      {
        cells: [{
          data: {
            value: reportData && reportData['user']? this.translateService.instant('report.generated_by', {user: reportData['user']?.firstName, email: reportData['user']?.emailAddress}): '',
            type: "String"
          }
        }]
      },
      {
        cells: [{
          data: {
            value: reportData && reportData['report-generated-time']? this.translateService.instant('report.generated_on', {dateTime: datePipe.transform(reportData['report-generated-time'], 'dd/MM/yyyy, h:mma')}): '',
            type: "String"
          }
        }]
      },
      {
        cells: [{
          data: {
            value: reportData? this.translateService.instant('report.filters', {filters: ''}): '',
            type: "String"
          }
        }]
      },
      { cells: [] }
    ];

    return content;
  }

  exportPDF(gridApi: GridApi, setting?: exportPdfSetting) {
    this.gridApi = gridApi;
    this.setting = setting;
    const docDefinition = this.getDocDefinition();
    pdfMake.createPdf(docDefinition).download(setting.fileName + '.pdf');
  }

  private getDocDefinition() {
    let datePipe = new DatePipe(this.locale);

    const PDF_HEADER_HEIGHT = '25';
    const PDF_ROW_HEIGHT = '15';
    const PDF_LOGO = "https://cdn.evoloper.com/images/ZeoniqLogo-R.png";

    const columnGroupsToExport = this.getColumnGroupsToExport();
    const columnsToExport = this.getColumnsToExport();
    const rowsToExport = this.getRowsToExport(columnsToExport);
    const widths = this.getExportedColumnsWidths(columnsToExport);
    const headerRows = columnGroupsToExport ? 2 : 1;
    const heights = rowIndex => rowIndex < headerRows ? PDF_HEADER_HEIGHT : PDF_ROW_HEIGHT;

    const docDefinition = {
      pageOrientation: this.setting.orientation,
      header: {
        stack: [
          { text: this.setting.title? this.translateService.instant('report.title', {reportName: this.translateService.instant(this.setting.title), date: this.setting.generatedDateTime? datePipe.transform(this.setting.generatedDateTime, 'mediumDate'): '' }): '', bold: true },
          { text: this.setting.user? this.translateService.instant('report.generated_by', {user: this.setting.user}): '' },
          { text: this.setting.generatedDateTime? this.translateService.instant('report.generated_on', {dateTime: datePipe.transform(this.setting.generatedDateTime, 'dd/MM/yyyy, h:mma')}): '' },
          { text: this.setting.filter? this.translateService.instant('report.filters', {filters: this.setting.filter}): ''}
        ],
        margin: [10, 10, 20, 20],
        fontSize: this.setting?.headerStyle?.fontSize? this.setting.headerStyle.fontSize: 12
      },
      content: [
        {
          table: {
            headerRows: headerRows,
            // widths: widths,
            body: columnGroupsToExport
            ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
            : [columnsToExport, ...rowsToExport],
          }
        }
      ],
      images: {
        "ag-grid-logo": PDF_LOGO
      },
      pageMargins: [10, this.setting?.headerStyle?.height? this.setting.headerStyle.height: 120, 10, 10],
      styles: {},
      defaultStyle: {
        font: 'pingfangSC',
        fontSize: this.setting?.tableStyle?.fontSize? this.setting?.tableStyle?.fontSize: null
      }
    }

    return docDefinition;
  }

  private getPdfExportOptions(colId) {
    let col: any = this.gridApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }

  private getExportedColumnsWidths(columnsToExport) {
    return columnsToExport.map(() => 100 / columnsToExport.length + "%");
  }

  private getRowsToExport(columnsToExport) {
    let rowsToExport = [];

    this.gridApi.forEachNodeAfterFilterAndSort(node => {
      // if (!node.isSelected()) {
      //   return;
      // }

      let rowToExport = columnsToExport.map(({ colId }) => {
        let cellValue = this.gridApi.getValue(colId, node);
        let formatValue = this.formatCellValue(cellValue, node, colId);
        let tableCell = this.createTableCell(formatValue, colId);
        return tableCell;
      });

      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }

  private getColumnsToExport() {
    let columnsToExport = [];

    this.gridApi?.getAllDisplayedColumns()?.forEach(col => {
      let pdfExportOptions = this.getPdfExportOptions(col.getColId());
      if (pdfExportOptions && pdfExportOptions.skipColumn) {
        return;
      }
      let headerCell = this.createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    return columnsToExport;
  }

  private getColumnGroupsToExport() {
    let displayedColumnGroups = this.gridApi?.getAllDisplayedColumnGroups();

    let isColumnGrouping = displayedColumnGroups?.some(col =>
      col.hasOwnProperty("children")
    );

    if (!isColumnGrouping) {
      return null;
    }

    let columnGroupsToExport = [];

    displayedColumnGroups.forEach((colGroup: any) => {
      let isColSpanning = colGroup.children.length > 1;
      let numberOfEmptyHeaderCellsToAdd = 0;

      if (isColSpanning) {
        let headerCell = this.createHeaderCell(colGroup);
        columnGroupsToExport.push(headerCell);
        // subtract 1 as the column group counts as a header
        numberOfEmptyHeaderCellsToAdd--;
      }

      // add an empty header cell now for every column being spanned
      colGroup.displayedChildren.forEach(childCol => {
        let pdfExportOptions = this.getPdfExportOptions(childCol.getColId());
        if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
          numberOfEmptyHeaderCellsToAdd++;
        }
      });

      for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
        columnGroupsToExport.push({});
      }
    });

    return columnGroupsToExport;
  }

  getCellToExport(value: any, styleId: string) {
    return {
      styleId: styleId,
      data: {
        type: /^\d+$/.test(value) ? "Number" : "String",
        value: String(value),
      },
    };
  }

  private createHeaderCell(col) {
    let headerCell: any = {};

    let isColGroup = col.hasOwnProperty("children");

    if (isColGroup) {
      headerCell.text = col.originalColumnGroup.colGroupDef.headerName;
      headerCell.colSpan = col.children.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.colId = col.getColId();
    }

    headerCell["style"] = "tableHeader";

    return headerCell;
  }

  private createTableCell(cellValue, colId) {
    const tableCell = {
      text: cellValue !== undefined ? cellValue : "",
      noWrap: +cellValue? true: false,
      style: "tableCell"
    };

    const pdfExportOptions = this.getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL } = pdfExportOptions;

      if (styles) {
        Object.entries(styles).forEach(
          ([key, value]) => (tableCell[key] = value)
        );
      }

      if (createURL) {
        tableCell["link"] = createURL(cellValue);
        tableCell["color"] = "blue";
        tableCell["decoration"] = "underline";
      }
    }

    return tableCell;
  }

  formatCellValue(cellValue: any, node: any, colId: any) {
    let id = node.group? node.field: colId;

    const pdfExportOptions = this.getPdfExportOptions(id);

    if(pdfExportOptions?.params) {
      if (node.group && pdfExportOptions.params.formula && cellValue) {
        cellValue = this.reportViewerService.calculateFormula(pdfExportOptions.params.formula, cellValue);
      }
      cellValue = this.reportViewerService.formatData(pdfExportOptions.params.type, cellValue, pdfExportOptions.params.unit);
    }

    return cellValue;
  }
}
