<app-form class="form-container" [formSetting]="formSetting" [actionToolbarSetting]="actionToolbarSetting" [page]="page" [formDetail]="formDetail"
  [formData]="invoiceData" (onToolbarAction)="onToolbarAction($event)" (onSubmitForm)="onSubmitForm($event)"></app-form>

  <div class="col-12">
    <div class="scrollable-container">
      <div class="ag-grid-table-container" [ngClass]="formDetail.formCode" #outletList>
        <div class="action-msg-bar">
          <div class="message">
            <app-message [msgs]="" [msgType]=""></app-message>
          </div>
          <div class="action-tool-bar">
            <app-action-tool-bar [formDetail]="formDetail" [isRowSelected]="isRowSelected"
              (onAction)="onToolbarAction($event)"></app-action-tool-bar>
          </div>
        </div>      
        <app-listing *ngIf="rowData" [colDef]="colDef" [rowData]="rowData" [formCode]="formCode.invoicelisting" [setting]="listingSetting"
        [excelStyles]="excelStyles" (onGridApi)="onGridApiUpdate($event)"></app-listing>
      </div>
    </div>
  </div>