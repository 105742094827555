/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EdmContainerElementKind = 'None' | 'EntitySet' | 'ActionImport' | 'FunctionImport' | 'Singleton';

export const EdmContainerElementKind = {
    None: 'None' as EdmContainerElementKind,
    EntitySet: 'EntitySet' as EdmContainerElementKind,
    ActionImport: 'ActionImport' as EdmContainerElementKind,
    FunctionImport: 'FunctionImport' as EdmContainerElementKind,
    Singleton: 'Singleton' as EdmContainerElementKind
};