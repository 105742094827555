import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GetRowIdFunc, GetRowIdParams, StatusPanelDef } from 'ag-grid-community';

import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { OrderDetailService } from '../../services/orderdetail.service';
import * as moment from 'moment';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FilterComponent } from '../../../shared/component/filter/filter.component';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Mode } from 'src/app/shared/enums/mode';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FormService } from 'src/app/shared/component/form/form.service';
import { CustomFilterComponent } from '../../filter/filter.component';
import { agGridSideBarType } from 'src/app/shared/enums/agGridSideBarType';

@Component({
  selector: 'app-orderdetail-listing',
  templateUrl: './orderdetail-listing.component.html',
  styleUrls: ['./orderdetail-listing.component.scss'],
  standalone: true,
  imports: [ListingComponent, FilterComponent, CustomFilterComponent]
})
export class OrderDetailListingComponent implements OnInit {
  @Input() page: Page;

  pages: Page[];
  screenContainer: any;
  rowData: any = null;
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  formDetail: any = null;
  listingSetting: ListingSetting;
  gotResend: boolean = true;
  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  selectedRowData: any;

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    editable: false,
    sortable: true,
    filterParams: {
      browserDatePicker: true
    }
  };

  colDef: ColDef[] = [
    {
      field: 'checkbox',
      headerCheckboxSelection: true
    },
    {
      ...listingRouterLink,
      field: 'orderNo', headerName: 'Order No', filter: 'agTextColumnFilter',
      cellRendererParams: {
        inRouterLink:{
          route: '/order-management/master/revertcutoff',
          params: ['activityId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        }
      },
    },
    { field: 'locShortDesc', headerName: 'Store Name', filter: 'agTextColumnFilter' },
    {
      field: 'tranDate', headerName: 'Transaction Date', filter: 'agDateColumnFilter',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filterParams: {
        browserDatePicker: true
      }
    },
    { field: 'sourceFlag', headerName: 'OrderSourceFlag', filter: 'agTextColumnFilter', },
    { field: 'statusFlag', headerName: 'Activity Status', filter: 'agTextColumnFilter' },
    { field: 'lastActivityType', headerName: 'Last Activity Type', filter: 'agTextColumnFilter' },
    { field: 'cutOffFlag', headerName: 'CutOffFlag', filter: 'agTextColumnFilter' },
    { field: 'checkOrderUrl', headerName: 'CheckOrderUrl' },
    { field: 'netAmt', headerName: 'NetAmt' },
    { field: 'orderDesc', headerName: 'OrderDesc' },
    { field: 'mobileNo', headerName: 'MobileNo' },
    { field: 'refNo1', headerName: 'Payment RefNo1', filter: 'agTextColumnFilter' },
    { field: 'refNo2', headerName: 'Payment RefNo2', filter: 'agTextColumnFilter' },
    { field: 'payRefNo', headerName: 'Payment PayRefNo', filter: 'agTextColumnFilter' },
    { field: 'paymentStatus', headerName: 'Payment Status', filter: 'agTextColumnFilter' },
    { field: 'errorMsg', headerName: 'Payment ErrorMsg' },
    { field: 'tenderAmt', headerName: 'TenderAmt' },
    { field: 'activityId', headerName: 'ActivityID', filter: true },
    { field: 'orderTranId', headerName: 'OrderTranId', filter: true },
    { field: 'salesTranId', headerName: 'SalesTranId', filter: true },
    { field: 'payTranid', headerName: 'PayTranid', filter: true },
    { field: 'linkId', headerName: 'LinkId', filter: true },
    { field: 'tableNo', headerName: 'TableNo', filter: true },
    { field: 'activeFlag', headerName: 'ActiveFlag' },
    { field: 'completeFlag', headerName: 'CompleteFlag' },
    { field: 'cancelFlag', headerName: 'CancelFlag' }
  ];

  statusBar: { statusPanels: StatusPanelDef[]; } = {
    statusPanels: [
      {
        statusPanel: 'totalRecordRenderer',
        align: 'left'
      },
      {
        statusPanel: 'paginatorRenderer',
        align: 'right',
        statusPanelParams: {
          page: FormCode.orderdetaillisting
        }
      },

    ],
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
    ],
  };
  public filters: FilterExpression[] = [
    {
      field: 'orderNo',
      title: 'Order No',
      editor: 'string',
    },
    {
      field: 'locShortDesc',
      title: 'Store Name',
      editor: 'string',
    },
    {
      field: 'tranDate',
      title: 'Transaction Date',
      editor: 'date',
    },
    {
      field: 'paymentStatus',
      title: 'Payment Status',
      editor: 'string',
    },
    {
      field: 'refno1',
      title: 'Reference No',
      editor: 'string',
    },
    {
      field: 'storeId',
      title: 'Store ID',
      editor: 'string',
    },
    {
      field: 'mobileNo',
      title: 'Mobile No',
      editor: 'string',
    }
  ];
  defaultFilterValue: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [{ field: "tranDate", operator: "eq", value: new Date(new Date(Date.now()).setHours(0,0,0,0))},
    { field: "orderNo", operator: "eq", value: undefined}]
  }
  constructor(
    private pageService: PageService,
    private orderDetailService: OrderDetailService,
    private authService: AuthService,
    private customService: CustomService,
    private loaderService: LoaderService,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private formService: FormService
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe(pages => {
      this.pages = pages;
      this.screenContainer = document.getElementById("general-container");
    });
  }

  ngOnInit() {
    //this.getOrderDetail();
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
      duplicateRow: false,
      sideBar: [agGridSideBarType.columns, agGridSideBarType.filters]
    }
  }

  async getOrderDetail() {
    this.rowData = (await this.orderDetailService.getOdataOrderDetail(null, this.formDetail.formCode))['value'];
    this.getRowId = (params: GetRowIdParams) => params.data['activityId'];
  }

  onToolbarAction(value: ActionToolBarResp) {
    /* if (value.name === 'new') {
      this.pageService.openNewPage(this.pageRoute.MERCHANT_FORM);
    } */
  }
  async onFilter(odataFilterString: any) {

    let queryOpts: ODataRawQueryOptions = {
      filter: odataFilterString
    };
    let data = await this.orderDetailService.getOdataOrderDetail(queryOpts, this.formDetail.formCode);
    if (data instanceof HttpErrorResponse === false) {
      console.log(data['value']);

      this.rowData = data && data['value'] ? data['value'] : [];
    }
  }

  onRowSelect(event: any) {
    this.selectedRowData = event[0]?.data? event.map(a => a.data): null;
  }

  async onResend() {
    if (this.selectedRowData){
      this.loaderService.startLoading();
      for await (const row of this.selectedRowData){
        if (row.salesTranId)
        {
          this.ResubmitSalesRequest(row.salesTranId)
        }
        else if (row.orderTranId){
          console.log(row.orderTranId);
          this.ResubmitOrderRequest(row.orderTranId)
        }
      }
      this.loaderService.stopLoading();
      let msg: PopupMessage = {
        formCode: FormCode.invoicelisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'Order/Sales Resend Success',
        desc: "Order/Sales resend successfully",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
     }
  }

  async ResubmitOrderRequest(orderTranId: number) {
    let requestPath: string = `/api/order/ZQResummitOrder?orderTranIds=${orderTranId}&secretkey=${encodeURIComponent("XSJZ4XW16G")}`;

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: requestPath,
      hostPath: environment.frontApiUrl,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    let headers = new HttpHeaders();

    if (environment.apiVersion !== undefined && environment.apiVersion !== null) {
      headers = headers.set('api-version', String(environment.apiVersion));
    }

    if (environment.subscription !== undefined && environment.subscription !== null) {
      headers = headers.set('SubscriptionID', String(environment.subscription));
    }

    if (hMac !== undefined && hMac !== null) {
      headers = headers.set('X-Signature', String(hMac));
    }

    headers = headers.set('Content-type', "application/json");
    headers = headers.set('Accept', "application/json");

    return this.httpClient.request<Array<any>>(HttpMethod.POST, environment.frontApiUrl + requestPath,
      {
        withCredentials: false,
        headers: headers,
      }
    ).toPromise();
  }

  async ResubmitSalesRequest(salesTranId: number) {
    let requestPath: string = `/api/order/ZQResummitOrder?salesTranIds=${salesTranId}&secretkey=${encodeURIComponent("XSJZ4XW16G")}`;

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: requestPath,
      hostPath: environment.frontApiUrl,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    let headers = new HttpHeaders();

    if (environment.apiVersion !== undefined && environment.apiVersion !== null) {
      headers = headers.set('api-version', String(environment.apiVersion));
    }

    if (environment.subscription !== undefined && environment.subscription !== null) {
      headers = headers.set('SubscriptionID', String(environment.subscription));
    }

    if (hMac !== undefined && hMac !== null) {
      headers = headers.set('X-Signature', String(hMac));
    }

    headers = headers.set('Content-type', "application/json");
    headers = headers.set('Accept', "application/json");

    return this.httpClient.request<Array<any>>(HttpMethod.POST, environment.frontApiUrl + requestPath,
      {
        withCredentials: false,
        headers: headers,
      }
    ).toPromise();
  }

  onPageSizeChange(pageSize: number) {

  }
}
