<div class="button-toggle-layout" [formGroup]="formGroup" 
  [ngStyle]="{justifyContent: buttonToggleSetting?.styling?.position}"
  [hidden]="buttonToggleSetting.hidden">
  <label *ngIf="buttonToggleSetting.label">{{ buttonToggleSetting.label | translate }}<span class="required-symbol" *ngIf="buttonToggleSetting.required">*</span></label>
  <mat-button-toggle-group name="fontStyle" formControlName="{{ buttonToggleSetting.name? buttonToggleSetting.name: '' }}"
    [ngStyle]="{width: buttonToggleSetting?.styling?.width, height: buttonToggleSetting?.styling?.height}"
    (change)="onSelectionChange($event)"
    aria-label="Font Style" [multiple]="buttonToggleSetting.multiple" class="custom-button-group">
    <mat-button-toggle *ngFor="let item of items" value="{{item.key}}" 
      [ngClass]="{'custom-button': true,'circle': buttonToggleSetting?.circleButton}"
      [disableRipple]="true">
      <img [src]="item.icon" *ngIf="item?.icon" [ngStyle]="{width: item?.iconStyling?.width}"/> 
      <span>{{item.name}}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
