/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type InvoiceStatusFlag = 'Active' | 'Emailed' | 'Regenerated' | 'RegenEmailed' | 'Cancelled';

export const InvoiceStatusFlag = {
    Active: 'Active' as InvoiceStatusFlag,
    Emailed: 'Emailed' as InvoiceStatusFlag,
    Regenerated: 'Regenerated' as InvoiceStatusFlag,
    RegenEmailed: 'RegenEmailed' as InvoiceStatusFlag,
    Cancelled: 'Cancelled' as InvoiceStatusFlag
};