/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ChannelCustExpiryFlag = 'NoExpiry' | 'ByDay' | 'ByMonth' | 'ByMonthEnd' | 'ByYear' | 'FixedDate';

export const ChannelCustExpiryFlag = {
    NoExpiry: 'NoExpiry' as ChannelCustExpiryFlag,
    ByDay: 'ByDay' as ChannelCustExpiryFlag,
    ByMonth: 'ByMonth' as ChannelCustExpiryFlag,
    ByMonthEnd: 'ByMonthEnd' as ChannelCustExpiryFlag,
    ByYear: 'ByYear' as ChannelCustExpiryFlag,
    FixedDate: 'FixedDate' as ChannelCustExpiryFlag
};