import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { AuthService } from 'src/app/core/services/auth.service';
import { InvoiceService } from '../invoice.service';
import { Page } from 'src/app/shared/models/page.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { InvoiceResponse, ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { PageService } from 'src/app/shared/services/page.service';
import { UtilsService, decodeBase64, formatDateString } from 'src/app/core/services/utils.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { CustomService } from 'src/app/core/services/custom.service';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { environment } from 'src/environments/environment';
import { InvoiceService as InvoiceServiceTwo } from 'src/swagger/cxmbackend/openapi';
import { CreateInvoiceRequest } from 'src/swagger/cxmbackend/openapi/model/createInvoiceRequest';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { InvoiceActionComponent } from '../invoice-action/invoice-action.component';
import { ColDef, GridApi } from 'ag-grid-community';
import { NgClass, NgIf } from '@angular/common';
import { MessageComponent } from 'src/app/shared/component/message/message.component';
import { ActionToolBarComponent } from 'src/app/shared/component/action-tool-bar/action-tool-bar.component';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import * as moment from 'moment';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { InvoiceStatusFlag } from 'src/swagger/cxmbackend/openapi/model/invoiceStatusFlag';
import { InvoiceService as InvoiceAPIService } from 'src/swagger/cxmbackend/openapi';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { InvoiceEmailRequest } from 'src/swagger/cxmbackend/openapi/model/InvoiceEmailRequest';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-invoice-emailentry',
  templateUrl: './invoice-emailentry.component.html',
  styleUrls: ['./invoice-emailentry.component.scss'],
  standalone: true,
  imports: [NgIf, FormComponent, NgClass, MessageComponent, ActionToolBarComponent, ListingComponent],
  providers: [InvoiceAPIService]
})
export class InvoiceEmailEntryComponent implements OnInit {
  formSetting: FormSetting = {
    formCode: FormCode.invoiceemailentry,
    content: [
      {
        inputText: {
          name: 'payoutDate',
          label: 'Payment Date',
          type: InputTypes.date,
          required: true,
          styling: {
            width: '60%'
          },
          editable: true
        }
      },
      {
        inputText: {
          name: 'sendEmail',
          label: 'Recipient Email (leave blank to send to Merchant)',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
        }
      },
      {
        inputText: {
          name: 'invoiceNo',
          label: 'Invoice No',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          disabled: true
        }
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  @Input() page: Page;
  formDetail: any = null;
  invoiceId: number = undefined;
  addressId: number = undefined;
  invoiceData: any;
  attrCode: any;
  private gridApi!: GridApi;
  rowData: any = null;
  formCode: any = FormCode;
  isRowSelected: boolean = false;
  invoiceStatuses: any = InvoiceStatusFlag;
  invoice: InvoiceResponse;
  email: string;
  @Input() params = null;
  @Input() popupFormData = null;

  constructor(private authService: AuthService,
    private invoiceService: InvoiceService,
    private invoiceServiceTwo: InvoiceServiceTwo,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private customService: CustomService,
    private utilsService: UtilsService,
    private loaderService: LoaderService,
    private formService: FormService)
    {}

  async ngOnInit() {
    console.log('getpopupFormData: ', this.popupFormData)
    this.formDetail = this.formService.getFormDetail(FormCode.invoiceemailentry);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.invoiceId = params['invoiceId'];
    }

    let queryOpts: ODataRawQueryOptions = {
      filter: 'invoiceId eq ' + this.invoiceId
    };
    this.invoice = (await this.invoiceService.getOdataInvoice(queryOpts, this.formDetail.formCode)).value[0];
    this.invoiceData =  this.invoice;

    if (this.popupFormData){
      this.formSetting.content = this.formSetting.content.filter(x => x.inputText.name != "invoiceNo")
    }
  }

  onGridApiUpdate($event) {
    this.gridApi = $event;
  }
  async getInvoice(data?: ODataRawQueryOptions) {
    let result = await this.invoiceService.getOdataInvoice(data);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.invoiceData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp){}

  async onSubmitForm(formGroup: UntypedFormGroup){
    if (this.popupFormData){
      let result  = null;
      this.loaderService.startLoading();
      console.log(formGroup.get('payoutDate').value)
      for await (const invoiceId of this.popupFormData)
      {
        let reqBody: InvoiceEmailRequest = {
          invoiceId: invoiceId,
          payoutDate: formatDateString(formGroup.get('payoutDate').value),
          email: formGroup.get('sendEmail').value,
        }
        if (!reqBody.email)
        {
          let queryOpts: ODataRawQueryOptions = {
            filter: 'invoiceId eq ' + invoiceId
          };
          this.invoice = (await this.invoiceService.getOdataInvoice(queryOpts, this.formDetail.formCode)).value[0];
          reqBody.email = this.invoice.email;
        }
          result = this.invoiceService.sendInvoiceEmail(reqBody, this.formDetail.formCode);
      }
      this.loaderService.stopLoading();
      let msg: PopupMessage = {
        formCode: FormCode.invoiceemailentry,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.invoice.emailed',
        desc: "msg.email.invoice",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
    else{
      let result  = null;
      console.log(formGroup.get('payoutDate').value)
      let reqBody: InvoiceEmailRequest = {
        invoiceId: this.invoiceId,
        payoutDate: formatDateString(formGroup.get('payoutDate').value),
        email: formGroup.get('sendEmail').value,
      }
      let cusReq: CustomRequest = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.invoice.SendInvoiceEmail,
        hostPath: environment.hostPath,
        body: reqBody,
        headers: null,
        httpHeaderType: undefined
      }
      let sentEmail = reqBody.email;
      if (!sentEmail)
      {
        sentEmail = this.invoice.email;
      }
      let hMac = this.customService.assignHMac(cusReq);
      this.loaderService.startLoading();
        result = this.invoiceServiceTwo.apiInvoiceSendinvoiceemailPost(reqBody, environment.apiVersion, environment.subscription, hMac, 'en').subscribe(
          (data) => {
            this.loaderService.stopLoading();
            if (data === true) {

              this.rowData = data ? data : [];
              this.popupFormService.close(this.page.form.formCode);
              let msg: PopupMessage = {
                formCode: FormCode.invoiceemailentry,
                id: 'success',
                titleIcon: 'assets/base/icons/check-circle.svg',
                title: 'msg.invoice.emailed',
                desc: "msg.email.invoice",
                actionBtnText: 'btn.okay'
              };
              this.dialog.open(PopupMessageComponent, {
                data: msg
              });
            }
          },
          (err) => {
            this.loaderService.stopLoading();
            console.error(err);
            let msg: PopupMessage = {
              formCode: FormCode.invoiceemailentry,
              id: 'failed',
              titleIcon: 'assets/base/icons/exclamation-circle.svg',
              title: 'msg.invoice.emailed.unsuccessful',
              desc: "msg.couldnt.email.invoice",
              actionBtnText: 'btn.back'
            };

            this.dialog.open(PopupMessageComponent, {
              data: msg
            });
          }
        );
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
  }

}
