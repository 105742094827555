import { Injectable } from '@angular/core';
import { GridApi, IDateFilterParams, IRowNode, RowNodeSorter } from 'ag-grid-community';
import { ButtonRendererTypeEnum } from '../enums/buttonRendererType';
import { FormCode } from '../enums/formCode';
import { PageService } from './page.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AgGridService {
  constructor(private pageService: PageService,
    private messageService: MessageService) { }

  setEditMode(gridApi: GridApi, rowNode: IRowNode, formCode: FormCode) {
    let agGridEl: any = document.querySelector('.ag-grid-table-container.' + formCode);
    if(agGridEl) agGridEl.classList.add('editing');

    // gridApi.getColumns().forEach((column) => {
    //   column.getColDef().editable = true;
    // });

    let actionBtnEl = gridApi.getColumn('actionButton')? gridApi.getColumn('actionButton'): null;
    if(actionBtnEl) {
      gridApi.getColumn('actionButton').getColDef().cellRendererParams.type =  ButtonRendererTypeEnum.save;
    }

    let checkboxEl = gridApi.getColumn('checkbox')? gridApi.getColumn('checkbox'): null;
    if(checkboxEl) {
      gridApi.getColumn('checkbox').getColDef().cellRenderer = 'buttonRenderer';
      gridApi.getColumn('checkbox').getColDef().cellRendererParams.type = ButtonRendererTypeEnum.cancelEdit;
      gridApi.getColumn('checkbox').getColDef().cellClass = 'hide-checkbox';
    }

    gridApi.setGridOption('suppressRowClickSelection', true);

    gridApi.redrawRows({
      // force: true,
      // columns: [gridApi.getColumn('checkbox')?.getColId(), gridApi.getColumn('actionButton')?.getColId()],
      rowNodes: [rowNode]
    });
  }

  unsetEditMode(gridApi: GridApi, rowNode: IRowNode, formCode: FormCode) {
    let agGridEl: any = document.querySelector('.ag-grid-table-container.' + formCode);
    if(agGridEl) agGridEl.classList.remove('editing');

    // gridApi.getColumns().forEach((column) => {
    //   column.getColDef().editable = false;
    // });

    let actionBtnEl = gridApi.getColumn('actionButton')? gridApi.getColumn('actionButton'): null;
    if(actionBtnEl) {
      gridApi.getColumn('actionButton').getColDef().cellRendererParams.type = ButtonRendererTypeEnum.duplicate;
    }

    let checkboxEl = gridApi.getColumn('checkbox')? gridApi.getColumn('checkbox'): null;
    if(checkboxEl) {
      gridApi.getColumn('checkbox').getColDef().cellRenderer = undefined;
      gridApi.getColumn('checkbox').getColDef().cellRendererParams.type = undefined;
      gridApi.getColumn('checkbox').getColDef().cellClass = '';
    }

    gridApi.setGridOption('suppressRowClickSelection', false);

    gridApi.redrawRows({
      // force: true,
      // columns: [gridApi.getColumn('checkbox')?.getColId(), gridApi.getColumn('actionButton')?.getColId()],
      rowNodes: [rowNode]
    });
  }

  setEditedMode(formCode: FormCode, event: any) {
    if(event.oldValue !== event.newValue) {
      document.querySelector('.ag-grid-table-container.' + formCode + ' .ag-row[row-index="' + event.rowIndex + '"] .ag-cell[col-id="' + event.column.colId + '"]').classList.add('edited');
    }
  }

  unsetEditedMode(formCode: FormCode, event: any) {
    let editedCellEl: any = document.querySelectorAll('.ag-grid-table-container.' + formCode + ' .ag-row[row-index="' + event.rowIndex + '"] .ag-cell.edited');
    editedCellEl.forEach((cell: any) => {
      cell.classList.remove('edited');
    });
  }

  setSavedMode(gridApi: GridApi, rowNode: IRowNode, parentEl: any, siblingEl: any) {
    let actionBtnEl = gridApi.getColumn('actionButton')? gridApi.getColumn('actionButton'): null;
    if(actionBtnEl) {
      gridApi.getColumn('actionButton').getColDef().cellRendererParams.type = ButtonRendererTypeEnum.saved;
    }

    let checkboxEl = gridApi.getColumn('checkbox')? gridApi.getColumn('checkbox'): null;
    if(checkboxEl) {
      gridApi.getColumn('checkbox').getColDef().cellRendererParams.type = ButtonRendererTypeEnum.none;
    }

    if(parentEl) parentEl.classList.add('button-renderer-2-col');
    if(siblingEl) siblingEl.classList.add('button-renderer-hide');

    gridApi.refreshCells({
      force: true,
      columns: [gridApi.getColumn('checkbox')?.getColId(), gridApi.getColumn('actionButton')?.getColId()],
      rowNodes: [rowNode]
    });
  }

  unsetSavedMode(gridApi: GridApi, rowNode: IRowNode, parentEl: any, siblingEl: any, formCode: FormCode) {
    let agGridEl: any = document.querySelector('.ag-grid-table-container.' + formCode);
    if(agGridEl) agGridEl.classList.remove('editing');

    let actionBtnEl = gridApi.getColumn('actionButton')? gridApi.getColumn('actionButton'): null;
    if(actionBtnEl) {
      gridApi.getColumn('actionButton').getColDef().cellRendererParams.type = ButtonRendererTypeEnum.duplicate;
    }

    let checkboxEl = gridApi.getColumn('checkbox')? gridApi.getColumn('checkbox'): null;
    if(checkboxEl) {
      gridApi.getColumn('checkbox').getColDef().cellRenderer = undefined;
      gridApi.getColumn('checkbox').getColDef().cellRendererParams.type = undefined;
      gridApi.getColumn('checkbox').getColDef().cellClass = '';
    }

    if(parentEl) parentEl.classList.remove('button-renderer-2-col');
    if(siblingEl) siblingEl.classList.remove('button-renderer-hide');

    gridApi.refreshCells({
      force: true,
      columns: [gridApi.getColumn('checkbox')?.getColId(), gridApi.getColumn('actionButton')?.getColId()],
      rowNodes: [rowNode]
    });
  }

  undoEdit(formCode: FormCode, event: any) {
    let editedCellEl: any = document.querySelectorAll('.ag-grid-table-container.' + formCode + ' .ag-row[row-index="' + event.rowIndex + '"] .ag-cell.edited');

    editedCellEl.forEach(() => {
      event.api.undoCellEditing();
    });
  }

  setSuccessSaveMode(data: {action: ButtonRendererTypeEnum, param: any, cellEl?: any}) {
    setTimeout(() => {
      if(data.cellEl) {
        let parentEl = data.cellEl;
        let siblingEl = data.cellEl.previousElementSibling;
        this.unsetSavedMode(data.param.api, data.param.node, parentEl, siblingEl, data.param.page);
      }

      this.pageService.unsetPendingEdit(data.param.page);
      this.unsetEditedMode(data.param.page, data.param);
      this.pageService.editing$.next({page: data.param.page, isEditing: false});
      this.messageService.clear();
    }, 1000);
  }

  hasEditedCell(formCode: FormCode, event: any) {
    return document.querySelectorAll('.ag-grid-table-container.' + formCode + ' .ag-row[row-index="' + event.rowIndex + '"] .ag-cell.edited')?.length > 0;
  }

  isEditing(formCode: FormCode) {
    return document.querySelector('.ag-grid-table-container.editing.' + formCode);
  }

  filterParams(): IDateFilterParams {
    return {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue ? cellValue.slice(0, 10) : null;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('-');
        var cellDate = new Date(
          Number(dateParts[0]),
          Number(dateParts[1]) - 1,
          Number(dateParts[2])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
    };
  }

  selectRow(gridApi: GridApi, id: number) {
    const node: IRowNode = gridApi.getRowNode(id.toString());

    if(node) {
      node.setSelected(true);
    }
  }
}
