import { PageRoute } from './shared/enums/pageRoute';
import { HeaderService } from './shared/services/headerService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, ViewChild, ViewContainerRef, ViewEncapsulation, Output, ElementRef, Renderer2, AfterViewInit, AfterViewChecked } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RouteName } from './shared/enums/routeName';
import { Page } from './shared/models/page.model';
import { PageService } from './shared/services/page.service';
import { devTools } from '@ngneat/elf-devtools';
import { LoaderService } from './shared/services/loader.service';
import { AuthService } from './core/services/auth.service';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastComponent } from './shared/component/toast/toast.component';
import { NavMenuComponent } from './layout/sidenav/nav-menu/nav-menu.component';
import { UserInfoComponent } from './shared/component/user-info/user-info.component';
import { NotificationComponent } from './shared/component/notification/notification.component';
import { PopupFormComponent } from './shared/component/popup-form/popup-form.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgFor, NgClass, NgIf, AsyncPipe, LowerCasePipe, CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoaderComponent } from './shared/component/loader/loader.component';
import { LanguageComponent } from './shared/component/language/language.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSidenav } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { SearchComponent } from './shared/component/search/search.component';
import { FormsModule } from '@angular/forms';
import { SearchingService } from './shared/services/searching.service';
import { HeaderComponent } from './layout/header/header.component';
import { AgGridTableComponent } from './shared/component/ag-grid/ag-grid-table/ag-grid-table.component';
import { FormService } from './shared/component/form/form.service';
import { Subscription } from 'rxjs';
import { SubscriptionService } from './core/services/subscription.service';
import { SubscriptionUserService } from './core/services/subscriptionUser.service';
import { UserRepository } from './state/user.repository';
import { UserProfile } from 'src/swagger/base-rms/openapi/model/models';
import { ReportComponent } from './project-demo/report/report.component';
import { ErrorPageComponent } from './shared/component/error-page/error-page.component';
import { ErrorPageService } from './shared/services/error-page.service';
import { MenuNavigationComponent } from './shared/component/menu-navigation/menu-navigation/menu-navigation.component';
import { ChangeDetectorRef } from '@angular/core';
import { SystemService } from './shared/services/system.service';
import { MenuRepository } from './state/menu.repository';
import { Menu } from './state/menu.repository';
import frameworkMenu from 'src/app/project-demo/framework-setting.json';
import { TermsAgreedComponent } from './core/terms-agreed/terms-agreed.component';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [],
  imports: [
    LoaderComponent, MatSidenavModule, MatToolbarModule, MatButtonModule, MatListModule, NgFor, NgClass, NgIf, FormsModule, MatMenuModule, PopupFormComponent, NotificationComponent, UserInfoComponent, NavMenuComponent, ToastComponent, AsyncPipe, LowerCasePipe, TranslateModule, LanguageComponent, OverlayModule, CommonModule, MatIconModule, SearchComponent, HeaderComponent, MatIconModule, RouterModule, AgGridTableComponent, ReportComponent, ErrorPageComponent, MenuNavigationComponent, DialogModule, TermsAgreedComponent],
})
export class AppComponent implements AfterViewInit, AfterViewChecked {
  dropdownOpen = false;
  sidebarOpened: boolean = false;
  buttonHidden: boolean = false;
  isDropdownVisible: boolean = true;
  isNotificationOpen: boolean = false;
  navbarOpened: boolean = false;
  searchText: string = '';
  userProfile: UserProfile = null;

  isActive: boolean;
  showPopupContent: boolean = false;
  menuOpen: boolean = false;
  errPgDt: any;
  isScrolling: any;

  overlayRef: any;

  scrHeight: any;
  scrWidth: any;
  event: any;

  activeRoute: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }

  @ViewChild('header', { static: false, read: ElementRef })
  headerEl: ElementRef;

  @ViewChild('sideNav', { static: false, read: ElementRef })
  sideNavEl: ElementRef;

  @ViewChild('rightSidenav') rightSidenav: MatSidenav;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  showSubmenu: boolean = false;
  selectedMenuIndex: any;
  activeMenuIndex: number | null = null;
  activeButton: any = null;
  lowerZIndex: boolean = false;

  menuSets = [];
  menus: Menu[] = [];
  private menuSubscription!: Subscription;

  childrenMenuData: any;
  routeName = RouteName;

  @ViewChild('drawer') drawer;
  sideNavPinned: boolean = true;

  @ViewChild('mainContainer', { static: true, read: ViewContainerRef })
  mainContainer: ViewContainerRef;

  @ViewChild('screenContainer', { static: false, read: ElementRef })
  screenContainer: ElementRef;

  @ViewChild('autoFocusInput') autoFocusInput: ElementRef;

  @ViewChild(MenuNavigationComponent) menuNav!: MenuNavigationComponent;

  isReportViewerPage: boolean = false;
  selectedSubscription: boolean = false;

  isLogin: boolean = false;
  hideHeader: boolean = true;
  isChildrenMenuOpen = false;

  currentContent: string = '';
  selectedMenuSet: any = [];
  isOpenMenu: boolean = false;
  fullScreen: boolean = false;
  recentMenu: any[] = [];
  openFavourite: boolean = false;
  openRecent: boolean = false;
  isDashboard: boolean = false;

  pageRoute: any = PageRoute;
  isMobile: boolean = false;
  showAgreed: boolean = false;

  constructor(
    public translateService: TranslateService,
    public translateCacheService: TranslateCacheService,
    private breakpointObserver: BreakpointObserver,
    private pageService: PageService,
    private loaderService: LoaderService,
    public router: Router,
    private searchingService: SearchingService,
    private headerService: HeaderService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private errorPageService: ErrorPageService,
    private cdr: ChangeDetectorRef,
    private systemService: SystemService,
    private menuRepository: MenuRepository,
  ) {
    this.authService.isDoneLoading$.subscribe(async data => {
      let validToken = this.authService.hasValidToken();
      this.isLogin = validToken;
      this.pageService.isLogin = this.isLogin;

        if (validToken) {
          this.loaderService.stopLoading();
        }
    })

    this.subscriptionService.getSubscriptionRepo().subscribe(async sub => {
      if (sub.selectedSubscription) {
        this.selectedSubscription = true;
        this.hideHeader = false;

        if(this.isLogin) {
          await this.subscriptionService.updateInfo();
        //call API here
        await this.callApi();
        this.menuRepository.addItems(this.menuSets)
        }

      } else {
        this.selectedSubscription = false;
        this.hideHeader = true;
      }
    });

    this.errorPageService.errorPage$.subscribe(err => this.errPgDt = err);

    this.isReportViewerPage = this.pageService.isReportViewerPage();

    this.getScreenSize();

    this.headerService.toggle$.subscribe(toggle => {
      this.lowerZIndex = toggle;
    });
  }

  selectedLang: any;
  async callApi() {
    try {
      const [menuResult, language, culture] = await Promise.all([
        this.systemService.getInitData(this.subscriptionService.selectedSub.subscriptionId),
        this.systemService.getSysLanguageList(this.subscriptionService.selectedSub.subscriptionId),
        this.systemService.getSysCultureList(this.subscriptionService.selectedSub.subscriptionId)
      ]);

      if (menuResult?.user?.isTermsAgreed == "FALSE") {
        this.showAgreed = true;
      }

      this.menuSets = environment.showFramework ?
        [...menuResult.menus, ...frameworkMenu.menus] : menuResult?.menus;
      this.userProfile = menuResult?.user?.userProfile;
      this.selectedLang = menuResult?.user?.userProfile?.refLangCode;

      this.languages = language.value.map(lang => ({
        name: lang.langDesc,
        value: lang.langCode
      }));

      this.regional = culture.value.map(cult => ({
        name: cult.cultureDesc,
        value: cult.cultureCode
      }));

      this.translateService.setDefaultLang(this.selectedLang);
      this.translateService.use(this.selectedLang);
    } catch (error) {
      console.error('Error during API calls:', error);
    }
  }

  async ngAfterViewInit() {
    await this.pageService.setMasterContainer(this.mainContainer);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  languages: any[] = [];
  regional: any[] = [];
  async ngOnInit() {
    devTools();

    this.searchingService.searchText$.subscribe((text) => {
      this.searchText = text;
    });

    this.searchingService.reset$.subscribe(() => {
      this.searchText = '';
    });

    this.getLocalStorage();
    // this.isDashboard = true;
    // this.router.navigateByUrl('/dashboard');
    if (localStorage.getItem('selectedMenuSet')) {
      this.recentMenu = JSON.parse(localStorage.getItem('selectedMenuSet'))
    }
  }

  updateMenuState(updatedMenu: Menu): void {
    const index = this.menus.findIndex(menu => menu.id === updatedMenu.id);
    if (index !== -1) {
      this.menus[index] = { ...updatedMenu };
    } else {
      this.menus.push({ ...updatedMenu });
    }
  }

  updateProfile(userProfile: UserProfile) {
    this.userProfile = userProfile;
  }

  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }

  clearActiveButton(): void {
    this.activeButton = null;
  }

  selectChildrenMenu(menuItem: any) {
    this.pageService.navigateByPath(menuItem.filePath);
    this.toggleSideNav();
    this.menuOpen = true;
    this.drawer.toggle();
    // this.pageService.scrollMostRight(this.screenContainer, this.drawer);
  }

  toggleDropdown(contentType: string): void {
    if (this.sidebarOpened && this.currentContent === contentType) {
      this.rightSidenav.close();
    } else {
      this.rightSidenav.open();
      this.closeSideNav();
      this.currentContent = contentType;
    }
  }

  closeSideNav() {
    this.drawer.close();
    this.menuOpen = true;
    this.showPopupContent = false;
    this.isActive = false;
    this.searchText = '';
    this.searchingService.triggerReset();
    if (this.isOpenMenu) {
      this.fullScreen = true;
    }
  }

  toggleChildrenMenu(): void {
    this.isChildrenMenuOpen = !this.isChildrenMenuOpen;
    this.dropdownOpen = false;
    this.showPopupContent = false;
    this.isActive = false;
  }

  toggleMenu(): void {
    this.isNotificationOpen = !this.isNotificationOpen;
  }

  closeChildrenMenu(): void {
    this.isChildrenMenuOpen = false;
  }

  selectSubMenu(index: number) {
    this.showSubmenu = this.selectedMenuIndex == index ? !this.showSubmenu : true;
    this.selectedMenuIndex = index;
    this.isChildrenMenuOpen = false;
  }

  openMenu(isOpen: boolean) {
    this.isOpenMenu = isOpen;
  }

  closeMenu() {
    this.isOpenMenu = false;
    this.openFavourite = false;
    this.openRecent = false;
    this.isDashboard = true;
    this.drawer.open();
    this.menuOpen = false;
    this.fullScreen = false;
    this.selectedMenuSet = [];
  }

  selectMenu(menuSet: any) {
    this.selectedMenuSet = menuSet;
    this.openFavourite = false;
    this.openRecent = false;
    this.isDashboard = false;

    if (this.menuNav) {
      this.menuNav.clearSearch();
    }

    if (menuSet.menuItems.length > 0) {
      this.isOpenMenu = true;
    } else {
      this.fullScreen = false;
      this.pageService.navigateByPath(menuSet.filePath);
      this.menuOpen = true;
      this.isOpenMenu = false;
      this.drawer.toggle();
    }
  }

  passRecentMenu(event) {
    this.recentMenu = event;
  }

  getLocalStorage(): any {
    const storedItem = localStorage.getItem('selectedMenuSet');
    const parsedItem = JSON.parse(storedItem);
    return parsedItem;
  }

  openDefaultMenu(target: string, menuSets?: any) {
    this.activeRoute = false;
    if (this.menuNav) {
      this.menuNav.clearSearch();
    }

    this.selectedMenuSet = [];
    if (target === 'favourite') {
      if (this.getFavouriteMenuItems(menuSets)) {
        this.selectedMenuSet = menuSets;
        this.isDashboard = false;
        this.openRecent = false;
        this.openFavourite = true;
        this.isOpenMenu = true;
      }
    } else if (target === 'recent') {
      if (localStorage.getItem('selectedMenuSet')) {
        this.selectedMenuSet = this.combineArrays([this.getLocalStorage()])
      }
      this.openFavourite = false;
      this.isDashboard = false;
      this.openRecent = true;
      this.isOpenMenu = true;
    } else if (target === 'home') {
      this.closeMenu();
      this.pageService.navigateByPath('/dashboard');
    }
  }

  combineArrays(arrays: any[][]): any[] {
    const combinedMap = new Map();
    arrays.forEach(array => {
      array.forEach(item => {
        if (combinedMap.has(item.id)) {
          const existingItem = combinedMap.get(item.id);
          existingItem.menuItems = this.mergeMenuItems(existingItem.menuItems, item.menuItems);
        } else {
          combinedMap.set(item.id, { ...item });
        }
      });
    });
    return this.extractLastLayer(Array.from(combinedMap.values()));
  }

  mergeMenuItems(menuItems1: any[], menuItems2: any[]): any[] {
    const menuItemMap = new Map();

    menuItems1.forEach(menuItem => {
      menuItemMap.set(menuItem.id, { ...menuItem });
    });

    menuItems2.forEach(menuItem => {
      if (menuItemMap.has(menuItem.id)) {
        const existingMenuItem = menuItemMap.get(menuItem.id);
        existingMenuItem.menuItems = this.mergeMenuItems(existingMenuItem.menuItems, menuItem.menuItems);
      } else {
        menuItemMap.set(menuItem.id, { ...menuItem });
      }
    });

    return Array.from(menuItemMap.values());
  }

  extractLastLayer(items: any[]): any[] {
    const lastLayerItems: any[] = [];

    items.forEach(item => {
      if (item.menuItems && item.menuItems.length > 0) {
        // Recursively extract last layer from menuItems
        lastLayerItems.push(...this.extractLastLayer(item.menuItems));
      } else {
        // No more menuItems, this is a last layer item
        lastLayerItems.push(item);
      }
    });

    return lastLayerItems;
  }

  getFavouriteMenuItems(menuItems: any[]): any[] {
    const favourites: any[] = [];

    const propagateFavouriteStatus = (item: any): boolean => {
      let isFavourite = item.isFavourite || false;
      if (item.menuItems && item.menuItems.length > 0) {
        item.menuItems.forEach((subItem: any) => {
          const subItemIsFavourite = propagateFavouriteStatus(subItem);
          if (subItemIsFavourite) {
            isFavourite = true;
          }
        });
      }

      item.isFavourite = isFavourite;
      return isFavourite;
    };

    menuItems.forEach(menuItem => {
      if (propagateFavouriteStatus(menuItem)) {
        favourites.push(menuItem);
      }
    });
    return favourites;
  }

  closePanel(page?: Page) {
    this.pageService.closePage(page);
  }

  public trackItem(index: number, item: any) {
    return item.trackId;
  }

  toggleSideNav() {
    this.showPopupContent = false;
    this.isActive = !this.isActive;
    this.searchText = '';
    this.searchingService.triggerReset();
  }

  //left side bar toggle close searching page
  handleMenuOpenChange(menuState: boolean) {
    this.rightSidenav.close();
    this.showPopupContent = false;
    this.isActive = false;
    this.searchText = '';
    this.searchingService.triggerReset();
    if (this.selectedMenuSet) {
      if (this.isOpenMenu) {
        this.fullScreen = !this.fullScreen;
      }
    }
  }


  closeSidebar() {
    this.rightSidenav.close();
    this.currentContent = '';
  }

  closeRightSidenav() {
    this.rightSidenav.close();
    this.buttonHidden = true;
  }

  handleSidebarClosed() {
    this.sidebarOpened = false;
    this.buttonHidden = false;
    this.currentContent = '';
  }

  //for mobile view search
  showPopup() {
    this.showPopupContent = true;
    this.isActive = true;
    this.autoFocusInput.nativeElement.focus();
  }

  resetSearch(): void {
    this.searchText = '';
    this.searchingService.triggerReset();
  }

  //web view cross icon close
  toggleContent() {
    this.showPopupContent = !this.showPopupContent;
    this.isActive = !this.isActive;
  }

  //mobile view back arrow close
  closePopup() {
    this.showPopupContent = false;
    this.isActive = !this.isActive;
  }

  //for mobile view
  handleTogglePopupContent(show: boolean) {
    this.showPopupContent = show;
  }

  //for mobile view
  handleToggleActiveStatus(active: boolean) {
    this.isActive = active;
  }

  onSearch() {
    this.searchingService.setSearchText(this.searchText);
  }

  autoPageScroll() {
    let contentEl: any = this.screenContainer ? this.screenContainer.nativeElement.querySelectorAll('app-content') : [];

    contentEl.forEach((element) => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.intersectionRatio > 0.5 && entry.intersectionRatio !== 1) {
          this.screenContainer.nativeElement.scrollTo({
            left: element.offsetLeft,
            behavior: 'smooth',
          });
        }
        observer.disconnect();
        observer.unobserve(element);
      });

      observer.observe(element);
    });
  }

  @HostListener('window:resize', ['$event'])
  resize() {
    this.autoPageScroll();
  }
}
