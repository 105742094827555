import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { AuthService } from 'src/app/core/services/auth.service';
import { Page } from 'src/app/shared/models/page.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { BackendChannel, BackendChannelAddress } from 'src/swagger/cxmbackend/openapi';
import { PageService } from 'src/app/shared/services/page.service';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { LocalChannelService } from '../localChannel.service';
import { FormService } from 'src/app/shared/component/form/form.service';
import { LookupStateComponent } from '../../lookup/lookup-state/lookup-state.component';
import { LookupCountryComponent } from '../../lookup/lookup-country/lookup-country.component';
import { Mode } from 'src/app/shared/enums/mode';

@Component({
  selector: 'app-channel-address-entry',
  templateUrl: './channel-address-entry.component.html',
  styleUrls: ['./channel-address-entry.component.scss'],
  standalone: true,
  imports: [FormComponent]
})
export class ChannelAddressEntryComponent implements OnInit {
  formSetting: FormSetting = {
    formCode: FormCode.channeladdrentry,
    content: [
      {
        inputText: {
          name: 'channelId',
          label: 'Channel ID',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          disabled: true
        },
      },
      {
        inputText: {
          name: 'addressId',
          label: 'Address ID',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          disabled: true
        },
      },
      {
        inputText: {
          name: 'searchLabel',
          label: 'Search Label',
          type: InputTypes.text,
          required: true,
          maxLength: 200,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'addUnit',
          label: 'Unit',
          type: InputTypes.text,
          required: true,
          maxLength: 15,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'addCountry',
          label: 'dealer.country',
          componentName: LookupCountryComponent,
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'addState',
          label: 'dealer.state',
          componentName: LookupStateComponent,
          required: true,
          styling: {
            width: '60%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true,
            filterBy: 'countryCode'
          },
        },
      },
      {
        inputText: {
          name: 'addCity',
          label: 'City',
          type: InputTypes.text,
          required: true,
          maxLength: 50,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'addPostal',
          label: 'Postal Code',
          type: InputTypes.text,
          required: true,
          maxLength: 15,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'latitude',
          label: 'Latitude',
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputNumber: {
          name: 'longitude',
          label: 'Longitude',
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  @Input() page: Page;
  @Input() params = null;
  formDetail: any = null;

  channelId: number = undefined;
  addressId: number = undefined;
  channelAddrData: any;
  attrCode: any;

  reqBody: BackendChannelAddress = null;
  editMode: boolean = false;

  constructor(private authService: AuthService,
    private channelService: LocalChannelService,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private formService: FormService) { }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    if (this.page.queryParams?.mode) {
      this.editMode = this.page.queryParams.mode === Mode.edit ? true : false;
    }

    // Get route parameter
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);

      this.channelId = this.params ? this.params['channelId'] : params['channelId'];
      this.addressId = this.params ? this.params['addressId'] : params['addressId'];
    }

    // Get pass in parameter
    if(this.params) {
      this.attrCode = this.params['AttrCode']? this.params['AttrCode']: null;
    }

    if(this.channelId && this.addressId) {
      await this.getChannelAddr(this.channelId, this.addressId);
    }
    else if (this.channelId) {
      this.channelAddrData = {
        channelId: this.channelId
      }
    }

  }

  async getChannelAddr(channelId: number, addressId: number) {
    let result = await this.channelService.getOdataChannelAddr(channelId, addressId, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.channelAddrData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp){}

  async onSubmitForm(formGroup: UntypedFormGroup){
    let result  = null;
    this.reqBody = this.formService.getReqBody(formGroup);

    if(this.addressId) {
      result = await this.channelService.updateChannelAddr(this.reqBody, this.formDetail.formCode);
     } else {

       result = await this.channelService.addChannelAddr(this.reqBody, this.formDetail.formCode);
     }

    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);

      let msg: PopupMessage = {
        formCode: FormCode.channeladdrlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.new.channel.addr.saved',
        desc: "popupMsg.added.new.channel.addr",
        actionBtnText: 'btn.okay'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.refresh([FormCode.channeladdrlisting]);
        this.pageService.closePage(this.page);
      });
    } else {
      let msg: PopupMessage = {
        formCode: FormCode.storeattrlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.new.store.attr.unsuccessful',
        desc: "popupMsg.system.couldnt.save.store.attr",
        actionBtnText: 'btn.back'
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }
}
