import { Component, Input, OnInit } from '@angular/core';
import { MessagesModule } from 'primeng/messages';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    standalone: true,
    imports: [MessagesModule]
})
export class MessageComponent implements OnInit {
  @Input() msgs: any;
  @Input() msgType: any;

  constructor() { }

  ngOnInit(): void {
  }

  

}
