import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { environment } from 'src/environments/environment';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { Router } from '@angular/router';
import { FormService } from 'src/app/shared/component/form/form.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import {
  InterfaceType,
  TaxConditionFService as TaxConditionFAPIService,
  TaxConditionDResponse,
  ErrorCode,
} from 'src/swagger/base/openapi';
import { DropdownOption } from 'src/app/shared/component/input/model/DropdownOption.model';
import { BackendTaxConditionD, CurrencyService, ODataRawQueryOptions, StoreRegisterstoretwoBody } from 'src/swagger/cxmbackend/openapi';
import { TimeZone } from 'src/app/shared/enums/timeZone';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { TaxService } from '../../tax.service';
import { TaxType } from 'src/swagger/base/openapi/model/taxType';
import { LookupTaxComponent } from 'src/app/cxm-backend-web/lookup/lookup-tax/lookup-tax.component';
import { LookupTaxGroupComponent } from 'src/app/cxm-backend-web/lookup/lookup-taxgroup/lookup-taxgroup.component';
import { FilterFlag } from 'src/swagger/base/openapi/model/filterFlag';

@Component({
  selector: 'app-taxconditiond-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, TranslateModule],
  templateUrl: './taxconditionf-entry.component.html',
  styleUrls: ['./taxconditionf-entry.component.scss'],
})
export class TaxConditionFEntryComponent {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  taxCondCode: string = '';
  taxCondLine: string = '';
  filterLine: string = '';
  formGroup: FormGroup;
  taxConditionFData: any;
  masterData: any;

  pageSub$: Subscription;

  interfaceTypeOption: any = Object.keys(InterfaceType).map((key) => { return { name: key, value: InterfaceType[key] } });


  formSetting: FormSetting = {
    formCode: FormCode.taxconditionfentry,
    content: [
      {
        inputText: {
          name: 'taxCondCode',
          label: 'Tax Condition Code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 5,
          disabled: true,
          editable: false,
        },
      },
      {
        inputText: {
          name: 'taxCondLine',
          label: 'Tax Condition Line Number',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 50,
          disabled: true,
          editable: false
        },
      },
      {
        inputText: {
          name: 'filterLine',
          label: 'Filter Line Number',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 50,
          disabled: true,
          editable: false
        },
      },
      {
        inputText: {
          name: 'filterParentLine',
          label: 'Filter Parent Line',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 50,
          editable: false
        },
      },
      {
        inputText: {
          name: 'fieldType',
          label: 'Field Type',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 6,
          editable: false
        },
      },
      {
        inputText: {
          name: 'fieldOperator',
          label: 'Field Operator',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 2,
          editable: false
        },
      },
      {
        inputText: {
          name: 'fieldData',
          label: 'Field Data',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%'
          },
          maxLength: 500,
          editable: false
        },
      },
    ],
  };

  actionToolbarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private customService: CustomService,
    private TaxConditionFApiService: TaxConditionFAPIService,
    private authService: AuthService,
    private pageService: PageService,
    public dialog: MatDialog,
    private taxConditionHService: TaxService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private formService: FormService,

  ) {
    this.pageSub$ = this.pageService.pageSubject$.subscribe(async pages => {
      this.pages = pages;

      if (this.page) {
        let foundPage = this.pages.find(page => page.form.filePath === this.page.form.filePath);
        if (foundPage) {
          // this.taxCondCode = foundPage.listingId;
          // if (this.taxCondCode) {
          //   await this.getTaxConditionF(this.taxCondCode);
          // }
        }
      }
    });
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.taxCondCode = params['taxCondCode'];
      this.taxCondLine = params['taxCondLine'];
      this.filterLine = params['filterLine'];
      if (this.taxCondCode && this.taxCondLine && this.filterLine) {
        let queryOpts: ODataRawQueryOptions = {
          filter: 'taxCondCode eq ' + '\'' + this.taxCondCode + '\''
          + ' and taxCondLine eq ' + '\'' + this.taxCondLine + '\''
          + ' and filterLine eq ' + '\'' + this.filterLine + '\''
        };
        await this.getTaxConditionF(queryOpts);

      }
      else {
        this.taxConditionFData = {
          taxCondCode: this.taxCondCode,
          taxCondLine: this.taxCondLine,
          filterLine: 1
        }
      }

    }

  }


  async getTaxConditionF(queryOpts: ODataRawQueryOptions) {
    let result = await this.taxConditionHService.getOdataTaxConditionF(null, queryOpts, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.taxConditionFData = result['value'][0];
    }
  }

  async addTaxConditionF(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.tax.taxConditionF.AddTaxConditionF,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: reqBody,
      httpHeaderType: undefined
    }
    this.loaderService.startLoading();
    let result = await this.reqCustomHttpCall(cusReq);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.taxconditionf.create.success.title',
        desc: 'msg.tax.taxconditionf.create.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxgrouplisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(result);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.taxconditionf.create.fail.title',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  async updateTaxConditionF(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.tax.taxConditionF.UpdateTaxConditionF,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: reqBody,
      httpHeaderType: undefined
    }

    this.loaderService.startLoading();
    let result = await this.reqCustomHttpCall(cusReq);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.taxconditionf.update.success.title',
        desc: 'msg.tax.taxconditionf.update.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxgrouplisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(result);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.taxconditionf.update.fail.title',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  onGenerateInput(event: any) {
    // call generate api
    let generatedCode = 'code';
    event.formGrp.controls[event.setting.name].setValue(generatedCode);
  }
  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody = this.formService.getReqBody(formGroup);

    if (this.filterLine) {
      this.updateTaxConditionF(reqBody);
    } else {
      this.addTaxConditionF(reqBody);
    }
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy() {
    this.pageSub$.unsubscribe();
  }

}
