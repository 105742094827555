import { Injectable } from '@angular/core';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomHeader } from 'src/app/core/models/local/CustomHeader';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { environment } from 'src/environments/environment';
import { cronExpression } from '../models/cronExpression.model';
import { FormCode } from '../enums/formCode';
import { FormSetHWithSchedulerRequest } from 'src/swagger/base-rms/openapi';

@Injectable({
  providedIn: 'root'
})
export class SysFormSetService {
  constructor(private customService: CustomService) { }

  async getReportSettingList(formCode: FormCode | string) {
    let cusReq = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.sysFormSet.GetReportSettingList,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      },
      httpHeaderType: undefined,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  async getReportSetting(formCode: FormCode | string, setId?: number) {
    let cusReq = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.sysFormSet.GetReportSetting,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      },
      httpHeaderType: undefined,
      queryParams: {
        setId: setId? setId: undefined
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  async getUserDefaultReportSetting(formCode: FormCode| string) {
    let cusReq = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.sysFormSet.GetUserDefaultReportSetting,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      },
      httpHeaderType: undefined
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  public async addReportSetting(formCode: FormCode | string, formSet: FormSetHWithSchedulerRequest) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.sysFormSet.AddReportSetting,
      hostPath: environment.hostPath,
      body: formSet,
      headers: {
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  public async updateReportSetting(formCode: FormCode | string, formSet: any) {
    let customRequest = {
      httpMethod: HttpMethod.PATCH,
      requestpath: environment.apis.sysFormSet.UpdateReportSetting,
      hostPath: environment.hostPath,
      body: formSet,
      headers: {
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  public async deleteReportSetting(formCode: FormCode | string, setId: number) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.sysFormSet.DeleteReportSetting,
      hostPath: environment.hostPath,
      headers: {
        formCode: formCode
      } as CustomHeader,
      queryParams: {
        setId: setId,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}
