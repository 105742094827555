import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Slide } from './carousel.interface';
import { transition, trigger, useAnimation } from '@angular/animations';
import { AnimationType, fadeIn, fadeOut, flipIn, flipOut, jackIn, jackOut, scaleIn, scaleOut } from './carousel.animations';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger("slideAnimation", [
      /* scale */
      transition("void => scale", [
        useAnimation(scaleIn, { params: { time: "500ms" } })
      ]),
      transition("scale => void", [
        useAnimation(scaleOut, { params: { time: "500ms" } })
      ]),

      /* fade */
      transition("void => fade", [
        useAnimation(fadeIn, { params: { time: "500ms" } })
      ]),
      transition("fade => void", [
        useAnimation(fadeOut, { params: { time: "500ms" } })
      ]),

      /* flip */
      transition("void => flip", [
        useAnimation(flipIn, { params: { time: "500ms" } })
      ]),
      transition("flip => void", [
        useAnimation(flipOut, { params: { time: "500ms" } })
      ]),

      /* JackInTheBox */
      transition("void => jackInTheBox", [
        useAnimation(jackIn, { params: { time: "700ms" } })
      ]),
      transition("jackInTheBox => void", [
        useAnimation(jackOut, { params: { time: "700ms" } })
      ])
    ])
  ]
})
export class CarouselComponent {
  @Input() slides: Slide[];
  @Input() animationType = AnimationType.Fade;
  @Input() intervalTime: number = 5000;

  currentSlide = 0;

  private startX: number = 0;
  private endX: number = 0;

  constructor() { }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  ngOnInit() {
    this.preloadImages(); // for the demo
  }

  preloadImages() {
    for (const slide of this.slides) {
      new Image().src = slide.src;
    }

    if (this.intervalTime) {
      setInterval(() => this.onNextClick(), this.intervalTime);
    }
  }

  setIndex(i: number): void {
    if (i !== this.currentSlide) {
      this.currentSlide = i;
    }
  }

  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
  }

  onTouchMove(event: TouchEvent) {
    this.endX = event.touches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    const threshold = 50; // Minimum distance in pixels to be considered a swipe
    if (this.startX - this.endX > threshold) {
      this.onNextClick();
    } else if (this.endX - this.startX > threshold) {
      this.onPreviousClick();
    }
  }
}
