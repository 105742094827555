import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading$: Subject<{show: boolean, msg?: string, title?: string, loadingType?: number}> = new Subject();

  constructor() { }

  startLoading(msg?: string, title?: string, loadingType?: number) {
    this.loading$.next({show: true, msg, title, loadingType});
  }

  stopLoading() {
    this.loading$.next({show: false});
  }
}
