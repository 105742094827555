import { ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef, forwardRef, Input } from '@angular/core';
import { PageService } from '../../services/page.service';
import { Page } from '../../models/page.model';
import { Subscription } from 'rxjs';
import { PageRoute } from '../../enums/pageRoute';
import { PopupFormComponent } from '../popup-form/popup-form.component';
import { ClosePageComponent } from '../close-page/close-page.component';
import { NgClass, NgIf } from '@angular/common';
import { Menu } from 'src/app/state/menu.repository';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, ClosePageComponent, forwardRef(() => PopupFormComponent)]
})
export class ContentComponent implements OnInit {
  @ViewChild('pageContainer', { read: ViewContainerRef }) pageContainer: ViewContainerRef;
  componentsReferences = Array<ComponentRef<any>>();
  @Input() menu: Menu;
  componentName: any = undefined;
  pages: Page[];
  screenContainer: any;
  sideNavPinned: boolean = false;
  component: any = undefined;
  
  sideNavPinnedSub$: Subscription;
  page$: Subscription;

  currentPage: Page = null;
  pageRoute = PageRoute;

  constructor(
    private pageService: PageService,
    private cdr: ChangeDetectorRef
  ) {
    this.pages = this.pageService.getPages();
    this.currentPage = this.pages[this.pages.length - 1];
    this.page$ = this.pageService.pageSubject$.subscribe(pages => {
      this.pages = pages;
      this.pageService.currentPageNo = this.pages.length - 1;
      this.screenContainer = document.getElementById("general-container");
      this.pageService.scrollMostRight(this.screenContainer);

      let page = this.pages.find((page) => page.seq === this.currentPage.seq);
      if(page) {
        this.currentPage = page;
      }
    });

    this.sideNavPinned = this.pageService.sideNavPinned;
    this.sideNavPinnedSub$ = this.pageService.sideNavPinned$.subscribe(sideNavPinned => {
      this.sideNavPinned = sideNavPinned;
    });
  }

  ngAfterViewInit() {
    let childComponentRef: ComponentRef<any> = this.pageContainer.createComponent(this.component);
    childComponentRef.instance.page = this.currentPage;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  // screenScroll(event: any) {
  //   const totalWidth: number = event.target.offsetWidth;
  //   const totalPage: number = this.pages.length;
  //   const scrollPosition: number = event.target.scrollLeft;

  //   // Calculate current page number
  //   if (scrollPosition !== totalWidth) {
  //     this.pageService.currentPageNo = Math.trunc((scrollPosition / totalWidth) * totalPage);
  //   } else {
  //     this.pageService.currentPageNo = totalPage - 1;
  //   }
  // }

  ngOnDestroy() {
    this.sideNavPinnedSub$.unsubscribe();
    this.page$?.unsubscribe();
  }
}
