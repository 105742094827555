import { AddressFormComponent } from './../../shared/component/form/address-form/address-form.component';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpMethod } from '../../core/enums/HttpMethod';
import { CustomRequest } from '../../core/models/local/CustomRequest';
import { CustomService } from '../../core/services/custom.service';
import OdataProvider from 'ag-grid-odata';
import { TranslateService } from '@ngx-translate/core';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoaderService } from 'src/app/shared/services/loader.service';
import {
  BackendChannel,
  BackendChannelAddress,
  BackendChannelSection,
  BackendChannelStore,
  BackendChannelStoreTax,
  ODataRawQueryOptions,
} from 'src/swagger/cxmbackend/openapi';

@Injectable({
  providedIn: 'root',
})
export class LocalChannelService {
  constructor(
    private customService: CustomService,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private loaderService: LoaderService
  ) {}

  async getOdataChannel(channelId?: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataChannelData(channelId, data, formCode);
  }

  async addChannel(reqBody: FormData, formCode?: string) {
    return await this.reqAddChannel(reqBody, formCode);
  }

  async updateChannel(reqBody: FormData, formCode?: string) {
    return await this.reqUpdateChannel(reqBody, formCode);
  }
  getOdataChannelProvider() {
    return this.reqOdataChannelProvider();
  }

  private async reqOdataChannelData(channelId?: string, data?: ODataRawQueryOptions, formCode?: string) {
    let filter = '';
    filter += channelId ? 'channelId eq ' + channelId : '';
    filter += channelId && data ? ' and ' : '';
    filter += data ? data.filter.replace('$filter=', '') : '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.OdataChannel,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter? filter: undefined,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private reqOdataChannelProvider() {
    const apiPath = environment.apis.channel.OdataChannel;
    return new OdataProvider({
      callApi: (options) =>
        fetch(`${environment.hostPath}${apiPath}${options}`, {
          headers: {
            SubscriptionID: environment.subscription,
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-Signature': this.getSignature(apiPath, options),
            Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
            'Content-Language': this.translate.currentLang,
          },
        }).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
      },
    });
  }

  getSignature(apiPath: string, options: any) {
    let optionsSpaceFormat = options.replace(/\s/g, '%20');
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: apiPath + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    };

    return this.customService.assignHMac(cusReq);
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  async getChannelDataSource() {
    const apiPath = environment.apis.channel.OdataChannel;
    return new OdataProvider({
      callApi: (options) =>
        fetch(`${environment.hostPath}${apiPath}${options}`, {
          headers: {
            SubscriptionID: environment.subscription,
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-Signature': this.getSignature(apiPath, options),
            Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
            'Content-Language': this.translate.currentLang,
          },
        }).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
      },
    });
  }

  private async reqAddChannel(reqBody: FormData, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channel.AddChannel,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        contentType: undefined,
        apiVersion: '2.0',
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqUpdateChannel(reqBody: FormData, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channel.UpdateChannel,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        contentType: undefined,
        apiVersion: '2.0',
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  // #region ChannelAddr
  async getOdataChannelAddr(channelId?: number, addressId?: number, formCode?: string, data?: ODataRawQueryOptions) {
    return await this.reqOdataChannelAddrData(channelId, addressId, formCode, data);
  }
  getOdataChannelAddressProvider(channelId?: number) {
    return this.reqOdataChannelAddrProvider(channelId);
  }

  async addChannelAddr(reqBody: BackendChannelAddress, formCode?: string) {
    return await this.reqAddChannelAddr(reqBody, formCode);
  }
  async updateChannelAddr(reqBody: BackendChannelAddress, formCode?: string) {
    return await this.reqUpdateChannelAddr(reqBody, formCode);
  }

  private async reqAddChannelAddr(reqBody: BackendChannelAddress, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channelAddr.AddChannelAddress,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private reqOdataChannelAddrProvider(channelId?: number) {
    const apiPath = environment.apis.channelAddr.OdataChannelAddress;
    return new OdataProvider({
      callApi: (options) =>
        fetch(`${environment.hostPath}${apiPath}${options}`, {
          headers: {
            SubscriptionID: environment.subscription,
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-Signature': this.getSignature(apiPath, options),
            Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
            'Content-Language': this.translate.currentLang,
          },
        }).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = channelId
          ? ['channelId eq ' + channelId]
          : undefined;
      },
    });
  }

  private async reqOdataChannelAddrData(channelId?: number, addressId?: number, formCode?: string, data?: ODataRawQueryOptions) {
    let filter = channelId ? 'channelId eq ' + channelId : '';
    filter += addressId ? ' and addressId eq ' + addressId : '';
    filter += data ? ' and (' : '';
    filter += data ? data.filter.replace('$filter=', '') + ')': '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channelAddr.OdataChannelAddress,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqUpdateChannelAddr(reqBody: BackendChannelAddress, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channelAddr.UpdateChannelAddress,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }
  // #endregion

  // #region ChannelStoreTax
  async getOdataChannelStoreTaxProvider(channelId?: number) {
    return await this.reqOdataChannelStoreTaxProvider(channelId);
  }
  async getOdataChannelStoreTax(channelId?: number, storeId?: number, formCode?: string) {
    return await this.reqOdataChannelStoreTaxData(channelId, storeId, null, formCode);
  }
  async reqOdataChannelStoreTaxData(channelId?: number, storeId?: number, taxLine?: number, formCode?: string) {
    let filter = channelId ? ' channelId eq ' + channelId : '';

    if (channelId && storeId && taxLine) {
      filter += ' and ';
    }

    filter += storeId ? ' storeId eq ' + storeId : '';
    if (channelId && storeId && taxLine) {
      filter += ' and ';
    }
    filter += taxLine ? ' taxLine eq ' + taxLine : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channelStoreTax.OdataChannelStoreTax,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  async reqOdataChannelStoreTaxProvider(channelId?: number, storeId?: number) {
    let filter = storeId ? 'storeId eq ' + storeId : undefined
    filter = channelId? (filter? filter + 'and channelId eq ' + channelId : 'channelId eq ' + channelId) :
            filter;
    let queryOpts: ODataRawQueryOptions = {
      filter: filter
    };
    console.log(queryOpts.filter)
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channelStoreTax.OdataChannelStoreTax,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: queryOpts?.filter.replace('$filter=', '')
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  async addChannelStoreTax(reqBody: BackendChannelStoreTax, formCode?: string) {
    return await this.reqAddChannelStoreTax(reqBody, formCode);
  }

  async updateChannelStoreTax(reqBody: BackendChannelStoreTax, formCode?: string) {
    return await this.reqUpdateChannelStoreTax(reqBody, formCode);
  }
  async deleteChannelStoreTax(channelId: number, storeId: number, taxLine: number, formCode?: string) {
    return await this.reqDeleteChannelStoreTax(channelId, storeId, taxLine, formCode);
  }
  async reqAddChannelStoreTax(reqBody: BackendChannelStoreTax, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channelStoreTax.AddChannelStoreTax,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqUpdateChannelStoreTax(reqBody: BackendChannelStoreTax, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channelStoreTax.UpdateChannelStoreTax,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async reqDeleteChannelStoreTax(channelId: number, storeId: number, taxLine: number, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.channelStoreTax.DeleteChannelStoreTax,
      hostPath: environment.hostPath,
      queryParams: {
        channelId: channelId,
        storeId: storeId,
        taxLine: taxLine
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
 //#endregion

  // #region ChannelSection
  async getOdataChannelSectionProvider(channelId?: number) {
    return await this.reqOdataChannelSectionProvider(channelId);
  }
  async getOdataChannelSection(channelId?: number, sectionId?: number, formCode?: string, data?: ODataRawQueryOptions) {
    return await this.reqOdataChannelSection(channelId, sectionId, formCode, data);
  }
  async reqOdataChannelSection(channelId?: number, sectionId?: number, formCode?: string, data?: ODataRawQueryOptions) {
    let filter = channelId ? ' channelId eq ' + channelId : '';

    if (channelId && sectionId) {
      filter += ' and ';
    }
    filter += sectionId ? ' sectionId eq ' + sectionId : '';

    filter += data ? ' and (' : '';
    filter += data ? data.filter.replace('$filter=', '') + ')': '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channelSection.OdataChannelSection,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  async reqOdataChannelSectionProvider(channelId?: number) {
    const apiPath = environment.apis.channelSection.OdataChannelSection;
    return new OdataProvider({
      callApi: (options) =>
        fetch(`${environment.hostPath}${apiPath}${options}`, {
          headers: {
            SubscriptionID: environment.subscription,
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-Signature': this.getSignature(apiPath, options),
            Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
            'Content-Language': this.translate.currentLang,
          },
        }).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = channelId
          ? ['channelId eq ' + channelId]
          : undefined;
      },
    });
  }

  async addChannelSection(reqBody: BackendChannelSection, formCode?: string) {
    return await this.reqAddChannelSection(reqBody, formCode);
  }

  async updateChannelSection(reqBody: BackendChannelSection, formCode?: string) {
    return await this.reqUpdateChannelSection(reqBody, formCode);
  }
  async deleteChannelSection(channelId: number, sectionId: number, taxLine: number, formCode?: string) {
    return await this.reqDeleteChannelSection(channelId, sectionId, taxLine, formCode);
  }
  async reqAddChannelSection(reqBody: BackendChannelSection, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channelSection.AddChannelSection,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqUpdateChannelSection(reqBody: BackendChannelSection, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channelSection.UpdateChannelSection,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    console.log(reqBody)
    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async reqDeleteChannelSection(channelId: number, sectionId: number, taxLine: number, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.channelSection.DeleteChannelSection,
      hostPath: environment.hostPath,
      queryParams: {
        channelId: channelId,
        sectionId: sectionId,
        taxLine: taxLine
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }


  // #endregion


  // #region ChannelStore
  async getOdataChannelStore(channelId?: number, storeId?: number, formCode?: string) {
    return await this.reqOdataChannelStoreData(channelId, storeId, formCode);
  }

  getOdataChannelStoreProvider(channelId?: number) {
    return this.reqOdataChannelStoreProvider(channelId);
  }

  async addChannelStore(reqBody: BackendChannelStore, formCode?: string) {
    return await this.reqAddChannelStore(reqBody, formCode);
  }

  async updateChannelStore(reqBody: BackendChannelStore, formCode?: string) {
    return await this.reqUpdateChannelStore(reqBody, formCode);
  }

  async deleteChannelStore(channelId: number, storeId: number, formCode?: string) {
    return await this.reqDeleteChannelStore(channelId, storeId, formCode);
  }

  // odata
  async reqOdataChannelStoreData(channelId?: number, storeId?: number, formCode?: string, data?: ODataRawQueryOptions) {
    let filter = channelId ? ' channelId eq ' + channelId : '';

    if (channelId && storeId) {
      filter += ' and ';
    }

    filter += storeId ? ' storeId eq ' + storeId : '';
    filter += data ? ' and (' : '';
    filter += data ? data.filter.replace('$filter=', '') + ')': '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channelStore.OdataChannelStore,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  reqOdataChannelStoreProvider(channelId?: number, storeId?:number) {
    const apiPath = environment.apis.channelStore.OdataChannelStore;
    return new OdataProvider({
      callApi: (options) =>
        fetch(`${environment.hostPath}${apiPath}${options}`, {
          headers: {
            SubscriptionID: environment.subscription,
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-Signature': this.getSignature(apiPath, options),
            Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
            'Content-Language': this.translate.currentLang,
          },
        }).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = channelId
          ? ['channelId eq ' + channelId]
          : storeId
          ? ['storeId eq ' + storeId]
          : undefined;
      },
    });
  }

  async reqAddChannelStore(reqBody: BackendChannelStore, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channelStore.AddChannelStore,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async reqUpdateChannelStore(reqBody: BackendChannelStore, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channelStore.UpdateChannelStore,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async reqDeleteChannelStore(channelId: number, storeId: number, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.channelStore.DeleteChannelStore,
      hostPath: environment.hostPath,
      queryParams: {
        channelId: channelId,
        storeId: storeId,
      },
      headers: {
        formCode: formCode
      }
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion
}
