/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface XplViewModel { 
    profileId?: number;
    profileCode?: string;
    parentId?: number;
    formCode?: string;
    caption?: string;
    headingFlag?: boolean;
    xplViewQueryStr?: string;
    sortOrder?: number;
    itemType?: number;
    xplIcon?: string;
    alert?: string;
    favourite?: boolean;
    formCaption?: string;
    formTypeCode?: string;
    modCode?: string;
    filePath?: string;
    formIcon?: string;
    inactiveFlag?: boolean;
    formQueryStr?: string;
    accessAttributeList?: Array<string>;
    xplViewModels?: Array<XplViewModel>;
}