import { Component } from '@angular/core';
import { AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-detail-cell-renderer',
  standalone: true,
  templateUrl: './detail-cell-renderer.component.html',
  styleUrls: ['./detail-cell-renderer.component.scss'],
  imports: [AgGridModule]
})
export class DetailCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  masterGridApi!: GridApi;
  rowId!: string;
  colDefs!: ColDef[];
  defaultColDef!: ColDef;
  rowData!: any[];

  agInit(params: any): void {
    this.params = params;

    this.masterGridApi = params.api;
    this.rowId = params.node.id!;

    this.colDefs = params.detailGridOptions.columnDefs;

    Object.keys(params.data).forEach(key => {
      if(key.includes('Json')) {
        this.rowData = JSON.parse(params.data[key]);
      }
    });
  }

  refresh(params: any): boolean {
    return false;
  }

  onGridReady(params: GridReadyEvent) {
  }

  ngOnDestroy(): void {
  }
}
