   <div class="col-12 form-layout-padding">
  <!-- Store OH form component-->
  <h1>Store OH Form</h1>

  <form class="form-class" [formGroup]="userFormGroup" (ngSubmit)="onSubmit()">
    <div class="header">
      <div class="err-container hidden-sm" *ngIf="requiredFormError">
        <span class="err-msg">
          {{'msg.required_msg' | translate}}
        </span>
      </div>
      <div class="btn-container" style="margin: 0 16px;" *ngIf="formDetail">
        <app-action-tool-bar [formDetail]="formDetail" [actionToolbarSetting]="actionToolbarSetting"
          (onAction)="onActionButton($event)"></app-action-tool-bar>
      </div>
    </div>
    <div class="form-content form-standard">
      <div class="flex flex-column">
        <mat-form-field>
          <mat-label class="mat-label">Description</mat-label>
          <input matInput placeholder="ie. Daily Schedule" required="true" formControlName="description"
            id="description" [maxlength]="255">
          <mat-icon-button *ngIf="getFormGrpCtrl('description').value !== ''" matSuffix mat-icon-button
            aria-label="Clear">
            <mat-icon *ngIf="getFormGrpCtrl('description').status === 'VALID'"
              class="indicator-icon valid-icon">check_circle_outline</mat-icon>
          </mat-icon-button>
          <mat-error *ngIf="getFormGrpCtrl('description')?.errors?.required">Description{{'msg.is_required' |
            translate}}</mat-error>
        </mat-form-field>
      </div>

      <!-- category -->
      <div class="formgrid grid mt-3">
        <mat-button-toggle-group style="width: 100%" name="fontStyle" formControlName="category" aria-label="Font Style">
          <mat-button-toggle style="width:100%" *ngFor="let category of categories" value="{{category.name}}">
            {{category.name}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!-- weekdays -->
      <div class="flex flex-wrap formgrid grid mt-3">
        <mat-button-toggle-group style="width: 100%" name="ingredients" formControlName="weekdays" aria-label="Ingredients" multiple>
          <mat-button-toggle style="width:100%" class="flex flex-column col" *ngFor="let weekday of weekdays" value="{{weekday.name}}">
            {{weekday.name}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!-- start time -->
      <div class="formgrid grid mt-3">
        <div class="flex flex-column col">
          <mat-form-field class="time-input">
            <mat-label>Start Time</mat-label>
            <input type="time" required="true" formControlName="startTime" matInput name="startTime" />
            <mat-hint>{{getFormGrpCtrl('startTime').value}}</mat-hint>
            <mat-icon-button *ngIf="getFormGrpCtrl('startTime').value !== ''" matSuffix mat-icon-button
              aria-label="Clear">
              <mat-icon *ngIf="getFormGrpCtrl('startTime').status === 'VALID'"
                class="indicator-icon valid-icon">check_circle_outline</mat-icon>
            </mat-icon-button>
            <mat-error *ngIf="getFormGrpCtrl('startTime')?.errors?.required">Start Time{{'msg.is_required' |
              translate}}</mat-error>
          </mat-form-field>
        </div>

        <!-- end time -->
        <div class="flex flex-column col">
          <mat-form-field class="time-input">
            <mat-label>End Time</mat-label>
            <input type="time" required="true" formControlName="endTime" matInput name="endTime" />
            <mat-hint>{{getFormGrpCtrl('endTime').value}}</mat-hint>
            <mat-icon-button *ngIf="getFormGrpCtrl('endTime').value !== ''" matSuffix mat-icon-button
              aria-label="Clear">
              <mat-icon *ngIf="getFormGrpCtrl('endTime').status === 'VALID'"
                class="indicator-icon valid-icon">check_circle_outline</mat-icon>
            </mat-icon-button>
            <mat-error *ngIf="getFormGrpCtrl('endTime')?.errors?.required">End Time{{'msg.is_required' |
              translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <hr class="mt-3">

      <div class="formgrid grid mt-3">
        <div class="flex flex-column col">
          <mat-form-field appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="startDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="flex flex-column col">
          <mat-form-field appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="endDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="formgrid grid mt-3">
        <div class="flex flex-row col sm:col-7 md:col-8">
          <label for="integeronly">Hide schedule</label>
        </div>
        <div class="flex flex-row col align-items-center justify-content-end col-offset-0">
          <mat-slide-toggle [color]="'primary'" formControlName="hideSchedule"></mat-slide-toggle>
        </div>
      </div>

      <div class="formgrid grid mt-3">
        <div class="flex flex-row col">
          <label for="integeronly">Hide description</label>
        </div>
        <div class="flex flex-row col align-items-center justify-content-end col-offset-3">
          <mat-slide-toggle [color]="'primary'" formControlName="hideDescription"></mat-slide-toggle>
        </div>
      </div>

      <div class="formgrid grid mt-3 pl-2 pr-2">
        <button mat-raised-button type='button' class="col-4 cancel-btn mr-2">Cancel</button>
        <button mat-raised-button type="submit" class="col add-btn">Add Schedule</button>
      </div>

      <hr class="mt-3 mb-3">

      <div class="storeoh-panel">
        <div style="display: flex;">
          <span class="week-word mr-1">W</span> Weekly Operations
          <span style="margin-left: auto;">
            <img src="assets/base/icons/pencil.svg" class="img-icon" />
            <img src="assets/base/icons/trash.svg" class="img-icon" />
          </span>
        </div>

        <div>
          Mon, Wed, Fri - 07:00 - 22:00
        </div>
        <div>
          <div>Start Date: 1/1/22</div>
          <div>End Date: 1/1/22</div>
        </div>
      </div>

      <button mat-raised-button type='button' class="col-12 done-btn mt-2">Cancel</button>
    </div>
  </form>
  <br>
</div>
