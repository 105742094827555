<div class="card-listing-layout">
    <div class="header">
        <app-action-tool-bar [actionToolbarSetting]="actionToolbarSetting" (onAction)="onToolbarAction($event)"></app-action-tool-bar>
    </div>
    <div class="card-container">
        <div class="card-wrapper" *ngFor="let data of cardListingData; let i = index" [ngClass]="{'active': selectedIndex?.includes(i)}" (click)="onClickCard(data)">
            <app-dynamic-card [data]="data" [component]="cardComponentName"></app-dynamic-card>
        </div>
    </div>
    <div class="footer" *ngIf="cardListingData && cardListingData.length > 0">
        <p>{{ 'report.result_found' | translate: {'count': cardListingData.length} }}</p>
    </div>
</div>
