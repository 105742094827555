/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FilterFlag = 'NetAmt' | 'GrossAmt' | 'AddTaxAmt' | 'NetAmtByUnit' | 'GrossAmtByUnit' | 'NetAmtExc' | 'GrossAmtExc' | 'FixedAmount' | 'MultipleByKM' | 'External';

export const FilterFlag = {
    None: 'None' as FilterFlag,
    Include: 'Include' as FilterFlag,
    Exclude: 'Exclude' as FilterFlag
};