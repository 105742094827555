/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SectionDataType = 'PredefinedStores' | 'ChannelStores' | 'InfoPanel' | 'Shortcut' | 'OrderHistory' | 'StoreProducts' | 'SplashCarousell';

export const SectionDataType = {
    PredefinedStores: 'PredefinedStores' as SectionDataType,
    ChannelStores: 'ChannelStores' as SectionDataType,
    InfoPanel: 'InfoPanel' as SectionDataType,
    Shortcut: 'Shortcut' as SectionDataType,
    OrderHistory: 'OrderHistory' as SectionDataType,
    StoreProducts: 'StoreProducts' as SectionDataType,
    SplashCarousell: 'SplashCarousell' as SectionDataType
};
