// Import necessary Angular Material modules
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DateISOPipe } from 'src/app/shared/pipes/date-iso.pipe';
import { DateRangeInputSetting } from '../model/DateRangeInputSetting';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-date-picker-range',
  templateUrl: './date-picker-range.component.html',
  styleUrls: ['./date-picker-range.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    NgIf,
    TranslateModule,
    MatInputModule
  ],
})
export class DatePickerRangeComponent implements OnChanges {
  @Input() inputDateSetting?: DateRangeInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() selectedDate: Date;
  @Input() editDateValue: any;
  @Input() isDateBoolean: boolean
  @Input() formData: any;
  @Output() updateDate: EventEmitter<string> = new EventEmitter<string>();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private datePipe: DatePipe,
    private dateISOPipe: DateISOPipe, 
    private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if(this.formGroup) {
      this.formGroup.addControl('start', this.fb.control(''));
      this.formGroup.addControl('end', this.fb.control(''));
    } else {
      this.formGroup = this.range;
    }
   
    if(this.isDateBoolean) {
      this.setDateValue(this.editDateValue);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue && changes.formData.currentValue[this.inputDateSetting.name]) {
      this.formGroup.controls['start'].setValue(changes.formData.currentValue[this.inputDateSetting.name]?.start);
      this.formGroup.controls['end'].setValue(changes.formData.currentValue[this.inputDateSetting.name]?.end);
      this.formGroup.controls[this.inputDateSetting.name].setValue(changes.formData.currentValue[this.inputDateSetting.name]);
    }
  }

  private setDateValue(value: string): void {
    const rangeDate = value.split(' - ');
    const parsedStartDate = this.dateISOPipe.transform(rangeDate[0]);
    const parsedEndDate = this.dateISOPipe.transform(rangeDate[1]);

    const startDate = new Date(parsedStartDate);
    const endDate = new Date(parsedEndDate);

    this.formGroup.controls['start'].setValue(startDate);
    this.formGroup.controls['end'].setValue(endDate);
    this.formGroup.controls[this.inputDateSetting.name].setValue({ start: startDate, end: endDate });
  }

  onDateChange() : void {
    const {start, end} = {start: this.formGroup.get('start').value, end: this.formGroup.get('end').value};
    const startD = this.datePipe.transform(start, 'dd/MM/yyyy');
    const endD = this.datePipe.transform(end, 'dd/MM/yyyy');

    this.updateDate.emit(`${startD} - ${endD}`);
  }
}
