import { PageService } from './../../shared/services/page.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  NgClass,
  NgIf,
  NgStyle,
} from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { UserProfile } from 'src/swagger/base-rms/openapi/model/models';
import { RMSSubscriptionInfoResponse } from 'src/app/core/models/local/RMSSubscriptionInfoResponse';
import { MatDialog } from '@angular/material/dialog';
import { JobStatusComponent } from 'src/app/shared/component/job-status/job-status.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, NgIf, NgClass, RouterLink, NgStyle],
})
export class HeaderComponent {
  @Input() currentContent: string;
  @Input() userProfile: UserProfile;
  @Input() sideNavPinned: boolean;
  @Input() drawer: any;
  @Input() isStandalonePage: boolean;
  @Output() toggleDropdownEvent = new EventEmitter<string>();
  @Output() toggleSideNavEvent = new EventEmitter<void>();
  @Output() closeMenu = new EventEmitter<any>();

  @Input() menuOpen: boolean;
  @Output() menuOpenChange = new EventEmitter<boolean>();
  @Output() closeSide = new EventEmitter<any>();

  subscriptionDetail: RMSSubscriptionInfoResponse;

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private pageService: PageService
  ) {
    this.subscriptionService.getSelectedSubscriptionRepo().subscribe(sub => {
      this.subscriptionDetail = sub;
    });
  }

  toggleDropdown(contentType: string) {
    this.toggleDropdownEvent.emit(contentType);
  }

  toggleSideNav() {
    this.menuOpen = !this.menuOpen;
    this.menuOpenChange.emit(this.menuOpen);
  }

  clickLogo() {
    if(!this.isStandalonePage) {
      this.router.navigateByUrl('/dashboard');
      this.closeMenu.emit();
      this.closeSide.emit();
    }
  }

  openJobQueue() {
    const dialogRef = this.dialog.open(JobStatusComponent, {
      height: '100vh',
      maxWidth: '100vw',
      width: '100vw',
      panelClass: 'fullWidth',
      hasBackdrop: false,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {

      }
    });
  }
}
