<div class="toolbar" [ngStyle]="{'top: 25px !important' : openFavourite}">
  <div class="filter">

    <div class="searchFilter">
      <img style="width: 20px;" src="assets/base/icons/filter.svg">
      <input class="filterbar" type="text" pInputText [(ngModel)]="searchInput" (input)="filterSearch()">
      <span style="cursor: pointer;" *ngIf="isSearch" (click)="clearSearch()"><img style="width: 20px;"
          src="assets/base/icons/remove.svg"></span>
    </div>

    <div class="filter-buttons">
      <button class="filter-button" [ngClass]="{'active': activeBtn === 'Show All'}"
        (click)="setActiveButton('Show All')">
        <img style="width: 20px; height: 20px; margin-right: 5px;"
            src="assets/base/icons/cardsThree.svg">{{'general.show_all' | translate}}</button>
      <button class="filter-button" *ngIf="favouriteBut" [ngClass]="{'active': activeBtn === 'Favorite'}"
        (click)="setActiveButton('Favorite')">
        <span><img style="width: 20px; height: 20px; margin-right: 5px;"
            src="assets/base/icons/star.svg"></span>{{'general.favorites' | translate}}</button>
      <ng-container *ngIf="openFavourite; else defaultMenuList">
        <button class="filter-button" *ngFor="let button of getFavouriteMenuItems(menuList)"
          [ngClass]="{'active': activeBtn === translateCaption(button.textId, button.caption)}"
          (click)="setActiveButton(translateCaption(button.textId, button.caption))">
          <span style="height: 100%; display: flex; align-items: center;">
            <img style="width: 20px; height: 20px; margin-right: 5px;"
              [src]="'assets/base/icons/' + button.icon + '.svg'">
          </span>{{ translateCaption(button.textId, button.caption) }}
        </button>
      </ng-container>
      <ng-template #defaultMenuList>
        <div style="display: flex;" *ngIf="!openRecent">
          <button class="filter-button" *ngFor="let button of menuList"
            [ngClass]="{'active': activeBtn === translateCaption(button.textId, button.caption)}"
            (click)="setActiveButton(translateCaption(button.textId, button.caption))">
            <span style="height: 100%; display: flex; align-items: center;">
              <img style="width: 20px; height: 20px; margin-right: 5px;"
                [src]="'assets/base/icons/' + button.icon + '.svg'">
            </span>{{ translateCaption(button.textId, button.caption) }}
          </button>
        </div>
      </ng-template>
    </div>
    <div class="sort">
      <span style="cursor: pointer;" (click)="onSort()">
        <img style="width: 24px;" [src]='"assets/base/icons/" + sortIcon + ".svg"'>
      </span>
    </div>
  </div>
  <div class="closeMenu">
    <span style="cursor: pointer;" (click)="onClose()">
      <img style="width: 24px;" [src]='"assets/base/icons/remove.svg"'>
    </span>
  </div>
</div>

<div *ngIf="openRecent; else menu">
  <div class="navigation-menu">
    <div class="contentBox">
      <div class="content" *ngFor="let content of filteredMenuList">
        <div class="subMenu-content" (click)="selectItem(content.filePath)">
          <span style="margin-right: 5px;" *ngIf="!content.isFavourite"><img style="width: 20px;"
              src="assets/base/icons/star.svg"></span>
          <span style="margin-right: 5px;" *ngIf="content.isFavourite"><img style="width: 20px;"
              src="assets/base/icons/star-solid-orange.svg"></span>
          <span class="caption">{{ translateCaption(content.textId, content.caption)}}</span>
          <span
            [ngClass]="{'preview': content.badge === 'preview', 'new': content.badge === 'new'}">{{content.badge}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #menu>
  <div *ngIf="openFavourite; else navMenu">
    <div *ngFor="let menu of favourites; let i = index">
      <div class="navigation-menu " *ngIf=" filteredMenuList[i]?.length > 0">
        <div class="mainMenu" (click)="toggleSubMenu(menu)">
          <div class="mainMenuTool">
            <div style="font-weight: 700;">{{ menu.caption }}</div>
            <span style="font-weight: 700; margin-left: 10px;">/</span>
            <span>
              <div class="filter-buttons">
                <button class="filter-button" [ngClass]="{'active': activeBtns[i] === 'Show All'}"
                  (click)="setActiveButtons('Show All', i); $event.stopPropagation();">
                  <img style="width: 20px; height: 20px; margin-right: 5px;"
                      src="assets/base/icons/cardsThree.svg">{{'general.show_all' | translate}}</button>
                <button class="filter-button" *ngIf="!openFavourite"
                  [ngClass]="{'active': activeBtns[i] === 'Favorite'}"
                  (click)="setActiveButtons('Favorite', i); $event.stopPropagation();">
                  <span><img style="width: 20px; height: 20px; margin-right: 5px;"
                      src="assets/base/icons/star.svg"></span>{{'general.favorites' | translate}}</button>
                <button class="filter-button" *ngFor="let button of menu.list; let j = index"
                  [ngClass]="{'active': activeBtns[i] === translateCaption(button.textId, button.caption)}"
                  (click)="setActiveButtons(translateCaption(button.textId, button.caption), i); $event.stopPropagation();">
                  <span style="height: 100%; display: flex; align-items: center;">
                    <img style="width: 20px; height: 20px; margin-right: 5px;"
                      [src]="'assets/base/icons/' + button.icon + '.svg'">
                  </span>{{ translateCaption(button.textId, button.caption) }}
                </button>
              </div>
            </span>
          </div>
          <span class="toggleIcon"><img *ngIf="!menu.isOpen" style="width: 14px;"
              src="assets/base/icons/chevron-up.svg">
            <img *ngIf="menu.isOpen" style="width: 14px;" src="assets/base/icons/chevron-down.svg"></span>
        </div>

        <div class="contentContainer" [ngClass]="{'close': menu.isOpen}">
          <div *ngFor="let list of filteredMenuList[i]; let j = index">
            <div class="subMenu" style="color: #9393A3; border: none;" [ngStyle]="{'margin-top.px': j === 0 ? 0 : 10}"
              *ngIf="list.item.length > 0">
              <span>{{ translateCaption(list.textId, list.caption) }}
                <span style="color: #b8b8be; font-style: italic; font-weight: 300 !important;">{{list.item.length}}
                  {{'general.listed' | translate}}</span></span>
            </div>

            <div class="contentBox" [ngClass]="{'close': list.isOpen}">
              <div class="content" *ngFor="let content of list.item">
                <div class="subMenu-content" (click)="selectItem(content.filePath)">
                  <span style="margin-right: 5px;" *ngIf="!content.isFavourite"><img style="width: 20px;"
                      src="assets/base/icons/star.svg"></span>
                  <span style="margin-right: 5px;" *ngIf="content.isFavourite"><img style="width: 20px;"
                      src="assets/base/icons/star-solid-orange.svg"></span>
                  <span class="caption">{{ translateCaption(content.textId, content.caption)}}</span>
                  <span
                    [ngClass]="{'preview': content.badge === 'preview', 'new': content.badge === 'new'}">{{content.badge}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #navMenu>
  <div *ngIf="hasItems(); else empty">
    <div class="navigation-menu " *ngFor="let menu of filteredMenuList">
      <div class="subMenu" style="cursor: pointer;" (click)="toggleSubMenu(menu)" *ngIf="menu.item.length > 0">
        <span>{{ translateCaption(menu.textId, menu.caption) }}
          <span style="color: #b8b8be; font-style: italic; font-weight: 300 !important;">{{menu.item.length}}
            {{'general.listed' | translate}}</span></span>
        <span class="toggleIcon"><img *ngIf="!menu.isOpen" style="width: 14px;" src="assets/base/icons/chevron-up.svg">
          <img *ngIf="menu.isOpen" style="width: 14px;" src="assets/base/icons/chevron-down.svg"></span>
      </div>

      <div class="contentBox" [ngClass]="{'close': menu.isOpen}">
        <div class="content" *ngFor="let content of menu.item">
          <div class="subMenu-content" (click)="selectItem(content.filePath)">
            <span style="margin-right: 5px;" *ngIf="!content.isFavourite"><img style="width: 20px;"
                src="assets/base/icons/star.svg"></span>
            <span style="margin-right: 5px;" *ngIf="content.isFavourite"><img style="width: 20px;"
                src="assets/base/icons/star-solid-orange.svg"></span>
            <span class="caption">{{ translateCaption(content.textId, content.caption)}}</span>
            <span
              [ngClass]="{'preview': content.badge === 'preview', 'new': content.badge === 'new'}">{{content.badge}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <div class="resultEmpty">
    <img class="no-results" src="assets/base/icons/no-results.svg">
    {{"msg.search_empty" | translate}} <br>
    {{"msg.try_again" | translate}}
  </div>
</ng-template>

<div class="resultEmpty"
  *ngIf="(openFavourite && (isResultEmpty || getFavouriteMenuItems(menuSet).length === 0)) || (openRecent && isResultEmpty)">
  <img class="no-results" src="assets/base/icons/no-results.svg">
  {{"msg.search_empty" | translate}} <br>
  {{"msg.try_again" | translate}}
</div>
