import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { InputNumberSetting } from '../model/InputNumberSetting.model';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  standalone: true,
  imports: [
    FormsModule, 
    ReactiveFormsModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatIconModule, 
    TranslateModule,
    NgIf
  ]
})
export class InputNumberComponent implements OnInit, OnChanges {
  @Input() inputNumberSetting?: InputNumberSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;

  @Output() onValueChange = new EventEmitter<any>();
  @ViewChild('numInput', {static: false}) numInput: ElementRef;

  pressTimer: any;
  formGrpCtrl: any;

  ngOnInit(): void {
    this.formGrpCtrl = this.formGroup.controls[this.inputNumberSetting.name];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue && changes.formData.currentValue[this.inputNumberSetting.name] !== '') {
      this.formGroup.controls[this.inputNumberSetting.name].setValue(changes.formData.currentValue[this.inputNumberSetting.name]);
    }
  }

  onClickIncreaseNum() {
    this.numInput.nativeElement.stepUp();
    this.formGroup.controls[this.inputNumberSetting.name].setValue(this.numInput.nativeElement.value);
  }

  onClickReduceNum() {
    this.numInput.nativeElement.stepDown();
    this.formGroup.controls[this.inputNumberSetting.name].setValue(this.numInput.nativeElement.value);
  }

  onMouseUp() {
    clearTimeout(this.pressTimer);
  }

  onMouseDown(up: boolean) {
    this.longHandler(up);
  }

  onKeyDown(event: any) {
    if(!this.inputNumberSetting?.isExponent) {
      return event.keyCode !== 69;
    }
    return true;
  }

  longHandler(up: boolean) {
    this.pressTimer = window.setTimeout(() => {
      if(up) {
        this.numInput.nativeElement.stepUp();
      } else {
        this.numInput.nativeElement.stepDown();
      }
      clearTimeout(this.pressTimer);
      this.longHandler(up);
    }, 150);
  }
}
