/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IEdmReferentialConstraint } from './iEdmReferentialConstraint';
import { IEdmStructuredType } from './iEdmStructuredType';
import { IEdmTypeReference } from './iEdmTypeReference';

export interface IEdmNavigationProperty { 
    partner?: IEdmNavigationProperty;
    readonly onDelete?: IEdmNavigationProperty.OnDeleteEnum;
    readonly containsTarget?: boolean;
    referentialConstraint?: IEdmReferentialConstraint;
    readonly propertyKind?: IEdmNavigationProperty.PropertyKindEnum;
    type?: IEdmTypeReference;
    declaringType?: IEdmStructuredType;
    readonly name?: string;
}
export namespace IEdmNavigationProperty {
    export type OnDeleteEnum = 'None' | 'Cascade';
    export const OnDeleteEnum = {
        None: 'None' as OnDeleteEnum,
        Cascade: 'Cascade' as OnDeleteEnum
    };
    export type PropertyKindEnum = 'None' | 'Structural' | 'Navigation';
    export const PropertyKindEnum = {
        None: 'None' as PropertyKindEnum,
        Structural: 'Structural' as PropertyKindEnum,
        Navigation: 'Navigation' as PropertyKindEnum
    };
}