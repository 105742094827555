import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { Page } from 'src/app/shared/models/page.model';
import { StoreService } from '../../store.service';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { FormCode } from 'src/app/shared/enums/formCode';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { UntypedFormGroup } from '@angular/forms';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { formatDateString } from 'src/app/core/services/utils.service';
import { BackendStoreOH } from 'src/swagger/cxmbackend/openapi';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-store-oh-entry',
  standalone: true,
  imports: [CommonModule, FormComponent],
  templateUrl: './store-oh-entry.component.html',
  styleUrls: ['./store-oh-entry.component.scss'],
})
export class StoreOhEntryComponent {
  formSetting: FormSetting = {
    formCode: FormCode.storeohentry,
    content: [
      {
        inputNumber: {
          name: 'storeId',
          label: 'store_oh.store_id',
          required: true,
          styling: {
            width: '30%',
          },
          disabled: true
        },
      },
      {
        inputNumber: {
          name: 'seqNo',
          label: 'store_oh.seq_no',
          required: true,
          styling: {
            width: '30%',
          },
        },
      },
      {
        inputText: {
          name: 'weekDay',
          label: 'store_oh.week_day',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '30%',
          },
          maxLength: 7,
          editable: true,
        },
      },
      {
        inputText: {
          name: 'weekDayDesc',
          label: 'store_oh.week_day_desc',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '30%',
          },
          maxLength: 30,
          editable: true,
        },
      },
      {
        inputText: {
          name: 'startDate',
          label: 'store_oh.start_date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '30%',
          },
          editable: true,
        },
      },
      {
        inputText: {
          name: 'endDate',
          label: 'store_oh.end_date',
          type: InputTypes.date,
          required: false,
          styling: {
            width: '30%',
          },
          editable: true,
        },
      },
      {
        timeInput: {
          name: 'startTime1',
          label: 'store_oh.start_time',
          styling: {
            width: '30%'
          },
        }
      },
      {
        timeInput: {
          name: 'endTime1',
          label: 'store_oh.end_time',
          styling: {
            width: '30%'
          },
        }
      },
      {
        toggleInput: {
          name: 'closedFlag',
          label: 'store_oh.closed',
          required: true,
          styling: {
            width: '30%',
          },
        },
      },
      {
        inputText: {
          name: 'closedMsg',
          label: 'popupMsg.closed_msg',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '30%',
          },
          maxLength: 30,
          editable: true,
        },
      },
    ],
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm,
  };

  @Input() page: Page;
  @Input() params = null;
  formDetail: any = null;
  storeId: any = undefined;
  seqNo: any = undefined;
  storeOHData: any;
  editMode: boolean = false;

  constructor(
    private authService: AuthService,
    private storeService: StoreService,
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog,
    private formService: FormService
  ) {}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);

      this.storeId = this.params ? this.params['storeId'] : params['storeId'];
      this.seqNo = this.params ? this.params['seqNo'] : params['seqNo'];
    }

    if (this.storeId && this.seqNo) {
      await this.getStoreOH(this.storeId, this.seqNo);
    }
    else {
      this.storeOHData = {
        storeId: this.storeId
      }
    }
  }

  async getStoreOH(storeId: number, seqNo: number) {
    let result = await this.storeService.getOdataStoreOH(storeId, seqNo, this.formDetail.formCode);

    if (
      result instanceof HttpErrorResponse === false &&
      result['value'].length > 0
    ) {
      this.storeOHData = result['value'][0];
    }
  }

  onToolbarAction(resp: ActionToolBarResp) {}

  async onSubmitForm(formGroup: UntypedFormGroup) {
    let result = null;

    if (this.storeId && this.seqNo) {
      let reqBody: BackendStoreOH = {
        storeId: this.storeId,
        seqNo: this.seqNo,
        weekDay: formGroup.get('weekDay').value,
        weekDayDesc: formGroup.get('weekDayDesc').value,
        startDate:
          formGroup.get('startDate').value == null
            ? null
            : formatDateString(formGroup.get('startDate').value),
        endDate:
          formGroup.get('endDate').value == null
            ? null
            : formatDateString(formGroup.get('endDate').value),
        startTime1: formGroup.get('startTime1').value,
        endTime1: formGroup.get('endTime1').value,
        closedFlag: formGroup.get('closedFlag').value,
        closedMsg: formGroup.get('closedMsg').value,
      };

      result = await this.storeService.updateStoreOH(reqBody, this.formDetail.formCode);
    } else {
      let reqBody: BackendStoreOH = {
        storeId: this.storeId,
        seqNo: formGroup.get('seqNo').value,
        weekDay: formGroup.get('weekDay').value,
        weekDayDesc: formGroup.get('weekDayDesc').value,
        startDate:
          formGroup.get('startDate').value == null
            ? null
            : formatDateString(formGroup.get('startDate').value),
        endDate:
          formGroup.get('endDate').value == null
            ? null
            : formatDateString(formGroup.get('endDate').value),
        startTime1: formGroup.get('startTime1').value,
        endTime1: formGroup.get('endTime1').value,
        closedFlag: formGroup.get('closedFlag').value,
        closedMsg: formGroup.get('closedMsg').value,
      };

      result = await this.storeService.addStoreOH(reqBody, this.formDetail.formCode);
    }

    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {
      this.popupFormService.close(this.page.form.formCode);

      let msg: PopupMessage = {
        formCode: FormCode.storeohlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.store_oh_saved',
        desc: (this.storeId && this.seqNo)
          ? 'msg.updated_store_oh'
          : 'msg.added_new_store_oh',
        actionBtnText: 'general.okay',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });

      this.pageService.refresh([FormCode.storeohlisting]);
    } else {
      let msg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.operation_unsuccessful',
        desc: 'msg.system_couldnt_save_store_oh',
        actionBtnText: 'general.back',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
    }
  }
}
