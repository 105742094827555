import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  toggleChange$: Subject<{name: string, checked: boolean}> = new Subject();
  toggleExpansionPanel$: Subject<{name: string, expand: boolean}> = new Subject();
  required$: Subject<{name: string, required: boolean}> = new Subject();

  constructor() { }

  setRequired(name: string, required: boolean) {
    this.required$.next({name: name, required: required});
  }
}
