import {  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { CellPosition, ColDef, GridApi, GridReadyEvent, TabToNextCellParams } from 'ag-grid-community';
import { PropertyGridRendererComponent } from '../ag-grid/property-grid-renderer/property-grid-renderer.component';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { PropertyGridSetting } from '../../models/PropertyGridSetting.model';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { TreeComponent } from '../tree/tree.component';
import * as _ from 'lodash';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AttributeCode } from '../../enums/attributeCode';
import { ButtonType } from '../../enums/buttonType';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { PropertyGridService } from '../services/property-grid.service';
import { TreeSetting } from '../tree/model/treeSetting.model';
import { ActionToolbarService } from '../services/action-toolbar.service';
import { ActionToolBarResp } from '../action-tool-bar/model/ActionToolBarResp.model';

@Component({
  selector: 'app-property-grid',
  standalone: true,
  imports: [CommonModule, AgGridModule, ActionToolBarComponent, TreeComponent, TranslateModule, ReactiveFormsModule, FormsModule],
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.scss']
})
export class PropertyGridComponent implements OnInit, OnChanges {
  @Input() rowData?: any;
  @Input() setting?: PropertyGridSetting;
  @Input() menuSetting?: TreeSetting;
  @Input() data?: any;
  @Input() actionToolbarSetting: ActionToolBarSetting;
  @Output() onMenuSelectionChanged = new EventEmitter<any>();
  @Output() onToolbarAction = new EventEmitter<ActionToolBarResp>();
  @Output() onSubmit = new EventEmitter<any>();

  defaultColDef: ColDef = {
    resizable: true,
    enableRowGroup: true,
    editable: false,
    sortable: true,
    autoHeight: true
  };

  colDef: ColDef[] = [];

  groupRowRendererParams: any = {
    suppressCount: true
  };

  private gridApi!: GridApi;

  formGroup: UntypedFormGroup;
  frameworkComponents: any;

  treeData: any;
  selectedNode: any;

  constructor(private translateService: TranslateService,
    private propertyGridService: PropertyGridService,
    private actionToolbarService: ActionToolbarService) {}

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});

    // Setting
    this.setting = {
      title: this.setting?.title? this.setting.title: '',
      parentKey: this.setting?.parentKey? this.setting.parentKey: 'ParentCode',
      groupKey: this.setting?.groupKey? this.setting.groupKey: 'SegmentCode',
      groupCaption: this.setting?.groupCaption? this.setting.groupCaption: 'SegmentCaption',
      groupDesc: this.setting?.groupDesc? this.setting.groupDesc: 'SegmentDesc',
      titleCaption: this.setting?.titleCaption? this.setting.titleCaption: 'SetCaption',
      titleDesc: this.setting?.titleDesc? this.setting.titleDesc: 'SetDesc',
      valueTypeKey: this.setting?.valueTypeKey? this.setting.valueTypeKey: 'ValueType',
      inputTypeKey: this.setting?.inputTypeKey? this.setting.inputTypeKey: 'InputType',
      inputKey: this.setting?.inputKey? this.setting.inputKey: 'SetCode',
      inputVal: this.setting?.inputVal? this.setting.inputVal: 'SetValue',
      dropdownData: this.setting?.dropdownData? this.setting.dropdownData: 'DataSource',
      childKey: this.setting?.childKey? this.setting.childKey: 'Children'
    }

    // Menu Setting
    this.menuSetting = {
      level: this.menuSetting?.level? this.menuSetting.level: 1,
      childKey: this.menuSetting?.childKey? this.menuSetting.childKey: 'Children',
      descCaption: this.menuSetting?.descCaption? this.menuSetting.descCaption: 'SegmentCaption',
      desc: this.menuSetting?.desc? this.menuSetting.desc: 'SegmentDesc',
      id: this.menuSetting?.id? this.menuSetting.id: 'SegmentCode'
    }

    // Set data
    this.setTreeData();

    // Set toolbar
    this.actionToolbarSetting = this.actionToolbarSetting? this.actionToolbarSetting: {
      actionToolBarItems: [
        { name: 'showAll', text: 'toolbar.show_all', sortNo: 1 },
        { name: 'filter', icon: 'assets/base/icons/filter.svg', text: 'toolbar.filter', input: true, clickable: false, sortNo: 2 },
        { name: 'save', icon: 'assets/base/icons/save.svg', text: 'toolbar.save', btnType: ButtonType.submit, checkPermission: [AttributeCode.EDIT], sortNo: 1, buttonActive: true, position: ActionToolbarPosition.RIGHT },
      ],
      position: ActionToolbarPosition.LEFT
    };
  }

  setTreeData() {
    if(this.setting?.title) {
      this.treeData = [{[this.setting.groupDesc]: this.setting.title, [this.setting.groupDesc]: this.setting.title, [this.menuSetting.childKey]: this.data}];
    } else {
      this.treeData = this.data;
    }

    if(this.treeData && this.treeData[0]) {
      this.selectedNode = this.treeData[0];
      this.rowData = this.propertyGridService.getChildData(this.treeData[0], this.setting);
      this.setColDef();
    }
  }

  setColDef() {
    // Set Col Def
    this.colDef = [
      {
        field: this.setting.groupKey,
        rowGroup: true,
        hide: true,
        cellRenderer:'agGroupCellRenderer',
        cellRendererParams: {
          suppressDoubleClickExpand: true,
          suppressCount: true,
        },
        valueFormatter: (params: any) => {
          let value = this.rowData.find(data => data[this.setting.groupKey] === params.value);  
          let parent = this.data.find(data => value && data[this.setting.parentKey] === value[this.setting.parentKey]);
          let parentVal = "";
          let fieldVal = "";

          if(parent) {
            let parentKey = parent[this.setting.groupCaption]? parent[this.setting.groupCaption]: null;
            parentVal = parentKey && this.translateService.instant(parentKey) !== parentKey? this.translateService.instant(parentKey): parent[this.setting.groupDesc];
          }

          if(value) {
            let fieldKey = value[this.setting.groupCaption];
            fieldVal = fieldKey && this.translateService.instant(fieldKey) !== fieldKey? this.translateService.instant(fieldKey): value[this.setting.groupDesc];
          }
          return parentVal + " - " + fieldVal;
        },
        filterValueGetter: (params: any) => {
          let field = params.data[this.setting.groupCaption];
          return field && this.translateService.instant(field) !== field? this.translateService.instant(field): params.data[this.setting.groupDesc];
        }
      },
      {
        field: this.setting.titleCaption,
        width: 250,
        valueGetter: (params: any) => {
          let field = params.data[this.setting.titleCaption];
          return field && this.translateService.instant(field) !== field? this.translateService.instant(field): params.data[this.setting.titleDesc];
        },
        filterValueGetter: (params: any) => {
          let field = params.data[this.setting.titleCaption];
          return field && this.translateService.instant(field) !== field? this.translateService.instant(field): params.data[this.setting.titleDesc];
        }
      },
      {
        field: this.setting.valueTypeKey,
        cellRenderer: PropertyGridRendererComponent,
        flex: 1,
        maxWidth: 445,
        suppressSizeToFit: true,
        resizable: false,
        filter: false,
        cellRendererParams: {
          setting: this.setting,
          formGroup: this.formGroup,
          rowData: this.rowData
        },
        filterValueGetter: (params: any) => {
          return params.data.value;
        }
      }
    ]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gridApi) {
      if (changes.rowData?.currentValue) {
        this.gridApi.setGridOption("rowData", changes.rowData.currentValue);
      }

      if (changes.setting?.currentValue) {
        this.setting = changes.setting.currentValue;
      }

      if (changes.actionToolbarSetting?.currentValue) {
        this.actionToolbarSetting = changes.actionToolbarSetting.currentValue;
      }
    }

    if(changes.data?.currentValue) {
      this.data = changes.data.currentValue;

      if(this.data.length > 0) {
        this.setTreeData();
        this.rowData = this.propertyGridService.getChildData(this.data, this.setting);
      }
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onActionToolbar(event: ActionToolBarResp) {
    this.gridApi?.setGridOption("quickFilterText", null);
    this.treeData = this.data;

    if(event.name === 'filter' && this.gridApi) {
      let filterData: any;
      filterData = _.cloneDeep(this.data);
      this.rowData = this.propertyGridService.getChildData(filterData, this.setting);

      if(event.data && this.gridApi) {
        this.gridApi.setGridOption("quickFilterText", event.data);
      }

      this.selectedNode = null;
    } else if(event.name === 'showAll') {
      this.rowData = this.propertyGridService.getChildData(this.data, this.setting);
      this.selectedNode = null;
      this.actionToolbarService.clearInput.next(['filter']);
    }

    event.data = {actionToolbarSetting: this.actionToolbarSetting};
    this.onToolbarAction.emit(event);
  }

  onSelectionChanged(node: any) {
    this.rowData = this.propertyGridService.getChildData(node, this.setting);
    this.selectedNode = node;
    this.onMenuSelectionChanged.emit(node);
  }

  onFilterChanged(event: any) {
    if(this.gridApi.getQuickFilter()) {
      let renderedNodes = [];
      event.api.getRenderedNodes().forEach(node => {
        if(node?.data) {
          renderedNodes.push(node.data);
        }
      });

      let groupKeys = renderedNodes.map(node => node[this.setting.groupKey]).filter((data, i, arr) => i === arr.indexOf(data));
      let parentKeys = renderedNodes.map(node => node[this.setting.parentKey]).filter((data, i, arr) => i === arr.indexOf(data));

      let data = _.cloneDeep(this.data);
      this.treeData = this.propertyGridService.getParentData(parentKeys, groupKeys, data, this.setting);
    }
  }

  onCellFocused(event: any) {
    let row = this.gridApi.getDisplayedRowAtIndex(event.rowIndex);

    if(row && row.data) {
      let titleDesc = row.data[this.setting.titleDesc];
      let element = document.getElementById(titleDesc);

      if(element?.classList?.contains('mat-mdc-slide-toggle')) {
        let button = document.getElementById(titleDesc + '-button');
        button.focus();
      }

      if(element) {
        element.focus();
      }

      if(element instanceof HTMLInputElement) {
        element.select();
      }
    }
  }

  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  onSubmitGrid() {
    // Validate to submit only edited values
    let editedField: any = [];
    let value = this.rowData.map(value => {return {[value[this.setting.titleDesc]] : value[this.setting.inputVal]}});
    let valueObj = Object.assign({}, ...value);

    Object.keys(this.formGroup.controls).forEach((name) => {
      const currentControl = this.formGroup.get(name);
      if (currentControl.touched && valueObj && currentControl.value !== valueObj[name]) {
        editedField.push({[name]: currentControl.value});
      }
    });

    editedField = Object.assign({}, ...editedField);
    this.onSubmit.emit(editedField);
  }

  tabToNextCell(params: TabToNextCellParams): CellPosition | null {
    const previousCell = params.previousCellPosition;
    const lastRowIndex = previousCell.rowIndex;
    let nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;
    const renderedRowCount = params.api!.getModel().getRowCount();

    if (nextRowIndex < 0) {
      nextRowIndex = -1;
    }

    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }

    const result = {
      rowIndex: nextRowIndex,
      column: previousCell.column,
      rowPinned: previousCell.rowPinned,
    };

    return result;
  }
}
