import { SubscriptionCardComponent } from './../card/subscription-card/subscription-card.component';
import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { NgFor, NgIf, NgClass, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { LanguageComponent } from '../language/language.component';
import { InputTextComponent } from '../input/input-text/input-text.component';
import { FormService } from '../form/form.service';
import { FormContent } from 'src/app/shared/component/form/model/FormContent.model';
import { InputTypes } from '../input/enum/InputTypes';
import { MenuItem } from 'primeng/api/menuitem';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { MatIconModule } from '@angular/material/icon';
import { CardComponent } from '../card/card.component';
import { TranslateService, TranslateModule, LangChangeEvent } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { InputPattern } from '../../enums/inputPattern';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { LookDropComponent } from '../input/look-drop/look-drop.component';
import { UserProfile } from 'src/swagger/base-rms/openapi/model/models';
import { Router } from '@angular/router';
import { SubscriptionUserService } from 'src/app/core/services/subscriptionUser.service';
import { LoaderService } from '../../services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastType } from '../../enums/toastType';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    LanguageComponent,
    InputTextComponent,
    LookDropInputComponent,
    NgFor,
    NgIf,
    NgClass,
    MatIconModule,
    SubscriptionCardComponent,
    TranslateModule,
    CardComponent,
    DatePipe,
    LookDropComponent,
    ReactiveFormsModule
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UserInfoComponent implements OnInit {
  @Input() userProfile: UserProfile;
  @Output() closeSidebar = new EventEmitter<void>();
  @Output() onUpdateProfile = new EventEmitter<UserProfile>();

  items: MenuItem[] = [];
  lookDropInputSetting: LookDropInputSetting;
  showCancelSave: boolean = true;
  selectedLanguage: string;

  readonly CONTENT_STATES = {
    USER_FORM: 'userForm',
    PASSWORD: 'password',
    SWITCH_SUBSCRIPTION: 'switchSubscription',
  };

  @Input() languages: any[];
  @Input() regional: any[];

  activeContent: string = this.CONTENT_STATES.USER_FORM; // Default to user-form

  inputEmail: FormContent = {
    inputText: {
      name: 'email',
      label: 'Email',
      type: InputTypes.text,
      placeholder: 'admin@some-company.com',
      required: true,
      styling: {
        width: '100%',
      },
      editable: false,
      disabled: true
    },
  };

  // inputRole: FormContent = {
  //   inputText: {
  //     name: 'Role',
  //     label: 'User Role',
  //     type: InputTypes.text,
  //     placeholder: 'ADMINISTRATOR',
  //     required: true,
  //     styling: {
  //       width: '100%',
  //     },
  //     editable: false,
  //   },
  // };

  inputFname: FormContent = {
    inputText: {
      name: 'firstName',
      label: 'First name',
      type: InputTypes.text,
      placeholder: 'John',
      required: true,
      styling: {
        width: '100%',
      },
      minLength: 2,
      maxLength: 50,
      textFormat: InputPattern.AlphanumericWithSymbols,
      patternErrorMsg: "First name can't be more than 50 characters.",
      editable: false,
      disabled: true
    },
  };

  inputLname: FormContent = {
    inputText: {
      name: 'lastName',
      label: 'Last name',
      type: InputTypes.text,
      placeholder: 'Doe',
      required: true,
      styling: {
        width: '100%',
      },
      minLength: 2,
      maxLength: 50,
      textFormat: InputPattern.AlphanumericWithSymbols,
      patternErrorMsg: "Last name can't be more than 50 characters.",
      editable: false,
      disabled: true
    },
  };

  dropdownLang: FormContent;

  dropdownRegion: FormContent;

  // inputCurrentPassword: FormContent = {
  //   inputText: {
  //     name: 'Current Password',
  //     label: 'Current Password',
  //     type: InputTypes.password,
  //     required: true,
  //     styling: {
  //       width: '100%',
  //     },
  //   },
  // };

  // inputNewPassword: FormContent = {
  //   inputText: {
  //     name: 'New Password',
  //     label: 'New Password',
  //     type: InputTypes.password,
  //     required: true,
  //     styling: {
  //       width: '100%',
  //     },
  //   },
  // };

  // inputConfirmNewPassword: FormContent = {
  //   inputText: {
  //     name: 'Confirm New Password',
  //     label: 'Confirm New Password',
  //     type: InputTypes.password,
  //     required: true,
  //     styling: {
  //       width: '100%',
  //     },
  //   },
  // };

  formContent;

  userFormGroup: UntypedFormGroup;
  showDropdown = false;
  subscribe$: Subscription;
  hideSwitchSubscription: boolean = false;
  editMode: boolean = false;

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private formService: FormService,
    public translateService: TranslateService,
    public translateCacheService: TranslateCacheService,
    private subscriptionService: SubscriptionService,
    private subsciptionUserService: SubscriptionUserService,
    private router: Router,
    private loaderService: LoaderService,
    private toastService: ToastService,
  ) {
    this.subscribe$ = this.subscriptionService.getSelectedSubscriptionRepo().subscribe(sub => {
      this.hideSwitchSubscription = sub?.hideSwitchSubscription;
    });
  }

  async ngOnInit() {
    this.selectedLanguage = this.userProfile.refLangCode;
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);

    this.dropdownLang = {
      lookDropInput: {
        name: 'langCode',
        label: 'Language',
        displayValueKey: 'name',
        valueKey: 'value',
        dropdownOption: this.languages,
        required: true,
        styling: {
          width: '60%',
        },
        editable: false,
        disabled: true,
        isDropdown: true
      },
    };

    this.dropdownRegion = {
      lookDropInput: {
        name: 'cultureCode',
        label: 'Regional Format',
        displayValueKey: 'name',
        valueKey: 'value',
        dropdownOption: this.regional,
        required: true,
        styling: {
          width: '60%',
        },
        editable: false,
        disabled: true,
        isDropdown: true
      },
    };

    this.formContent = [
      this.inputEmail,
      // this.inputRole,
      this.inputFname,
      this.inputLname,
      this.dropdownLang,
      this.dropdownRegion,
      // this.inputCurrentPassword,
      // this.inputNewPassword,
      // this.inputConfirmNewPassword,
    ];

    this.userFormGroup = this.formService.generateFormGroup(this.formContent);
    this.patchFormWithUserData();
  }

  patchFormWithUserData() {
    if (this.userProfile && this.userFormGroup) {
      this.userFormGroup.patchValue({
        'email': this.userProfile.email,
        'firstName': this.userProfile.firstName,
        'lastName': this.userProfile.lastName,
        'langCode': this.userProfile.langCode,
        'cultureCode': this.userProfile.cultureCode,
      });
      // this.cd.detectChanges();
    }
  }


  handleMyProfileAction() {
    this.activeContent = this.CONTENT_STATES.USER_FORM;
    this.toggleDropdown();
    this.showCancelSave = true;
  }

  handlePasswordAction() {
    this.activeContent = this.CONTENT_STATES.PASSWORD;
    this.toggleDropdown();
    this.showCancelSave = true;
  }

  async handleSwitchSubscriptionAction() {
    this.onCloseButtonClick();
    this.subscriptionService.resetSubscription();
    this.router.navigate(['/subscription']);
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  signOut() {
    this.authService.logout();
    this.cd.detectChanges();
  }

  onClickEdit() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.userFormGroup.enable();
      this.userFormGroup.get('email').disable();
    } else {
      this.userFormGroup.disable();
      this.patchFormWithUserData();
    }
  }

  onClickCancelEdit() {
    this.editMode = false;
    this.userFormGroup.disable();
    this.patchFormWithUserData();
  }

  onCloseButtonClick() {
    this.patchFormWithUserData();
    this.closeSidebar.emit();
  }

  async onSubmit() {
    this.userFormGroup.markAllAsTouched();

    if (this.userFormGroup.valid) {
      this.loaderService.startLoading();
      let result = await this.subsciptionUserService.updateMyProfile(this.userFormGroup.value);
      this.loaderService.stopLoading();

      if (!(result instanceof HttpErrorResponse)) {
        this.userProfile = result;
        this.patchFormWithUserData();
        this.onUpdateProfile.emit(this.userProfile);

        const selectedLanguage = this.userProfile.refLangCode;
        if (selectedLanguage) {
          this.translateService.setDefaultLang(selectedLanguage);
          this.translateService.use(selectedLanguage);
        }

        let toast: ToastData = {
          name: 'updateReportSetting',
          type: ToastType.Success,
          message: 'msg.success_updated'
        };
        this.toastService.show(toast);
      }
      this.editMode = false;
      this.userFormGroup.disable();
    }
  }

  ngOnDestroy() {
    this.subscribe$?.unsubscribe();
  }
}
