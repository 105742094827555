import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PageService } from 'src/app/shared/services/page.service';
import { BackendCurrency } from 'src/swagger/base/openapi';
import { CurrencyService } from '../currency.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormComponent } from '../../../shared/component/form/form.component';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Mode } from 'src/app/shared/enums/mode';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastType } from 'src/app/shared/enums/toastType';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-currency-entry',
  standalone: true,
  templateUrl: './currency-entry.component.html',
  styleUrls: ['./currency-entry.component.scss'],
  imports: [CommonModule, FormComponent]
})
export class CurrencyEntryComponent {
  page: Page;
  formDetail: any = null;
  component: any;
  formCode: FormCode;
  currCode: string;
  formData: FormData;
  isEditMode: boolean;

  formSetting: FormSetting = {
    formCode: FormCode.currencyentry,
    content: [
      {
        inputText: {
          name: 'currCode',
          label: 'Currency Code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          minLength: 0,
          maxLength: 5
        },
      },
      {
        inputText: {
          name: 'currDesc',
          label: 'Description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          minLength: 0,
          maxLength: 254
        },
      },
      {
        inputText: {
          name: 'currPrefix',
          label: 'Currency Prefix',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          minLength: 0,
          maxLength: 10
        },
      },
      {
        inputText: {
          name: 'currSuffix',
          label: 'Currency Suffix',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          minLength: 0,
          maxLength: 10
        },
      },
      {
        inputText: {
          name: 'rateUDF',
          label: 'Currency Rate UDF',
          type: InputTypes.text,
          styling: {
            width: '60%'
          },
          minLength: 0,
          maxLength: 15
        },
      },
      {
        lookDropInput: {
          name: 'currFormat',
          label: 'Currency Format',
          type: InputTypes.text,
          displayValueKey: 'name',
          valueKey: 'value',
          styling: {
            width: '60%'
          },
          dropdownOption: [
            { name: "1,234.56", value: 1 },
            { name: "1.234,56", value: 2 },
            { name: "1,234", value: 3 },
            { name: "1.234", value: 4 }
          ],
          autoComplete: false
        },
      }
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private formService: FormService,
    private pageService: PageService,
    private currencyService: CurrencyService,
    private dialog: MatDialog,
    private toastService: ToastService
  ) { }

  async ngOnInit() {
    this.page = this.pageService.pages[this.pageService.pages.length - 1];
    this.formCode = FormCode.currencyentry;
    this.formDetail = this.formService.getFormDetail(this.formCode);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.currCode = params['currCode'];
      if (this.currCode) {
        this.formData = await this.currencyService.getCurrency(this.formCode, this.currCode);
      }
    }

    // Check edit mode
    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }
  }

  onToolbarAction(resp: ActionToolBarResp) {}

  async onSubmitForm(formGroup: UntypedFormGroup) {
    let result = null;
    let msg = '';
    let reqBody: BackendCurrency = {
      currCode: formGroup.get('currCode').value,
      currDesc: formGroup.get('currDesc').value,
      currPrefix: formGroup.get('currPrefix').value,
      currSuffix: formGroup.get('currSuffix').value,
      rateUDF: formGroup.get('rateUDF').value,
    }

    if(this.isEditMode) {
      result = await this.currencyService.updateCurrency(reqBody, this.formCode);
      msg = 'msg.success_updated';
    } else {
      result = await this.currencyService.addCurrency(reqBody, this.formCode);
      msg = 'msg.success_added';
    }

    if (!(result instanceof HttpErrorResponse)) {
      this.pageService.refresh([FormCode.currencylisting]);

      if(this.page) {
        this.pageService.closePage(this.page);
      }

      let toast: ToastData = {
        type: ToastType.Success,
        message: msg
      };
      this.toastService.show(toast);
    }
  }

  showCurrencyPopup(success: boolean) {
    let popupMsg: PopupMessage = {
      titleIcon: 'assets/base/icons/exclamation-circle.svg',
      title: success ? 'msg.currency_success_title' : 'msg.currency_failed_title',
      desc: success ? 'msg.currency_success_desc' : 'msg.currency_failed_desc',
      actionBtnText: 'general.ok'
    };

    const dialogRef = this.dialog.open(PopupMessageComponent, {
      data: popupMsg
    });

    dialogRef.afterClosed().subscribe(result => {
      this.pageService.closePage(this.page);
    });
  }
}
