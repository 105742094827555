/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackendTaxConditionD { 
    taxCondCode: string;
    taxCondLine?: number;
    taxLineDesc: string;
    effectiveDate?: Date;
    obsoleteDate?: Date;
    taxCode: string;
    inclFlag?: boolean;
    taxMethod?: number;
    taxRate?: number;
    taxUDF: string;
    calcMethod?: number;
    addTaxGroupCode?: string;
    adjFlag?: boolean;
    taxClaimFlag?: boolean;
    minSalesAmt?: number;
    maxSalesAmt?: number;
    minDistanceKM?: number;
    maxDistanceKM?: number;
    inactiveFlag?: boolean;
    filterFlag?: number;
    rowVersion?: string;
}