/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StatementDataResponse { 
    salesTranId?: number;
    statementId?: number;
    status?: string;
    storeName?: string;
    orderNo?: string;
    bizDate?: Date;
    channelDesc?: string;
    orderMode?: string;
    collectionMode?: string;
    refNo?: string;
    grossAmt?: number;
    merchantDiscAmt?: number;
    platformDiscAmt?: number;
    netAmtExc?: number;
    taxAmt?: number;
    chargeAmt?: number;
    otherChargeAmt?: number;
    adjAmt?: number;
    billCollection?: number;
    merchantTaxAmt?: number;
    selfDeliveryAmt?: number;
    merchantAdjAmtPlusAdjAmt?: number;
    commSalesAmt?: number;
    commPerc?: number;
    commAmt?: number;
    commTaxAmt?: number;
    revenueAmt?: number;
    payableAmt?: number;
    collectionAmt?: number;
    odaringRevenue?: number;
    custName?: string;
    email?: string;
    mobileNo?: string;
    checkOrderUrl?: string;
    remarks?: string;
    otherPayment?: number;
}