import { PageComponent } from './../models/pageComponent.model';
import { PageRoute } from "../enums/pageRoute";
import { StatementListingComponent } from "src/app/cxm-backend-web/statement/statement-listing/statement-listing.component";
import { StatementEntryComponent } from 'src/app/cxm-backend-web/statement/statement-entry/statement-entry.component';
import { FormCode } from '../enums/formCode';

export const statementPathComponent: PageComponent[] = [
  {
  filePath: PageRoute.STATEMENT_LISTING,
  component: StatementListingComponent
  },
  {
    filePath: PageRoute.STATEMENT_ENTRY,
    formCode: FormCode.statemententry,
    component: StatementEntryComponent
  },
];
