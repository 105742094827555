<div class="report-viewer-layout" *ngIf="((!isReportMode && reportViewerData) || isReportMode); else error">
  <div class="container-header" style="font-size: 18px; font-weight: bold" *ngIf="reportViewerData && reportViewerData.reportTitle && !isLogin">
    <div class="title ">
      {{ 'report.title' | translate: {reportName: 'report.' + reportViewerData.reportTitle | translate} }}
      <span *ngIf="reportViewerData.reportGeneratedTime"> - {{ 'report.title_date' | translate : {date: (reportViewerData.reportGeneratedTime | date:'mediumDate')} }}</span>
    </div>
  </div>
  <div class="generated" *ngIf="reportViewerData && reportViewerData.reportGeneratedTime">
    <div class="title " [innerHtml]="'report.report_generated_on' | translate: {dateTime: (reportViewerData.reportGeneratedTime |date: 'dd/MM/yyyy, h:mma'), user: '<b>' + reportViewerData.userName + '</b>' }"></div>
  </div>

  <!-- filter & grouping component -->
  <div class="filter-grp-container">
    <app-filter-group-statement #filterGroupState
      [filters]="filters" [reportData]="reportViewerData" [filterItems]="filterItems" [reportViewerFilter]="reportViewerFilter" [report]="isReport"
      [formCode]="formCode" [selectionGroups]="selectionGroups" [selectedGroups]="selectedGroups" [rptJobFilter]="rptJobFilter"
      [editMode]="isReportMode" [defaultFilter]="defaultFilter" [default]="default" [filterGrpData]="filterGrpData" [countBy]="countBy"
      [actionToolbarSetting]="filterGrpActionToolbar"
      (onFilterGroupData)="onSetFilterGrp($event)" (onActionToolbar)="onActionToolbar($event)" (queryStringPass)="onQueryString($event)"
    ></app-filter-group-statement>
  </div>

  <!-- table action toolbar -->
  <app-action-tool-bar [actionToolbarSetting]="tableActionToolbar"
    (onAction)="onActionToolbar($event)" id="toolbar"></app-action-tool-bar>

  <!-- ag grid table -->
  <div class="ag-grid-table-container" *ngIf="rowData">
    <ag-grid-angular
      class="ag-theme-alpine ag-grid-table"
      [defaultColDef]="defaultColDef"
      [columnDefs]="colDefs"
      [context]="context"
      [rowData]="rowData"
      [components]="frameworkComponents"
      [sideBar]="sideBar"
      [statusBar]="statusBar"
      [style]="{'height': 'calc(100vh - 143px)'}"
      [excelStyles]="excelStyles"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [groupIncludeFooter]="true"
      [groupIncludeTotalFooter]="true"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [rowGroupPanelShow]="'onlyWhenGrouping'"
      [suppressDragLeaveHidesColumns]="true"
      [suppressAggFuncInHeader]="true"
      [pagination]="false"
      [paginationAutoPageSize]="true"
      [paginateChildRows]="true"
      [suppressPaginationPanel]="true"
      [suppressGroupRowsSticky]="true"
      [enableCharts]="true"
      [enableRangeSelection]="true"
      [suppressMakeColumnVisibleAfterUnGroup]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      [masterDetail]="detailCellRendererParams? true: false"
      [detailRowAutoHeight]="true"
      [defaultExcelExportParams]="defaultExcelExportParams"
      (gridReady)="onGridReady($event)"
      (columnRowGroupChanged)="onColRowGrpChged()"
      (stateUpdated)="onGridStateUpdated($event)"
    ></ag-grid-angular>
  </div>

  <!-- empty layout -->
  <div class="submitted-view" *ngIf="!reportViewerData && !rowData && this.generateRptResp; else emptyView">
    <div class="wrapper">
      <h1>{{ "report.rpt_submitted" | translate: {
        date: (generateRptResp.submissionDateTime + 'Z' | date: 'dd/MM/yy': '' : 'en-US'),
        time: (generateRptResp.submissionDateTime + 'Z' | date: 'HH:mm': '' : 'en-US') } }}</h1>
      <div class="code-wrapper">
        <div class="content" style="visibility: hidden;">
          <p>{{ "report.submission_code" | translate }}</p>
          <p class="code">{{ generateRptResp.submissionCode }}</p>
          <ng-lottie [options]="iconOptions" class="icon" width="100px" />
          <p class="status" *ngIf="generateRptStatus">{{ generateRptStatus | translate }}</p>
        </div>
        <div class="loader" #loader [ngStyle]="{'transition-duration': transitionDuration}">
          <div class="content">
            <p>{{ "report.submission_code" | translate }}</p>
            <p class="code">{{ generateRptResp.submissionCode }}</p>
            <ng-lottie [options]="iconOptions" class="icon" width="100px" />
            <p class="status" *ngIf="generateRptStatus">{{ generateRptStatus | translate }}</p>
          </div>
        </div>
      </div>
      <div class="cancel-wrapper">
        <p>{{ "report.cancel_rpt" | translate }}</p>
        <img src="assets/base/icons/remove-circle.svg" (click)="stopGenerateRpt()" />
      </div>
      <!-- <h2>{{ "report.notify_when_ready" | translate }}</h2> -->
      <h2 *ngIf="activeRptNo">{{ "report.report_submitted" | translate: { reportNo: activeRptNo } }}</h2>
      <!-- <p>{{ "report.can_generate" | translate }} <span (click)="onClickRptFilterGrp()">{{ "report.new_rpt" | translate }}</span> {{ "report.return_to" | translate }} <span (click)="onClickHome()">{{ "report.home" | translate }}</span></p> -->
      <p>{{ "report.view_report" | translate }} <span (click)="onClickOpenJobQueue()">{{ "report.prefiltered_job" | translate }}</span></p>
    </div>
  </div>
  <ng-template #emptyView>
    <div class="center-error-view" *ngIf="!reportViewerData && !rowData && !this.generateRptResp">
      <h1>{{ (filters && filters.length > 0? "report.no_table_yet": "report.nothing_here") | translate }}</h1>
      <img src="assets/base/images/no-table.svg" />
      <h2>{{ (filters && filters.length > 0? "report.time_to": "report.try") | translate }}
        <span (click)="onGenerateReport()" *ngIf="filters && filters.length > 0">{{ "report.generate" | translate }}</span>
        <span (click)="onClickRptFilterGrp()" *ngIf="!filters || filters.length === 0">{{ "report.filter" | translate }}</span>
      </h2>
      <p>{{ "report.return_to" | translate }} <span (click)="onClickHome()">{{ "report.home" | translate }}</span></p>
    </div>
  </ng-template>
</div>

<ng-template #error>

</ng-template>
