import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Page } from 'src/app/shared/models/page.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { Subscription } from 'rxjs';
import { ColDef, IRowNode } from 'ag-grid-community';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { TranslateService } from '@ngx-translate/core';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { PopupForm } from 'src/app/shared/models/popupForm.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { StoreService } from '../../store.service';
import { StoreAttrEntryComponent } from '../store-attr-entry/store-attr-entry.component';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowSelection } from 'src/app/shared/enums/rowSelection';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { Mode } from 'src/app/shared/enums/mode';
import { FormService } from 'src/app/shared/component/form/form.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';

@Component({
  selector: 'app-store-attr-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './store-attr-listing.component.html',
  styleUrls: ['./store-attr-listing.component.scss']
})
export class StoreAttrListingComponent {
  page: Page;
  rowData: any;
  storeId: number;
  selectedRowData: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  refresh$: Subscription;
  popupMsg$: Subscription;
  actionBtn$: Subscription;

  gridApi: any;
  formDetail: any = null;
  // Column data
  colDef: ColDef[] = [
    listingCheckbox,
    {
      ...listingActionButton,
      cellRendererParams: {
        type: ButtonRendererTypeEnum.duplicate
      },
    },
    {
      ...listingRouterLink,
      field: 'storeId',
      headerName: 'Store Id',
      cellRendererParams: {
        inRouterLink: {
          route: '/store/storeattr/entry',
          params: ['storeId', 'attrCode'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      }
    },
    {
      field: 'attrCode',
      headerName: 'Attribute Code',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'attrData',
      headerName: 'Attribute Data',
      filter: true,
      initialFlex: 1
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      ...toolbarListing,
      { name: 'new', icon: 'assets/base/icons/plus.svg', text: 'toolbar.new_store_attr', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
    ],
    position: ActionToolbarPosition.RIGHT
  }

  listingSetting: ListingSetting = {
    rowSelection: RowSelection.single
  }

  constructor(
    public translate: TranslateService,
    private popupFormService: PopupFormService,
    private pageService: PageService,
    private storeService: StoreService,
    private formService: FormService,
  ) { }

  ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    let params = this.pageService.getParameterDecode(this.page);
    this.storeId = params['storeId'];
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    this.getStoreAttr();

    // Listen to refresh event
    this.refresh$ = this.pageService.refreshListing$.subscribe((formCode: any) => {
      if (formCode.includes(this.page.form.formCode)) {
        this.getStoreAttr();
      }
    });

    // Listen to popup message event
    this.popupMsg$ = this.pageService.actionPopupMsg$.subscribe((data: any) => {
      if (data.formCode === this.page.form.formCode && data.id === 'success') {
        this.getStoreAttr();
      }
    });

    // Listen to Action Button event
    this.actionBtn$ = this.pageService.actionBtnListing$.subscribe((data: any) => {
      if (data.param.page.formCode === this.page.form.formCode && data.action === ButtonRendererTypeEnum.save) {
        // save action
      }
    });
  }

  async getStoreAttr() {
    this.rowData = (await this.storeService.getOdataStoreAttr(this.storeId, null, this.formDetail.formCode))['value'];
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByUrl(PageRoute.STORE_ATTR_ENTRY, {
        storeId: this.storeId,
      }, null, true);
    }

    if (value.name === 'delete' && this.selectedRowData && this.selectedRowData.data['attrCode']) {
      let result = null;
      result = await this.storeService.deleteStoreAttr(this.storeId, this.selectedRowData.data['attrCode'], this.formDetail.formCode);

      if (result instanceof HttpErrorResponse === false) {
        this.getStoreAttr();
        this.selectedRowData.setSelected(false); //remove selected node
      }
    }
  }

  onRowSelect(nodes: IRowNode[]) {
    // single selection so select only first data
    this.selectedRowData = nodes[0]? nodes[0]: null;
  }

  onPageSizeChange(pageSize: number) { }

  ngOnDestroy(): void {
    // Destroy event listener when component close
    this.refresh$?.unsubscribe();
    this.popupMsg$?.unsubscribe();
    this.actionBtn$?.unsubscribe();
  }
}
