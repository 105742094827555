import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ActivityLogService } from './api/activityLog.service';
import { AliChatAppWebHookService } from './api/aliChatAppWebHook.service';
import { AppNotificationService } from './api/appNotification.service';
import { BankService } from './api/bank.service';
import { CatgAttrService } from './api/catgAttr.service';
import { CatgAttrOptionService } from './api/catgAttrOption.service';
import { ChannelService } from './api/channel.service';
import { ChannelAddressService } from './api/channelAddress.service';
import { ChannelCatgAttrService } from './api/channelCatgAttr.service';
import { ChannelPlatformSetService } from './api/channelPlatformSet.service';
import { ChannelSectionService } from './api/channelSection.service';
import { ChannelSectionListService } from './api/channelSectionList.service';
import { ChannelStoreService } from './api/channelStore.service';
import { ChannelStoreTaxService } from './api/channelStoreTax.service';
import { ContinentService } from './api/continent.service';
import { CountryService } from './api/country.service';
import { CtryAreaService } from './api/ctryArea.service';
import { CtryRegionService } from './api/ctryRegion.service';
import { CtryStateService } from './api/ctryState.service';
import { CurrencyService } from './api/currency.service';
import { EmailService } from './api/email.service';
import { FileService } from './api/file.service';
import { FileListService } from './api/fileList.service';
import { GeneralMasterDataService } from './api/generalMasterData.service';
import { InvoiceService } from './api/invoice.service';
import { MerchantService } from './api/merchant.service';
import { MessageService } from './api/message.service';
import { MetadataService } from './api/metadata.service';
import { OrderDetailService } from './api/orderDetail.service';
import { PDFService } from './api/pDF.service';
import { PayoutService } from './api/payout.service';
import { StatementService } from './api/statement.service';
import { StoreService } from './api/store.service';
import { StoreAttrService } from './api/storeAttr.service';
import { StoreCatgAttrService } from './api/storeCatgAttr.service';
import { StoreOHService } from './api/storeOH.service';
import { SysPendingJobService } from './api/sysPendingJob.service';
import { SystemService } from './api/system.service';
import { TaxConditionDService } from './api/taxConditionD.service';
import { TaxConditionFService } from './api/taxConditionF.service';
import { TaxConditionHService } from './api/taxConditionH.service';
import { XplViewService } from './api/xplView.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ActivityLogService,
    AliChatAppWebHookService,
    AppNotificationService,
    BankService,
    CatgAttrService,
    CatgAttrOptionService,
    ChannelService,
    ChannelAddressService,
    ChannelCatgAttrService,
    ChannelPlatformSetService,
    ChannelSectionService,
    ChannelSectionListService,
    ChannelStoreService,
    ChannelStoreTaxService,
    ContinentService,
    CountryService,
    CtryAreaService,
    CtryRegionService,
    CtryStateService,
    CurrencyService,
    EmailService,
    FileService,
    FileListService,
    GeneralMasterDataService,
    InvoiceService,
    MerchantService,
    MessageService,
    MetadataService,
    OrderDetailService,
    PDFService,
    PayoutService,
    StatementService,
    StoreService,
    StoreAttrService,
    StoreCatgAttrService,
    StoreOHService,
    SysPendingJobService,
    SystemService,
    TaxConditionDService,
    TaxConditionFService,
    TaxConditionHService,
    XplViewService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
