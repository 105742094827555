<div class="param-rule">
  <ng-container>
    <button class="param-drop" [style.position]="'relative'"
      [ngClass]="{'selected': newSelectedGrpIndex === selectedIndexI }">
      <div class="params" [ngClass]="{'disabled': !enableEdit}">
        <div style="display: flex; gap: 10px; align-items: center;">
          <button class="chips-param" [disabled]="!enableEdit" [ngClass]="{'disabled': !enableEdit}">
            <div class="param-text">
              {{ ('general.'+ selectedId.toLowerCase()) | translate }}
            </div>
          </button>
          <div class="chips-op">
            <button class="op" *ngIf="selectedParam|| selectParam" [disabled]="!enableEdit"
              [ngClass]="{'disabled': !enableEdit}"
              (click)="toggleOpDropdown(selectedParam, selectedIndexI, selectedIndexJ); handleClick($event)">
              <div class="dropdown-operator">
                <span>{{ ('general.' + selectedOperator | translate | capitalize) }}</span>
                <span><img class="param-remove" src="assets/base/icons/chevron-down.svg"></span>
              </div>
            </button>
            <ul class="chips-op-options" *ngIf="openOpDrop">
              <li style="display: flex; justify-content: space-between;" *ngFor="let operator of availableOperators"
                (click)="onOperatorSelected(operator); handleClick($event)">
                <span class="op-options">
                  {{ 'general.' + operator | translate}}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div style="display: flex;" [style.align-items]="ruleInputs === 'lookup' ? 'center' : 'normal'">
          <div class="chips-value" *ngIf="!opNull"
            [ngClass]="{'checkboxBorder' : ruleInputs === 'checkbox',
          'lookupBorder': ruleInputs === 'lookup', 'betweenDate' : selectedOperator === 'between' && ruleInputs === 'formuladatepicker' && userInputValue, 'mobileCheck' : ruleInputs === 'checkbox'}">
            <ng-container [ngSwitch]="ruleInputs">
              <ng-container *ngSwitchCase="'text'">
                <button *ngIf="inputType === 'string'" class="valueButton" [disabled]="!enableEdit"
                  [ngClass]="{'disabled': !enableEdit}">
                  <div class="popup-value">
                    <input type="text" class="quantity-input-single" [(ngModel)]="userInputValue"
                      (click)="handleClick($event)"
                      (ngModelChange)="onValueChange(userInputValue, ruleInputs, selectedIndexI, selectedIndexJ)">
                  </div>
                </button>

                <button *ngIf="inputType === 'integer' && selectedOperator !== 'between'" class="valueButton"
                  [disabled]="!enableEdit" [ngClass]="{'disabled': !enableEdit}">
                  <div class="popup-value">
                    <input type="text" class="quantity-input-single" [(ngModel)]="numericValue"
                      (click)="handleClick($event)"
                      (ngModelChange)="updateInteger(numericValue, selectedOperator, ruleInputs, selectedIndexI, selectedIndexJ, 'none')"
                      step="1" min="0" placeholder="0" (input)="validateInteger($event)">
                  </div>
                </button>

                <button *ngIf="inputType === 'integer' && selectedOperator === 'between'" class="valueButton"
                  [disabled]="!enableEdit" [ngClass]="{'disabled': !enableEdit}">
                  <div class="popup-value">
                    <div class="popup-value">
                      <input type="number" class="quantity-input-single" [(ngModel)]="numValues[0]"
                        (click)="handleClick($event)"
                        (ngModelChange)="updateInteger(numValues[0], selectedOperator, ruleInputs, selectedIndexI, selectedIndexJ,  'first')"
                        step="1" min="0" placeholder="0">
                    </div>
                  </div>
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="'select'">
                <button class="valueButton" (click)="toggleDropdown(); handleClick($event)" [disabled]="!enableEdit"
                  [ngClass]="{'disabled': !enableEdit}">
                  <span class="dropdownValue">{{ userInputValue | translate }}</span>
                  <span><img class="param-remove" src="assets/base/icons/chevron-down.svg"></span>
                </button>
                <ul class="select-options" *ngIf="openValueDrop">
                  <li style="display: flex; justify-content: space-between;" *ngFor="let value of itemValue"
                    (click)="onValueSelect(value); handleClick($event)">
                    <span class="op-options">{{ value | translate }}</span>
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngSwitchCase="'formuladatepicker'">
                <button class="valueButton" [disabled]="!enableEdit" [ngClass]="{'disabled': !enableEdit}"
                  (click)="edit(userInputValue, ruleInputs, selectedOperator); handleClick($event)">
                  <span class="param-text">
                    {{ extractValueText(userInputValue)}}
                  </span>
                  <span><img class="param-remove" src="assets/base/icons/calendar.svg"></span>
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="'radio'">
                <div class="custom-radio" *ngFor="let option of showBoolean()">
                  <input type="radio" [value]="option" [(ngModel)]="userInputValue" (click)="handleClick($event)"
                    (change)="onValueChange(userInputValue, ruleInputs, selectedIndexI, selectedIndexJ)"
                    [name]="'filterBoolean_' + selectedIndexI + selectedIndexJ" [disabled]="!enableEdit">
                  <label>{{ option | translate }}</label>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'lookup'">
                <button class="valueButton">
                  <app-lookdrop [lookDropInputSetting]="lookDropInput" [defaultLookup]="defaultLookup"
                    [formGroup]="formGrp" [formData]="form" (passData)="handlePassData($event)"></app-lookdrop>
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <div class="checkbox-group">
                  <div class="custom-checkbox" *ngFor="let option of getCheckboxOptions(); let i = index">
                    <input type="checkbox" [value]="translateOption(option)" [checked]="isChecked(option)"
                      (change)="updateCheckbox(selectedParam, option, $event, ruleInputs, selectedIndexI, selectedIndexJ)"
                      [attr.disabled]="!enableEdit ? true : null"
                      id="checkbox-option-{{selectedIndexI}}-{{selectedIndexJ}}-{{i}}">
                    <label for="checkbox-option-{{selectedIndexI}}-{{selectedIndexJ}}-{{i}}">{{ option|translate
                      }}</label>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="shouldApplyBorderColor()" style="margin: 0; padding: 0;">
            <span style="display: flex; padding: 7px 0 0 3px;">
              <img style="width: 16px;" src="assets/base/icons/exclamation-circle-red.svg">
            </span>
          </div>
        </div>

        <ng-container *ngIf="selectedOperator === 'between' && ruleInputs ==='text'">
          <span><img style="width: 12px; margin: 10px;" src="assets/base/icons/minus.svg"></span>
          <div class="chips-value">
            <button class="valueButton" [disabled]="!enableEdit" [ngClass]="{'disabled': !enableEdit}">
              <div class="popup-value">
                <input type="number" class="quantity-input-single" [(ngModel)]="numValues[1]" step="1"
                  (ngModelChange)="updateInteger(numValues[1], selectedOperator, ruleInputs, selectedIndexI, selectedIndexJ,  'second')"
                  min="{{numValues[0] + 1}}" placeholder="0">
              </div>
            </button>
          </div>
        </ng-container>

        <div *ngIf="(ruleInputs === 'formuladatepicker' || (ruleInputs === 'lookup' && !mult)) && (selectedOperator !== 'isnotnull' && selectedOperator !== 'isnull')" style="padding: 5px; margin-left: 10px;">
          <span *ngIf="ruleInputs === 'formuladatepicker'"> {{ dateValue }} </span>
          <span *ngIf="ruleInputs === 'lookup'"> {{ lookupDesc }} </span>
        </div>
      </div>

      <div class="param-action">
        <div class="param-setting">
          <button class="trash" [ngClass]="{'trash-disabled': !enableEdit}" [disabled]="!enableEdit"
            (click)="removeParam(selectedIndexI, selectedIndexJ)">
            <img class="param-trash" src="assets/base/icons/trash.svg"></button>
        </div>
      </div>
    </button>
  </ng-container>
</div>

<div class="popup" *ngIf="popup not-blur">
  <div class="popContent">
    <div class="popupHead">
      <div class="popupTitle">
        <p *ngIf="ruleInputs === 'formuladatepicker'">{{"msg.filter_date_picker" | translate}}</p>
      </div>
      <div style="cursor: pointer;">
        <img class="closePopup" src="assets/base/icons/remove.svg" (click)="closePopup()">
      </div>
    </div>
    <div class="popupContent">
      <div class="dateInput">
        <app-calendar [isDatePickerMode]="isDatePickerMode" [editValue]="editValue" [formulaBuild]="formulaBuild"
          [dateSelection]="dateSelection.dateSelection" [isBetween]="isBetween" [matching]="matching"
          [dateValue]="dateValue" [isDateBoolean]="isDateBoolean" [editDateValue]="editDateValue"
          (newDate)="handleNewDate($event)" (dateModeChange)="dateModeChange($event)"
          (dateFormula)="dateFormula($event)" (modifyDate)="handleModifyDate($event)"></app-calendar>
      </div>
    </div>
    <div class="errorMessage" *ngIf="error && isDatePickerMode">
      <span>{{ errorMessage | translate }}</span>
    </div>
    <div class="popupButton">
      <div class="buttons">
        <button (click)="closePopup()">{{"general.cancel" | translate}}</button>
        <button
          (click)="submitValue(selectedParam, ruleInputs, selectedOperator, userInputValue, selectedIndexI, selectedIndexJ)"
          style="font-weight: 800;">{{"general.done" | translate}}</button>
      </div>
    </div>
  </div>
</div>
