import { Component, Input, OnInit, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SysFormSetService } from 'src/app/shared/services/sys-form-set.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { CardListingComponent } from '../../card-listing/card-listing.component';
import { ReportSettingCardComponent } from '../../card/report-setting-card/report-setting-card.component';
import { SysFormSetListResponse } from 'src/swagger/base-rms/openapi';
import { ReportService } from 'src/app/shared/services/report.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-report-setting-listing',
  standalone: true,
  imports: [CommonModule, CardListingComponent],
  templateUrl: './report-setting-listing.component.html',
  styleUrls: ['./report-setting-listing.component.scss']
})
export class ReportSettingListingComponent implements OnInit {
  @Input() popupFormData: any;

  reportSettingList: SysFormSetListResponse[];
  cardComponentName: Type<any>
  selectedIndex: number[];

  constructor(private sysFormSetService: SysFormSetService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private reportService: ReportService) {}

  async ngOnInit() {
    // call API
    this.loaderService.startLoading();
    let result = await this.sysFormSetService.getReportSettingList(this.popupFormData?.formCode);
    this.loaderService.stopLoading();
    this.cardComponentName = ReportSettingCardComponent;

    if(!(result instanceof HttpErrorResponse)) {
      this.reportSettingList = result;
    }

    // handling selected index
    if(this.reportSettingList && this.popupFormData?.setId) {
      let index = this.reportSettingList.findIndex(data => data?.setId === this.popupFormData.setId);

      if(index >= 0) {
        this.selectedIndex = [index];
      }
    }
  }

  onClickCard(data: SysFormSetListResponse) {
    this.popupFormService.closeById('reportSettingList');
    this.reportService.getRptSetting$.next(data?.setId);
    this.reportService.resetRptSetting$.next();
  }
}
