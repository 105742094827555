import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColDef, GetRowIdFunc } from 'ag-grid-community';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { LocalMerchantService } from '../localMerchant.service';
import { MerchantStatus } from 'src/swagger/cxmbackend/openapi/model/merchantStatus';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { Mode } from 'src/app/shared/enums/mode';
import { HttpErrorResponse } from '@angular/common/http';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Page } from 'src/app/shared/models/page.model';

@Component({
  selector: 'app-merchant-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './merchant-listing.component.html',
  styleUrls: ['./merchant-listing.component.scss'],
})
export class MerchantListingComponent {
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;

  locDesc: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;
  merchantStatuses: any = MerchantStatus;

  // Column data
  colDef: ColDef[] = [];
  listingSetting: ListingSetting;
  rowData: any;
  formDetail: any = null;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'general.merchant.filter', input: true, clickable: false, sortNo: 1, position: ActionToolbarPosition.LEFT },
      { name: 'new', icon: 'assets/base/icons/plus.svg', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
      { name: 'edit', icon: 'assets/base/icons/pencil.svg', text: 'toolbar.edit', checkPermission: [AttributeCode.EDIT], collapseTo: 'others', sortNo: 3  },
    ],
    position: ActionToolbarPosition.RIGHT,
  };
  page: Page;

  constructor(
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private merchantService: LocalMerchantService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService
  ) {}

  ngOnInit() {
    // Column data
    this.colDef = [
      {
        ...listingRouterLink,
        field: 'merchantId',
        headerName: 'Merchant ID',
        cellRendererParams: {
          inRouterLink: {
            route: '/merchant/master/entry',
            params: ['merchantId'],
            queryParams: { mode: Mode.edit },
            childPage: true
          },
        },
        sortable: true,
      },
      {
        ...listingRouterLink,
        field: 'merchantCode',
        headerName: 'Merchant Code',
        cellRendererParams: {
          inRouterLink: {
            route: '/merchant/master/entry',
            params: ['merchantId'],
            queryParams: { mode: Mode.edit },
          },
        },
        sortable: true,
      },
      {
        field: 'merchantDesc',
        headerName: 'Description',
        filter: true,
      },
      {
        field: 'phoneNo',
        headerName: 'Phone No',
        filter: true,
      },
      {
        field: 'email',
        headerName: 'Email',
        filter: true,
      },
      {
        ...listingDropdown,
        field: 'statusFlag',
        headerName: 'Status',
        cellEditorParams: {
          options: Object.keys(this.merchantStatuses).map(
            (key) => this.merchantStatuses[key]
          ),
        },
        initialFlex: 1,
      },
    ];
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    this.getMerchantData();
  }

  async getMerchantData() {
    let result = await this.merchantService.getOdataMerchant(null, null, this.formDetail.formCode);
    this.rowData = result.value && result.value.length > 0? result.value: [];
    // this.rowData = this.merchantService.getOdataMerchantProvider();
  }

  closePopup() {
    this.popupFormService.close(this.formCode.merchantlisting);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.MERCHANT_MASTER_ENTRY, null, null, true);
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
      let result = await this.merchantService.getOdataMerchant(null, filterString, this.formDetail.formCode);
      if (!(result instanceof HttpErrorResponse)) {
        this.rowData = result['value'];
      }
    }
  }

  onPageSizeChange(pageSize: number) {}
}
