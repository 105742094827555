/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StkReqMResponse { 
    itemDesc?: string;
    colorCode?: string;
    colorDesc?: string;
    sizeCode?: string;
    sizeDesc?: string;
    sizeGrpCode?: string;
    reqDocNo?: string;
    itemCode?: string;
    reqDocLine?: number;
    docQty?: number;
    approveQty?: number;
    allocQty?: number;
    pickQty?: number;
    shipQty?: number;
    rcvQty?: number;
    ucmtDscpQty?: number;
    lastUserId?: string;
    rowVersion?: string;
}