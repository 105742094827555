import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  AnimationLoader,
  AnimationOptions,
  LottieComponent,
  provideLottieOptions,
} from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule, LottieComponent, NgStyle],
})
export class LoaderComponent implements OnInit {
  loadingSub: Subscription;
  showLoadingPage: boolean = false;
  loadingMsg: string;
  title: string;
  options: AnimationOptions = {
    path: '/assets/base/animations/loading-animation.json',
  };
  loadingType: number = 0;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadingSub = this.loaderService.loading$
      .pipe()
      .subscribe(
        (value: {
          show: boolean;
          msg: string;
          title: string;
          loadingType: number;
        }) => {
          this.showLoadingPage = value.show ? true : false;
          this.loadingMsg = value.msg ? value.msg : '';
          this.title = value.title ? value.title : '';
          this.loadingType = value.loadingType ? value.loadingType : 0;
        }
      );
  }

  close() {
    this.loaderService.stopLoading();
  }

  ngOnDestroy() {
    this.loadingSub?.unsubscribe();
  }
}
