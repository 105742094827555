import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { UserProfile } from 'src/swagger/base-rms/openapi/model/models';

export interface UserProps {
  user: UserProfile;
}

const userStore = createStore(
  { name: 'user' }, withProps<UserProps>({ user: null })
);

// userStore.subscribe((state) => {
//   console.log('userRepoState: ', state);
// });

@Injectable({ providedIn: 'root' })
export class UserRepository {
  user$ = userStore.pipe(select((state) => state.user));

  updateUser(user: UserProfile) {
    userStore.update((state) => ({
      ...state,
      user: user,
    }));
  }

  resetUser() {
    userStore.reset();
  }
}
