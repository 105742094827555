import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColDef, GetRowIdFunc, GridOptions } from 'ag-grid-community';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { listingDropdown } from 'src/app/shared/component/listing/data/listing-dropdown.data';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { Mode } from 'src/app/shared/enums/mode';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { HttpErrorResponse } from '@angular/common/http';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { AgGridModule } from 'ag-grid-angular';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { CustomService } from 'src/app/core/services/custom.service';
import { AgGridService } from 'src/app/shared/services/ag-grid.service';
import { Subscription } from 'rxjs';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { StoreService as StoreAPIService} from 'src/swagger/base/openapi/api/api';
import { StoreService } from '../store.service';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Page } from 'src/app/shared/models/page.model';

@Component({
  selector: 'app-store-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent, AgGridModule],
  templateUrl: './store-listing.component.html',
  styleUrls: ['./store-listing.component.scss'],
})
export class StoreListingComponent implements OnDestroy {
  listingSetting: ListingSetting;
  rowData: any;
  getRowId: GetRowIdFunc;

  locDesc: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  actionBtnList$: Subscription;
  refresh$: Subscription;
  gridOptions: GridOptions;
  // Column data
  colDef: ColDef[] = [];
  formDetail: any = null;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'store.filter', input: true, clickable: false, sortNo: 1, position: ActionToolbarPosition.LEFT },
      { name: 'new', icon: 'assets/base/icons/plus.svg', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
      { name: 'edit', icon: 'assets/base/icons/pencil.svg', text: 'toolbar.edit', checkPermission: [AttributeCode.EDIT], collapseTo: 'others', sortNo: 3  },
    ],
    position: ActionToolbarPosition.RIGHT,
  };
  page: Page;

  constructor(
    private pageService: PageService,
    private popupFormService: PopupFormService,
    private storeService: StoreService,
    private customService: CustomService,
    private storeApiService: StoreAPIService,
    private agGridService: AgGridService,
    private lookupMasterService: LookupMasterService,
    private formService: FormService
  ) {}

  ngOnInit() {
    // Column data
    this.colDef = [
    {
      ...listingRouterLink,
      field: 'storeId',
      headerName: 'Store ID',
      cellRendererParams: {
        inRouterLink: {
          route: '/store/master/entry',
          params: ['storeId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      },
      filter: 'agTextColumnFilter',
    },
    {
      ...listingRouterLink,
      field: 'locCode',
      headerName: 'Store Code',
      cellRendererParams: {
        inRouterLink: {
          route: '/store/master/entry',
          params: ['storeId'],
          queryParams: { mode: Mode.edit },
        },
      },
      filter: 'agTextColumnFilter',
    },
    {
      field: 'locDesc',
      headerName: 'Description',
      filter: 'agTextColumnFilter',
    },
    {
      ...listingDropdown,
      field: 'currCode',
      headerName: 'Price Code',
      cellEditorParams: {
        options: [
          'MYR',
          'SGD',
          'Baht',
          'Won',
          'TW',
          'MYR',
          'SGD',
          'Baht',
          'Won',
          'TW',
          'MYR',
          'SGD',
          'Baht',
          'Won',
          'TW',
          'MYR',
          'SGD',
          'Baht',
          'Won',
          'TW',
        ],
      },
    },
      {
        field: 'countryCode',
        headerName: 'Country Code',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['MY', 'SG'],
        },
        initialFlex: 1,
      },
      {
        field: 'dealerName',
        headerName: 'Dealer Name',
        filter: 'agTextColumnFilter',
      },
    ];

    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.getStoreData();

    this.actionBtnList$ = this.pageService.actionBtnListing$.subscribe(
      (value: any) => {
        if (value.action && value.action === ButtonRendererTypeEnum.save) {
          delete value.param.data.rowVersion;
          this.updateStore(value);
        }
      }
    );

    // Listen to refresh event
    this.refresh$ = this.pageService.refreshListing$.subscribe(
      (formCode: any) => {
        if (formCode.includes(FormCode.storelisting)) {
          this.getStoreData();
        }
      }
    );
  }

  async getStoreData(filter?: ODataRawQueryOptions) {
    // infinite row model
    // this.rowData = await this.storeService.getOdataStoreDS(100);

    // server side row model
    //this.rowData = this.storeService.getOdataStoreProvider();

    // client side row model
     let result = await this.storeService.getOdataStore(null, filter, this.formDetail.formCode);
     this.rowData = result.value && result.value.length > 0? result.value: [];
  }

  updateStore(actionBtnVal: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.store.UpdateStore,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: actionBtnVal.param.data,
      httpHeaderType: undefined,
    };

    let hMac = this.customService.assignHMac(cusReq);

    this.storeApiService
      .apiStoreUpdatestorePut(
        actionBtnVal.param.data,
        environment.apiVersion,
        environment.subscription,
        hMac,
        'en'
      )
      .subscribe(
        (data) => {
          this.agGridService.setSuccessSaveMode(actionBtnVal);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  closePopup() {
    this.popupFormService.close(this.formCode.storelisting);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.STORE_MASTER_ENTRY, null, null, true);
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
     await this.getStoreData(filterString);
    }
  }

  onPageSizeChange(pageSize: number) {}

  ngOnDestroy(): void {
    this.actionBtnList$?.unsubscribe();
    this.refresh$?.unsubscribe();
  }
}
