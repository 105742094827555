<!-- <div class="mat-card-container"> -->
  <mat-card appearance="outlined" class="card-layout" (click)="selectSub()">
    <mat-card-content class="mat-card" *ngIf="subscription">
      <div class="content-container"
        [ngClass]="{'expired-disabled' : subscription.status === subscriptionStatus.Expired || subscription.status === subscriptionStatus.Terminated}">
        <div class="content-top">
          <div class="content-title">
            {{subscription.billingName}}
          </div>
          <div class="content-description">{{subscription.subscriptionId}} - {{subscription.subscriptionName}}</div>
        </div>

        <div class="content-datetime">
          <label>{{'msg.expires_on' | translate}}</label>
          {{subscription.expiryDate | date: 'short' }}
        </div>
      </div>

      <div class="content-footer">
        <div class="footerText">
          <label class="active-status" *ngIf="subscription.status === subscriptionStatus.Active">
            {{'msg.active' | translate | uppercase}}
          </label>
          <label class="expired-status"*ngIf="subscription.status === subscriptionStatus.Expired || subscription.status === subscriptionStatus.Terminated">
            {{'msg.expired' | translate | uppercase}}
          </label>
          <label class="suspended-status" *ngIf="subscription.status === subscriptionStatus.Suspended">
            {{'msg.suspended' | translate | uppercase}}
          </label>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
<!-- </div> -->
