<div class="input-text-layout" [formGroup]="formGroup">
  <!-- input text, email -->
  <mat-form-field [style]="{ 'width': inputTextSetting.styling?.width }"
    *ngIf="inputTextSetting.type === INPUTTYPES.text || inputTextSetting.type === INPUTTYPES.email || inputTextSetting.type === INPUTTYPES.url">
    <mat-label class="mat-label">{{ inputTextSetting.label | translate }}</mat-label>

    <input matInput placeholder="{{ inputTextSetting.placeholder }}"
      required="{{ inputTextSetting.required? inputTextSetting.required: false }}"
      formControlName="{{ inputTextSetting.name? inputTextSetting.name: '' }}"
      id="{{ inputTextSetting.id? inputTextSetting.id: '' }}"
      [maxlength]="inputTextSetting.maxLength ? inputTextSetting.maxLength : 255"
      [attr.disabled]="disabled ? '' : null">

    <mat-icon-button *ngIf="formGroup.controls[inputTextSetting.name].value !== ''" matSuffix mat-icon-button
      aria-label="Clear">
      <mat-icon
        *ngIf="formGroup.controls[inputTextSetting.name].status === 'INVALID' || formGroup.controls[inputTextSetting.name]?.errors?.validatePhoneNumber"
        class="indicator-icon invalid-icon">error_outline</mat-icon>
      <mat-icon *ngIf="formGroup.controls[inputTextSetting.name].status === 'VALID'"
        class="indicator-icon valid-icon">check_circle_outline</mat-icon>
    </mat-icon-button>
    <mat-error *ngIf="formGrpCtrl?.errors?.required">{{inputTextSetting.label | translate}}
      {{'msg.is_required' | translate}}
    </mat-error>
    <mat-error *ngIf="inputTextSetting.type === INPUTTYPES.email && formGrpCtrl?.errors?.pattern">
      {{'msg.please_enter_valid_email_address' | translate}}
    </mat-error>
    <mat-error *ngIf="inputTextSetting.type === INPUTTYPES.text && formGrpCtrl?.errors?.pattern">
      {{(inputTextSetting ? inputTextSetting.textForamtMsg : 'msg.invalid_text_format') | translate: {format: 'msg.format_AlphanumericWithSymbols' | translate} }}
    </mat-error>
  </mat-form-field>

  <!-- input password -->
  <mat-form-field [style]="{'width' : inputTextSetting.styling?.width }"
    *ngIf="inputTextSetting.type === INPUTTYPES.password">
    <mat-label class="mat-label">{{ inputTextSetting.label | translate }} </mat-label>

    <input type="password" matInput placeholder="{{ inputTextSetting.placeholder }}"
      required="{{ inputTextSetting.required? inputTextSetting.required: false }}"
      formControlName="{{ inputTextSetting.name? inputTextSetting.name: '' }}"
      id="{{ inputTextSetting.id? inputTextSetting.id: '' }}"
      [maxlength]="inputTextSetting.maxLength ? inputTextSetting.maxLength : 255">

    <mat-icon-button *ngIf="formGroup.controls[inputTextSetting.name].value !== ''" matSuffix mat-icon-button
      aria-label="Clear">
      <mat-icon
        *ngIf="formGroup.controls[inputTextSetting.name].status === 'INVALID' || formGroup.controls[inputTextSetting.name]?.errors?.validatePhoneNumber"
        class="indicator-icon invalid-icon">error_outline</mat-icon>
      <mat-icon *ngIf="formGroup.controls[inputTextSetting.name].status === 'VALID'"
        class="indicator-icon valid-icon">check_circle_outline</mat-icon>
    </mat-icon-button>

    <mat-error *ngIf="formGrpCtrl?.errors?.required">{{inputTextSetting.label | translate}}
      {{'msg.is_required' | translate}}
    </mat-error>
    <mat-error *ngIf="formGrpCtrl?.errors?.invalid">{{'msg.invalid' | translate}} {{inputTextSetting.label | translate}}</mat-error>
  </mat-form-field>

  <!-- input tel -->
  <mat-form-field [style]="{ 'width': inputTextSetting.styling?.width }"
    *ngIf="inputTextSetting.type === INPUTTYPES.tel">
    <mat-label class="mat-label">{{ inputTextSetting.label | translate }}<span class="required-indicator"
        *ngIf="inputTextSetting.required"> *</span></mat-label>

    <ngx-mat-intl-tel-input formControlName="{{ inputTextSetting.name? inputTextSetting.name: '' }}"
      [onlyCountries]="countries" [enablePlaceholder]="true" [enableSearch]="false"
      name="{{ inputTextSetting.name? inputTextSetting.name: '' }}" [id]="inputTextSetting.id? inputTextSetting.id: ''" #phone
      class="custom-tel-input">
    </ngx-mat-intl-tel-input>
    <mat-icon-button *ngIf="formGrpCtrl.value !== ''" matSuffix mat-icon-button aria-label="Clear">
      <mat-icon *ngIf="formGrpCtrl?.errors?.validatePhoneNumber" class="indicator-icon invalid-icon">error_outline</mat-icon>
      <mat-icon *ngIf="formGrpCtrl.status === 'VALID'" class="indicator-icon valid-icon">check_circle_outline</mat-icon>
    </mat-icon-button>
    <mat-error *ngIf="formGrpCtrl?.errors?.required">{{inputTextSetting.label | translate}}{{'msg.is_required' | translate}}</mat-error>
    <mat-error *ngIf="formGrpCtrl?.errors?.validatePhoneNumber">{{'msg.invalid_phone_number' | translate}}</mat-error>
  </mat-form-field>

  <!-- input date, time, dateTimeLocal -->
  <mat-form-field class="date-container" [style]="{ 'width': inputTextSetting.styling?.width }"
    [ngClass]="{'unclickable': formGroup.get(this.inputTextSetting.name).disabled || element?.disabled}"
    *ngIf="inputTextSetting.type === INPUTTYPES.date || inputTextSetting.type === INPUTTYPES.dateTimeLocal || inputTextSetting.type === INPUTTYPES.time ||
      inputTextSetting.type === INPUTTYPES.year || inputTextSetting.type === INPUTTYPES.month">

    <mat-label class="mat-label">{{ inputTextSetting.label | translate }}</mat-label>
    <input required="{{ inputTextSetting.required? inputTextSetting.required: false }}"
      formControlName="{{ inputTextSetting.name? inputTextSetting.name: '' }}" [ngModel]="dateValue"
      [style]="{ 'width': inputTextSetting.styling?.width, 'visibility': 'hidden', 'position': 'absolute' }"
      autocomplete="off">

    <input matInput placeholder="{{ inputTextSetting.placeholder }}"
      required="{{ inputTextSetting.required? inputTextSetting.required: false }}"
      id="{{ inputTextSetting.id? inputTextSetting.id: '' }}" value="{{ displayDateValue }}"
      [style]="{ 'width': inputTextSetting.styling?.width }"autocomplete="off"
      (focusout)="onFocusOut()" #displayDateTime>

    <p-calendar matInput [(ngModel)]="dateValue" appendTo="body" formControlName="{{ inputTextSetting.name }}"
      dateFormat="{{ inputTextSetting.dateFormat }}"
      [view]="(inputTextSetting.type === INPUTTYPES.year || inputTextSetting.type === INPUTTYPES.month)? inputTextSetting.type: 'date'"
      [showTime]="inputTextSetting.type === INPUTTYPES.dateTimeLocal || inputTextSetting.type === INPUTTYPES.time"
      [hourFormat]="'12'" [baseZIndex]="9999" (onSelect)="onDateSelect()" #calendar></p-calendar>

    <mat-icon-button matSuffix mat-icon-button aria-label="Indicator" aria-disabled="true" tabindex="-1">
      <img class="date-icon" src="assets/base/icons/calendar.svg" (click)="showTimePicker();" />
    </mat-icon-button>
    <mat-error *ngIf="formGrpCtrl?.errors?.required">{{inputTextSetting.label | translate}}{{'msg.is_required' | translate}}</mat-error>
  </mat-form-field>
</div>
