import { StrucConvertService } from './../../../shared/services/struc-convert.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { IRowNode } from 'ag-grid-community';
import { MatSliderModule } from '@angular/material/slider';
import { DatePickerComponent } from 'src/app/shared/component/input/date-picker/date-picker.component';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormService } from 'src/app/shared/component/form/form.service';
import { FormContent } from 'src/app/shared/component/form/model/FormContent.model';
import { FilterComponent } from 'src/app/shared/component/filter/filter.component';
import { CalendarComponent } from 'src/app/shared/component/input/date/calendar/calendar.component';
import { LookupCurrencyComponent } from '../../lookup/lookup-currency/lookup-currency.component';
import { CustomFilterComponent } from 'src/app/shared/component/filter/custom-filter/custom-filter.component';
import { CustomFilterExpression } from 'src/app/shared/component/filter/model/customFilterExpression.mode';
import dateSelectionData from 'src/app/shared/component/input/date/calendar/dateSelection.json';
import lookupCurrency from "src/app/project-demo/lookup/lookup-currency/lookup-currency.json"
import { FilterGroupStatementComponent } from "../../../shared/component/filter-group/filter-group-statement/filter-group-statement.component";

@Component({
  selector: 'app-demo-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    LookDropInputComponent,
    MatSliderModule,
    DatePickerComponent,
    FilterComponent,
    CalendarComponent,
    LookupCurrencyComponent,
    CustomFilterComponent,
    FilterGroupStatementComponent
  ],
  templateUrl: './demo-form.component.html',
  styleUrls: ['./demo-form.component.scss'],
})
export class DemoFormComponent implements OnInit {
  timeValue: any;
  rangeStart: number = 10;
  rangeEnd: number = 50;

  public filter: CustomFilterExpression[] = [];
  queryString: any;

  lookDropInputSetting1: LookDropInputSetting;
  lookDropInputSetting2: LookDropInputSetting;
  rowData: any;
  formGroup: any;
  multiSelectData: any;

  dateSelection = dateSelectionData;
  reportViewerData: any;

  startDate: FormContent = {
    dateInput: {
      name: 'startDate',
      label: 'Start Date',
      type: InputTypes.date,
      pattern: 'dd/MM/yy',
      styling: {
        width: '60%',
      },
      required: true,
    },
  };

  dateFormGroup: FormGroup = null;
  dateFormContent = [this.startDate];

  //Default filter setting when open
  customFilter: any = {
    filters: [
      {
        condition: "AND",
        rules: [
          {
            id: "BizDate",
            field: "BizDate",
            type: "string",
            input: "formuladatepicker",
            operator: "equal",
            value: {
              formula: "[pastquarter]",
              type: "range",
              from: "2023-09-30T16:00:00Z",
              fromDate: "2023-10-01",
              to: "2023-12-31T15:59:59.999Z",
              toDate: "2023-12-31",
              offset: "+08:00",
              showText: "1/10/2023~31/12/2023"
            }
          },
          {
            id: "StatusFlag",
            field: "StatusFlag",
            type: "string",
            input: "select",
            operator: "equal",
            value: "A"
          },
          {
            id: "Weekdaynum",
            field: "Weekdaynum",
            type: "string",
            input: "checkbox",
            operator: "in",
            value: "1,5,6"
          },
          {
            id: "VoidFlag",
            field: "VoidFlag",
            type: "integer",
            input: "radio",
            operator: "equal",
            value: "0"
          },
          {
            id: "LocCode",
            field: "LocCode",
            type: "string",
            input: "lookup",
            operator: "equal",
            value: "SJ"
          },
        ]
      },
      {
        condition: "OR",
        rules: [
          {
            id: "BillRefNo",
            field: "BillRefNo",
            type: "string",
            input: "text",
            operator: "contains",
            value: "123abc"
          },
          {
            id: "Week",
            field: "Week",
            input: "text",
            type: "integer",
            operator: "not_equal",
            value: "2"
          }
        ]
      }
    ]
  };


  //Filter parameters option
  filterItems: any = [
    {
      fieldName: "loccode",
      id: "LocCode",
      input: "lookup",
      type: "string",
      operator: [
        "equal",
        "not_equal",
        "in",
        "not_in"
      ]
    },
    {
      fieldName: "tableno",
      id: "TableNo",
      input: "text",
      type: "string",
      operator: [
        "equal",
        "not_equal",
        "begins_with",
        "contains",
        "not_contains",
        "ends_with",
        "isnull",
        "isnotnull"
      ]
    },
    {
      fieldName: "bizdate",
      id: "BizDate",
      input: "formuladatepicker",
      type: "string",
      operator: [
        "equal",
        "not_equal",
        "greater",
        "greater_or_equal",
        "lesser",
        "less_or_equal",
        "between"
      ]
    },
    {
      fieldName: "weekdaynum",
      id: "Weekdaynum",
      input: "checkbox",
      type: "string",
      values: {
        0: "general.sunday",
        1: "general.monday",
        2: "general.tuesday",
        3: "general.wednesday",
        4: "general.thursday",
        5: "general.friday",
        6: "general.saturday"
      },
      operator: [
        "in",
        "not_in"
      ]
    },
    {
      fieldName: "week",
      id: "Week",
      input: "text",
      type: "integer",
      operator: [
        "equal",
        "not_equal",
        "greater",
        "greater_or_equal",
        "lesser",
        "less_or_equal",
        "between"
      ]
    },
    {
      fieldName: "salesmode",
      id: "SalesMode",
      input: "select",
      type: "string",
      values: {
        S: "general.cash_sales",
        R: "general.return_and_exchange",
        C: "general.credit_sales",
        D: "general.deposit_sales",
        E: "general.refunded_deposit",
        F: "general.deposit_redeem",
        G: "general.deposit_refund",
        H: "general.hire_purchase_sales",
        X: "general.tax_relief_sales",
        N: "general.non_sale"
      },
      operator: [
        "equal",
        "not_equal"
      ]
    },
    {
      fieldName: "sourceflag",
      id: "SourceFlag",
      input: "select",
      type: "integer",
      values: {
        0: "general.pos",
        1: "general.web_order_onsite",
        3: "general.web_order_pickup",
        4: "general.web_order_delivery",
        5: "general.app_onsite",
        6: "general.app_take_way",
        7: "general.app_pickup",
        8: "general.app_delivery",
        9: "general.web_QR_dine_in",
        10: "general.web_QR_take_away",
        11: "general.app_QR_dine_in",
        12: "general.app_QR_take_away",
        90: "general.third_party"
      },
      operator: [
        "equal",
        "not_equal"
      ]
    },
    {
      fieldName: "statusflag",
      id: "StatusFlag",
      input: "select",
      type: "string",
      values: {
        A: "general.active",
        V: "general.voided",
        C: "general.cancelled"
      },
      operator: [
        "equal",
        "not_equal"
      ]
    },
    {
      fieldName: "voidflag",
      id: "VoidFlag",
      input: "radio",
      type: "integer",
      values: {
        0: "general.no",
        1: "general.yes"
      },
      operator: [
        "equal",
        "not_equal"
      ]
    },
    {
      fieldName: "seatno",
      id: "SeatNo",
      input: "text",
      type: "integer",
      operator: ["equal", "not_equal", "in", "not_in", "lesser", "less_or_equal", "greater", "greater_or_equal", "between", "not_between", "isnull", "isnotnull"]
    },
    {
      fieldName: "billrefno",
      id: "BillRefNo",
      input: "text",
      type: "string",
      operator: ["equal", "not_equal", "begins_with", "contains", "not_contains", "ends_with"]
    },
  ]

  editValue: string;

  constructor(
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private strucConvertService: StrucConvertService
  ) { }

  ngOnInit(): void {
    // Lookdrop Input
    this.lookDropInputSetting1 = {
      name: 'lookup1',
      label: 'Single select lookup',
      valueKey: 'value',
      styling: { width: '60%' },
      colDef: [{ field: 'name' }, { field: 'value' }],
    };

    this.lookDropInputSetting2 = {
      name: 'lookup2',
      label: 'Multi select lookup',
      valueKey: 'value',
      styling: { width: '60%' },
      colDef: [{ field: 'name' }, { field: 'value' }],
      multiSelect: true,
    };

    this.formGroup = new UntypedFormGroup({});
    this.formGroup.addControl('lookup', this.fb.control(''));

    // for date formgroup
    this.dateFormGroup = this.formService.generateFormGroup(
      this.dateFormContent
    );

    this.editValue = 'Today'
  }

  onLookup() {
    this.rowData = [
      { name: 'Data 1', value: 'data1' },
      { name: 'Data 2', value: 'data2' },
    ];
  }

  onAutoComplete() {
    this.rowData = [
      { name: 'Data 1', value: 'data1' },
      { name: 'Data 2', value: 'data2' },
    ];
  }

  onSelected(selectedNodes: IRowNode[]) {
    this.multiSelectData = selectedNodes.map((node) => {
      return { node: node, value: node.data.value };
    });
  }

  onFilter(odataFilterString: any) {
    // console.log('odataFilterString: ', odataFilterString);
  }
}
