<div [formGroup]="formGroup" *ngIf="!inputDateSetting.hidden">
  <mat-form-field appearance="fill" [style]="{ 'width': inputDateSetting?.styling?.width }">
    <mat-label>{{ inputDateSetting?.label | translate }}</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="{{ inputDateSetting.name? inputDateSetting.name: '' }}"
    [required]="!inputDateSetting.hideError && inputDateSetting.required" [min]="inputDateSetting?.minDate" [max]="inputDateSetting.maxDate"
    [value]="newDate | date:'dd/MM/yyyy'" 
    (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>
        <img class="date-icon" src="assets/base/icons/calendar.svg" />
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker matDatepickerParse="DD/MM/YYYY"></mat-datepicker>
    <mat-error *ngIf="!inputDateSetting.hideError && formGroup.controls[inputDateSetting.name]?.errors?.required">
      <span *ngFor="let err of errors">
        <span *ngIf="formGroup.controls[inputDateSetting.name]?.hasError(err.name) && formGroup.controls[inputDateSetting.name]?.getError(err.name)">
          {{ err.errMsg | translate }}
        </span>
      </span>
    </mat-error>
  </mat-form-field>
</div>
