/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IEdmEntityContainer } from './iEdmEntityContainer';

export interface IEdmEntityContainerElement { 
    readonly containerElementKind?: IEdmEntityContainerElement.ContainerElementKindEnum;
    container?: IEdmEntityContainer;
    readonly name?: string;
}
export namespace IEdmEntityContainerElement {
    export type ContainerElementKindEnum = 'None' | 'EntitySet' | 'ActionImport' | 'FunctionImport' | 'Singleton';
    export const ContainerElementKindEnum = {
        None: 'None' as ContainerElementKindEnum,
        EntitySet: 'EntitySet' as ContainerElementKindEnum,
        ActionImport: 'ActionImport' as ContainerElementKindEnum,
        FunctionImport: 'FunctionImport' as ContainerElementKindEnum,
        Singleton: 'Singleton' as ContainerElementKindEnum
    };
}