import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SubscriptionService } from './services/subscription.service';
import { AuthService } from './services/auth.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const AuthLoginGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router)
  const subscriptionService: SubscriptionService = inject(SubscriptionService);
  const authService: AuthService = inject(AuthService);

  return authService.isDoneLoading$.pipe(
    filter(isDone => {
      return isDone;
    }),
    switchMap(_ => authService.isAuthenticated$),
    tap(isAuthenticated => {
      let isSubscriptionPage = state.url == '/subscription';
      let allowAccess: boolean = false;

      if (isAuthenticated) {
        let selectedSub = subscriptionService.getSelectedSubscription();

        if (environment.disableSubscriptionPage) {
          // if subscription empty will select default
          if (!selectedSub) {
            subscriptionService.selectDefaultSubscription();
          }

          // if subscription page will redirect user to default dashboard page
          if (isSubscriptionPage) {
            router.navigateByUrl('/dashboard');
            allowAccess = false;
          }
        } else {
          if (selectedSub && isSubscriptionPage) {
            router.navigateByUrl('/dashboard');
            allowAccess = false;
          } else if (!selectedSub && !isSubscriptionPage) {
            router.navigate(['/subscription']);
            allowAccess = false;
          } else {
            allowAccess = true;
          }
        }
      } else {
        // navigate to portal login page
        authService.login(state.url);
        allowAccess = false;
      }

      return allowAccess;
    }),
  );
};
