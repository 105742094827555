<div class="lookup-input-layout" [formGroup]="formGroup" #lookupInput>
  <div class="lookup-input-wrapper" *ngIf="lookDropInputSetting"
    [ngStyle]="{'position: relative': lookDropInputSetting.defaultLookdrop}">
    <div class="" *ngIf="!lookDropInputSetting.defaultLookdrop; else filterLookdrop">
      <!-- single select -->
      <mat-form-field [style]="{ 'width': lookDropInputSetting.styling?.width }"
        *ngIf="!lookDropInputSetting.multiSelect" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <mat-label class="mat-label" *ngIf="lookDropInputSetting.label">{{ lookDropInputSetting.label |
          uppercaseTranslate }}</mat-label>
        <input matInput placeholder="{{lookDropInputSetting.placeholder | translate}}"
          [ngClass]="{'cursor-pointer': readonly}"
          required="{{ lookDropInputSetting.required? lookDropInputSetting.required: false }}"
          formControlName="displayInput-{{ lookDropInputSetting.name | translate}}"
          id="{{lookDropInputSetting.id? lookDropInputSetting.id: ''}}" #input [readonly]="readonly"
          (input)="onInput($event)" (focus)="onFocus($event);" (focusout)="onInputFocusOut($event);"
          (keydown)="onKeydown($event)" autocomplete="off" (click)="onClickInput($event)">

        <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

        <button type="button" class="btn-clear" (click)="onClickClear()"
          *ngIf="readonly && formGroup.get(lookDropInputSetting.name)?.value !== ''"><span>{{ 'general.clear' |
            translate }}</span></button>
        <mat-icon class="icon-wrapper" matSuffix mat-icon-button
          *ngIf="!openAutoComplete && lookDropInputSetting.dropdownOption && !formGroup.get(lookDropInputSetting.name)?.disabled && !element?.disabled"
          (click)="onClickDropdown()" (keyup.enter)="onClickDropdown()">
          <img class="chevron-down-icon" tabindex="0" src="assets/base/icons/chevron-down.svg" />
        </mat-icon>
        <mat-icon class="icon-wrapper" matSuffix mat-icon-button (click)="onClickLookup($event);"
          (keyup.enter)="onClickLookup($event)"
          *ngIf="!openLookup && !openAutoComplete && !lookDropInputSetting.dropdownOption && !formGroup.get(lookDropInputSetting.name)?.disabled">
          <img class="chevron-right-icon" tabindex="0" src="assets/base/icons/chevron-right.svg" />
        </mat-icon>
        <mat-icon class="icon-wrapper" matSuffix mat-icon-button (click)="onAutoCompleteClose($event);"
          *ngIf="openAutoComplete && rowData">
          <img class="chevron-close-icon" tabindex="0" src="assets/base/icons/remove.svg" />
        </mat-icon>
        <mat-error *ngIf="formGroup.controls[lookDropInputSetting.name]?.errors?.required">{{lookDropInputSetting.label
          | translate}} {{'msg.is_required' | translate}}</mat-error>
      </mat-form-field>

    <!-- multi select -->
    <mat-form-field [hidden]="true">
      <input matInput tabindex="-1" required="{{ lookDropInputSetting.required? lookDropInputSetting.required: false }}" formControlName="{{lookDropInputSetting.name? lookDropInputSetting.name: ''}}">
    </mat-form-field>
    <mat-form-field [style]="{ 'width': lookDropInputSetting.styling?.width }"
      [ngClass]="{'multi-select': multiSelectData}"
      *ngIf="lookDropInputSetting.multiSelect" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <mat-label class="mat-label" *ngIf="lookDropInputSetting.label">{{ lookDropInputSetting.label | uppercaseTranslate }}<span class="required-symbol" *ngIf="lookDropInputSetting.required">*</span></mat-label>
      <input matInput placeholder="{{lookDropInputSetting.placeholder | translate}}" id="{{lookDropInputSetting.id? lookDropInputSetting.id: ''}}"
        #input autocomplete="off" [readonly]="readonly" formControlName="displayInput-{{ lookDropInputSetting.name }}" [ngClass]="{'cursor-pointer': readonly}"
        (input)="onInput($event)" (focus)="onFocus($event)" (focusout)="onInputFocusOut($event);" (keydown)="onKeydown($event)" (click)="onClickInput($event)">

      <mat-icon class="icon-wrapper" matSuffix mat-icon-button
        *ngIf="lookDropInputSetting.manualInput && lookDropInputSetting.multiSelect"
        (click)="onAddManualInputVal($event)" (keyup.enter)="onAddManualInputVal($event)">
        <img class="add-icon" style="width: 18px" tabindex="0" src="assets/base/icons/plus.svg" />
      </mat-icon>
      <mat-icon class="icon-wrapper" matSuffix mat-icon-button
        *ngIf="!openAutoComplete && lookDropInputSetting.dropdownOption && lookDropInputSetting.dropdownOption.length > 0 && (!formGroup.get(lookDropInputSetting.name)?.disabled && !element?.disabled)"
        (click)="onClickDropdown()" (keyup.enter)="onClickDropdown()">
        <img class="chevron-down-icon" tabindex="0" src="assets/base/icons/chevron-down.svg" />
      </mat-icon>
      <mat-icon class="icon-wrapper" matSuffix mat-icon-button (click)="onClickLookup($event)" (keyup.enter)="onClickLookup($event)"
        *ngIf="!openLookup && !openAutoComplete && !lookDropInputSetting.dropdownOption && lookDropInputSetting.dropdownOption?.length !== 0 && !formGroup.get(lookDropInputSetting.name)?.disabled">
        <img class="chevron-right-icon" tabindex="0" src="assets/base/icons/chevron-right.svg"/>
      </mat-icon>
      <mat-icon class="icon-wrapper" matSuffix mat-icon-button (click)="onAutoCompleteClose($event);" *ngIf="openAutoComplete && rowData && rowData.length > 0">
        <img class="chevron-close-icon" tabindex="0" src="assets/base/icons/remove.svg" />
      </mat-icon>
      <mat-error>
        <span *ngIf="formGroup.controls[displayName]?.errors?.email; else requiredErr">{{'msg.please_enter_valid_email_address' | translate}}</span>
        <ng-template #requiredErr>
          <span *ngIf="formGroup.controls[lookDropInputSetting.name]?.errors?.required">{{lookDropInputSetting.label | translate}} {{'msg.is_required' | translate}}</span>
        </ng-template>
      </mat-error>

        <div class="multi-select-container" *ngIf="multiSelectData && multiSelectData.length > 0">
          <div class="wrapper" *ngFor="let data of multiSelectData; let i = index;">
            <span>{{ data.displayValue? data.displayValue: data.value }}</span>
            <button type="button" tabindex="-1" class="multi-select-btn"><img src="assets/base/icons/remove.svg"
                (click)="onRemoveMultiSelectNodes($event, data.node, i)" /></button>
          </div>
        </div>
      </mat-form-field>

      <!-- autocomplete -->
      <div class="flex justify-content-center autocomplete">
        <div class="autoComplete-wrapper">
          <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
            cdkConnectedOverlayPanelClass="lookdrop" [cdkConnectedOverlayOpen]="openAutoComplete"
            (detach)="onAutoCompleteClose()">
            <ng-container *ngIf="rowData && rowData.length > 0; else noAutoCompleteResult">
              <ag-grid-angular class="autocomplete-table ag-theme-alpine" id="agGrid" style="height: 40vh"
                [ngClass]="{'no-header': (lookDropInputSetting.multiSelect && lookDropInputSetting.colDef.length < 3) || (!lookDropInputSetting.multiSelect && lookDropInputSetting.colDef.length) && lookDropInputSetting.dropdownOption}"
                [rowData]="rowData" [columnDefs]="lookDropInputSetting.colDef" [alwaysShowHorizontalScroll]="true"
                [suppressHorizontalScroll]="true" [tabToNextCell]="tabToNextCell"
                [rowSelection]="lookDropInputSetting.multiSelect? rowSelection.multiple: rowSelection.single"
                [rowMultiSelectWithClick]="lookDropInputSetting.multiSelect" (gridReady)="onGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)" (focusout)="onAutoCompleteFocusOut($event);"
                (selectionChanged)="onSelectionChanged($event)" (rowClicked)="onRowClicked($event)"
                (cellKeyDown)="onCellKeydown($event)"></ag-grid-angular>
              <button type="button" class="show-more-btn" (click)="onClickLookup($event)"
                *ngIf="!lookDropInputSetting.dropdownOption">{{ 'general.show_more' | translate }}</button>
            </ng-container>
            <ng-template #noAutoCompleteResult>
              <ng-container *ngIf="rowData && inputText">
                <table class="no-result" tabindex="-1" (focusout)="onAutoCompleteFocusOut($event);">
                  <tr>
                    <td>{{ "msg.no_result_try_again" | translate: {fieldName: inputText } }}</td>
                  </tr>
                </table>
              </ng-container>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
    <ng-template #filterLookdrop>
      <!-- single select -->
      <div class="lookdropContainer" *ngIf="!lookDropInputSetting.multiSelect">
        <input class="inputBox" placeholder="{{lookDropInputSetting.placeholder | translate}}"
          [ngClass]="{'cursor-pointer': readonly}"
          required="{{ lookDropInputSetting.required? lookDropInputSetting.required: false }}"
          formControlName="displayInput-{{ lookDropInputSetting.name | translate}}"
          id="{{lookDropInputSetting.id? lookDropInputSetting.id: ''}}" #input [readonly]="readonly"
          (input)="onInput($event)" (focus)="onFocus($event);" (focusout)="onInputFocusOut($event);"
          (keydown)="onKeydown($event)" autocomplete="off" (click)="onClickInput($event)">

        <button class="lookupBut" (click)="onClickLookup($event);" (keyup.enter)="onClickLookup($event)">
          <img style="width: 20px;" src="assets/base/icons/chevron-right.svg">
        </button>
      </div>

      <!-- multi select -->
      <div class="lookdropContainer" *ngIf="lookDropInputSetting.multiSelect">
        <input class="inputBox" placeholder="{{lookDropInputSetting.placeholder | translate}}"
          id="{{lookDropInputSetting.id? lookDropInputSetting.id: ''}}" #input autocomplete="off" [readonly]="readonly"
          formControlName="displayInput-{{ lookDropInputSetting.name | translate }}" [ngClass]="{'cursor-pointer': readonly}"
          required="{{ lookDropInputSetting.required? lookDropInputSetting.required: false }}" (input)="onInput($event)"
          (focus)="onFocus($event)" (focusout)="onInputFocusOut($event);" (keydown)="onKeydown($event)"
          (click)="onClickInput($event)">

          <div class="multiSelect" *ngIf="multiSelectData && multiSelectData.length > 0">
            <div class="wrapper" *ngFor="let data of visibleData; let i = index;">
              <span class="item-text">{{ data.displayValue ? data.displayValue : data.value }}</span>
              <button type="button" tabindex="-1" class="multi-select-btn">
                <img src="assets/base/icons/remove.svg" (click)="removeMultiSelect($event, data.node, i)" />
              </button>
            </div>
            <div *ngIf="hiddenCount > 0" class="more-info">
              +{{ hiddenCount }} more
            </div>
          </div>

        <button class="lookupBut" (click)="onClickLookup($event);" (keyup.enter)="onClickLookup($event)">
          <img style="width: 20px;" src="assets/base/icons/chevron-right.svg">
        </button>
      </div>

      <!-- autocomplete -->
      <div class="flex justify-content-center autocomplete">
        <div class="autocomplete-dropdown" (clickOutside)="onAutoCompleteClose()" *ngIf="openAutoComplete">
          <ng-container *ngIf="rowData && rowData.length > 0; else noAutoCompleteResult">
            <ag-grid-angular class="ag-theme-alpine" id="agGrid" style="height: 200px; width: 500px;"
              [ngClass]="{'no-header': (lookDropInputSetting.multiSelect && lookDropInputSetting.colDef.length < 3) || (!lookDropInputSetting.multiSelect && lookDropInputSetting.colDef.length) && lookDropInputSetting.dropdownOption}"
              [rowData]="rowData" [columnDefs]="lookDropInputSetting.colDef" [alwaysShowHorizontalScroll]="true"
              [suppressHorizontalScroll]="true" [tabToNextCell]="tabToNextCell"
              [rowSelection]="lookDropInputSetting.multiSelect? rowSelection.multiple: rowSelection.single"
              [rowMultiSelectWithClick]="lookDropInputSetting.multiSelect" (gridReady)="onGridReady($event)"
              (firstDataRendered)="onFirstDataRendered($event)" (focusout)="onAutoCompleteFocusOut($event);"
              (selectionChanged)="onSelectionChanged($event)" (rowClicked)="onRowClicked($event)"
              (cellKeyDown)="onCellKeydown($event)"></ag-grid-angular>
            <button type="button" class="show-more-btn" (click)="onClickLookup($event)"
              *ngIf="!lookDropInputSetting.dropdownOption">{{ 'general.show_more' | translate }}</button>
          </ng-container>
          <ng-template #noAutoCompleteResult>
            <ng-container *ngIf="rowData && inputText && lookDropInputSetting.autoComplete">
              <table class="no-result" tabindex="-1" (focusout)="onAutoCompleteFocusOut($event);">
                <tr>
                  <td>{{ "msg.no_result_try_again" | translate: {fieldName: inputText } }}</td>
                </tr>
              </table>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </ng-template>

  </div>

  <!-- lookup dialog -->
  <p-dialog header="{{ lookDropInputSetting.headerName | translate}}" *ngIf="openLookup" [(visible)]="openLookup"
    [modal]="true" appendTo="body" [baseZIndex]="10000" [draggable]="false" [resizable]="false" panelClass="lookup"
    backdropClass="lookup" [style]="{width: '100%', height: '100%'}" (onShow)="onShowLookup()"
    (visibleChange)="onLookupVisibleChange($event)">
    <app-listing [colDef]="lookDropInputSetting.colDef" [rowData]="rowData" [selectedNodes]="selectedNodes"
      [setting]="listingSetting" [actionToolbarSetting]="lookupActionToolbarSetting"
      [lookDropInputSetting]="lookDropInputSetting" [formCode]="lookupFormCode"
      (onToolbarAction)="onToolbarActioned($event)" (onListingRowSelected)="onListingRowSelected($event)"
      (onApplySelectedRow)="onListingApplySelectedRow($event)"  (onGridColumnChange)="onGridColumnChange($event)"
      (onRowDataUpdated)="onRowDataUpdated($event)"
      *ngIf="!lookDropInputSetting.lookupComponentName"></app-listing>
    <ng-container #lookup *ngIf="lookDropInputSetting.lookupComponentName"></ng-container>
  </p-dialog>
</div>
