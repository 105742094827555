import { Component, NgZone } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';

import { encodeBase64 } from 'src/app/core/services/utils.service';
import { PopupForm } from 'src/app/shared/models/popupForm.model';
import { InRouterLink } from 'src/app/shared/models/inRouterLink.model';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { NgIf } from '@angular/common';

export interface ICellRendererParamsExtend extends ICellRendererParams {
  params: any
}

@Component({
    selector: 'app-router-link-renderer',
    templateUrl: './router-link-renderer.component.html',
    styleUrls: ['./router-link-renderer.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class RouterLinkRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParamsExtend & { inRouterLink: InRouterLink, popupForm: PopupForm };
  paramObj: any = {};

  constructor(
    private ngZone: NgZone,
    private pageService: PageService,
    private popupFormService: PopupFormService
  ) { }

  agInit(params: ICellRendererParamsExtend & { inRouterLink: InRouterLink, popupForm: PopupForm }): void {
    this.params = params;
  }

  navigate() {
    // get params
    if (this.params?.inRouterLink?.params || this.params?.popupForm?.params) {
      let routerParams = this.params.inRouterLink? this.params.inRouterLink.params: this.params.popupForm.params;

      for (let index = 0; index < routerParams.length; index++) {
        let key = routerParams[index];
        this.paramObj[key] = this.params.data && this.params.data[key]? this.params.data[key]: null;
      }
    }
    
    // routing url
    if(this.params.inRouterLink || this.params?.popupForm?.route) {
      let route = this.params.inRouterLink? this.params.inRouterLink.route: this.params.popupForm.route;
      let queryParams = this.params.inRouterLink? this.params.inRouterLink?.queryParams: this.params.popupForm?.queryParams;

      if(this.params?.popupForm) {
        queryParams = { ...{p: encodeBase64(this.params.popupForm.componentName.name), f: encodeBase64(this.params.popupForm.formCode)}, ...queryParams};
      }

      this.ngZone.run(() => {
        this.pageService.navigateByPath(route, _.cloneDeep(this.paramObj), queryParams, this.params?.inRouterLink?.childPage);
      });
    }

    // open popup form
    if(this.params.popupForm) {
      this.popupFormService.open(this.params.popupForm, this.params.data);
    }
  }

  refresh(params: ICellRendererParamsExtend & { inRouterLink: InRouterLink, popupForm: PopupForm }): boolean {
    this.params = params;
    return false;
  }
}
