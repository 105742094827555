import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SearchbarComponent } from '../input/searchbar/searchbar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ListingComponent } from '../listing/listing.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobService } from '../../services/job.service';
import { ListingSetting } from '../listing/model/ListingSetting.model';
import { RowModelType } from '../../enums/rowModelType';
import { ColDef, GridApi } from 'ag-grid-community';
import { RowSelection } from '../../enums/rowSelection';
import { FormsModule } from '@angular/forms';
import { JobType } from '../../enums/jobType';
import { PageService } from '../../services/page.service';
import { JobQueueModel } from 'src/swagger/base-rms/openapi';
import * as _ from 'lodash';
import { encodeBase64 } from 'src/app/core/services/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-status',
  standalone: true,
  imports: [CommonModule, SearchbarComponent, TranslateModule, ListingComponent, FormsModule],
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss']
})
export class JobStatusComponent {
  private gridApi!: GridApi;
  options: any[] = [];
  defaultColDef: ColDef = {
    resizable: false,
    filter: false,
    sortable: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressMovable: true,
    lockVisible: true,
    enableRowGroup: false
  };
  colDef: ColDef[] = [
    {
      field: 'docNo',
      headerName: this.translateService.instant('general.doc_no')
    },
    {
      field: 'createdDate',
      headerName: this.translateService.instant('general.job_submitted'),
      valueFormatter: (params) => this.datePipe.transform(params.value, 'dd/MM/yy hh:mm:ss'),
      filterValueGetter: (params) => this.datePipe.transform(params.data.createdDate, 'dd/MM/yy hh:mm:ss'),
      rowGroup: false,
    },
    {
      field: 'refCode1',
      headerName: this.translateService.instant('general.action'),
      valueFormatter: (params) => this.translate.instant('general.task' + params.value.toLowerCase()),
      filterValueGetter: (params) => this.translate.instant('general.task' + params.data.refCode1.toLowerCase())
    },
    {
      field: 'jobStatusDesc',
      headerName: this.translateService.instant('general.job_status'),
      cellRenderer: this.jobStatusCellRenderer,
      maxWidth: 350
    },
  ];
  rowData: any;
  listingSetting: ListingSetting;
  formCode: string;
  jobData: JobQueueModel[];
  inputValue: string = '';
  dropdownOptions: string[] = [];
  allOptions: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4']; // Example options

  constructor(
    public dialogRef: MatDialogRef<JobStatusComponent>,
    private jobService: JobService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private pageService: PageService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async ngOnInit() {
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
      rowSelection: RowSelection.single,
      rowClassRules: {
        'disabled': (params) => {return params.data?.jobStatusDesc !== 'Completed';}
      }
    }

    try {
      let result = await this.jobService.getJobsbyqueue(this.data?.queueName, this.data?.formCode);
      this.jobData = _.cloneDeep(result);
      this.rowData = result;
    } catch (error) {
      console.log('error: ', error)
    }
  }

  jobStatusCellRenderer(params: any) {
    const jobStatus = params.value;
    let icon = '';

    switch (jobStatus) {
      case 'Pending':
        icon = '<img style="width: 20px; margin: 5px;" src="assets/base/icons/CloudLoading.svg"></img>';
        break;
      case 'Completed':
        icon = '<img style="width: 20px; margin: 5px;" src="assets/base/icons/FileCloud.svg"></img>';
        break;
      case 'Cancelled':
        icon = '<img style="width: 20px; margin: 5px;" src="assets/base/icons/CloudX.svg"></img>';
        break;
      case 'Open':
        icon = '<img style="width: 20px; margin: 5px;" src="assets/base/icons/CloudArrowUp.svg"></img>';
        break;
      case 'Submitted':
        icon = '<img style="width: 20px; margin: 5px;" src="assets/base/icons/CloudCheck.svg"></img>';
        break;
      case 'Error':
        icon = '<img style="width: 20px; margin: 5px;" src="assets/base/icons/CloudLoading.svg"></img>';
        break;
    }

    return `<span style="display: flex;">${icon} ${jobStatus}</span>`;
  }

  paramSearch(event: { result: any[]; search: boolean, searchInput: string }) {
    this.gridApi.setGridOption("quickFilterText", event.searchInput);
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  async onGridReady(gridApi: GridApi<any>) {
    this.gridApi = gridApi;
  }

  onGridDoubleClicked(event) {
    let type = event[0]?.docNo.split('-')[0];
    let jobData = this.jobData.find((job: JobQueueModel) => job.docNo === event[0]?.docNo);

    if(type === JobType.report && jobData.jobStatus === 2) {
      let param =  {parameter: encodeBase64(jobData?.jobId.toString())};
      
      this.router.navigateByUrl('/dummy-route', { skipLocationChange: true }).then(() => {
        this.pageService.navigateByUrl('report/' + jobData?.refCode1, null, param);
      });

      this.closeDialog();
    }
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.updateDropdownOptions(value);
  }

  updateDropdownOptions(value: string): void {
    if (value) {
      this.dropdownOptions = this.allOptions.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      this.dropdownOptions = [];
    }
  }

  selectOption(option: string): void {
    this.inputValue = option;
    this.dropdownOptions = [];
  }

  clearInput(): void {
    this.inputValue = '';
    this.dropdownOptions = [];
  }
}
