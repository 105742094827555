
<div class="filter-container">
  <p class="title">Action</p>

  <div class="right-button">
    <button class="regenerate-btn" (click)="regenerate()">Regenerate</button>
    <button class="cancel-btn" (click)="cancel()">Cancel</button>
    <button class="email-btn" (click)="email()">Email</button>
    <button class="serverpdf-btn"(click)="downloadserverpdf()">Download Invoice as PDF</button>
    <button class="stmt-btn"(click)="downloadstmt()">Download Appendix 1 as Excel</button>
  </div>
</div>
