<div class="toggle-container" [formGroup]="formGroup" [ngStyle]="toggleInputSetting.styling"
  *ngIf="toggleInputSetting" [ngClass]="{'inline': toggleInputSetting?.inline}">
  <mat-label class="mat-label" *ngIf="toggleInputSetting.label">
    <span [ngStyle]="{'color': toggleInputSetting.styling?.color}">{{ toggleInputSetting.label | translate }}</span>
  </mat-label>
  <div>
    <mat-slide-toggle [color]="'primary'" formControlName="{{ toggleInputSetting.name? toggleInputSetting.name: '' }}" 
      [name]="toggleInputSetting.name? toggleInputSetting.name: ''" #slideToggle
      [id]="toggleInputSetting.id? toggleInputSetting.id: ''"
      [checked]="formData && formData[toggleInputSetting.name]? formData[toggleInputSetting.name]: false"
      (change)="onChange($event)"></mat-slide-toggle>
  </div>
</div>
