import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTypes } from '../../../shared/component/input/enum/InputTypes';
import { DropdownOption } from '../../../shared/component/input/model/DropdownOption.model';
import { FormSetting } from '../../../shared/component/form/model/FormSetting.model';
import { PageService } from 'src/app/shared/services/page.service';
import { Page } from 'src/app/shared/models/page.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { UntypedFormGroup } from '@angular/forms';
import { LookupCountryCodeComponent } from '../../lookup/component/lookup-country-code/lookup-country-code.component';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { LookupStateCodeComponent } from '../../lookup/component/lookup-state-code/lookup-state-code.component';
import { LookupRegionCodeComponent } from '../../lookup/component/lookup-region-code/lookup-region-code.component';
import { LookupAreaCodeComponent } from '../../lookup/component/lookup-area-code/lookup-area-code.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormComponent } from '../../../shared/component/form/form.component';

import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';
import { LookupCountryComponent } from '../../lookup/lookup-country/lookup-country.component';
import { LookupStateComponent } from 'src/app/project-demo/lookup/lookup-state/lookup-state.component';
import { LookupRegionComponent } from '../../lookup/lookup-region/lookup-region.component';
import { LookupAreaComponent } from '../../lookup/lookup-area/lookup-area.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { Mode } from 'src/app/shared/enums/mode';
import { DealerService } from '../dealer.service';
import { DealerStatus } from 'src/swagger/cxmbackend/openapi/model/dealerStatus';
import { DropdownBankComponent } from '../../dropdown/dropdown-bank/dropdown-bank.component';

@Component({
  selector: 'app-dealer-form',
  templateUrl: './dealer-entry.component.html',
  styleUrls: ['./dealer-entry.component.scss'],
  standalone: true,
  imports: [FormComponent],
  providers: [DealerService]
})
export class DealerEntryComponent implements OnInit, OnDestroy {
  page: Page;
  pages: Page[];
  formDetail: any = null;
  dealerId: string;
  dealerData: any;
  isEditMode: boolean;
  page$: Subscription;

  formSetting: FormSetting = {
    formCode: FormCode.dealerentry,
    content: [
      {
        inputText: {
          name: 'dealerName',
          label: 'Name',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 10
        },
      },
      {
        lookDropInput: {
          name: 'statusFlag',
          label: 'Status',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(DealerStatus).map((key) => {
            return { value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'address1',
          label: 'Address 1',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputText: {
          name: 'address2',
          label: 'Address 2',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputText: {
          name: 'address3',
          label: 'Address 3',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputText: {
          name: 'addCity',
          label: 'City',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          }
        }
      },
      {
        inputNumber: {
          name: 'addPostal',
          label: 'Postal Code',
          required: true,
          maxLength: 5
        }
      },

      {
        lookDropInput: {
          name: 'countryCode',
          label: 'dealer.country',
          componentName: LookupCountryComponent,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        lookDropInput: {
          name: 'stateCode',
          label: 'dealer.state',
          componentName: LookupStateComponent,
          required: false,
          styling: {
            width: '60%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true,
            filterBy: 'countryCode' // optional if same value with name
          },
        },
      },
      {
        lookDropInput: {
          name: 'regionCode',
          label: 'dealer.region',
          componentName: LookupRegionComponent,
          required: false,
          styling: {
            width: '60%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true
          },
        },
      },
      {
        lookDropInput: {
          name: 'areaCode',
          label: 'dealer.area',
          componentName: LookupAreaComponent,
          required: false,
          styling: {
            width: '60%',
          },
          dependency: {
            name: 'countryCode',
            filterByParentVal: true
          },
        },
      },
      {
        inputText: {
          name: 'email',
          label: 'Email',
          type: InputTypes.email,
          required: true,
          maxLength: 50,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'mobileNo',
          label: 'Mobile Number',
          type: InputTypes.tel,
          required: true,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'phoneNo',
          label: 'Phone Number',
          type: InputTypes.tel,
          required: true,
          styling: {
            width: '60%',
          },
        }
      },
      {
        inputText: {
          name: 'statementEmails',
          label: 'Statement Emails',
          type: InputTypes.email,
          maxLength: 254,
          required: false,
          styling: {
            width: '60%',
          },
        }
      },
      {
        lookDropInput: {
          name: 'bankCode',
          label: 'dealer.bank_code',
          componentName: DropdownBankComponent,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'bankAccountNo',
          label: 'dealer.bank_acc_no',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 100,
        }
      },
      {
        inputText: {
          name: 'bankAccountName',
          label: 'dealer.bank_acc_name',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 100,

        }
      },
    ],
    sideNavItm: [
      { name: 'Dealer 1', action: '' },
      { name: 'Dealer 2', action: '' },
      { name: 'Dealer 3', action: '' },
      { name: 'Dealer 4', action: '' },
      { name: 'Dealer 5', action: '' },
      { name: 'Dealer 6', action: '' },
      { name: 'Dealer 7', action: '' },
      { name: 'Dealer 8', action: '' },
      { name: 'Dealer 9', action: '' },
    ]
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private authService: AuthService,
    private pageService: PageService,
    private dealerService: DealerService,
    private customService: CustomService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    public dialog: MatDialog,
    private formService: FormService,
  ) {
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.dealerId = params['dealerId'];
      if (this.dealerId) {
        await this.getDealer(this.dealerId);
      }
    }
    // Check edit mode
    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }
    else {
      this.dealerData = {
        statusFlag: 'New',
      }
    }
  }


  onToolbarAction(value: ActionToolBarResp) {
  }

  async getDealer(dealerId: string) {
    // Get Dealer
    let result = await this.dealerService.getOdataDealer(dealerId, null, this.formDetail.formCode);
    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.dealerData = result['value'][0];
    }
  }

  async addDealer(reqBody: any) {
    this.loaderService.startLoading();
    let result = await this.dealerService.reqCreateDealer(reqBody, this.formDetail.formCode);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.dealerlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.new.dealer.created',
        desc: "popupMsg.added.new.dealer",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.dealerlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.new.dealer.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  async updateDealer(reqBody: any) {
    this.loaderService.startLoading();
    let result = await this.dealerService.reqUpdateDealer(reqBody, this.formDetail.formCode);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.dealerlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'popupMsg.dealer.updated',
        desc: "popupMsg.updated.dealer",
        actionBtnText: 'btn.okay'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.dealerlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.update.dealer.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody = this.formService.getReqBody(formGroup);
    if (this.isEditMode) {
      reqBody = { ...reqBody, dealerId: +this.dealerId };
      //reqBody.append("dealerId", +this.dealerId);
      this.updateDealer(reqBody);
    } else {
      this.addDealer(reqBody);
    }

    this.pageService.refreshListing$.next([FormCode.dealerlisting]);
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy(): void {
    this.page$?.unsubscribe();
  }
}
