import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorCode } from 'src/swagger/base/openapi';
import { ErrorPageSetting } from '../component/input/model/ErrorPageSetting.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorPageService {
  errorPage$: Subject<{show: boolean, errorPage?: ErrorPageSetting, }> = new Subject();

  constructor() { }

  show(errorCode: ErrorCode | number, msg?: string, redirectLink?: string) {
    this.errorPage$.next({
      show: true,
      errorPage: {
        errorCode: errorCode,
        msg: msg? msg: '',
        redirectLink: redirectLink? redirectLink: ''
      }
    });
  }

  hide() {
    this.errorPage$.next({show: false});
  }
}
