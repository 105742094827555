import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareValueService {
  private valueSubject = new BehaviorSubject<any>({});
  public value$ = this.valueSubject.asObservable();

  setData(value: any) {
    this.valueSubject.next(value);
  }
}
