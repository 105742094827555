/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AddressFamily = 'Unspecified' | 'Unix' | 'InterNetwork' | 'ImpLink' | 'Pup' | 'Chaos' | 'Ipx' | 'Iso' | 'Ecma' | 'DataKit' | 'Ccitt' | 'Sna' | 'DecNet' | 'DataLink' | 'Lat' | 'HyperChannel' | 'AppleTalk' | 'NetBios' | 'VoiceView' | 'FireFox' | 'Banyan' | 'Atm' | 'InterNetworkV6' | 'Cluster' | 'Ieee12844' | 'Irda' | 'NetworkDesigners' | 'Max' | 'Packet' | 'ControllerAreaNetwork' | 'Unknown';

export const AddressFamily = {
    Unspecified: 'Unspecified' as AddressFamily,
    Unix: 'Unix' as AddressFamily,
    InterNetwork: 'InterNetwork' as AddressFamily,
    ImpLink: 'ImpLink' as AddressFamily,
    Pup: 'Pup' as AddressFamily,
    Chaos: 'Chaos' as AddressFamily,
    Ipx: 'Ipx' as AddressFamily,
    Iso: 'Iso' as AddressFamily,
    Ecma: 'Ecma' as AddressFamily,
    DataKit: 'DataKit' as AddressFamily,
    Ccitt: 'Ccitt' as AddressFamily,
    Sna: 'Sna' as AddressFamily,
    DecNet: 'DecNet' as AddressFamily,
    DataLink: 'DataLink' as AddressFamily,
    Lat: 'Lat' as AddressFamily,
    HyperChannel: 'HyperChannel' as AddressFamily,
    AppleTalk: 'AppleTalk' as AddressFamily,
    NetBios: 'NetBios' as AddressFamily,
    VoiceView: 'VoiceView' as AddressFamily,
    FireFox: 'FireFox' as AddressFamily,
    Banyan: 'Banyan' as AddressFamily,
    Atm: 'Atm' as AddressFamily,
    InterNetworkV6: 'InterNetworkV6' as AddressFamily,
    Cluster: 'Cluster' as AddressFamily,
    Ieee12844: 'Ieee12844' as AddressFamily,
    Irda: 'Irda' as AddressFamily,
    NetworkDesigners: 'NetworkDesigners' as AddressFamily,
    Max: 'Max' as AddressFamily,
    Packet: 'Packet' as AddressFamily,
    ControllerAreaNetwork: 'ControllerAreaNetwork' as AddressFamily,
    Unknown: 'Unknown' as AddressFamily
};