import { Auth } from './../../state/auth.repository';
import { Injectable } from '@angular/core';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { GetNextCounterRequest } from 'src/swagger/base/openapi';
import { XplViewResponse } from 'src/swagger/base-rms/openapi';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private customService: CustomService,private oauthService: OAuthService) { }

  async getNextCounter(reqBody: GetNextCounterRequest) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.system.GetNextCounter,
      hostPath: environment.hostPath,
      body: reqBody
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  async getInitData(subscriptionId: string): Promise<XplViewResponse> {
      let customRequest = {
        httpMethod: HttpMethod.GET,
        requestpath: environment.apis.system.GetInitData,
        hostPath: environment.hostPath,
        headers: {
          accessToken: this.oauthService.getAccessToken(),
          formCode: "sysxplview"
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest;

      let respInfo = null;
      respInfo = await this.reqCustomHttpCall(customRequest);
      return respInfo;
    }

  async updateFavourite(formCode: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.system.UpdateFavourite,
      hostPath: environment.hostPath,
      queryParams: {
        favFormCode: formCode ? formCode : undefined
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async getSysCultureList(subscriptionId: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.system.GetSysCultureList,
      hostPath: environment.hostPath,
      headers: {
        subscriptionId: subscriptionId
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  async getSysLanguageList(subscriptionId: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.system.GetSysLanguageList,
      hostPath: environment.hostPath,
      headers: {
        subscriptionId: subscriptionId
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }
}
