import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CountryService } from './api/country.service';
import { CurrencyService } from './api/currency.service';
import { CustomerService } from './api/customer.service';
import { CustomerCatg1Service } from './api/customerCatg1.service';
import { CustomerCatg2Service } from './api/customerCatg2.service';
import { CustomerCatg3Service } from './api/customerCatg3.service';
import { DemoService } from './api/demo.service';
import { GenderService } from './api/gender.service';
import { JobService } from './api/job.service';
import { LocCategoryService } from './api/locCategory.service';
import { LocCategory2Service } from './api/locCategory2.service';
import { LocCategory3Service } from './api/locCategory3.service';
import { LocGroupService } from './api/locGroup.service';
import { LocationService } from './api/location.service';
import { NotificationService } from './api/notification.service';
import { OrderPlatformService } from './api/orderPlatform.service';
import { PosOrderTypeService } from './api/posOrderType.service';
import { PosPromotionHService } from './api/posPromotionH.service';
import { PosTenderService } from './api/posTender.service';
import { PosTerminalService } from './api/posTerminal.service';
import { PosUserService } from './api/posUser.service';
import { ProdBrandService } from './api/prodBrand.service';
import { ProdBrandGroupService } from './api/prodBrandGroup.service';
import { ProdCategoryService } from './api/prodCategory.service';
import { ProdCategory2Service } from './api/prodCategory2.service';
import { ProdCategory3Service } from './api/prodCategory3.service';
import { ProdClassService } from './api/prodClass.service';
import { ProdColorService } from './api/prodColor.service';
import { ProdDepartmentService } from './api/prodDepartment.service';
import { ProdItemService } from './api/prodItem.service';
import { ProdProductGroupService } from './api/prodProductGroup.service';
import { ProdSeasonService } from './api/prodSeason.service';
import { ProdSizeService } from './api/prodSize.service';
import { ProdSizeGroupService } from './api/prodSizeGroup.service';
import { RMSSalesReportService } from './api/rMSSalesReport.service';
import { RaceService } from './api/race.service';
import { ReportViewerService } from './api/reportViewer.service';
import { SellPriceTypeService } from './api/sellPriceType.service';
import { StkReqService } from './api/stkReq.service';
import { SubscriptionService } from './api/subscription.service';
import { SubscriptionUserService } from './api/subscriptionUser.service';
import { SysFormSetService } from './api/sysFormSet.service';
import { SystemService } from './api/system.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CountryService,
    CurrencyService,
    CustomerService,
    CustomerCatg1Service,
    CustomerCatg2Service,
    CustomerCatg3Service,
    DemoService,
    GenderService,
    JobService,
    LocCategoryService,
    LocCategory2Service,
    LocCategory3Service,
    LocGroupService,
    LocationService,
    NotificationService,
    OrderPlatformService,
    PosOrderTypeService,
    PosPromotionHService,
    PosTenderService,
    PosTerminalService,
    PosUserService,
    ProdBrandService,
    ProdBrandGroupService,
    ProdCategoryService,
    ProdCategory2Service,
    ProdCategory3Service,
    ProdClassService,
    ProdColorService,
    ProdDepartmentService,
    ProdItemService,
    ProdProductGroupService,
    ProdSeasonService,
    ProdSizeService,
    ProdSizeGroupService,
    RMSSalesReportService,
    RaceService,
    ReportViewerService,
    SellPriceTypeService,
    StkReqService,
    SubscriptionService,
    SubscriptionUserService,
    SysFormSetService,
    SystemService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
