import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ListingComponent } from "../listing/listing.component";
import moment from 'moment';
import { ColDef, ColumnState, ExcelExportParams, ExcelRow, ExcelStyle, GridApi, GridReadyEvent, IDetailCellRendererParams, ProcessRowGroupForExportParams, RowGroupingDisplayType, SideBarDef, StatusPanelDef } from 'ag-grid-community';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { ActionToolBarResp } from '../action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe, Location, NgClass, NgIf, NgStyle } from '@angular/common';
import { ReportViewerService } from '../services/report-viewer.service';
import { TotalRecordRendererComponent } from '../ag-grid/total-record-renderer/total-record-renderer.component';
import { PaginatorRendererComponent } from '../ag-grid/paginator-renderer/paginator-renderer.component';
import { LoaderService } from '../../services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InputTextComponent } from '../input/input-text/input-text.component';
import { FormService } from '../form/form.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormContent } from '../form/model/FormContent.model';
import { InputTypes } from '../input/enum/InputTypes';
import { CustomFilterComponent } from '../filter/custom-filter/custom-filter.component';
import { exportPdfSetting } from '../../models/exportPdfSetting.model';
import { Page } from '../../models/page.model';
import { ReportService } from '../../services/report.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PopupForm } from '../../models/popupForm.model';
import { PopupFormService } from '../../services/popup-form.service';
import { FormCode } from '../../enums/formCode';
import { ReportSettingEntryComponent } from '../reportSetting/report-setting-entry/report-setting-entry.component';
import { ReportSettingListingComponent } from '../reportSetting/report-setting-listing/report-setting-listing.component';
import { Subject, Subscription } from 'rxjs';
import { SysFormSetService } from '../../services/sys-form-set.service';
import { FilterGroupStatementComponent } from '../filter-group/filter-group-statement/filter-group-statement.component';
import { ExportService } from '../../services/export.service';
import { exportType } from '../../enums/exportType';
import { pageOrientation } from '../../enums/pageOrientation';
import { ActionToolbarService } from '../services/action-toolbar.service';
import { Filter, FormSetHWithSchedulerRequest, ReportDataRequest, SysFormSetHResponse } from 'src/swagger/base-rms/openapi';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { JobStatusComponent } from '../job-status/job-status.component';
import { StrucConvertService } from '../../services/struc-convert.service';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { PageService } from '../../services/page.service';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { ErrorPageService } from '../../services/error-page.service';
import * as _ from 'lodash';
import { agGridDataType } from '../../enums/agGridDataType';
import { takeUntil } from 'rxjs/operators';
import { DetailCellRendererComponent } from '../ag-grid/detail-cell-renderer/detail-cell-renderer.component';
import { style } from '@angular/animations';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
  standalone: true,
  imports: [
    ListingComponent,
    ActionToolBarComponent,
    AgGridModule,
    TranslateModule,
    NgIf,
    InputTextComponent,
    NgClass,
    ReactiveFormsModule,
    DatePipe,
    CustomFilterComponent,
    MatToolbarModule,
    FilterGroupStatementComponent,
    LottieModule,
    NgStyle
  ],
})
export class ReportViewerComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() page: Page;
  @Input() formCode: string;
  @Input() isReportMode: boolean = false;
  @Input() filterGrpData: any;
  @Input() selectionGroups: any;
  @Input() selectedGroups: any;
  @Input() filterItems: any;
  @Input() rptViewerData: any;
  @Input() defaultFilter: any;
  @Input() default: any;
  @Output() onGridApi = new EventEmitter<any>();
  @Output() onPageSizeChange = new EventEmitter<number>();

  @ViewChild('loader') loaderEl: ElementRef;
  @ViewChild('filterGroupState') filterGroupState: FilterGroupStatementComponent
  @ViewChild('')

  getRptSetting$: Subscription;
  updateRptSetting$: Subscription;
  resetRptSetting$: Subscription;
  export$: Subscription;
  quitRptEditMode$: Subscription;
  quitRptViewer$: Subscription;
  routeQueryParam$: Subscription;
  destroy$ = new Subject<void>();

  private gridApi!: GridApi;
  isReport: boolean = true;
  rowData: any;
  reportData: any;
  reportViewerData: any;
  reportSettingData: SysFormSetHResponse;
  rptGenerateFilter: Filter;
  rptSetFilter: any;
  countBy: number;
  generateRptResp: any;
  generateRptStatus: string;
  activeRptNo: number;
  getRowHeight: any;
  filterHeight: string;
  colDefs: any[] = [];
  context: any;
  timer: number;
  parameters: any;
  accessCode: string;
  formGrp: FormGroup = null;
  requiredAccessCode: boolean;
  rptSettingEditMode: boolean = false;
  errorCode: number;
  fileName: string;
  queryString: any;
  rptInterval: any;
  timeInterval: number = 30000;
  reportViewerFilter: any;
  resetFilter: boolean;
  filters: any;
  transitionDuration: string;
  isLogin: boolean;
  rptJobFilter: any;
  filterSetting: boolean = false;
  rptGeneratedTime: any;
  rptSetSavedState: { state: ColumnState[], pivot: boolean };

  defaultColDef: ColDef = {
    flex: 1,
    enableValue: true,
    enablePivot: true,
    enableRowGroup: true,
    useValueFormatterForExport: true,
  }

  filterGrpActionToolbar: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'generate', text: 'toolbar.generate_report', icon: 'assets/base/icons/table.svg', sortNo: 1 },
      { name: 'reset', text: 'general.reset_filter', icon: 'assets/base/icons/filter.svg', sortNo: 2 },
      {
        name: 'report_setting_dropdown', text: 'toolbar.default_settings', sortNo: 3, dropdownItm: [
          { name: 'report_setting', text: 'toolbar.report_settings', sortNo: 1 },
          { name: 'load_report_setting', text: 'toolbar.load_settings', icon: 'assets/base/icons/upload.svg', sortNo: 2 },
        ]
      }
    ],
    position: ActionToolbarPosition.RIGHT,
  };

  tableActionToolbar: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', icon: 'assets/base/icons/filter.svg', input: true, clickable: false, sortNo: 2, position: ActionToolbarPosition.LEFT, hide: true },
      { name: 'reset_table', text: 'general.reset_table', icon: 'assets/base/icons/revert.svg', sortNo: 1, hide: true },
      { name: 'export', text: 'toolbar.export', icon: 'assets/base/icons/export.svg', sortNo: 5, hide: true },
    ],
    position: ActionToolbarPosition.RIGHT,
  };

  autoGroupColumnDef: any = {
    pinned: 'left',
    cellRendererParams: {
      suppressCount: true,
      footerValueGetter: params => {
        const isRootLevel = params.node.level === -1;
        if (isRootLevel) {
          return 'Grand Total:';
        }
        return `Subtotal:`;
      },
    }
  };

  accessCodeInput: FormContent = {
    inputText: {
      name: 'accessCode',
      label: "Access Code",
      required: true,
      type: InputTypes.password,
      styling: {
        width: '100%'
      },
    }
  };

  frameworkComponents: any;
  detailCellRendererParams: any;
  defaultExcelExportParams: ExcelExportParams;

  // display each row grouping in group rows
  groupDisplayType: RowGroupingDisplayType = 'groupRows';

  // default grouping expand
  groupDefaultExpanded: number = -1;

  // display group panel
  rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'onlyWhenGrouping';

  // pagination panel
  statusBar: { statusPanels: StatusPanelDef[]; } = {
    statusPanels: [
      {
        statusPanel: TotalRecordRendererComponent,
        align: 'left'
      }
    ],
  };

  sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {},
      },
    ],
    defaultToolPanel: 'columns',
  }

  // export excel
  excelStyles: ExcelStyle[] = [
    {
      id: 'header',
      font: {
        bold: true
      }
    },
    {
      id: 'subHeader',
      interior: {
        color: "#aaaaaa",
        pattern: "Solid",
      },
    },
    {
      id: "body",
      interior: {
        color: "#dddddd",
        pattern: "Solid",
      },
    },
    {
      id: 'dateType',
      numberFormat: {
        format: 'dd/mm/yyyy',
      },
    },
    {
      id: 'decimalType',
      numberFormat: {
        format: '0.000',
      },
    },
    {
      id: 'percentageType',
      numberFormat: {
        format: '0.00%',
      },
    }
  ]

  // loading icons
  iconOptions: AnimationOptions = {
    path: '/assets/base/animations/rpt-loading-animation.json',
    loop: true,
    autoplay: true
  }

  // auto sizing
  autoSizeStrategy: any = {
    type: 'fitCellContents'
  }

  constructor(
    private cd: ChangeDetectorRef,
    public translateService: TranslateService,
    private reportViewerService: ReportViewerService,
    private loaderService: LoaderService,
    private formService: FormService,
    private exportService: ExportService,
    private router: Router,
    private reportService: ReportService,
    private popupFormService: PopupFormService,
    private sysFormSetService: SysFormSetService,
    private actionToolbarService: ActionToolbarService,
    private dialog: MatDialog,
    private filterStrucConvertService: StrucConvertService,
    private renderer: Renderer2,
    private pageService: PageService,
    private route: ActivatedRoute,
    private errorPageService: ErrorPageService,
    private location: Location
  ) {

    this.context = { componentParent: this };
  }

  async ngOnInit() {
    this.default = this.defaultFilter;
    this.isLogin = this.pageService.isLogin;

    // components
    this.frameworkComponents = {
      totalRecordRenderer: TotalRecordRendererComponent,
      paginatorRenderer: PaginatorRendererComponent,
      detailCellRenderer: DetailCellRendererComponent
    }

    // get default report setting
    if (this.isReportMode) {
      let defaultRptSet = await this.sysFormSetService.getUserDefaultReportSetting(this.formCode);
      if (!(defaultRptSet instanceof HttpErrorResponse) && defaultRptSet) {
        if (defaultRptSet?.status !== 204) {
          this.reportSettingData = defaultRptSet;
          this.setRptSettingData(defaultRptSet);
        }
      }
    }

    // get report setting
    this.getRptSetting$ = this.reportService.getRptSetting$.pipe(takeUntil(this.destroy$)).subscribe(async (setId: number) => {
      if (setId) {
        this.loaderService.startLoading();
        let result: SysFormSetHResponse = await this.sysFormSetService.getReportSetting(this.formCode, setId);
        this.loaderService.stopLoading();

        if (!(result instanceof HttpErrorResponse)) {
          this.stopGenerateRpt();
          this.setRptSettingData(result);
        }
      }
    });

    // update report setting desc / name
    this.updateRptSetting$ = this.popupFormService.closeById$.subscribe((data: any) => {
      if (data?.data?.setDesc) {
        this.filterGrpActionToolbar = this.actionToolbarService.setText(this.filterGrpActionToolbar, 'report_setting_dropdown', data.data.setDesc);
      }
    });

    // reset report setting when delete
    this.resetRptSetting$ = this.reportService.resetRptSetting$.subscribe((setId: number) => {
      this.reset();
      this.resetParam();
    });

    // handling report viewer
    if (!this.isReportMode) {
      this.filterGrpActionToolbar = this.actionToolbarService.hide(this.filterGrpActionToolbar, ['generate', 'reset', 'report_setting_dropdown']);
    }

    // handling report from job status
    if (this.isReportMode && this.isLogin && this.page?.queryParams?.parameter) {
      this.loaderService.startLoading('msg.loading_report', '', 1);
      this.reset();
      let jobId = decodeBase64(this.page.queryParams.parameter);
      this.getReportResult(+jobId);
    }

    this.routeQueryParam$ = this.pageService.routeQueryParam$.subscribe(page => {
      if (this.page?.form?.formCode === page?.form?.formCode && this.isReportMode && this.isLogin && page?.queryParams?.parameter) {
        this.page = page;
        let jobId = decodeBase64(this.page.queryParams.parameter);
        this.loaderService.startLoading('msg.loading_report', '', 1);
        this.getReportResult(+jobId);
      }
    });

    // handling form controls
    this.formGrp = this.formService.generateFormGroup([this.accessCodeInput]);

    // Get previous submitted reports
    let localLastSubmittedRpt: any = localStorage.getItem('lastSubmittedRpt');
    if (localLastSubmittedRpt && localLastSubmittedRpt.formcode === this.formCode && this.isReportMode) {
      this.generateRptStatus = 'report.status_checking';
      this.generateRptResp = JSON.parse(localLastSubmittedRpt);

      // Get active reports no
      await this.getPreviousGeneratedReports();
      await this.getReportResult(this.generateRptResp?.jobId);
    }

    // validate and remove expired saved state
    if (localStorage.getItem('rptSavedState')) {
      let savedStates = JSON.parse(localStorage.getItem('rptSavedState'));
      let validSavedStates = savedStates.filter(state => new Date() <= new Date(state.expiryDate));

      if (validSavedStates) {
        localStorage.setItem('rptSavedState', JSON.stringify(validSavedStates));
      } else {
        localStorage.removeItem('rptSavedState');
      }
    }
  }

  ngAfterViewInit(): void {
    if (document.getElementById('filter') && document.getElementById('toolbar')) {
      this.filterHeight = document.getElementById('filter').offsetHeight + document.getElementById('toolbar').offsetHeight + 'px';
      this.cd.detectChanges();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.rptViewerData) {
      this.reportViewerData = changes.rptViewerData.currentValue;
      await this.setRptData(this.reportViewerData);
      if (this.reportViewerData) {
        this.rptGeneratedTime = this.reportViewerData?.reportGeneratedTime;
      }
      this.loaderService.stopLoading();
    }
  }

  setRptSettingData(rptSetData: SysFormSetHResponse) {
    this.reportSettingData = rptSetData;
    this.rptSettingEditMode = true;
    let setData = rptSetData?.setData ? JSON.parse(rptSetData.setData) : null;
    this.rptSetFilter = setData?.filter ? setData.filter : null;
    this.selectedGroups = setData?.group ? setData.group : setData?.grouping ? setData.grouping : null;
    this.countBy = setData?.countBy ? setData.countBy : 0;
    this.rptSetSavedState = setData?.colState ? setData.colState : null;

    const structureMapping = {
      condition: 'ruleCondition',
      rules: 'rules',
    };

    let newSetFilter = this.filterStrucConvertService.convertArrayStructure(this.rptSetFilter, structureMapping);
    this.defaultFilter = this.filterStrucConvertService.reverseNewStructure(newSetFilter[0], this.filterItems);
    this.filterGrpActionToolbar = this.actionToolbarService.setText(this.filterGrpActionToolbar, 'report_setting_dropdown', this.reportSettingData?.setDesc);
  }

  async setRptData(rptData: any) {
    if (!rptData) {
      this.reset(false);
      this.loaderService.stopLoading();
      return null;
    } else {
      this.rptGeneratedTime = rptData?.reportGeneratedTime;
      this.rptSetSavedState = rptData?.request?.colState? JSON.parse(rptData?.request?.colState): null;
      this.countBy = rptData?.request?.option;
      let rptJob = rptData?.request?.filter?.filters;
      const additionalParam = rptData?.request?.additionalParameter;
      const dateTitle = JSON.parse(additionalParam)

      const structureMapping = {
        ruleCondition: 'logic',
        rules: 'filters',
      };

      this.rptJobFilter = this.filterStrucConvertService.convertArrayStructure(this.filterStrucConvertService.combineMultiple
        (this.filterStrucConvertService.bindDateTitle(this.filterStrucConvertService.convertJobRequest(rptJob, this.filterItems), dateTitle), this.filterItems), structureMapping);
    }
    this.reportData = rptData;
    let grpItems = this.filterGrpData?.groupItems;

    // Set export params
    this.fileName = rptData?.reportTitle && rptData?.reportGeneratedTime 
    ? (this.translateService.instant(rptData?.reportTitle) + ' ' + moment(rptData?.reportGeneratedTime).format("DD-MMM-YYYY")) 
    : ('reporting ' + moment(new Date).format("DD-MMM-YYYY"));

    this.defaultExcelExportParams = {
      fileName: this.fileName,
      skipColumnGroupHeaders: true,
      rowHeight: 35,
      headerRowHeight: 20,
      prependContent: this.excelPrependRow(),
      processCellCallback(params) {
        if(params?.node?.group && !params?.node?.footer && params.column.getColId() !== "ag-Grid-AutoColumn") {
          return null;
        }
        const value = params.value;
        return value === undefined ? '' : `${params.formatValue(params.value)}`
      },
      processRowGroupCallback(params) {
        let colDef: any = params.api.getColumnDef(params.node.field);
        let formatParams = { value: params.node.key, data: params?.node?.data, node: params?.node, colDef: colDef, column: params?.api?.getColumn(params.node.field), api: params?.api, columnApi: params?.columnApi, context: params?.context };
        let value = colDef?.valueFormatter(formatParams)? colDef?.valueFormatter(formatParams): params.node.key;

        if(params.node.footer) {
          return value? `Subtotal: ${value}`: `Grand Total:`
        } else {
          return value? `${value}`: null
        }
      }
    };

    // format group items to one layer
    let rptGroups = [];

    rptData?.request?.group?.forEach((rpt: any) => {
      let groupItm = grpItems?.find(itm => itm.groupId === rpt.groupId);
      rpt?.fields.forEach(field => {
        let itmField = groupItm?.fields?.find(rptField => rptField.fieldNames === field.fieldNames);
        rptGroups.push({
          ...itmField,
          groupId: rpt.groupId,
          field: field?.field,
          grouped: itmField.grouped && rpt.showTopN,
          pinned: itmField.grouped && rpt.isDisabled,
        });
      });
    });

    await this.reportService.getUnzipFiles(rptData.reportData).then(data => {
      this.stopGenerateRpt();
      this.generateRptStatus = 'report.status_done';
      let colDefs = [];

      // form coldefs of columns
      if (data[0]) {
        let firstData = _.cloneDeep(data[0]);

        // sort group to top and set sub grid
        let grpDataArr: any[] = [];
        let grpData: any;
        let sortedData: any;
        Object.keys(firstData).forEach(dt => {
          if (dt.includes('Grp')) {
            grpDataArr.push({ [dt]: firstData[dt] });
            delete firstData[dt];
          } else if(dt.includes('Json')) {
            // sub grid
            let columns = firstData[dt]? JSON.parse(firstData[dt]): null;
            let keys = columns && columns[0]? Object.keys(columns[0]): [];
            let colDefs: any[] = [];

            // sub grid col def
            keys.forEach(key => {
              let colData;
              colData = this.filterGrpData?.columns.find(col => col.field === key);

              colDefs.push({
                field: key,
                headerName: colData?.title ? this.translateService.instant(colData.title) : key,
                valueFormatter: (params) => {
                  return params.value || params.value === 0 ? this.reportViewerService.formatData(colData.type, params.value, colData?.unit, colData?.template) : params.value;
                },
                filterValueGetter: params => {
                  return params.data[colData?.field] || params.data[colData?.field] === 0 ? this.reportViewerService.formatData(colData.type, params.data[colData?.field], colData?.unit, colData?.template) : params.data[colData?.field];
                },
                cellClass: this.getCellClass(colData.type),
                cellStyle: params => {
                  let formatParams = { value: params.value, data: params?.node?.data, node: params?.node, colDef: params?.colDef, column: params?.api?.getColumn(params.column.colId), api: params?.api, columnApi: params?.columnApi, context: params?.context };
                  let value = params?.colDef?.valueFormatter(formatParams);
                  return +value || +value === 0 || colData?.type === agGridDataType.percentage? { 'text-align': 'right' } : null;
                },
                pdfExportOptions: {
                  params: colData,
                  styles: {
                    alignment: colData.type !== 'string' && colData.type !== 'date' ? 'right' : 'left'
                  }
                }
              })
            });

            // sub grid setting
            this.detailCellRendererParams = {
              detailGridOptions: {
                columnDefs: colDefs,
                suppressAutoSize: true
              },
              getDetailRowData: (params) => {
                let data = params?.data[dt]? JSON.parse(params.data[dt]): null;
                params.successCallback(data);
              },
            } as IDetailCellRendererParams;

            // sub grid export to excel
            this.defaultExcelExportParams = {
              ...this.defaultExcelExportParams,
              getCustomContentBelowRow: (params: ProcessRowGroupForExportParams) => {
                let rows: any[];

                if(params?.node?.data && params?.node?.data[dt]) {
                  rows = [
                    {
                      outlineLevel: 1,
                      cells: colDefs.map(colDef => {return this.exportService.getCellToExport(colDef.headerName, "subHeader")})
                    },
                  ].concat([
                    ...JSON.parse(params?.node?.data[dt]).map((record: any) => {
                      let keys = Object.keys(record);
                      let cells = [];
  
                      cells = [...cells, ...keys.map(key => this.exportService.getCellToExport(record[key], "body"))];
  
                      return {
                        outlineLevel: 1,
                        cells: cells
                      };
                    })
                  ]);
                }

                return rows as ExcelRow[]
              },
            };
          }
        });

        grpData = Object.assign({}, ...grpDataArr);

        // sort columns
        let colFields = Object.keys(firstData);
        let colDataArr: any[] = [];
        let colData: any;

        this.filterGrpData?.columns.forEach(col => {
          if (colFields.includes(col.field)) {
            colDataArr.push({ [col.field]: firstData[col.field] });
          }
        });

        colData = Object.assign({}, ...colDataArr);
        sortedData = { ...grpData, ...colData };

        Object.keys(sortedData).forEach((key, index) => {
          let grpItm = rptGroups.find(grp => grp.field === key);
          let colItm = !grpItm ? this.filterGrpData?.columns?.find(filterGrp => filterGrp?.field === key) : null;

          if (colItm || grpItm) {
            let colData = grpItm ? grpItm : colItm;
            colDefs.push(this.setColDef(colData, grpItm));
          }
        });
      }

      // assign group name
      this.selectedGroups = rptData?.request?.group?.map(grp => {
        let grpItm = this.filterGrpData.groupItems.find(itm => itm.groupId === grp.groupId);
        return {
          groupName: grpItm?.groupName ? grpItm.groupName : grp.groupId,
          ...grp,
        }
      });

      let setData = rptData?.formSetData ? JSON.parse(rptData.formSetData) : null;
      this.reportViewerFilter = setData?.filter ? setData.filter : null;
      this.colDefs = colDefs;
      this.rowData = data;

      this.tableActionToolbar = this.actionToolbarService.show(this.tableActionToolbar, ['export', 'filter', 'reset_table']);
    });
  }

  setColDef(colData: any, grpItm?: any) {
    let colDef: any;

    colDef = {
      field: colData.field,
      headerName: colData?.title ? this.translateService.instant(colData.title) : colData.key,
      rowGroup: colData.grouped ? colData.grouped : false,
      hide: colData.grouped || colData.pinned? true: colData.hidden,
      // pinned: colData.pinned,
      aggFunc: colData?.aggregates && colData?.aggregates[0] ? colData?.aggregates[0] : undefined,
      valueFormatter: params => {
        if (colData?.formula) {
          if(grpItm) {
            colData.formula = colData.formula.replace(grpItm.groupId, colData.key);
          }
          let calVal = this.reportViewerService.calculateFormula(colData?.formula, params.data, params.api);
          params.value = calVal? calVal: params.value;
        }
        return params.value || params.value === 0 ? this.reportViewerService.formatData(colData.type, params.value, colData?.unit, colData?.template) : params.value;
      },
      filterValueGetter: params => {
        return params.data[colData?.field] || params.data[colData?.field] === 0 ? this.reportViewerService.formatData(colData.type, params.data[colData?.field], colData?.unit, colData?.template) : params.data[colData?.field];
      },
      cellClass: this.getCellClass(colData.type),
      cellStyle: params => {
        let formatParams = { value: params.value, data: params?.node?.data, node: params?.node, colDef: params?.colDef, column: params?.api?.getColumn(params.column.colId), api: params?.api, columnApi: params?.columnApi, context: params?.context };
        let value = params?.colDef?.valueFormatter(formatParams);
        return +value || +value === 0 || colData?.type === agGridDataType.percentage? { 'text-align': 'right' } : null;
      },
      pdfExportOptions: {
        params: colData,
        styles: {
          alignment: colData.type !== 'string' && colData.type !== 'date' ? 'right' : 'left'
        }
      }
    }

    return colDef;
  }

  getTotalColumnValue(gridApi: GridApi, columnField: string) {
    let total = 0;
  
    // Iterate over each row node
    gridApi.forEachNode((node) => {
      // Add the value of the specified column to the total
      total += node.data[columnField];
    });
  
    return total;
  }

  getCellClass(type: any) {
    if (type === agGridDataType.date) {
      return 'dateType'
    } else if (type === agGridDataType.decimal) {
      return 'decimalType'
    } else if (type === agGridDataType.percentage) {
      return 'percentageType'
    }
    return ''
  }

  exportExcel() {
    this.exportService.exportExcel(this.gridApi);
  }

  exportPdf() {
    let setting: exportPdfSetting = {
      fileName: this.fileName,
      orientation: pageOrientation.landscape,
      title: this.reportData && this.reportData?.reportTitle ? this.translateService.instant('report.' + this.reportData.reportTitle) : '',
      generatedDateTime: this.rptGeneratedTime ? this.rptGeneratedTime : '',
      user: this.reportData && this.reportData?.userName ? this.reportData?.userName : '',
      filter: this.queryString ? this.queryString : '',
      tableStyle: {
        fontSize: 8
      }
    }

    this.exportService.exportPDF(this.gridApi, setting);
  }

  excelPrependRow() {
    const datePipe = new DatePipe('en-US');

    let content: ExcelRow[] = [
      {
        cells: [{
          data: {
            value: this.reportData ? this.translateService.instant('report.title', { reportName: this.translateService.instant("report." + this.reportData?.reportTitle), date: datePipe.transform(this.reportData?.reportGeneratedTime, 'mediumDate') }) : '',
            type: "String"
          },
          styleId: 'header'
        }]
      },
      {
        cells: [{
          data: {
            value: this.reportData ? this.translateService.instant('report.generated_by', { user: this.reportData?.userName }) : '',
            type: "String"
          }
        }]
      },
      {
        cells: [{
          data: {
            value: this.reportData ? this.translateService.instant('report.generated_on', { dateTime: datePipe.transform(this.reportData?.reportGeneratedTime, 'dd/MM/yyyy, h:mma') }) : '',
            type: "String"
          }
        }]
      },
      {
        cells: [{
          data: {
            value: this.reportData ? this.translateService.instant('report.filters', {
              filters: this.queryString ? this.queryString : '',
            }) : '',
            type: "String"
          }
        }]
      },
      { cells: [] }
    ];

    return content;
  }

  reset(stopGenerateRpt: boolean = true) {
    this.filterGroupState?.reset();
    this.clearGenerateInterval();

    if (stopGenerateRpt) {
      this.stopGenerateRpt();
    }
    this.rptSettingEditMode = false;
    this.selectedGroups = this.filterGrpData?.group ? this.filterGrpData.group : null;
    this.selectionGroups = this.filterGrpData?.groupItems ? _.cloneDeep(this.filterGrpData.groupItems) : null;
    this.countBy = this.filterGrpData?.countBy ? this.filterGrpData?.countBy : 0;
    this.rptSetSavedState = null;
    this.filterGrpActionToolbar = this.actionToolbarService.setText(this.filterGrpActionToolbar, 'report_setting_dropdown', 'toolbar.default_settings');
    this.filterGrpActionToolbar = this.actionToolbarService.enable(this.filterGrpActionToolbar, ['generate', 'reset', 'report_setting_dropdown']);
    this.tableActionToolbar = this.actionToolbarService.hide(this.tableActionToolbar, ['export', 'filter', 'reset_table']);
    this.filterGrpActionToolbar = this.actionToolbarService.show(this.filterGrpActionToolbar, ['generate', 'reset', 'report_setting_dropdown']);
    this.rowData = null;
    this.reportSettingData = null;
    this.cd.detectChanges();
  }

  resetState() {
    this.resetSavedState();
    this.gridApi?.setGridOption("pivotMode", false);

    if (this.reportData?.request?.colState) {
      let restoreState = JSON.parse(this.reportData.request.colState);
      this.gridApi?.applyColumnState({ state: restoreState?.state, applyOrder: true });
      this.gridApi?.setGridOption("pivotMode", restoreState?.pivot);
    } else {
      this.gridApi?.resetColumnState();
    }
  }

  resetParam() {
    const currentUrl = this.location.path();
    const urlWithoutParameter = currentUrl.split('?')[0];
    this.location.replaceState(urlWithoutParameter);
  }

  clearGenerateInterval() {
    if (this.rptInterval) {
      clearInterval(this.rptInterval);
      this.rptInterval = null;
    }
  }

  stopGenerateRpt() {
    this.clearGenerateInterval();
    this.setRptLoader();
    if (this.isReportMode && localStorage.getItem('lastSubmittedRpt')) {
      this.generateRptResp = null;
      localStorage.removeItem('lastSubmittedRpt');
    }
  }

  resetSavedState() {
    let rptSavedStates = localStorage.getItem('rptSavedState') ? JSON.parse(localStorage.getItem('rptSavedState')) : [];
    rptSavedStates?.forEach((state: any, index: number) => {
      if (this.formCode === state.formCode && this.reportData?.jobId === state.jobId) {
        rptSavedStates.splice(rptSavedStates[index], 1);
      }
    });
    localStorage.setItem('rptSavedState', JSON.stringify(rptSavedStates));
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.onGridApi.emit(this.gridApi);

    if (document.getElementById('filter') && document.getElementById('toolbar')) {
      this.filterHeight = document.getElementById('filter').offsetHeight + document.getElementById('toolbar').offsetHeight + 'px';
      this.cd.detectChanges();
    }

    // restore report state
    let rptSavedStates = localStorage.getItem('rptSavedState') ? JSON.parse(localStorage.getItem('rptSavedState')) : [];
    let rptSavedState = rptSavedStates?.find(state => this.formCode === state.formCode && this.reportData?.jobId === state.jobId);
    let restoreState = rptSavedState ? rptSavedState : this.rptSetSavedState;

    if (restoreState) {
      this.gridApi.applyColumnState({ state: restoreState.state, applyOrder: true });
      this.gridApi.setGridOption("pivotMode", restoreState.pivot);
    }

    // handling export
    this.export$ = this.exportService.export$.subscribe((exportData: any) => {
      if (exportData.type === exportType.excel) {
        this.exportExcel();
      } else if (exportData.type === exportType.pdf) {
        this.exportPdf();
      }
      this.exportService.closeSelection();
    });
  }

  onSetFilterGrp(event: any) {
    // Event when apply filter and group
    if (event?.selectedGroups && event.selectedGroups.length > 0) {
      this.filterSetting = true;
      this.stopGenerateRpt();
      this.selectedGroups = event.selectedGroups;
      this.rptSetFilter = event.filterSetting;
      this.rptGenerateFilter = event.filterStructure;
      this.countBy = event.countBy;
    }
    if (event?.generate) {
      this.onGenerateReport();
    }
  }

  onQueryString(event) {
    this.queryString = event;
  }

  onActionToolbar(value: ActionToolBarResp) {
    if (!this.isReportMode && value.name !== 'export' && value.name !== 'filter' && value.name !== 'reset_table') {
      this.reportViewerService.showQuitRptViewer();
      this.actionToolbarService.disable(this.filterGrpActionToolbar, ['report_setting_dropdown']);
      return null;
    }

    if (value.name === 'filter') {
      if (value.data) {
        this.gridApi.setGridOption("quickFilterText", value.data);
      } else {
        this.gridApi.setGridOption("quickFilterText", null);
      }
    } else if (value.name === 'report_setting') {
      let popupForm: PopupForm = {
        id: 'reportSetting',
        formCode: this.formCode,
        componentName: ReportSettingEntryComponent,
        fullScreen: true,
        position: ActionToolbarPosition.CENTER,
        params: this.rptSettingEditMode ? ['setId'] : null,
        deleteBtn: this.rptSettingEditMode && this.reportSettingData?.updateRight,
        disableClose: true,
        title: 'report.report_setting'
      }

      let filter;

      if (!this.rptSetFilter) {
        if (this.rptJobFilter) {
          filter = this.rptJobFilter;
        } else {
          filter = this.filterStrucConvertService.reverseNewStructure(this.filterGrpData.rules, this.filterItems)
        }
      } else {
        filter = this.rptSetFilter
      }

      let setData: any = {
        filter: filter,
        group: this.selectedGroups,
        countBy: this.countBy,
        colState: this.rptSetSavedState
      };

      let rptSetReq: FormSetHWithSchedulerRequest = {
        setId: this.reportSettingData?.setId,
        formCode: this.formCode,
        schedulerRptRequest: {
          apiUrl: environment.hostPath + this.filterGrpData?.api,
          request: {
            filter: this.rptGenerateFilter,
            group: this.selectedGroups,
            colState: JSON.stringify(this.rptSetSavedState)
          }
        },
        setData: setData ? JSON.stringify(setData) : '',
      }

      this.popupFormService.open(popupForm, rptSetReq);
    } else if (value.name === 'load_report_setting') {
      let popupForm: PopupForm = {
        id: 'reportSettingList',
        formCode: FormCode.salesReportListing,
        componentName: ReportSettingListingComponent,
        fullScreen: true,
        position: ActionToolbarPosition.CENTER,
        title: 'toolbar.report_settings',
        subTitle: 'report.select_1',
        styling: {
          width: '90%',
          padding: '0'
        }
      }

      let rptSetListReq: any = this.reportSettingData ? this.reportSettingData : { formCode: this.formCode };
      this.popupFormService.open(popupForm, rptSetListReq);
    } else if (value.name === 'generate') {
      this.onGenerateReport();
    } else if (value.name === 'export') {
      this.exportService.openSelection();
    } else if (value.name === 'reset') {
      this.reset();
      this.resetParam();
    } else if(value.name === 'reset_table') {
      this.resetState();
    }
  }

  onClickHome() {
    this.router.navigateByUrl('/dashboard');
  }

  onClickRptFilterGrp() {
    this.reportService.openRptFilterGrp$.next();
  }

  onClickOpenJobQueue() {
    const dialogRef = this.dialog.open(JobStatusComponent, {
      height: '100vh',
      maxWidth: '100vw',
      width: '100vw',
      panelClass: 'fullWidth',
      hasBackdrop: false,
      data: {
        queueName: 'rpt',
        formCode: this.formCode
      }
    });
  }

  onGridStateUpdated(event: any) {
    if (!(event?.sources.includes('gridInitializing') || event?.sources.includes('columnSizing'))) {
      this.updateState();
    }

    if (!(event?.sources.includes('scroll'))) {
      this.gridApi?.autoSizeAllColumns();
    }
  }

  onColRowGrpChged() {
    this.updateState();
  }

  updateState() {
    // set report state
    let curState = this.gridApi.getColumnState();
    let curPivot = this.gridApi.isPivotMode();
    
    this.rptSetSavedState = {
      state: curState,
      pivot: curPivot
    }

    if (curState && curState.length > 0) {
      let rptSavedStates = localStorage.getItem('rptSavedState') ? JSON.parse(localStorage.getItem('rptSavedState')) : [];
      let index = rptSavedStates?.findIndex(state => this.formCode === state.formCode && this.reportData?.jobId === state.jobId);

      if (index >= 0) {
        rptSavedStates[index].state = curState;
        rptSavedStates[index].pivot = curPivot;
        rptSavedStates[index].expiryDate = this.reportData?.expiryDate;
      } else {
        rptSavedStates.push({
          formCode: this.formCode,
          jobId: this.reportData?.jobId,
          pivot: curPivot,
          state: curState,
          expiryDate: this.reportData?.expiryDate
        });
      }

      localStorage.setItem('rptSavedState', JSON.stringify(rptSavedStates));
    }
  }

  async onGenerateReport() {
    if (this.filterGrpData) {
      this.rowData = null;
      this.stopGenerateRpt();
      this.resetParam();
      this.tableActionToolbar = this.actionToolbarService.hide(this.tableActionToolbar, ['export', 'filter', 'reset_table']);

      // assign group id
      if (this.selectedGroups) {
        let count = 0;
        this.selectedGroups.map((group: any) => {
          if (group?.fields) {
            group.fields.map((field: any) => {
              count++;
              field.field = 'Grp' + count;
            });
          }
        });
      }

      // convert default filter to rpt setting filter
      const structureMapping = {
        logic: 'ruleCondition',
        filters: 'rules',
      };

      if (!this.filterSetting) {
        this.rptGenerateFilter = this.filterStrucConvertService.addParent(this.filterStrucConvertService.convertArrayStructure(
          this.filterStrucConvertService.separateMultiple(this.defaultFilter, this.filterItems),
          structureMapping
        ));
      }

      const hasDateTitle = this.filterStrucConvertService.getDateTitleInfo(this.rptGenerateFilter.filters)
      let reportData: ReportDataRequest = {
        filter: this.rptGenerateFilter,
        group: this.selectedGroups,
        additionalParameter: JSON.stringify(hasDateTitle),
        option: this.countBy,
        colState: JSON.stringify(this.rptSetSavedState)
      };

      this.loaderService.startLoading();
      let result = await this.reportService.generateReport(this.filterGrpData?.api, this.formCode, reportData);
      this.loaderService.stopLoading();

      if (!(result instanceof HttpErrorResponse) && result?.jobId) {
        this.generateRptStatus = 'report.status_checking';
        this.generateRptResp = result;

        // Get active reports no
        this.loaderService.startLoading();
        await this.getPreviousGeneratedReports();
        this.loaderService.stopLoading();

        localStorage.setItem('lastSubmittedRpt', JSON.stringify({ formCode: this.formCode, ...this.generateRptResp }));
        this.getReportResult(result.jobId);
      } else if (!(result instanceof HttpErrorResponse) && result[0]) {
        let colDefs = [];
        Object.keys(result[0]).forEach(data => {
          colDefs.push({
            field: data,
            headerName: data,
            rowGroup: data.toLowerCase().includes('grp')
          });
        });

        this.colDefs = colDefs;
        this.rowData = result;
        this.tableActionToolbar = this.actionToolbarService.show(this.tableActionToolbar, ['export', 'filter', 'reset_table']);
      }
    }
  }

  async getReportResult(jobId: number) {
    let rptData = null;

    if (this.formCode && jobId) {
      rptData = await this.reportService.getReportResult(this.formCode, jobId, false);
      if (rptData instanceof HttpErrorResponse === false) {
        this.setRptLoader();
        this.generateRptStatus = 'report.status_retrieve_data';
        await this.setRptData(rptData);
        this.loaderService.stopLoading();
      } else if (rptData?.status === 400) {
        if (this.isReportMode && this.timeInterval && jobId && !this.rptInterval) {
          this.rptInterval = setInterval(() => {
            this.getReportResult(jobId);
          }, this.timeInterval);
        }
      } else if (rptData?.status === 500) {
        this.errorPageService.show(500, rptData.statusText);
      }
    }

    if (this.rptInterval) {
      this.setRptLoader(null, 30);
    }
  }

  async getPreviousGeneratedReports() {
    let result = null;
    let generatedRptResult = await this.reportViewerService.getPreviousGeneratedReports(this.formCode);
    if (!(generatedRptResult instanceof HttpErrorResponse)) {
      result = await new Response(generatedRptResult).json();
      this.activeRptNo = result.length;
    }
    return result
  }

  setRptLoader(percentage?: number, durationInSec?: number) {
    if (this.loaderEl) {
      this.transitionDuration = 'unset';
      if (this.loaderEl.nativeElement.classList.contains('active')) {
        this.renderer.removeClass(this.loaderEl.nativeElement, 'active');
      }
    }

    if (this.loaderEl && (percentage || percentage === 0)) {
      this.renderer.setStyle(this.loaderEl, 'background-position', percentage + "%");
    }

    if (this.loaderEl && durationInSec) {
      setTimeout(() => {
        this.transitionDuration = durationInSec + 's';
        this.renderer.addClass(this.loaderEl.nativeElement, 'active');
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.getRptSetting$?.unsubscribe();
    this.updateRptSetting$?.unsubscribe();
    this.resetRptSetting$?.unsubscribe();
    this.quitRptEditMode$?.unsubscribe();
    this.quitRptViewer$?.unsubscribe();
    this.routeQueryParam$?.unsubscribe();
    this.export$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
