<div class="form-panel" *ngIf="formSetting && formGrp" [ngClass]="{'popup': formSetting.popup}">
  <div class="grid">
    <div class="col-12 form-layout">
      <form class="form-class" [formGroup]="formGrp" (ngSubmit)="onSubmit()">
        <div class="tool-bar-container" *ngIf="requiredFormError || !formSetting.popup">
          <div class="error-msg hidden-sm" *ngIf="requiredFormError && formGrp.invalid">
            <label>{{'msg.required_msg' | translate}}</label>
          </div>
          <app-action-tool-bar [formDetail]="formDetail" [actionToolbarSetting]="actionToolbarSetting"
              *ngIf="!formSetting.popup"
              (onAction)="onActionButton($event);" (onSubmit)="onSubmit()"></app-action-tool-bar>
        </div>
        <div class="form-content formgrid grid">
          <div *ngFor="let content of formSetting.content" [ngClass]="getFormContentClass(content)">
            <app-form-inputs [content]="content" [formData]="formData" [formGrp]="formGrp"
              (onGenerateInput)="onGenerate($event)" (onButtonToggle)="onBtnToggle($event)" (onTimeChange)="onTimeChg($event)"
              (onPeriodChange)="onPeriodChg($event)" (onDateChange)="onDateChg($event)" (onToggleChange)="onToggleChg($event)"></app-form-inputs>

            <!-- Expansion Panel -->
            <ng-template [ngIf]="content.expansionPanel">
              <app-expansion-panel [expansionPanelSetting]="content.expansionPanel" [formGrp]="formGrp" [formData]="formData"
                (onGenerateInput)="onGenerate($event)" (onButtonToggle)="onBtnToggle($event)" (onTimeChange)="onTimeChg($event)"
                (onPeriodChange)="onPeriodChg($event)" (onDateChange)="onDateChg($event)" (onToggleChange)="onToggleChg($event)"></app-expansion-panel>
            </ng-template>
          </div>
          <div class="btn-container" *ngIf="formSetting.popup">
            <app-action-tool-bar [formDetail]="formDetail" [actionToolbarSetting]="actionToolbarSetting"
              (onAction)="onActionButton($event)" (onSubmit)="onSubmit()" (onToggleChange)="onToggleChg($event)"></app-action-tool-bar>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
