<div class="breadcrumb-container">
  <label *ngFor="let page of pages; let i = index" class="breadcrumb-header">
    <label *ngIf="page.pageName" [ngClass]="{'active': page.pageName === currentPage.pageName }" (click)="navigate(page)">{{page.pageName}}</label>
    <div class="seperator-container" *ngIf="pages[i+1]"><span class="dot"></span></div>
    <span *ngIf="!isFavourite(page.form.formCode)">
      <img class="star" src="assets/base/icons/star.svg" *ngIf="i === pages.length - 1" (click)="favouriteItem(page.form.filePath)" />
    </span>
    <span *ngIf="isFavourite(page.form.formCode)">
      <img class="star" src="assets/base/icons/star-solid-orange.svg" *ngIf="i === pages.length - 1" (click)="favouriteItem(page.form.filePath)" />
    </span>
  </label>
</div>


