/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackendChannelStoreTax { 
    channelId?: number;
    storeId?: number;
    taxLine?: number;
    orderTypeList?: string;
    transportModeList?: string;
    effectiveDate?: Date;
    obsoleteDate?: Date;
    taxCondCode: string;
    lastUserId?: string;
    lastRevDate?: Date;
    rowVersion?: string;
}