import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { Page } from 'src/app/shared/models/page.model';
import { UntypedFormGroup } from '@angular/forms';
import { PageService } from 'src/app/shared/services/page.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { LookupCatgAttrOptionsComponent } from 'src/app/cxm-backend-web/lookup/lookup-catg-attr-options/lookup-catg-attr-options.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { BackendStoreCatgAttr } from 'src/swagger/cxmbackend/openapi';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from "src/app/cxm-backend-web/store/store.service";
import { Mode } from 'src/app/shared/enums/mode';

@Component({
  selector: 'app-store-cat-attr-entry',
  standalone: true,
  imports: [CommonModule, FormComponent],
  templateUrl: './store-cat-attr-entry.component.html',
  styleUrls: ['./store-cat-attr-entry.component.scss'],
  providers: [StoreService]
})
export class StoreCatAttrEntryComponent {
  @Input() params = null;
  @Input() page: Page;

  storeId: number = undefined;
  attrOptionId: number = undefined;
  isEditMode: boolean;
  formDetail: any = null;
  catgAttrOptionsListing: any;

  formSetting: FormSetting = {
    formCode: FormCode.storecatgattrentry,
    content: [
      {
        inputNumber: {
          name: 'storeId',
          label: 'store_oh.store_id',
          required: true,
          styling: {
            width: '30%',
          },
          disabled: true
        },
      },
      {
        lookDropInput: {
          componentName: LookupCatgAttrOptionsComponent,
          name: 'attrOptionId',
          label: 'storeCatgAttr.store_catg_Attr_id',
          valueKey: 'attrOptionId',
          hintValueKey: 'attrOptionDesc',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
    ]
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm,
  };

  constructor(
    private pageService: PageService,
    private formService: FormService,
    private dialog: MatDialog,
    private storeService: StoreService,
  ){}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    let params = this.pageService.getParameterDecode(this.page);
    this.storeId = this.params ? this.params['storeId'] : params['storeId'];
    this.attrOptionId = this.params ? this.params['attrOptionId'] : params['attrOptionId'];

    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }else if(this.attrOptionId && (this.attrOptionId != null || this.attrOptionId)){
      this.isEditMode = true;
    }

    if(this.storeId && this.attrOptionId) {
      await this.getStoreCatgAttr(this.storeId, this.attrOptionId);
    }else {
      this.catgAttrOptionsListing = {
        storeId: this.storeId,
      }
    }
  }

  onToolbarAction(value: ActionToolBarResp) {}

  async getStoreCatgAttr(storeId: number, attrOptionId: number){
    let result = await this.storeService.getOdataStoreCatgAttrData(storeId, attrOptionId, this.formDetail.formCode);
    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.catgAttrOptionsListing =result['value'][0];
    }
  }

  async onSubmitForm(formGroup: UntypedFormGroup) {
    if (this.isEditMode) {
      let reqBody: BackendStoreCatgAttr = {
        attrOptionId: formGroup.get('attrOptionId').value,
        oldAttrOptionId: Number(this.attrOptionId),
        storeId: Number(this.storeId),
      }
      this.updateStoreCatgAttr(reqBody);
    } else {
      let reqBody: BackendStoreCatgAttr = {
        attrOptionId: formGroup.get('attrOptionId').value,
        storeId: Number(this.storeId),
      }
      this.addStoreAttr(reqBody);
    }
  }

  async addStoreAttr(reqBody: BackendStoreCatgAttr){
    let result = await this.storeService.addStoreCatgAttr(reqBody, this.formDetail.formCode);
    if(result instanceof (HttpErrorResponse)){
      if(result.status == 409){
        let msg: PopupMessage = {
          formCode: FormCode.storecatgattrlisting,
          id: 'failed',
          titleIcon: 'assets/base/icons/exclamation-circle.svg',
          title: result.error.errorData[0].errorField ? result.error.errorData[0].errorField : 'msg.new_store_catg_attr_unsuccessful',
          desc: result.error.title ? result.error.title : 'msg.duplicate_store_catg_attr',
          actionBtnText: 'popupMsg.closed_msg'
        };

        this.dialog.open(PopupMessageComponent, {
          data: msg
        });
      }
    }else{
      let msg: PopupMessage = {
        formCode: FormCode.storecatgattrlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'storeCatgAttr.store_catg_Attr',
        desc: "msg.new_store_catg_attr_created",
        actionBtnText: 'popupMsg.closed_msg'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
  }

  async updateStoreCatgAttr(reqBody: BackendStoreCatgAttr){
    let result = await this.storeService.updateStoreCatgAttr(reqBody, this.formDetail.formCode);

    if(result instanceof (HttpErrorResponse)){
      if(result.status == 409){
        let msg: PopupMessage = {
          formCode: FormCode.storecatgattrlisting,
          id: 'failed',
          titleIcon: 'assets/base/icons/exclamation-circle.svg',
          title: result.error.errorData[0].errorField ? result.error.errorData[0].errorField : 'msg.new_store_catg_attr_unsuccessful',
          desc: result.error.title ? result.error.title : 'msg.duplicate_store_catg_attr',
          actionBtnText: 'popupMsg.closed_msg'
        };

        this.dialog.open(PopupMessageComponent, {
          data: msg
        });
      }else if(result.status === 404){
        let msg: PopupMessage = {
          formCode: FormCode.storecatgattrlisting,
          id: 'failed',
          titleIcon: 'assets/base/icons/exclamation-circle.svg',
          title: result.error.errorData[0].errorField ? result.error.errorData[0].errorField : 'msg.new_store_catg_attr_unsuccessful',
          desc: result.error.title ? result.error.title : 'msg.duplicate_store_catg_attr',
          actionBtnText: 'popupMsg.closed_msg'
        };

        this.dialog.open(PopupMessageComponent, {
          data: msg
        });
      }
    }else{
      let msg: PopupMessage = {
        formCode: FormCode.storecatgattrlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'storeCatgAttr.store_catg_Attr',
        desc: "msg.update_store_catg_attr_created",
        actionBtnText: 'popupMsg.closed_msg'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
  }

  openChildForm(event) {
    console.log('event: ', event);
  }
}
