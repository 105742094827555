export enum memberType {
    none = 0,
    cuxtomlab = 1,
    odaring = 2,
    chatime = 90,
    claritas = 91,
    woocommerce = 92,
    gohalal = 93,
    fides = 94,
    eber = 95,
    loyalty = 96,
    onecard = 97,
    tada = 98
}