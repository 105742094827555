import { Component, EventEmitter, InjectionToken, Input, OnChanges, Output, SimpleChanges, Type } from '@angular/core';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { ActionToolBarComponent } from '../action-tool-bar/action-tool-bar.component';
import { ActionToolBarSetting } from '../action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from '../../enums/actionToolbarPosition';
import { TranslateModule } from '@ngx-translate/core';
import { ActionToolBarResp } from '../action-tool-bar/model/ActionToolBarResp.model';
import * as _ from 'lodash';
import { DynamicCardComponent } from '../card/dynamic-card/dynamic-card.component';

export const CARD = new InjectionToken<any>(''); 

@Component({
  selector: 'app-card-listing',
  standalone: true,
  imports: [
    CommonModule, 
    ActionToolBarComponent,
    TranslateModule,
    NgComponentOutlet,
    DynamicCardComponent
  ],
  templateUrl: './card-listing.component.html',
  styleUrls: ['./card-listing.component.scss']
})
export class CardListingComponent implements OnChanges {
  @Input() cardComponentName: Type<any>;
  @Input() listData: any;
  @Input() selectedIndex: number[];
  @Output() cardClicked = new EventEmitter<any>();

  cardListingData: any;

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1, position: ActionToolbarPosition.LEFT },
    ]
  };

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.listData?.currentValue) {
      this.cardListingData = changes.listData.currentValue;
    }

    if(changes.selectedIndex?.currentValue) {
      this.selectedIndex = changes.selectedIndex.currentValue;
    }
  }

  onClickCard(data: any) {
    this.cardClicked.emit(data);
  }

  onToolbarAction(event: ActionToolBarResp) {
    if(event.name === 'filter') {
      if(event.data) {
        let filteredData = _.cloneDeep(this.listData).filter((data: any) => data.setDesc.toLowerCase().includes(event.data.toLowerCase()));
        this.cardListingData = filteredData? filteredData: this.listData;
      } else {
        this.cardListingData = this.listData;
      }
    }
  }
}
