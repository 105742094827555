<div style="margin: 0 16px;">
  <h1>Components</h1>

  <section>
    <div class="label">Time input</div>
    <br>
    <mat-form-field class="time-input" appearance="outline">
      <mat-label>Input time</mat-label>
      <!-- <input type="time" [ngModel]="timeValue" (change)="onChangeValue($event)" matInput name="week" min="09:00" max="18:00" /> -->
      <input type="time" [(ngModel)]="timeValue" matInput name="week" />
      <mat-hint>Output: {{timeValue}}</mat-hint>
    </mat-form-field>
  </section>

  <section>
    <div class="label">Selecting a range</div>
    <div class="flex flex-column">
      <mat-slider>
        <input matSliderStartThumb [(ngModel)]="rangeStart">
        <input matSliderEndThumb [(ngModel)]="rangeEnd">
      </mat-slider>
    </div>
    Start: {{rangeStart}} | End: {{rangeEnd}}
  </section>

  <section>
    <div class="label">Lookup (Refer to lookup-currency component)</div>
    <form [formGroup]="formGroup">
      <app-look-drop-input [lookDropInputSetting]="lookDropInputSetting1" [rowData]="rowData" [formGroup]="formGroup"
        (onLookup)="onLookup()" (onAutoComplete)="onAutoComplete()"></app-look-drop-input>

      <app-look-drop-input [lookDropInputSetting]="lookDropInputSetting2" [rowData]="rowData" [formGroup]="formGroup"
        [multiSelectData]="multiSelectData" (onLookup)="onLookup()" (onAutoComplete)="onAutoComplete()"
        (onSelected)="onSelected($event)"></app-look-drop-input>

    </form>
  </section>

  <section>
    <div class="label">Custom Filter</div>
    <app-filter [customFilter]="customFilter" [filterItems]="filterItems"
    (onFilterValue)="onFilter($event)"></app-filter>
  </section>

  <section>
    <div class="label">Date Formula Builder</div>
    <app-calendar [dateSelection]="dateSelection.dateSelection" [editValue]="editValue"></app-calendar>
  </section>

  <section>
    <div class="label">Tax Formula Builder</div>
  </section>

  <section>
    <form [formGroup]="dateFormGroup">
      <app-date-picker [inputDateSetting]="startDate.dateInput" [formGroup]="dateFormGroup"></app-date-picker>
    </form>
  </section>

  <section>
    <input matNativeControl type="datetime-local">
  </section>
</div>
