<div style="height: 100%; display: flex; flex-direction: column;">
  <div class="filterHeader" *ngIf="isStandalone">
    <span class="filterTitle ">{{'general.filter' | translate}}</span>
    <span (click)="onClose()">
      <img class="closeIcon" src="assets/base/icons/remove.svg">
    </span>
  </div>

  <!-- Filter -->
  <div style="flex: 1; height: 85%; padding: 0 10px">
    <div class="statementPreview" *ngIf="!isMobile" [ngClass]="{'edit-mode': isEditMode, 'default': !isEditMode}">
      <span style="font-size: 12px; color: rgba(190, 190, 199, 1);">{{'general.preview' | translate}}</span>
      <div class="filterStatement" *ngIf="!filterQuery; else filterStatement">
        <span class="filterStatementContent">{{'msg.filter_preview_statement_empty' | translate}}</span>
      </div>
      <ng-template #filterStatement>
        <span class="filterStatementContent " [innerHTML]="filterQuery |  safeHtml"></span>
      </ng-template>
    </div>

    <div class="filterContent" [ngClass]="{'standaloneFilter': isStandalone}">

      <!-- Filter parameters button -->
      <div class="filter-param" [ngStyle]="isMobile ? { height: bottomHeight + 'px' } : {}" #bottomDiv>
        <div class="param-title ">
          {{'msg.filter_param' | translate}}
          <span style="display: flex;" (click)="onSort()">
            <img style="width: 25px; cursor: pointer;" [src]="'assets/base/icons/' + sortIcon + '.svg'">
          </span>
        </div>
        <div class="parameter">
          <div class="param1">
            <div class="search">
              <app-searchbar [searchList]="options" [searchTitle]="'general.parameters' | translate"
                (searchResult)="paramSearch($event)"></app-searchbar>
            </div>
            <div class="paramButtons"
              *ngIf="(!isSearching && options.length > 0) || (isSearching && newOptions.length > 0); else empty">
              <button class="param-but " *ngFor="let option of (isSearching ? newOptions : options); let i = index"
                [value]="option.field | translate " (click)="enableEdit? selectOption(option): onQuitEditMode()"
                [ngClass]="{'disabled': !enableEdit}">
                <span class="paramText">{{ 'general.' + option.field | translate}}</span>
                <span class="buttonCount" *ngIf="getRuleTitleCount(option.field | translate) > 0">
                  {{ getRuleTitleCount(option.field | translate) }}</span>
              </button>
            </div>
            <ng-template #empty>
              <div class="resultEmpty">
                <img class="no-results" src="assets/base/icons/no-results.svg">
                {{"msg.search_empty" | translate}} <br>
                {{"msg.try_again" | translate}}
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <app-resizable-divider *ngIf="isMobile" class="divider" (resize)="onResize($event)"></app-resizable-divider>

      <div class="filter-right-sec" [ngStyle]="isMobile ? { height: topHeight + 'px' } : {}" #topDiv
        [ngClass]="{'rightSec': !filterQuery}">
        <!-- Filter drag and drop-->
        <div class="filter-dragdrop" [ngStyle]="{'height: auto': isStandalone}"
          [ngClass]="{'filterEmpty': resultArray.length === 0}">
          <div class="filter-box" [ngClass]="{'active': reportData}">
            <div class="dragdrop-empty" *ngIf="resultArray.length === 0">
              <span class="default-statement">
                {{'msg.filter_statement_empty' | translate}}
              </span>
            </div>

            <div *ngFor="let ruleGroup of resultArray; let i = index" class="rule-group-box">
              <div class="rule-group-border"
                [ngClass]="{ 'selected': (grpSelected && newSelectedGrpIndex === i) || (!grpSelected && selectedGrpIndex === i) }"
                *ngIf="ruleGroup.rules.length > 0" (click)="enableEdit? selectGrp(i): onQuitEditMode()">
                <div class="ruleGroup">
                  <div class="switch" [ngClass]="{'disabled': !enableEdit, 'firstCondition': i === 0}">
                    <button class="toggle-switch" [disabled]="!enableEdit"
                      [ngClass]="{'disabled': !enableEdit, 'switchDisabled': !enableEdit, 'active': switchStates[i], 'switchActiveDisabled': !enableEdit && switchStates[i]}"
                      *ngIf="ruleGroup.ruleCondition && ruleGroup.rules.length > 1 || i === 0 && resultArray.length > 1"
                      (click)="toggleSwitch(i); $event.stopPropagation()">
                      <div class="toggle-thumb ">
                        <span>{{ (switchStates[i]? 'OR' : 'AND') | translate }}</span>
                      </div>
                    </button>
                    <span class="groupNo">{{"general.group" | translate}} {{i+1}}</span>
                  </div>

                  <div class="removeGroup">
                    <button class="grp-remove " [ngClass]="{'disabled': !enableEdit}" [disabled]="!enableEdit"
                      (click)="removeGrp(i)">{{'general.remove_grp' | translate}}</button>
                  </div>
                  <div *ngFor="let rule of ruleGroup.rules; let j = index;" class="added-rule-box">
                    <app-rule-box [selectedParam]="rule.field" [selectedOperator]="rule.operator"
                      [inputType]="rule.type" [selectedId]="rule.id" [clickedRow]="clickedRow"
                      [clickedColumn]="clickedColumn" [userInputValue]="rule.value || userInputValue"
                      [ruleInputs]="rule.input" [selectedIndexI]="i" [selectedIndexJ]="j"
                      [isDatePickerMode]="isDatePickerMode" [formData]="filterItems" [missingFields]="missingFields"
                      [availableOperators]="availableOperators" [enableEdit]="enableEdit" [selectParam]="selectParam"
                      [openOpDrop]="currentOpenOpDropdown === i + '-' + j "
                      [openValueDrop]="currentOpenValueDropdown === i + '-' + j"
                      [newSelectedGrpIndex]="newSelectedGrpIndex" (ispopupChange)="ispopupChange($event)"
                      (isReset)="isReset($event)" (valueChanged)="handleValueChanged($event)"
                      (showOp)="handleShowOp($event)" (removeRule)="removeRule($event)"
                      (closeDropdown)="onDropdownClicked()" (isDateModeChange)="isDateModeChange($event)"
                      (toggleOp)="toggleOpDropdown(i, j)" (toggleValue)="toggleValueDropdown(i, j)">
                    </app-rule-box>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-group" *ngIf="resultArray.length > 0 && resultArray[0].rules.length > 0  && !addGrp">
              <div class="add-rule" [class.active]="paramGrp" *ngIf="!isGroupClicked">
                <button class="add" [ngClass]="{'disabled': !enableEdit}" [disabled]="!enableEdit"
                  (click)="enableEdit? addNewRuleSet(): onQuitEditMode()">
                  {{'general.add_grp' | translate}}
                </button>
              </div>
            </div>
          </div>

          <div class="groupRule" *ngIf="addGrp">
            <div class="newGroup" [ngClass]="{'selected': addGrp}">
              <div style="display: flex; justify-content: center; width: 100%;">
                <span>{{'general.new_grp_incoming' | translate}}</span>
                <img class="remove" src="assets/base/icons/remove.svg" (click)="removeNewGrp()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="filterFooter" *ngIf="isStandalone">
    <app-action-tool-bar [actionToolbarSetting]="actionToolbarSetting"
      (onAction)="onToolbarAction($event)"></app-action-tool-bar>
  </div>
</div>
