import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'uppercaseTranslate',
  standalone: true
})
export class UppercaseTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(key: string) {
    if(key && this.translateService.instant(key.toLowerCase()) !== key.toLowerCase()) {
      return this.translateService.instant(key.toLowerCase());
    }
    return this.translateService.instant(key);
  }

}
