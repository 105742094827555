<div class="col-12 form-layout-padding" style="margin: auto; max-width: 900px;">

  <div class="">
    <!-- toolbar -->
    <div class="tool-bar-container" style="background-color: #FAFAFA;">
      <label style="font-size: 16px; margin-right: 14px;">DOCUMENT STATUS: Open</label>
      <div class="error-msg hidden-sm" *ngIf="requiredFormError">
        <label>{{'msg.required_msg' | translate}}</label>
      </div>
      <div class="formgrid grid" style="margin-left: auto; margin-right: 0;">
        <div class="field" style="margin-left: 21px;">
          <button class="action-btn">
            <img src="assets/base/icons/print.svg" />{{"general.print" | translate}}
          </button>
        </div>
        <div class="field" style="margin-left: 21px;">
          <button class="action-btn" (click)="save()">
            <img src="assets/base/icons/save.svg" />{{"general.save" | translate}}
          </button>
        </div>
        <div class="field" style="margin-left: 21px;">
          <button class="action-btn">
            <img src="assets/base/icons/check.svg" />{{"general.post" | translate}}
          </button>
        </div>
      </div>
    </div>

    <!-- form -->
    <form class="col-12" [formGroup]="formGrp" *ngIf="formGrp">
      <!-- first section -->
      <div class="accor form-content formgrid grid">
        <div class="accor-header field col-12">
          <label class="tab-label">
            Outlet Details
          </label>
          <img class="accor-icon accordion"
            [src]="display ? 'assets/base/icons/chevron-up.svg' : 'assets/base/icons/chevron-down.svg'"
            (click)="display = !display" />
        </div>
        <ng-template [ngIf]="display">
          <div class="accor-content field col-12"></div>
          <div class="accor-content field col-12 md:col-6">
            <app-auto-generate-input [autoGenerateInputSetting]="docNumber.autoGenerateInput"
              [formGroup]="formGrp"></app-auto-generate-input>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="docDate.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="outletCode.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="docTypeCode.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
        </ng-template>
      </div>

      <!-- second section -->
      <div class="accor form-content formgrid grid">
        <div class="accor-header field col-12">
          <label class="tab-label" [ngClass]="{'active': selectedTab == 1}" (click)="clickTab(1)">
            Outlet Origin
          </label>
          <label class="tab-label" [ngClass]="{'active': selectedTab == 2}" (click)="clickTab(2)">
            Origin Details
          </label>
          <img class="accor-icon accordion"
            [src]="display2 ? 'assets/base/icons/chevron-up.svg' : 'assets/base/icons/chevron-down.svg'"
            (click)="display2 = !display2" />
        </div>
        <!-- tab 1 content -->
        <div class="formgrid grid col-12" [hidden]="!(display2 && selectedTab == 1)">
          <div class="accor-content field col-12"></div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="origin.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="destination.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12">
            <app-input-text [inputTextSetting]="remark.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
        </div>

        <!-- tab 2 content -->
        <div class="formgrid grid col-12" [hidden]="!(display2 && selectedTab == 2)">
          <div class="accor-content field col-12"></div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="inputA.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="inputB.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="inputC.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
          <div class="accor-content field col-12 md:col-6">
            <app-input-text [inputTextSetting]="inputD.inputText" [formGroup]="formGrp"></app-input-text>
          </div>
        </div>

      </div>

    </form>
  </div>

  <hr style="border-color: #E9E9EC;">

  <!-- listing -->
  <div style="margin-left: -16px; margin-right: -16px;">
    <app-listing [colDef]="colDef" [rowData]="rowData" [formCode]="formCode.statementlisting"
      [suppressRowClickSelection]="true">
    </app-listing>
  </div>
</div>
