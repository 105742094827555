import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, DatePipe, NgClass, NgIf } from '@angular/common';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SubscriptionListDetailOutputDTO } from 'src/app/core/models/local/SubscriptionListDetailOutputDTO';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionStatus } from 'src/app/shared/enums/subscriptionStatus';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, NgIf, NgClass, DatePipe, TranslateModule],
})
export class SubscriptionCardComponent implements OnInit {
  @Input() subscription: SubscriptionListDetailOutputDTO;
  @Output() selectSubscription = new EventEmitter<SubscriptionListDetailOutputDTO>();;

  dateFormat: string;
  subscriptionStatus = SubscriptionStatus;

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.dateFormat = this.utilsService.getLocalDateFormat();
  }

  selectSub() {
    this.selectSubscription.emit(this.subscription);
  }
}
