/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ODataRawQueryOptions { 
    readonly filter?: string;
    readonly apply?: string;
    readonly compute?: string;
    readonly search?: string;
    readonly orderBy?: string;
    readonly top?: string;
    readonly skip?: string;
    readonly select?: string;
    readonly expand?: string;
    readonly count?: string;
    readonly format?: string;
    readonly skipToken?: string;
    readonly deltaToken?: string;
}