import { NgIf, } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  imports: [FormsModule, InputTextModule, NgIf, TranslateModule],
})
export class SearchbarComponent {
  searchInput: string;
  isSearch: boolean = false;
  @Input() searchList: any[] = [];
  @Input() searchTitle: string;
  @Output() searchResult: EventEmitter<{ result: any[]; search: boolean, searchInput: string }> =
    new EventEmitter<{ result: any[]; search: boolean, searchInput: string }>();

  searchResults: any[];

  search() {
    if (this.searchInput) {
      this.isSearch = true;
      const searchTerm = this.searchInput.toLowerCase();
      this.searchResults = this.searchList.filter((result) => {
        return result?.field?.toLowerCase().includes(searchTerm);
      });
    } else {
      this.isSearch = false;
      this.searchResults = this.searchList;
    }
    this.searchResult.emit({result: this.searchResults, search: this.isSearch, searchInput: this.searchInput});
  }

  clear() {
    this.searchInput = '';
    this.isSearch = false;
    this.searchResults = this.searchList;
    this.searchResult.emit({ result: this.searchResults, search: this.isSearch, searchInput: this.searchInput });
  }
}
