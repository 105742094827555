import { OnChanges, Inject } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ElementRef, ViewChild, HostListener } from '@angular/core';
import { NgFor, NgClass, NgIf, NgStyle } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RuleBoxComponent } from '../rule-box/rule-box.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { RulesTransformService } from 'src/app/shared/services/rules-transform.service';
import { StrucConvertService } from 'src/app/shared/services/struc-convert.service';
import { ChangeDetectorRef } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { ResizableDividerComponent } from '../../resizable-divider/resizable-divider.component';
import { PageService } from 'src/app/shared/services/page.service';
import { ToastType } from 'src/app/shared/enums/toastType';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionToolBarComponent } from '../../action-tool-bar/action-tool-bar.component';
import { ActionToolBarResp } from '../../action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from '../../action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { ButtonType } from 'src/app/shared/enums/buttonType';
export interface RuleSet {
  field?: string;
  operator?: string;
  value?: string;
  condition?: string;
  rules?: RuleSet[];
}

export interface Rules {
  logic: string;
  ruleSet: RuleSet[];
}

interface Rule {
  field: string;
  operator: string;
  value: string;
  input: string;
  type: string;
  id: string;
}

interface RuleGroup {
  ruleCondition: string;
  rules: Rule[];
}

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, NgStyle, SharedModule, ResizableDividerComponent, ActionToolBarComponent, RuleBoxComponent, TranslateModule, CdkDrag],
})

export class CustomFilterComponent implements OnInit, OnChanges {
  @Input() filters: any[] = [];
  @Input() reportData: any;
  @Input() formCode: string;
  @Input() filterItems: any;
  @Input() filterStructure: any;
  @Input() isMobile: boolean = false;
  @Input() defaultFilter: any;
  @Input() isEditMode: boolean;

  @Output() onQuery: EventEmitter<{ queryHTML: any; resultArray: any[]; firstGroupQuery: string; }> = new EventEmitter<{ queryHTML: any; resultArray: any[]; firstGroupQuery: string; }>();
  @Output() passFilter: EventEmitter<{ filterStructure: any, filterSetting: any, dateFormulaBuilder: any }> = new EventEmitter<{ filterStructure: any, filterSetting: any, dateFormulaBuilder: any }>();
  @Output() passChangeState: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('topDiv', { static: true }) topDiv!: ElementRef;
  @ViewChild('bottomDiv', { static: true }) bottomDiv!: ElementRef;

  options: any[] = [];
  isDatePickerMode: boolean = true;
  selectedParam: any = null;
  selectedOperator: any = null;
  availableOperators: any[] = [
    {
      id: 'equal',
    },
    {
      id: 'not_equal',
    },
    {
      id: 'greater',
    },
    {
      id: 'greater_or_equal',
    },
    {
      id: 'lesser',
    },
    {
      id: 'lesser_or_equal',
    },
    {
      id: 'between',
    },
    {
      id: 'in',
    },
    {
      id: 'not_in',
    },
    {
      id: 'begins_with',
    },
    {
      id: 'ends_with',
    },
    {
      id: 'contains',
    },
    {
      id: 'not_contains',
    },
    {
      id: 'isnull',
    },
    {
      id: 'isnotnull',
    },
  ];
  userInputValue: any;
  selectedOp: any;
  resultArray: RuleGroup[] = [
    {
      ruleCondition: this.translateOption('general.and'),
      rules: [],
    },
  ];
  showOptions = false;
  isDropUpOpen: boolean = false;
  isGroupClicked: boolean = false;
  paramGrp: boolean = false;
  ruleSet: Rules[] = [];
  toggleValue: string = this.translateOption('general.and');
  isGrpToggleActive: boolean;
  ispopup: boolean = false;
  enableEdit: boolean = false;
  filterArray: any[] = [];
  settingFilterArray: any[] = [];
  missingFields: any[] = [];
  dateFormulaBuilder: any = {};
  firstGroupQuery: any;
  clickedRow: number | null = null;
  clickedColumn: number | null = null;
  newOptions: any[] = [];
  isSearching: boolean = false;
  selectParam: any = {};
  selectedGrpIndex: number = 0;
  newSelectedGrpIndex: number = 0;
  grpSelected: boolean = false;
  addGrp: boolean = false;
  switchIndicesToShow: number[] = [];
  switchStates: boolean[] = [];
  isChange: boolean = false;
  topHeight: number;
  bottomHeight: number;
  sortIcon: string = 'white-list-align';
  ascending: boolean = true;
  currentOpenOpDropdown: string = null;
  currentOpenValueDropdown: string = null;
  isStandalone: boolean = false;
  filterQuery: any;

  actionToolbarSetting: ActionToolBarSetting = {
    popup: true,
    actionToolBarItems: [
      {
        id: 'btn-cancel',
        name: 'cancel',
        text: 'toolbar.cancel',
        btnType: ButtonType.button,
        sortNo: 1,
        position: ActionToolbarPosition.RIGHT,
        styling: { minWidth: '140px' },
      },
      {
        id: 'btn-apply',
        name: 'apply',
        text: 'general.apply',
        btnType: ButtonType.button,
        sortNo: 2,
        position: ActionToolbarPosition.RIGHT,
        buttonActive: true,
        styling: { minWidth: '200px' },
        dropdownItm: [
          {
            id: 'btn-apply-generate',
            name: 'apply_generate',
            text: 'toolbar.apply_generate',
            btnType: ButtonType.button,
            sortNo: 1,
          },
        ],
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<CustomFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    private RulesTransform: RulesTransformService,
    private StrucConvertService: StrucConvertService,
    private cd: ChangeDetectorRef,
    private toast: ToastService,
    private pageService: PageService,
  ) {
    this.selectedOperator = this.availableOperators[0];
    this.checkIfMobile();
  }

  ngOnInit(): void {
    if (this.data) {
      this.isStandalone = this.data.isStandalone;
      this.filters = this.data.filters;
      this.isEditMode = this.data.editMode;
      this.filterItems = this.data.filterItems;
      this.isMobile = this.data.isMobile;
      this.populateOptions();
    }

    if (this.isEditMode) {
      this.enableEdit = true;
    } else {
      this.enableEdit = false;
    }
    if (this.filters.length > 0) {
      this.resultArray = this.filters;
      this.generateQueryString();
    }
    this.initializeSwitchStates()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateOptions();
    this.filterItems = changes.filterItems.currentValue;
    this.cd.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any): void {
    this.checkIfMobile();
  }

  private checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 991;
  }

  generateQueryString() {
    let queryHTML: string = '';
    let queryString: string = '';
    let firstGrpQueryHTML: string = '';
    for (const key in this.resultArray) {
      if (this.resultArray.hasOwnProperty(key)) {
        const ruleGroup = this.resultArray[key];
        const firstGroup = this.resultArray[0];

        let firstCondition = firstGroup.ruleCondition;
        let groupSentences: string[] = [];
        let innerQueryString: string = '';
        let firstGrp;

        if (ruleGroup.rules) {
          ruleGroup.rules.forEach((rule, j) => {
            let field = this.translateOption('general.' + rule.field);
            let op = this.translateOption('general.' + rule.operator);
            let value;

            if ((rule.operator !== 'isnull' && rule.operator !== 'isnotnull') && (rule.value === null || rule.value === undefined || rule.value === '')) {
              value = 'undefined';
            } else {
              let transValue = rule.value;
              if (typeof transValue === 'string' && transValue.includes(', ')) {
                let values = transValue.split(', ');
                let translatedValues: string[] = [];

                values.forEach(value => {
                  translatedValues.push(this.translateOption(value));
                });
                value = translatedValues.join(', ');
              } else if (typeof transValue === 'number') {
                value = transValue;
              } else if (Array.isArray(rule.value)) {
                value = rule.value.join(', ');
              } else {
                value = this.translateOption(transValue)
              }
            }

            let valueStyle = value === 'undefined' ? 'color: #ffb938;' : '';

            groupSentences.push(
              `${field} <span style="font-weight: bold;">${op.toUpperCase()}</span> <span style="${valueStyle}">${value}</span>`
            );

            innerQueryString += `${field} ${op.toUpperCase()} ${value}`;

            if (j !== ruleGroup.rules.length - 1) {
              const groupStyle = `color: ${ruleGroup.ruleCondition === "AND" ? '#FC5555' : '#0DCAF0'
                };`;

              groupSentences.push(
                `<span style="${groupStyle};">${ruleGroup.ruleCondition}</span>`
              );

              innerQueryString += ` ${ruleGroup.ruleCondition} `;
            }
          });
        }

        const firstGroupStyle = `color: ${firstCondition === "AND" ? '#FC5555' : '#0DCAF0'};`;

        const groupSentence = groupSentences.join(' ');
        if (parseInt(key) === 0) {
          firstGrp = groupSentence
        }

        if (groupSentence.trim().length > 0) {
          queryHTML += `<span style="font-family: sofia-pro; background-color: #e9e9e9; padding: 4px 10px; border-radius: 8px;  max-width: fit-content; font-size: 14px;">${groupSentence}</span>`;
          if (firstGrp) {
            firstGrpQueryHTML += `<span class="firstGrp" style="font-family: sofia-pro; background-color: #e9e9e9; padding: 4px 10px; border-radius: 8px; max-width: fit-content; font-size: 14px;">${firstGrp}</span>`;
          }

          if (parseInt(key) < Object.keys(this.resultArray).length - 1) {
            queryHTML += `<span style="font-family: sofia-pro; margin: 5px; max-width: fit-content; font-size: 14px; ${firstGroupStyle}">${firstCondition}</span>`;
            queryString += `(${innerQueryString}) ${firstCondition} `;
          } else if (parseInt(key) > 0) {
            queryString += `(${innerQueryString})`;
          } else {
            queryString = innerQueryString;
          }
        }
      }
    }
    this.firstGroupQuery = firstGrpQueryHTML;
    this.filterQuery = queryHTML;
    this.onQuery.emit({ queryHTML: queryHTML, resultArray: this.resultArray, firstGroupQuery: this.firstGroupQuery });
    this.cd.detectChanges();
  }

  restructure() {
    let missingFieldsMap = {};
    this.resultArray.forEach((list, i) => {
      list.rules.forEach((item, j) => {
        if (item.operator !== 'isnull' && item.operator !== 'isnotnull') {
          if (item.value === undefined || item.value === null || item.value === '') {
            if (!this.missingFields.some(field => field.i === i && field.j === j)) {
              this.missingFields.push({ i: i, j: j });
            }

            if (!missingFieldsMap[i]) {
              missingFieldsMap[i] = [];
            }
            missingFieldsMap[i].push(item.field);
          } else {
            const indexToRemove = this.missingFields.findIndex(index => index.i === i && index.j === j);
            if (indexToRemove !== -1) {
              this.missingFields.splice(indexToRemove, 1);
            }
          }
        }
      });
    });

    // Show toast messages if there are missing fields
    if (Object.keys(missingFieldsMap).length > 0) {
      let toastMessage = {
        type: ToastType.Warning,
        message: '',
        hideCloseBtn: false,
        timeToClose: 5000
      };

      for (const i in missingFieldsMap) {
        if (missingFieldsMap.hasOwnProperty(i)) {
          const missingFields = missingFieldsMap[i];
          const missingFieldsList = missingFields.join(', ');
          const groupNumber = (parseInt(i) + 1).toString();

          this.translateService.get('msg.filter_missing', { fields: missingFieldsList, group: groupNumber })
            .subscribe((translation: string) => {
              toastMessage.message += translation;
            });

          toastMessage.timeToClose += missingFields.length * 1000;
        }
      }

      this.toast.show(toastMessage);
      throw new Error('There are missing fields.');
    } else {
      const structureMapping = {
        logic: 'ruleCondition',
        filters: 'rules',
      };

      this.filterArray = this.StrucConvertService.addParent(
        this.StrucConvertService.convertArrayStructure(
          this.StrucConvertService.separateMultiple(this.resultArray, this.filterItems),
          structureMapping
        )
      );

      this.settingFilterArray = this.StrucConvertService.convertNewStructure(this.resultArray, this.filterItems);
      this.dateBuilderFormula();
      this.passFilter.emit({
        filterStructure: this.filterArray,
        filterSetting: this.settingFilterArray,
        dateFormulaBuilder: this.dateFormulaBuilder
      });
      this.generateQueryString();
    }
  }

  dateBuilderFormula(): any {
    this.settingFilterArray.forEach(list => {
      list.rules.forEach(item => {
        if (item.id === 'BizDate' && !this.isDatePickerMode) {
          this.dateFormulaBuilder[item.id] = item.value.formula;
          return this.dateFormulaBuilder;
        }
      })
    })
  }

  flattenRules(rules: any): any {
    return this.RulesTransform.flattenRules(rules.rules);
  }

  translateOption(option: string): string {
    if (option) {
      return this.translateService.instant(option);
    } else {
      return option;
    }
  }

  ispopupChange(value: boolean) {
    this.ispopup = value;
  }

  isReset(eventData: { clickedRow: number; clickedColumn: number }): void {
    this.clickedRow = eventData.clickedRow;
    this.clickedColumn = eventData.clickedColumn;
  }

  getFieldTitle(field: string): string {
    return field;
  }

  getRuleTitleCount(title: string): number {
    let count = 0;
    this.resultArray.forEach((ruleGroup) => {
      ruleGroup.rules.forEach((rule) => {
        if (this.getFieldTitle(this.translateOption(rule.field)) === title) {
          count++;
        }
      });
    });

    return count;
  }

  paramSearch(event: { result: any[]; search: boolean }) {
    if (event.search) {
      this.newOptions = event.result;
      this.isSearching = event.search;
    } else {
      this.newOptions = this.options;
      this.isSearching = event.search;
    }
  }

  populateOptions() {
    this.options = this.filterItems.map((item) => ({
      field: this.translateOption(item.fieldName),
      id: item.id,
      input: item.input,
      type: item.type,
      value: item.values,
      operator: item.operator,
    }));
  }

  handleShowOp(event: { selectedParam: string; i: number; j: number }) {
    const selectedField = this.options.find(
      (field) => field.field === event.selectedParam
    );
    if (selectedField) {
      this.availableOperators = selectedField.operator;
    }
  }

  selectOption(selectedOption: any) {
    this.addGrp = false;
    this.selectParam = { ...selectedOption };
    this.selectParam.input = selectedOption.input || '';
    this.selectParam.type = selectedOption.type || '';
    this.availableOperators = selectedOption.operator;
    const newRule = {
      field: this.selectParam.field,
      operator: this.availableOperators[0],
      value: this.userInputValue,
      input: this.selectParam.input,
      type: this.selectParam.type,
      id: this.selectParam.id,
    };

    let index = 0;

    if (this.grpSelected) {
      index = this.newSelectedGrpIndex;
    } else {
      index = this.selectedGrpIndex;
    }

    if (index >= 0 && index < this.resultArray.length) {
      this.resultArray[index].rules.push(newRule);
    } else {
      this.resultArray.push({
        ruleCondition: this.translateOption("general.and"),
        rules: [newRule],
      });
      this.newSelectedGrpIndex = this.resultArray.length - 1;
    }
    this.generateQueryString();
  }

  selectGrp(index: number) {
    if (this.addGrp === true) {
      this.addGrp = false;
      this.removeNewGrp();
    }
    this.grpSelected = true;
    this.newSelectedGrpIndex = index;
  }

  removeRule(event: { row: number; col: number }) {
    if (event.col === 0 && this.resultArray[event.row].rules.length <= 1) {
      this.removeGrp(event.row);
    } else {
      if (event.row >= 0 && event.row < this.resultArray.length) {
        const rules = this.resultArray[event.row].rules;
        if (event.col >= 0 && event.col < rules.length) {
          if (event.col === 0) {
            this.selectedGrpIndex = this.selectedGrpIndex - 1;
            this.switchIndicesToShow.pop();
          }
          rules.splice(event.col, 1);
        }
      }
    }
    this.generateQueryString();
  }

  removeGrp(col: number) {
    if (col >= 0 && col < this.resultArray.length) {
      this.resultArray.splice(col, 1);
      this.newSelectedGrpIndex = this.resultArray.length - 1;
      this.newSelectedGrpIndex = 0;
    }

    if (this.selectedGrpIndex === 0) {
      this.selectedGrpIndex = this.selectedGrpIndex;
    } else {
      this.selectedGrpIndex = this.selectedGrpIndex - 1;
    }

    if (this.resultArray.length === 0) {
      this.userInputValue = '';
    };

    this.switchIndicesToShow.pop();
    this.switchStates.splice(col, 1)
    this.generateQueryString();
  }

  removeNewGrp() {
    this.resultArray.pop()
    this.addGrp = false;
    this.selectedGrpIndex = this.selectedGrpIndex > 0 ? this.selectedGrpIndex - 1 : 0;
    this.switchIndicesToShow.pop();
    this.generateQueryString();
  }

  addNewRuleSet() {
    this.addGrp = true;
    this.selectedGrpIndex = this.resultArray.length;
    this.newSelectedGrpIndex = this.resultArray.length;
    this.grpSelected = false;
    this.switchIndicesToShow.push(this.resultArray.length);
    this.resultArray.push({
      ruleCondition: this.translateOption("general.and"),
      rules: [],
    });
  }

  toggleSwitch(index: number) {
    this.switchStates[index] = !this.switchStates[index];
    this.isChange = true;
    this.passChangeState.emit(this.isChange);
    this.updateSwitch(this.switchStates[index], index)
    this.generateQueryString();
  }

  updateSwitch(switchStates: boolean, index: number) {
    if (this.resultArray.length > 0) {
      if (switchStates === true) {
        this.resultArray[index].ruleCondition = "OR";
      } else {
        this.resultArray[index].ruleCondition = "AND";
      }
    }
  }

  initializeSwitchStates() {
    this.switchStates = this.resultArray.map(
      (ruleGroup) => ruleGroup.ruleCondition === "OR"
    );

    if (this.resultArray[0]?.ruleCondition === "OR") {
      this.isGrpToggleActive = true;
    }
  }

  handleValueChanged(valueChange: { isChanged: boolean; op: any; value: string; input: string; row: number; col: number; }): void {
    const { isChanged, value, input, row, col } = valueChange;
    const ruleKey = `${row}_${col}`;
    this.isChange = isChanged;
    this.passChangeState.emit(this.isChange);
    let transValue;

    if (valueChange.input === 'radio' || valueChange.input === 'select') {
      transValue = this.translateOption(valueChange.value);
    } else if (valueChange.input === 'checkbox') {
      let checkboxValues;
      if (valueChange.value && valueChange.value.includes(',')) {
        checkboxValues = valueChange.value.split(',').map(val => val.trim());
        const transCheckbox = checkboxValues.map(value => this.translateOption(value));
        transValue = transCheckbox.join(', ');
      } else {
        checkboxValues = this.translateOption(valueChange.value);
        transValue = checkboxValues
      }

    } else {
      transValue = valueChange.value;
    }

    this.updateNewValue(row, col, valueChange.op, value);
    this.generateQueryString();
  }

  updateNewValue(i: number, j: number, op: any, value: string) {
    const ruleGroup = this.resultArray[i];
    ruleGroup.rules[j].value = value;
    ruleGroup.rules[j].operator = op;
  }

  isDateModeChange(event) {
    this.isDatePickerMode = event;
  }

  onResize(event: { clientY: number }) {
    const clientY = event.clientY;

    const newTopHeight = clientY;
    const newBottomHeight = window.innerHeight - clientY;

    if (newTopHeight > 50 && newBottomHeight > 50) {
      this.topHeight = newTopHeight;
      this.bottomHeight = newBottomHeight;
    }
  }

  onSort() {
    if (this.sortIcon === 'white-list-align') {
      this.sortIcon = 'white-sortAtoZ';
      this.ascending = true;
      this.sortOptions(this.ascending);
    } else if (this.sortIcon === 'white-sortAtoZ') {
      this.sortIcon = 'white-sortZtoA';
      this.ascending = false;
      this.sortOptions(this.ascending);
    } else if (this.sortIcon === 'white-sortZtoA') {
      this.populateOptions();
      this.sortIcon = 'white-list-align';
    }
  }

  sortOptions(ascending: boolean): void {
    this.options.sort((a, b) => {
      const fieldA = a.field.toLowerCase();
      const fieldB = b.field.toLowerCase();
      if (fieldA < fieldB) {
        return ascending ? -1 : 1;
      }
      if (fieldA > fieldB) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  }

  toggleOpDropdown(i: number, j: number) {
    const dropdownIdentifier = `${i}-${j}`;
    if (this.currentOpenValueDropdown) {
      this.currentOpenValueDropdown = null;
    }

    if (this.currentOpenOpDropdown === dropdownIdentifier) {
      this.currentOpenOpDropdown = null;
    } else {
      this.currentOpenOpDropdown = dropdownIdentifier;
    }
  }

  toggleValueDropdown(i: number, j: number) {
    const dropdownIdentifier = `${i}-${j}`;
    if (this.currentOpenOpDropdown) {
      this.currentOpenOpDropdown = null;
    }

    if (this.currentOpenValueDropdown === dropdownIdentifier) {
      this.currentOpenValueDropdown = null;
    } else {
      this.currentOpenValueDropdown = dropdownIdentifier;
    }
  }

  onDropdownClicked(): void {
    this.currentOpenOpDropdown = null;
    this.currentOpenValueDropdown = null;
  }

  onQuitEditMode() {
    this.pageService.quitEditMode$.next();
  }

  onClose() {
    this.dialogRef.close();
  }

  onToolbarAction(resp: ActionToolBarResp) {
    if (resp.name === 'cancel') {
      this.onClose();
    } else if (resp.name === 'apply') {
      const generateResult = this.onGenerate(); // Check if an error occurred during generation
      if (!generateResult) {
        return; // Exit early if an error occurred
      }
      this.dialogRef.close({
        filterQueryString: this.filterQuery,
        filter: this.filterArray,
        generate: false,
      });
    } else if (resp.name === 'apply_generate') {
      const generateResult = this.onGenerate(); // Check if an error occurred during generation
      if (!generateResult) {
        return; // Exit early if an error occurred
      }
      this.dialogRef.close({
        filterQueryString: this.filterQuery,
        filter: this.filterArray,
        generate: true,
      });
    }
  }

  onGenerate(): boolean {
    try {
      this.restructure();
      return true; // Return true if restructuring succeeds
    } catch (error) {
      return false; // Return false if an error occurs during restructuring
    }
  }
}
