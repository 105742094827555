import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleInputSetting } from '../model/ToggleInputSetting.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormService } from '../../form/form.service';
import { TranslateModule } from '@ngx-translate/core';
import { ElementService } from 'src/app/shared/services/element.service';
import { ComponentService } from 'src/app/shared/services/component.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-toggle-input',
    templateUrl: './toggle-input.component.html',
    styleUrls: ['./toggle-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, NgIf, MatSlideToggleModule, TranslateModule, NgClass, NgStyle]
})
export class ToggleInputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() toggleInputSetting: ToggleInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;
  @Output() onToggleChange = new EventEmitter<{name: string, checked: boolean}>();

  @ViewChild('slideToggle', { static: false }) slideToggleEl: MatSlideToggle;

  toggleChange$: Subscription;

  element: any;

  constructor(private formservice: FormService,
    private elementService: ElementService,
    private componentService: ComponentService) { }

  ngOnInit(): void {
    // Set Dependency
    if(this.formGroup && this.toggleInputSetting) {
      this.formservice.setDependency(this.formGroup, this.toggleInputSetting);
    }

    if(this.toggleInputSetting?.name && this.formGroup?.get(this.toggleInputSetting.name)) {
      this.toggleChange$ = this.formGroup.get(this.toggleInputSetting.name).valueChanges.subscribe((value: any) => {
        this.componentService.toggleChange$.next({name: this.toggleInputSetting.name, checked: value});
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue) {
      this.formData = changes.formData.currentValue;
      this.formGroup?.controls[this.toggleInputSetting.name].setValue(changes.formData.currentValue[this.toggleInputSetting.name]);
    }
  }

  ngAfterViewInit(): void {
    // Get Element
    if(this.toggleInputSetting?.id && this.elementService.getElementById(this.toggleInputSetting.id)) {
      this.element = this.elementService.getElementById(this.toggleInputSetting.id);

      setTimeout(() => {
        if(this.element?.disabled) {
          this.elementService.disableElementById(this.slideToggleEl.buttonId);
        }
      }, 0);
    }

  }

  onChange(event: any) {
    if(!this.formGroup?.get(this.toggleInputSetting.name)) {
      this.componentService.toggleChange$.next({name: event.source.name, checked: event.checked});
    } else {
      this.onToggleChange.emit({name: event.source.name, checked: event.checked});
    }
  }

  ngOnDestroy(): void {
    this.toggleChange$?.unsubscribe();
  }
}
