import { Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyGridComponent } from 'src/app/shared/component/property-grid/property-grid.component';
import { ColDef } from 'ag-grid-community';
import { PropertyGridSetting } from 'src/app/shared/models/PropertyGridSetting.model';
import { PropertyGridService } from 'src/app/shared/component/services/property-grid.service';
import { UntypedFormGroup } from '@angular/forms';
import { TreeSetting } from 'src/app/shared/component/tree/model/treeSetting.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarItem } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarItem.model';
import { ActionToolbarService } from 'src/app/shared/component/services/action-toolbar.service';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { ButtonType } from 'src/app/shared/enums/buttonType';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';

@Component({
  selector: 'app-setting',
  standalone: true,
  imports: [CommonModule, PropertyGridComponent],
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  rowData: any;
  colDef: ColDef[];
  sysOptionsData: any = [];
  setting: PropertyGridSetting;
  menuSetting: TreeSetting;
  actionToolbarSetting: ActionToolBarSetting;

  constructor(private propertyGridService: PropertyGridService,
    private actionToolbarService: ActionToolbarService
  ) {}

 async ngOnInit() {
    // call Api
    // this.sysOptionsData = this.propertyGridService.getSysData();
    
    await new Promise(resolve => setTimeout(resolve, 100)).then(
      () => {
        this.sysOptionsData = this.propertyGridService.getSysData();
      }
    );

    // Set setting
    this.setting = {
      parentKey: 'ParentCode',
      groupKey: 'SegmentCode',
      groupCaption: 'SegmentCaption',
      groupDesc: 'SegmentDesc',
      titleCaption: 'SetCaption',
      titleDesc: 'SetDesc',
      valueTypeKey: 'ValueType',
      inputTypeKey: 'InputType',
      inputKey: 'SetCode',
      inputVal: 'SetValue',
      dropdownData: 'DataSource',
      childKey: 'Children'
    };

    this.actionToolbarSetting = {
      actionToolBarItems: [
        { name: 'showAll', text: 'toolbar.show_all', sortNo: 1 },
        { name: 'filter', icon: 'assets/base/icons/filter.svg', text: 'toolbar.filter', input: true, clickable: false, sortNo: 2 },
        { name: 'save', icon: 'assets/base/icons/save.svg', text: 'toolbar.save', btnType: ButtonType.submit, checkPermission: [AttributeCode.EDIT], sortNo: 1, buttonActive: true, position: ActionToolbarPosition.RIGHT },
        { name: 'dropdown', text: 'dropdown example', sortNo: 3, dropdownItm: [
          { name: 'dropdown_1', text: 'dropdown 1', sortNo: 1 },
          { name: 'dropdown_2', text: 'dropdown 2', sortNo: 2 }]
        }
      ],
      position: ActionToolbarPosition.LEFT
    }

    // Set menu data
    this.menuSetting = {
      level: 1,
      childKey: 'Children',
      descCaption: 'SegmentCaption',
      desc: 'SegmentDesc'
    }
  }

  onActionToolbar(value: ActionToolBarResp) {
    // get value and do your action
    if(value?.name === 'dropdown_1' || value?.name === 'dropdown_2') {
      this.actionToolbarSetting = this.actionToolbarService.setText(value.data?.actionToolbarSetting, 'dropdown', value?.toolbar.text);
    }
  }

  onSubmit(data: any) {
    // Call add / update api
    console.log(data);
  }
}

