import { Component, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { environment } from 'src/environments/environment';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { Router } from '@angular/router';
import { FormService } from 'src/app/shared/component/form/form.service';
import { PopupAction } from 'src/app/shared/enums/popupAction';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import {
  InterfaceType,
  TaxConditionHService as TaxConditionHAPIService,
  TaxConditionHResponse,
  ErrorCode,
} from 'src/swagger/base/openapi';
import { DropdownOption } from 'src/app/shared/component/input/model/DropdownOption.model';
import { BackendTaxConditionH, CurrencyService, StoreRegisterstoretwoBody } from 'src/swagger/cxmbackend/openapi';
import { TimeZone } from 'src/app/shared/enums/timeZone';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { TaxService } from '../../tax.service';
import { TaxType } from 'src/swagger/base/openapi/model/taxType';

@Component({
  selector: 'app-taxconditionh-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, TranslateModule],
  templateUrl: './taxconditionh-entry.component.html',
  styleUrls: ['./taxconditionh-entry.component.scss'],
  providers:[CurrencyService]
})
export class TaxConditionHEntryComponent {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  taxCondCode: string = '';
  formGroup: FormGroup;
  taxConditionHData: any;
  masterData: any;

  pageSub$: Subscription;

  interfaceTypeOption: any = Object.keys(InterfaceType).map((key) => { return { name: key, value: InterfaceType[key] } });

  RegionTimeZone: {[key: string]: string} = {
    'Singapore Standard Time (UTC+08:00)': 'Singapore Standard Time',
    }

  formSetting: FormSetting = {
    formCode: FormCode.taxconditionhentry,
    content: [
      {
        inputText: {
          name: 'taxCondCode',
          label: 'Tax Condition Code',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 5,
          editable: false,
        },
      },
      {
        inputText: {
          name: 'taxCondDesc',
          label: 'Tax Condition Description',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 254,
        },
      },
      {
        lookDropInput: {
          name: 'taxType',
          label: 'Tax Type',
          valueKey: 'value',
          hintValueKey: 'name',
          dropdownOption: Object.keys(TaxType).map((key) => {
            return { name: TaxType[key], value: key };
          }),
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
    ],
  };

  actionToolbarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private customService: CustomService,
    private TaxConditionHApiService: TaxConditionHAPIService,
    private authService: AuthService,
    private pageService: PageService,
    public dialog: MatDialog,
    private taxConditionHService: TaxService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private formService: FormService

  ) {
    this.pageSub$ = this.pageService.pageSubject$.subscribe(async pages => {
      this.pages = pages;

      if (this.page) {
        let foundPage = this.pages.find(page => page.form.filePath === this.page.form.filePath);
        if (foundPage) {
          this.taxCondCode = foundPage.listingId;
          if (this.taxCondCode) {
            await this.getTaxConditionH(this.taxCondCode);
          }
        }
      }
    });
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);


    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.taxCondCode = params['taxCondCode'];
      if (this.taxCondCode) {
        await this.getTaxConditionH(this.taxCondCode.toString());
        this.taxConditionHData.timeZone = this.taxConditionHData.timeZone? this.taxConditionHData.timeZone : 'Singapore Standard Time';
      }
      else {
        this.taxConditionHData =
        {
          taxType: 'O'
        };
      }
    }

  }


  async getTaxConditionH(taxCondCode: string) {
    let result = await this.taxConditionHService.getOdataTaxConditionH(taxCondCode, null, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.taxConditionHData = result['value'][0];
    }
  }

  async addTaxConditionH(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.tax.taxConditiohH.AddTaxConditionH,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: reqBody,
      httpHeaderType: undefined
    }

    this.loaderService.startLoading();
    let result = await this.reqCustomHttpCall(cusReq);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.taxconditionh.create.success.title',
        desc: 'msg.tax.taxconditionh.create.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxgrouplisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(result);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'popupMsg.new.TaxConditionH.unsuccessful',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  async updateTaxConditionH(reqBody: any) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.tax.taxConditiohH.UpdateTaxConditionH,
      hostPath: environment.hostPath,
      headers: {
        formCode: this.formDetail.formCode
      },
      body: reqBody,
      httpHeaderType: undefined
    }

    this.loaderService.startLoading();
    let result = await this.reqCustomHttpCall(cusReq);
    this.loaderService.stopLoading();
    if (result instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.tax.taxconditionh.update.success.title',
        desc: 'msg.tax.taxconditionh.update.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.taxgrouplisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(result);
      let msg: PopupMessage = {
        formCode: FormCode.taxgrouplisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.tax.taxconditionh.update.fail.title',
        desc: result.error.title,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }

  onGenerateInput(event: any) {
    // call generate api
    let generatedCode = 'code';
    event.formGrp.controls[event.setting.name].setValue(generatedCode);
  }
  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody: BackendTaxConditionH = {
      taxCondCode: formGroup.get('taxCondCode').value,
      taxCondDesc: formGroup.get('taxCondDesc').value,
      taxType: formGroup.get('taxType').value,
    }

    if (this.taxCondCode) {
      reqBody = { ...reqBody, taxCondCode: this.taxCondCode };
      this.updateTaxConditionH(reqBody);
    } else {
      reqBody = { ...reqBody, taxCondCode: formGroup.get('taxCondCode').value };
      this.addTaxConditionH(reqBody);
    }
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy() {
    this.pageSub$.unsubscribe();
  }
}
