import { Component, Input, Output, EventEmitter, HostListener, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { InputTextComponent } from '../../input/input-text/input-text.component';
import { InputTypes } from '../../input/enum/InputTypes';
import { FormContent } from '../../form/model/FormContent.model';
import { ButtonToggleComponent } from '../../input/button-toggle/button-toggle.component';
import { DatePickerComponent } from '../../input/date-picker/date-picker.component';
import { DatePickerRangeComponent } from '../../input/date-picker-range/date-picker-range.component';
import { FormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareValueService } from 'src/app/shared/services/share-value.service';
import { LookDropInputComponent } from '../../input/look-drop-input/look-drop-input.component';
import { LookDropComponent } from '../../input/look-drop/look-drop.component';
import { InputNumberComponent } from '../../input/input-number/input-number.component';
import * as dateSelectionData from '../../input/date/calendar/dateSelection.json';
import { CalendarComponent } from '../../input/date/calendar/calendar.component';
import { componentMap } from '../../../../project-demo/lookup/lookupComponentMap';
import { LookDropInputSetting } from '../../input/model/LookDropInputSetting.model';
@Component({
  selector: 'app-rule-box',
  templateUrl: './rule-box.component.html',
  styleUrls: ['./rule-box.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    MatDividerModule,
    SharedModule,
    TranslateModule,
    InputTextComponent,
    ButtonToggleComponent,
    DatePickerComponent,
    DatePickerRangeComponent,
    CalendarComponent,
    ReactiveFormsModule,
    FormsModule,
    LookDropInputComponent,
    LookDropComponent,
    InputNumberComponent
  ],
})
export class RuleBoxComponent {
  inputPreset: FormContent = {
    inputText: {
      name: 'Preset',
      type: InputTypes.text,
      styling: {
        width: '100%',
      },
    },
  };

  inputText: FormContent = {
    inputText: {
      name: 'Popup Text',
      type: InputTypes.text,
      styling: {
        width: '100%',
      },
    },
  };

  public inputMap = {
    'inputPreset.inputText': this.inputPreset.inputText,
    'inputText.inputText': this.inputText.inputText,
  };

  @Input() formData: any;
  @Input() selectedId: string;
  @Input() selectedParam: string;
  @Input() selectedOperator: string = '';
  @Input() userInputValue: any;
  @Input() availableOperators: any[];
  @Input() openOpDrop: boolean;
  @Input() openValueDrop: boolean;
  @Input() ruleInputs: string;
  @Input() inputType: string;
  @Input() dateValue: string;
  @Input() selectedIndexI: number;
  @Input() selectedIndexJ: number;
  @Input() operators: any[];
  @Input() selectParam: any;
  @Input() newSelectedGrpIndex: number;
  @Input() missingFields: any[] = [];
  @Input() clickedRow: number;
  @Input() clickedColumn: number;
  @Input() isDatePickerMode: boolean;
  @Input() enableEdit: boolean;
  @Input() isChanged: boolean = false;

  @Output() ispopupChange = new EventEmitter<boolean>();
  @Output() paramAddChange: EventEmitter<{ paramAdd: any; selectIndex: number; }> = new EventEmitter<{ paramAdd: any; selectIndex: number }>();
  @Output() removeRule: EventEmitter<{ row: number; col: number }> = new EventEmitter<{ row: number; col: number }>();
  @Output() showOp: EventEmitter<{ selectedParam: string; i: number; j: number; }> = new EventEmitter<{ selectedParam: string; i: number; j: number }>();
  @Output() closeDropdown = new EventEmitter<void>();
  @Output() toggleOp = new EventEmitter<void>();
  @Output() toggleValue = new EventEmitter<void>();
  @Output() blurOthers: EventEmitter<{ i: number; j: number }> = new EventEmitter<{ i: number; j: number }>();
  @Output() isReset = new EventEmitter<{ clickedRow: number; clickedColumn: number; }>();
  @Output() valueChanged: EventEmitter<{ isChanged: boolean; op: any; value: any; input: string; row: number; col: number }> = new
    EventEmitter<{ isChanged: boolean; op: any; value: any; input: string; row: number; col: number; }>();
  @Output() isPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isDateModeChange = new EventEmitter();

  form: any;
  dateSelection = dateSelectionData;
  lookDropInput: LookDropInputSetting;
  formGrp: UntypedFormGroup;
  formContent = [this.inputPreset, this.inputText];
  showOpDropdown: boolean;
  numericValue: number;
  numValues: number[] = [0, 1];
  firstValue: string;
  defaultLookup: any;
  mult: boolean;
  formulaBuild: boolean = true;
  lookupValue: string;
  lookupDesc: string;
  isLookup: boolean = false;
  splitValues: string[];
  popup: boolean = false;
  isFocus: boolean = false;
  editValue: string;
  editDateValue: string;
  isBetween: boolean = true;
  dateCalcValue: string;
  isDateBoolean: boolean;
  selectedOptions: any[] = [];
  userInputValueNewPushed: boolean = false;
  matching: boolean;
  errorMessage: string;
  error: boolean = false;
  eventDate: string;
  eventOption: string;
  newDate: string;
  isModify: boolean;
  showValue: boolean = false;
  itemValue: any[] = [];
  userInputValueNew: any;
  opNull: boolean = false;

  constructor(
    public translateService: TranslateService,
    private shareValueService: ShareValueService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef) {
    this.formGrp = this.formBuilder.group({
      inputText: [''],
    });
  }

  ngOnInit(): void {
    this.initializeLookDropInput();
    this.handleRuleInputs();
    this.splitingValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOperator?.currentValue) {
      if (changes.selectedOperator.currentValue === 'in' || changes.selectedOperator.currentValue === 'not_in') {
        this.mult = true;
      } else {
        this.mult = false;
      }
      this.handleRuleInputs()
    }

    this.initializeLookDropInput();
  }

  initializeLookDropInput(): void {
    const componentName = componentMap[this.selectedId];
    this.lookDropInput = {
      name: this.selectedId,
      label: 'general.' + this.selectedId,
      componentName: componentName,
      multiSelect: this.mult,
      autoComplete: true,
      disabled: !this.enableEdit,
      styling: {
        width: '100%'
      },
      defaultLookdrop: true
    };
  }

  handleRuleInputs(): void {
    switch (this.ruleInputs) {
      case 'checkbox':
        if (this.userInputValue) {
          this.getCheckboxValue();
        }
        break;
      case 'select':
        this.setDropdown();
        if (!this.userInputValue) {
          this.userInputValue = this.itemValue[0];
          this.valueChanged.emit({ isChanged: true, op: this.selectedOperator, value: this.userInputValue, input: this.ruleInputs, row: this.selectedIndexI, col: this.selectedIndexJ });
        }
        break;
      case 'radio':
        const booleanOptions = this.showBoolean();
        if (!this.userInputValue) {
          this.userInputValue = booleanOptions[0];
          this.valueChanged.emit({ isChanged: true, op: this.selectedOperator, value: this.userInputValue, input: this.ruleInputs, row: this.selectedIndexI, col: this.selectedIndexJ });
        }
        break;
      case 'lookup':
        if (this.selectedOperator === 'in' || this.selectedOperator === 'not_in') {
          this.mult = true;
        } else {
          this.mult = false;
        }

        if (this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull') {
          this.opNull = true;
          this.userInputValue = '';
        }

        if (this.userInputValue) {
          this.defaultLookup = this.userInputValue;
        }

        this.form = {
          [this.selectedId]: this.defaultLookup,
          isMultiple: this.mult,
        };
        break;
      case 'formuladatepicker':
        if (this.userInputValue) {
          if (!this.isDatePickerMode || this.userInputValue.includes('(')) {
            const match = this.userInputValue.match(/\(([^)]+)\)/);
            if (match) {
              this.dateValue = match[1];
            }
          } else {
            this.newDate = this.userInputValue;
          }
        } else {
          this.onOperatorSelected('equal');
        }
        break;
      case 'text':
        if (this.inputType === 'integer' && !this.userInputValue) {
          if (this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull') {
            this.opNull = true;
            this.userInputValue = '';
          } else if (this.selectedOperator !== 'between') {
            this.userInputValue = 0;
            this.opNull = false;
          }
          this.valueChanged.emit({ isChanged: true, op: this.selectedOperator, value: this.userInputValue, input: this.ruleInputs, row: this.selectedIndexI, col: this.selectedIndexJ });
        }
        break;
    }

    if (this.ruleInputs === 'text' && this.inputType === 'integer') {
      this.numericValue = Number(this.userInputValue);
    }

    if (this.ruleInputs === 'formuladatepicker' && this.userInputValue) {
      this.checkRegex(this.userInputValue);
    }
  }

  shouldApplyBorderColor(): boolean {
    return this.missingFields.some(field => field.i === this.selectedIndexI && field.j === this.selectedIndexJ);
  }

  handlePassData(data: { data?: any, value: any, description: any }) {
    this.lookupValue = data.value;
    this.lookupDesc = data.description;
    this.onValueChange(data.value, this.ruleInputs, this.selectedIndexI, this.selectedIndexJ);
  }

  convertStringToNum(stringArray: string[]): number[] {
    return stringArray.map((value) => {
      return +value;
    });
  }

  splitingValue() {
    if (this.selectedOperator === 'between') {
      this.splitValues = this.userInputValue.split(' ~ ');
    }

    if (this.splitValues) {
      this.firstValue = this.splitValues[0];
      this.numValues = this.convertStringToNum(this.splitValues);
    } else {
      this.splitValues = [];
    }
  }

  removeParam(row: number, col: number) {
    this.selectedOptions = [];
    this.userInputValueNewPushed = false;
    this.userInputValueNew = undefined;
    this.removeRule.emit({ row, col });
  }

  lookPop() {
    this.isLookup = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-operator') && !target.closest('.chips-op-options')) {
      this.closeDropdown.emit();
    }
  }

  toggleOpDropdown(selectedParam: string, i: number, j: number) {
    this.toggleOp.emit();
    let translatedParam = this.translateOption(selectedParam)
    this.showOp.emit({ selectedParam: translatedParam, i, j });
  }

  onOperatorSelected(operator: any): void {
    this.showOpDropdown = !this.showOpDropdown;
    const previousOperator = this.selectedOperator;
    this.selectedOperator = operator;
    if (this.ruleInputs === 'formuladatepicker') {
      if (previousOperator !== 'between' && operator === 'between') {
        const end = new Date();
        const endDay = String(end.getDate()).padStart(2, '0');
        const endMonth = String(end.getMonth() + 1).padStart(2, '0');
        const endYear = end.getFullYear();
        const endDate = `${endDay}/${endMonth}/${endYear}`
        const start = new Date(end);
        start.setDate(start.getDate() - 2);
        const startDay = String(start.getDate()).padStart(2, '0');
        const startMonth = String(start.getMonth() + 1).padStart(2, '0');
        const startYear = start.getFullYear();
        const startDate = `${startDay}/${startMonth}/${startYear}`
        this.dateValue = null;
        this.editValue = '';
        this.userInputValue = `${startDate} - ${endDate}`;
      } else {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        this.userInputValue = `${day}/${month}/${year}`;
        this.dateValue = null;
        this.editValue = '';
      }
    } else if (this.ruleInputs === 'text' && this.inputType === 'integer') {
      if (this.selectedOperator === 'between') {
        this.numValues = [0, 1];
        this.userInputValue = this.numValues.join(' ~ ')
      } else {
        this.userInputValue = 0;
      }
    } else if (this.ruleInputs === 'lookup') {
      const single = ['equal', 'not_equal'];
      const multi = ['in', 'not_in']
      if (single.includes(operator) && single.includes(previousOperator)) {
        if (previousOperator !== operator) {
          this.lookupValue = '';
          this.lookupDesc = ''
          this.defaultLookup = '';
          this.userInputValue = '';
        } else {
        }
      } else if (multi.includes(operator) && multi.includes(previousOperator)) {
        if (previousOperator !== operator) {
          this.lookupValue = '';
          this.lookupDesc = ''
          this.defaultLookup = '';
          this.userInputValue = '';
        } else {
        }
      } else {
        this.lookupValue = '';
        this.lookupDesc = ''
        this.defaultLookup = '';
        this.userInputValue = '';
      }
    }

    if (this.selectedOperator === 'isnull' || this.selectedOperator === 'isnotnull') {
      this.opNull = true;
      this.userInputValue = '';
    } else {
      this.opNull = false;
    }

    this.closeDropdown.emit();
    this.valueChanged.emit({ isChanged: true, op: this.selectedOperator, value: this.userInputValue, input: this.ruleInputs, row: this.selectedIndexI, col: this.selectedIndexJ });
  }

  dateValueText(value: any): any {
    const containsSpecialCharacters = /[\[\]\+\-]/.test(value);
    if (!this.isDatePickerMode) {
      if (containsSpecialCharacters) {
        const slicedValue = value.slice(1, -1);
        if (
          this.dateSelection.dateSelection.some(
            (selection) => selection.value === slicedValue
          )
        ) {
          return 'general.' + value;
        }
      }
    } else {
      return value;
    }
  }

  onFocus() {
    this.isFocus = !this.isFocus;
    if (this.isFocus === true) {
      this.blurOthers.emit({ i: this.selectedIndexI, j: this.selectedIndexJ });
    } else {
      this.blurOthers.emit({ i: null, j: null });
    }
    this.ispopupChange.emit(this.isFocus);
  }

  onValueChange(value: any, input: string, i: number, j: number): void {
    this.isChanged = true;
    this.valueChanged.emit({ isChanged: this.isChanged, op: this.selectedOperator, value: value, input: input, row: i, col: j });
  }

  handleClick(event: MouseEvent) {
    event.stopPropagation();
  }

  updateInteger(value: any, op: string, input: string, i: number, j: number, clickedPart: string) {
    value = value.toString().replace(/[^0-9]/g, '');

    if (op === 'between') {
      if (clickedPart === 'first') {
        this.splitValues[0] = value;
        this.isChanged = true;
        this.valueChanged.emit({ isChanged: this.isChanged, op: this.selectedOperator, value: value, input: input, row: i, col: j });

        if (this.numValues[0] >= this.numValues[1]) {
          value = parseInt(value);
          this.numValues[1] = value + 1;
          this.splitValues[1] = value + 1;
        }
      } else if (clickedPart === 'second') {
        value = this.numValues[1];
        this.splitValues[1] = value;
        this.isChanged = true;
        this.valueChanged.emit({ isChanged: this.isChanged, op: this.selectedOperator, value: value, input: input, row: i, col: j });
      }
      this.userInputValue = this.splitValues.join(' ~ ');
      this.onValueChange(this.userInputValue, input, i, j);
    } else {
      if (value === null) {
        this.numericValue = 0;
        this.userInputValue = 0;
      } else if (op === 'isnull' || op === 'isnotnull') {
        this.userInputValue = '';
      } else {
        this.userInputValue = value;
      }
      this.onValueChange(this.userInputValue, input, i, j);
    }
  }

  validateInteger(event: any): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/[^0-9]/g, '');

    if (value !== input.value) {
      input.value = value;
    }

    this.numericValue = value ? +value : null;
  }

  updateCheckbox(param: string, option: any, event: any, input: string, i: number, j: number) {
    const predefinedOrder: string[] = [];
    const dataItem = this.formData.find(item => this.translateOption(item.fieldName) === param);

    if (dataItem) {
      const dataValues = Object.values(dataItem.values);
      dataValues.forEach(value => {
        const stringValue = value.toString();
        const dotIndex = stringValue.indexOf('.');
        if (dotIndex !== -1) {
          const extractedValue = 'general.' + stringValue.substring(dotIndex + 1);
          predefinedOrder.push(extractedValue);
        }
      });
    }

    // Ensure userInputValueNew is only pushed once
    if (this.ruleInputs === 'checkbox' && !this.userInputValueNewPushed && this.userInputValueNew !== undefined) {
      this.selectedOptions = [...this.userInputValueNew];
      this.userInputValueNewPushed = true;
    }

    // Update selectedOptions based on checkbox state
    if (event.target.checked) {
      if (!this.selectedOptions.includes(option)) {
        this.selectedOptions.push(option);
      }
    } else {
      const index = this.selectedOptions.indexOf(option);
      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
      }
    }

    // Sort and filter selectedOptions
    this.selectedOptions.sort((a, b) => predefinedOrder.indexOf(a) - predefinedOrder.indexOf(b));
    this.selectedOptions = this.selectedOptions.filter(option => option);

    // Call the onValueChange method to propagate changes
    this.onValueChange(this.selectedOptions.join(', '), input, i, j);
  }

  edit(value: string, input: string, op: string) {
    if (value && value.includes('(')) {
      this.dateModeChange(false)
    } else {
      this.dateModeChange(true)
    }

    this.closeDropdown.emit();
    this.onFocus();
    this.isDateBoolean = this.isDate(value);
    this.popup = true;
    this.isPopup.emit(this.popup);

    if (value && this.isDate(value) === false) {
      this.checkRegex(value);
    } else {
      this.editDateValue = value;
    }

    const exclusions = ['equal', 'not_equal'];

    if (op === 'between') {
      this.isBetween = false;
    } else {
      this.isBetween = true;
    }

    if (!exclusions.includes(op)) {
      this.formulaBuild = false
    } else {
      this.formulaBuild = true;
    }
    this.cd.detectChanges();
  }

  isDate(value: string): boolean {
    const datePattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    const dateRangePattern = /^\d{2}\/\d{2}\/\d{4} - \d{2}\/\d{2}\/\d{4}$/;

    if (this.selectedOperator === 'between') {
      if (dateRangePattern.test(value)) {
        return true;
      }
      return false;
    } else {
      if (datePattern.test(value)) {
        return true;
      }
      return false;
    }
  }

  checkRegex(value: string) {
    const regex = /\[([^\]]+)\]([\+\-]?)(\d+)?/;
    const match = value.match(regex);

    if (match) {
      this.isDatePickerMode = false;
      const contentInsideBrackets = this.getDateSelectionName(match[1]);
      const operator = ` ${match[2]} ` || '';
      const digits = match[3] || '';

      if (match[2] === '' || match[2] === undefined) {
        this.matching = true;
        this.isModify = false;
      } else {
        this.isModify = true;
        this.matching = false;
      }

      this.shareValueService.setData({
        operator,
        digits,
      });

      this.editValue = `${contentInsideBrackets}`;
      this.dateCalcValue = `${operator} ${digits}`;
    } else {
      this.editValue = value;
      const operator = '';
      const digits = '';
      this.dateCalcValue = `${operator} ${digits}`;
      this.shareValueService.setData({
        operator,
        digits,
      });
    }
  }

  getDateSelectionName(valueToFind: string): string | undefined {
    const selectedDate = this.dateSelection.dateSelection.find(
      (date) => date.value === valueToFind
    );
    return selectedDate ? selectedDate.name : valueToFind;
  }

  closePopup() {
    this.popup = false;
    this.isFocus = false;
    this.ispopupChange.emit(this.popup);
    this.isPopup.emit(this.popup);
    this.clickedRow = null;
    this.clickedColumn = null;
    this.isReset.emit({ clickedRow: this.clickedRow, clickedColumn: this.clickedColumn });
  }

  updateValue(newValue: any, checkChange: boolean, input: string, i: number, j: number) {
    if (this.userInputValue === undefined || this.userInputValue === '' || this.userInputValue === null) {
      this.error = true;
      this.errorMessage = 'msg.filter_datepicker_empty';
      this.userInputValue = undefined;
    } else {
      this.error = false;
      this.valueChanged.emit({ isChanged: checkChange, op: this.selectedOperator, value: newValue, input, row: i, col: j });
      this.closePopup();
    }
  }

  dateModeChange(value: boolean) {
    this.isDatePickerMode = value;
    this.isDateModeChange.emit(this.isDatePickerMode);
  }

  dateFormula(event: { date: string; optionNameWithCalc: string }) {
    this.eventDate = event.date;
    this.eventOption = event.optionNameWithCalc;
  }

  //Update new Date
  submitValue(param: string, input: string, op: string, value: string, i: number, j: number): any {
    switch (input) {
      case 'formuladatepicker':
        if (this.isDatePickerMode) {
          this.dateValue = null;
          if (this.newDate !== null) {
            this.userInputValue = this.newDate;
          }
          this.updateValue(this.userInputValue, this.isChanged, this.ruleInputs, i, j);
        } else if (!this.isDatePickerMode) {
          if (this.isModify) {
            this.userInputValue = this.eventOption + ` (${this.eventDate})`;
            this.dateValue = this.eventDate;
          } else {
            this.userInputValue = this.eventOption + ` (${this.eventDate})`;
            this.dateValue = this.eventDate;
          }
          this.updateValue(this.userInputValue, this.isChanged, this.ruleInputs, i, j);
        }
    }
  }

  extractValueText(input: string): any {
    if (input && input.includes('(')) {
      const result = input.match(/^.*?(?=\s*\()/);
      return result[0];
    } else {
      return input;
    }
  }

  handleNewDate(newDate: string): void {
    this.newDate = newDate;
  }

  handleModifyDate(isModify: boolean): void {
    this.isModify = isModify;
  }

  setDropdown() {
    const dropdownItemsValue = this.formData.filter(
      (item) => item.values && Object.keys(item.values).length > 0
    );

    const matchObj = dropdownItemsValue.filter(
      (item) => this.translateOption(item.fieldName) === this.selectedParam
    );
    const allValuesForFieldName = matchObj.flatMap((item) =>
      Object.values(item.values || [])
    );
    this.itemValue = allValuesForFieldName;
  }

  toggleDropdown() {
    this.toggleValue.emit();
    this.onFocus();
    this.setDropdown();
    this.showValue = !this.showValue;
  }

  onValueSelect(value: string): void {
    this.onFocus();
    this.showValue = !this.showValue;
    this.userInputValue = value;
    this.closeDropdown.emit();
    this.valueChanged.emit({ isChanged: true, op: this.selectedOperator, value: this.userInputValue, input: this.ruleInputs, row: this.selectedIndexI, col: this.selectedIndexJ });
  }

  showBoolean() {
    let param = this.selectedParam;
    const booleanFilter = this.formData.filter(
      (item) => item.values && Object.keys(item.values).length > 0
    );

    const matchValue = booleanFilter.filter(
      (item) => this.translateOption(item.fieldName) === param
    );
    const allValuesForFieldName = matchValue.flatMap((item) =>
      Object.values(item.values || [])
    );
    return allValuesForFieldName;
  }

  translateOption(option: string): string {
    if (option) {
      return this.translateService.instant(option);
    } else {
      return option;
    }
  }

  getCheckboxOptions() {
    let param = this.selectedParam;
    const checkboxFilter = this.formData.filter(
      (item) => item.values && Object.keys(item.values).length > 0
    );

    const matchValue = checkboxFilter.filter(
      (item) => this.translateOption(item.fieldName) === param
    );

    const allValuesForFieldName = matchValue.flatMap((item) =>
      Object.values((item.values) || [])
    );
    return allValuesForFieldName;
  }

  getCheckboxValue() {
    if (this.userInputValue) {
      let arrayOfValues = [...new Set(this.userInputValue.trim().split(',').map(value => value.trim()))];
      this.userInputValueNew = arrayOfValues;
      return this.userInputValueNew;
    }
    return [];
  }

  isChecked(option: any): boolean {
    if (this.userInputValue) {
      return this.userInputValue.split(', ').includes(option);
    } else {
      return this.selectedOptions.includes(option);
    }
  }
}
