/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EdmSchemaElementKind = 'None' | 'TypeDefinition' | 'Term' | 'Action' | 'EntityContainer' | 'Function';

export const EdmSchemaElementKind = {
    None: 'None' as EdmSchemaElementKind,
    TypeDefinition: 'TypeDefinition' as EdmSchemaElementKind,
    Term: 'Term' as EdmSchemaElementKind,
    Action: 'Action' as EdmSchemaElementKind,
    EntityContainer: 'EntityContainer' as EdmSchemaElementKind,
    Function: 'Function' as EdmSchemaElementKind
};