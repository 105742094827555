import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextSetting } from '../model/InputTextSetting.model';
import { InputTypes } from '../../input/enum/InputTypes';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { FormService } from '../../form/form.service';
import { ElementService } from 'src/app/shared/services/element.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatInputModule, MatIconModule, NgxMatIntlTelInputComponent, CalendarModule, TranslateModule, NgClass]
})
export class InputTextComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() inputTextSetting?: InputTextSetting;
  @Input() disabled: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;
  @Output() onValueChange = new EventEmitter<any>();

  @ViewChild('calendar', {static: false}) calendar: Calendar;
  @ViewChild('displayDateTime', {static: false}) dateTimeEl: ElementRef;
  @ViewChild('phone') phoneElement!:NgxMatIntlTelInputComponent;

  INPUTTYPES = InputTypes;
  dateValue: any;
  displayDateValue: any;
  displayCalendar: boolean = false;
  params!: ICellRendererParams;
  formGrpCtrl: any;
  element: HTMLInputElement;
  currDisplayValue: any;

  dependency$: Subscription;
  countries: string[] = null;
  authData$: Subscription;

  constructor(
    private datePipe: DatePipe,
    private formService: FormService,
    private elementService: ElementService,
  ) { }

  ngOnInit(): void {
    this.countries = this.formService.settingData.countries;
    this.formGrpCtrl = this.formGroup.controls[this.inputTextSetting.name];

    // Set Dependency
    if(this.formGroup && this.inputTextSetting) {
      this.dependency$ = this.formService.setDependency(this.formGroup, this.inputTextSetting);
    }
  }

  ngAfterViewInit(): void {
    // Get Element
    if(this.inputTextSetting.id && this.elementService.getElementById(this.inputTextSetting.id)) {
      this.element = this.elementService.getElementById(this.inputTextSetting.id);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.formData?.currentValue) {
      // Set and format datetime
      if(changes.formData.currentValue[this.inputTextSetting.name] && (this.inputTextSetting.type === this.INPUTTYPES.date || this.inputTextSetting.type === this.INPUTTYPES.dateTimeLocal || this.inputTextSetting.type === this.INPUTTYPES.time)) {
        let utcDateFormat = changes.formData.currentValue[this.inputTextSetting.name];

        this.displayDateValue = this.datePipe.transform(utcDateFormat, this.inputTextSetting.dateFormat);
        changes.formData.currentValue[this.inputTextSetting.name] = new Date(utcDateFormat);
      }

      if(changes.formData.currentValue[this.inputTextSetting.name]) {
        this.formGroup.controls[this.inputTextSetting.name].setValue(changes.formData.currentValue[this.inputTextSetting.name]);
      }
    }

    if (changes.disabled && this.formGroup && this.inputTextSetting) {
      const control = this.formGroup.get(this.inputTextSetting.name);
      if (control) {
        if (this.disabled) {
          control.disable();
        } else {
          control.enable();
        }
      }
    }
  }

  onFocusOut() {
    let displayDateTime: string = this.dateTimeEl?.nativeElement.value? this.dateTimeEl.nativeElement.value: null;
    if(displayDateTime !== this.currDisplayValue) {
      this.displayDateValue = null;
      this.currDisplayValue = null;
      this.dateValue = null;
    }
  }

  showTimePicker() {
    if(!this.formGroup.get(this.inputTextSetting.name).disabled && !this.element?.disabled) {
      this.calendar.showOverlay();
    }
    this.calendar.cd.detectChanges();
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return false;
  }

  onDateSelect() {
    this.displayDateValue = this.datePipe.transform(this.formGroup.get(this.inputTextSetting.name).value, this.inputTextSetting.dateFormat);
    this.currDisplayValue = this.displayDateValue;
  }

  ngOnDestroy(): void {
    this.dependency$?.unsubscribe();
    this.authData$?.unsubscribe();
  }
}
