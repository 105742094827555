import { Pipe, PipeTransform } from '@angular/core';
import { SysFormatType } from '../enums/sysFormatType';
import { FormService } from '../component/form/form.service';

@Pipe({
  name: 'sysFormat'
})
export class SysFormatPipe implements PipeTransform {

  constructor(private formService: FormService) {}

  transform(value: any, type: SysFormatType): any {
    const sysOption = this.findSysOption(type);
    let formattedVal = value;

    switch(sysOption.groupCode) {
      case 'DECIMAL':
        formattedVal = this.convertToDec(sysOption, value);
        break;
      default:
        break;
    }
    
    return formattedVal;
  }

  private findSysOption(setCode: string) {
    let sysOptions = [];
    this.formService.getSysOptions().forEach(grpOption => 
      sysOptions = [...sysOptions, ...grpOption.options.map(option => {return{ groupCode: grpOption.groupCode, ...option }})]
    );
    return sysOptions?.find(option => option.setCode === setCode);
  }

  private convertToDec(sysOption: any, value: any) {
    if (typeof value === 'number') {
      return value.toFixed(parseInt(sysOption?.setValue));
    }
    return value;
  }
}
