import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) return value;

    let foundFirstAlpha = false;
    let result = value.split('').map(char => {
      if (!foundFirstAlpha && /[a-zA-Z]/.test(char)) {
        foundFirstAlpha = true;
        return char.toUpperCase();
      }
      return char;
    }).join('');

    return result;
  }

}
