import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TreeSetting } from './model/treeSetting.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tree',
  standalone: true,
  imports: [CommonModule, TreeModule, MatTreeModule, MatIconModule, MatButtonModule, NgFor, TranslateModule],
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, OnChanges{
  @Input() treeData: TreeNode[];
  @Input() setting?: TreeSetting;
  @Input() selectedNode?: any;
  @Output() onSelectionChanged = new EventEmitter<any>();

  selectedFile: TreeNode;
  // activeNode: any;

  treeControl: NestedTreeControl<any>;
  dataSource: MatTreeNestedDataSource<any>;
  hasChild: Function;
  hasLevel: Function;

  constructor(private translateService: TranslateService,
    private cd: ChangeDetectorRef){}

  ngOnInit(): void {
    this.setting = {
      rootTitle: this.setting?.rootTitle? this.setting.rootTitle: '',
      level: this.setting?.level? this.setting.level: 0,
      childKey: this.setting?.childKey? this.setting.childKey: 'Children',
      descCaption: this.setting?.descCaption? this.setting.descCaption: '',
      desc: this.setting?.desc? this.setting.desc: '',
      id: this.setting?.id? this.setting.id: ''
    }

    this.treeControl = new NestedTreeControl<any>(node => node[this.setting.childKey]);
    this.dataSource = new MatTreeNestedDataSource<any>();
    this.hasChild = (_: any, node: any) => node[this.setting.childKey] && node[this.setting.childKey].length > 0;
    this.hasLevel = (_: any, node: any) => node.level < this.setting.level;

    this.setData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedNode?.currentValue) {
      this.selectedNode = changes.selectedNode.currentValue;
    }

    if (changes.treeData?.currentValue) {
      this.treeData = changes.treeData.currentValue;
      this.setData();
    }
  }

  setData() {
    if(this.treeData) {
      this.treeData = this.setLevel(this.treeData);
      let data = this.treeData;

      if(this.dataSource) {
        this.dataSource.data = null;
        this.dataSource.data = data;
      }
    }

    if(this.treeData && this.treeData[0] && this.treeControl) {
      this.treeControl.expand(this.treeData[0]);
    }
  }

  setLevel(treeData: any, level: number = 0) {
    if(treeData && treeData.length > 0) {
      treeData.forEach((data: any) => {
        if(data[this.setting.childKey] && data[this.setting.childKey].length > 0) {
          this.setLevel(data[this.setting.childKey], level + 1);
        }

        if(data[this.setting.childKey] && data[this.setting.childKey][0] && data[this.setting.childKey][0][this.setting.childKey]) {
          data.childVisible = true;
        } else {
          data.childVisible = false;
        }
        data.level = level;
      });
    }
    return treeData;
  }

  getValue(data: any) {
    if(this.setting?.descCaption) {
      let desckey = this.setting.descCaption;

      if(data[desckey] && this.translateService.instant(data[desckey]) !== data[desckey]) {
        return this.translateService.instant(data[desckey]);
      } else if(this.setting?.desc) {
        return data[this.setting?.desc];
      }
    }

    return '';
  }
  
  onClickTreeNode(event: any, node: any) {
    this.selectedNode = node;
    this.onSelectionChanged.emit(node);
  }
}
