import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { environment } from 'src/environments/environment';
import { OrderDetailService } from '../../services/orderdetail.service';
import { UtilsService, decodeBase64 } from 'src/app/core/services/utils.service';
import { CommonModule } from '@angular/common';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { Mode } from 'src/app/shared/enums/mode';
import { AuthService } from 'src/app/core/services/auth.service';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'revertcutoff-form',
  templateUrl: './revertcutoff-form.component.html',
  styleUrls: ['./revertcutoff-form.component.scss'],
  standalone: true,
  imports: [FormComponent, CommonModule, FormsModule]
})

export class RevertCutoffFormComponent implements OnInit {
  page: Page;
  pages: Page[];
  page$: Subscription;

  activityId: string;

  isEditMode: boolean;

  formDetail: any = null;
  component: any;
  orderDetailData: any = {

  };

  formSetting: FormSetting = {
    formCode: FormCode.revertcutoff,
    content: [
      {
        inputText: {
          name: 'orderNo',
          label: 'Order No',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'activityId',
          label: 'ActivityID',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'orderTranId',
          label: 'OrderTranId',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'salesTranId',
          label: 'SalesTranId',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'payTranid',
          label: 'PayTranid',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'locShortDesc',
          label: 'Store Name',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'tranDate',
          label: 'TranDate',
          type: InputTypes.dateTimeLocal,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'sourceFlag',
          label: 'OrderSourceFlag',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'statusFlag',
          label: 'Activity Status',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'lastActivityType',
          label: 'Last Activity Type',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'cutOffFlag',
          label: 'CutOffFlag',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'checkOrderUrl',
          label: 'CheckOrderUrl',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'netAmt',
          label: 'NetAmt',
          type: InputTypes.number,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'orderDesc',
          label: 'OrderDesc',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'mobileNo',
          label: 'MobileNo',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'refNo1',
          label: 'Payment RefNo1',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'refNo2',
          label: 'Payment RefNo2',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'payRefNo',
          label: 'Payment PayRefNo',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'paymentStatus',
          label: 'Payment Status',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'errorMsg',
          label: 'Payment ErrorMsg',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'tenderAmt',
          label: 'TenderAmt',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'linkId',
          label: 'LinkId',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'tableNo',
          label: 'TableNo',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'activeFlag',
          label: 'ActiveFlag',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'completeFlag',
          label: 'CompleteFlag',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
      {
        inputText: {
          name: 'cancelFlag',
          label: 'CancelFlag',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          minLength: 0,
          maxLength: 254,
          editable: false
        },
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }
  pageSub$: Subscription;

  constructor(
    private httpClient: HttpClient,
    private pageService: PageService,
    private customService: CustomService,
    private orderDetailService: OrderDetailService,
    private loaderService: LoaderService,
    private utilsService: UtilsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private formService: FormService
  ) {  }

  async ngOnInit() {
    console.log(this.page)
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.activityId = params['activityId'];
      console.log(params)
      if (this.activityId) {
        await this.getOrderDetail(this.activityId);
      }
    }
    // Check edit mode
    if(this.page && this.page?.queryParams?.mode) {
      this.isEditMode = this.page.queryParams.mode === Mode.edit? true: false;
    }
  }

  ngOnDestroy() {
    //this.page$.unsubscribe();
  }

  onToolbarAction(resp: ActionToolBarResp) {

  }

  async onSubmitForm(formGroup: UntypedFormGroup) {
  }

  async getOrderDetail(activityId: string) {
    this.loaderService.startLoading();
    let queryOpts: ODataRawQueryOptions = {
      filter: 'activityId eq ' + this.activityId
    };
    let result = await this.orderDetailService.getOdataOrderDetail(queryOpts, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.orderDetailData = result['value'][0];

      this.loaderService.stopLoading();
    }
  }

  async revertCutOff() {
    try {
      if (this.orderDetailData == null || this.orderDetailData.orderTranId == null) {
        alert("Data not ready. Please try again later.");

        return false;
      }

      this.loaderService.startLoading();
      let result = await this.RevertCutOffRequest();

      if (result == true) {
        alert("Successfully Revert CutOffFlag")
        await this.getOrderDetail(this.orderDetailData.activityId)
      } else {
        alert("Some error occured. Please try again later.")
      }

      this.loaderService.stopLoading();

      return result;
    } catch (ex) {
      console.log(ex);
      this.loaderService.stopLoading();
      return "";
    }
  }

  async RevertCutOffRequest() {
    let revertCutOffModel: RevertCutOffModel = {
      orderTranIds: [this.orderDetailData.orderTranId]
    }

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: '/api/support/RevertCutOffOrder?secretkey=YufrI3e1osW',
      hostPath: environment.frontApiUrl,
      headers: null,
      body: revertCutOffModel,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    let headers = new HttpHeaders();

    if (environment.apiVersion !== undefined && environment.apiVersion !== null) {
      headers = headers.set('api-version', String(environment.apiVersion));
    }

    if (environment.subscription !== undefined && environment.subscription !== null) {
      headers = headers.set('SubscriptionID', String(environment.subscription));
    }

    if (hMac !== undefined && hMac !== null) {
      headers = headers.set('X-Signature', String(hMac));
    }

    headers = headers.set('Content-type', "application/json");
    headers = headers.set('Accept', "application/json");

    return this.httpClient.request<boolean>(HttpMethod.POST, `${environment.frontApiUrl}/api/support/RevertCutOffOrder?secretkey=YufrI3e1osW`,
      {
        body: revertCutOffModel,
        withCredentials: false,
        headers: headers,
      }
    ).toPromise();
  }

  async resubmitOrder() {
    try {
      if (this.orderDetailData == null || this.orderDetailData.orderTranId == null) {
        alert("Data not ready. Please try again later.");

        return false;
      }

      this.loaderService.startLoading();
      let result = await this.ResubmitOrderRequest();

      if (result.length > 0) {
        alert("Successfully Resubmit Order to POS")
        await this.getOrderDetail(this.orderDetailData.activityId)
      } else {
        alert("Some error occured. Please try again later.")
      }

      this.loaderService.stopLoading();

      return result;
    } catch (ex) {
      console.log(ex);
      this.loaderService.stopLoading();
      return "";
    }
  }

  async ResubmitOrderRequest() {
    let requestPath: string = `/api/order/ZQResummitOrder?orderTranIds=${this.orderDetailData.orderTranId}&secretkey=${encodeURIComponent("XSJZ4XW16G")}`;

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: requestPath,
      hostPath: environment.frontApiUrl,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    let headers = new HttpHeaders();

    if (environment.apiVersion !== undefined && environment.apiVersion !== null) {
      headers = headers.set('api-version', String(environment.apiVersion));
    }

    if (environment.subscription !== undefined && environment.subscription !== null) {
      headers = headers.set('SubscriptionID', String(environment.subscription));
    }

    if (hMac !== undefined && hMac !== null) {
      headers = headers.set('X-Signature', String(hMac));
    }

    headers = headers.set('Content-type', "application/json");
    headers = headers.set('Accept', "application/json");

    return this.httpClient.request<Array<any>>(HttpMethod.POST, environment.frontApiUrl + requestPath,
      {
        withCredentials: false,
        headers: headers,
      }
    ).toPromise();
  }

  async resubmitSales() {
    try {
      if (this.orderDetailData == null || this.orderDetailData.orderTranId == null) {
        alert("Data not ready. Please try again later.");

        return false;
      }

      if (this.orderDetailData.salesTranId == null || this.orderDetailData.salesTranId == 0) {
        alert("Order must have SalesTranId to resubmit sales.");
        return false;
      }

      this.loaderService.startLoading();
      let result = await this.ResubmitSalesRequest();

      if (result.length > 0) {
        alert("Successfully Resubmit Sales to POS")
        await this.getOrderDetail(this.orderDetailData.activityId)
      } else {
        alert("Some error occured. Please try again later.")
      }

      this.loaderService.stopLoading();

      return result;
    } catch (ex) {
      console.log(ex);
      this.loaderService.stopLoading();
      return "";
    }
  }

  async ResubmitSalesRequest() {
    let requestPath: string = `/api/order/ZQResummitOrder?salesTranIds=${this.orderDetailData.salesTranId}&secretkey=${encodeURIComponent("XSJZ4XW16G")}`;

    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: requestPath,
      hostPath: environment.frontApiUrl,
      headers: null,
      httpHeaderType: undefined
    }

    let hMac = this.customService.assignHMac(cusReq);

    let headers = new HttpHeaders();

    if (environment.apiVersion !== undefined && environment.apiVersion !== null) {
      headers = headers.set('api-version', String(environment.apiVersion));
    }

    if (environment.subscription !== undefined && environment.subscription !== null) {
      headers = headers.set('SubscriptionID', String(environment.subscription));
    }

    if (hMac !== undefined && hMac !== null) {
      headers = headers.set('X-Signature', String(hMac));
    }

    headers = headers.set('Content-type', "application/json");
    headers = headers.set('Accept', "application/json");

    return this.httpClient.request<Array<any>>(HttpMethod.POST, environment.frontApiUrl + requestPath,
      {
        withCredentials: false,
        headers: headers,
      }
    ).toPromise();
  }

  async unlinkWebLinkToken(){

    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.orderManagement.UnlinkWeblinkToken,
      hostPath: environment.hostPath,
      queryParams: {
        activityId: this.activityId
      }
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    let msg: PopupMessage = {
      formCode: FormCode.revertcutoff,
      id: 'success',
      titleIcon: 'assets/base/icons/check-circle.svg',
      title: 'msg.web_link_token_unlinked',
      desc: "msg.web_link_token_unlinked_desc",
      actionBtnText: 'btn.okay'
    };
    this.dialog.open(PopupMessageComponent, {
      data: msg
    });
  }

  updateOrderStatus(){
    this.pageService.navigateByUrl(PageRoute.UPDATEORDERSTATUS_FORM, { activityId: this.activityId }, null, true);
  }

  openChildForm(event) {
    console.log('event: ', event)
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }

}

class RevertCutOffModel {
  orderTranIds?: Array<number>;
}
