import { Component, EventEmitter, Input,  OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormInputsComponent } from '../form/form-inputs/form-inputs.component';
import { ExpansionPanelSetting } from '../input/model/ExpansionPanelSetting.model';
import { UntypedFormGroup } from '@angular/forms';
import { ComponentService } from '../../services/component.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-expansion-panel',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, FormInputsComponent],
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit, OnDestroy {
  @Input() expansionPanelSetting: ExpansionPanelSetting;
  @Input() formData?: any;
  @Input() formGrp: UntypedFormGroup;
  @Output() onGenerateInput = new EventEmitter();
  @Output() onToggleChange = new EventEmitter();
  @Output() onButtonToggle = new EventEmitter();
  @Output() onDateChange = new EventEmitter();
  @Output() onTimeChange = new EventEmitter();
  @Output() onPeriodChange = new EventEmitter();

  toggleExpansionPanel$: Subscription;

  expanded: boolean;

  constructor(private componentService: ComponentService) {}

  ngOnInit(): void {
    this.expanded = this.expansionPanelSetting?.expand? true: false;

    this.toggleExpansionPanel$ = this.componentService.toggleExpansionPanel$.subscribe((event: {name: string, expand: boolean}) => {
      if(event.name === this.expansionPanelSetting.name) {
        this.expanded = event.expand;
        this.onButtonToggle.emit(this.expanded);
      }
    });
  }

  onGenerate(event: any) {
    this.onGenerateInput.emit(event);
  }

  onToggleChg(event: any) {
    this.onToggleChange.emit(event);
  }

  onBtnToggle(event: any) {
    this.onButtonToggle.emit(event);
  }

  onDateChg(event: any) {
    this.onDateChange.emit(event);
  }

  onTimeChg(event: any) {
    this.onTimeChange.emit(event);
  }

  onPeriodChg(event: any) {
    this.onPeriodChange.emit(event);
  }

  ngOnDestroy(): void {
    this.toggleExpansionPanel$?.unsubscribe();
  }
}
