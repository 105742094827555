import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColDef, IRowNode } from 'ag-grid-community';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Page } from 'src/app/shared/models/page.model';
import { LookDropInputSetting } from 'src/app/shared/component/input/model/LookDropInputSetting.model';
import { LookDropInputComponent } from 'src/app/shared/component/input/look-drop-input/look-drop-input.component';
import { UntypedFormGroup } from '@angular/forms';
import { CtryStateService } from 'src/swagger/base/openapi/api/ctryState.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';

@Component({
  selector: 'app-dropdown-bank',
  standalone: true,
  imports: [CommonModule, LookDropInputComponent],
  templateUrl: './dropdown-bank.component.html',
  styleUrls: ['./dropdown-bank.component.scss']
})
export class DropdownBankComponent implements OnInit {
  colDef: ColDef[];
  lookDropInputSetting: LookDropInputSetting;
  formGroup: UntypedFormGroup;
  formData: any;
  multiSelectData: any;
  rowData: any;
  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      { name: 'filter', text: 'toolbar.filter', input: true, clickable: false, sortNo: 1 },
    ]
  };
  constructor(private utilsService: UtilsService,    
    private customService: CustomService,
    private lookupMasterService: LookupMasterService
    ) {}

  ngOnInit(): void {
    // Set column
    this.colDef = [ 
      {
        field: "bankCode",
        headerName: 'Bank Code',
        sortable: true,
        filter: 'agTextColumnFilter'
      },
      {
        field: "bankName",
        headerName: 'Bank Name',
        sortable: true,
        filter: 'agTextColumnFilter'
      },    
    ];

    // Custom Setting
    this.lookDropInputSetting = {
      name: this.lookDropInputSetting.name ? this.lookDropInputSetting.name : 'bankCode',
      label: this.lookDropInputSetting.label ? this.lookDropInputSetting.label : '',
      valueKey: this.lookDropInputSetting.valueKey ? this.lookDropInputSetting.valueKey : 'bankCode',
      hintValueKey: this.lookDropInputSetting.hintValueKey ? this.lookDropInputSetting.hintValueKey : 'bankName',
      colDef: this.lookDropInputSetting.colDef? this.lookDropInputSetting.colDef: this.colDef,
      placeholder: this.lookDropInputSetting.placeholder ? this.lookDropInputSetting.placeholder : 'Bank',
      required: this.lookDropInputSetting.required ? this.lookDropInputSetting.required : false,
      multiSelect: this.lookDropInputSetting.multiSelect ? this.lookDropInputSetting.multiSelect : false,
      autoComplete: this.lookDropInputSetting.autoComplete === false ? false : true,
      styling: this.lookDropInputSetting.styling? this.lookDropInputSetting.styling: {},
      dependency: this.lookDropInputSetting.dependency? this.lookDropInputSetting.dependency: null
    }
    
    // Get data
    // this.lookDropInputSetting.dropdownOption = this.formatBankState(FULLBankState);

    // console.log(this.lookDropInputSetting.dropdownOption);
  }

  formatBank(array: any) {  
    let result = [];
    let states = []

    // array.forEach((obj: any) => {
    //   if(obj[BankState.States] && obj[BankState.States] instanceof Array) {
    //     obj[BankState.States].forEach((state: any) => {
    //       states.push({
    //         [BankState.StateName]: state.name,
    //         [BankState.StateCode]: state.code,
    //         [BankState.Subdivision]: state.subdivision,
    //       }); 
    //     });
    //   }

    //   states.forEach(state => {
    //     states.push({...obj, ...state});
    //   });
    // });

    return result;
  }
  async getBank(filter?: string, top?: number) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.bank.OdataBank,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
      queryParams: {
        $count: true,
        $filter: filter? filter: undefined,
        $top: top? top: undefined
      }
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  async onLookup(data: any) {
    // call api and set row
    this.rowData = (await this.getBank())['value'];
  }
  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
  onSelected(selectedNodes: IRowNode[]) {

    if(this.lookDropInputSetting?.multiSelect) {
      // set display value
      this.multiSelectData = selectedNodes.map((node: IRowNode) => {return {node: node, value: node.data['bankCode']}});
    } 
    
  }
  async onLookupToolbarAction(data: any) {
    if(data.name === 'filter') {
      let filterBy = this.lookDropInputSetting.colDef.filter((col: ColDef) => col.field !== 'checkbox').map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(data.data, filterBy);
      this.rowData = (await this.getBank(filter))['value'];
    }
  }
}
