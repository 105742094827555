<form style="height: 100%" [formGroup]="formGroup">
  <div class="group-selection-layout" [ngClass]="{'editMode': !editMode}">
    <div class="header">
      <span>{{ "report.grouping" | translate }}</span>
    </div>
    <div class="search-wrapper">
      <p *ngIf="editMode">{{ "report.select_group_to_add" | translate }}</p>
      <app-searchbar [searchTitle]="'report.item_name'" (searchResult)="onSearch($event)"></app-searchbar>
    </div>
    <div class="group-container">
      <div class="selected-container" [style.height.px]="topHeight - 180" #topDiv>
        <div class="list block" cdkDropList #selected="cdkDropList" [cdkDropListData]="selectedGroups"
          [cdkDropListConnectedTo]="[selection]" [cdkDropListDisabled]="!editMode"
          (cdkDropListDropped)="onDrop($event)">
          <div class="empty-grp" *ngIf="selectedGroups?.length <= 0; else selectedList">
            <p>{{ "report.select_group" | translate }}</p>
          </div>
          <ng-template #selectedList>
            <div class="grp-itm" cdkDrag *ngFor="let item of selectedGroups; let i = index;"
              (click)="!editMode? onQuitEditMode(): onDeselected($event, item)">
              <img class="first-icon" *ngIf="editMode && isMobile"
                [src]="'assets/base/icons/' + (i === 0? 'sortAcsList.svg': 'sortDescList.svg')" cdkDragHandle />
              <img class="first-icon" *ngIf="editMode && !isMobile "
                [src]="'assets/base/icons/' + (i === 0? 'sortAcsList.svg': 'sortDescList.svg')" />
              <p>{{ item['groupName'] | uppercaseTranslate }}</p>
              <app-toggle-input [toggleInputSetting]="toggleInputSettings[i]" [formGroup]="formGroup"
                (click)="onClickToggle($event);" (onToggleChange)="onToggleChange($event)"></app-toggle-input>
            </div>
          </ng-template>
        </div>
      </div>

      <app-resizable-divider class="divider" (resize)="onResize($event)"></app-resizable-divider>

      <div class="selection-container" [style.height.px]="bottomHeight" #bottomDiv>
        <div class="list-wrapper">
          <div class="list" cdkDropList #selection="cdkDropList" [cdkDropListData]="displaySelection"
            [cdkDropListConnectedTo]="[selected]" [cdkDropListDisabled]="!editMode"
            (cdkDropListDropped)="onDrop($event)">
            <div class="grp-itm" cdkDrag *ngFor="let item of displaySelection" [ngClass]="{'disabled': editMode}"
              (click)="!editMode? onQuitEditMode(): onSelected(item)">
              <p>{{ item['groupName'] | uppercaseTranslate }}</p>
              <img *ngIf="isMobile" style="cursor: move;" src="assets/base/icons/addList.svg" cdkDragHandle />
              <img *ngIf="!isMobile" style="cursor: move;" src="assets/base/icons/addList.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
