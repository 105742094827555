export enum agGridTemplate {
    sourceFlag = "sourceFlag",
    orderStatus = "orderStatus",
    memberType = "memberType",
    status = "status",
    salesMode = "salesMode",
    salesException = "salesException",
    lineMode = "lineMode",
    yesno = "yesno",
    secMins = "secMins"
}
