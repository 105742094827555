import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GetRowIdFunc, GetRowIdParams, StatusPanelDef } from 'ag-grid-community';

import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { OrderDetailService } from '../../services/orderdetail.service';
import * as moment from 'moment';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterComponent } from '../../../shared/component/filter/filter.component';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-syspendingjob-listing',
  templateUrl: './syspendingjob-listing.component.html',
  styleUrls: ['./syspendingjob-listing.component.scss'],
  standalone: true,
  imports: [ListingComponent, FilterComponent]
})
export class SysPendingJobListingComponent implements OnInit {
  @Input() page: Page;

  pages: Page[];
  screenContainer: any;
  rowData: any = null;
  serverSideDataSource: any;
  getRowId: GetRowIdFunc;
  formDetail: any = null;
  listingSetting: ListingSetting;
  activityId: string;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    editable: false,
    sortable: true,
    filterParams: {
      browserDatePicker: true
    }
  };

  colDef: ColDef[] = [
    { field: 'jobId', headerName: 'Job Id', filter: 'agTextColumnFilter' },
    { field: 'queueName', headerName: 'Queue Name', filter: 'agTextColumnFilter', },
    { field: 'subscriptionId', headerName: 'Subscription ID', filter: 'agTextColumnFilter' },
    { field: 'jobType', headerName: 'Job Type', filter: 'agTextColumnFilter' },
    { field: 'jobStatus', headerName: 'Job Status', filter: 'agTextColumnFilter' },
    { field: 'priorityNo', headerName: 'Priority No', filter: 'agTextColumnFilter' },
    { field: 'requestBody', headerName: 'Request Body', filter: 'agTextColumnFilter' },
    { field: 'responseBody', headerName: 'Response Body', filter: 'agTextColumnFilter' },
    { field: 'docNo', headerName: 'Document No', filter: 'agTextColumnFilter' },
    {
      field: 'docDate', headerName: 'Document Date', filter: 'agDateColumnFilter',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filterParams: {
        browserDatePicker: true
      }
    },
    { field: 'refCode1', headerName: 'Reference Code 1', filter: 'agTextColumnFilter' },
    { field: 'refCode2', headerName: 'Reference Code 2', filter: 'agTextColumnFilter' },
    { field: 'startTime', headerName: 'Start Time', filter: 'agTextColumnFilter' },
  ];

  statusBar: { statusPanels: StatusPanelDef[]; } = {
    statusPanels: [
      {
        statusPanel: 'totalRecordRenderer',
        align: 'left'
      },
      {
        statusPanel: 'paginatorRenderer',
        align: 'right',
        statusPanelParams: {
          page: FormCode.activityloglisting
        }
      },

    ],
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
    ],
  };

  constructor(
    private pageService: PageService,
    private orderDetailService: OrderDetailService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private formService: FormService
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe(pages => {
      this.pages = pages;
      this.screenContainer = document.getElementById("general-container");
    });
  }

  async ngOnInit() {
    //this.getActivityLog();
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.listingSetting = {
      rowModelType: RowModelType.clientSide
    }
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.activityId = params['activityId'];
      if (this.activityId) {
        await this.getSysPendingJob();
      }
    }
  }

  async getSysPendingJob() {
    let queryOpts: ODataRawQueryOptions = {
      filter: 'activityId eq ' + this.activityId
    };
    let result = await this.orderDetailService.getOdataOrderDetail(queryOpts, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      let orderDetail = result['value'][0];
      console.log(orderDetail)
      queryOpts = {
        filter: 'docNo eq ' + orderDetail.orderTranId
      };
      result = await this.orderDetailService.getOdataSysPendingJob(queryOpts, this.formDetail.formCode);
      if (result instanceof HttpErrorResponse === false){
        this.rowData = result['value'];
      }

    }
  }

  onToolbarAction(value: ActionToolBarResp) {

  }
  async onFilter(odataFilterString: any) {

    let queryOpts: ODataRawQueryOptions = {
      filter: odataFilterString
    };
    let data = await this.orderDetailService.getOdataOrderDetail(queryOpts, this.formDetail.formCode);
    if (data instanceof HttpErrorResponse === false) {
      console.log(data['value']);

      this.rowData = data && data['value'] ? data['value'] : [];
    }
  }
  onPageSizeChange(pageSize: number) {

  }
}
