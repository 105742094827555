import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';

import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { PageService } from 'src/app/shared/services/page.service';
import { ChannelAddressEntryComponent } from '../channel-address-entry/channel-address-entry.component';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { PopupForm } from 'src/app/shared/models/popupForm.model';
import { Page } from 'src/app/shared/models/page.model';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingActionButton } from 'src/app/shared/component/listing/data/listing-action-button.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { HttpErrorResponse } from '@angular/common/http';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { LocalChannelService } from '../localChannel.service';
import { Mode } from 'src/app/shared/enums/mode';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { FormService } from 'src/app/shared/component/form/form.service';
import { LookupMasterService } from 'src/app/shared/services/lookup-master.service';
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';

@Component({
  selector: 'app-channel-address-listing',
  templateUrl: './channel-address-listing.component.html',
  styleUrls: ['./channel-address-listing.component.scss'],
  standalone: true,
  imports: [ListingComponent]

})
export class ChannelAddressListingComponent implements OnInit, OnDestroy {
  page: Page;
  serverSideDataSource: any;
  channelId: number;
  selectedRowData: any;

  pageRoute: any = PageRoute;
  formDetail: any = null;
  refresh$: Subscription;
  popupMsg$: Subscription;
  actionBtn$: Subscription;
  rowData: any = null;
  listingSetting: ListingSetting;
  // Column data
  colDef: ColDef[] = [
    listingCheckbox,
    {
      ...listingActionButton,
      cellRendererParams: {
        type: ButtonRendererTypeEnum.duplicate
      },
    },
    {
      ...listingRouterLink,
      field: 'channelId',
      headerName: 'Channel Id',
      cellRendererParams: {
        inRouterLink: {
          route: '/channel/channeladdr/entry',
          params: ['channelId', 'addressId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      }
    },
    {
      field: 'searchLabel',
      headerName: 'Search Label',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'addUnit',
      headerName: 'Add Unit',
      filter: true,
      initialFlex: 1
    }
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      ...toolbarListing,
      { name: 'new', icon: 'assets/base/icons/plus.svg', text: 'toolbar.new_channeladdr', checkPermission: [AttributeCode.NEW], collapseTo: 'others', sortNo: 2 },
    ]
  }

  constructor(
    public translate: TranslateService,
    private popupFormService: PopupFormService,
    private pageService: PageService,
    private channelService: LocalChannelService,
    private formService: FormService,
    private lookupMasterService: LookupMasterService
  ) {}

  async ngOnInit() {
    // Get route parameter
    console.log('this.page: ', this.page)
    let listingId = this.pageService.getListingId(this.page);
    console.log('listingId: ', listingId)
    this.channelId = +decodeBase64(decodeURIComponent(listingId));
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    let params = this.pageService.getParameterDecode(this.page);
    this.channelId = params['channelId'];
    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
    await this.getChannelAddr();

    // Listen to refresh event
    // this.refresh$ = this.pageService.refreshListing$.subscribe((formCode: FormCode) => {
    //   if(formCode === this.page.form.formCode) {
    //    this.getChannelAddr();
    //   }
    // });

    // Listen to popup message event
    this.popupMsg$ = this.pageService.actionPopupMsg$.subscribe(async (data: any) => {
      if(data.formCode === this.page.form.formCode && data.id === 'success') {
       await this.getChannelAddr();
      }
    });

    // Listen to Action Button event
    this.actionBtn$ = this.pageService.actionBtnListing$.subscribe((data: any) => {
      if(data.formCode === this.page.form.formCode && data.action === ButtonRendererTypeEnum.save) {
        // save action
       }
    });
  }

  async getChannelAddr(filter?: ODataRawQueryOptions) {
    let result = await this.channelService.getOdataChannelAddr(this.channelId, null, this.formDetail.formCode, filter);
    if (!(result instanceof HttpErrorResponse)) {
      this.rowData =  result['value'];
    }
  }

  deleteChannelAddr() {
    //this.serverSideDataSource = this.channelService.getOdataChannelAddressProvider(this.channelId);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if(value.name === 'new') {
      this.pageService.navigateByUrl(PageRoute.CHANNEL_CHANNELADDR_ENTRY, {
        channelId: this.channelId
      }, null, true);
    }
    else if (value.name === 'filter'){
      let filterBy = this.colDef
      .filter((col: ColDef) => col.field !== 'checkbox' && col.field !== 'actionButton')
      .map((col: ColDef) => col.field);
      let filter = this.lookupMasterService.setFilter(value.data, filterBy);
      let filterString : ODataRawQueryOptions ={
        filter: filter
      }
     await this.getChannelAddr(filterString);
    }
    // if(value.name === 'delete' && this.selectedRowData && this.selectedRowData['AddressId']) {
    //   let result = null;
    //   result = await this.channelService.deleteChannelAddr(this.channelId, this.selectedRowData['AddressId']);

    //   if (result instanceof HttpErrorResponse === false) {
    //     this.getChannelAddr();
    //   }
    // }
  }

  onRowSelect(event: any) {
    this.selectedRowData = event.data? event.data: null;
  }

  onPageSizeChange(pageSize: number) {}

  ngOnDestroy(): void {
    // Destroy event listener when component close
    this.refresh$?.unsubscribe();
    this.popupMsg$?.unsubscribe();
    this.actionBtn$?.unsubscribe();
  }
}
