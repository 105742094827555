/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ChannelSectionListResponse { 
    channelId?: number;
    channelTag?: string;
    sectionId?: number;
    sectionLine?: number;
    itemLabel?: string;
    storeId?: number;
    shortcutValue?: string;
    merchantId?: number;
    menuSetId?: number;
    menuCatgCode?: string;
    startDate?: Date;
    endDate?: Date;
    inactiveFlag?: boolean;
    rowVersion?: string;
}