/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorResponseModel } from '../model/errorResponseModel';
import { JobQueueModel } from '../model/jobQueueModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class JobService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get top 200 jobs for job listing.
     * 
     * @param queueName rpt (bgrpt), all (invqueue + bgrpt), publishMenu (gentaskqueue), genVoucher,  rmsCoreRpt, default return invqueue if this field is empty.
     * @param rptFormCode Report formcode. This field is only required for filtering job list by old RMS background reports.
     * @param apiVersion 
     * @param subscriptionId 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param formCode 
     * @param cultureCode Specify ISO 639-1 standard language codes. Default is &#x27;en-US&#x27;
     * @param clientDate yyyy-MM-dd
     * @param clientTimeZone Time Zone Name. Eg: Singapore Standard Time
     * @param autoFlag 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiJobGetjobsbyqueueGet(queueName?: string, rptFormCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<JobQueueModel>>;
    public apiJobGetjobsbyqueueGet(queueName?: string, rptFormCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<JobQueueModel>>>;
    public apiJobGetjobsbyqueueGet(queueName?: string, rptFormCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<JobQueueModel>>>;
    public apiJobGetjobsbyqueueGet(queueName?: string, rptFormCode?: string, apiVersion?: string, subscriptionId?: string, xSignature?: string, formCode?: string, cultureCode?: string, clientDate?: string, clientTimeZone?: string, autoFlag?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (queueName !== undefined && queueName !== null) {
            queryParameters = queryParameters.set('queueName', <any>queueName);
        }
        if (rptFormCode !== undefined && rptFormCode !== null) {
            queryParameters = queryParameters.set('rptFormCode', <any>rptFormCode);
        }

        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionId !== undefined && subscriptionId !== null) {
            headers = headers.set('subscriptionId', String(subscriptionId));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('x-Signature', String(xSignature));
        }
        if (formCode !== undefined && formCode !== null) {
            headers = headers.set('formCode', String(formCode));
        }
        if (cultureCode !== undefined && cultureCode !== null) {
            headers = headers.set('cultureCode', String(cultureCode));
        }
        if (clientDate !== undefined && clientDate !== null) {
            headers = headers.set('clientDate', String(clientDate));
        }
        if (clientTimeZone !== undefined && clientTimeZone !== null) {
            headers = headers.set('clientTimeZone', String(clientTimeZone));
        }
        if (autoFlag !== undefined && autoFlag !== null) {
            headers = headers.set('autoFlag', String(autoFlag));
        }

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'text/plain',
            'application/octet-stream',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<JobQueueModel>>('get',`${this.basePath}/api/job/getjobsbyqueue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
