/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IEdmStructuredType } from './iEdmStructuredType';
import { IEdmTypeReference } from './iEdmTypeReference';

export interface IEdmProperty { 
    readonly propertyKind?: IEdmProperty.PropertyKindEnum;
    type?: IEdmTypeReference;
    declaringType?: IEdmStructuredType;
    readonly name?: string;
}
export namespace IEdmProperty {
    export type PropertyKindEnum = 'None' | 'Structural' | 'Navigation';
    export const PropertyKindEnum = {
        None: 'None' as PropertyKindEnum,
        Structural: 'Structural' as PropertyKindEnum,
        Navigation: 'Navigation' as PropertyKindEnum
    };
}