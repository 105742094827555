import { Component, Input, Output, OnInit, EventEmitter, HostListener } from '@angular/core';
import { SharedFilterComponent } from './shared-filter/shared-filter.component';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [SharedFilterComponent],
})
export class FilterComponent{
  @Input() customFilter: any;
  @Input() filterItems: any;
  @Output() onFilterValue = new EventEmitter();

  filterValue(param: any) {
    this.onFilterValue.emit(param);
  }
}
