import { NgClass, CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, Input, NgModule } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-resizable-divider',
  templateUrl: './resizable-divider.component.html',
  styleUrls: ['./resizable-divider.component.scss'],
  imports: [NgClass, CommonModule]
})
export class ResizableDividerComponent {
  @Input() isVertical: boolean;
  @Output() resize = new EventEmitter<{ clientY: number }>();

  private isDragging = false;

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  onTouchStart(event: TouchEvent) {
    this.isDragging = true;
    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);
  }

  onMouseMove = (event: MouseEvent) => {
    if (this.isDragging) {
      this.resize.emit({ clientY: event.clientY });
    }
  };

  onTouchMove = (event: TouchEvent) => {
    if (this.isDragging) {
      this.resize.emit({ clientY: event.touches[0].clientY });
    }
  };

  onMouseUp = () => {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  onTouchEnd = () => {
    this.isDragging = false;
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
  };
}
