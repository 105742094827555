<div class="time-picker-layout" [formGroup]="formGroup" *ngIf="!timeSetting.hidden">
    <mat-form-field [style]="{ 'width': timeSetting?.styling?.width }">
        <mat-label *ngIf="timeSetting.label">{{ timeSetting.label | translate }}</mat-label>
        <input type="time" [required]="timeSetting.required? timeSetting.required: false" [formControlName]="timeSetting.name" matInput
            (change)="timeChange($event)" (focusout)="focusOut($event)" />
        <mat-icon-button matSuffix mat-icon-button aria-label="Clear" class="icon-container">
            <div *ngIf="formGroup.controls[timeSetting.name].value !== ''">
                <mat-icon class="indicator-icon valid-icon" *ngIf="formGroup.controls[timeSetting.name].status === 'VALID'">check_circle_outline</mat-icon>
            </div>
            <img src="assets/base/icons/schedule.svg" />
        </mat-icon-button>
        <mat-error>
            <span *ngFor="let err of errors">
                <span *ngIf="formGroup.controls[timeSetting.name]?.hasError(err.name) && formGroup.controls[timeSetting.name]?.getError(err.name)">
                    {{ err.errMsg | translate }}
                </span>
            </span>
        </mat-error>
    </mat-form-field>
</div>