import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateInputSetting } from '../model/DateInputSetting.model';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateISOPipe } from 'src/app/shared/pipes/date-iso.pipe';
import moment from 'moment';
import { customInputError } from '../model/customInputError.model';
import { Subscription } from 'rxjs';
import { ComponentService } from 'src/app/shared/services/component.service';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    TranslateModule,
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnDestroy {
  @Input() inputDateSetting?: DateInputSetting;
  @Input() formGroup: UntypedFormGroup;
  @Input() selectedDate: Date;
  @Input() editDateValue: any;
  @Input() isDateBoolean: boolean;
  @Input() formData: any;

  @Output() updateDate: EventEmitter<string> = new EventEmitter<string>();

  required$: Subscription;

  formGrpCtrl: any;
  isChanged: boolean;
  pattern: string;
  newDate: string;
  errors: customInputError[];

  constructor(private datePipe: DatePipe,
    private dateISOPipe: DateISOPipe,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private componentService: ComponentService) {
  }

  ngOnInit(): void {
    if(this.inputDateSetting.errors) {
      this.setError();
    }

    if(!this.formGroup?.get(this.inputDateSetting.name)) {
      this.formGroup = new UntypedFormGroup({});
      this.formGroup.addControl(this.inputDateSetting.name, this.fb.control(''));
    }

    if (this.isDateBoolean) {
      this.setDateValue(this.editDateValue);
    }

    this.required$ = this.componentService.required$.subscribe((val: any) => {
      if(val.name === this.inputDateSetting.name) {
        this.inputDateSetting.required = val.required;

        if(this.inputDateSetting.required) {
          this.formGroup.get(this.inputDateSetting.name)?.addValidators(Validators.required);
        } else {
          this.formGroup.get(this.inputDateSetting.name)?.removeValidators(Validators.required);
        }
      }
    });
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    let newValue = moment(event.value).format('DD/MM/YYYY');
    this.newDate = newValue;
  }

  setFormGrpVal(val: Date | string) {
    if(this.formGroup.get(this.inputDateSetting.name)) {
      this.formGroup.controls[this.inputDateSetting.name].setValue(val);
    }
  }

  setError() {
    let errors: customInputError[] = [];
    let name: string = this.translateService.instant(this.inputDateSetting?.name);

    // default errors
    errors.push({name: 'required', errMsg: name + ' ' + this.translateService.instant('msg.is_required')});

    errors = [...this.inputDateSetting.errors, ...errors];
    let errNames = errors.map(err => err.name);
    this.errors = errors.filter((err, index) => {return errNames.findIndex(name => name === err.name) === index;});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate && !changes.selectedDate.firstChange) {
      // Update the date value in the form control when selectedDate changes
      this.setFormGrpVal(this.selectedDate);
    } else {
      this.setFormGrpVal(this.editDateValue);
    }

    if(changes.formData?.currentValue) {
      this.setFormGrpVal(changes.formData.currentValue[this.inputDateSetting.name]);
    }
  }

  private setDateValue(value: string): void {
    // Parse the string to a Date object before setting it in the FormControl
    const parsedDate = this.dateISOPipe.transform(value);
    this.setFormGrpVal(parsedDate);
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.setFormGrpVal(event.target.value);
    const updatedDate = this.datePipe.transform(event.value, 'dd/MM/yyyy');
    this.updateDate.emit(updatedDate);
  }

  ngOnDestroy(): void {
    this.required$?.unsubscribe();
  }
}
