/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IEdmTypeReference } from './iEdmTypeReference';

export interface SingleValueNode { 
    typeReference?: IEdmTypeReference;
    readonly kind?: SingleValueNode.KindEnum;
}
export namespace SingleValueNode {
    export type KindEnum = 'None' | 'Constant' | 'Convert' | 'NonResourceRangeVariableReference' | 'BinaryOperator' | 'UnaryOperator' | 'SingleValuePropertyAccess' | 'CollectionPropertyAccess' | 'SingleValueFunctionCall' | 'Any' | 'CollectionNavigationNode' | 'SingleNavigationNode' | 'SingleValueOpenPropertyAccess' | 'SingleResourceCast' | 'All' | 'CollectionResourceCast' | 'ResourceRangeVariableReference' | 'SingleResourceFunctionCall' | 'CollectionFunctionCall' | 'CollectionResourceFunctionCall' | 'NamedFunctionParameter' | 'ParameterAlias' | 'EntitySet' | 'KeyLookup' | 'SearchTerm' | 'CollectionOpenPropertyAccess' | 'CollectionComplexNode' | 'SingleComplexNode' | 'Count' | 'SingleValueCast' | 'CollectionPropertyNode' | 'AggregatedCollectionPropertyNode' | 'In' | 'CollectionConstant';
    export const KindEnum = {
        None: 'None' as KindEnum,
        Constant: 'Constant' as KindEnum,
        Convert: 'Convert' as KindEnum,
        NonResourceRangeVariableReference: 'NonResourceRangeVariableReference' as KindEnum,
        BinaryOperator: 'BinaryOperator' as KindEnum,
        UnaryOperator: 'UnaryOperator' as KindEnum,
        SingleValuePropertyAccess: 'SingleValuePropertyAccess' as KindEnum,
        CollectionPropertyAccess: 'CollectionPropertyAccess' as KindEnum,
        SingleValueFunctionCall: 'SingleValueFunctionCall' as KindEnum,
        Any: 'Any' as KindEnum,
        CollectionNavigationNode: 'CollectionNavigationNode' as KindEnum,
        SingleNavigationNode: 'SingleNavigationNode' as KindEnum,
        SingleValueOpenPropertyAccess: 'SingleValueOpenPropertyAccess' as KindEnum,
        SingleResourceCast: 'SingleResourceCast' as KindEnum,
        All: 'All' as KindEnum,
        CollectionResourceCast: 'CollectionResourceCast' as KindEnum,
        ResourceRangeVariableReference: 'ResourceRangeVariableReference' as KindEnum,
        SingleResourceFunctionCall: 'SingleResourceFunctionCall' as KindEnum,
        CollectionFunctionCall: 'CollectionFunctionCall' as KindEnum,
        CollectionResourceFunctionCall: 'CollectionResourceFunctionCall' as KindEnum,
        NamedFunctionParameter: 'NamedFunctionParameter' as KindEnum,
        ParameterAlias: 'ParameterAlias' as KindEnum,
        EntitySet: 'EntitySet' as KindEnum,
        KeyLookup: 'KeyLookup' as KindEnum,
        SearchTerm: 'SearchTerm' as KindEnum,
        CollectionOpenPropertyAccess: 'CollectionOpenPropertyAccess' as KindEnum,
        CollectionComplexNode: 'CollectionComplexNode' as KindEnum,
        SingleComplexNode: 'SingleComplexNode' as KindEnum,
        Count: 'Count' as KindEnum,
        SingleValueCast: 'SingleValueCast' as KindEnum,
        CollectionPropertyNode: 'CollectionPropertyNode' as KindEnum,
        AggregatedCollectionPropertyNode: 'AggregatedCollectionPropertyNode' as KindEnum,
        In: 'In' as KindEnum,
        CollectionConstant: 'CollectionConstant' as KindEnum
    };
}