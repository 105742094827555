import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { environment } from 'src/environments/environment';
import {
  InterfaceType
} from 'src/swagger/base/openapi';
import { CurrencyService, ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { StatementService } from '../../services/statement.service';


@Component({
  selector: 'app-statement-entry',
  standalone: true,
  imports: [CommonModule, FormComponent, TranslateModule],
  templateUrl: './statement-entry.component.html',
  styleUrls: ['./statement-entry.component.scss'],
  providers:[CurrencyService]
})
export class StatementEntryComponent {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  salesTranId: string = '';
  formGroup: FormGroup;
  statementData: any;
  masterData: any;

  pageSub$: Subscription;

  interfaceTypeOption: any = Object.keys(InterfaceType).map((key) => { return { name: key, value: InterfaceType[key] } });

  RegionTimeZone: {[key: string]: string} = {
    'Singapore Standard Time (UTC+08:00)': 'Singapore Standard Time',
    }

  formSetting: FormSetting = {
    formCode: FormCode.statemententry,
    content: [
      {
        inputText: {
          name: 'salesTranId',
          label: 'Sales ID',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '60%'
          },
          maxLength: 5,
          editable: false,
        },
      },
      {
        inputText: {
          name: 'invoiceDate',
          label: 'Transaction Date',
          type: InputTypes.date,
          required: true,
          styling: {
            width: '60%'
          },
        }
      },
    ],
  };

  actionToolbarSetting = {
    actionToolBarItems: toolbarForm
  }

  constructor(
    private customService: CustomService,
    private authService: AuthService,
    private pageService: PageService,
    public dialog: MatDialog,
    private statementService: StatementService,
    private loaderService: LoaderService,
    private popupFormService: PopupFormService,
    private formService: FormService,


  ) {
    this.pageSub$ = this.pageService.pageSubject$.subscribe(async pages => {
      this.pages = pages;
    });
  }

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.salesTranId = params['salesTranId'];
      if (this.salesTranId) {
        await this.getStatement(this.salesTranId.toString());
      }
    }

  }


  async getStatement(salesTranId: string) {
    let queryOpts: ODataRawQueryOptions = {
      filter: 'salesTranId eq ' + salesTranId
    };
    let result = await this.statementService.getOdataStatement(queryOpts, this.formDetail.formCode);

    if (result instanceof HttpErrorResponse === false && result['value'].length > 0) {
      this.statementData = result['value'][0];
    }
  }

  async updateStatementTransactionDate(reqBody: any) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.statement.UpdateStatementTransactionDate,
      hostPath: environment.hostPath,
      body: reqBody,
    } as CustomRequest;

    let data = null;
    this.loaderService.startLoading();
    data = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    if (data instanceof HttpErrorResponse === false) {
      this.popupFormService.close(this.page.form.formCode);
      let msg: PopupMessage = {
        formCode: FormCode.statementlisting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.statement.update.success.title',
        desc: 'msg.statement.update.success.desc',
        actionBtnText: 'btn.okay'
      };
      this.pageService.refresh([FormCode.statementlisting]);
      this.dialog.open(PopupMessageComponent, {
        data: msg
      }).afterClosed().subscribe(result => {
        this.pageService.closePage(this.page);
      });
    }
    else {
      this.loaderService.stopLoading();
      console.error(data);
      let msg: PopupMessage = {
        formCode: FormCode.statementlisting,
        id: 'failed',
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.statement.update.fail.title',
        desc: data.error.title? data.error.title : data.message,
        actionBtnText: 'btn.back'
      };
      this.dialog.open(PopupMessageComponent, {
        data: msg
      });
    }
  }
  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
  onGenerateInput(event: any) {
    let generatedCode = 'code';
    event.formGrp.controls[event.setting.name].setValue(generatedCode);
  }
  onToolbarAction(resp: ActionToolBarResp) { }

  onSubmitForm(formGroup: UntypedFormGroup) {
    let reqBody = this.formService.getReqBody(formGroup);
    if (this.salesTranId) {
      this.updateStatementTransactionDate(reqBody);
    }
  }



  openChildForm(event) {
    console.log('event: ', event)
  }

  ngOnDestroy() {
    this.pageSub$.unsubscribe();
  }
}
