/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IEdmType { 
    readonly typeKind?: IEdmType.TypeKindEnum;
}
export namespace IEdmType {
    export type TypeKindEnum = 'None' | 'Primitive' | 'Entity' | 'Complex' | 'Collection' | 'EntityReference' | 'Enum' | 'TypeDefinition' | 'Untyped' | 'Path';
    export const TypeKindEnum = {
        None: 'None' as TypeKindEnum,
        Primitive: 'Primitive' as TypeKindEnum,
        Entity: 'Entity' as TypeKindEnum,
        Complex: 'Complex' as TypeKindEnum,
        Collection: 'Collection' as TypeKindEnum,
        EntityReference: 'EntityReference' as TypeKindEnum,
        Enum: 'Enum' as TypeKindEnum,
        TypeDefinition: 'TypeDefinition' as TypeKindEnum,
        Untyped: 'Untyped' as TypeKindEnum,
        Path: 'Path' as TypeKindEnum
    };
}