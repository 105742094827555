import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, IDateFilterParams, StatusPanelDef } from 'ag-grid-community';
import { AuthService } from 'src/app/core/services/auth.service';
import { ButtonRendererComponent } from 'src/app/shared/component/ag-grid/button-renderer/button-renderer.component';
import { DropdownCellEditorComponent } from 'src/app/shared/component/ag-grid/dropdown-cell-editor/dropdown-cell-editor.component';
import { DropdownRendererComponent } from 'src/app/shared/component/ag-grid/dropdown-renderer/dropdown-renderer.component';
import { PaginatorRendererComponent } from 'src/app/shared/component/ag-grid/paginator-renderer/paginator-renderer.component';
import { RouterLinkRendererComponent } from 'src/app/shared/component/ag-grid/router-link-renderer/router-link-renderer.component';
import { TotalRecordRendererComponent } from 'src/app/shared/component/ag-grid/total-record-renderer/total-record-renderer.component';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { Page } from 'src/app/shared/models/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import OdataProvider from "ag-grid-odata";
import { ODataRawQueryOptions } from 'src/swagger/cxmbackend/openapi';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from 'src/app/core/services/custom.service';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { StatementService as LocalStatementService } from '../../services/statement.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ListingComponent } from '../../../shared/component/listing/listing.component';
import { ActionToolBarComponent } from '../../../shared/component/action-tool-bar/action-tool-bar.component';
import { MessageComponent } from '../../../shared/component/message/message.component';
import { FilterComponent } from '../../../shared/component/filter/filter.component';
import { NgIf, NgClass } from '@angular/common';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { Mode } from 'src/app/shared/enums/mode';
import { FormService } from 'src/app/shared/component/form/form.service';
import { CustomFilterComponent } from '../../filter/filter.component';
import { agGridSideBarType } from 'src/app/shared/enums/agGridSideBarType';

@Component({
    selector: 'app-statement-listing',
    templateUrl: './statement-listing.component.html',
    styleUrls: ['./statement-listing.component.scss'],
    standalone: true,
    imports: [NgIf, FilterComponent, NgClass, MessageComponent, ActionToolBarComponent, ListingComponent, CustomFilterComponent]
})
export class StatementListingComponent implements OnInit {
  @Input() page: Page;

  rowData: any = null;
  options: any = null;
  frameworkComponents: any;
  formDetail: any = null;
  isRowSelected: boolean = false;
  isMobile: boolean = false;
  formCode: any = FormCode;
  listingSetting: ListingSetting;

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    editable: false,
    sortable: true,
    filterParams: {
      browserDatePicker: true
    }
  };

  statusBar: { statusPanels: StatusPanelDef[]; } = {
    statusPanels: [
      {
        statusPanel: 'totalRecordRenderer',
        align: 'left'
      },
      {
        statusPanel: 'paginatorRenderer',
        align: 'right',
        statusPanelParams: {
          page: FormCode.statementlisting
        }
      },

    ],
  };

  rowSelection: 'single' | 'multiple' = 'multiple';

  colDef: ColDef[] = [
    {
      ...listingRouterLink,
      field: 'statementId',
      headerName: 'Statement ID',
      cellRendererParams: {
        inRouterLink: {
          route: '/statement/entry',
          params: ['salesTranId'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      },
      filter: 'agTextColumnFilter',
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: true,
      sortable: true
    },
    {
      field: 'storeName',
      headerName: 'Store',
      filter: true,
      sortable: true
    },
    {
      field: 'orderNo',
      headerName: 'Order ID',
      filter: true,
      sortable: true
    },
    {
      field: 'refNo',
      headerName: 'Payment Ref No',
      filter: true
    },
    {
      field: 'salesDate',
      headerName: 'Sales Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filterParams: filterParams,
      type: 'dateColumn',
      sortable: true
    },
    {
      field: 'channelDesc',
      headerName: 'Channel',
      filter: true,
      sortable: true
    },
    {
      field: 'orderMode',
      headerName: 'Order Type',
      filter: true,
      sortable: true
    },
    {
      field: 'collectionMode',
      headerName: 'CollectionMode',
      filter: true
    },
    {
      field: 'netAmtExc',
      headerName: 'Net Order Value(Exclude Tax)',
      filter: true
    },
    {
      field: 'platformDiscAmt',
      headerName: 'Platform Discount',
      filter: true,
      hide: true
    },
    {
      field: 'merchantTaxAmt',
      headerName: 'Invoice Tax Value',
      filter: true,
      hide: true
    },
    {
      field: 'chargeAmt',
      headerName: 'Merchant Charges',
      filter: true,
      hide: true
    },
    {
      field: 'selfDeliveryAmt',
      headerName: 'Merchant Delivery Charges',
      filter: true,
      hide: true
    },
    {
      field: 'merchantAdjAmtPlusAdjAmt',
      headerName: 'Miscellaneous / Adjustments (If Any)',
      filter: true,
      hide: true
    },
    {
      field: 'otherChargeAmt',
      headerName: 'Other Charges Amount',
      filter: true
    },
    {
      field: 'commSalesAmt',
      headerName: 'Commission Base',
      filter: true
    },
    {
      field: 'commPerc',
      headerName: 'Platform Charge %',
      filter: true,
      hide: true
    },
    {
      field: 'commAmt',
      headerName: 'Platform Charge Value',
      filter: true
    },
    {
      field: 'commTaxAmt',
      headerName: 'Platform Charge Tax Value',
      filter: true
    },
    {
      field: 'revenueAmt',
      headerName: 'Restaurant Revenue',
      filter: true,
      hide: true
    },
    {
      field: 'payableAmt',
      headerName: 'Net Payable',
      filter: true
    },
    {
      field: 'collectionAmt',
      headerName: 'Odaring Collection',
      filter: true
    },
    {
      field: 'odaringRevenue',
      headerName: 'Odaring Revenue',
      filter: true
    },
    {
      field: 'otherPayment',
      headerName: 'Other Payments',
      filter: true
    },
    {
      field: 'custName',
      headerName: 'Customer"s Name',
      filter: true,
      hide: true
    },
    {
      field: 'email',
      headerName: 'Customer"s Email',
      filter: true,
      hide: true
    },
    {
      field: 'mobileNo',
      headerName: 'Customer"s MobileNo',
      filter: true,
      hide: true
    },
    {
      field: 'checkOrderUrl',
      headerName: 'CheckOrderUrl',
      filter: true,
      hide: true
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      filter: true,
      hide: true
    },
    {
      field: 'bizDate',
      headerName: 'Order Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filterParams: filterParams,
      type: 'dateColumn',
      sortable: true,
      hide: true
    },
    {
      field: 'dealerAmt',
      headerName: 'Dealer Amount',
      filter: true
    },
    {
      field: 'dealerPerc',
      headerName: 'Dealer Percentage',
      filter: true
    },
    {
      field: 'dealerName',
      headerName: 'Dealer Name',
      filter: true
    },
    {
      field: 'invoiceDate',
      headerName: 'Transaction Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => params.value == null ? null : moment(params.value).format("DD.MM.YYYY"),
      filterParams: filterParams,
      type: 'dateColumn',
      sortable: true
    },
  ]

  public filters: FilterExpression[] = [
    {
      field: 'salesDate',
      title: 'Sales Date',
      editor: 'date',
    },
    {
      field: 'bizDate',
      title: 'Order Date',
      editor: 'date',
    },
    {
      field: 'status',
      title: 'Status',
      editor: 'string',
    },
    {
      field: 'orderMode',
      title: 'Order Type',
      editor: 'string',
    },
    {
      field: 'storeName',
      title: 'Store Name',
      editor: 'string',
    },
  ];

  hMac: any;
  private gridApi!: GridApi;
  defaultFilterValue: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [{ field: "salesDate", operator: "eq", value: new Date(new Date(Date.now()).setHours(-24,0,0,0))}]
  }

  customFilter: any = {
    filters: [
      {
        condition: "AND",
        rules: [
          {
            id: "SalesDate",
            field: "salesDate",
            type: "string",
            input: "formuladatepicker",
            operator: "equal",
            value: {
              formula: "[yesterday]",
              // type: "range",
              // from: "2023-09-30T16:00:00Z",
              // fromDate: "2023-10-01",
              // to: "2023-12-31T15:59:59.999Z",
              // toDate: "2023-12-31",
              // offset: "+08:00",
              // showText: "1/10/2023~31/12/2023"
            }
          }
        ]
      },
      {
        // condition: "OR",
        // rules: [
        //   {
        //     id: "BillRefNo",
        //     field: "BillRefNo",
        //     type: "string",
        //     input: "text",
        //     operator: "contains",
        //     value: "123abc"
        //   },
        //   {
        //     id: "Week",
        //     field: "Week",
        //     input: "text",
        //     type: "integer",
        //     operator: "not_equal",
        //     value: "2"
        //   }
        // ]
      }
    ]
  };
  filterItems: any = [
    {
      fieldName: "general.salesdate",
      id: "SalesDate",
      input: "formuladatepicker",
      type: "string",
      operator: [
        "equal",
        "not_equal",
        "greater",
        "greater_or_equal",
        "lesser",
        "less_or_equal",
        "between"
      ]
    },
  ]




  constructor(
    private pageService: PageService,
    private authService: AuthService,
    private localStatementService: LocalStatementService,
    private customService: CustomService,
    private translate: TranslateService,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      totalRecordRenderer: TotalRecordRendererComponent,
      paginatorRenderer: PaginatorRendererComponent,
      routerLinkRenderer: RouterLinkRendererComponent,
      dropdownRenderer: DropdownRendererComponent,
      dropdownCellEditor: DropdownCellEditorComponent,
    };

    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);

    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
      duplicateRow: false,
      sideBar: [agGridSideBarType.columns, agGridSideBarType.filters]
    }
  }

  onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByPath(PageRoute.STORE_ENTRY);
    } else if (value.name === 'edit') {
      this.edit();
    } else if (value.name === 'restore') {
      this.restore();
    }
  }

  edit() {

  }

  restore() {

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.setColumnDefs(this.colDef);
    params.api.setServerSideDatasource(
      new OdataProvider({
        callApi: (options) =>
          fetch(
            `${environment.hostPath}${environment.apis.statement.OdataStatement}${options}`,
            {
              headers: {
                'SubscriptionID': environment.subscription,
                'Content-type': "application/json",
                'Accept': "application/json",
                'X-Signature': this.getSignature(options),
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'Content-Language': this.translate.currentLang
              }
            }
          ).then((resp) => resp.json()),
        beforeRequest: (options: OdataQueryOptions) => {
          options['count'] = true;
          options['top'] = 30;
        },
        afterLoadData: (options, rowData) => {
        }
      })
    );

  }

  onBtExport() {
    var params = this.getParams();
    // this.gridApi.exportDataAsCsv(params);
    this.gridApi.exportDataAsExcel(params);
  }

  getParams() {
    return {
      fileName: 'statement ' + moment(new Date).format("DD-MMM-YYYY")
    };
  }

  getSignature(options: any) {
    let optionsSpaceFormat = options.replace(/\s/g, "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.statement.OdataStatement + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }

  async onFilter(odataFilterString: any) {
    let queryOpts: ODataRawQueryOptions = {
      filter: odataFilterString
    };
    console.log(odataFilterString)
    let data = await this.localStatementService.getOdataStatement(queryOpts, this.formDetail.formCode);
    if (data instanceof HttpErrorResponse === false) {
      this.rowData = data && data['value'] ? data['value'] : [];
    }
  }

  onGridApiUpdate($event) {
    this.gridApi = $event;
  }
}

var filterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};
