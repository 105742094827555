/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IEdmExpression { 
    readonly expressionKind?: IEdmExpression.ExpressionKindEnum;
}
export namespace IEdmExpression {
    export type ExpressionKindEnum = 'None' | 'BinaryConstant' | 'BooleanConstant' | 'DateTimeOffsetConstant' | 'DecimalConstant' | 'FloatingConstant' | 'GuidConstant' | 'IntegerConstant' | 'StringConstant' | 'DurationConstant' | 'Null' | 'Record' | 'Collection' | 'Path' | 'If' | 'Cast' | 'IsType' | 'FunctionApplication' | 'LabeledExpressionReference' | 'Labeled' | 'PropertyPath' | 'NavigationPropertyPath' | 'DateConstant' | 'TimeOfDayConstant' | 'EnumMember' | 'AnnotationPath';
    export const ExpressionKindEnum = {
        None: 'None' as ExpressionKindEnum,
        BinaryConstant: 'BinaryConstant' as ExpressionKindEnum,
        BooleanConstant: 'BooleanConstant' as ExpressionKindEnum,
        DateTimeOffsetConstant: 'DateTimeOffsetConstant' as ExpressionKindEnum,
        DecimalConstant: 'DecimalConstant' as ExpressionKindEnum,
        FloatingConstant: 'FloatingConstant' as ExpressionKindEnum,
        GuidConstant: 'GuidConstant' as ExpressionKindEnum,
        IntegerConstant: 'IntegerConstant' as ExpressionKindEnum,
        StringConstant: 'StringConstant' as ExpressionKindEnum,
        DurationConstant: 'DurationConstant' as ExpressionKindEnum,
        Null: 'Null' as ExpressionKindEnum,
        Record: 'Record' as ExpressionKindEnum,
        Collection: 'Collection' as ExpressionKindEnum,
        Path: 'Path' as ExpressionKindEnum,
        If: 'If' as ExpressionKindEnum,
        Cast: 'Cast' as ExpressionKindEnum,
        IsType: 'IsType' as ExpressionKindEnum,
        FunctionApplication: 'FunctionApplication' as ExpressionKindEnum,
        LabeledExpressionReference: 'LabeledExpressionReference' as ExpressionKindEnum,
        Labeled: 'Labeled' as ExpressionKindEnum,
        PropertyPath: 'PropertyPath' as ExpressionKindEnum,
        NavigationPropertyPath: 'NavigationPropertyPath' as ExpressionKindEnum,
        DateConstant: 'DateConstant' as ExpressionKindEnum,
        TimeOfDayConstant: 'TimeOfDayConstant' as ExpressionKindEnum,
        EnumMember: 'EnumMember' as ExpressionKindEnum,
        AnnotationPath: 'AnnotationPath' as ExpressionKindEnum
    };
}