<div class="toast-container bottom-center" id="toast-container" [attr.data-name]="toastData?.name"
  [style.visibility]="toastState == 'show'? 'visible' : 'hidden'">
  <div class="toast"
    [ngStyle]="{'background-color': toastData?.backgroundColorCode ? toastData.backgroundColorCode : '#000000'}"
    id="toast" [@toast]="toastState" (@toast.done)="animationDone($event)">
    <div class="toast-wrapper">
      <span class="title " *ngIf="toastData?.title">{{toastData?.title | translate}}</span>
      <div class="message-container">
        <i class="{{toastData?.icon}} message-icon" [style.color]="iconColor"
          *ngIf="toastData && !toastData?.showCircleIcon && toastData?.icon"></i>
        <span class="message-circle-container" *ngIf="toastData && toastData?.showCircleIcon && toastData?.icon">
          <i class="{{toastData?.icon}}" [style.color]="iconColor"></i>
        </span>
        <span [ngStyle]="{'color': toastData?.fontColor ? toastData.fontColor : 'white'}"
          class="toast-message " *ngIf="!toastData?.messageData">{{toastData?.message | translate}}</span>
        <span class="toast-message" *ngIf="toastData?.messageData">{{toastData?.message | translate:
          toastData.messageData}}</span>
      </div>
    </div>
    <div [hidden]="toastData?.hideCloseBtn" style="display: flex;" (click)="closeToast()">
      <img style="width: 25px; cursor: pointer; opacity: 20%" src="assets/base/icons/remove.svg">
    </div>
  </div>
</div>
