import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementService {
  element: any;

  constructor() { }

  getElementById(id: string) {
    this.element = document.getElementById(id);
    return this.element;
  }

  disableElementById(id: string) {
    if(this.getElementById(id)) {
      this.element.disabled = true;
    }
  }

  enableElementById(id: string) {
    if(this.getElementById(id)) {
      this.element.disabled = false;
    }
  }
}
