import { createStore } from '@ngneat/elf';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import OdataProvider from "ag-grid-odata";
import { OdataQueryOptions } from "ag-grid-odata/build/types";
import { OAuthService } from "angular-oauth2-oidc";

import { HttpMethod } from "src/app/core/enums/HttpMethod";
import { CustomRequest } from "src/app/core/models/local/CustomRequest";
import { CustomService } from "src/app/core/services/custom.service";
import { UtilsService } from "src/app/core/services/utils.service";
import { LoaderService } from "src/app/shared/services/loader.service";
import { BackendChannelPlatformSet, BackendStoreAttr, BackendStoreOH, ODataRawQueryOptions, BackendStoreCatgAttr, BackendStore } from "src/swagger/cxmbackend/openapi";
import { environment } from "src/environments/environment";
import { CustomHeader } from "src/app/core/models/local/CustomHeader";
import { GetSettingsRequest } from "src/swagger/cxmbackend/openapi/model/getSettingsRequest";

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(
    private customService: CustomService,
    private loaderService: LoaderService,
    private oauthService: OAuthService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) { }

  // #region Store
  async getOdataStore(storeId: string, data?: ODataRawQueryOptions, formCode?: string) {
    return await this.reqOdataStoreData(storeId, data, formCode);
  }

  getOdataStoreProvider(storeId?: number, filter?: string) {
    return this.reqOdataStoreProvider(storeId, filter);
  }
  async addStore(reqBody: BackendStore, formCode?: string) {
    return await this.reqAddStore(reqBody, formCode);
  }
  async updateStore(reqBody: BackendStore, formCode?: string) {
    return await this.reqUpdateStore(reqBody, formCode);
  }
  // #endregion

  // #region StoreAttr
  async getOdataStoreAttr(storeId?: number, attrCode?: string, formCode?: string) {
    return await this.reqOdataStoreAttrData(storeId, attrCode, formCode);
  }

  getOdataStoreAttrProvider(storeId?: number) {
    return this.reqOdataStoreAttrProvider(storeId);
  }

  async addStoreAttr(reqBody: FormData, formCode?: string) {
    return await this.reqAddStoreAttr(reqBody, formCode);
  }

  async updateStoreAttr(formData: FormData, formCode?: string) {
    return await this.reqUpdateStoreAttr(formData, formCode);
  }

  async deleteStoreAttr(storeId?: number, attrCode?: string, formCode?: string) {
    return await this.reqDeleteStoreAttr(storeId, attrCode, formCode);
  }
  // #endregion

  // #region StoreOH
  async getOdataStoreOH(storeId?: number, seqNo?: number, formCode?: string) {
    return await this.reqOdataStoreOHData(storeId, seqNo, formCode);
  }

  getOdataStoreOHProvider(storeId?: number) {
    return this.reqOdataStoreOHProvider(storeId);
  }

  async addStoreOH(reqBody: BackendStoreOH, formCode?: string) {
    return await this.reqAddStoreOH(reqBody, formCode);
  }

  async updateStoreOH(reqBody: BackendStoreOH, formCode?: string) {
    return await this.reqUpdateStoreOH(reqBody, formCode);
  }

  async deleteStoreOH(storeId?: number, seqNo?: number, formCode?: string) {
    return await this.reqDeleteStoreOH(storeId, seqNo, formCode);
  }
  // #endregion


  //#region Store Setting

  async getStoreSettingData(reqBody?: GetSettingsRequest, formCode?: string) {
    return await this.reqStoreSettings(reqBody, formCode);
  }
  async getStorePlatformData(platformCode?: string, formCode?: string) {
    return await this.reqStorePlatformData(platformCode, formCode);
  }
  async getStoreChannelData(platformCode?: string, formCode?: string) {
    return await this.reqStorePlatformData(platformCode, formCode);
  }
  async addUpdateStoreSettings(reqBody?: BackendChannelPlatformSet, formCode?: string) {
    return await this.reqAddUpdateStoreSettings(reqBody, formCode);
  }
  //#endregion

  //#region store Category Att
  getOdataStoreCatgAttrData (storeId?: any, attrOptionId?: number, formCode?: string){
    return this.reqOdataStoreCatgAttrData(storeId, attrOptionId, formCode)
  }
  getOdataStoreCatgAttrProvider(storeId?: any) {
    return this.reqOdataStoreCatgAttrProvider(storeId);
  }
   getCatgAttrOption(data?: ODataRawQueryOptions, formCode?: string) {
    return this.reqCatgAttrOption(data, formCode);
  }
  addStoreCatgAttr(reqBody: BackendStoreCatgAttr, formCode?: string){
    return this.reqAddStoreCatgAttr(reqBody, formCode);
  }
  updateStoreCatgAttr(reqBody: BackendStoreCatgAttr, formCode?: string){
    return this.reqUpdateStoreCatgAttr(reqBody, formCode);
  }
  deleteStoreCatgAttr(storeId: number, attrOptionId: number, formCode?: string){
    return this.reqDeleteStoreCatgAttr(storeId, attrOptionId, formCode);
  }
  //#endregion

  // #region Store Odata
  private async reqOdataStoreData(storeId: string, data?: ODataRawQueryOptions, formCode?: string) {
    console.log(data)
    let filter = '';
    filter += storeId ? 'storeId eq ' + storeId : '';
    filter += storeId && data ? ' and ' : '';
    filter += data ? data.filter.replace('$filter=', '') : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.store.OdataStore,
      hostPath: environment.hostPath,
      queryParams: filter ===''?{
        $count: true
      }:{
        $count: true,
        $filter: filter
      },
      headers:{
        formCode: formCode
      }
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private reqOdataStoreProvider(storeId?: number, filter?: string) {
    const apiPath = environment.apis.store.OdataStore;
    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${this.utilsService.fixedEncodeURIComponent(options)}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              "Content-Language": this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
      }
    });
  }

  private async reqAddStore(reqBody: BackendStore, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.store.RegisterStore,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqUpdateStore(reqBody: BackendStore, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.store.UpdateStore,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  // #endregion

  // #region StoreAttr Odata
  private async reqOdataStoreAttrData(storeId?: number, attrCode?: string, formCode?: string) {
    let filter = storeId ? 'storeId eq ' + storeId : '';
    filter += attrCode ? ' and attrCode eq \'' +  attrCode + '\'' : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.storeAttr.OdataStoreAttr,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;

  }

  private reqOdataStoreAttrProvider(storeId?: number) {
    const apiPath = environment.apis.storeAttr.OdataStoreAttr;
    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${options}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              "Content-Language": this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = storeId ? ['storeId eq ' + storeId] : undefined;
      }
    });
  }

  private async reqAddStoreAttr(formData: FormData, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.storeAttr.AddStoreAttr,
      hostPath: environment.hostPath,
      body: formData,
      headers: {
        contentType: undefined,
        apiVersion: '2.0',
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqUpdateStoreAttr(formData: FormData, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.storeAttr.UpdateStoreAttr,
      hostPath: environment.hostPath,
      body: formData,
      headers: {
        contentType: undefined,
        apiVersion: '2.0',
        formCode: formCode
      } as CustomHeader,
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqDeleteStoreAttr(storeId: number, attrCode: string, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.storeAttr.DeleteStoreAttr,
      hostPath: environment.hostPath,
      queryParams: {
        storeId: storeId,
        attrCode: attrCode
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

  // #region StoreOH Odata
  private async reqOdataStoreOHData(storeId?: number, seqNo?: number, formCode?: string) {
    let filter = storeId ? 'storeId eq ' + storeId : '';
    filter += seqNo ? ' and seqNo eq ' + seqNo : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.storeOH.OdataStoreOH,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private reqOdataStoreOHProvider(storeId?: number) {
    const apiPath = environment.apis.storeOH.OdataStoreOH;

    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${options}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              "Content-Language": this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = storeId ? ['storeId eq ' + storeId] : undefined;
      }
    });
  }

  private async reqAddStoreOH(reqBody: BackendStoreOH, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.storeOH.AddStoreOH,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqUpdateStoreOH(reqBody: BackendStoreOH, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.storeOH.UpdateStoreOH,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }

  private async reqDeleteStoreOH(storeId: number, seqNo: number, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.storeOH.DeleteStoreOH,
      hostPath: environment.hostPath,
      queryParams: {
        storeId: storeId,
        seqNo: seqNo
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  // #endregion

  // #region StoreSetting
  private async reqStoreSettings(reqBody?: GetSettingsRequest, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.systemSettings.GetSystemSettings,
      hostPath: environment.hostPath,
      queryParams: {
        storeId: reqBody.storeId,
        setType: reqBody.setType,
        platformCode: reqBody.platformCode,
        channelId: reqBody.channelId
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  private async reqStorePlatformData(platformCode?: string, formCode?: string) {
    let filter = platformCode ? ' and platformCode eq \'' +  platformCode + '\'' : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.platform.OdataPlatform,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        //$filter: filter ? filter : undefined
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqStoreChannelData(storeId?: number) {
    let filter = storeId ? 'storeId eq ' + storeId : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channelStore.OdataChannelStore,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }
  private async reqAddUpdateStoreSettings(reqBody: BackendChannelPlatformSet, formCode?: string) {
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.systemSettings.AddOrUpdateChannelPlatformSet,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(customRequest);
    return respInfo;
  }
  // #endregion

  // #region store category attribute
  private async reqOdataStoreCatgAttrData(storeId?: any, attrOptionId?: number, formCode?: string) {
    let filter = storeId ? 'storeId eq ' + storeId : '';
    filter += attrOptionId ? ' and attrOptionId eq \'' +  attrOptionId + '\'' : '';

    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.storeCatgAttr.StoreCatgAttr,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter ? filter : undefined
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;

  }

  private reqOdataStoreCatgAttrProvider(storeId?: any) {
    const apiPath = environment.apis.storeCatgAttr.StoreCatgAttr;
    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${options}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              "Content-Language": this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
        options['filter'] = storeId ? ['storeId eq ' + storeId] : undefined;
      }
    });
  }

  private async reqCatgAttrOption(data?: ODataRawQueryOptions, formCode?: string){
    let filter = '';
    filter += data ? data.filter.replace('$filter=', '') : '';
    let customRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.catgAttrOption.CatgAttrOption,
      hostPath: environment.hostPath,
      queryParams: {
        $count: true,
        $filter: filter? filter: undefined
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqAddStoreCatgAttr(reqBody: BackendStoreCatgAttr, formCode?: string){
    let customRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.storeCatgAttr.AddStoreCatgAttr,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqUpdateStoreCatgAttr(reqBody: BackendStoreCatgAttr, formCode?: string){
    let customRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.storeCatgAttr.UpdateStoreCatgAttr,
      hostPath: environment.hostPath,
      body: reqBody,
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;

    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  private async reqDeleteStoreCatgAttr(storeId: number, attrOptionId: number, formCode?: string){
    let customRequest = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.storeCatgAttr.DeleteStoreCatgAttr,
      hostPath: environment.hostPath,
      queryParams: {
        storeId : storeId,
        attrOptionId: attrOptionId,
      },
      headers: {
        formCode: formCode,
      },
    } as CustomRequest;
    let respInfo = null;
    this.loaderService.startLoading();
    respInfo = await this.reqCustomHttpCall(customRequest);
    this.loaderService.stopLoading();
    return respInfo;
  }

  // #endregion

  getSignature(apiPath: string, options: any) {
    let optionsSpaceFormat = options.replace(/\s/g, "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: apiPath + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}

