import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from '@progress/kendo-angular-filter';
import { CommonModule } from '@angular/common';  
import { PageService } from 'src/app/shared/services/page.service';
import { Page } from 'src/app/shared/models/page.model';
import { decodeBase64 } from 'src/app/core/services/utils.service';
import { InvoiceFormComponent } from '../invoice-form/invoice-form.component';
import { InvoiceResponse } from 'src/swagger/cxmbackend/openapi';
@Component({
    selector: 'invoice-action',
    templateUrl: './invoice-action.component.html',
    styleUrls: ['./invoice-action.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FilterModule, CommonModule]
})
export class InvoiceActionComponent implements OnInit {
  @Input() invoiceData: InvoiceResponse;
  @Output() onActionValue = new EventEmitter();
  optionControl = new FormControl();
  conditionControl = new FormControl();
  isVisible: boolean;
  odataActionString: string = '';
  page: Page;
  invoiceId: string;

  constructor(
    private formBuilder: FormBuilder,
    private pageService: PageService,
  ) { }

  ngOnInit() {
  }
  ngOnChanges() {
      if(this.invoiceData != null && this.invoiceData.outputURL != null && this.invoiceData.outputURL != "")
      {
        this.isVisible = true;
      }   
    }   
  download(){
    this.odataActionString = "download"
    this.onActionValue.emit(this.odataActionString);
  }
  regenerate(){
    this.odataActionString = "regenerate"
    this.onActionValue.emit(this.odataActionString);
  }
  cancel(){
    this.odataActionString = "cancel"
    this.onActionValue.emit(this.odataActionString);
  }
  email(){
    this.odataActionString = "email"
    this.onActionValue.emit(this.odataActionString);
  }
  downloadstmt(){
    this.odataActionString = "downloadstmt"
    this.onActionValue.emit(this.odataActionString);
  }
  downloadserverpdf()
  {
    fetch(this.invoiceData.outputURL)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "INV_"+this.invoiceData.storeDesc+"_"+this.invoiceData.startDate+"_"+this.invoiceData.endDate+".pdf";
      link.click();
  })
  .catch(console.error);
  }
  async sendServerPdf(odataActionString: any) 
  {
    console.log(odataActionString);
  }


}

