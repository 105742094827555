/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CXMProblemModel } from '../model/cXMProblemModel';
import { GeneralMasterDataResponse } from '../model/generalMasterDataResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GeneralMasterDataService {

    protected basePath = 'https://cxmbackendapi.azurewebsites.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param apiVersion 
     * @param subscriptionID 
     * @param xSignature Format of signature header &#x3D; plaintextHmacsha256(httpmethod+requestpath+querystring+unixtimestamp+guid+body):unixtimestamp:guid
     * @param contentLanguage Specify language UpperCase(ISO_639-1_codes). Default is &#x27;EN&#x27;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGeneralmasterdataGetgeneralmasterdataGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralMasterDataResponse>;
    public apiGeneralmasterdataGetgeneralmasterdataGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralMasterDataResponse>>;
    public apiGeneralmasterdataGetgeneralmasterdataGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralMasterDataResponse>>;
    public apiGeneralmasterdataGetgeneralmasterdataGet(apiVersion?: string, subscriptionID?: string, xSignature?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;
        if (apiVersion !== undefined && apiVersion !== null) {
            headers = headers.set('api-version', String(apiVersion));
        }
        if (subscriptionID !== undefined && subscriptionID !== null) {
            headers = headers.set('SubscriptionID', String(subscriptionID));
        }
        if (xSignature !== undefined && xSignature !== null) {
            headers = headers.set('X-Signature', String(xSignature));
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
            headers = headers.set('Content-Language', String(contentLanguage));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GeneralMasterDataResponse>('get',`${this.basePath}/api/generalmasterdata/getgeneralmasterdata`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
