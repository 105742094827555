/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents a filter expression of Kendo DataSource.
 */
export interface Filter { 
    /**
     * Gets or sets the name of the sorted field (property). Set to `null` if the `Filters` property is set.
     */
    field?: string;
    /**
     * Gets or sets the filtering operator. Set to `null` if the `Filters` property is set.
     */
    operator?: string;
    /**
     * Gets or sets the filtering value. Set to `null` if the `Filters` property is set.
     */
    value?: any;
    /**
     * Gets or sets the filtering logic. Can be set to \"or\" or \"and\". Set to `null` unless `Filters` is set.
     */
    logic?: string;
    /**
     * Gets or sets the child filter expressions. Set to `null` if there are no child expressions.
     */
    filters?: Array<Filter>;
}