import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { HttpMethod } from '../enums/HttpMethod';
import { CustomHeader } from '../models/local/CustomHeader';
import { CustomRequest } from '../models/local/CustomRequest';
import { HMacObj } from '../models/local/HMacObj';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import FULLTIMEZONE from 'src/app/shared/json/full-time-zone.json';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomService {
  public curHttpHeader: CustomHeader = {
    apiVersion: environment.apiVersion,
    subscriptionId: environment.subscription,
    hmacAuth: '',
    accessToken: '',
    cultureCode: 'en-US',
    contentType: 'application/json',
    clientDate: this.utilsService.getDateWithFormat(),
    clientTimeZone: FULLTIMEZONE.TIMEZONE?.find(timezone => timezone?.UTC?.includes(moment(new Date())?.format('Z')))?.Timezone
  };

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
  ) {
  }

  public assignHMac(cus: CustomRequest) {
    const utilsSv = this.utilsService;
    let hMacObj: Partial<HMacObj> = {
      httpmethod: cus.httpMethod,
      requestpath: cus.requestpath,
      queryParams: cus.queryParams,
      date: new Date(),
      guid: utilsSv.createUUID(),
      body: cus.body
    }
    return this.constructHMacObj((hMacObj as HMacObj));
  }

  private constructHMacObj(hMacObj: HMacObj) {
    const utilsSv = this.utilsService;
    return utilsSv.createHttpHeaderHMac(hMacObj);
  }

  createRequest(cus: CustomRequest, isCompression?: boolean, isOriBody?: boolean) {
    const utilsSv = this.utilsService;
    let reqDt = cus;
    reqDt = this.mappingCustomRequest(reqDt);
    let cusRequest: any = null;
    if (reqDt.httpMethod === HttpMethod.GET) {
      let url = this.utilsService.fixedEncodeURIComponent(reqDt.hostPath + reqDt.requestpath + (reqDt.queryParams ? '?' + utilsSv.createHttpQueryString(reqDt.queryParams) : ''));

      if (isCompression) {
        cusRequest = new HttpRequest(reqDt.httpMethod, url, {
          headers: this.createHeader(reqDt.httpHeaderType, reqDt.headers), responseType: 'arrayBuffer' as 'json'
        });
      } else {
        cusRequest = new HttpRequest(reqDt.httpMethod, url, {
          headers: this.createHeader(reqDt.httpHeaderType, reqDt.headers),
        });
      }
    } else if (reqDt.httpMethod === HttpMethod.POST || reqDt.httpMethod === HttpMethod.PUT || reqDt.httpMethod === HttpMethod.DELETE || reqDt.httpMethod === HttpMethod.PATCH) {
      cusRequest = new HttpRequest(reqDt.httpMethod, reqDt.hostPath + reqDt.requestpath + (reqDt.queryParams ? '?' + utilsSv.createHttpQueryString(reqDt.queryParams) : ''),
        reqDt.body, {
        headers: this.createHeader(reqDt.httpHeaderType, reqDt.headers),
      });
    }

    if (!cusRequest) {
      throw 'create Error Obj';
    }

    return this.http.request(cusRequest).toPromise().catch((err) => {
      // Show out the api error description
      console.log("API Failed: ", err);
      return err;
    });
  }

  initHttpHeader(headers: CustomHeader) {
    this.curHttpHeader = headers;
  }

  private createHeader(headerType: HttpHeaderType, headers: CustomHeader) {
    let header = new HttpHeaders()
      .set("api-version", headers.apiVersion)
      .set("SubscriptionId", headers.subscriptionId)
      .set("X-Signature", headers.hmacAuth)
      .set("CultureCode", headers.cultureCode)
      .set("ClientDate", headers.clientDate);

    if (headers.formCode) {
      header = header.append("FormCode", headers.formCode);
    }

    if (headerType === HttpHeaderType.Auth) {
      header = header.append("Authorization", headers.accessToken ? "Bearer" + " " + headers.accessToken : "");
    }

    if (headers.contentType) {
      header = header.append("Content-Type", headers.contentType);
    }

    if (headers.xAccessCode) {
      header = header.append("X-AccessCode", headers.xAccessCode);
    }

    if (headers.clientTimeZone) {
      header = header.append("ClientTimeZone", headers.clientTimeZone);
    }

    return header;
  }

  private mappingCustomRequest(cus: CustomRequest) {
    const cusReq = _.cloneDeep(cus);
    cusReq.headers = {
      ...this.curHttpHeader,
      ...cusReq.headers,
      hmacAuth: this.assignHMac(cusReq)
    };
    return cusReq;
  }

  getCustomReq(httpMethod: HttpMethod, requestpath: string, hostPath: string, headers: string, reqBody: any, httpHeaderType: string) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.store.RegisterStore,
      hostPath: environment.hostPath,
      headers: null,
      body: reqBody,
      httpHeaderType: undefined,
    }
    return cusReq;
  }
}
