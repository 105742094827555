import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Params } from '@angular/router';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { HttpMethod } from '../enums/HttpMethod';
import { HMacObj } from '../models/local/HMacObj';
import { v4 } from 'uuid';
import * as _ from 'lodash';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private dateFormat = this.getSystemDateFormat();

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) { }

  createHttpHeaderHMac(hMacObj: HMacObj) {
    const hmac = hMacObj;
    const unixTimeStmp = this.createUnixTimeStamp(hmac.date);
    return this.createHMac(hmac.httpmethod, this.fixedEncodeURIComponent(hmac.requestpath), hmac.queryParams ? this.createHttpQueryString(hmac.queryParams) : '', unixTimeStmp, hmac.guid, hmac.body);
  }

  createHttpQueryString(obj: object) {
    return Object.entries(obj).map(dd => {
      return dd[1] !== undefined ? dd[0] + '=' + encodeURIComponent(dd[1]) : undefined
    }).filter(data => data !== undefined).join('&');
  }

  fixedEncodeURIComponent(str) {
    return str.replace(/[',()]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  createUUID() {
    return v4();
  }

  formatSrvDateStr(date: Date) {
    const dt = _.clone(date);
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`
  }

  formatToDateObj(dateStr: string) {
    let oDateTime = null;
    const dtStr = _.clone(dateStr);

    const s = dtStr.split("T"),
      a = s[0],
      ab = a.split('-').map(dd => Number(dd));

    let b, bb, timeZoneOps, tzHr, tzMins, curTimeOffset, rsMins;
    if (s[1].includes('+') || s[1].includes('-')) {
      if (s[1].includes('+')) {
        timeZoneOps = '+';
        b = s[1].split('+');
      } else if (s[1].includes('-')) {
        timeZoneOps = '-';
        b = s[1].split('-');
      }
      if (!b) {
        return;
      }
      bb = b[0].split(':').map(dd => Number(dd));
      tzHr = b[1].substr(0, 2);
      tzMins = b[1].substr(2, 2);

      oDateTime = new Date(ab[0], ab[1] - 1, ab[2], bb[0], bb[1], bb[2]);
      curTimeOffset = oDateTime.getTimezoneOffset();

      if (timeZoneOps === '+') {
        rsMins = oDateTime.getMinutes() + curTimeOffset + ((Number(tzHr) * 60) + Number(tzMins));
      } else if (timeZoneOps === '-') {
        rsMins = oDateTime.getMinutes() + curTimeOffset - ((Number(tzHr) * 60) + Number(tzMins));
      }
      oDateTime = new Date(oDateTime.getFullYear(), oDateTime.getMonth(), oDateTime.getDate(), oDateTime.getHours(), rsMins, oDateTime.getSeconds());
    } else {
      b = s[1];
      bb = b.split(':').map(dd => Number(dd));
      oDateTime = new Date(ab[0], ab[1] - 1, ab[2], bb[0], bb[1], bb[2]);
    }

    return oDateTime;
  }

  // formatPhoneNo(phoneObj: ChangeData) {
  //   return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  // }

  createOTPPrefix() {
    return this.createRandomStr(4);
  }

  removeProp(obj: any, propToDelete: any) {
    for (var property in obj) {
      if (typeof obj[property] == "object") {
        let objectToCheck = obj[property];
        delete obj.property
        let newJsonData = this.removeProp(obj[property], propToDelete);
        obj[property] = newJsonData
      } else {
        if (property === propToDelete) {
          delete obj[property];
        }
      }
    }
    return obj
  }

  // check if url contain 'odaring'
  async checkUrlValidity(url: string) {
    // 1. create URL object
    let scannedUrl = new URL(url);

    // 2. check if url hsotname is localhost if it is then return true
    // for testing/development purpose
    if (scannedUrl.hostname == ('localhost')) {
      return true;
    }

    // 3. check if url contains "odaring"
    let containOdaring = scannedUrl.hostname.includes("odaring");
    return containOdaring ? true : false;
  }

  // lowercase url search param key by accepting UrlSearchParams object
  lowerCaseSearchParam(params: any) {
    let lowerCaseParam = new URLSearchParams();
    for (const [name, value] of params) {
      lowerCaseParam.append(name.toLowerCase(), value);
    }

    return lowerCaseParam;
  }

  // lowercase url param key by accepting router queryParam objects
  toLowerQueryChar(params: Params) {
    const lowerKeyParams: Params = {};
    for (const key in params) {
      lowerKeyParams[key.toLowerCase()] = params[key];
    }

    return lowerKeyParams;
  }

  private createHMac(httpmethod: HttpMethod, requestpath: string, querystring: string, unixtimestamp: number, guid: string, body: any) {
    return sha256.hmac(environment.secretKey, `${httpmethod}${requestpath}${querystring ? "?" + this.fixedEncodeURIComponent(querystring) : ""}${unixtimestamp}${guid}${body ? JSON.stringify(body) : ""}`) + `:${unixtimestamp}:${guid}`;
  }

  private createUnixTimeStamp(date: Date) {
    return Math.trunc(date.getTime() / 1000);
  }

  private createRandomStr(charlength: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // abcdefghijklmnopqrstuvwxyz0123456789
    const charactersLength = characters.length;
    for (let i = 0; i < charlength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  formatUrlSlash() {

  }

  formatUTCDate(dateValue: string) {
    let date = new Date(dateValue);
    date.setUTCDate(date.getDate());
    date.setUTCHours(0);
    return date;
  }

  formatDropdownJSON(items: any, properties: string, value: string) {
    let formatedValue = [];
    let splitProperties = properties.split('|')
    for (let item of items) {
      formatedValue.push({
        name: this.getDropdownName(item, splitProperties),
        value: item[value]
      });
    }
    return formatedValue;
  }

  getDropdownName(item: any, splitProperties: any[]): string {
    item[splitProperties[0]] + ", " + item[splitProperties[1]]
    let finalName = '';

    for (let index = 0; index < splitProperties.length; index++) {
      finalName += (finalName ? ', ' : '') + item[splitProperties[index]];
    }

    return finalName;
  }

  formatNestedArrayToFlat(array: any, mainParent: any = null) {
    let result = [];

    if (array instanceof Array) {
      let keys = Object.keys(array[0]);

      array.forEach((obj: any) => {
        keys.forEach((key: any) => {
          if (obj[key] && obj[key] instanceof Array) {
            result.push(...this.formatNestedArrayToFlat(obj[key]));
          } else {
            result.push(...obj[key]);
          }
        });
      });
    }

    return result;
  }

  getEncodedParams(object: any) {
    for (const key in object) {
      object[key] = encodeBase64(object[key]);
    }
    return encodeBase64(JSON.stringify(object));
  }

  getDecodedParamObj(objectJSON: any) {
    let object = JSON.parse(objectJSON);
    for (const key in object) {
      object[key] = decodeBase64(object[key]);
    }
    return object;
  }

  getLocalDateFormat() {
    return getLocaleDateFormat(this.locale, FormatWidth.Short);
  }

  getLocaleDateFormat() {
    return {
      parse: {
        dateInput: 'LL',
      },
      display: {
        dateInput: getLocaleDateFormat(this.locale, FormatWidth.Short),
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
      },
    };
  }

  getSystemDateFormat(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    return currentDate.toLocaleDateString(undefined, options);
  }

  // default format is YYYY-MM-DD, like "2024-03-01"
  getDateWithFormat(format: string = "YYYY-MM-DD") {
    return moment().format(format);
  }
}

export function encodeBase64(data: string) {
  return btoa(data);
}

export function decodeBase64(data: string) {
  return atob(data);
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (control: AbstractControl) => {
    const input = control.get(controlName);
    const matchingInput = control.get(matchingControlName);

    if (input === null || matchingInput === null) {
      return null;
    }

    if (matchingInput?.errors && !matchingInput.errors.mustMatch) {
      // return if another validator has already found an error on the matchingInput
      return null;
    }

    // set error on matchingControl if validation fails
    if (input.value !== matchingInput.value) {
      matchingInput.setErrors({ mustMatch: true });
      return ({ mustMatch: true });
    } else {
      matchingInput.setErrors(null);
      return null;
    }
  }
}

export function formatDateString(dateValue): any {
  var dateObj = new Date(dateValue);
  return `${dateObj.getFullYear()}-${("0" + (dateObj.getMonth() + 1)).slice(-2)}-${("0" + dateObj.getDate()).slice(-2)}${"T00:00:00.000Z"}`;
}

export const MY_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
}

export function convertStringToObject(inputString: string): any {
  const keyValuePairs = inputString.split('&'); // Split by '&' to get individual key-value pairs
  const obj = {};
  for (let index = 0; index < keyValuePairs.length; index++) {
    const [key, value] = keyValuePairs[index].split('=');
    obj[key] = value;
  }
  return obj;
}

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}
