import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FormCode } from 'src/app/shared/enums/formCode';

@Component({
    selector: 'app-dropdown-renderer',
    templateUrl: './dropdown-renderer.component.html',
    styleUrls: ['./dropdown-renderer.component.scss'],
    standalone: true
})
export class DropdownRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams & {page: FormCode };
  isPopup: boolean = false;

  constructor() { }

  agInit(params: ICellRendererParams & {page: FormCode }): void {
    this.params = params;
  }

  click() {
    if(document.querySelector('.ag-grid-table-container.editing.' + this.params.page)) {
      let colId = this.params.column.getColId();
      this.params.api.startEditingCell({ rowIndex: this.params.rowIndex, colKey: colId});
    }
  }

  refresh(params: ICellRendererParams & {page: FormCode }): boolean {
    this.params = params;
    return false;
  }
}
