import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { PaginatorModule } from 'primeng/paginator';
import { CalendarModule } from 'primeng/calendar';
import { RouterModule } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FilterModule } from '@progress/kendo-angular-filter';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { UppercasePipe } from './pipes/uppercase.pipe';
import { LowercasePipe } from './pipes/lowercase.pipe';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchbarComponent } from './component/input/searchbar/searchbar.component';
import { DateISOPipe } from './pipes/date-iso.pipe';
import { ResizableDividerComponent } from './component/resizable-divider/resizable-divider.component';
import { SysFormatPipe } from './pipes/sys-format.pipe';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { FlagMappingPipe } from './pipes/flag-mapping.pipe';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        DynamicDialogModule,
        MessageModule,
        MessagesModule,
        DialogModule,
        ButtonModule,
        // material module
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatSliderModule,
        MatGridListModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatTableModule,
        MatSortModule,
        MatNativeDateModule,
        MatRippleModule,
        DynamicDialogModule,
        MatButtonModule,
        PaginatorModule,
        CalendarModule,
        RouterModule,
        InputSwitchModule,
        FilterModule,
        OverlayModule,
        ReactiveFormsModule,
        SearchbarComponent,
        ResizableDividerComponent,
    ],
    exports: [
        DialogModule,
        ButtonModule,
        // material module
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatSliderModule,
        MatGridListModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatTableModule,
        MatSortModule,
        MatNativeDateModule,
        MatRippleModule,
        // primeng
        InputSwitchModule,
        CapitalizePipe,
        UppercasePipe,
        LowercasePipe,
        SafeHtmlPipe,
        ReactiveFormsModule,
        SearchbarComponent,
        DateISOPipe,
        ResizableDividerComponent,
        SysFormatPipe,
        FlagMappingPipe
    ],
    providers: [
        MatDatepickerModule,
        { provide: LOCALE_ID, useValue: 'en-US' },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        DateISOPipe,
        SysFormatPipe,
        FlagMappingPipe
    ],
    declarations: [
      CapitalizePipe,
      UppercasePipe,
      LowercasePipe,
      SafeHtmlPipe,
      DateISOPipe,
      SysFormatPipe,
      FlagMappingPipe,
    ]
})
export class SharedModule {}
