<div class="input-number-layout" [formGroup]="formGroup">
    <mat-form-field [style]="{ 'width': inputNumberSetting.styling?.width }">
        <mat-label class="mat-label" *ngIf="inputNumberSetting.label">{{ inputNumberSetting.label | translate }}</mat-label>
        <input matInput placeholder="{{ inputNumberSetting.placeholder }}"
            required="{{ inputNumberSetting.required? inputNumberSetting.required: false }}"
            formControlName="{{ inputNumberSetting.name? inputNumberSetting.name: '' }}" 
            id="{{ inputNumberSetting.id? inputNumberSetting.id: '' }}" 
            type="number"
            [max]="inputNumberSetting.max !== null? inputNumberSetting.max: undefined" 
            [min]="inputNumberSetting.min !== null? inputNumberSetting.min: undefined"
            [step]="inputNumberSetting.step !== null? inputNumberSetting.step: undefined"
            (keydown)="onKeyDown($event)"
            #numInput>

        <div class="up-down-button" *ngIf="inputNumberSetting?.showButton">
            <img src="assets/base/icons/chevron-up.svg" (click)="onClickIncreaseNum()" (mouseup)="onMouseUp()" (mousedown)="onMouseDown(true)"/>
            <img src="assets/base/icons/chevron-down.svg" (click)="onClickReduceNum()" (mouseup)="onMouseUp()" (mousedown)="onMouseDown(false)" />
        </div>

        <mat-icon-button *ngIf="formGroup.controls[inputNumberSetting.name].value !== ''" matSuffix mat-icon-buttonvaria-label="Clear">
            <mat-icon *ngIf="formGroup.controls[inputNumberSetting.name].status === 'INVALID'" class="indicator-icon invalid-icon">error_outline</mat-icon>
            <mat-icon *ngIf="formGroup.controls[inputNumberSetting.name].status === 'VALID'" class="indicator-icon valid-icon">check_circle_outline</mat-icon>
        </mat-icon-button>

        <mat-error *ngIf="formGrpCtrl?.errors?.required">{{(inputNumberSetting.label? inputNumberSetting.label: 'msg.field') | translate}} {{'msg.is_required' | translate}}</mat-error>
        <mat-error *ngIf="formGrpCtrl?.errors?.min">{{ 'msg.number_less' | translate: {min: inputNumberSetting.min} }}</mat-error>
        <mat-error *ngIf="formGrpCtrl?.errors?.max">{{ 'msg.number_exceeded' | translate: {max: inputNumberSetting.max} }}</mat-error>
    </mat-form-field>
</div>
