import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgFor, NgClass, NgIf, NgStyle } from '@angular/common';
import { PageService } from 'src/app/shared/services/page.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgFor,
    NgClass,
    NgIf,
    NgStyle,
    TranslateModule
  ]
})
export class MenuNavigationComponent implements OnChanges {
  menuCaption: any;
  menuList: { textId: any, caption: any, icon: any, item: any[], isFavourite?: any }[] = [];
  activeBtn: string;
  filteredMenuList: any[] = [];
  defaultList: any[] = [];
  sortedList: any[] = [];
  isMobile: boolean;
  searchInput: string;
  isSearch: boolean = false;
  sortIcon: string = 'sortAcsList';
  ascending: boolean = true;
  navMenu: boolean = true;
  activeList: any[] = [];
  favouriteBut: boolean;
  menuSection: any[] = []
  activeBtns: string[] = [];
  favourites: any = [];
  isResultEmpty: boolean = false;
  recentMenu: any[] = [];

  @Input() menuSet: any;
  @Input() openFavourite: boolean;
  @Input() openRecent: boolean;
  @Output() isOpen = new EventEmitter<boolean>();
  @Output() closeSide = new EventEmitter<any>();
  @Output() isRecent = new EventEmitter<any>();
  @Output() closeMenu = new EventEmitter<any>();

  constructor(private pageService: PageService, private translateService: TranslateService,) {
    this.checkIfMobile();

    this.translateService.setDefaultLang('en-US');
  }

  ngOnInit(): void {
    this.setMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.menuSet && changes?.menuSet?.currentValue) {
      this.menuSet = changes.menuSet.currentValue;
      this.setMenu();
    }

    if ((changes?.openFavourite && changes.openFavourite.currentValue === true)) {
      this.favouriteBut = false;
    } else {
      if (this.getFavouriteMenuItems(this.menuSet).length > 0) {
        this.favouriteBut = true;
      } else {
        this.favouriteBut = false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }

  private checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 991;
  }

  getFavouriteMenuItems(menuItems: any[]): any[] {
    const favourites: any[] = [];

    if (menuItems.length > 0) {
      menuItems.forEach(menuItem => {
        if (menuItem.menuItems && menuItem.menuItems.length > 0) {
          const subMenuFavourites = this.getFavouriteMenuItems(menuItem.menuItems);
          if (subMenuFavourites.length > 0) {
            const updatedMenuItem = { ...menuItem, menuItems: subMenuFavourites };
            favourites.push(updatedMenuItem);
          }
        } else if (menuItem.isFavourite) {
          favourites.push(menuItem);
        }
      });
    } else {
      menuItems = [menuItems];
      menuItems.forEach(menuItem => {
        if (menuItem.menuItems && menuItem.menuItems.length > 0) {
          const subMenuFavourites = this.getFavouriteMenuItems(menuItem.menuItems);
          if (subMenuFavourites.length > 0) {
            const updatedMenuItem = { ...menuItem, menuItems: subMenuFavourites };
            favourites.push(updatedMenuItem);
          }
        } else if (menuItem.isFavourite) {
          favourites.push(menuItem);
        }
      });
    }
    return favourites;
  }

  setMenu() {
    this.activeBtn = 'Show All';
    this.menuList = [];
    this.menuCaption = '';
    this.menuSection = [];

    if (this.openFavourite) {
      if (Array.isArray(this.menuSet) && this.menuSet.length > 0) {
        this.menuList = this.menuSet;
        this.getFavouriteMenuItems(this.menuSet).forEach(menu => {
          const secondLayer = menu.menuItems.map(list => ({
            textId: list.textId,
            caption: list.caption,
            icon: list.icon,
            item: list.menuItems
          }));

          this.menuSection.push({ caption: menu.caption, list: secondLayer });
        });
        this.favourites = this.menuSection;
      }
    } else if (this.openRecent) {
      this.menuList = this.menuSet;
      this.filteredMenuList = this.menuSet;
    } else {
      if (this.menuSet) {
        this.menuCaption = this.translateCaption(this.menuSet.textId, this.menuSet.caption);

        const secondLayer = this.menuSet.menuItems;
        secondLayer.forEach(list => {
          this.menuList.push({
            textId: list.textId,
            caption: list.caption,
            icon: list.icon,
            item: list.menuItems
          });
        });
      }
    }

    if (this.openFavourite || this.openRecent) {
      this.activeBtns = [];
      this.menuSection.forEach(() => {
        this.activeBtns.push('Show All');
      });
    }

    this.filterMenuItems();
    this.defaultList = JSON.parse(JSON.stringify(this.menuList));
    this.sortedList = JSON.parse(JSON.stringify(this.defaultList));
  }

  toggleSubMenu(item: any): void {
    item.isOpen = !item.isOpen;
  }

  setActiveButtons(buttonName: string, menuIndex?: number) {
    this.activeBtns[menuIndex] = buttonName;
    this.filterMenuItems();
    this.clearSearch();
  }

  setActiveButton(buttonCaption: string): void {
    this.sortIcon = 'sortAcsList';
    this.activeBtn = buttonCaption;
    if (this.openFavourite) {
      this.filterMenu();
    } else {
      this.filterMenuItems();
    }
    this.clearSearch();
  }

  filterMenu(): void {
    const filteredList = [];

    if (this.activeBtn === 'Show All') {
      this.favourites = this.menuSection;
      this.favourites.forEach(menu => {
        filteredList.push(menu.list);
        this.activeList = filteredList
      })
    } else if (this.activeBtn === 'Favorite') {
      this.favourites = this.menuSection;
      this.favourites.forEach(menu => {
        filteredList.push(menu.list
          .map(menu => {
            const favoriteItems = menu.item.filter(item => item.isFavourite === true);
            return { ...menu, item: favoriteItems };
          })
          .filter(menu => menu.item.length > 0))
      });
      this.activeList = filteredList;
    } else {
      this.favourites = this.menuSection.filter(menu =>
        this.translateCaption(menu.textId, menu.caption) === this.activeBtn);

      this.favourites.forEach(menu => {
        filteredList.push(menu.list);
        this.activeList = filteredList
      })
    }
    this.filteredMenuList = JSON.parse(JSON.stringify(this.activeList));
  }

  filterMenuItems(): void {
    if (this.openFavourite) {
      const filteredList = [];
      this.favourites.forEach((menu, index) => {
        const activeBtn = this.activeBtns[index];
        if (activeBtn === 'Show All') {
          filteredList.push(menu.list);
        } else if (activeBtn === 'Favorite') {
          filteredList.push(menu.list
            .map(menu => {
              const favoriteItems = menu.item.filter(item => item.isFavourite === true);
              return { ...menu, item: favoriteItems };
            })
            .filter(menu => menu.item.length > 0));
        } else {
          filteredList.push(menu.list.filter(item =>
            this.translateCaption(item.textId, item.caption) === activeBtn
          ));
        }
      });
      this.activeList = filteredList
    } else {
      if (this.activeBtn === 'Show All') {
        this.activeList = this.menuList;
      } else if (this.activeBtn === 'Favorite') {
        if (this.openRecent) {
          this.activeList = this.menuList
            .filter(item => item.isFavourite === true)
            .map(item => ({
              ...item,
            }));
        } else {
          this.activeList = this.menuList
            .map(menu => {
              const favoriteItems = menu.item.filter(item => item.isFavourite === true);
              return { ...menu, item: favoriteItems };
            })
            .filter(menu => menu.item.length > 0);
        }

      } else {
        this.activeList = this.menuList.filter(menu => this.translateCaption(menu.textId, menu.caption) === this.activeBtn);
      }
    }
    if (this.openRecent) {
      this.menuSet = this.activeList
      this.sortedList = JSON.parse(JSON.stringify(this.activeList));
    } else {
      this.filteredMenuList = JSON.parse(JSON.stringify(this.activeList));
      this.sortedList = JSON.parse(JSON.stringify(this.activeList));
    }
  }

  hasItems(): boolean {
    return this.filteredMenuList.some(menu => menu.item && menu.item.length > 0);
  }

  selectItem(target: any) {
    if (target) {
      this.pageService.navigateByPath(target);
      this.navMenu = false;
      this.isOpen.emit(this.navMenu);
      this.closeSide.emit();
      if (!this.openFavourite && !this.openRecent) {
        this.setRecentLocal(target);
      }
    } else {
      this.navMenu = true;
    }
  }

  findPath(targetFilePath: string): any {
    if (!this.menuSet || !this.menuSet.menuItems || !Array.isArray(this.menuSet.menuItems)) {
      return null;
    }
    const filterMenuItems = (items: any[]): any[] => {
      return items.reduce((acc, item) => {
        let newItem = { ...item };

        if (newItem.menuItems && newItem.menuItems.length > 0) {
          newItem.menuItems = filterMenuItems(newItem.menuItems);

          if (newItem.menuItems.length > 0 || newItem.filePath === targetFilePath) {
            acc.push(newItem);
          }
        } else if (newItem.filePath === targetFilePath) {
          acc.push(newItem);
        }

        return acc;
      }, []);
    };

    const filteredMenuSet = this.menuSet.menuItems.reduce((acc, item) => {
      let newItem = { ...item };

      newItem.menuItems = filterMenuItems(newItem.menuItems);
      if (newItem.menuItems.length > 0 || this.hasChildWithPath(newItem, targetFilePath)) {
        acc.push(newItem);
      }

      return acc;
    }, []);

    const result = filteredMenuSet.length > 0 ? [{ ...this.menuSet, menuItems: filteredMenuSet }] : [];
    return result;
  }

  hasChildWithPath(item: any, targetFilePath: string): boolean {
    if (item.filePath === targetFilePath) {
      return true;
    }
    if (item.menuItems && item.menuItems.length > 0) {
      return item.menuItems.some((child: any) => this.hasChildWithPath(child, targetFilePath));
    }
    return false;
  }

  setRecentLocal(targetFilePath: string) {
    const pathToTarget = this.findPath(targetFilePath);
    const storedItem = localStorage.getItem('selectedMenuSet');
    this.recentMenu = storedItem ? JSON.parse(storedItem) : [];

    const existingIndex = this.recentMenu.findIndex(menu => {
      return this.deepCompareObjects(menu, pathToTarget[0]);
    });

    if (existingIndex !== -1) {
      this.recentMenu.splice(existingIndex, 1);
    }

    this.recentMenu.push(pathToTarget[0]);

    localStorage.setItem('selectedMenuSet', JSON.stringify(this.recentMenu));
    this.isRecent.emit(this.recentMenu);
  }

  deepCompareObjects(obj1: any, obj2: any): boolean {
    const obj1Str = JSON.stringify(obj1);
    const obj2Str = JSON.stringify(obj2);
    return obj1Str === obj2Str;
  }

  filterSearch(): void {
    this.sortIcon = 'sortAcsList';

    if (this.searchInput) {
      this.isSearch = true;
      const searchTerm = this.searchInput.toLowerCase().trim();
      if (this.openFavourite) {
        this.filteredMenuList = this.activeList.map(section => {
          return section.map(menu => {
            const filteredItems = this.searchItemsRecursive(menu.item, searchTerm);
            return { ...menu, item: filteredItems };
          }).filter(menu => menu.item.length > 0);
        });
      } else if (this.openRecent) {
        this.filteredMenuList = this.activeList
          .filter(menu => {
            return menu.caption.toLowerCase().includes(searchTerm.toLowerCase());
          }).map(menu => {
            return menu;
          })
        this.menuSet = this.filteredMenuList;
      } else {
        this.isSearch = true;
        const searchTerm = this.searchInput.toLowerCase();
        this.filteredMenuList = this.activeList.map(menu => {
          const filteredItems = menu.item.filter(item =>
            this.translateCaption(item.textId, item.caption).toLowerCase().includes(searchTerm));
          return { ...menu, item: filteredItems };
        }).filter(menu => menu.item.length > 0);
      }
      this.isResultEmpty = this.filteredMenuList.length === 0 || this.filteredMenuList.every(section => section.length === 0);
    } else {
      this.isSearch = false;
      this.isResultEmpty = false;
      if (this.openRecent) {
        this.filteredMenuList = this.activeList;
      } else {
        this.filteredMenuList = JSON.parse(JSON.stringify(this.activeList));
      }
    }
  }

  searchItemsRecursive = (data: any[], searchTerm: string): any[] => {
    const results: any[] = [];

    data.forEach(item => {
      if (
        item.caption.toLowerCase().includes(searchTerm) ||
        item.textId.toLowerCase().includes(searchTerm)
      ) {
        results.push(item);
      }

      if (item.menuItems && item.menuItems.length > 0) {
        const nestedResults = this.searchItemsRecursive(item.menuItems, searchTerm);
        if (nestedResults.length > 0) {
          results.push({
            ...item,
            menuItems: nestedResults
          });
        }
      }
    });
    return results;
  };

  clearSearch() {
    this.searchInput = '';
    this.isSearch = false;
    this.isResultEmpty = false;
    this.filteredMenuList = JSON.parse(JSON.stringify(this.activeList));
  }

  onSort() {
    if (this.sortIcon === 'sortAcsList') {
      this.sortIcon = 'sortDescList';
      this.ascending = true;
      this.sortOptions(this.ascending);
    } else if (this.sortIcon === 'sortDescList') {
      this.sortIcon = 'list-align';
      this.ascending = false;
      this.sortOptions(this.ascending);
    } else if (this.sortIcon === 'list-align') {
      this.sortIcon = 'sortAcsList';
      this.filteredMenuList = JSON.parse(JSON.stringify(this.activeList));
      this.sortedList = JSON.parse(JSON.stringify(this.activeList));
      return;
    }
  }

  sortOptions(ascending: boolean): void {
    this.clearSearch();

    const sortedFilteredMenuList = JSON.parse(JSON.stringify(this.activeList));
    if (this.openFavourite) {
      sortedFilteredMenuList.forEach(menu => {
        menu.forEach(list => {
          list.item.sort((a, b) => {
            const fieldA = a.caption.toLowerCase();
            const fieldB = b.caption.toLowerCase();
            if (fieldA < fieldB) {
              return ascending ? -1 : 1;
            }
            if (fieldA > fieldB) {
              return ascending ? 1 : -1;
            }
            return 0;
          });
        });
      });
    } else if (this.openRecent) {
      sortedFilteredMenuList.sort((a, b) => {
        const fieldA = a.caption ? a.caption.toLowerCase() : '';
        const fieldB = b.caption ? b.caption.toLowerCase() : '';

        if (fieldA < fieldB) {
          return ascending ? -1 : 1;
        }
        if (fieldA > fieldB) {
          return ascending ? 1 : -1;
        }
        return 0;
      });
    } else {
      sortedFilteredMenuList.forEach(menu => {
        menu.item.sort((a, b) => {
          const fieldA = a.caption.toLowerCase();
          const fieldB = b.caption.toLowerCase();
          if (fieldA < fieldB) {
            return ascending ? -1 : 1;
          }
          if (fieldA > fieldB) {
            return ascending ? 1 : -1;
          }
          return 0;
        });
      });
    }

    this.filteredMenuList = sortedFilteredMenuList;
    this.sortedList = sortedFilteredMenuList;
  }

  translateCaption(textId: string, caption: string): string {
    if (textId) {
      if (this.translateService.instant(textId) === textId) {
        return caption;
      } else {
        return this.translateService.instant(textId);
      }
    } else {
      return caption;
    }
  }

  onClose() {
    this.closeMenu.emit();
  }
}
