<div [formGroup]="formGroup" *ngIf="!inputDateSetting.hidden">
  <mat-form-field appearance="fill" [style]="{ 'width': inputDateSetting?.styling?.width }">
    <mat-label>{{ inputDateSetting?.label | translate }}</mat-label>
    <mat-date-range-input [formGroup]="formGroup? formGroup: range" [rangePicker]="picker" [disabled]="inputDateSetting?.disabled">
      <input matStartDate formControlName="start" 
        [required]="!inputDateSetting.hideError && inputDateSetting.required"
        [placeholder]="(inputDateSetting?.placeholder?.start? inputDateSetting?.placeholder?.start:  'general.start_date') | translate"
        (dateChange)="onDateChange()">
      <input matEndDate formControlName="end" 
        [required]="!inputDateSetting.hideError && inputDateSetting.required"
        [placeholder]="(inputDateSetting?.placeholder?.end? inputDateSetting?.placeholder?.end:  'general.end_date') | translate"
        (dateChange)="onDateChange()">
    </mat-date-range-input>
    <!-- <mat-hint>{{ inputDateSetting?.pattern }}</mat-hint> -->
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error *ngIf="!inputDateSetting.hideError && (formGroup.controls['start']?.errors?.required && formGroup.controls['end']?.errors?.required); else invalidRange">
      {{ (inputDateSetting?.label? inputDateSetting.label: 'msg.field') | translate }} {{ 'msg.is_required' | translate }}
    </mat-error>
    <ng-template #invalidRange>
      <mat-error *ngIf="!inputDateSetting.hideError && (!formGroup.controls['start']?.errors?.required || formGroup.controls['end']?.errors?.required)">
        {{ 'msg.invalid_date_range' | translate }}
      </mat-error>
    </ng-template>
  </mat-form-field>
</div>
