import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Page } from '../../models/page.model';
import { PageService } from '../../services/page.service';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { NgIf } from '@angular/common';
import { Menu } from 'src/app/state/menu.repository';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-close-page',
  templateUrl: './close-page.component.html',
  styleUrls: ['./close-page.component.scss'],
  standalone: true,
  imports: [NgIf, BreadcrumbComponent, AppComponent]
})
export class ClosePageComponent implements OnInit {
  @Input() currentPage: Page;
  @Input() pageNo: number = 0;
  @Input() menu: Menu;
  pages: Page[];

  constructor(
    private pageService: PageService,
    private appComponent: AppComponent
  ) {
    this.pages = this.pageService.getPages();
    this.pageService.pageSubject$.subscribe(pages => {
      this.pages = pages;
    });
  }

  ngOnInit(): void {
    this.pages.slice(0, this.pageNo);
  }

  closePanel() {
    this.pageService.closePage(this.currentPage);
    this.appComponent.closeSideNav();
  }
}
