<div *ngIf="formulaBuild">
  <span style="display: flex; align-items: center; margin-bottom: 20px;">
    <label class="toggleDate">
      <input type="checkbox" [checked]="!isDatePickerMode" (click)="toggleView()">
      <span class="slider"></span>
    </label>
    <p style="margin: 0 5px; font-size: 12px;">
      {{ isDatePickerMode ? ('general.datepicker' | translate) : ('general.dateFormulaBuild' | translate) }}</p>
  </span>
</div>

<ng-container *ngIf="isDatePickerMode; else dropDownMode">
  <div *ngIf="isBetween">
    <app-date-picker [inputDateSetting]="dateFilter.dateInput" [formGroup]="formGrp" [editDateValue]="editDateValue"
      [isDateBoolean]="isDateBoolean" (updateDate)="handleDateChanged($event)"></app-date-picker>
  </div>

  <div *ngIf="!isBetween">
    <app-date-picker-range [inputDateSetting]="dateRangeFilter.dateRangeInput" [formGroup]="formGrp"
      [selectedDate]="formGrp.get('Range').value" [isDateBoolean]="isDateBoolean" [editDateValue]="editDateValue"
      (updateDate)="handleDateChanged($event)"></app-date-picker-range>
  </div>

</ng-container>

<ng-template #dropDownMode>
  <div class="custom-dropdown">
    <button class="popup-date" (click)="toggleDropdown()">
      <span *ngIf="!isModify"> {{ editValue }} </span>
      <span *ngIf="isModify"> {{ editValue + calc + updatedValue }} </span>
      <img *ngIf="!isDropdownOpen" class="dropdown-icon" src="assets/base/icons/chevron-down.svg">
      <img *ngIf="isDropdownOpen" class="dropdown-icon" src="assets/base/icons/chevron-up.svg">
    </button>

    <ul *ngIf="isDropdownOpen" class="dropdown-options">
      <li style="display: flex; justify-content: space-between;" *ngFor="let option of dateSelection"
        (click)="onDateOptionChange(option.name)">
        <span *ngIf="!isModify" class="option-name">{{ option.name }}</span>
        <span *ngIf="isModify" class="option-name">{{ optionNameWithCalc}}</span>
        <span class="option-date">{{ getDateValue(option.name) }}</span>
      </li>
    </ul>
  </div>
  <div class="dateModify">
    <span class="example-value">{{'general.date' | translate}} - {{ getDateValue(editValue) || dateValue}}</span>

    <div class="modifier" [ngClass]="{'isModify': isModify}">
      <img (click)="dateModify()" *ngIf="!isModify; else dateCalc" style="width: 30px; cursor: pointer;"
        src="assets/base/icons/calculator-solid.svg">

      <ng-template class="dateCalc" #dateCalc>
        <div style="display: flex; align-items: center; margin-right: 5px;">
          <label class="toggleCalc" style="margin-right: 15px; margin-top: 2px; display: flex; align-items: center;">
            <input type="checkbox" (click)="toggleCalc()" [checked]="isCalc == false">
            <span class="sliderCalc" [class.active]="isCalc == false"></span>
          </label>
            <span style="display: flex; align-items: center;">
              <img *ngIf="calc === ' + '" style="width: 20px;" src="assets/base/icons/plus.svg">
              <img *ngIf="calc === ' - '" style="width: 20px;" src="assets/base/icons/minus.svg">
            </span>
        </div>
        <div class="inputCalc">
          <form [formGroup]="form">
            <input class="calcInput" type="number" formControlName="quantity" step="1" min="1"
              [(ngModel)]="updatedValue" (input)="onQuantityChange($event)">
          </form>
        </div>
        <img style="width: 20px; cursor: pointer;" (click)="dateModify()" src="assets/base/icons/remove.svg">
      </ng-template>
    </div>
  </div>
</ng-template>
