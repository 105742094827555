<div class="paginator-renderer-layout">
  <i class="pi pi-refresh btn-refresh" (click)="onRefresh()"
    *ngIf="params?.setting?.refresh && params.setting.refresh"></i>
  <div class="dropdown-paginator dropup"
    *ngIf="totalRows && (params?.setting?.rowModelType && params.setting.rowModelType !== rowModelType.infinite) && showPageSize">
    <label>{{ 'general.listing_per_page' | translate }}</label>
    <button type="button" id="dropdownBtn" (click)="onClickDropdown()">
      <p>{{ totalRows < 100 ? ('general.all' | translate) : (displayRow !==totalRows ? displayRow : ('general.all' |
          translate)) }}</p>
          <img src="assets/base/icons/chevron-down.svg" />
    </button>
    <p-overlay [(visible)]="openDropdown" contentStyleClass="surface-overlay shadow-2" [appendTo]="'body'">
      <ul class="dropdown-menu">
        <li *ngFor="let size of pageSizes" (click)="onPageSizeChange($event)">{{ size }}</li>
        <li (click)="onPageSizeChange($event, true)">{{ 'general.all' | translate }}</li>
      </ul>
    </p-overlay>
  </div>
  <p-paginator #paginator [rows]="displayRow" [totalRecords]="totalRows" [showPageLinks]="true"
    [pageLinkSize]="pageLinkSize" [showCurrentPageReport]="false" (onPageChange)="onPageChange($event)"
    class="primeng-paginator" *ngIf="(displayRow && displayRow !== totalRows) && totalRows > 100"></p-paginator>
</div>
