import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RulesTransformService {
  constructor() {}

  transformedData: any[] = [];

  transformedRules: any[] = [];
  flattenRules(rules: any, layer: number = 0): any {
    if (!this.transformedData[layer] && !this.transformedRules[layer]) {
      this.transformedData[layer] = [];
      this.transformedRules[layer] = [];
    }

    if (rules && rules.rules) {
      const nestedRules = rules.rules;
      const condition = rules.condition;

      for (const rule of nestedRules) {
        rule.layer = layer;

        if (rule.rules) {
          this.flattenRules(rule, layer + 1);
        } else {
          this.transformedRules[layer].push(rule);
        }

      }
      if (condition) {
        this.transformedData[layer].push({
          ruleCondition: condition,
          rules: this.transformedRules[layer],
        });
      }
    } else if (Array.isArray(rules)) {
      for (const rule of rules) {
        this.flattenRules(rule, layer);
      }
    }
    return this.transformedData.reduce((acc, val) => acc.concat(val), []);
  }
}
