import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyGridService {
  constructor() {}

  getSysData() {
    return [
      {
        SegmentCode: 'INV', // Parent key
        ParentCode: 'INV', // Parent key
        SegmentDesc: 'Inventory',
        SegmentCaption: 'Inventory',
        SegmentType: 0,
        RowVersion: 'AAAAAAcIZ9Q=',
        Children: [
          {
            SegmentCode: 'INV1',
            ParentCode: 'INV',
            SegmentDesc: 'Setup',
            SegmentCaption: 'Setup',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9U=',
            Children: [
              {
                GroupCode: 'INV', // Children key
                SetCode: 'DOCNOBYLOC', // Children key
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Document No. By Outlet (Prefix)',
                SetDesc: 'Document No. By Outlet (Prefix)',
                ValueType: 'D',
                InputType: 0,
                SetValue: '2023-03-02 07:33:11.5866667',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+I=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'INVLOTFORMAT',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Inventory Lot No. Format',
                SetDesc: 'Inventory Lot No. Format',
                ValueType: 'C',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"yyyyMMdd","ItemValue":"yyyyMMdd"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+E=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'PRINTOPTION',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Allow Document Printing After Post Only',
                SetDesc: 'Allow Document Printing After Post Only',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+A=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'SALESPOST',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Sales Posting Method',
                SetDesc: 'Sales Posting Method',
                ValueType: 'N',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"None","ItemValue":"0"},{"ItemDesc":"Stock Deduction","ItemValue":"1"},{"ItemDesc":"Standard Cost From Master","ItemValue":"2"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ98=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'AVGCOSTCALCTYPE',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Average Costing Calculation Type',
                SetDesc: 'Average Costing Calculation Type',
                ValueType: 'C',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Item","ItemValue":"I"},{"ItemDesc":"Item + Outlet","ItemValue":"V"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+U=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'AVGCOSTPERIOD',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Average Costing Period',
                SetDesc: 'Average Costing Period',
                ValueType: 'C',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Day","ItemValue":"D"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+Q=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'COSTINGMTHD',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Costing Method',
                SetDesc: 'Costing Method',
                ValueType: 'C',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"FIFO (First in first out)","ItemValue":"F"},{"ItemDesc":"WAC (Weighted Average)","ItemValue":"W"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+M=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'COSTINGMTHD',
                ParentCode: 'INV',
                SegmentCode: 'INV1',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Costing Method',
                SetDesc: 'url',
                ValueType: 'C',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"FIFO (First in first out)","ItemValue":"F"},{"ItemDesc":"WAC (Weighted Average)","ItemValue":"W"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+M=',
              },
            ],
          },
          {
            SegmentCode: 'INV10',
            ParentCode: 'INV',
            SegmentDesc: 'System Decimal Points Setting',
            SegmentCaption: 'System Decimal Points Setting',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ94=',
            Children: [
              {
                GroupCode: 'DECIMAL',
                SetCode: 'DECBYAMT',
                ParentCode: 'INV',
                SegmentCode: 'INV10',
                SegmentDesc: 'System Decimal Points Setting',
                SegmentCaption: 'System Decimal Points Setting',
                SetCaption: 'Amount Decimal Points',
                SetDesc: 'Amount Decimal Points',
                ValueType: 'N',
                InputType: 1,
                SetValue: '',
                DataSource: '{"SpinnerMin":0,"SpinnerMax":999,"SpinnerStep":1}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/8=',
              },
              {
                GroupCode: 'DECIMAL',
                SetCode: 'DECBYQTY',
                ParentCode: 'INV',
                SegmentCode: 'INV10',
                SegmentDesc: 'System Decimal Points Setting',
                SegmentCaption: 'System Decimal Points Setting',
                SetCaption: 'Quantity Decimal Points',
                SetDesc: 'Quantity Decimal Points',
                ValueType: 'N',
                InputType: 1,
                SetValue: '',
                DataSource: '{"SpinnerMin":0,"SpinnerMax":999,"SpinnerStep":1}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/4=',
              },
              {
                GroupCode: 'DECIMAL',
                SetCode: 'DECBYQTY',
                ParentCode: 'INV',
                SegmentCode: 'INV10',
                SegmentDesc: 'System Decimal Points Setting',
                SegmentCaption: 'System Decimal Points Setting',
                SetCaption: 'Quantity Decimal Points',
                SetDesc: 'url2',
                ValueType: 'N',
                InputType: 1,
                SetValue: '',
                DataSource: '{"SpinnerMin":0,"SpinnerMax":999,"SpinnerStep":1}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/4=',
              },
            ],
          },
          {
            SegmentCode: 'INV2',
            ParentCode: 'INV',
            SegmentDesc: 'Stock Request',
            SegmentCaption: 'Stock Request',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9Y=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'REQSAFELVLCHECK',
                ParentCode: 'INV',
                SegmentCode: 'INV2',
                SegmentDesc: 'Stock Request',
                SegmentCaption: 'Stock Request',
                SetCaption: 'Block if stock balance is above safety stock quantity',
                SetDesc: 'Block if stock balance is above safety stock quantity',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+k=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'STKREQAUTOAPPRV',
                ParentCode: 'INV',
                SegmentCode: 'INV2',
                SegmentDesc: 'Stock Request',
                SegmentCaption: 'Stock Request',
                SetCaption: 'Auto Approve For Stock Request',
                SetDesc: 'Auto Approve For Stock Request',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+Y=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'STKREQRESERVE',
                ParentCode: 'INV',
                SegmentCode: 'INV2',
                SegmentDesc: 'Stock Request',
                SegmentCaption: 'Stock Request',
                SetCaption: 'Stock Reservation Upon Approval',
                SetDesc: 'Stock Reservation Upon Approval',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+c=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'STKREQRMVZEROROW',
                ParentCode: 'INV',
                SegmentCode: 'INV2',
                SegmentDesc: 'Stock Request',
                SegmentCaption: 'Stock Request',
                SetCaption: 'Remove Zero Qty. Item(s) Upon Confirmation',
                SetDesc: 'Remove Zero Qty. Item(s) Upon Confirmation',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+g=',
              },
            ],
          },
          {
            SegmentCode: 'INV3',
            ParentCode: 'INV',
            SegmentDesc: 'Sales Order',
            SegmentCaption: 'Sales Order',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9c=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'SORESERVE',
                ParentCode: 'INV',
                SegmentCode: 'INV3',
                SegmentDesc: 'Sales Order',
                SegmentCaption: 'Sales Order',
                SetCaption: 'Stock Reservation Upon Confirmation',
                SetDesc: 'Stock Reservation Upon Confirmation',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+o=',
              },
            ],
          },
          {
            SegmentCode: 'INV4',
            ParentCode: 'INV',
            SegmentDesc: 'Stock Transfer',
            SegmentCaption: 'Stock Transfer',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9g=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'INVCHKDSCP',
                ParentCode: 'INV',
                SegmentCode: 'INV4',
                SegmentDesc: 'Stock Transfer',
                SegmentCaption: 'Stock Transfer',
                SetCaption: 'Invoice On Net Qty. (After Discrepancy)',
                SetDesc: 'Invoice On Net Qty. (After Discrepancy)',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+s=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'TRFRCVWITHDOCDT',
                ParentCode: 'INV',
                SegmentCode: 'INV4',
                SegmentDesc: 'Stock Transfer',
                SegmentCaption: 'Stock Transfer',
                SetCaption: 'Document date as receive date',
                SetDesc: 'Document date as receive date',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcM5Ns=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'AUTORCVTRF',
                ParentCode: 'INV',
                SegmentCode: 'INV4',
                SegmentDesc: 'Stock Transfer',
                SegmentCaption: 'Stock Transfer',
                SetCaption: 'Auto receive after transfer out',
                SetDesc: 'Auto receive after transfer out',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+w=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'BLKNEGTRFOUT',
                ParentCode: 'INV',
                SegmentCode: 'INV4',
                SegmentDesc: 'Stock Transfer',
                SegmentCaption: 'Stock Transfer',
                SetCaption: 'Block Negative Stock Out',
                SetDesc: 'Block Negative Stock Out',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+0=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'DOCTYPEMANDATORY',
                ParentCode: 'INV',
                SegmentCode: 'INV4',
                SegmentDesc: 'Stock Transfer',
                SegmentCaption: 'Stock Transfer',
                SetCaption: 'Document Type Mandatory',
                SetDesc: 'Document Type Mandatory',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+4=',
              },
            ],
          },
          {
            SegmentCode: 'INV5',
            ParentCode: 'INV',
            SegmentDesc: 'Purchase Order',
            SegmentCaption: 'Purchase Order',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9k=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'AUTOAPVPO',
                ParentCode: 'INV',
                SegmentCode: 'INV5',
                SegmentDesc: 'Purchase Order',
                SegmentCaption: 'Purchase Order',
                SetCaption: 'Auto Approve Upon Confirmation',
                SetDesc: 'Auto Approve Upon Confirmation',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcLkX4=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'MULTISTKTMP',
                ParentCode: 'INV',
                SegmentCode: 'INV5',
                SegmentDesc: 'Purchase Order',
                SegmentCaption: 'Purchase Order',
                SetCaption: 'Allow Multiple Stock Templates',
                SetDesc: 'Allow Multiple Stock Templates',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ+8=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'PORMVZEROROW',
                ParentCode: 'INV',
                SegmentCode: 'INV5',
                SegmentDesc: 'Purchase Order',
                SegmentCaption: 'Purchase Order',
                SetCaption: 'Remove Zero Qty. Item(s) Upon Approval',
                SetDesc: 'Remove Zero Qty. Item(s) Upon Approval',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/E=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'POSAFELVLCHECK',
                ParentCode: 'INV',
                SegmentCode: 'INV5',
                SegmentDesc: 'Purchase Order',
                SegmentCaption: 'Purchase Order',
                SetCaption: 'Block if stock balance is above safety stock quantity',
                SetDesc: 'Block if stock balance is above safety stock quantity',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/I=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'AUTOCLOSEPO',
                ParentCode: 'INV',
                SegmentCode: 'INV5',
                SegmentDesc: 'Purchase Order',
                SegmentCaption: 'Purchase Order',
                SetCaption: 'Auto close after all stock received',
                SetDesc: 'Auto close after all stock received',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/M=',
              },
            ],
          },
          {
            SegmentCode: 'INV6',
            ParentCode: 'INV',
            SegmentDesc: 'Goods Receive',
            SegmentCaption: 'Goods Receive',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9o=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'GRNLATESTPRICE',
                ParentCode: 'INV',
                SegmentCode: 'INV6',
                SegmentDesc: 'Goods Receive',
                SegmentCaption: 'Goods Receive',
                SetCaption: 'Update Supplier Price On Posting',
                SetDesc: 'Update Supplier Price On Posting',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/Q=',
              },
            ],
          },
          {
            SegmentCode: 'INV7',
            ParentCode: 'INV',
            SegmentDesc: 'Stock Adjustment',
            SegmentCaption: 'Stock Adjustment',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9s=',
            Children: [
              {
                GroupCode: 'INVSETUP',
                SetCode: 'ADJESTCOSTMODE',
                ParentCode: 'INV',
                SegmentCode: 'INV7',
                SegmentDesc: 'Stock Adjustment',
                SegmentCaption: 'Stock Adjustment',
                SetCaption: 'Adjust Est. Cost Mode',
                SetDesc: 'Adjust Est. Cost Mode',
                ValueType: 'N',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Off","ItemValue":"0"},{"ItemDesc":"Update Item With Zero Cost","ItemValue":"1"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/U=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'ADJESTCOSTOPTION',
                ParentCode: 'INV',
                SegmentCode: 'INV7',
                SegmentDesc: 'Stock Adjustment',
                SegmentCaption: 'Stock Adjustment',
                SetCaption: 'Adjust Est. Cost Option',
                SetDesc: 'Adjust Est. Cost Option',
                ValueType: 'N',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Last Stock In Cost","ItemValue":"0"},{"ItemDesc":"Supplier Price","ItemValue":"1"},{"ItemDesc":"Standard Cost","ItemValue":"2"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/Y=',
              },
            ],
          },
          {
            SegmentCode: 'INV8',
            ParentCode: 'INV',
            SegmentDesc: 'Stock Take',
            SegmentCaption: 'Stock Take',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ9w=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'IGNOREREASONMANDATORY',
                ParentCode: 'INV',
                SegmentCode: 'INV8',
                SegmentDesc: 'Stock Take',
                SegmentCaption: 'Stock Take',
                SetCaption: 'Ignore Inventory Document Reason Mandatory',
                SetDesc: 'Ignore Inventory Document Reason Mandatory',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcLGpI=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'STKDEFZERO',
                ParentCode: 'INV',
                SegmentCode: 'INV8',
                SegmentDesc: 'Stock Take',
                SegmentCaption: 'Stock Take',
                SetCaption: 'Zero Doc. Qty By Default',
                SetDesc: 'Zero Doc. Qty By Default',
                ValueType: 'N',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Empty Doc. Qty By Default","ItemValue":"0"},{"ItemDesc":"Zero Doc. Qty By Default","ItemValue":"1"},{"ItemDesc":"Follow Document (Empty/Zero)","ItemValue":"2"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/k=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'ESTCOSTMODE',
                ParentCode: 'INV',
                SegmentCode: 'INV8',
                SegmentDesc: 'Stock Take',
                SegmentCaption: 'Stock Take',
                SetCaption: 'Stock In Cost Estimation Mode',
                SetDesc: 'Stock In Cost Estimation Mode',
                ValueType: 'N',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Off","ItemValue":"0"},{"ItemDesc":"Update Item With Zero Cost","ItemValue":"1"},{"ItemDesc":"Update All Items","ItemValue":"2"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/c=',
              },
              {
                GroupCode: 'INVSETUP',
                SetCode: 'ESTCOSTOPTION',
                ParentCode: 'INV',
                SegmentCode: 'INV8',
                SegmentDesc: 'Stock Take',
                SegmentCaption: 'Stock Take',
                SetCaption: 'Stock In Cost Estimation Option',
                SetDesc: 'Stock In Cost Estimation Option',
                ValueType: 'N',
                InputType: 2,
                SetValue: '',
                DataSource: '{"SelectionItemList":[{"ItemDesc":"Last Stock In Cost","ItemValue":"0"},{"ItemDesc":"Supplier Price","ItemValue":"1"},{"ItemDesc":"Standard Cost","ItemValue":"2"}]}',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/g=',
              },
            ],
          },
          {
            SegmentCode: 'INV9',
            ParentCode: 'INV',
            SegmentDesc: 'General',
            SegmentCaption: 'General',
            SegmentType: 0,
            RowVersion: 'AAAAAAcIZ90=',
            Children: [
              {
                GroupCode: 'INV',
                SetCode: 'PRODUDMAPPING',
                ParentCode: 'INV',
                SegmentCode: 'INV9',
                SegmentDesc: 'General',
                SegmentCaption: 'General',
                SetCaption: 'Map Product User Defined Fields',
                SetDesc: 'Map Product User Defined Fields',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/w=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'REASONMANDATORY',
                ParentCode: 'INV',
                SegmentCode: 'INV9',
                SegmentDesc: 'General',
                SegmentCaption: 'General',
                SetCaption: 'Inventory Document Reason Mandatory',
                SetDesc: 'Inventory Document Reason Mandatory',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/s=',
              },
              {
                GroupCode: 'INV',
                SetCode: 'SCANAUTOINSERT',
                ParentCode: 'INV',
                SegmentCode: 'INV9',
                SegmentDesc: 'General',
                SegmentCaption: 'General',
                SetCaption: 'Auto Insert Line Scan Mode',
                SetDesc: 'Auto Insert Line Scan Mode',
                ValueType: 'L',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcIZ/o=',
              },
            ],
          },
        ],
      },
      {
        SegmentCode: 'PWRBI',
        ParentCode: 'PWRBI',
        SegmentDesc: 'Power BI Dashboard',
        SegmentCaption: 'Power BI Dashboard',
        SegmentType: 0,
        RowVersion: 'AAAAAAcJcmA=',
        Children: [
          {
            SegmentCode: 'SETUP',
            ParentCode: 'PWRBI',
            SegmentDesc: 'Setup',
            SegmentCaption: 'Setup',
            SegmentType: 0,
            RowVersion: 'AAAAAAcJcmE=',
            Children: [
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIAPIURL',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI API Url',
                SetDesc: 'Power BI API Url',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmo=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIAPPID',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Application Id',
                SetDesc: 'Power BI Application Id',
                ValueType: 'C',
                InputType: 0,
                DataSource: '',
                SetValue: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmI=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIAPPSECRET',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Application Secret',
                SetDesc: 'Power BI Application Secret',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmY=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIAUTHTYPE',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Authentication Type',
                SetDesc: 'Power BI Authentication Type',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmU=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIMAUTHORITYURL',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Authority Url',
                SetDesc: 'Power BI Authority Url',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcms=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIMSCOPE',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Scope',
                SetDesc: 'Power BI Scope',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmw=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIPASSWORD',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Password',
                SetDesc: 'Power BI Password',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmk=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIREPORTID',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Report Id',
                SetDesc: 'Power BI Report Id',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmQ=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBITENANT',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Tenant',
                SetDesc: 'Power BI Tenant',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmc=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIUSERNAME',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Username',
                SetDesc: 'Power BI Username',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmg=',
              },
              {
                GroupCode: 'PWRBI',
                SetCode: 'POWERBIWORKSPACEID',
                ParentCode: 'PWRBI',
                SegmentCode: 'SETUP',
                SegmentDesc: 'Setup',
                SegmentCaption: 'Setup',
                SetCaption: 'Power BI Workspace Id',
                SetDesc: 'Power BI Workspace Id',
                ValueType: 'C',
                InputType: 0,
                SetValue: '',
                DataSource: '',
                QueryString: null,
                RowVersion: 'AAAAAAcJcmM=',
              },
            ],
          },
        ],
      },
    ];
  }

  getChildData(parent: any, setting: any) {
    let obj = [];
    let children = setting?.childKey && parent[setting.childKey]? parent[setting.childKey]: [{[setting.childKey]: parent}];
    children.forEach((child: any) => {
      if (child[setting.childKey]) {
        obj = [...obj, ...this.getChildData(child, setting)];
      } else {
        obj.push(child);
      }
    });

    return obj;
  }

  getParentData(parentKeys: any, groupKeys: any, rowData: any, setting: any) {
    let obj = [];
    
    rowData.forEach(data => {
      let foundGroup = rowData.filter(child => groupKeys.includes(child[setting.groupKey]));
  
      if(data[setting.childKey]) {
        data[setting.childKey] = this.getParentData(parentKeys, groupKeys, data[setting.childKey], setting);

        if(foundGroup && foundGroup.length > 0) {
          if(groupKeys.includes(data[setting.groupKey])) {
            obj.push(data);
          }
        } else {
          if(parentKeys.includes(data[setting.parentKey])) {
            obj.push(data);
          }
        }
      } else {
        obj.push(data);
      }
    });

    return obj;
  }
}
