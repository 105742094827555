import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import moment from 'moment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { filterDateKey } from '../enums/filterDateKey';

@Injectable({
  providedIn: 'root'
})
export class StrucConvertService {

  constructor(private translateService: TranslateService) { }

  public convertField(inputArray: any[], checkValues?: any): any {
    inputArray.forEach(list => {
      list.rules.forEach(filterObj => {
        checkValues.forEach((item) => {
          if (item.id === filterObj.id) {
            filterObj.field = item.fieldName;
          }
        })
      })
    })
    return inputArray;

  }

  public addParent(inputArray: any[]): any {
    let outputObj = {
      logic: inputArray[0].logic,
      filters: inputArray
    }
    return outputObj
  }

  public removeParent(outputObj: any): any {
    if (outputObj && outputObj.filters && Array.isArray(outputObj.filters)) {
      return outputObj.filters;
    }
  }

  public convertJobRequest(inputArray: any[], checkValues?: any[]): any {
    const filters = [];

    const checkValuesMap = new Map<string, any>();
    if (checkValues) {
      checkValues.forEach(itm => {
        checkValuesMap.set(itm.fieldName, itm);
      });
    }

    inputArray.forEach(list => {
      const innerFilter = [];
      if (list.field && list.filters === null) {
        let fieldName = list.field.toLowerCase();
        const item = checkValuesMap.get(fieldName);

        if (item) {
          const combined = {
            fieldName: fieldName,
            field: list.field,
            id: list.field,
            type: item.type,
            input: item.input,
            operator: list.operator,
            value: list.value
          };
          innerFilter.push(combined);
        }
      } else if (list.filters) {
        list.filters.forEach(filterObj => {
          if (filterObj.field) {
            let fieldName = filterObj.field.toLowerCase();
            const item = checkValuesMap.get(fieldName);

            if (item) {
              const combined = {
                fieldName: fieldName,
                field: filterObj.field,
                id: filterObj.field,
                type: item.type,
                input: item.input,
                operator: filterObj.operator,
                value: filterObj.value
              };
              innerFilter.push(combined);
            }
          } else if (filterObj.filters) {
            let innerObj = filterObj.filters;
            let fieldName = innerObj[0].field.toLowerCase();
            const item = checkValuesMap.get(fieldName);

            if (item.fieldName === fieldName) {
              if (item.input === 'formuladatepicker') {
                const startFilter = {
                  fieldName: fieldName,
                  field: innerObj[0].field,
                  id: innerObj[0].field,
                  type: item.type,
                  input: item.input,
                  operator: "gte",
                  value: innerObj[0].value
                };

                const endFilter = {
                  fieldName: fieldName,
                  field: innerObj[0].field,
                  id: innerObj[0].field,
                  type: item.type,
                  input: item.input,
                  operator: "lte",
                  value: innerObj[1].value
                };

                innerFilter.push({
                  filters: [startFilter, endFilter],
                  logic: "and"
                });
              } else if (item.input === 'checkbox') {
                const checkboxArray = [];
                innerObj.forEach(keyValue => {
                  const combined = {
                    fieldName: fieldName,
                    field: innerObj[0].field,
                    id: innerObj[0].field,
                    type: item.type,
                    input: item.input,
                    operator: keyValue.operator,
                    value: keyValue.value
                  };
                  checkboxArray.push(combined);
                });
                innerFilter.push({ filters: checkboxArray, logic: "or" });
              }
            }
          }
        });
      }

      filters.push({ filters: innerFilter, logic: inputArray[0].logic.toLowerCase() });
    });

    return filters;
  }

  public convertArrayStructure(inputArray: any[], structureMapping: any): any {
    const outputArray: any[] = [];
    inputArray.forEach(item => {
      const newItem: any = {};
      Object.keys(structureMapping).forEach(key => {
        newItem[key] = item[structureMapping[key]];
      });
      outputArray.push(newItem);
    });

    return outputArray;
  }

  public convertNewStructure(inputArray: any[], checkValues?: any[]): any[] {
    const outputArray: any[] = [];
    inputArray.forEach(list => {
      const innerFilter = [];
      list.rules.forEach(filterObj => {
        const regex = /[a-zA-Z]/;
        if (filterObj.input === 'formuladatepicker' && regex.test(filterObj.value)) {
          const date = DateTime.fromFormat(filterObj.value, 'dd/MM/yyyy', { zone: 'utc' });
          const momentutc = moment(date);
          const offset = momentutc.format('Z');
          let valueType;

          if (this.extractValueText(filterObj.value).startDate === this.extractValueText(filterObj.value).endDate) {
            valueType = 'single';
          } else {
            valueType = 'range';
          }

          const newFilter = {
            id: filterObj.id,
            field: filterObj.id,
            type: filterObj.type,
            input: filterObj.input,
            operator: filterObj.operator,
            value: {
              formula: this.extractValueText(filterObj.value).title,
              from: this.convertDateString(!regex.test(filterObj.value), this.extractValueText(filterObj.value).startDate, 'start'),
              fromDate: this.extractValueText(filterObj.value).startDate,
              to: this.convertDateString(!regex.test(filterObj.value), this.extractValueText(filterObj.value).endDate, 'end'),
              toDate: this.extractValueText(filterObj.value).endDate,
              offSet: offset,
              showText: this.extractValueText(filterObj.value).range,
              type: valueType,
            }
          };
          innerFilter.push(newFilter);

        } else if (filterObj.input === 'formuladatepicker' && !regex.test(filterObj.value) && filterObj.operator !== 'between') {
          let dateParts = filterObj.value.split("/");
          let day = parseInt(dateParts[0]);
          let month = parseInt(dateParts[1]);
          let year = parseInt(dateParts[2]);
          let date = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
          let formattedDate = `${month}/${day}/${year}`;
          let reformDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`

          const newFilter = {
            id: filterObj.id,
            field: filterObj.id,
            type: filterObj.type,
            input: filterObj.input,
            operator: filterObj.operator,
            value: {
              formula: date,
              from: date,
              fromDate: reformDate,
              to: date,
              toDate: reformDate,
              offSet: date,
              showText: formattedDate,
              value: "",
              type: 'single'
            }
          };
          innerFilter.push(newFilter);

        } else if (filterObj.input === 'formuladatepicker' && !regex.test(filterObj.value) && filterObj.operator === 'between') {
          const [startValue, endValue] = filterObj.value.split(' - ').map(val => val.trim());

          const newFilter = {
            id: filterObj.id,
            field: filterObj.id,
            type: filterObj.type,
            input: filterObj.input,
            operator: filterObj.operator,
            value: [
              this.convertDateString(regex.test(filterObj.value), startValue), this.convertDateString(regex.test(filterObj.value), endValue)
            ]
          };
          innerFilter.push(newFilter);

        } else if (filterObj.input !== 'text') {
          let valueKeys;
          checkValues.forEach((item) => {
            if (item.id === filterObj.id) {
              valueKeys = this.getKeysByValue(item.values, filterObj.value)
            }
          })

          if (valueKeys.length > 0) {
            if (filterObj.input === 'checkbox') {
              const newFilter = {
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: filterObj.operator,
                type: filterObj.type,
                value: [valueKeys]
              };
              innerFilter.push(newFilter)
            } else {
              valueKeys.forEach(key => {
                const newFilter = {
                  field: filterObj.id,
                  id: filterObj.id,
                  input: filterObj.input,
                  operator: filterObj.operator,
                  type: filterObj.type,
                  value: key
                };
                innerFilter.push(newFilter)
              })
            }

          } else {
            const newFilter = {
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: filterObj.operator,
              type: filterObj.type,
              value: filterObj.value
            };
            innerFilter.push(newFilter)
          }
        } else {
          const newFilter = {
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: filterObj.operator,
            type: filterObj.type,
            value: filterObj.value
          };
          innerFilter.push(newFilter)
        }
      });
      outputArray.push({ rules: innerFilter, ruleCondition: list.ruleCondition });
    });
    return outputArray;
  }

  private dateFormula(optionName: any, offset?: any): any {
    let format = 'DD/MM/YYYY';
    if (!offset) {
      offset = 0;
    }

    switch (optionName) {
      case '[today]':
        const today = moment().add(offset, 'days').format(format);
        return today;

      case '[yesterday]':
        const yesterday = moment()
          .subtract(1, 'days')
          .add(offset, 'days')
          .format(format);
        return yesterday;

      case '[thisweek]':
        const startWeek = moment()
          .startOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const endWeek = moment()
          .endOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const thisWeek = `${startWeek} - ${endWeek}`;
        return thisWeek;

      case '[thismonth]':
        const startMonth = moment()
          .startOf('month')
          .add(offset, 'month')
          .format(format);
        const endMonth = moment()
          .endOf('month')
          .add(offset, 'month')
          .format(format);
        const thisMonth = `${startMonth} - ${endMonth}`;
        return thisMonth;


      case '[thisquarter]':
        const startQuarter = moment()
          .startOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const endQuarter = moment()
          .endOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const thisQuart = `${startQuarter} - ${endQuarter}`;
        return thisQuart;


      case '[thisyear]':
        const startYear = moment()
          .startOf('year')
          .add(offset, 'year')
          .format(format);
        const endYear = moment()
          .endOf('year')
          .add(offset, 'year')
          .format(format);
        const thisYear = `${startYear} - ${endYear}`;
        return thisYear;

      case '[pastweek]':
        const startPastWeek = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const lastPastWeek = moment()
          .subtract(1, 'weeks')
          .endOf('isoWeek')
          .add(offset, 'weeks')
          .format(format);
        const pastWeek = `${startPastWeek} - ${lastPastWeek}`;
        return pastWeek;

      case '[pastmonth]':
        const startPastMonth = moment()
          .subtract(1, 'month')
          .startOf('month')
          .add(offset, 'month')
          .format(format);
        const lastPastMonth = moment()
          .subtract(1, 'month')
          .endOf('month')
          .add(offset, 'month')
          .format(format);
        const pastMonth = `${startPastMonth} - ${lastPastMonth}`;
        return pastMonth;

      case '[pastquarter]':
        const startPastQuarter = moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const lastPastQuarter = moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
          .add(offset, 'quarter')
          .format(format);
        const pastQuart = `${startPastQuarter} - ${lastPastQuarter}`;
        return pastQuart;

      case '[pastyear]':
        const startPastYear = moment()
          .subtract(1, 'year')
          .startOf('year')
          .add(offset, 'year')
          .format(format);
        const lastPastYear = moment()
          .subtract(1, 'year')
          .endOf('year')
          .add(offset, 'year')
          .format(format);
        const pastYear = `${startPastYear} - ${lastPastYear}`;
        return pastYear;

    }
  }

  public getDateTitleInfo(filters: any[]): any[] {
    const dateTitleInfo = [];

    filters.forEach((filter, filterIndex) => {
      if (Array.isArray(filter.filters)) {
        filter.filters.forEach((rule, ruleIndex) => {
          if (Array.isArray(rule.filters)) {
            rule.filters.forEach((innerFilter, innerFilterIndex) => {
              if ('dateTitle' in innerFilter) {
                dateTitleInfo.push({
                  filterIndex,
                  ruleIndex,
                  innerFilterIndex,
                  dateTitle: innerFilter.dateTitle
                });
              }
            });
          } else if (rule.dateTitle) {
            let innerFilterIndex = null;
            dateTitleInfo.push({
              filterIndex,
              ruleIndex,
              innerFilterIndex,
              dateTitle: rule.dateTitle
            });
          }
        });
      }
    });

    return dateTitleInfo;
  }

  public reverseNewStructure(inputObject: any, checkValues?: any[], viewer?: boolean): any {
    const outputArray: any[] = [];
    let inputArray;
    if (viewer) {
      inputArray = inputObject;
    } else {
      inputArray = [inputObject];
    }

    let fieldName;
    inputArray.forEach(list => {
      const innerFilter = [];
      list.rules.forEach(filterObj => {
        checkValues.forEach((item) => {
          if (item.id === filterObj.id) {
            fieldName = item.fieldName;
            if (filterObj.value.formula) {
              if (filterObj.operator !== 'between' && filterObj.input === 'formuladatepicker' && filterObj.value.formula.includes('[')) {
                let formula;
                let date;

                if (this.checkForPlusOrMinusOne(filterObj.value.formula)) {
                  const datePart = this.separateParts(filterObj.value.formula).datePart;
                  const modifier = this.separateParts(filterObj.value.formula).modifier
                  formula = `${datePart}${modifier}`
                  date = this.dateFormula(datePart, modifier)
                } else {
                  const datePart = filterObj.value.formula;
                  formula = datePart;
                  if (filterDateKey[formula]) {
                    formula = filterDateKey[formula];
                  }
                  date = this.dateFormula(datePart)
                }

                let newFilter;
                newFilter = {
                  id: filterObj.id,
                  field: fieldName,
                  input: filterObj.input,
                  type: filterObj.type,
                  operator: filterObj.operator,
                  value: (`${formula} (${(date)})`)
                };

                innerFilter.push(newFilter)
              } else if (filterObj.operator !== 'between' && filterObj.input === 'formuladatepicker' && filterObj.value.formula.includes('Z')) {
                const newFilter = {
                  id: filterObj.id,
                  field: fieldName,
                  input: filterObj.input,
                  type: filterObj.type,
                  operator: filterObj.operator,
                  value: filterObj.value.formula
                };

                innerFilter.push(newFilter)
              } else if (filterObj.operator !== 'between' && filterObj.input === 'formuladatepicker' && filterObj.value.formula.includes('/')) {
                let date = this.convertDateFormat(filterObj.value.formula, true)

                const newFilter = {
                  id: filterObj.id,
                  field: fieldName,
                  input: filterObj.input,
                  type: filterObj.type,
                  operator: filterObj.operator,
                  value: date
                };

                innerFilter.push(newFilter)
              }
            } else if (filterObj.input === 'formuladatepicker' && filterObj.operator === 'between' && !filterObj.value[0].includes('/')) {
              const newFilter = {
                id: filterObj.id,
                field: fieldName,
                input: filterObj.input,
                type: filterObj.type,
                operator: filterObj.operator,
                value: `${this.reverseDateString(filterObj.value[0])} - ${this.reverseDateString(filterObj.value[1])}`
              };

              innerFilter.push(newFilter)
            } else if (filterObj.input === 'formuladatepicker' && filterObj.operator === 'between' && filterObj.value[0].includes('/')) {
              const newFilter = {
                id: filterObj.id,
                field: fieldName,
                input: filterObj.input,
                type: filterObj.type,
                operator: filterObj.operator,
                value: `${filterObj.value[0]} - ${filterObj.value[1]}`
              };

              innerFilter.push(newFilter)
            } else if (filterObj.input === 'checkbox' || filterObj.input === 'select' || filterObj.input === 'radio') {
              let valueKeys;
              checkValues.forEach((item) => {
                if (item.id === filterObj.id) {
                  if (filterObj.value.includes('general.')) {
                    valueKeys = filterObj.value;
                  } else {
                    valueKeys = this.combineKeysByValue(item.values, filterObj.value)
                  }
                }
              })
              const newFilter = {
                field: fieldName,
                id: filterObj.id,
                input: filterObj.input,
                operator: filterObj.operator,
                type: filterObj.type,
                value: valueKeys
              };
              innerFilter.push(newFilter)
            } else {
              const newFilter = {
                field: fieldName,
                id: filterObj.id,
                input: filterObj.input,
                type: filterObj.type,
                operator: filterObj.operator,
                value: filterObj.value
              };

              innerFilter.push(newFilter)
            }
          }
        });
      });
      outputArray.push({ rules: innerFilter, ruleCondition: list.ruleCondition || list.condition })
    })

    return outputArray;
  }

  public toQueryStringParam(inputArray: any): string {
    const groupStrings = inputArray.map((array, groupIndex) => {
      const filterStrings = array.filters.map(filter => {
        let field;
        let operator;
        let value;
        if (Array.isArray(filter.filters)) {
          const arrayStrings = filter.filters.map(item => {
            const nestedField = this.camelCase(item.id)
            const nestedOperator = this.queryOpChange(item.operator);
            const nestedValue = item.value

            return `${nestedField} ${nestedOperator} ${nestedValue}`;
          })
          return `(${arrayStrings.join(` ${filter.logic.toLowerCase()} `)})`;
        } else {
          field = this.camelCase(filter.id);
          filter.operator = this.queryOpChange(filter.operator);

          if (filter.operator === 'isnull') {
            operator = 'eq';
            value = null;
          } else if (filter.operator === 'isnotnull') {
            operator = 'neq';
            value = null;
          } else if (filter.operator === 'startswith' || filter.operator === 'endswith' || filter.operator === 'contains') {
            field = `${filter.operator}(${this.camelCase(filter.id)},'${filter.value}')`;
            operator = '';
            value = '';
          } else if (filter.operator === 'doesnotcontain') {
            field = `indexof(${this.camelCase(filter.id)},'${filter.value}') eq -1`;
            operator = '';
            value = '';
          } else {
            operator = filter.operator;
            value = filter.value;
          }
        }

        return operator === '' && value === '' ? `${field}` : `${field} ${operator} ${value}`;
      });
      return groupIndex === 0 ? filterStrings.join(` ${array.logic.toLowerCase()} `) : `(${filterStrings.join(` ${array.logic.toLowerCase()} `)})`;
    })
    return `(${groupStrings.join(` ${inputArray[0].logic.toLowerCase()} `)})`
  }

  private queryOpChange(op: any): any {
    switch (op) {
      case 'neq': return 'ne';
      case 'gte': return 'ge';
      case 'lte': return 'le';
      default: return op;
    }
  }

  private camelCase(str: string): string {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  private extractValueText(input: string): { title: string, startDate: any, endDate: any, range: any } {
    if (input) {
      const rangeMatch = input.match(/^(.*?)\s*\(([^-]+)(?: - ([^)]+))?\)/);
      if (rangeMatch) {
        let formulaTitle;
        let titleRange = rangeMatch[1].trim();

        if (rangeMatch[1].match(/.+\s*[-+]\s*\d+/)) {
          const titleMatch = rangeMatch[1].match(/(.+)\s*([-+])\s*(\d+)/);
          const text = titleMatch[1].trim();
          const calculation = `${titleMatch[2]}${titleMatch[3]}`
          formulaTitle = `[${text.toLowerCase()}]` + calculation;
        } else {
          formulaTitle = `[${titleRange.replace(/\s+/g, '').toLowerCase()}]`;
        }
        const title = formulaTitle;
        const startDate = rangeMatch[2].trim();
        const endDate = rangeMatch[3] ? rangeMatch[3].trim() : startDate;
        const range = `${startDate}${rangeMatch[3] ? ' - ' + endDate : ''}`;

        return { title, startDate, endDate, range };
      } else {
        const match = input.match(/^([^(\d]+)\s*(?:\(([^)]+)\))?$/);
        if (match) {
          const title = `[${match[1].trim().toLowerCase()}]`;
          const startDate = match[2]?.trim() || '';
          const endDate = startDate || '';
          const range = startDate ? `${startDate}` : '';

          return { title, startDate, endDate, range };
        }
        return { title: input.trim(), startDate: '', endDate: '', range: '' };
      }
    } else {
      return { title: '', startDate: '', endDate: '', range: '' };
    }
  }

  private convertOp(op: any): any {
    let opConvert;

    switch (op) {
      case 'equal':
        opConvert = 'eq';
        break;
      case 'not_equal':
        opConvert = 'neq';
        break;
      case 'greater':
        opConvert = 'gt';
        break;
      case 'greater_or_equal':
        opConvert = 'gte';
        break;
      case 'lesser':
        opConvert = 'lt';
        break;
      case 'less_or_equal':
        opConvert = 'lte';
        break;
      case 'in':
        opConvert = 'eq';
        break;
      case 'not_in':
        opConvert = 'neq';
        break;
      case 'begins_with':
        opConvert = 'startswith';
        break;
      case 'ends_with':
        opConvert = 'endswith';
        break;
      case 'contains':
        opConvert = 'contains';
        break;
      case 'not_contains':
        opConvert = 'doesnotcontain';
        break;
      case 'isnull':
        opConvert = 'isnull';
        break;
      case 'isnotnull':
        opConvert = 'isnotnull';
        break;
    }
    return opConvert;
  }

  private reverseOp(op: any, type: any): any {
    let opReverse;

    switch (op) {
      case 'eq':
        if (type === 'checkbox') {
          opReverse = 'in';
        } else {
          opReverse = 'equal';
        }
        break;
      case 'neq':
        if (type === 'checkbox') {
          opReverse = 'nin';
        } else {
          opReverse = 'not_equal';
        }
        break;
      case 'gt':
        opReverse = 'greater';
        break;
      case 'gte':
        opReverse = 'greater_or_equal';
        break;
      case 'lt':
        opReverse = 'lesser';
        break;
      case 'lte':
        opReverse = 'less_or_equal';
        break;
      case 'startswith':
        opReverse = 'begins_with';
        break;
      case 'endswith':
        opReverse = 'ends_with';
        break;
      case 'contains':
        opReverse = 'contains';
        break;
      case 'doesnotcontain':
        opReverse = 'not_contains';
        break;
      case 'isnull':
        opReverse = 'isnull';
        break;
      case 'isnotnull':
        opReverse = 'isnotnull';
        break;
      case 'in':
        opReverse = 'in'
        break;
      case 'nin':
        opReverse = 'not_in'
        break;
    }
    return opReverse;
  }

  public separateMultiple(inputFilter: any[], checkValues?: any[]): any[] {
    const filters = [];
    inputFilter.forEach(list => {
      const innerFilter = [];
      list.rules.forEach(filterObj => {

        if (filterObj.value === "SQLBREAKLOCCODE") {
          return;
        }

        if (filterObj.operator === 'between' && filterObj.value.includes(' ~ ')) {
          const [startValue, endValue] = filterObj.value.split(' ~ ').map(val => val.trim());

          const startFilter = {
            fieldName: filterObj.field,
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: "gte",
            type: filterObj.type,
            value: startValue
          };

          const endFilter = {
            fieldName: filterObj.field,
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: "lte",
            type: filterObj.type,
            value: endValue
          };

          innerFilter.push({
            filters: [startFilter, endFilter],
            logic: "and"
          });
        } else if (filterObj.input === 'checkbox' && filterObj.value.includes(', ')) {
          const checkboxArray = [];
          let valueKeys;

          checkValues.forEach(item => {
            if (item.id === filterObj.id) {
              valueKeys = this.getKeysByValue(item.values, filterObj.value)
            }
          })

          valueKeys.forEach(key => {
            const newFilter = {
              fieldName: filterObj.field,
              field: filterObj.id, id: filterObj.id,
              input: "checkbox",
              operator: this.convertOp(filterObj.operator),
              type: filterObj.type,
              value: key
            };
            checkboxArray.push(newFilter);
          });
          innerFilter.push({ filters: checkboxArray, logic: "or" });
        } else if (filterObj.input === 'formuladatepicker' && filterObj.operator === 'equal' && filterObj.value.includes('(')) {
          const startValue = this.extractValueText(filterObj.value).startDate;
          const endValue = this.extractValueText(filterObj.value).endDate;
          const dayOption = this.extractValueText(filterObj.value).title;

          if (this.checkForPlusOrMinusOne(dayOption)) {
            let parts = this.separateParts(dayOption)
            let test = ['Today', 'Yesterday'];
            if (test.includes(filterDateKey[parts.datePart])) {
              const filter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: this.convertOp(filterObj.operator),
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: `${filterDateKey[parts.datePart]} ${parts.modifier}`
              };

              innerFilter.push(filter);
            } else {
              const startFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "gte",
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: `${filterDateKey[parts.datePart]} ${parts.modifier}`
              };

              const endFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "lte",
                type: filterObj.type,
                value: this.convertDateString(true, endValue, 'end'),
                dateTitle: `${filterDateKey[parts.datePart]} ${parts.modifier}`
              };

              innerFilter.push({ filters: [startFilter, endFilter], logic: "and" });
            }
          } else {
            if (dayOption === 'Today' || dayOption === 'Yesterday') {
              const filter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: this.convertOp(filterObj.operator),
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: dayOption
              };

              innerFilter.push(filter);
            } else {
              const startFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "gte",
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: dayOption
              };

              const endFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "lte",
                type: filterObj.type,
                value: this.convertDateString(true, endValue, 'end'),
                dateTitle: dayOption
              };

              innerFilter.push({ filters: [startFilter, endFilter], logic: "and" });
            }
          }
        } else if (filterObj.input === 'formuladatepicker' && filterObj.operator === 'not_equal' && filterObj.value.includes('(')) {
          const startValue = this.extractValueText(filterObj.value).startDate;
          const endValue = this.extractValueText(filterObj.value).endDate;
          const dayOption = this.extractValueText(filterObj.value).title;
          if (this.checkForPlusOrMinusOne(dayOption)) {
            let parts = this.separateParts(dayOption);
            let test = ['Today', 'Yesterday'];
            if (test.includes(filterDateKey[parts.datePart])) {
              const filter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: this.convertOp(filterObj.operator),
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: `${filterDateKey[parts.datePart]} ${parts.modifier}`
              };

              innerFilter.push(filter);
            } else {
              const startFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "lte",
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: `${filterDateKey[parts.datePart]} ${parts.modifier}`
              };

              const endFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "gte",
                type: filterObj.type,
                value: this.convertDateString(true, endValue, 'end'),
                dateTitle: `${filterDateKey[parts.datePart]} ${parts.modifier}`
              };

              innerFilter.push({ filters: [startFilter, endFilter], logic: "and" });
            }
          } else {
            let test = ['Today', 'Yesterday'];

            if (test.includes(filterDateKey[dayOption])) {
              const filter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: this.convertOp(filterObj.operator),
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: filterDateKey[dayOption]
              };

              innerFilter.push(filter);
            } else {
              const startFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "lte",
                type: filterObj.type,
                value: this.convertDateString(true, startValue, 'start'),
                dateTitle: filterDateKey[dayOption]
              };

              const endFilter = {
                fieldName: filterObj.field,
                field: filterObj.id,
                id: filterObj.id,
                input: filterObj.input,
                operator: "gte",
                type: filterObj.type,
                value: this.convertDateString(true, endValue, 'end'),
                dateTitle: filterDateKey[dayOption]
              };

              innerFilter.push({ filters: [startFilter, endFilter], logic: "and" });
            }
          }
        } else if (filterObj.input === 'formuladatepicker' && !filterObj.value.includes('[') && filterObj.operator !== 'between') {
          const filter = {
            fieldName: filterObj.field,
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: this.convertOp(filterObj.operator),
            type: filterObj.type,
            value: this.convertDateString(!filterObj.value.includes('['), filterObj.value),
          };

          innerFilter.push(filter);
        } else if (filterObj.input === 'formuladatepicker' && !filterObj.value.includes('[') && filterObj.operator === 'between') {
          const [startValue, endValue] = filterObj.value.split(' - ').map(val => val.trim());

          const startFilter = {
            fieldName: filterObj.field,
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: "gte",
            type: filterObj.type,
            value: this.convertDateString(!filterObj.value.includes('['), startValue, 'start')
          };

          const endFilter = {
            fieldName: filterObj.field,
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: "lte",
            type: filterObj.type,
            value: this.convertDateString(!filterObj.value.includes('['), endValue, 'end')
          };

          innerFilter.push({
            filters: [startFilter, endFilter],
            logic: "and"
          });
        } else if (typeof (filterObj.value) === 'string' && filterObj.value.includes('general.')) {
          let valueKeys;
          checkValues.forEach(item => {
            if (item.id === filterObj.id) {
              valueKeys = this.getKeysByValue(item.values, filterObj.value)
            }
          })

          valueKeys.forEach(key => {
            const newFilter = {
              fieldName: filterObj.field,
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: this.convertOp(filterObj.operator),
              type: filterObj.type,
              value: key
            };
            innerFilter.push(newFilter);
          });
        } else if (filterObj.input === 'lookup' && filterObj.operator === 'in') {
          let filters;
          if (Array.isArray(filterObj.value)) {
            filters = filterObj.value.map(value => ({
              fieldName: filterObj.field,
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: "eq",
              type: filterObj.type,
              value: value
            }));
          } else if (filterObj.value.includes(', ')) {
            const splitValue = filterObj.value.split(', ');
            filters = splitValue.map(value => ({
              fieldName: filterObj.field,
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: "eq",
              type: filterObj.type,
              value: value
            }));
          } else {
            filters = [{
              fieldName: filterObj.field,
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: "eq",
              type: filterObj.type,
              value: filterObj.value
            }]
          }

          innerFilter.push({
            filters: filters,
            logic: "or"
          });
        } else if (filterObj.input === 'lookup' && filterObj.operator === 'not_in') {
          let filters;
          if (Array.isArray(filterObj.value)) {
            filters = filterObj.value.map(value => ({
              fieldName: filterObj.field,
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: "neq",
              type: filterObj.type,
              value: value
            }));
          } else if (filterObj.value.includes(', ')) {
            const splitValue = filterObj.value.split(', ');
            filters = splitValue.map(value => ({
              fieldName: filterObj.field,
              field: filterObj.id,
              id: filterObj.id,
              input: filterObj.input,
              operator: "neq",
              type: filterObj.type,
              value: value
            }));
          }

          innerFilter.push({
            filters: filters,
            logic: "or"
          });
        } else {
          const newFilter = {
            fieldName: filterObj.field,
            field: filterObj.id,
            id: filterObj.id,
            input: filterObj.input,
            operator: this.convertOp(filterObj.operator),
            type: filterObj.type,
            value: filterObj.value
          };

          innerFilter.push(newFilter);
        }
      });

      if (innerFilter.length > 0) {
        filters.push({ rules: innerFilter, ruleCondition: list.ruleCondition.toLowerCase() })
      }
    });
    return filters;
  }

  public bindDateTitle(filters: any[], parsed: any[]): any[] {
    if (parsed) {
      parsed.forEach(item => {
        const { filterIndex, ruleIndex, innerFilterIndex, dateTitle } = item;
        filters.forEach(filter => {
          if (Array.isArray(filter.filters)) {
            filter = filter.filters[filterIndex]
            if (filter.filters) {
              if (filter.filters[ruleIndex]) {
                const innerFilter = filter.filters[innerFilterIndex];

                innerFilter.dateTitle = dateTitle;
              }
            } else {
              const innerFilter = filter

              innerFilter.dateTitle = dateTitle;
            }
          }
        });
      })
      return filters;
    } else {
      return filters;
    }
  }

  public combineMultiple(inputFilter: any[], checkValues?: any[]): any[] {
    const filters = [];
    inputFilter.forEach(list => {
      const innerFilter = [];
      list.filters.forEach(filterObj => {
        if (filterObj.value === "SQLBREAKLOCCODE") {
          return;
        }

        let innerObj = filterObj.filters;
        if (Array.isArray(filterObj.filters)) {
          let hasRange = false;
          let hasComma = false;
          let eqFormulaDateRange = false;
          let neqFormulaDateRange = false;
          let eqDateFormulaCalc = false;
          let neqDateFormulaCalc = false;
          let dateRange = false;
          let lookupIn = false;
          let lookupNotIn = false;

          innerObj.forEach(f => {
            if ((innerObj[0].operator === "gte" && innerObj[1].operator === "lte") && innerObj[0].input !== 'formuladatepicker') {
              hasRange = true;
            } else if (innerObj[0].dateTitle && !this.checkForPlusOrMinusOne(innerObj[0].dateTitle) && (innerObj[0].operator === "gte" && innerObj[1].operator === "lte") && innerObj[0].input === 'formuladatepicker') {
              eqFormulaDateRange = true;
            } else if (innerObj[0].dateTitle && !this.checkForPlusOrMinusOne(innerObj[0].dateTitle) && (innerObj[0].operator === "lte" && innerObj[1].operator === "gte") && innerObj[0].input === 'formuladatepicker') {
              neqFormulaDateRange = true;
            } else if (innerObj[0].dateTitle && this.checkForPlusOrMinusOne(innerObj[0].dateTitle) && (innerObj[0].operator === "gte" && innerObj[1].operator === "lte") && innerObj[0].input === 'formuladatepicker') {
              eqDateFormulaCalc = true;
            } else if (innerObj[0].dateTitle && this.checkForPlusOrMinusOne(innerObj[0].dateTitle) && (innerObj[0].operator === "lte" && innerObj[1].operator === "gte") && innerObj[0].input === 'formuladatepicker') {
              neqDateFormulaCalc = true;
            } else if ((innerObj[0].operator === "gte" && innerObj[1].operator === "lte") && innerObj[0].input === 'formuladatepicker' && !innerObj[0].dateTitle) {
              dateRange = true;
            } else if (f.input === "checkbox") {
              hasComma = true;
            } else if (innerObj[0].input === 'lookup' && innerObj[0].operator === 'eq') {
              lookupIn = true;
            } else if (innerObj[0].input === 'lookup' && innerObj[0].operator === 'neq') {
              lookupNotIn = true;
            }
          });

          if (hasRange) {
            const combinedValue = innerObj.map(f => f.value).join(' ~ ');

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: "between",
              type: innerObj[0].type,
              value: combinedValue
            };

            innerFilter.push(combined);

          } else if (eqFormulaDateRange) {
            let start, end;

            if (innerObj[0].value.includes('Z')) {
              start = this.reverseDateString(innerObj[0].value);
              end = this.reverseDateString(innerObj[1].value);
            } else {
              start = innerObj[0].value;
              end = innerObj[1].value;
            }

            const title = filterDateKey[innerObj[0].dateTitle];
            let combinedValue;
            let test = ['Today', 'Yesterday']
            if (test.includes(title)) {
              combinedValue = `${start}`;
            } else {
              combinedValue = `${start} - ${end}`;
            }

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: "equal",
              type: innerObj[0].type,
              value: `${title} (${combinedValue})`
            };

            innerFilter.push(combined);

          } else if (neqFormulaDateRange) {
            let start, end;

            if (innerObj[0].value.includes('Z')) {
              start = this.reverseDateString(innerObj[0].value);
              end = this.reverseDateString(innerObj[1].value);
            } else {
              start = innerObj[0].value;
              end = innerObj[1].value;
            }
            const title = filterDateKey[innerObj[0].dateTitle];
            let combinedValue;
            let test = ['Today', 'Yesterday']
            if (test.includes(title)) {
              combinedValue = `${start}`;
            } else {
              combinedValue = `${start} - ${end}`;
            }

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: "not_equal",
              type: innerObj[0].type,
              value: `${title} (${combinedValue})`
            };

            innerFilter.push(combined);

          } else if (eqDateFormulaCalc) {
            let start, end;

            if (innerObj[0].value.includes('Z')) {
              start = this.reverseDateString(innerObj[0].value);
              end = this.reverseDateString(innerObj[1].value);
            } else {
              start = innerObj[0].value;
              end = innerObj[1].value;
            }
            const title = innerObj[0].dateTitle;
            const combinedValue = `${start} - ${end}`;

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: "equal",
              type: innerObj[0].type,
              value: `${title} (${combinedValue})`
            };
            innerFilter.push(combined);

          } else if (neqDateFormulaCalc) {
            let start, end;

            if (innerObj[0].value.includes('Z')) {
              start = this.reverseDateString(innerObj[0].value);
              end = this.reverseDateString(innerObj[1].value);
            } else {
              start = innerObj[0].value;
              end = innerObj[1].value;
            }
            const title = innerObj[0].dateTitle;
            const combinedValue = `${start} - ${end}`;

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: "not_equal",
              type: innerObj[0].type,
              value: `${title} (${combinedValue})`
            };

            innerFilter.push(combined);

          } else if (dateRange) {
            const start = this.reverseDateString(innerObj[0].value);
            const end = this.reverseDateString(innerObj[1].value);
            let combinedValue;
            let op;
            if (start === end) {
              if (innerObj[0].operator === 'gte' && innerObj[1].operator === 'lte') {
                op = 'equal'
              } else if (innerObj[0].operator === 'lte' && innerObj[1].operator === 'gte') {
                op = 'not_equal'
              }
              combinedValue = start;
            } else {
              op = 'between'
              combinedValue = `${start} - ${end}`;
            }

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: op,
              type: innerObj[0].type,
              value: `${combinedValue}`
            };

            innerFilter.push(combined);

          } else if (hasComma) {
            const combinedKey = innerObj.map(f => f.value).join(', ');
            let combinedValue;

            checkValues.forEach(item => {
              if (item.id === innerObj[0].id) {
                combinedValue = this.combineKeysByValue(item.values, combinedKey)
              }
            })

            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: "checkbox",
              operator: this.reverseOp(innerObj[0].operator, innerObj[0].input),
              type: innerObj[0].type,
              value: combinedValue
            };

            innerFilter.push(combined);
          } else if (lookupIn) {
            const combinedKey = innerObj.map(f => f.value).map(value => value.includes(',') ? value : [value]).flat().join(', ');
            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: 'in',
              type: innerObj[0].type,
              value: combinedKey
            }

            innerFilter.push(combined);
          } else if (lookupNotIn) {
            const combinedKey = innerObj.map(f => f.value).join(', ');
            const combined = {
              field: innerObj[0].fieldName,
              id: innerObj[0].id,
              input: innerObj[0].input,
              operator: 'not_in',
              type: innerObj[0].type,
              value: combinedKey
            }

            innerFilter.push(combined);
          }
        } else {
          if (filterObj.input === 'checkbox') {
            const combinedKey = [filterObj].map(f => f.value).join(', ');
            let combinedValue;

            checkValues.forEach(item => {
              if (item.id === filterObj.id) {
                combinedValue = this.combineKeysByValue(item.values, combinedKey)
              }
            })
            const combined = {
              field: filterObj.fieldName,
              id: filterObj.id,
              input: "checkbox",
              operator: this.reverseOp(filterObj.operator, filterObj.input),
              type: filterObj.type,
              value: combinedValue
            };

            innerFilter.push(combined);
          } else if (filterObj.input === 'formuladatepicker' && filterObj.dateTitle) {
            const combined = {
              field: filterObj.fieldName,
              id: filterObj.id,
              input: filterObj.input,
              operator: this.reverseOp(filterObj.operator, filterObj.input),
              type: filterObj.type,
              value: `${filterObj.dateTitle} (${this.reverseDateString(filterObj.value)})`
            };
            innerFilter.push(combined)

          } else if (filterObj.input === 'formuladatepicker' && !filterObj.dateTitle) {
            const combined = {
              field: filterObj.fieldName,
              id: filterObj.id,
              input: filterObj.input,
              operator: this.reverseOp(filterObj.operator, filterObj.input),
              type: filterObj.type,
              value: this.reverseDateString(filterObj.value)
            };
            innerFilter.push(combined)

          } else if (filterObj.input === 'select' || filterObj.input === 'radio') {
            let combinedValue;

            checkValues.forEach(item => {
              if (item.id === filterObj.id) {
                combinedValue = this.combineKeysByValue(item.values, filterObj.value)
              }
            })

            const combined = {
              field: filterObj.fieldName,
              id: filterObj.id,
              input: filterObj.input,
              operator: this.reverseOp(filterObj.operator, filterObj.input),
              type: filterObj.type,
              value: combinedValue
            };
            innerFilter.push(combined)

          } else {
            const combined = {
              field: filterObj.fieldName,
              id: filterObj.id,
              input: filterObj.input,
              operator: this.reverseOp(filterObj.operator, filterObj.input),
              type: filterObj.type,
              value: filterObj.value
            };
            innerFilter.push(combined)
          }
        }
      });
      if (list.logic === 'and') {
        list.logic = 'AND';
      } else if (list.logic === 'or') {
        list.logic = 'OR';
      }

      if (innerFilter.length > 0) {
        filters.push({ filters: innerFilter, logic: list.logic });
      }
    });
    return filters;
  }

  private getKeysByValue(valuesObject: any, targetValue: string): string[] {
    const keys: string[] = [];
    let targetValues;

    if (targetValue.includes(', ')) {
      targetValues = targetValue.split(',').map(val => val.trim());
    } else {
      targetValues = targetValue;
    }

    for (const key in valuesObject) {
      if (valuesObject.hasOwnProperty(key)) {
        if (targetValues.includes(valuesObject[key])) {
          keys.push(key);
        }
      }
    }
    return keys;
  }

  private combineKeysByValue(valuesObject: any, targetKeys: string): string {
    if (Array.isArray(targetKeys)) {
      targetKeys = targetKeys.join(',');
      let targetValues = targetKeys.split(',').map(val => val.trim());
      let targetValue = targetValues.map(key => valuesObject[key]);

      return targetValue.join(', ');
    } else {
      let targetValues = targetKeys.split(',').map(val => val.trim());
      let targetValue = targetValues.map(key => valuesObject[key]);
      return targetValue.join(', ');
    }
  }

  private convertDateString(isNeed?: boolean, dateString?: string, type?: string): any {
    let dateFormat = this.convertDateFormat(dateString);
    if (isNeed) {
      if (type === 'start' || type === null) {
        const date = DateTime.fromFormat(dateFormat, 'dd/MM/yyyy', { zone: 'utc' });
        const utc = date.toUTC();
        const localTime = utc.toISO();

        return localTime;

      } else if (type === 'end') {
        const date = DateTime.fromFormat(dateFormat, 'dd/MM/yyyy', { zone: 'utc' });
        const dateEnd = date.set({ hour: 23, minute: 59, second: 0 });
        const utc = dateEnd.toUTC();
        const localTime = utc.toISO();

        return localTime;

      } else {
        const date = DateTime.fromFormat(dateFormat, 'dd/MM/yyyy', { zone: 'utc' });
        const utc = date.toUTC();
        const localTime = utc.toISO();

        return localTime;
      }
    } else {
      return dateFormat;
    }
  }

  private reverseDateString(dateString: string): string {
    const dateObj = DateTime.fromISO(dateString, { zone: 'utc' });
    const day = dateObj.day.toString().padStart(2, '0');
    const month = dateObj.month.toString().padStart(2, '0');
    const year = dateObj.year.toString();
    return `${day}/${month}/${year}`;
  }

  private convertDateFormat(inputDate: string, isReverse?: boolean): string {
    const dateParts = inputDate.split('/');
    let day;
    let month;
    const year = dateParts[2];

    if (isReverse) {
      day = dateParts[1];
      month = dateParts[0];
    } else {
      day = dateParts[0];
      month = dateParts[1];
    }

    if (day.length === 1) {
      day = '0' + day;
    }
    if (month.length === 1) {
      month = '0' + month;
    }

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  private checkForPlusOrMinusOne(value: string): boolean {
    const regex = /.+\s*[+-]\s*\d+/;
    return regex.test(value);
  }

  private separateParts(value: string): { datePart: string, modifier: string } | null {
    const regex = /^(\[.*?\])\s*([+-]\d+)$/;
    const match = value.match(regex);

    if (match) {
      if (match[2] !== '') {
        match[2] = match[2].replace(/([+-])(\d+)/, '$1 $2');
      }
      return {
        datePart: match[1].replace(/\s+/g, ''),
        modifier: match[2]
      };
    }
    return null;
  }
}
