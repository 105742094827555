import { filter, first } from 'rxjs/operators';
import { Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyGridComponent } from 'src/app/shared/component/property-grid/property-grid.component';
import { ColDef } from 'ag-grid-community';
import { PropertyGridService } from 'src/app/shared/component/services/property-grid.service';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { TreeSetting } from 'src/app/shared/component/tree/model/treeSetting.model';
import { StoreService } from '../store.service';
import { FormCode } from 'src/app/shared/enums/formCode';
import { AuthService } from 'src/app/core/services/auth.service';
import { PageService } from 'src/app/shared/services/page.service';
import { Page } from 'src/app/shared/models/page.model';
import { GetSettingsRequest } from 'src/swagger/cxmbackend/openapi/model/getSettingsRequest';
import { composeSortDescriptors } from '@progress/kendo-data-query';
import { BackendChannelPlatformSet } from 'src/swagger/cxmbackend/openapi';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { FormService } from 'src/app/shared/component/form/form.service';
import { PropertyGridSetting } from 'src/app/shared/models/PropertyGridSetting.model';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { LocalChannelService } from '../../channel/localChannel.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ActionToolBarItem } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarItem.model';
import { ActionToolbarService } from 'src/app/shared/component/services/action-toolbar.service';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { ButtonType } from 'src/app/shared/enums/buttonType';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';

@Component({
  selector: 'app-setting',
  standalone: true,
  imports: [CommonModule, PropertyGridComponent],
  templateUrl: './store-setting.component.html',
  styleUrls: ['./store-setting.component.scss']
})
export class StoreSettingComponent implements OnInit {
  rowData: any;
  colDef: ColDef[];
  sysOptionsData: any = [];
  setting: PropertyGridSetting;
  menuSetting: TreeSetting;
  formDetail: any = null;
  storeId: any =undefined;
  formGroup: FormGroup;
  page: Page;
  data: any;
  listOfChildren: any = [];
  filters: any = {channelId: undefined, platformCode: undefined};
  filterTags: string[] = ['channelId', 'platformCode'];
  currentFilter: string = '';
  actionToolbarItems: ActionToolBarItem[];
  actionToolbarSetting: ActionToolBarSetting;
  filterDropDown: any =  [];

  constructor(private storeService: StoreService,
    private pageService: PageService,
    private dialog: MatDialog,
    private formService: FormService,
    private channelService: LocalChannelService,
    private loaderService: LoaderService,
    private actionToolbarService: ActionToolbarService
    ) {}

  async ngOnInit() {

    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    this.filters = {channelId: undefined, platformCode: undefined};
    if (this.page) {
      let params = this.pageService.getParameterDecode(this.page);
      this.storeId = params['storeId'];
    }

    //Platform dropdown setup
    {
    let platforms =  await this.storeService.getStorePlatformData();
    let settingPlatform = {
      name: 'platformCode', text: 'Select a platform', sortNo: 1, dropdownItm: [
    ]
    };
    let platformList = [];
    let index = 1
    platforms.value.forEach(platform => {
      platformList.push(
        { name: platform.platformCode, text: platform.platformDesc, sortNo: index },
      )
      index++;
    });
    settingPlatform.dropdownItm = platformList;
    this.filterDropDown.push(settingPlatform);

    //Channel dropdown setup
    let channels =  await this.channelService.getOdataChannelStore(null, this.storeId, this.formDetail.formCode);
    let settingChannel = {
      name: 'channelId', text: 'Select a channel', sortNo: 2, dropdownItm: [
    ]
    };
    let channelList = [];

    index = 1
    channelList.push(
      { name: '0', text: 'All Channels', sortNo: index },
    );
    index++;

    channels.value.forEach(channel => {
      channelList.push(
        { name: channel.channelId, text: channel.channelTag, sortNo: index },
      )
      index++;
    });
    settingChannel.dropdownItm = channelList;
    this.filterDropDown.push(settingChannel);

    this.filterDropDown.push(
      { name: 'save', icon: 'assets/base/icons/save.svg', text: 'toolbar.save', btnType: ButtonType.submit, checkPermission: [AttributeCode.EDIT], sortNo: 1, buttonActive: true, position: ActionToolbarPosition.RIGHT },
    );
  }
    // Set setting
    this.setting = {
      parentKey: 'parentCode',
      groupKey: 'segmentCode',
      groupCaption: 'platformCode',
      groupDesc: 'segmentDesc',
      titleCaption: 'setCode',
      titleDesc: 'setDesc',
      valueTypeKey: 'valueType',
      inputTypeKey: 'inputType',
      inputKey: 'setCode',
      inputVal: 'setValue',
      dropdownData: 'dataSource',
      childKey: 'children',
    }
    this.actionToolbarSetting = {
      actionToolBarItems: this.filterDropDown,
      position: ActionToolbarPosition.LEFT
    }

    // Set menu data
    this.menuSetting = {
      level: 1,
      childKey: 'children',
      descCaption: 'segmentDesc',
      desc: 'segmentDesc'
    }


  }


  async onSubmit(data: any) {
    if (Object.keys(data).length > 0) {
      let platformSets = Object.entries(data)

      // Call add / update api per each plarform setting
      platformSets.forEach(async platformSet => {
        let platformSetting = this.listOfChildren.filter(child => child.setCode === platformSet[0])[0]
        if (platformSetting.valueType == 'L') {
          platformSet[1] = platformSet[1]? 1 : 0;
        }
        let reqBody: BackendChannelPlatformSet = {
          channelId:  this.filters['channelId']?? 0,
          storeId: this.storeId,
          platformCode: platformSetting.platformCode,
          setCode: platformSet[0],
          setValue: platformSet[1]+'',
          groupCode: platformSetting.groupCode,
          valueType: platformSetting.valueType
        }

        let result = await this.storeService.addUpdateStoreSettings(reqBody, this.formDetail.formCode);

      });
      let msg: PopupMessage = {
        formCode: FormCode.storesetting,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.updated_store_settings',
        desc: 'msg.updated_store_settings_desc',
        actionBtnText: 'general.okay',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
    }

  }

  async onActionToolbar(value: ActionToolBarResp) {

    if (this.filterTags.indexOf(value.name) > -1 )
    {
      this.currentFilter = value.name;
    }
    else if (value.name != 'save') {
      this.filters[this.currentFilter] = value.name

      this.actionToolbarSetting = this.actionToolbarService.setText(value.data?.actionToolbarSetting, this.currentFilter, value?.toolbar.text);

      if (this.filters['platformCode'] && this.filters['channelId']) {
        await this.getStoreSetting();
       }
    }
  }

  async getStoreSetting() {

    let reqBody : GetSettingsRequest = {
      storeId: +this.storeId,
      setType: 1,
      platformCode : this.filters['platformCode']?? "",
      channelId :  this.filters['channelId']?? 0
    };

    let result =  await this.storeService.getStoreSettingData(reqBody, this.formDetail.formCode);
    this.sysOptionsData = result;

    let parents = this.sysOptionsData;
    this.listOfChildren = [];
    parents.forEach(parent => {
    parent.children.forEach(child => {
        child.children.forEach(grandChild => {
          this.listOfChildren.push(grandChild)
        });
      });
    });
  }
}

