/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TaxMethod = 'NetAmt' | 'GrossAmt' | 'AddTaxAmt' | 'NetAmtByUnit' | 'GrossAmtByUnit' | 'NetAmtExc' | 'GrossAmtExc' | 'FixedAmount' | 'MultipleByKM' | 'External';

export const TaxMethod = {
    NetAmt: 'NetAmt' as TaxMethod,
    GrossAmt: 'GrossAmt' as TaxMethod,
    AddTaxAmt: 'AddTaxAmt' as TaxMethod,
    NetAmtByUnit: 'NetAmtByUnit' as TaxMethod,
    GrossAmtByUnit: 'GrossAmtByUnit' as TaxMethod,
    NetAmtExc: 'NetAmtExc' as TaxMethod,
    GrossAmtExc: 'GrossAmtExc' as TaxMethod,
    FixedAmount: 'FixedAmount' as TaxMethod,
    MultipleByKM: 'MultipleByKM' as TaxMethod,
    External: 'External' as TaxMethod
};