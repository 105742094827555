/**
 * CXM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PaymentProviderType = 'IPay88' | 'GKash' | 'RM' | 'MPGS' | 'POS' | 'RP';

export const PaymentProviderType = {
    IPay88: 'IPay88' as PaymentProviderType,
    GKash: 'GKash' as PaymentProviderType,
    RM: 'RM' as PaymentProviderType,
    MPGS: 'MPGS' as PaymentProviderType,
    POS: 'POS' as PaymentProviderType,
    RP: 'RP' as PaymentProviderType
};