import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchingService {
  private searchTextSubject = new BehaviorSubject<string>('');
  searchText$ = this.searchTextSubject.asObservable();

  private dashboardResultsSubject = new BehaviorSubject<string[]>([]);
  dashboardResults$ = this.dashboardResultsSubject.asObservable();

  private posResultsSubject = new BehaviorSubject<string[]>([]);
  posResults$ = this.posResultsSubject.asObservable();

  private resetSubject = new BehaviorSubject<boolean>(false);
  reset$ = this.resetSubject.asObservable();

  private dashboardItems: string[] = [
    'POS Analytics +',
    'RMS Analytics',
    'Odaring Analytics',
    'Zeoniq Analytics',
  ];
  private posItems: string[] = [
    'POS Menu',
    'POS Terminal',
    'POS Short Key',
    'RMS Menu',
    'RMS Terminal',
    'RMS Short Key',
  ];

  triggerReset() {
    this.resetSubject.next(true);
  }

  setSearchText(text: string) {
    this.searchTextSubject.next(text);
    this.performSearch(text);
  }

  private performSearch(text: string) {
    if (text.trim()) {
      const searchTerm = text.toLowerCase();

      const dashboardResults = this.dashboardItems.filter((item) =>
        item.toLowerCase().includes(searchTerm)
      );
      const posResults = this.posItems.filter((item) =>
        item.toLowerCase().includes(searchTerm)
      );

      this.dashboardResultsSubject.next(dashboardResults);
      this.posResultsSubject.next(posResults);
    } else {
      this.dashboardResultsSubject.next([]);
      this.posResultsSubject.next([]);
    }
  }
}
