import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateISO'
})
export class DateISOPipe implements PipeTransform {

  transform(dateString: string): string {
    const [day, month, year] = dateString.split('/');

    const date = new Date(`${year}-${month}-${day}`);

    const isoDateString = date.toISOString();

    return isoDateString;
  }
}
