import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [CommonModule, FormComponent],
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {
  formSettingMix: FormSetting = {
    formCode: FormCode.storeentry,
    content: [
      {
        autoGenerateInput: {
          name: 'merchantId',
          label: 'Label A',
          required: true,
          docgrp: PageRoute.STORE,
          doccde: FormCode.storeentry,
          manual: true,
          length: '3',
          styling: {
            width: '100%'
          }
        },
      },
      {
        inputText: {
          name: 'locCode',
          label: 'Label B',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          maxLength: 25,
          editable: false
        },
      },
      {
        inputText: {
          name: 'locCode',
          label: 'Label C',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          maxLength: 25,
          editable: false
        },
      },
      {
        inputText: {
          name: 'storeTag',
          label: 'Label D',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          maxLength: 50,
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label E',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label F',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label G',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label H',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label I',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label J',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label K',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 3,
        columnSeq: 2
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label L',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 3,
        columnSeq: 1
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label M',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 2,
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label N',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 5,
        columnSeq: 1
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label O',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 5,
        columnSeq: 2
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label P',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 7,
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label Q',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 9,
        columnSeq: 2
      },
      {
        rowSeq: 9,
        columnSeq: 1,
        columnEmpty: true
      },
      {
        inputText: {
          name: 'locDesc',
          label: 'Label R',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
        },
        rowSeq: 12,
        columnSeq: 1
      },
      {
        rowSeq: 12,
        columnSeq: 2,
        columnEmpty: true
      },
    ]
  }

  formSetting2: FormSetting = {
    formCode: FormCode.storeentry,
    content: [
      {
        autoGenerateInput: {
          name: 'merchantId',
          label: 'Label A',
          required: true,
          docgrp: PageRoute.STORE,
          doccde: FormCode.storeentry,
          manual: true,
          length: '3',
          styling: {
            width: '100%'
          }
        },
        rowSeq: 1,
        columnSeq: 1
      },
      {
        inputText: {
          name: 'locCode',
          label: 'Label B',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          maxLength: 25,
          editable: false
        },
        rowSeq: 1,
        columnSeq: 2
      },
      {
        inputText: {
          name: 'locCode',
          label: 'Label C',
          type: InputTypes.text,
          required: true,
          styling: {
            width: '100%'
          },
          maxLength: 25,
          editable: false
        },
        rowSeq: 2,
        columnSeq: 1
      },
      {
        rowSeq: 2,
        columnSeq: 2
      },
    ]
  }

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm
  }
}
