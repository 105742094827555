/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IPAddress { 
    readonly addressFamily?: IPAddress.AddressFamilyEnum;
    scopeId?: number;
    readonly isIPv6Multicast?: boolean;
    readonly isIPv6LinkLocal?: boolean;
    readonly isIPv6SiteLocal?: boolean;
    readonly isIPv6Teredo?: boolean;
    readonly isIPv6UniqueLocal?: boolean;
    readonly isIPv4MappedToIPv6?: boolean;
    address?: number;
}
export namespace IPAddress {
    export type AddressFamilyEnum = 'Unspecified' | 'Unix' | 'InterNetwork' | 'ImpLink' | 'Pup' | 'Chaos' | 'Ipx' | 'Iso' | 'Ecma' | 'DataKit' | 'Ccitt' | 'Sna' | 'DecNet' | 'DataLink' | 'Lat' | 'HyperChannel' | 'AppleTalk' | 'NetBios' | 'VoiceView' | 'FireFox' | 'Banyan' | 'Atm' | 'InterNetworkV6' | 'Cluster' | 'Ieee12844' | 'Irda' | 'NetworkDesigners' | 'Max' | 'Packet' | 'ControllerAreaNetwork' | 'Unknown';
    export const AddressFamilyEnum = {
        Unspecified: 'Unspecified' as AddressFamilyEnum,
        Unix: 'Unix' as AddressFamilyEnum,
        InterNetwork: 'InterNetwork' as AddressFamilyEnum,
        ImpLink: 'ImpLink' as AddressFamilyEnum,
        Pup: 'Pup' as AddressFamilyEnum,
        Chaos: 'Chaos' as AddressFamilyEnum,
        Ipx: 'Ipx' as AddressFamilyEnum,
        Iso: 'Iso' as AddressFamilyEnum,
        Ecma: 'Ecma' as AddressFamilyEnum,
        DataKit: 'DataKit' as AddressFamilyEnum,
        Ccitt: 'Ccitt' as AddressFamilyEnum,
        Sna: 'Sna' as AddressFamilyEnum,
        DecNet: 'DecNet' as AddressFamilyEnum,
        DataLink: 'DataLink' as AddressFamilyEnum,
        Lat: 'Lat' as AddressFamilyEnum,
        HyperChannel: 'HyperChannel' as AddressFamilyEnum,
        AppleTalk: 'AppleTalk' as AddressFamilyEnum,
        NetBios: 'NetBios' as AddressFamilyEnum,
        VoiceView: 'VoiceView' as AddressFamilyEnum,
        FireFox: 'FireFox' as AddressFamilyEnum,
        Banyan: 'Banyan' as AddressFamilyEnum,
        Atm: 'Atm' as AddressFamilyEnum,
        InterNetworkV6: 'InterNetworkV6' as AddressFamilyEnum,
        Cluster: 'Cluster' as AddressFamilyEnum,
        Ieee12844: 'Ieee12844' as AddressFamilyEnum,
        Irda: 'Irda' as AddressFamilyEnum,
        NetworkDesigners: 'NetworkDesigners' as AddressFamilyEnum,
        Max: 'Max' as AddressFamilyEnum,
        Packet: 'Packet' as AddressFamilyEnum,
        ControllerAreaNetwork: 'ControllerAreaNetwork' as AddressFamilyEnum,
        Unknown: 'Unknown' as AddressFamilyEnum
    };
}