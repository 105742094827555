import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AuthLoginGuard } from './core/auth-login.guard';
import { ReportViewerComponent } from './shared/component/report-viewer/report-viewer.component';
import { ReportViewerGuard } from './core/guards/repor-viewer.guard';


const restrictedRoutes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'report-viewer', loadChildren: () => import('./project-demo/report/report.routes').then(r => r.REPORT_ROUTES)},
  { path: '**', redirectTo: '' },
];

const routes: Routes = [
  { path: '', redirectTo: 'subscription', pathMatch: 'full' },
  { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthLoginGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthLoginGuard] },
  { path: 'merchant', loadChildren: () => import('./cxm-backend-web/merchant/merchant.routes').then(r => r.MERCHANT_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'store', loadChildren: () => import('./cxm-backend-web/store/store.routes').then(r => r.STORE_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'statement', loadChildren: () => import('./cxm-backend-web/statement/statement.routes').then(r => r.STATEMENT_ROUTES) , canActivate: [AuthLoginGuard] },
  { path: 'currency', loadChildren: () => import('./cxm-backend-web/currency/currency.routes').then(r => r.CURRENCY_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'invoice', loadChildren: () => import('./cxm-backend-web/invoice/invoice.routes').then(r => r.INVOICE_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'channel', loadChildren: () => import('./cxm-backend-web/channel/channel.routes').then(r => r.CHANNEL_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'dealer', loadChildren: () => import('./cxm-backend-web/dealer/dealer.routes').then(r => r.DEALER_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'framework', loadChildren: () => import('./project-demo/framework/framework.routes').then(r => r.FRAMEWORK_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'report-viewer', component: ReportViewerComponent, canActivate: [AuthLoginGuard] },
  { path: 'order-management', loadChildren: () => import('./cxm-backend-web/order-management/order-management.routes').then(r => r.ORDER_MANAGEMENT_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'tax', loadChildren: () => import('./cxm-backend-web/tax/tax.routes').then(r => r.TAX_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'report/:formcode', loadChildren: () => import('./project-demo/report/report.routes').then(r => r.REPORT_ROUTES), canActivate: [AuthLoginGuard] },
  { path: 'report-viewer', loadChildren: () => import('./project-demo/report/report.routes').then(r => r.REPORT_ROUTES), canActivate: [ReportViewerGuard] },
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
