import { Injectable } from '@angular/core';
import { cronExpression } from '../models/cronExpression.model';
import { CronPeriodType } from '../enums/cronPeriodType';
import { PeriodType } from '../enums/periodType';
import moment from 'moment';

const dailyExp = /\d+ \d+ \d+ \* \* [\?\*]/;
const weeklyExp = /\d+ \d+ \d+ [\?\*] \* [1-7]/;
const monthlyExp = /\d+ \d+ \d+ (\d+|L|LW|1W) \* [\?\*]/;
const yearlyExp  = /\d+ \d+ \d+ (\d+|L|LW|1W) \d+ [\?\*]/;

@Injectable({
  providedIn: 'root'
})
export class CronService {
  cronVal: cronExpression;

  constructor() { }

  get weekDayDefaultChar() {
    return '?';
  }

  get monthDayDefaultChar() {
    return '?';
  }

  computeCron(cronType: CronPeriodType, cronVal: cronExpression): string {
    this.cronVal = cronVal;

    let cron: string;
    switch(cronType) {
      case CronPeriodType.daily:
        cron = this.computeDailyCron();
        break;
      case CronPeriodType.weekly:
        cron = this.computeWeeklyCron();
        break;
      case CronPeriodType.custom:
        cron = this.computeCustomCron();
        break;
      default:
        throw Error('Unknown cron type ' + cronType);
    }
    return cron;
  }

  getCronType(cron: string) {
    let cronType: any;

    if(cron) {
      let cronExp = cron.split(' ');
      let min = cronExp[1] && parseInt(cronExp[1])? cronExp[1]: '00';
      let hr = cronExp[2] && parseInt(cronExp[2])? cronExp[2]: '00'; 
      let time = hr + ':' + min;
      let months = moment.months();
  
      if(cron.match(dailyExp)) {
        cronType = {cronType: CronPeriodType.daily, time: time};
      } else if(cron.match(weeklyExp)) {
        cronType = {cronType: CronPeriodType.weekly, time: time, week: cronExp[5]};
      } else if(cron.match(monthlyExp)) {
        cronType = {cronType: CronPeriodType.custom, periodType: PeriodType.month, time: time, day: parseInt(cronExp[3])};
      } else if(cron.match(yearlyExp)) {
        cronType = {cronType: CronPeriodType.custom, periodType: PeriodType.year, time: time, day: +parseInt(cronExp[3])? parseInt(cronExp[3]): 0, month: months[parseInt(cronExp[4]) - 1]};
      }
    }
    
    return cronType;
  }

  private computeDailyCron(): string {
    const cron = this.cronVal;
    return `0 ${cron.min} ${cron.hr} * * ${this.weekDayDefaultChar}`.trim();
  }

  private computeWeeklyCron(): string {
    const cron = this.cronVal;
    return `0 ${cron.min} ${cron.hr} ${this.monthDayDefaultChar} * ${cron.week}`.trim();
  }

  private computeCustomCron() {
    if(this.cronVal.month) {
      return this.computeYearlyCron();
    }
    return this.computeMonthlyCron();
  }

  private computeMonthlyCron(): string {
    const cron = this.cronVal;
    return `0 ${cron.min} ${cron.hr} ${cron.day} * ${this.weekDayDefaultChar}`.trim();
  }

  private computeYearlyCron(): string {
    const cron = this.cronVal;
    return `0 ${cron.min} ${cron.hr} ${cron.day} ${cron.month} ${this.weekDayDefaultChar}`.trim();
  }
}
