import { Component, ViewChild } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { BodyScrollEndEvent, IStatusPanelParams } from 'ag-grid-community';
import { MessageService } from 'primeng/api';
import { FormCode } from 'src/app/shared/enums/formCode';
import { AgGridService } from 'src/app/shared/services/ag-grid.service';
import { PageService } from 'src/app/shared/services/page.service';
import { TranslateModule } from '@ngx-translate/core';
import { Paginator, PaginatorModule } from 'primeng/paginator';
import { NgIf, NgFor } from '@angular/common';
import { OverlayModule } from 'primeng/overlay';
import { RowModelType } from 'src/app/shared/enums/rowModelType';

@Component({
    selector: 'app-paginator-renderer',
    templateUrl: './paginator-renderer.component.html',
    styleUrls: ['./paginator-renderer.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, PaginatorModule, TranslateModule, OverlayModule]
})
export class PaginatorRendererComponent implements IStatusPanelAngularComp {
  @ViewChild('paginator', {static: false}) paginator: Paginator;

  params!: IStatusPanelParams & { page: FormCode, setting: any };
  componentParent: any;
  defaultPageSizes: Array<number> = [100, 500, 1000, 5000, 10000, 50000, 100000];
  pageSizes: Array<number> = [];
  pageLinkSize: number = 5;
  totalRows: number;
  displayRow: number;
  first: number;
  currPageSize: number;

  openDropdown: boolean;
  pageChanged: boolean = false;
  bodyScroll: boolean = false;
  showPageSize: boolean;

  rowModelType = RowModelType;

  constructor(private agGridService: AgGridService,
    private messageService: MessageService,
    private pageService: PageService) {}

  agInit(params: IStatusPanelParams & { page: FormCode, setting: any }): void {
    this.params = params;
    this.initValue();
    this.params.api.addEventListener('gridReady', this.onGridReady.bind(this));
    this.params.api.addEventListener('paginationChanged', this.onPaginationChange.bind(this));
    this.params.api.addEventListener('bodyScroll', this.onBodyScroll.bind(this));
    this.componentParent = this.params?.context?.componentParent;
  }

  getTotalRowCount() {
    return this.params?.api?.getModel()?.getRowCount() ? this.params.api.getModel().getRowCount(): 0;
  }

  getPageSize() {
    return this.params?.api?.paginationGetPageSize() ? this.params.api.paginationGetPageSize(): 0;
  }

  initValue() {
    this.totalRows = this.getTotalRowCount();

    if(this.params?.setting?.pageSize && this.params?.setting?.pageSize.length > 0) {
      this.pageSizes = this.params.setting.pageSize.filter((pageSize: number) => pageSize < this.totalRows);
    } else {
      this.pageSizes = this.defaultPageSizes.filter((pageSize: number) => pageSize < this.totalRows);
    }

    this.showPageSize = this.params?.setting?.showPageSize === false? false: true;

    if(this.params?.setting?.rowModelType === RowModelType.infinite || this.params?.setting?.rowModelType === RowModelType.clientSide) {
      this.displayRow = this.params?.setting?.pageSize && this.params.setting.pageSize.length > 0? this.params.setting.pageSize[0]: 100;
    }
  }

  onPageChange(event: any) {
    if(!this.bodyScroll) {
      let selectedNodes = this.params.api.getSelectedNodes();
      if(selectedNodes && selectedNodes.length > 0) {
        this.agGridService.unsetEditMode(this.params.api, selectedNodes[0], this.params.page);
      }

      if(this.params?.setting?.rowModelType && this.params.setting.rowModelType === RowModelType.infinite) {
        let jumpTo = event.page * this.displayRow;

        if ((this.params.api.getInfiniteRowCount() || 0) < jumpTo + 1) {
          this.params.api.setRowCount(jumpTo + 1, false);
        }

        this.pageChanged = true;
        this.params.api.ensureIndexVisible(jumpTo, "top");
      } else {
        this.params.api.paginationGoToPage(event.page);
      }

      if(event) {
        this.pageService.editing$.next({ page: this.params.page, isEditing: false });
        this.messageService.clear();
      }

      this.openDropdown = false;
    } else {
      this.bodyScroll = false;
    }
  }

  onPageSizeChange(event: any, showAll?: boolean) {
    if(event?.target?.innerText) {
      this.displayRow = !showAll? event.target.innerText: this.totalRows;
      this.params.api.setGridOption('paginationPageSize', this.displayRow);

      if(this.componentParent?.pageSizeChange) {
        this.componentParent.pageSizeChange(this.displayRow);
      }
    }
    this.openDropdown = false;
  }

  onGridReady() {
    this.initValue();

    if(this.params?.setting?.rowModelType !== RowModelType.infinite) {
      this.displayRow = this.getPageSize() >= this.totalRows? this.getPageSize(): this.params?.setting?.pageSize && this.params.setting.pageSize.length > 0? this.params.setting.pageSize[0]: 0;
    }
  }

  onPaginationChange() {
    this.initValue();

    if(this.params?.setting?.rowModelType !== RowModelType.infinite) {
      this.displayRow = this.getPageSize() !== this.totalRows? this.getPageSize(): this.totalRows;
    }
  }

  onClickDropdown() {
    this.openDropdown = !this.openDropdown;
  }

  onBodyScroll(event: any) {
    if(!this.pageChanged && this.params?.setting?.rowModelType && this.params.setting.rowModelType === RowModelType.infinite && this.displayRow) {
      let page: number = 0;
      let lastPage: boolean = (this.totalRows * event.api.rowModel.rowHeight) - ((this.displayRow - 1) * event.api.rowModel.rowHeight) === Math.floor(event.top);
      page = Math.ceil(event.top / (event.api.rowModel.rowHeight * this.displayRow)) - 1;

      if(page >= 0 && !lastPage) {
        this.bodyScroll = true;
        this.paginator.changePage(page);
      } else if(page >= 0 && lastPage) {
        this.bodyScroll = true;
        this.paginator.changePage(page + 1);
      }

    } else {
      this.pageChanged = false;
    }
  }

  onRefresh() {
    if(this.params?.setting?.rowModelType && this.params.setting.rowModelType === RowModelType.infinite) {
      this.params.api.purgeInfiniteCache();
    } else if (this.params?.setting?.rowModelType && this.params.setting.rowModelType === RowModelType.serverSide) {
      this.params.api.refreshServerSide();
    } else if (this.params?.setting?.rowModelType && this.params.setting.rowModelType === RowModelType.clientSide) {
      this.params.api.refreshClientSideRowModel();
    }
  }

  destroy() {
    this.params.api.removeEventListener('gridReady', this.onGridReady.bind(this));
    this.params.api.removeEventListener('paginationChanged', this.onPaginationChange.bind(this));
    this.params.api.removeEventListener('bodyScroll', this.onBodyScroll.bind(this));
  }
}

