import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListingComponent } from 'src/app/shared/component/listing/listing.component';
import { PageService } from 'src/app/shared/services/page.service';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { StoreService } from '../../store.service';
import { Subscription } from 'rxjs';
import { ButtonRendererTypeEnum } from 'src/app/shared/enums/buttonRendererType';
import { FormCode } from 'src/app/shared/enums/formCode';
import { Page } from 'src/app/shared/models/page.model';
import { PageRoute } from 'src/app/shared/enums/pageRoute';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { listingCheckbox } from 'src/app/shared/component/listing/data/listing-checkbox.data';
import { listingRouterLink } from 'src/app/shared/component/listing/data/listing-router-link.data';
import { AttributeCode } from 'src/app/shared/enums/attributeCode';
import { StoreOhEntryComponent } from '../store-oh-entry/store-oh-entry.component';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { PopupForm } from 'src/app/shared/models/popupForm.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ListingSetting } from 'src/app/shared/component/listing/model/ListingSetting.model';
import { RowModelType } from 'src/app/shared/enums/rowModelType';
import { ColDef, IRowNode } from 'ag-grid-community';
import { ActionToolbarPosition } from 'src/app/shared/enums/actionToolbarPosition';
import { toolbarListing } from 'src/app/shared/component/action-tool-bar/data/toolbar-listing.data';
import { Mode } from 'src/app/shared/enums/mode';
import { FormService } from 'src/app/shared/component/form/form.service';

@Component({
  selector: 'app-store-oh-listing',
  standalone: true,
  imports: [CommonModule, ListingComponent],
  templateUrl: './store-oh-listing.component.html',
  styleUrls: ['./store-oh-listing.component.scss'],
})
export class StoreOhListingComponent {
  listingSetting: ListingSetting;
  page: Page;
  rowData: any;
  storeId: number;
  selectedRowData: any;

  formCode: any = FormCode;
  pageRoute: any = PageRoute;

  refresh$: Subscription;
  popupMsg$: Subscription;
  actionBtn$: Subscription;
  formDetail: any = null;

  // Column data
  colDef: ColDef[] = [
    listingCheckbox,
    {
      ...listingRouterLink,
      field: 'seqNo',
      headerName: 'store_oh.seq_no',
      cellRendererParams: {
        inRouterLink: {
          route: '/store/storeoh/entry',
          params: ['storeId', 'seqNo'],
          queryParams: { mode: Mode.edit },
          childPage: true
        },
      },
    },
    {
      field: 'weekDay',
      headerName: 'store_oh.week_day',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'weekDayDesc',
      headerName: 'store_oh.week_day_desc',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'startDate',
      headerName: 'store_oh.start_date',
      filter: 'date',
    },
    {
      field: 'endDate',
      headerName: 'store_oh.end_date',
      filter: 'date',
    },
    {
      field: 'startTime1',
      headerName: 'store_oh.start_time',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'endTime1',
      headerName: 'store_oh.end_time',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'closedFlag',
      headerName: 'store_oh.closed',
      filter: true,
    },
    {
      field: 'closedMsg',
      headerName: 'popupMsg.closed_msg',
      filter: 'agTextColumnFilter',
    },
  ];

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarListing,
    position: ActionToolbarPosition.RIGHT,
  };

  /* actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: [
      {
        name: 'filter',
        text: 'toolbar.filter',
        input: true,
        clickable: false,
        sortNo: 1,
        position: ActionToolbarPosition.LEFT,
      },
      {
        name: 'new',
        icon: 'assets/base/icons/plus.svg',
        text: 'toolbar.new_store_oh',
        checkPermission: [AttributeCode.NEW],
        collapseTo: 'others',
        sortNo: 2,
        position: ActionToolbarPosition.RIGHT,
      },
      {
        name: 'delete',
        icon: 'assets/base/icons/trash.svg',
        text: 'toolbar.delete',
        checkPermission: [AttributeCode.DELETE],
        collapseTo: 'others',
        sortNo: 3,
        position: ActionToolbarPosition.RIGHT,
      },
    ],
  }; */

  constructor(
    public translate: TranslateService,
    private popupFormService: PopupFormService,
    private pageService: PageService,
    private storeService: StoreService,
    private formService: FormService,
  ) {}

  ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);
    // Get route parameter
    let params = this.pageService.getParameterDecode(this.page);
    this.storeId = params['storeId'];

    this.getStoreOH();

    // Listen to refresh event
    this.refresh$ = this.pageService.refreshListing$.subscribe(
      async (formCode: any) => {
        if (formCode.includes(this.page.form.formCode)) {
          await this.getStoreOH();
        }
      }
    );

    // Listen to popup message event
    this.popupMsg$ = this.pageService.actionPopupMsg$.subscribe(async (data: any) => {
      if (data.formCode === this.page.form.formCode && data.id === 'success') {
        await this.getStoreOH();
      }
    });

    // Listen to Action Button event
    this.actionBtn$ = this.pageService.actionBtnListing$.subscribe(
      (data: any) => {
        if (
          data.param.page.formCode === this.page.form.formCode &&
          data.action === ButtonRendererTypeEnum.save
        ) {
          // save action
        }
      }
    );

    this.listingSetting = {
      rowModelType: RowModelType.clientSide,
    };
  }

  async getStoreOH() {
    this.rowData = (await this.storeService.getOdataStoreOH(this.storeId, null, this.formDetail.formCode))['value'];
  }

  deleteStoreOH() {
    this.rowData = this.storeService.getOdataStoreOHProvider(this.storeId);
  }

  async onToolbarAction(value: ActionToolBarResp) {
    if (value.name === 'new') {
      this.pageService.navigateByUrl(PageRoute.STORE_OH_ENTRY, {
        storeId: this.storeId,
      }, null, true);
    }

    if (
      value.name === 'delete' &&
      this.selectedRowData &&
      this.selectedRowData['seqNo']
    ) {
      let result = null;
      result = await this.storeService.deleteStoreOH(
        this.storeId,
        this.selectedRowData['seqNo'],
        this.formDetail.formCode
      );

      if (result instanceof HttpErrorResponse === false) {
        this.getStoreOH();
      }
    }
  }

  onPageSizeChange(pageSize: number) {}

  onRowSelect(nodes: IRowNode[]) {
    this.selectedRowData = nodes[0]?.data ? nodes[0].data : null;
  }

  ngOnDestroy(): void {
    // Destroy event listener when component close
    this.refresh$?.unsubscribe();
    this.popupMsg$?.unsubscribe();
    this.actionBtn$?.unsubscribe();
  }
}
