import { Component, Input, Output, EventEmitter, OnInit, ViewContainerRef, ViewChild, AfterViewInit, ComponentRef, ChangeDetectorRef, OnChanges, SimpleChanges, reflectComponentType } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { LookDropInputComponent } from '../look-drop-input/look-drop-input.component';
import { IRowNode } from 'ag-grid-community';
@Component({
  selector: 'app-lookdrop',
  standalone: true,
  imports: [CommonModule, LookDropInputComponent],
  templateUrl: './look-drop.component.html',
  styleUrls: ['./look-drop.component.scss']
})
export class LookDropComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @Input() lookDropInputSetting: any;
  @Input() formGroup: UntypedFormGroup;
  @Input() formData: any;
  @Input() defaultLookup: any;

  componentRef: ComponentRef<any>
  multiSelectData: any;

  constructor(public viewContainerRef: ViewContainerRef,
    private cd: ChangeDetectorRef) { }
  @Output() passData: EventEmitter<{ data?: any, value: string, description: string }> = new EventEmitter<{ data?: any, value: string, description: string }>();

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.lookDropInputSetting.componentName) {
      this.container.clear();
      this.componentRef = this.container.createComponent(this.lookDropInputSetting.componentName);
      this.componentRef.instance.lookDropInputSetting = this.lookDropInputSetting;
      this.componentRef.instance.defaultLookup = this.defaultLookup;
      this.componentRef.instance.formGroup = this.formGroup;
      this.componentRef.instance.formData = this.formData;
      if (this.componentRef.instance.passData) {
        this.componentRef.instance.passData.subscribe((data: { data: any, value: string, description: string }) => {
          this.passData.emit(data);
        });
      }
      this.setInput();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formData?.currentValue) {
      this.formData = changes.formData?.currentValue;
      if (this.componentRef) {
        this.componentRef.instance.formData = this.formData;
        this.setInput();
      }
    }
  }

  setInput() {
    if (this.lookDropInputSetting.componentName) {
      let componentData: any = reflectComponentType(this.lookDropInputSetting.componentName);
      let inputs: any = [];
      componentData.inputs.forEach((data: any) => inputs.push(data.propName));


      if (inputs.includes('lookDropInputSetting')) {
        this.componentRef.setInput('lookDropInputSetting', this.lookDropInputSetting);
      }

      if (inputs.includes('formGroup')) {
        this.componentRef.setInput('formGroup', this.formGroup);
      }

      if (inputs.includes('formData')) {
        this.componentRef.setInput('formData', this.formData);
      }
    }
  }

  onSelected(selectedNodes: IRowNode[]) {
    if (this.lookDropInputSetting?.multiSelect && this.lookDropInputSetting?.valueKey) {
      this.multiSelectData = selectedNodes.map((node: IRowNode) => { return { node: node, value: node.data[this.lookDropInputSetting.valueKey] } });
    }
  }
}
