<div class="header-dialog">
  <div class="header-panel">
    <span>{{ "general.job_status" | translate }}</span>
  </div>
  <div class="close-btn">
    <!-- <img src="assets/base/icons/info-circle.svg" /> -->
    <img src="assets/base/icons/remove.svg" (click)="closeDialog()" />
  </div>
</div>

<div class="job-status-content">
  <div style="display: flex;">
    <div class="search-filter-bar">
      <app-searchbar class="search-input" [searchList]="options" [searchTitle]="'documents...'"
        (searchResult)="paramSearch($event)">
      </app-searchbar>

      <!-- <div class="searchFilter">
        <img style="width: 20px;" src="assets/base/icons/filter.svg">
        <div style="width: 100%; display: flex;">
          <span style="width: 15%; margin: auto;">Filter by: </span>
          <div style="border-bottom: 1px solid #3D3D59; width: 100%; display: flex;">
            <input class="filterbar" type="text" (input)="onInput($event)" [(ngModel)]="inputValue">
            <img *ngIf="inputValue" style="width: 20px; cursor: pointer;" src="assets/base/icons/remove.svg"
              (click)="clearInput()">
          </div>
        </div>
      </div> -->
    </div>
  </div>


  <app-listing [colDef]="colDef" [rowData]="rowData" [setting]="listingSetting" [customColDef]="defaultColDef"
    (onGridApi)="onGridReady($event)" (onRowDoubleClicked)="onGridDoubleClicked($event)"></app-listing>
</div>
