import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { PageService } from '../../services/page.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { PopupAction } from '../../enums/popupAction';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, MatButtonModule, TranslateModule]
})
export class PopupMessageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PopupMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pageService: PageService) { }

  ngOnInit(): void {
  }

  close(isClickClose: boolean) {
    this.dialogRef.close(isClickClose ? PopupAction.closeBtn : undefined);
  }

  action() {
    this.dialogRef.close(PopupAction.actionBtn);
    this.pageService.actionPopupMsg$.next(this.data);
  }
}
