<div class="period-input-layout" [formGroup]="formGroup" *ngIf="!periodSetting.hidden">
    <mat-label class="mat-label" *ngIf="periodSetting.label">{{ periodSetting.label | translate }}<span class="required-symbol" *ngIf="periodSetting.required">*</span></mat-label>
    <div class="inline-wrapper">
        <!-- Type -->
        <mat-form-field class="flex-1 active" [style]="{ 'width': periodSetting.styling?.width }">
            <mat-select [formControlName]="periodSetting.name" [required]="periodSetting.required"
                (selectionChange)="typeSelectionChange($event)">
                <mat-option [value]="type.value" *ngFor="let type of periodSetting?.typeOption;">{{ type.name | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <p>{{ "general.on_the" | translate }}</p>

        <!-- Month -->
        <mat-form-field class="flex-1" style="margin-right: 8px" [style]="{ 'width': periodSetting.styling?.width }" *ngIf="!hideMonthInput">
            <mat-select [formControlName]="'month'" [required]="periodSetting.required"
                (selectionChange)="monthSelectionChange($event)">
                <mat-option [value]="month.value" *ngFor="let month of periodSetting?.monthOption;">{{ month.name | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Day -->
        <mat-form-field class="flex-1" [style]="{ 'width': periodSetting.styling?.width }">
            <mat-select [formControlName]="'day'" [required]="periodSetting.required"
                (selectionChange)="daySelectionChange($event)">
                <mat-option [value]="day" *ngFor="let day of periodSetting?.dayOption;">{{ day? (day | ordinal): ('general.last_day' | translate) }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
