import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import OdataProvider from 'ag-grid-odata';
import { OdataQueryOptions } from 'ag-grid-odata/build/types';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpMethod } from 'src/app/core/enums/HttpMethod';
import { CustomRequest } from 'src/app/core/models/local/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { BackendCurrency, CurrencyService as CurrencyServiceApi } from 'src/swagger/cxmbackend/openapi';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(
    private currencyServiceApi: CurrencyServiceApi,
    private customService: CustomService,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private utilsService: UtilsService
  ) { }

  async addCurrency(reqBody: BackendCurrency) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.currency.AddCurrency,
      hostPath: environment.hostPath,
      headers: null,
      body: reqBody,
      httpHeaderType: undefined
    }
    let hMac = this.customService.assignHMac(cusReq);
    return this.currencyServiceApi.apiCurrencyAddcurrencyPost(reqBody, environment.apiVersion, environment.subscription, hMac).toPromise();
  }

  async updateCurrency(reqBody: BackendCurrency) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.currency.UpdateCurrency,
      hostPath: environment.hostPath,
      headers: null,
      body: reqBody,
      httpHeaderType: undefined
    }
    let hMac = this.customService.assignHMac(cusReq);
    return this.currencyServiceApi.apiCurrencyUpdatecurrencyPut(reqBody, environment.apiVersion, environment.subscription, hMac).toPromise();
  }

  async getCurrency(filter?: string, top?: number) {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.currency.OdataCurrency,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
      queryParams: {
        $count: true,
        $filter: filter? filter: undefined,
        $top: top? top: undefined
      }
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(cusReq);
    return respInfo;
  }

  async getCurrencyDataSource() {
    const apiPath = environment.apis.currency.OdataCurrency;
    return new OdataProvider({
      callApi: (options) =>
        fetch(
          `${environment.hostPath}${apiPath}${options}`,
          {
            headers: {
              'SubscriptionID': environment.subscription,
              'Content-type': "application/json",
              'Accept': "application/json",
              'X-Signature': this.getSignature(apiPath, options),
              'Authorization': 'Bearer ' + this.oauthService.getAccessToken(),
              'Content-Language': this.translate.currentLang
            }
          }
        ).then((resp) => resp.json()),
      beforeRequest: (options: OdataQueryOptions) => {
        options['count'] = true;
      }
    });
  }

  getSignature(apiPath: string, options: any) {
    let optionsSpaceFormat = options.replace(/\s/g, "%20");
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: apiPath + optionsSpaceFormat,
      hostPath: environment.hostPath,
      headers: null,
      httpHeaderType: undefined,
    }

    return this.customService.assignHMac(cusReq);
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      return dd && dd['body'] ? dd['body'] : dd;
    });
  }
}
