export enum MenuItemId {
  LocCode = "LocCode",
  LocGrpCode = "LocGrpCode",
  LocCatgCode = "LocCatgCode",
  LocCatg2Code = "LocCatg2Code",
  LocCatg3Code = "LocCatg3Code",
  TableNo = "TableNo",
  POSId = "POSId",
  CashierId = "CashierId",
  AuthoriseId = "AuthoriseId",
  BillAuthoriseId = "BillAuthoriseId",
  BizDate = "BizDate",
  SalesDate = "SalesDate",
  Weekdaynum = "Weekdaynum",
  Week = "Week",
  BillNo = "BillNo",
  OrderLine = "OrderLine",
  LinkPOSId = "LinkPOSId",
  LinkOrderNo = "LinkOrderNo",
  CatgCode = "CatgCode",
  Catg2Code = "Catg2Code",
  Catg3Code = "Catg3Code",
  BrandGrpCode = "BrandGrpCode",
  BrandCode = "BrandCode",
  DeptCode = "DeptCode",
  ClassCode = "ClassCode",
  SeasonCode = "SeasonCode",
  ColorCode = "ColorCode",
  SizeGrpCode = "SizeGrpCode",
  SizeCode = "SizeCode",
  OrderTypeCode = "OrderTypeCode",
  PromotionCode = "PromotionCode",
  SalesMode = "SalesMode",
  SourceFlag = "SourceFlag",
  OrderPlatformCode = "OrderPlatformCode",
  OrderStatus = "OrderStatus",
  StatusFlag = "StatusFlag",
  VoidFlag = "VoidFlag",
  ExcpFlag = "ExcpFlag",
  IsComboHead = "IsComboHead",
  MasterItemCode = "MasterItemCode",
  ParentItemCode = "ParentItemCode",
  ItemParentCode = "ItemParentCode",
  ItemCode = "ItemCode",
  SerialNo = "SerialNo",
  LotNo = "LotNo",
  SeatNo = "SeatNo",
  BumpQty = "BumpQty",
  BumpSec = "BumpSec",
  AvgBumpSec = "AvgBumpSec",
  BumpOutMins = "BumpOutMins",
  BillRefNo = "BillRefNo",
  RefNo = "RefNo",
  LineDesc = "LineDesc",
  Remarks = "Remarks",
  BillRemarks = "BillRemarks",
  DiscountFlag = "DiscountFlag",
  CustomerCode = "CustomerCode",
  CustCatg1Code = "CustCatg1Code",
  CustCatg2Code = "CustCatg2Code",
  CustCatg3Code = "CustCatg3Code",
  MobileNo = "MobileNo",
  MemberType = "MemberType",
  MemberNo = "MemberNo",
  CustName = "CustName",
  PointAwarded = "PointAwarded",
  PointRedeemed = "PointRedeemed",
  SalesPersonId = "SalesPersonId",
  dSPTCode = "dSPTCode",
  TenderCode = "TenderCode",
  ProdGrpCode = "ProdGrpCode",
  SpecialFlag = "SpecialFlag",
  cAddPostal = "cAddPostal",
  SalesCatgCode = "SalesCatgCode",
  SalesBrandCode = "SalesBrandCode",
  SalesClassCode = "SalesClassCode"
}
