import { AttributeCode } from "src/app/shared/enums/attributeCode";
import { ActionToolBarItem } from "../model/ActionToolBarItem.model";
import { ButtonType } from "src/app/shared/enums/buttonType";
import { ActionToolbarPosition } from "src/app/shared/enums/actionToolbarPosition";

export const toolbarForm: ActionToolBarItem[] = [
    { id: 'btn-save', name: 'save', icon: 'assets/base/icons/save.svg', text: 'toolbar.save', btnType: ButtonType.submit, checkPermission: [AttributeCode.EDIT], sortNo: 1, buttonActive: true, position: ActionToolbarPosition.RIGHT },
    // { name: 'saveNext', icon: 'assets/base/icons/save.svg', text: 'toolbar.save_next', btnType: ButtonType.submit, checkPermission: [AttributeCode.EDIT], sortNo: 2, position: ActionToolbarPosition.RIGHT },
];

