import { OrderDetailListingComponent } from "src/app/cxm-backend-web/order-management/orderdetail-listing/orderdetail-listing.component";
import { PageRoute } from "../enums/pageRoute";
import { RevertCutoffFormComponent } from "src/app/cxm-backend-web/order-management/revertcutoff-form/revertcutoff-form.component";
import { FormCode } from "../enums/formCode";
import { UpdateOrderStatusComponent } from "src/app/cxm-backend-web/order-management/updateorderstatus-form/updateorderstatus-form.component";
import { ActivityLogListingComponent } from "src/app/cxm-backend-web/order-management/activitylog-listing/activitylog-listing.component";
import { MasterFormComponent } from "../component/master-form/master-form.component";
import { SysPendingJobListingComponent } from "src/app/cxm-backend-web/order-management/syspendingjob-listing/syspendingjob-listing.component";

export const orderManagementPathComponent = [{
  filePath: PageRoute.REVERTCUTOFFFORM,
  formCode: FormCode.revertcutoff,
  component: RevertCutoffFormComponent
}, {
  filePath: PageRoute.ORDERDETAIL_LISTING,
  component: OrderDetailListingComponent
},
{
  filePath: PageRoute.UPDATEORDERSTATUS_FORM,
  formCode: FormCode.updateorderstatus,
  component: UpdateOrderStatusComponent
},
{
  filePath: PageRoute.SYSPENDINGJOB_LISTING,
  formCode: FormCode.syspendingjoblisting,
  component: SysPendingJobListingComponent
},
{
  filePath: PageRoute.ACTIVITYLOG_LISTING,
  formCode: FormCode.activityloglisting,
  component: ActivityLogListingComponent
},
{
  filePath: PageRoute.REVERTCUTOFF_MASTER_FORM,
  component: MasterFormComponent
}, 
{
  filePath: PageRoute.ACTIVITYLOG_MASTER_LISTING,
  component: MasterFormComponent
},
{
  filePath: PageRoute.SYSPENDINGJOB_MASTER_LISTING,
  component: MasterFormComponent
},];
