import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _toggle = new BehaviorSubject<boolean>(false);
  public toggle$ = this._toggle.asObservable();

  increaseZIndex(): void {
      this._toggle.next(true);
  }

  revertZIndex(): void {
      this._toggle.next(false);
  }
}
