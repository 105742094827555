import { Component } from '@angular/core';
import { CardSetting } from '../shared/component/card/model/CardSetting';
import { FooterComponent } from '../layout/footer/footer.component';
import { CalenderActivityCardComponent } from '../shared/component/card/calender-activity-card/calender-activity-card.component';
import { QuickFormCardComponent } from '../shared/component/card/quick-form-card/quick-form-card.component';
import { ReportManagerCardComponent } from '../shared/component/card/report-manager-card/report-manager-card.component';
import { JobStatusCardComponent } from '../shared/component/card/job-status-card/job-status-card.component';
import { SubscriptionCardComponent } from '../shared/component/card/subscription-card/subscription-card.component';
import { MarketingBannerComponent } from '../shared/component/banner/marketing-banner/marketing-banner.component';
import { SearchComponent } from '../shared/component/search/search.component';
import { Subscription } from 'rxjs';
import { FavoritesComponent } from '../shared/component/card/favorites/favorites.component';
import { GuideMasterCardComponent } from '../shared/component/card/guide-master-card/guide-master-card.component';
import { DocumentSummaryCardComponent } from '../shared/component/card/document-summary-card/document-summary-card.component';
import {
  NgFor,
  NgSwitch,
  NgIf,
  CommonModule,
} from '@angular/common';
import { LoaderService } from '../shared/services/loader.service';
import { CardComponent } from '../shared/component/card/card.component';
import { CarouselComponent } from '../shared/component/carousel/carousel.component';
import { Slide } from '../shared/component/carousel/carousel.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    MarketingBannerComponent,
    SubscriptionCardComponent,
    JobStatusCardComponent,
    ReportManagerCardComponent,
    QuickFormCardComponent,
    CalenderActivityCardComponent,
    FooterComponent,
    SearchComponent,
    FavoritesComponent,
    DocumentSummaryCardComponent,
    GuideMasterCardComponent,
    NgIf,
    NgSwitch,
    NgFor,
    CommonModule,
    CardComponent,
    CarouselComponent
  ],
})
export class DashboardComponent {
  showPopupContent: boolean = false;
  private subs: Subscription;
  private activeSubscription: Subscription;
  isActive: boolean = false;

  draggedWidgetId: string;
  draggedParent: HTMLElement;

  /** Based on the screen size, switch from standard to one column per row */
  // cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
  //   map(({ matches }) => {
  //     if (matches) {
  //       return [
  //         { title: 'Card 1', cols: 1, rows: 1 },
  //         { title: 'Card 2', cols: 1, rows: 1 },
  //         { title: 'Card 3', cols: 1, rows: 1 },
  //         { title: 'Card 4', cols: 1, rows: 1 }
  //       ];
  //     }

  //     return [
  //       { title: 'Card 1', cols: 2, rows: 1 },
  //       { title: 'Card 2', cols: 1, rows: 1 },
  //       { title: 'Card 3', cols: 1, rows: 2 },
  //       { title: 'Card 4', cols: 1, rows: 1 }
  //     ];
  //   })
  // );

  breakpoint: number = 4;

  subscriptionSetting: CardSetting = {
    title: 'Information',
    layout: 'side',
    titleIcon: 'assets/base/icons/info-circle.svg',
    buttonIcon: 'assets/base/icons/info-circle.svg',
    show: true,
    buttonMore: true,
    contentList: [
      {
        title: 'Secret Recipe (Mont K)',
        id: 1,
        state: 'Expired on',
        datetime: '30/12/2022',
      },
      {
        title: 'Secret Recipe (Mont K)',
        id: 0,
        state: 'Expires on',
        datetime: '30/12/2022',
      },
      {
        title: 'Secret Recipe (Mont K)',
        id: 0,
        state: 'Suspended',
        datetime: '30/12/2022',
      },
      {
        title: 'Secret Recipe (Mont K)',
        id: 0,
        state: 'Expires on',
        datetime: '30/12/2022',
      },
      {
        title: 'Subscription Name',
        id: 0,
        state: 'Expires on',
        datetime: '30/12/2022',
      },
      {
        title: 'Subscription Name',
        id: 0,
        state: 'Expires on',
        datetime: '30/12/2022',
      },
      {
        title: 'Subscription Name',
        id: 0,
        state: 'Expires on',
        datetime: '30/12/2022',
      },
    ],
    styling: { title: 'font-size: 16px;', cardWidth: 'width: 218px;' },
  };

  slides: Slide[] = [
    {
      headline: "For Your Current Mood",
      src: "assets/base/carousel/zeoniq_banner_1.jpg"
    },
    {
      headline: "For Your Current Mood",
      src: "assets/base/carousel/zeoniq_banner_2.png"
    },
    {
      headline: "For Your Current Mood",
      src: "assets/base/carousel/zeoniq_banner_3.png"
    },
  ]

  constructor(
    private loaderService: LoaderService,
  ) {}

  ngOnInit() {
    this.loaderService.stopLoading();
  }

  onResize(event) {
    // this.breakpoint = (event.target.innerWidth <= 900) ? 1 : 6;
  }

  startDrag(ev: DragEvent) {
    (ev.target as HTMLElement).classList.add('dragging');
    this.removeTargetHoverClass();
    this.drag(ev);
  }

  endDrag(ev: DragEvent) {
    (ev.target as HTMLElement).classList.remove('dragging');
    this.removeTargetHoverClass();
  }

  removeTargetHoverClass() {
    const draggableElements = document.querySelectorAll('[draggable="true"]');
    draggableElements.forEach((el) => {
      el.classList.remove('target-hover');
    });
  }

  onDragEnter(ev: DragEvent) {
    const target = (ev.target as HTMLElement).closest('[draggable="true"]');
    if (target && target.id !== this.draggedWidgetId) {
      this.removeTargetHoverClass();
      target.classList.add('target-hover');
    }
  }

  onDragLeave(ev: DragEvent) {
    const target = (ev.target as HTMLElement).closest('[draggable="true"]');
    if (target) {
      target.classList.remove('target-hover');
    }
  }

  drop(ev: DragEvent) {
    ev.preventDefault();

    const targetWidget = (ev.target as HTMLElement).closest(
      '[draggable="true"]'
    ) as HTMLElement;
    const draggedWidget = document.getElementById(
      this.draggedWidgetId
    ) as HTMLElement;

    if (targetWidget && draggedWidget && targetWidget !== draggedWidget) {
      if (this.isBefore(draggedWidget, targetWidget)) {
        targetWidget.parentNode.insertBefore(draggedWidget, targetWidget);
      } else {
        if (targetWidget.nextSibling) {
          targetWidget.parentNode.insertBefore(
            draggedWidget,
            targetWidget.nextSibling
          );
        } else {
          targetWidget.parentNode.appendChild(draggedWidget);
        }
      }
    }
  }

  isBefore(elem1: HTMLElement, elem2: HTMLElement): boolean {
    if (elem2.parentNode === elem1.parentNode) {
      for (let cur = elem1.previousSibling; cur; cur = cur.previousSibling) {
        if (cur === elem2) {
          return true;
        }
      }
    }
    return false;
  }

  allowDrop(ev: DragEvent) {
    ev.preventDefault();
    const target = (ev.target as HTMLElement).closest('[draggable="true"]');
    if (target) {
      target.classList.add('target-hover');
    }
  }

  drag(ev: DragEvent) {
    const draggedElement = (ev.target as HTMLElement).closest('[draggable="true"]');

    ev.dataTransfer.setData('text/plain', 'This is data.');
    ev.dataTransfer.effectAllowed = 'move';

    if (draggedElement) {
        this.draggedWidgetId = draggedElement.id;
        this.draggedParent = draggedElement.parentElement;
    } else {
        console.error('Unexpected drag source:', ev.target);
    }
}


  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }
  }

  addNewWidget() {
    // Code to handle adding a new widget
  }
}
