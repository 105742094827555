import { basedEnvironmentProd } from "./base/environment.prod";

export const environment = {
  ...basedEnvironmentProd,
  projversion: 'CXMBUI.v1.0.10',
  // frontApiUrl: 'https://cxmapidev.azurewebsites.net',
  // hostPath: 'https://cxmbackendapi.azurewebsites.net',
  // identityUrl: 'https://cxmbackendaccdev.azurewebsites.net',
  // subscription: '00009',
  frontApiUrl: 'https://odafrontapi100.odaring.com',
  hostPath: 'https://odabackapi100.odaring.com',
  identityUrl: 'https://odabackendacc100.azurewebsites.net',
  subscription: '00001',
  apis:
  {
    ...basedEnvironmentProd.apis,
    invoice: {
      OdataInvoice: '/odata/invoice',
      CreateInvoice: '/api/invoice/createinvoice',
      UpdateInvoiceStatus: '/api/invoice/updateinvoicestatus',
      SendInvoiceEmail: '/api/invoice/sendinvoiceemail',
      GetInvoiceDetailHtml: '/api/invoice/getinvoicedetailhtml',
      DeleteInvoice: '/api/invoice/deleteinvoice',
      DeleteMultiInvoice: '/api/invoice/deletemultiinvoice'
    },
    channel: {
      GetAllChannel: '/api/channel/getallchannel',
      AddChannel: '/api/channel/addchannel',
      UpdateChannel: '/api/channel/updatechannel',
      OdataChannel: '/odata/channel'
    },
    channelAddr: {
      AddChannelAddress: '/api/channeladdress/addchanneladdress',
      UpdateChannelAddress: '/api/channeladdress/updatechanneladdress',
      AddDeleteStoreCatgAttr: '/api/channeladdress/deletechanneladdress',
      OdataChannelAddress: '/odata/channeladdress'
    },
    channelSection: {
      AddChannelSection: '/api/channelsection/addchannelsection',
      UpdateChannelSection: '/api/channelsection/updatechannelsection',
      DeleteChannelSection: '/api/channelsection/deletechannelsection',
      OdataChannelSection: '/odata/channelsection'
    },
    ctryState: {
      OdataCtryState: '/odata/ctryState'
    },
    country: {
      OdataCountry: '/odata/country'
    },
    bank: {
      OdataBank: '/odata/bank'
    },
    channelStore: {
      UpdateChannelStore: '/api/channelstore/updatechannelstore',
      OdataChannelStore: '/odata/channelstore',
      AddChannelStore: '/api/channelstore/addchannelstore',
      DeleteChannelStore: '/api/channelstore/deletechannelstore'
    },
    channelStoreTax: {
      UpdateChannelStoreTax: '/api/channelstoretax/updatechannelstoretax',
      OdataChannelStoreTax: '/odata/channelstoretax',
      AddChannelStoreTax: '/api/channelstoretax/addchannelstoretax',
      DeleteChannelStoreTax: '/api/channelstoretax/deletechannelstoretax'
    },
    taxconditionh:{
      OdataTaxConditionH: '/odata/taxconditionh'
    },
    tax: {
      tax:{
        OdataTax: '/odata/tax',
        AddTax: '/api/tax/addtax',
        UpdateTax: '/api/tax/updatetax',
        DeleteTax: '/api/tax/deletetax'
      },
      taxConditionD:{
        OdataTaxConditionD: '/odata/taxconditiond',
        AddTaxConditionD: '/api/taxconditiond/addtaxconditiond',
        UpdateTaxConditionD: '/api/taxconditiond/updatetaxconditiond',
        DeleteTaxConditionD: '/api/taxconditiond/deletetaxconditiond'
      },
      taxConditionF:{
        OdataTaxConditionF: '/odata/taxconditionf',
        AddTaxConditionF: '/api/taxconditionf/addtaxconditionf',
        UpdateTaxConditionF: '/api/taxconditionf/updatetaxconditionf',
        DeleteTaxConditionF: '/api/taxconditionf/deletetaxconditionf'
      },
      taxConditiohH:{
        OdataTaxConditionH: '/odata/taxconditionh',
        AddTaxConditionH: '/api/taxconditionh/addtaxconditionh',
        UpdateTaxConditionH: '/api/taxconditionh/updatetaxconditionh',
        DeleteTaxConditionH: '/api/taxconditionh/deletetaxconditionh'
      },
      taxGroup:{
        OdataTaxGroup: '/odata/taxgroup',
        AddTaxGroup: '/api/taxgroup/addtaxgroup',
        UpdateTaxGroup: '/api/taxgroup/updatetaxgroup',
        DeleteTaxGroup: '/api/taxgroup/deletetaxgroup'
      },
    },
    orderManagement:{
      UnlinkWeblinkToken: '/api/orderdetail/unlinkweblinktoken',
      UpdateOrderStatus: '/api/orderdetail/updateorderstatus',
      OdataOrderDetail: '/odata/orderDetail'
    },
    activityLog:{
      OdataActivityLog: '/odata/activitylog'
    },
    statement:{
      UpdateStatementTransactionDate: '/api/statement/updatestatementtransactiondate',
      OdataStatement: '/odata/statement'
    },
    sysPendingJob:{
      OdataSysPendingJob: '/odata/syspendingjob'
    },
    systemSettings:{
        GetSystemSettings: '/api/channelplatformset/getsystemsettings',
        AddOrUpdateChannelPlatformSet: '/api/channelplatformset/addorupdatechannelplatformset'
      },
    platform: {
        UpdatePlatform: '/api/platform/updateplatform',
        OdataPlatform: '/odata/platform',
        AddPlatform: '/api/platform/addplatform',
        DeletePlatform: '/api/platform/deleteplatform'
      },
    dealer: {
        UpdateDealer: '/api/dealer/updatedealer',
        OdataDealer: '/odata/dealer',
        AddDealer: '/api/dealer/adddealer',
        DeleteDealer: '/api/dealer/deletedealer'
      },
    storeCatgAttr: {
      StoreCatgAttr: '/odata/storecatgattr',
      AddStoreCatgAttr: '/api/storecatgattr/addstorecatgattr',
      UpdateStoreCatgAttr: '/api/storecatgattr/updatestorecatgattr',
      DeleteStoreCatgAttr: '/api/storecatgattr/deletestorecatgattr',
    },
    catgAttrOption: {
      CatgAttrOption: '/odata/catgattroption',
    }
    },
    secretKey: 'Yuz7ZoQrgRRk14NKKdgzzKXtL95rmzua5sVuq+Vdvdg=',
    disableSubscriptionPage: true
  };
