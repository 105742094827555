<div style="height: 100%; width: 100%;">
    <div class="layout-box">
        <div style="display: flex;">
            <label class="title">Outlet Manager</label>
            <span class="spacer-between"></span>
            <img style="width: 20px; cursor: pointer;" src="assets/base/icons/remove.svg" (click)="closeDialog()" />
        </div>
        <div class="panel-container">
            <div *ngFor="let sideItm of sideNavItems" class=" side-nav-itm"
                (click)="onClickSideNavItem(sideItm)">
                {{sideItm.name}}
                <span *ngIf="sideItm.subNavItm || isSubNavMode" class="spacer-between"></span>
                <img *ngIf="sideItm.subNavItm && !isSubNavMode" style="width: 20px; cursor: pointer;"
                    src="assets/base/icons/chevron-right.svg" (click)="viewSubNavItm(sideItm)" />
                <div style="display: flex;" *ngIf="sideItm.subNavItm && isSubNavMode" (click)="viewBackNavItm()">
                    <img style="width: 20px; cursor: pointer;" src="assets/base/icons/chevron-left.svg" />
                    <label>back</label>
                </div>
            </div>
        </div>
    </div>
</div>
