/**
 * RMS API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IEdmTypeReference } from './iEdmTypeReference';
import { RangeVariable } from './rangeVariable';
import { SingleValueNode } from './singleValueNode';

export interface OrderByClause { 
    thenBy?: OrderByClause;
    expression?: SingleValueNode;
    direction?: OrderByClause.DirectionEnum;
    rangeVariable?: RangeVariable;
    itemType?: IEdmTypeReference;
}
export namespace OrderByClause {
    export type DirectionEnum = 'Ascending' | 'Descending';
    export const DirectionEnum = {
        Ascending: 'Ascending' as DirectionEnum,
        Descending: 'Descending' as DirectionEnum
    };
}