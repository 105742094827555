import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormComponent } from 'src/app/shared/component/form/form.component';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { FormCode } from 'src/app/shared/enums/formCode';
import { AuthService } from 'src/app/core/services/auth.service';
import { PageService } from 'src/app/shared/services/page.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionToolBarResp } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarResp.model';
import { UntypedFormGroup } from '@angular/forms';
import { toolbarForm } from 'src/app/shared/component/action-tool-bar/data/toolbar-form.data';
import { ActionToolBarSetting } from 'src/app/shared/component/action-tool-bar/model/ActionToolBarSetting.model';
import { FormSetting } from 'src/app/shared/component/form/model/FormSetting.model';
import { InputTypes } from 'src/app/shared/component/input/enum/InputTypes';
import {
  BackendChannelStoreTax,
  DeliveryMode,
} from 'src/swagger/cxmbackend/openapi';
import { FormService } from 'src/app/shared/component/form/form.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupFormService } from 'src/app/shared/services/popup-form.service';
import { Mode } from 'src/app/shared/enums/mode';
import { formatDateString } from 'src/app/core/services/utils.service';
import { PopupMessageComponent } from 'src/app/shared/component/popup-message/popup-message.component';
import { PopupMessage } from 'src/app/shared/models/popupMessage.model';
import { LookupStoreComponent } from 'src/app/cxm-backend-web/lookup/lookup-store/lookup-store.component';
import { LocalChannelService } from 'src/app/cxm-backend-web/channel/localChannel.service';
import { LookupChannelComponent } from 'src/app/cxm-backend-web/lookup/lookup-channel/lookup-channel.component';
import { LookupTaxConditionHComponent } from 'src/app/cxm-backend-web/lookup/lookup-taxcondition/lookup-taxconditionh/lookup-taxconditionh.component';
import { PageRoute } from 'src/app/shared/enums/pageRoute';

@Component({
  selector: 'app-channelstoretax-entry',
  standalone: true,
  templateUrl: './channelstoretax-entry.component.html',
  styleUrls: ['./channelstoretax-entry.component.scss'],
  imports: [FormComponent],
  providers: [LocalChannelService],
})
export class ChannelStoreTaxEntryComponent implements OnInit, OnDestroy {
  page: Page;
  @Input() params = null;
  pages: Page[];
  formDetail: any = null;
  channelId: number;
  storeId: number;
  taxLine: number;
  channelStoreTaxData: any;
  editMode: boolean = false;
  selectedRowData: any;

  page$: Subscription;

  formSetting: FormSetting = {
    formCode: FormCode.channelentry,
    content: [
      {
        inputText: {
          name: 'channelDesc',
          label: 'channelstoretax.channel_desc',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
          disabled: true
        },
      },
      {
        lookDropInput: {
          componentName: LookupStoreComponent,
          name: 'storeId',
          label: 'channelstoretax.store_name',
          valueKey: 'storeId',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
          editable: false,
          required: true
        },
      },
      {
        lookDropInput: {
          componentName: LookupTaxConditionHComponent,
          label: 'channelstoretax.tax_cond_code',
          name: 'taxCondCode',
          valueKey: 'taxCondCode',
          placeholder: 'general.select_at_least_one',
          api: '',
          filter: '',
          styling: {
            width: '60%',
          },
          required: true
        },
      },
      {
        toggleInput: {
          name: 'dineinFlag',
          label: 'channelstoretax.dinein_flag',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'pickupFlag',
          label: 'channelstoretax.pickup_flag',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'deliveryFlag',
          label: 'channelstoretax.delivery_flag',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        toggleInput: {
          name: 'takeawayFlag',
          label: 'channelstoretax.takeaway_flag',
          required: true,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'transportModeList',
          label: 'channelstoretax.transport_mode_list',
          type: InputTypes.text,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'effectiveDate',
          label: 'channelstoretax.effective_date',
          type: InputTypes.dateTimeLocal,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
      {
        inputText: {
          name: 'obsoleteDate',
          label: 'channelstoretax.obsolete_date',
          type: InputTypes.dateTimeLocal,
          required: false,
          styling: {
            width: '60%',
          },
        },
      },
    ],
  };

  actionToolbarSetting: ActionToolBarSetting = {
    actionToolBarItems: toolbarForm,
  };

  reqBody: BackendChannelStoreTax = null;

  constructor(
    private authService: AuthService,
    private localChannelService: LocalChannelService,
    private pageService: PageService,
    private formService: FormService,
    private popupFormService: PopupFormService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.formDetail = this.formService.getFormDetail(this.page.form.formCode);


    if (this.page) {
      if (this.page.queryParams?.mode) {
        this.editMode = this.page.queryParams.mode === Mode.edit ? true : false;
      }

      let params = this.pageService.getParameterDecode(this.page, 'params');

      this.channelId = this.params
        ? this.params['channelId']
        : params['channelId'];
      this.storeId = this.params ? this.params['storeId'] : params['storeId'];
      this.taxLine = this.params ? this.params['taxLine'] : params['taxLine'];

      if (this.editMode && this.channelId && this.storeId && this.taxLine) {
        await this.getchannelstoretax(this.channelId, this.storeId, this.taxLine);
      }
      else if (!this.editMode){
        this.channelStoreTaxData = {
          channelDesc: (await this.localChannelService.getOdataChannel(this.channelId.toString()))['value'][0].channelDesc
        }
      }
    }
  }

  async onToolbarAction(value: ActionToolBarResp) {}

  async getchannelstoretax(channelId: number, storeId: number, taxLine: number) {
    // Get channelstoretax
    let result = await this.localChannelService.reqOdataChannelStoreTaxData(
      channelId,
      storeId,
      taxLine
    );

    if (
      result instanceof HttpErrorResponse === false &&
      result['value'].length > 0
    ) {
      this.channelStoreTaxData = result['value'][0];
    }
  }

  async onSubmitForm(formGroup: UntypedFormGroup) {
    let result = null;

    this.reqBody = this.formService.getReqBody(formGroup);

    let orderTypeList = ''
    orderTypeList += formGroup.get('dineinFlag').value == true? '1' : '';
    orderTypeList += formGroup.get('pickupFlag').value == true? orderTypeList == ''? '2' : ',2' : '';
    orderTypeList += formGroup.get('deliveryFlag').value == true? orderTypeList == ''? '3' : ',3' : '';
    orderTypeList += formGroup.get('takeawayFlag').value == true? orderTypeList == ''? '4' : ',4' : '';
    if (this.editMode) {
      this.reqBody = {
        ...this.reqBody,
        storeId: +this.storeId,
        channelId: +this.channelId,
        taxLine: +this.taxLine,
        orderTypeList: orderTypeList,
        rowVersion: this.channelStoreTaxData?.rowVersion
          ? this.channelStoreTaxData.rowVersion
          : '',
      };
      result = await this.localChannelService.updateChannelStoreTax(this.reqBody);
    } else {
      this.reqBody = {
        ...this.reqBody,
        channelId: +this.channelId,
        orderTypeList: orderTypeList
      };
      result = await this.localChannelService.addChannelStoreTax(this.reqBody);
    }

    if (
      result != null &&
      result instanceof HttpErrorResponse === false &&
      (result.status == null || result.status == 200)
    ) {


      let msg: PopupMessage = {
        formCode: FormCode.channelstoretaxentry,
        id: 'success',
        titleIcon: 'assets/base/icons/check-circle.svg',
        title: 'msg.channelstoretax_saved',
        desc: this.editMode
          ? 'msg.updated_channelstoretax'
          : 'msg.added_new_channelstoretax',
        actionBtnText: 'general.okay',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });

      this.pageService.refresh([FormCode.channelstoretaxlisting]);

      this.pageService.closePage(this.page);
    } else {
      let msg: PopupMessage = {
        titleIcon: 'assets/base/icons/exclamation-circle.svg',
        title: 'msg.operation_unsuccessful',
        desc: this.editMode
        ? 'msg.system_couldnt_update_channelstoretax'
        : 'msg.system_couldnt_save_channelstoretax',
        actionBtnText: 'general.back',
      };

      this.dialog.open(PopupMessageComponent, {
        data: msg,
      });
    }
  }

  openChildForm(event) {
    console.log('event: ', event);
  }

  ngOnDestroy(): void {
    this.page$?.unsubscribe();
  }
}
