<div class="row" style="padding-top: 20px;">
  <div class="column-10">
    <div class="row" style="margin: 0px">
      <!-- <app-marketing-banner id="marketing-banner" draggable="true" (dragstart)="startDrag($event)"
        (dragend)="endDrag($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
        (drop)="drop($event)" (dragover)="allowDrop($event)"></app-marketing-banner>
      <div id="subscription-card" draggable="true" (dragstart)="startDrag($event)" (dragend)="endDrag($event)"
        (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="drop($event)"
        (dragover)="allowDrop($event)" class="subscription-widget">
        <app-card [cardSetting]="subscriptionSetting"></app-card>
      </div>
      <div id="favorites" draggable="true" (dragstart)="startDrag($event)" (dragend)="endDrag($event)"
        (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="drop($event)"
        (dragover)="allowDrop($event)" class="subscription-widget">
        <app-favorites></app-favorites>
      </div>
      <div id="guide-master-card" draggable="true" (dragstart)="startDrag($event)" (dragend)="endDrag($event)"
        (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="drop($event)"
        (dragover)="allowDrop($event)" class="guide-widget">
        <app-guide-master-card></app-guide-master-card>
      </div>
      <div id="document-summary-card" draggable="true" (dragstart)="startDrag($event)" (dragend)="endDrag($event)"
        (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="drop($event)"
        (dragover)="allowDrop($event)" class="guide-widget">
        <app-document-summary-card></app-document-summary-card>
      </div>
      <div id="job-status-card" draggable="true" (dragstart)="startDrag($event)" (dragend)="endDrag($event)"
        (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="drop($event)"
        (dragover)="allowDrop($event)" class="guide-widget">
        <app-job-status-card></app-job-status-card>
      </div>
      <div class="add-widget-box" (click)="addNewWidget()">
        <img class="add-icon" src="assets/base/icons/plus.svg">
        <span class="add-widget">Add Widget</span>
      </div> -->
      <!-- <app-swiper></app-swiper> -->

      <div style="width: calc(100vw - 40px); height: calc(100vh - 180px);">
        <!-- <app-carousel [slides]="slides" [intervalTime]="5000"></app-carousel> -->
        <app-carousel [slides]="slides"></app-carousel>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
