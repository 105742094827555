<div class="report-layout" *ngIf="!requiredAccessCode">
  <app-report-viewer [page]="page" [formCode]="formCode" [isReportMode]="isReportMode" [filterGrpData]="filterGrpData" [defaultFilter]="defaultFilter"
  [selectionGroups]="selectionGroups" [selectedGroups]="selectedGroups" [filterItems]="filterItems" [rptViewerData]="rptViewerData"></app-report-viewer>
</div>

<div class="report-viewer-access-layout" *ngIf="requiredAccessCode">
  <ng-container>
    <form class="form-class" [formGroup]="formGrp" (ngSubmit)="onSubmitAccessCode()">
      <div class="popup-container">
        <p class="title">{{ "report.hello" | translate }}</p>
        <p [innerHtml]="'report.input_access_code' | translate"></p>
        <app-input-text [inputTextSetting]="accessCodeInput.inputText" [formGroup]="formGrp"></app-input-text>
        <button type="submit" [disabled]="!formGrp.valid" [ngClass]="{'active': formGrp.valid}">{{ "report.access_report" | translate }}</button>
      </div>
    </form>
  </ng-container>
</div>
