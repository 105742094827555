import { Injectable } from '@angular/core';
import { CustomRequest } from '../models/local/CustomRequest';
import { HttpMethod } from '../enums/HttpMethod';
import { environment } from 'src/environments/environment';
import { CustomService } from './custom.service';
import { Router } from '@angular/router';
import { SubscriptionStatus } from 'src/app/shared/enums/subscriptionStatus';
import { SubscriptionRepository } from 'src/app/state/subscription.repository';
import { RMSSubscriptionInfoResponse } from '../models/local/RMSSubscriptionInfoResponse';
import { Pagination } from 'src/app/shared/enums/pagination';
import { SubscriptionListDetailOutputDTOCustomODataResult } from '../models/local/SubscriptionListDetailOutputDTOCustomODataResult';
import { OdataModel } from '../models/local/odata';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  selectedSub: RMSSubscriptionInfoResponse;
  defaultSubscription: RMSSubscriptionInfoResponse = {
    subscriptionId: environment.subscription,
    subscriptionName: environment.subscriptionName,
    expiryDate: "",
    disable: false,
    remarks: "",
    statusFlag: 1,
    status: SubscriptionStatus.Active,
    billingName: "",
    apiUrl: "",
    apiUrl4: environment.hostPath,
    posApiUrl: "",
    firebaseConfig: environment.firebaseConfig,
    hideSwitchSubscription: true
  }

  constructor(
    private customService: CustomService,
    private subscriptionRepository: SubscriptionRepository,
    private router: Router
  ) {
    this.getSelectedSubscriptionRepo().subscribe(selectedSub => {
      this.selectedSub = selectedSub;
    });
  }

  async getSubscriptionInfoList(pagination: Pagination): Promise<SubscriptionListDetailOutputDTOCustomODataResult> {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.subscription.Getsubscriptioninfolist,
      hostPath: environment.subHostPath,
      headers: null,
      httpHeaderType: undefined,
      queryParams: {
        $count: true,
        $top: pagination ? pagination.top : undefined,
        $skip: pagination ? pagination.skip : undefined,
        appCode: 'ZRMS',
      },
    };

    let respInfo: any = null;
    respInfo = await this.reqCustomHttpCall(cusReq);

    return respInfo;
  }

  async getRMSSubscriptionInfo(subscriptionId: string): Promise<RMSSubscriptionInfoResponse> {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.subscription.GetRMSSubscriptionInfo,
      hostPath: environment.subHostPath,
      headers: null,
      httpHeaderType: undefined,
      queryParams: {
        subscriptionId: subscriptionId,
        appCode: 'ZRMS',
      },
    };

    let respInfo: any = null;
    respInfo = await this.reqCustomHttpCall(cusReq);

    return respInfo;
  }

  async updateInfo(): Promise<any> {
    let cusReq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.subscription.UpdateInfo,
      hostPath: this.selectedSub?.apiUrl4,
      headers: {
        subscriptionId: this.selectedSub.subscriptionId
      },
    };

    let respInfo: any = null;
    respInfo = await this.reqCustomHttpCall(cusReq);

    return respInfo;
  }

  private reqCustomHttpCall(customRequest: CustomRequest) {
    return this.customService.createRequest(customRequest).then((dd: any) => {
      let odataBody: OdataModel = <any>{};

      if (dd && dd['body'] && customRequest.requestpath.includes('/odata/')) {
        if (dd['body']['@odata.nextLink']) {
          odataBody.odataNextLink = dd['body']['@odata.nextLink'];
        }

        if (dd['body']['@odata.count']) {
          odataBody.odataCount = dd['body']['@odata.count'];
        }

        if (dd['body']['value']) {
          odataBody.value = dd['body']['value'];
        }

        return odataBody;
      } else {
        return dd && dd['body'] ? dd['body'] : dd;
      }
    });
  }

  selectSubscription(subscription: RMSSubscriptionInfoResponse) {
    this.subscriptionRepository.selectSubscription(
      subscription
    );
  }

  resetSubscription() {
    this.subscriptionRepository.resetSubscription();
  }

  async selectActiveSubscription(subscriptionResponse: RMSSubscriptionInfoResponse) {
    if (subscriptionResponse.status === SubscriptionStatus.Active) {
      await this.selectSubscription(subscriptionResponse);
      this.updateInfo();
      this.router.navigateByUrl('dashboard');
    }
  }

  async selectDefaultSubscription() {
    await this.selectSubscription(this.defaultSubscription);
  }

  getSubscriptionRepo() {
    return this.subscriptionRepository.subscription$;
  }

  getSelectedSubscriptionRepo() {
    return this.subscriptionRepository.selectedSubscription$;
  }

  getSelectedSubscription() {
    return this.subscriptionRepository.getSubscription();
  }
}
